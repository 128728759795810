import React from 'react';
import { BehaviorEditorProps } from './Type';
import { Behavior, BehaviorType, RunScriptBehavior } from 'internal/models';
import { Button, Collapsible } from '@smartaction/visuals';
import { VisualCategory } from '@smartaction/styles';
import { FollowUpListView } from './FollowUpList';
import { EditorInterface, MonacoEditor } from 'ui/components/design/scripting/MonacoEditor';
import { useBots, useClient } from 'contexts';

type RunScriptProps = BehaviorEditorProps<RunScriptBehavior> & {
    allowedFollowUpTypes: BehaviorType[]
}

export const RunScriptBehaviorView: React.FC<RunScriptProps> = ({behavior, update, allowedFollowUpTypes}) => {
    const validateClient = useClient('validator');

    // TODO: prop drill from parent
    const { isReadOnlyBot } = useBots();
    const isReadOnly = isReadOnlyBot;

    const scriptChange = () => {
        update(new RunScriptBehavior(behavior.script, behavior.followUpBehaviors));
    }

    const saveFollowUps = (behaviors: Behavior[]) => {
        update(new RunScriptBehavior(behavior.script, behaviors));
    }

    const followUps = allowedFollowUpTypes.length
    ? (
        <React.Fragment>
            <hr/>
            <FollowUpListView allowedTypes={allowedFollowUpTypes} followUpBehaviors={behavior.followUpBehaviors} update={saveFollowUps} />
        </React.Fragment>
    ) 
    : <React.Fragment/>;
    
    return (
        <React.Fragment>
            <Collapsible labelContent="Edit Script" className='run-script-editor'>
                <MonacoEditor id={"behaviorEditor"}
                    isReadOnly={isReadOnly}
                    value={behavior.script} 
                    types={[EditorInterface.ConversationState]} 
                    onSave={scriptChange} 
                    validateMethod={(tenant, snapshot, script) => validateClient.validateInputFormatterScript(tenant, snapshot, script) } />
            </Collapsible>
            <Button type={VisualCategory.Primary} action={scriptChange}>Save Script</Button>
            {followUps}
        </React.Fragment>
    );
}
