import { Select } from '@smartaction/visuals';
import { ICellEditorParams } from 'ag-grid-community';
import { ConfigValue, DayOfWeek, ValueType } from 'internal/models';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';


const dayOfWeekOptions = [
    { value: 'Sunday', label: 'Sunday' },
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
  ];

export const DayOfWeekCellEditor = forwardRef((props: ICellEditorParams, ref: React.ForwardedRef<unknown>) => {
    const configValue = (props.value as ConfigValue);
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState(configValue.type === ValueType.DayOfWeek ? (configValue.value as DayOfWeek) : "");
    const [, setChanged] = useState(false);

    function inputHandler(e : React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value);
        setChanged(true);
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [inputRef]);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value 
                    ? new ConfigValue(ValueType.DayOfWeek, value) 
                    : new ConfigValue(ValueType.Unset, undefined);
            },
            isCancelAfterEnd: () => {
                return value === configValue.value;
            }
        };
    });

    return (
        <Select
        value={value}
        options={dayOfWeekOptions}
        onChange={(selectedValue) => { setValue(selectedValue);}}
        id={'day-of-week'}
      />
    )
});