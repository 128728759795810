import { CustomField } from './CustomField';

export class LocationDefinition {
  id: string;
  ownerId: string;
  tenantId: string;
  name: string;
  customFields: CustomField[];

  constructor(id: string, tenantId: string, ownerId: string, name: string, customFields: CustomField[]) {
    this.id = id;
    this.ownerId = ownerId;
    this.tenantId = tenantId;
    this.name = name;
    this.customFields = customFields;
  }

  static fromJson(json: any) {
    return new LocationDefinition(json.id, json.ownerId, json.tenantId, json.name, json.customFields);
  }
}
