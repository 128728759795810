// https://stackoverflow.com/a/62900613

import { IconType } from "@smartaction/styles";

// apparently number is acting as an implicit translation to the index of the AllDataTypes array.
export const AllDataTypes = ["Boolean", "String", "Integer", "Decimal", "CreditCard", "Address", "Currency", "Date", "DateTime"];

export type DataType = typeof AllDataTypes[number];

export const DataTypeToIcon = new Map<DataType, IconType>();

DataTypeToIcon.set('Boolean', IconType.CheckboxChecked);
DataTypeToIcon.set('String', IconType.Hippo); // switching to 'Text' when Styles is updated
DataTypeToIcon.set('Integer', IconType.Hippo); // TBD
DataTypeToIcon.set('Decimal', IconType.Hippo); // TBD
DataTypeToIcon.set('CreditCard', IconType.CreditCard);
DataTypeToIcon.set('Address', IconType.Company);
DataTypeToIcon.set('Currency', IconType.Hippo); // currency on updating Styles
DataTypeToIcon.set('Date', IconType.Calendar);
DataTypeToIcon.set('DateTime', IconType.Hippo); // Clock on updating Styles

