import { Editor } from '@smartaction/visuals';
import { useClient } from 'contexts/ClientContext';
import React from 'react';
import { useConfigs } from 'contexts/design/ConfigsContext';
import { EntitlementNames } from 'EntitlementNames';
import { useCheckTenantAccess } from 'contexts/AccessContext';
import { ConfigTable } from './ConfigTable';
import { useBots } from '../../../../../contexts';

export const ConfigsView: React.FC = () => {
  const configs = useConfigs();
  const client = useClient('config');
  const { isReadOnlyBot} = useBots();
  //TODO: Bypassing until AccessContext fixed, currently entitlement names returned dont match with ones defined in UI also logic
  //in AccessContext needs rework when to use AD user vs Outh user.
  const canViewTechnical = true;
  //const canViewTechnical = useCheckTenantAccess(EntitlementNames.Configs.Technical.View);

  const businessView = (
    <ConfigTable
      configSet="business"
      configs={configs.business}
      client={client.business}
      refresh={configs.refresh}
      manageEntitlement={EntitlementNames.Configs.Business.Manage}
      viewSensitiveValuesEntitlement={EntitlementNames.Configs.Business.ViewSensitiveValues}
      setProductionValueEntitlement={EntitlementNames.Configs.Business.SetProduction}
      isReadOnly={isReadOnlyBot}
    />
  );

  const technicalView = (
    <ConfigTable
      configSet="technical"
      configs={configs.technical}
      client={client.technical}
      refresh={configs.refresh}
      manageEntitlement={EntitlementNames.Configs.Technical.Manage}
      viewSensitiveValuesEntitlement={EntitlementNames.Configs.Technical.ViewSensitiveValues}
      setProductionValueEntitlement={EntitlementNames.Configs.Technical.SetProduction}
      isReadOnly={isReadOnlyBot}
    />
  );

  return (
    <React.Fragment>
      <Editor>
        <h5>Business</h5>
        {businessView}

        {canViewTechnical && (
          <>
            <h5>Technical</h5>
            {technicalView}
          </>
        )}
      </Editor>
    </React.Fragment>
  );
};
