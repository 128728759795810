import { BaseModel } from '@smartaction/common';
import { StepType } from './Type';

export abstract class Step extends BaseModel<Step> {
  type: StepType;
  description: string;
  tags: string[];

  constructor(id: string | undefined, name: string, description: string, type: StepType, tags: string[]) {
    super(name, id);
    this.type = type;
    this.description = description || '';
    this.tags = tags;
  }
}