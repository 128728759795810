import { IconType } from '@smartaction/styles';
import { Pointer, PointerType, SendSMS } from 'internal/models';
import React from 'react';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { useClient, useFlow, useSnapshot } from 'contexts';
import { OutputStepsColors } from '../../StepColorGroups';
import { PointerControl } from 'ui/components';
import { StepOutputsView } from '../../StepOutputsView';

export const SendSMSStyling: StepStyling = {
  typeName: 'Send SMS',
  icon: IconType.SMS,
  ...OutputStepsColors,
};

export const SendSMSStepView: React.FC<TypedStepProps<SendSMS>> = ({ step, manipulateStep }) => {
  return (
    <StepView step={step} styling={SendSMSStyling} isCollapsible={true} manipulateStep={manipulateStep}>
      <SMSDetails step={step} manipulateStep={manipulateStep} />
    </StepView>
  );
};

const SMSDetails: React.FC<TypedStepProps<SendSMS>> = ({ step }) => {
  const { snapshot } = useSnapshot();
  const { updateFlow } = useFlow();
  const client = useClient('flow');
  const onToNumberChange = async (pointer: Pointer) => {
    const response = await client.steps.sendSMS.setToNumber(snapshot.id, step.id, pointer);
    if (response.success) {
      updateFlow(() => {
        step.toNumber = pointer;
      });
    }
  };

  const onFromNumberChange = async (pointer: Pointer) => {
    const response = await client.steps.sendSMS.setFromNumber(snapshot.id, step.id, pointer);
    if (response.success) {
      updateFlow(() => {
        step.fromNumber = pointer;
      });
    }
  };

  return (
    <React.Fragment>
      <div>
        <h4>To Number</h4>
          <PointerControl types={[PointerType.Context, PointerType.Iteration]} pointer={step.toNumber} update={onToNumberChange} />
      </div>
      <div>
        <h4>From Number</h4>
          <PointerControl
            types={[PointerType.Config, PointerType.Context, PointerType.DirectAssignment, PointerType.Iteration]}
            pointer={step.fromNumber}
            update={onFromNumberChange}
          />
      </div>
      <StepOutputsView typeName="Message" step={step} />
    </React.Fragment>
  );
};
