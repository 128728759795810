import React, { useState } from 'react';
import { Button, PortalModal, useFromModal } from '@smartaction/visuals';
import { VisualCategory } from '@smartaction/styles';

type PublishModalProps = {
  publish: (comment: string) => Promise<void>;
};
export const PublishModal: React.FC<PublishModalProps> = ({ publish }) => {
  const modal = useFromModal();
  const [publishComment, setPublishComment] = useState<string>('');
  const commentMaxLength = 500;

  const header = {
    type: VisualCategory.Primary,
    content: <React.Fragment>Publish</React.Fragment>,
  };

  const content = (
    <>
      <textarea
        rows={5}
        placeholder={'Type comment here...'}
        className={'form-control'}
        aria-label={'Comment'}
        style={{ minWidth: '400px' }}
        value={publishComment}
        onChange={(event) => setPublishComment(event.target.value)}
      />
      {publishComment && publishComment?.length > commentMaxLength && (
        <div className="alert alert-warning mt-4">Comment is too long. Max 500 characters.</div>
      )}
    </>
  );

  const cancel = () => modal.forceClose();
  const saveClick = async () => {
    modal.forceClose();
    await publish(publishComment);
  };

  const buttons = (
    <React.Fragment>
      <Button
        className={'me-3'}
        action={saveClick}
        isDisabled={!publishComment || publishComment?.length > commentMaxLength}
        type={VisualCategory.Primary}
      >
        {'Publish'}
      </Button>
      <Button action={cancel} type={VisualCategory.Secondary}>
        Cancel
      </Button>
    </React.Fragment>
  );

  return <PortalModal header={header} content={content} buttons={buttons} />;
};
