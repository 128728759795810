import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { IIncrementClient } from './IIncrementClient';

export class IncrementClient implements IIncrementClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private url(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }

  async setIsDecrement(snapshotId: string, stepId: string, isDecrement: boolean): Promise<ServerResponse> {
    const url = `${this.url(snapshotId, stepId)}/is-decrement`;
    const response = await this.methods.put(url, isDecrement);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Updated} Is Decrement`,
      response,
    );
  }
}
