import React, { useEffect, useState } from 'react';
import { CellValueChangedEvent, GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { Field, FieldType, Obj } from 'internal/models';
import { Button, SelectRenderer, useSidePanel } from '@smartaction/visuals';
import { VisualCategory } from '@smartaction/styles';
import { useBots, useClient, useObjects, useSnapshot } from 'contexts';
import { AgGridReact } from 'ag-grid-react';
import { CheckboxCellStyle, CheckEditableRenderer } from 'ui/controls/grid/editors/CheckEditableRenderer';
import { useCheckTenantAccess } from 'contexts/AccessContext';
import { EntitlementNames } from 'EntitlementNames';
import { ServerResponse } from 'internal/clients';
import { toast } from 'react-toastify';

export const FieldsRenderer: React.FC<ICellRendererParams<ObjectInUse, Field[]>> = ({ colDef, data, value }) => {
  const sidePanel = useSidePanel();
  const objects = useObjects();

  const hasAccess = useCheckTenantAccess(EntitlementNames.Objects.Manage);
  const {isReadOnlyBot} = useBots();
  const isStaticObject = (obj: Obj): boolean => {
    return objects.staticObjects.some((staticObjects) => obj.id === staticObjects.id);
  }

  // Check snapshot readonly, permissions, and if Static
  const isReadOnly = (obj: Obj): boolean => {
    return isReadOnlyBot || !hasAccess || isStaticObject(obj);
  };

  if (!data || !value) {
    return <React.Fragment />;
  }
  const fieldNames = !value.length ? <i>No fields defined</i> : <span>{value.map((v) => v.name).join(', ')}</span>;
  return (
    <div>
      <span style={{ display: 'inline-flex', maxWidth: '85%', overflow: 'auto' }} className="fieldNames">
        {fieldNames}
      </span>
      <Button
        className="mx-2 btn-sm"
        type={VisualCategory.Primary}
        action={() => {
          sidePanel.setContents(data.obj.name, 
            <FieldEditor obj={data.obj} fields={value} isReadonly={isReadOnly(data.obj)} />);
          sidePanel.open();
        }}>
        {isReadOnly(data.obj) ? "View" : "Manage"}
      </Button>
    </div>
  );
};

export type ObjectInUse = {
  obj: Obj;
  inUse: boolean;
};

type FieldEditorProps = {
  isReadonly: boolean;
  obj: Obj;
  fields: Field[];
};

const FieldEditor: React.FC<FieldEditorProps> = ({ obj, fields, isReadonly }) => {
  const objects = useObjects();
  const client = useClient('objects');
  const snapshot = useSnapshot();
  const [gridApi, setGridApi] = useState<GridApi>();

  useEffect(() => {
    gridApi?.setRowData(fields);
  }, [objects.objects]);

  const handleSaveEvent = async (event: CellValueChangedEvent<Field>) => {
    var columnDef = event.colDef;
    const field = event.data;
    const newValue = event.newValue;
    const snapshotId = snapshot.snapshot.id;
    switch (columnDef.field) {
      case 'name':
        if (!event.newValue) {
          toast('Name must be set! Changes are not saved.', { type: 'error', containerId: 'default' });
          return;
        }
        finishSave(client.fields.updateName(snapshotId, obj.id, field.id, newValue), () => (field.name = newValue));
        break;
      case 'description':
        finishSave(
          client.fields.updateDescription(snapshotId, obj.id, field.id, newValue),
          () => (field.description = newValue),
        );
        break;
      case 'type':
        finishSave(client.fields.updateType(snapshotId, obj.id, field.id, newValue), () => (field.type = newValue));
        break;
      case 'isList':
        finishSave(client.fields.updateIsList(snapshotId, obj.id, field.id, newValue), () => (field.isList = newValue));
        break;
    }
  };

  const finishSave = async (responsePromise: Promise<ServerResponse>, localUpdate: () => void) => {
    const response = await responsePromise;
    if (response.success) {
      localUpdate();
      objects.refresh();
    }
  };

  const create = async () => {
    const field = new Field('', 'New Field', '', FieldType.String, false);
    const response = await client.fields.create(snapshot.snapshot.id, obj.id, field.name, field.type);
    if (response.success) {
      field.id = response.data!;
      obj.fields.push(field);
      objects.refresh();
    }
  };

  const gridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api);
  };

  return (
    <div className="editorWithGrid">
      <div className="ag-theme-balham" style={{ height: '100%' }}>
        <AgGridReact
          disableStaticMarkup={true} // https://www.ag-grid.com/react-fine-tuning/#react-cell-rendering - disabled this because it causes the very first cell to frequently render twice
          columnDefs={colDefs(isReadonly)}
          defaultColDef={{ cellStyle: { fontFamily: 'Quicksand', fontSize: '14px' }, singleClickEdit: true }}
          rowData={fields}
          onCellValueChanged={handleSaveEvent}
          rowHeight={36}
          stopEditingWhenCellsLoseFocus={true}
          domLayout="autoHeight"
          suppressRowTransform={true}
          onGridReady={gridReady}
        />
      </div>
      {!isReadonly &&
        <Button type={VisualCategory.Primary} action={create}>
          Create New Field
        </Button>
      }
    </div>
  );
};

function colDefs(isReadOnly: boolean) {
  return [
    { 
      headerName: 'Name', 
      field: 'name', 
      editable: !isReadOnly, 
      width: 130, 
      resizable: true },
    { 
      headerName: 'Description', 
      field: 'description', 
      editable: !isReadOnly, 
      width: 200, 
      resizable: true },
    {
      headerName: 'Type',
      field: 'type',
      cellRenderer: SelectRenderer,
      options: selectOptions,
      editable: false, // use custom
      isReadOnly: isReadOnly,
      width: 165,
      resizable: true,
    },
    {
      headerName: 'Is List',
      field: 'isList',
      cellRenderer: CheckEditableRenderer,
      editable: false, // use custom
      cellStyle: CheckboxCellStyle,
      cellRendererParams: { canEdit: !isReadOnly},
      width: 100,
      resizable: true,
    }
  ];
}

const selectOptions = [
  { label: 'String', value: FieldType.String },
  { label: 'Integer', value: FieldType.Integer },
  { label: 'Decimal', value: FieldType.Decimal },
  { label: 'Boolean', value: FieldType.Boolean },
  { label: 'Date', value: FieldType.Date },
  { label: 'Date and Time', value: FieldType.DateTime },
  { label: 'Date Range', value: FieldType.DateRange },
  { label: 'Currency', value: FieldType.Currency },
  { label: 'Credit Card', value: FieldType.CreditCard },
  { label: 'Address', value: FieldType.Address },
];
