import { DirectAssignmentPointer } from 'internal/models';
import React, { useState } from 'react';
import { PointerEditorProps } from './PointerEditorProps';

export const DirectAssignmentPointerEditor: React.FC<PointerEditorProps<DirectAssignmentPointer>> = ({pointer, update, isDisabled}) => {
    const [text, setText] = useState(pointer?.value ?? "");

    const onChange=(evt: React.ChangeEvent<HTMLInputElement>) => {
        setText(evt.target.value);
    }

    const save = () => {
        update(new DirectAssignmentPointer(text));
    }
    const onPressEnter = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.key === 'Enter') {
            evt.currentTarget.blur();
        }
    };
    return <input type="text" disabled={isDisabled} className='form-control form-control-sm' onChange={onChange} onBlur={save} value={text} onKeyDown={onPressEnter} />
}