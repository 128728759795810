import { BaseModel } from "@smartaction/common";

export const ObjectPropertyNamesForValidation = ["name", "description", "fields"];

// This class is called Obj instead of Object because Object is apparently a protected name.
export class Obj extends BaseModel<Obj> {
    originalId: string;
    description: string;
    fields: Field[];

    constructor(id: string, originalId: string, name: string, description: string, fields: Field[]) {
        super(name, id);
        this.description = description;
        this.originalId = originalId;
        this.fields = fields;
    }

    isDirty(original: Obj): boolean {
        throw new Error("Method not implemented.");
    }
    getDirtyFields(original: Obj): string[] {
        throw new Error("Method not implemented.");
    }
    clone(): Obj {
        throw new Error("Method not implemented.");
    }

    static fromJson(json: any) {
        const fields = json.fields && json.fields.length > 0
            ? json.fields.map((f: any) => Field.fromJson(f))
            : [];
        return new Obj(json.id, json.originalId, json.name, 
            json.description, fields);
    }
}

export class Field extends BaseModel<Field> {
    description: string;
    type: FieldType;
    typeId?: string;
    isList: boolean;

    constructor(id: string, name: string, description: string, type: FieldType, isList: boolean, typeId?: string) {
        super(name, id);
        this.description = description;
        this.type = type;
        this.typeId = typeId;
        this.isList = isList;
    }

    isDirty(original: Field): boolean {
        throw new Error("Method not implemented.");
    }
    getDirtyFields(original: Field): string[] {
        throw new Error("Method not implemented.");
    }
    clone(): Field {
        throw new Error("Method not implemented.");
    }

    static fromJson(json: any) {
        return new Field(json.id, json.name, json.description, json.type, json.isList, json.typeId);
    }
}

export enum FieldType {
    Unknown = "Unknown",
    String = "String",
    Integer = "Integer",
    Decimal = "Decimal",
    Boolean = "Boolean",
    Date = "Date",
    DateRange = "DateRange",
    DateTime = "DateTime",
    Currency = "Currency",
    CreditCard = "CreditCard",
    Address = "Address"
}

const StaticAddressObj = new Obj(
    "add000000000000000000000",
    "add000000000000000000000",
    "Address",
    "A location and its identifiable information.",
    [
        new Field("add000000000000000000001", "StreetLines", "", FieldType.String, true, ""),
        new Field("add000000000000000000002", "City", "", FieldType.String, false, ""),
        new Field("add000000000000000000003", "State", "", FieldType.String, false, ""),
        new Field("add000000000000000000004", "PostalCode", "", FieldType.String, false, ""),
        new Field("add000000000000000000005", "Country", "", FieldType.String, false, "")
    ]);

export const StaticObjectList: Obj[] = [
    StaticAddressObj
];