export const EntitlementNames = {
  APIs: {
    Manage: 'Manage APIs',
    ViewScripts: 'View Scripts',
    ModifyScripts: 'Modify Scripts',
  },
  Auth: {
    // No EntitlementNames.cs file in service
    ListEntitlements: 'List Entitlements',
    GetRole: 'Get Role',
    ListRoles: 'List Roles',
    CreateRole: 'Create Role',
    AssignEntitlementToRole: 'Assign Entitlement to Role',
    RemoveEntitlementFromRole: 'Remove Entitlement from Role',
    ListEntitlementsInclSystem: 'List Entitlements (incl. System)',
    UpdateEntitlement: 'Update Entitlement',
    ManageSmartActionRoles: 'Manage SmartAction Roles',
    ViewSmartActionRoles: 'View SmartAction Roles',
  },
  Bots: {
    // No EntitlementNames.cs file in service
    Manage: 'Manage Bots',
    AccessTeamsChannel: 'Access Teams Channel',
    PublishSnapshot: 'Publish Snapshot',
    PublishingApproval: 'Publishing Approval',
    ManageSnapshots: 'Manage Snapshots',
  },
  Configs: {
    Business: {
      Manage: 'Manage Configs - Business',
      ViewSensitiveValues: 'View Sensitive Values - Business',
      SetProduction: 'Set Production Value - Business',
    },
    Technical: {
      View: 'View Configs - Technical',
      Manage: 'Manage Configs - Technical',
      ViewSensitiveValues: 'View Sensitive Values - Technical',
      SetProduction: 'Set Production Value - Technical',
    },
  },
  Context: {
    Manage: 'Manage Context',
  },
  ConversationData: {
    ViewSummaries: 'View Summaries',
    ViewExecutions: 'View Executions',
    DownloadRecordings: 'Download Recordings',
  },
  Flow: {
    Flows: {
      Manage: 'Manage Flow',
      View: 'View Flow',
    },
    Blocks: {
      Manage: 'Manage Blocks',
      View: 'View Blocks',
    },
    Modules: {
      View: 'View Modules',
      Manage: 'Manage Modules',
      Design: 'Design Modules',
    },
    Steps: {
      View: 'View Steps',
      Manage: 'Manage Steps',
      ViewScript: 'View Script Steps',
      ManageScript: 'Manage Script Steps',
      UpdateText: 'Update Step Text',
    },
    Policies: {
      Manage: 'Manage Policies',
    },
    Functions: {
      Manage: 'Manage Functions',
    },
    Logs: {
      View: 'View Logs',
    },
  },
  LanguageModel: {
    Manage: 'Manage',
  },
  Locations: {
    LocationDefinitions: {
      Manage: 'Manage Location Definitions',
    },
    Locations: {
      Manage: 'Manage Locations',
    },
  },
  Objects: {
    Manage: 'Manage Objects',
  },
  Properties: {
    View: 'View Properties',
    Manage: 'Manage Properties',
  },
  Publisher: {
    View: 'View History',
    Validate: 'Validate',
    Publish: 'Publish',
  },
  Resources: {
    Manage: 'Manage Resources',
    View: 'View Resources',
    ReadAccessTokens: 'Read Access Tokens',
    RunCLUModel: 'Run CLU Model',
  },
  Snapshot: {
    PublishingApproval: 'Publishing Approval',
  },
  SystemAdministration: {
    ViewActiveConversations: 'Conversation Management - View Active Conversations',
    EndConversation: 'Conversation Management - End Conversation',
    Testing: 'Testing',
  },
  Tenants: {
    ListAllTenants: 'List All Tenants',
    Create: 'Create Tenant',
    Update: 'Update Tenant',
    ManageContacts: 'Manage Contacts',
  },
  Users: {
    Create: 'Create User',
    Get: 'Get User',
    ListUsers: 'List Users',
    Update: 'Update User',
    AssignUserToRole: 'Assign User to Role',
    RemoveUserFromRole: 'Remove User from Role',
    ListAllUsers: 'List All Users',
  },
  Validator: {
    Validate: 'Validate',
  },

  //Duplicator - No data and EntitlementNames.cs file in service
  //Tenant - No data and EntitlementNames.cs file in service
};
