import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';


export const CheckEditableRenderer: React.FC<ICellRendererParams> = (params) => {
    const {value, setValue } = params;
    const canEdit: boolean = (params as any).canEdit ?? false;
    return <input className="form-check-input form-control disabled" type="checkbox" checked={value} onChange={(e) => setValue!(e.target.checked)} disabled={!canEdit} />;
}
export const CheckDisabledRenderer: React.FC<ICellRendererParams> = (params) => {
    const {value, setValue } = params;
    const canEdit: boolean = (params as any).canEdit ?? false;
    return <input className="form-check-input form-control disabled" type="checkbox" checked={value} onChange={(e) => setValue!(e.target.checked)} disabled={true} />;
}

export const CheckboxCellStyle = {
    'height': '100%',
    'display': 'flex ',
    'justifyContent': 'center',
    'alignItems': 'center '
};