import { BaseModel } from '@smartaction/common';

export type DateRange = {
  start: Date | undefined;
  end: Date | undefined;
};

export type TwilioValue = {
  phoneNumber: string;
  messageServiceSId: string;
  resourceId: string;
};

export enum ConfigType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  MultiString = 'MultiString',
  Time = 'Time',
  Twilio = 'Twilio',
  Date = 'Date',
  DateRange = 'DateRange',
  DateTime = 'DateTime',
  DayOfWeek = 'DayOfWeek',
  DayOfMonth = 'DayOfMonth',
}

export enum ValueType {
  Unset = 'Unset',
  Sensitive = 'Sensitive',
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  MultiString = 'MultiString',
  Time = 'Time',
  Twilio = 'Twilio',
  Date = 'Date',
  DateRange = 'DateRange',
  DayOfWeek = 'DayOfWeek',
  DayOfMonth = 'DayOfMonth',
}

export enum DayOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export class Config extends BaseModel<Config> {
  originalId: string;
  description: string;
  type: ConfigType;
  isSensitive: boolean;
  devValue: ConfigValue;
  qaValue: ConfigValue;
  uatValue: ConfigValue;
  prodValue: ConfigValue;

  constructor(
    id: string,
    originalId: string,
    name: string,
    description: string,
    type: ConfigType,
    isSensitive: boolean,
    dev?: ConfigValue,
    qa?: ConfigValue,
    uat?: ConfigValue,
    prod?: ConfigValue,
  ) {
    super(name, id);
    this.originalId = originalId;
    this.description = description;
    this.type = type;
    this.isSensitive = isSensitive;
    this.devValue = dev || ConfigValue.unset();
    this.qaValue = qa || ConfigValue.unset();
    this.uatValue = uat || ConfigValue.unset();
    this.prodValue = prod || ConfigValue.unset();
  }

  isDirty(original: Config): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: Config): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): Config {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any) {
    return new Config(
      json.id,
      json.originalId,
      json.name,
      json.description,
      json.type,
      json.isSensitive,
      ConfigValue.fromJson(json.dev),
      ConfigValue.fromJson(json.qa),
      ConfigValue.fromJson(json.uat),
      ConfigValue.fromJson(json.prod),
    );
  }
}

export interface IValue {
  [ValueType.Unset]: undefined;
  [ValueType.Sensitive]: null;
  [ValueType.Boolean]: boolean;
  [ValueType.String]: string;
  [ValueType.MultiString]: string[];
  [ValueType.Number]: number;
  [ValueType.Time]: string;
  [ValueType.Twilio]: TwilioValue;
  [ValueType.Date]: Date;
  [ValueType.DayOfMonth]: number;
  [ValueType.DayOfWeek]: DayOfWeek;
  [ValueType.DateRange]: DateRange;
}

export class ConfigValue {
  type: ValueType;
  value: IValue[ValueType];

  constructor(type: ValueType, value: IValue[ValueType]) {
    this.type = type;
    this.value = value;
  }

  static fromJson(json: any) {
    if (json.type === ConfigType.DateRange) {
      return new ConfigValue(json.type, { start: new Date(json.start), end: new Date(json.end) });
    }
    if (json.type === ConfigType.Date) {
      return new ConfigValue(json.type, new Date(json.value));
    }
    if (json.type === ConfigType.Twilio) {
      return new ConfigValue(json.type, { phoneNumber: json.phoneNumber, resourceId: json.resourceId, messageServiceSId: json.messageServiceSid });
    }
    return new ConfigValue(json.type, json.value);
  }

  static unset() {
    return new ConfigValue(ValueType.Unset, undefined);
  }
}
