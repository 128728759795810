import { IconType } from '@smartaction/styles';
import {
  CLU,
  If,
  Say,
  SendSMS,
  Step,
  StepType,
  GoTo,
  PhoneTransfer,
  CallAPI,
  Hangup,
  DTMFMenu,
  UnknownStep,
  Script,
  CallFunction,
  NumericInput,
  SetValues,
  YesNo,
  TextEntry,
  AddressInput,
  SetLanguage,
  ReceiveMedia,
  SetLocation,
  TriggerAgent,
  WaitForAgent,
  Break,
} from 'internal/models';
import React from 'react';
import {
  TransferStepView,
  IfStepView,
  SayStepView,
  SendSMSStepView,
  CallAPIStepView,
  CLUStepView,
  GoToStepView,
  HangupStepView,
  ScriptStepView,
  DTMFStepView,
  CallFunctionStepView,
  NumericInputStepView,
  TransferStyling,
  IfStyling,
  SayStyling,
  CallAPIStyling,
  CLUStyling,
  GoToStyling,
  HangupStyling,
  DTMFStyling,
  ScriptStyling,
  CallFunctionStyling,
  NumericInputStyling,
  StepView,
  SendSMSStyling,
  SetValuesStyling,
  SetValuesView,
  TextEntryStyling,
  TextEntryStepView,
  YesNoStepView,
  YesNoStyling,
  SetLanguageStep,
  SetLanguageStyling,
  AddressInputStepView,
  AddressInputStyling,
  SetLocationStyling,
  SetLocationStep,
  TriggerAgentStep,
  TriggerAgentStyling,
  WaitForAgentStep,
  WaitForAgentStyling,
  MediaInputStepView,
  MediaInputStyling,
  BreakStepView,
  BreakStyling,
} from './stepTypes';
import { DraggableStep } from './DraggableStep';

type StepProps = {
  step: Step;
  asDraggable?: boolean;
  isContainer?: boolean;
  manipulateStep: (step: Step, action?: string, parentId?: string) => Promise<boolean>;
};

type StepRenders = {
  Normal: (step: Step, manipulateStep: (step: Step) => Promise<boolean>, isContainer?: boolean) => React.ReactElement;
  Draggable: (step: Step, manipulateStep: (step: Step) => Promise<boolean>) => React.ReactElement;
};

const stepViewMap = new Map<StepType, StepRenders>();
stepViewMap.set(StepType.CallAPI, {
  Normal: (s, manipulateStep) => <CallAPIStepView step={s as CallAPI} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => <DraggableStep step={s} styling={CallAPIStyling} manipulateStep={manipulateStep} />,
});

stepViewMap.set(StepType.Hangup, {
  Normal: (s, manipulateStep) => <HangupStepView step={s as Hangup} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => <DraggableStep step={s} styling={HangupStyling} manipulateStep={manipulateStep} />,
});

stepViewMap.set(StepType.Break, {
  Normal: (s, manipulateStep) => <BreakStepView step={s as Break} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => <DraggableStep step={s} styling={BreakStyling} manipulateStep={manipulateStep} />,
});

stepViewMap.set(StepType.If, {
  Normal: (s, manipulateStep) => <IfStepView step={s as If} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => <DraggableStep step={s} styling={IfStyling} manipulateStep={manipulateStep} />,
});

stepViewMap.set(StepType.GoTo, {
  Normal: (s, manipulateStep) => <GoToStepView step={s as GoTo} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => <DraggableStep step={s} styling={GoToStyling} manipulateStep={manipulateStep} />,
});

stepViewMap.set(StepType.PhoneTransfer, {
  Normal: (s, manipulateStep) => <TransferStepView step={s as PhoneTransfer} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => (
    <DraggableStep step={s} styling={TransferStyling} manipulateStep={manipulateStep} />
  ),
});

stepViewMap.set(StepType.Say, {
  Normal: (s, manipulateStep) => <SayStepView step={s as Say} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => <DraggableStep step={s} styling={SayStyling} manipulateStep={manipulateStep} />,
});

stepViewMap.set(StepType.SendSMS, {
  Normal: (s, manipulateStep) => <SendSMSStepView step={s as SendSMS} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => <DraggableStep step={s} styling={SendSMSStyling} manipulateStep={manipulateStep} />,
});

stepViewMap.set(StepType.CLU, {
  Normal: (s, manipulateStep, isContainer) => (
    <CLUStepView step={s as CLU} isContainer={isContainer} manipulateStep={manipulateStep} />
  ),
  Draggable: (s, manipulateStep) => <DraggableStep step={s} styling={CLUStyling} manipulateStep={manipulateStep} />,
});

stepViewMap.set(StepType.DTMFMenu, {
  Normal: (s, manipulateStep) => <DTMFStepView step={s as DTMFMenu} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => <DraggableStep step={s} styling={DTMFStyling} manipulateStep={manipulateStep} />,
});

stepViewMap.set(StepType.Script, {
  Normal: (s, manipulateStep) => <ScriptStepView step={s as Script} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => <DraggableStep step={s} styling={ScriptStyling} manipulateStep={manipulateStep} />,
});

stepViewMap.set(StepType.CallFunction, {
  Normal: (s, manipulateStep) => <CallFunctionStepView step={s as CallFunction} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => (
    <DraggableStep step={s} styling={CallFunctionStyling} manipulateStep={manipulateStep} />
  ),
});

stepViewMap.set(StepType.NumericInput, {
  Normal: (s, manipulateStep) => <NumericInputStepView step={s as NumericInput} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => (
    <DraggableStep step={s} styling={NumericInputStyling} manipulateStep={manipulateStep} />
  ),
});
stepViewMap.set(StepType.SetValues, {
  Normal: (s, manipulateStep) => <SetValuesView step={s as SetValues} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => (
    <DraggableStep step={s} styling={SetValuesStyling} manipulateStep={manipulateStep} />
  ),
});

stepViewMap.set(StepType.YesNo, {
  Normal: (s, manipulateStep) => <YesNoStepView step={s as YesNo} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => <DraggableStep step={s} styling={YesNoStyling} manipulateStep={manipulateStep} />,
});

stepViewMap.set(StepType.TextEntry, {
  Normal: (s, manipulateStep) => <TextEntryStepView step={s as TextEntry} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => (
    <DraggableStep step={s} styling={TextEntryStyling} manipulateStep={manipulateStep} />
  ),
});
stepViewMap.set(StepType.AddressInput, {
  Normal: (s, manipulateStep) => <AddressInputStepView step={s as AddressInput} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => (
    <DraggableStep step={s} styling={AddressInputStyling} manipulateStep={manipulateStep} />
  ),
});
stepViewMap.set(StepType.ReceiveMedia, {
  Normal: (s, manipulateStep) => <MediaInputStepView step={s as ReceiveMedia} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => (
    <DraggableStep step={s} styling={MediaInputStyling} manipulateStep={manipulateStep} />
  ),
});

stepViewMap.set(StepType.SetLanguage, {
  Normal: (s, manipulateStep) => <SetLanguageStep step={s as SetLanguage} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => (
    <DraggableStep step={s} styling={SetLanguageStyling} manipulateStep={manipulateStep} />
  ),
});

stepViewMap.set(StepType.SetLocation, {
  Normal: (s, manipulateStep) => <SetLocationStep step={s as SetLocation} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => (
    <DraggableStep step={s} styling={SetLocationStyling} manipulateStep={manipulateStep} />
  ),
});

stepViewMap.set(StepType.TriggerAgent, {
  Normal: (s, manipulateStep) => <TriggerAgentStep step={s as TriggerAgent} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => (
    <DraggableStep step={s} styling={TriggerAgentStyling} manipulateStep={manipulateStep} />
  ),
});
stepViewMap.set(StepType.WaitForAgent, {
  Normal: (s, manipulateStep) => <WaitForAgentStep step={s as WaitForAgent} manipulateStep={manipulateStep} />,
  Draggable: (s, manipulateStep) => (
    <DraggableStep step={s} styling={WaitForAgentStyling} manipulateStep={manipulateStep} />
  ),
});

const unknownStyling = {
  typeName: 'Unknown',
  color: '#FDAC55',
  icon: IconType.Hippo,
};

export const StepSelector: React.FC<StepProps> = ({ step, asDraggable = false, isContainer, manipulateStep }) => {
  if (stepViewMap.has(step.type)) {
    return asDraggable
      ? stepViewMap.get(step.type)!.Draggable(step, manipulateStep)
      : stepViewMap.get(step.type)!.Normal(step, manipulateStep, isContainer);
  }

  return (
    <StepView step={step} styling={unknownStyling} manipulateStep={manipulateStep}>
      <label>Type: {(step as UnknownStep).receivedType}</label>
    </StepView>
  );
};
