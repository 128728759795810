import { BaseModel } from "@smartaction/common";
import { PublishedSnapshot, UnpublishedSnapshot } from "..";



export class Bot extends BaseModel<Bot> {
    code: string;
    tenantId: string;
    description: string;
    // Whether a published version of this interaction exists.
    isDisabled: boolean;
    isDeleted: boolean;

    /** Use this constructor for building out a collection of Interactions retrieved by the client. */
    constructor(id: string | undefined, tenantId: string, name: string, description: string, code: string, 
        isDisabled: boolean, isDeleted: boolean, unpublished?: UnpublishedSnapshot, published?: PublishedSnapshot) {
        super(name, id);
        this.tenantId = tenantId;
        this.description = description;
        this.code = code;
        this.isDisabled = isDisabled;
        this.isDeleted = isDeleted;
        this.published = published;
        this.unpublished = unpublished;
    }

    published?: PublishedSnapshot;
    unpublished?: UnpublishedSnapshot;

    isDirty(original: Bot): boolean {
        throw new Error("Method not implemented.");
    }
    getDirtyFields(original: Bot): string[] {
        throw new Error("Method not implemented.");
    }
    clone(): Bot {
        var clone = new Bot(this.id, this.tenantId, this.name, this.description, this.code, this.isDisabled, this.isDeleted, this.unpublished, this.published);
        return clone;
    }

    static fromJson(json: any) {
        const bot = new Bot(json.id, json.tenantId, json.name, json.description, json.code, json.isDisabled, json.isDeleted, UnpublishedSnapshot.fromJson(json.unpublishedSnapshot), PublishedSnapshot.fromJson(json.publishedSnapshot));
        return bot;
    }
    
}