
import { GoToBehavior } from "./GoToBehavior";
import { NoActionBehavior } from "./NoActionBehavior";
import { WaitBehavior } from "./WaitBehavior";
import { ResumeBehavior } from "./ResumeBehavior";
import { RunScriptBehavior } from "./RunScriptBehavior";
import { SkipBehavior } from "./SkipBehavior";
import { RetryStepBehavior } from "./RetryStepBehavior";
import { CallFunctionBehavior } from "./CallFunctionBehavior";
import { BehaviorType } from "./BehaviorType";
import { UnknownBehavior } from "./UnknownBehavior";
import { Behavior } from "./Behavior";
import { TalkBehavior } from "./TalkBehavior";

export function BehaviorBuilder(json: any, isFollowUp: boolean = false) : Behavior {
    switch (json.type) {
        case BehaviorType.GoTo: 
            return GoToBehavior.fromJson(json);
        case BehaviorType.RetryStep: 
            return RetryStepBehavior.fromJson(json);
        case BehaviorType.Skip: 
            return SkipBehavior.fromJson(json);
        case BehaviorType.Wait: 
            return WaitBehavior.fromJson(json, ConvertFollowups(json.followUpBehaviors));
        case BehaviorType.RunScript: 
            return RunScriptBehavior.fromJson(json, ConvertFollowups(json.followUpBehaviors));
        case BehaviorType.Resume: 
            return ResumeBehavior.fromJson(json);
        case BehaviorType.NoAction: 
            return NoActionBehavior.fromJson(json);
        case BehaviorType.Talk:
            return TalkBehavior.fromJson(json, ConvertFollowups(json.followUpBehaviors));
        case BehaviorType.CallFunction:
            return CallFunctionBehavior.fromJson(json);
        default:
            return new UnknownBehavior();
    }
}


function ConvertFollowups(json: any, isFollowUp: boolean = false) {
    // No cascading allowed. Behaviors defined as FollowUpBehaviors cannot themselves have followups.
    if (isFollowUp) {
        return [];
    }
    return json && Array.isArray(json)
        ? json.map((fb: any) => BehaviorBuilder(fb, true))
        : [];
}