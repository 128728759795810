import { ListViewer } from '@smartaction/visuals';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LocationsManagement } from './location/LocationsManagement';

export const TenantManagement: React.FC = () => {
  return (
    <div className={'tenant-management'} style={{ overflowX: 'clip' }}>
      <Routes>
        <Route
          path="tenant-management"
          element={
            <ListViewer
              folders={[
                {
                  identifier: 'management',
                  label: 'Management',
                  isEditable: false,
                  items: [
                    {
                      id: `locations`,
                      isEditable: false,
                      label: 'Locations',
                      labelValue: 'Locations',
                      onOpen: {
                        label: 'Locations',
                        renderFunc: () => <LocationsManagement />,
                        dropType: 'locations',
                        closeable: true,
                      },
                    },
                  ],
                },
              ]}
              identifier="locationsList"
              dropTypes={[]}
              openFirstItem={true}
            />
          }
        />
      </Routes>
    </div>
  );
};
