import { Condition } from "../../shared";
import { Policy } from "../Policy";
import { PolicyType } from "../PolicyType";

export class ConditionalPolicy extends Policy {
    conditions: Array<Condition>; 

    constructor(id: string, ownerId: string, conditions: Array<Condition>) {
        super(id, ownerId, PolicyType.Conditional );
        this.conditions = conditions;
    }

    static fromJson(json: any): ConditionalPolicy {
        const conditions = json.conditions && Array.isArray(json.conditions)
            ? json.conditions.map((c: any) => Condition.fromJson(c))
            : [];

        return new ConditionalPolicy(json.id, json.ownerId, conditions);

    }

}