import { Output } from "internal/models";
import { Policy } from "../Policy";
import { PolicyType } from "../PolicyType";

export class ExpirationPolicy extends Policy {
    smsTimeout: number;
    webChatTimeout: number;
    outputs: Output[];

    constructor(id: string, ownerId: string, smsTimeout: number, webChatTimeout: number, outputs:Output[] ) {
        super(id, ownerId, PolicyType.Expiration);
        this.smsTimeout = smsTimeout;
        this.webChatTimeout = webChatTimeout;
        this.outputs = outputs;
    }

    static fromJson(json: any): ExpirationPolicy {
        const outputs = json.outputs && 
            Array.isArray(json.outputs) ? json.outputs.map((p: any) => Output.fromJson(p)) : [];
        return new ExpirationPolicy(json.id, json.ownerId, json.smsTimeout, json.webChatTimeout, outputs);
    }
}