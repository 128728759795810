import { Pointer } from "../../shared";
import { Step } from "../Step";
import { StepType } from "../Type";

export class DefineObject extends Step {
    saveLocation : Pointer;
    assignments: PropertyAssignment[];

    constructor(id: string | undefined, name: string, description: string, tags: string[],
        saveLocation: Pointer, assignments: PropertyAssignment[]) {
        super(id, name, description, StepType.DefineObject, tags);
        this.saveLocation = saveLocation;
        this.assignments = assignments;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): DefineObject {
        const assignments = json.assignments && Array.isArray(json.assignments)
            ? json.assignments.map((p: any) => PropertyAssignment.fromJson(p))
            : [];

        return new DefineObject(json.id, json.name, json.description, json.tags, Pointer.fromJson(json.saveLocation), assignments);
    }
}

export class PropertyAssignment {
    /** The ObjectId of the property as defined on the Object and which we're defining in the ContextItem that points to said Object. */
    propertyId: string;
    /** As in the source of the data we're going to save to the object's property. */
    source: Pointer;

    constructor(parameterId: string, source: Pointer) {
        this.propertyId = parameterId;
        this.source = source; 
    }

    static fromJson(json: any) {
        return new PropertyAssignment(json.propertyId, Pointer.fromJson(json.source));
    }
}
