import { DateTimePointerValueBuilder } from './DateTimePointerValueBuilder';
import { ConfigType } from '../../Config';

export enum PointerType {
  Config = 'Config',
  DirectAssignment = 'DirectAssignment',
  DirectAssignmentDateTime = 'DirectAssignmentDateTime',
  DirectAssignmentList = 'DirectAssignmentList',
  FunctionContext = 'FunctionContext',
  InputStep = 'InputStep',
  Context = 'Context',
  Empty = 'Empty',
  CurrentDateTime = 'CurrentDateTime',
  Location = 'Location',
  Iteration = 'Iteration',
}
export enum PointerControlFilters {
  ListsOnly = 'ListsOnly',
  HideCreateOption = 'HideCreateOption',
  HideStaticContextItems = 'HideStaticContextItems',
  MultiStringOnly = 'MultiStringOnly',
  ShowObjects = 'ShowObjects'
}

export abstract class Pointer {
  type: PointerType;

  constructor(type: PointerType) {
    this.type = type;
  }

  static fromJson(json: any): Pointer {
    switch (json.type) {
      case PointerType.Config:
        return ConfigPointer.fromJson(json);
      case PointerType.DirectAssignment:
        return DirectAssignmentPointer.fromJson(json);
      case PointerType.DirectAssignmentDateTime:
        return DirectAssignmentDateTimePointer.fromJson(json);
      case PointerType.DirectAssignmentList:
        return DirectAssignmentListPointer.fromJson(json);
      case PointerType.Context:
        return ContextPointer.fromJson(json);
      case PointerType.InputStep:
        return new InputStepPointer();
      case PointerType.CurrentDateTime:
        return CurrentDateTimePointer.fromJson(json);
      case PointerType.Location:
        return LocationPointer.fromJson(json);
      case PointerType.Iteration:
        return IterationPointer.fromJson(json);
      case PointerType.Empty:
        return new EmptyPointer();
    }
    throw new Error("Pointer received doesn't match supported types");
  }
}

export class EmptyPointer extends Pointer {
  constructor() {
    super(PointerType.Empty);
  }
}

export class ConfigPointer extends Pointer {
  configId: string;
  specificConfigType?: ConfigType;

  constructor(configId: string, specificConfigType?: ConfigType) {
    super(PointerType.Config);
    this.configId = configId;
    this.specificConfigType = specificConfigType;
  }

  static fromJson(json: any) {
    return new ConfigPointer(json.configId, json.specificConfigType);
  }
}

export class CurrentDateTimePointer extends Pointer {
  currentDateTimeCheck?: string;
  constructor(currentDateTimeCheck?: string) {
    super(PointerType.CurrentDateTime);
    this.currentDateTimeCheck = currentDateTimeCheck;
  }
  static fromJson(json: any) {
    return new CurrentDateTimePointer(json.currentDateTimeCheck);
  }
}

// NOTE: The ContextPointer will need to be extended later to support propertyIds to pull individual fields off of a model instantiated in the context.
// Not having the components that would explain/describe how that works yet, I've left it out to keep it simpler.
export class ContextPointer extends Pointer {
  contextItemId: string;
  propertyId: string;

  constructor(contextItemId: string, propertyId: string) {
    super(PointerType.Context);
    this.contextItemId = contextItemId;
    this.propertyId = propertyId;
  }

  static fromJson(json: any) {
    if (!json) return new EmptyPointer();
    return new ContextPointer(json.contextItemId, json.propertyId);
  }
}

export class DirectAssignmentPointer extends Pointer {
  value: string;

  constructor(value: string) {
    super(PointerType.DirectAssignment);
    this.value = value;
  }

  static fromJson(json: any) {
    return new DirectAssignmentPointer(json.value);
  }
}

export class DirectAssignmentDateTimePointer extends Pointer {
  value: DateTimePointerValue;

  constructor(value: DateTimePointerValue) {
    super(PointerType.DirectAssignmentDateTime);
    this.value = value;
  }

  static fromJson(json: any) {
    let dateTimePointerValue = DateTimePointerValueBuilder(json.value);
    return new DirectAssignmentDateTimePointer(dateTimePointerValue!);
  }
}

export enum DateTimePointerValueType {
  Unknown,
  DateTimeValue = 'DateTimeValue',
  DateValue = 'DateValue',
  TimeValue = 'TimeValue',
  DayOfWeekValue = 'DayOfWeekValue',
  DayOfMonthValue = 'DayOfMonthValue',
  Empty = 'Empty',
}

export abstract class DateTimePointerValue {
  type: DateTimePointerValueType;

  constructor(type: DateTimePointerValueType) {
    this.type = type;
  }

  displayResult(): string {
    return '';
  }

  static fromJson(json: any): DateTimePointerValue {
    switch (json.type) {
      case DateTimePointerValueType.DateTimeValue:
        return DateTimeValue.fromJson(json);
      case DateTimePointerValueType.DateValue:
        return DateValue.fromJson(json);
      case DateTimePointerValueType.TimeValue:
        return TimeValue.fromJson(json);
      case DateTimePointerValueType.DayOfWeekValue:
        return DayOfWeekValue.fromJson(json);
      case DateTimePointerValueType.DayOfMonthValue:
        return DayOfMonthValue.fromJson(json);
      case DateTimePointerValueType.Empty:
        return new EmptyValue();
    }
    throw new Error("Pointer received doesn't match supported types");
  }
}

export class DateTimeValue extends DateTimePointerValue {
  dateTime: string;

  constructor(dateTime: string) {
    super(DateTimePointerValueType.DateTimeValue);

    this.dateTime = dateTime;
  }

  displayResult(): string {
    return this.dateTime;
  }

  static fromJson(json: any) {
    return new DateTimeValue(json.dateTime);
  }
}

export class DateValue extends DateTimePointerValue {
  date: string;

  constructor(date: string) {
    super(DateTimePointerValueType.DateValue);

    this.date = date;
  }

  displayResult(): string {
    return this.date;
  }

  static fromJson(json: any) {
    return new DateValue(json.date);
  }
}

export class TimeValue extends DateTimePointerValue {
  time: string;

  constructor(time: string) {
    super(DateTimePointerValueType.TimeValue);

    this.time = time;
  }

  displayResult(): string {
    return this.time;
  }

  static fromJson(json: any) {
    return new TimeValue(json.time);
  }
}

export enum DayOfWeekType {
  Unset = 'Unset',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export class DayOfWeekValue extends DateTimePointerValue {
  dayOfWeek: DayOfWeekType;

  constructor(dayOfWeek: DayOfWeekType) {
    super(DateTimePointerValueType.DayOfWeekValue);

    this.dayOfWeek = dayOfWeek;
  }

  displayResult(): string {
    return this.dayOfWeek.toString();
  }

  static fromJson(json: any) {
    return new DayOfWeekValue(json.dayOfWeek);
  }
}

export class DayOfMonthValue extends DateTimePointerValue {
  dayOfMonth: Number;

  constructor(dayOfMonth: Number) {
    super(DateTimePointerValueType.DayOfMonthValue);
    this.dayOfMonth = dayOfMonth;
  }

  displayResult(): string {
    return this.dayOfMonth.toString();
  }

  static fromJson(json: any) {
    return new DayOfMonthValue(json.dayOfMonth);
  }
}

export class EmptyValue extends DateTimePointerValue {
  constructor() {
    super(DateTimePointerValueType.Empty);
  }
}

export class DirectAssignmentListPointer extends Pointer {
  values: string[];

  constructor(values: string[]) {
    super(PointerType.DirectAssignmentList);
    this.values = values;
  }

  static fromJson(json: any) {
    return new DirectAssignmentListPointer(json.values);
  }
}

export class InputStepPointer extends Pointer {
  constructor() {
    super(PointerType.InputStep);
  }
}

export class FunctionContextPointer extends Pointer {
  fieldName: string;

  constructor(fieldName: string) {
    super(PointerType.FunctionContext);
    this.fieldName = fieldName;
  }

  static fromJson(json: any) {
    return new FunctionContextPointer(json.fieldName);
  }
}

export class LocationPointer extends Pointer {
  fieldId: string;

  constructor(fieldId: string) {
    super(PointerType.Location);
    this.fieldId = fieldId;
  }

  static fromJson(json: any) {
    return new LocationPointer(json.fieldId);
  }
}

export class IterationPointer extends Pointer {
  propertyId: string;

  constructor(propertyId: string) {
    super(PointerType.Iteration);
    this.propertyId = propertyId;
  }

  static fromJson(json: any) {
    return new IterationPointer(json.propertyId);
  }
}
