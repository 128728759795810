import React, { useEffect, useState } from 'react';

/** This debouncer expects that it can run the action for you, silently, anytime its dependencies change. */
export const useConstantDebouncer = (action: () => void, timeout: number, deps: React.DependencyList) => {
    useEffect(() => {
        const id = setTimeout(action, timeout);
        return () => clearTimeout(id);
    }, [deps]);
}

/** This debouncer expects you to pass it the action to run each time */
export const useDebouncer = (timeout: number) => {
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
    const [action, setAction] = useState<() => void>();

    useEffect(() => {
        if (!action) return;
        const id = setTimeout(() => {
            action();
            setAction(undefined);
        }, timeout);
        setTimeoutId(id);
        return () => {
            clearTimeout(id);
            setTimeoutId(undefined);
        }
    }, [action]);

    const start = (action: () => void) => {
        setAction(() => action);
    };
    const stop = () => { 
        if (timeoutId) { clearTimeout(timeoutId) }
    };
    return { start, stop };
}