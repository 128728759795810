import { Condition, PointerType } from 'internal/models';
import React from 'react';
import { useClient, useSnapshot } from 'contexts';
import { arrayRemove } from '@smartaction/common';
import { usePolicies } from 'contexts/design/PoliciesContext';
import { ConditionalPolicy } from 'internal/models';
import { PolicyModalProps } from '../Types';
import { ConditionsTableAG } from 'ui/views/bots/design/flow/conditions/ConditionsTable-AG';

const targetAllowedTypes = [PointerType.Config, PointerType.Context, PointerType.Iteration];
const sourceAllowedTypes = [
  PointerType.Config,
  PointerType.Context,
  PointerType.DirectAssignment,
  PointerType.DirectAssignmentList,
  PointerType.Iteration
];

export const ConditionalPolicyView: React.FC<PolicyModalProps<ConditionalPolicy>> = ({ policy, disabled }) => {
  const snapshot = useSnapshot();
  const client = useClient('flow').policies;
  const { updatePolicies } = usePolicies();
  const addCondition = async (condition: Condition) => {
    const result = await client.conditionals.addConditionAsync(
      snapshot.snapshot.id,
      policy.ownerId,
      policy.id,
      condition,
    );
    if (result) {
      condition.id = result.data!;
      updatePolicies(() => {
        policy.conditions = [...policy.conditions, condition];
      });
    }
  };

  const replaceCondition = async (condition: Condition) => {
    const result = await client.conditionals.replaceConditionAsync(
      snapshot.snapshot.id,
      policy.ownerId,
      policy.id,
      condition.id,
      condition,
    );
    if (result) {
      updatePolicies(() => {
        const index = policy.conditions.findIndex((c) => c.id === condition.id);
        const newConditions = [...policy.conditions];
        newConditions[index] = condition;
        policy.conditions = newConditions;
      });
    }
  };

  const deleteCondition = async (conditionId: string) => {
    const result = await client.conditionals.deleteConditionAsync(
      snapshot.snapshot.id,
      policy.ownerId,
      policy.id,
      conditionId,
    );
    if (result) {
      updatePolicies(() => {
        const index = policy.conditions.findIndex((c) => c.id === conditionId);
        policy.conditions = arrayRemove(policy.conditions, index);
      });
    }
  };
  return (
    <div style={{ pointerEvents: disabled ? 'none' : 'initial' }} className="decision-branch-editor">
      <label className="mt-2">Conditions</label>
      <ConditionsTableAG
        conditions={policy?.conditions!}
        targetAllowedTypes={targetAllowedTypes}
        sourceAllowedTypes={sourceAllowedTypes}
        onAddCondition={addCondition}
        onUpdateCondition={replaceCondition}
        onDeleteCondition={deleteCondition}
      />
    </div>
  );
};
