import {  CheckboxSimple } from '@smartaction/visuals';
import { ICellRendererParams } from 'ag-grid-community';
import { useFlow, useSnapshot } from 'contexts';
import { CompareCondition, Condition, ConditionType, LengthCompareCondition } from 'internal/models';
import React, {  } from 'react';



type ReverseRendererProps = ICellRendererParams<CompareCondition, boolean> & {
    onUpdateCondition: (condition: Condition) => void;
}

 const checkIfShowLengthCompareTypeCell = (type: ConditionType): boolean => {
    switch (type) {
      case ConditionType.Compare:
      case ConditionType.LengthCompare:
        return true;
      default:
        return false;
    }
  };
  


export const LengthRenderer: React.FC<ReverseRendererProps> = ({colDef, value, data, onUpdateCondition}) => {
    const condition = data as CompareCondition;
    if(!checkIfShowLengthCompareTypeCell(condition.type))
      return (<span>n/a</span>);

    return (
      <CheckboxSimple
        onChange={(isChecked) => { 
          
            if (isChecked) {
                let newLengthCompareCondition = {
                    ...condition,
                    type: ConditionType.LengthCompare
                } as LengthCompareCondition;
                onUpdateCondition(newLengthCompareCondition);
              } else {
                let newCompareCondition = {
                    ...condition,
                    type: ConditionType.Compare
                } as CompareCondition;
                onUpdateCondition(newCompareCondition);
              }
        }}
        value={condition.type === ConditionType.LengthCompare}
        size={26}
        disabled={false}
      />
    );

}
