import {
  DataResponse,
  ServerResponse,
  IObjectClient,
  Methods,
  IFieldClient,
  ValidateResponseActionEnum,
} from 'internal/clients';
import { Obj, FieldType } from 'internal/models';
import { PostError } from 'ui/events';

export class ObjectClient implements IObjectClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
    this.fields = new FieldClient(serviceUrl, methods);
  }

  async getAll(snapshotId: string): Promise<DataResponse<Obj[]>> {
    const url = this.getUrl(snapshotId);
    const response = await this.methods.get(url);

    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} list of Objects`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    if (!Array.isArray(data)) {
      const error = 'Unexpected response when retrieving Objects';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data: data.map((c) => Obj.fromJson(c)),
    };
  }

  async get(snapshotId: string, objectId: string): Promise<DataResponse<Obj>> {
    const url = `${this.getUrl(snapshotId)}/${objectId}`;
    const response = await this.methods.get(url);
    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} Object`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    return {
      success: true,
      data: Obj.fromJson(data),
    };
  }

  async create(snapshotId: string, name: string): Promise<DataResponse<string>> {
    const url = this.getUrl(snapshotId);

    const response = await this.methods.plainBodyRequest(url, 'POST', name);

    return await this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Created} Object`, response);
  }

  async updateName(snapshotId: string, objectId: string, name: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${objectId}/name`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', name);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Object name`, response);
  }

  async updateDescription(snapshotId: string, objectId: string, description: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${objectId}/description`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', description);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Object description`, response);
  }

  async delete(snapshotId: string, objectId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${objectId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Object`, response);
  }

  readonly fields: IFieldClient;

  private getUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/snapshot/${snapshotId}`;
  }
}

class FieldClient implements IFieldClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }
  async create(snapshotId: string, objectId: string, name: string, type: FieldType): Promise<DataResponse<string>> {
    const url = this.getUrl(snapshotId, objectId);
    const request = { name, type };
    const response = await this.methods.post(url, request);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created}  Field on Object`,
      response,
    );
  }
  async updateName(snapshotId: string, objectId: string, fieldId: string, name: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, objectId)}/${fieldId}/name`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', name);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Field name`, response);
  }
  async updateType(snapshotId: string, objectId: string, fieldId: string, type: FieldType): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, objectId)}/${fieldId}/type`;
    const response = await this.methods.put(url, type);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Field type`, response);
  }
  async updateDescription(
    snapshotId: string,
    objectId: string,
    fieldId: string,
    description: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, objectId)}/${fieldId}/description`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', description);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Field description`, response);
  }
  async updateIsList(snapshotId: string, objectId: string, fieldId: string, isList: boolean): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, objectId)}/${fieldId}/isList`;
    const response = await this.methods.put(url, isList);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Field isList`, response);
  }
  async delete(snapshotId: string, objectId: string, fieldId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, objectId)}/${fieldId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Field`, response);
  }

  private getUrl(snapshotId: string, objectId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/snapshot/${snapshotId}/${objectId}/fields`;
  }
}
