import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button } from '@smartaction/visuals';
import React from 'react';
import { Channel, EmptyPointer, EntryPoint, Pointer, PointerType } from '../../../../internal/models';
import { PointerControl, Table } from '../../../components';
import { v4 as uuid } from 'uuid';
import { useClient, useFlow, useSnapshot } from 'contexts';

type SIPHeadersRendererProps = {
  data: Channel;
  moduleId: string;
  entryPoint: EntryPoint;
};

export const SIPHeadersRenderer: React.FC<SIPHeadersRendererProps> = (props) => {
  const client = useClient('flow').modules.entryPoints;
  const { snapshot } = useSnapshot();
  const { updateFlow } = useFlow();
  const { data, moduleId, entryPoint } = props;
  let { id, sipHeaders } = data;

  const getCurrentSipHeader = (id: string) =>
    id && sipHeaders.findIndex((sipHeader: { id: string }) => sipHeader.id === id);

  const handleAddSipHeader = async () => {
    const result = await client.createChannelSipHeader(
      snapshot.id,
      moduleId,
      entryPoint.id,
      id,
      '',
      new EmptyPointer(),
    );
    if (result.success) {
      updateFlow(() => {
        sipHeaders?.push({
          id: `${result.data}`,
          name: '',
          source: new EmptyPointer(),
        });
      });
    }
  };
  const handleDeleteSipHeader = async (headerId: string) => {
    const result = await client.deleteChannelSipHeader(snapshot.id, moduleId, entryPoint.id, id, headerId);
    if (result.success) {
      updateFlow(() => sipHeaders?.splice(getCurrentSipHeader(headerId) as number, 1));
    }
  };

  const handleUpdateSipHeaderName = async (headerId: string, name: string) => {
    const result = await client.updateChannelSipHeaderName(snapshot.id, moduleId, entryPoint.id, id, name, headerId);
    if (result.success) {
      updateFlow(() => (sipHeaders[getCurrentSipHeader(headerId) as number].name = name));
    }
  };
  const handleUpdateSipHeaderSource = async (headerId: string, source: Pointer) => {
    const result = await client.updateChannelSipHeaderSource(
      snapshot.id,
      moduleId,
      entryPoint.id,
      id,
      source,
      headerId,
    );
    if (result.success) {
      updateFlow(() => (sipHeaders[getCurrentSipHeader(headerId) as number].source = source));
    }
  };

  return (
    <React.Fragment>
      <Table
        id="SipHeadersTable"
        headersGroup={['Header name', 'Pointer Control']}
        rows={
          sipHeaders.map(({ name, id, source }) => ({
            id: uuid(),
            cells: [
              {
                type: 'input',
                id: uuid(),
                value: name,
                onChange: ({ value }) => handleUpdateSipHeaderName(id, `${value}`),
              },
              {
                type: 'customNode',
                id: 'pointer',
                customNode: (
                  <PointerControl
                    types={[PointerType.Context]}
                    pointer={source}
                    update={(p) => handleUpdateSipHeaderSource(id, p)}
                  />
                ),
              },
              {
                type: 'customNode',
                id: 'delete',
                customNode: (
                  <Button type={VisualCategory.Danger} action={() => handleDeleteSipHeader(id)}>
                    <Icon type={IconType.Delete} />
                  </Button>
                ),
              },
            ],
          })) || []
        }
        columnsSize={['md', 'md']}
        footer={
          <Button type={VisualCategory.Primary} action={handleAddSipHeader}>
            Add
          </Button>
        }
      />
    </React.Fragment>
  );
};
