import { ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Methods } from 'internal/clients/Methods';
import { IScriptClient } from './IScriptClient';

export class ScriptClient implements IScriptClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private getUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }

  async setScript(snapshotId: string, stepId: string, script: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/code`;
    const response = await this.methods.put(url, script);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Script for Step`, response);
  }
}
