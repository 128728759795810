import {
  DragApproach,
  DroppableData,
  SourceIdentifier,
  Stack,
  usePortalContextMenu,
} from '@smartaction/visuals';
import { useBots } from 'contexts';
import { CLU, Step, StepType } from 'internal/models';
import React, { useRef } from 'react';
import { StepSelector } from './StepSelector';
import { sessionStorageCopiedItemKey, StepActionsEnum } from './stepTypes';
import { Color, Icon, IconType, VisualCategory } from '@smartaction/styles';
import { PostSuccess } from 'ui/events';

type StepManagerProps = {
  identifier: string;
  steps: Step[];
  reorderSteps: (stepIds: string[]) => Promise<boolean>;
  deleteSteps: (steps: Step[]) => Promise<boolean>;
  addSteps: (
    steps: Step[],
    fromSource?: DroppableData,
    index?: number,
    isPaste?: boolean,
    targetId?: string,
  ) => Promise<boolean>;
  stepsUpdated: (steps: Step[]) => void;
  allowedTypes: StepType[];
  hasTrash: boolean;
  sourceIdentifier: SourceIdentifier;
  isContainer: boolean;
};

export const StepManager: React.FC<StepManagerProps> = ({
  identifier,
  steps,
  reorderSteps,
  deleteSteps,
  addSteps,
  stepsUpdated,
  allowedTypes,
  hasTrash,
  sourceIdentifier,
  isContainer,
}) => {
  const { isReadOnlyBot } = useBots();
  const ref = useRef<HTMLDivElement>(null);

  const contextMenuButtons = [
    {
      label: (
        <span className={Color(VisualCategory.Primary)}>
          <Icon type={IconType.Paste} /> Paste Step
        </span>
      ),
      action: async () => {
        const copiedStep = sessionStorage.getItem(sessionStorageCopiedItemKey);
        copiedStep &&
          (await addSteps([JSON.parse(copiedStep)], {} as DroppableData, undefined, true).then(() => {
            PostSuccess('Step Pasted');
            return sessionStorage.removeItem(sessionStorageCopiedItemKey);
          }));
      },
      isDisabled: isReadOnlyBot || !sessionStorage.getItem(sessionStorageCopiedItemKey),
    },
  ];
  const contextMenu = usePortalContextMenu(ref, contextMenuButtons);
  const render = (step: Step) => {
    return {
      type: step.type,
      children: (
        <StepSelector
          key={step.id}
          step={step}
          isContainer={isContainer}
          manipulateStep={(step, action, targetId) => {
            if (action === StepActionsEnum.Paste) {
              return addSteps([step], {} as DroppableData, undefined, true, targetId);
            } else {
              return deleteSteps([step]);
            }
          }}
        />
      ),
      dragChildren: (
        <StepSelector
          key={step.id}
          step={step}
          asDraggable={true}
          isContainer={isContainer}
          manipulateStep={(step, action, targetId) => {
            if (action === StepActionsEnum.Paste) {
              return addSteps([step], {} as DroppableData, undefined, true, targetId);
            } else {
              return deleteSteps([step]);
            }
          }}
        />
      ),
    };
  };

  return (
    // Padding added to make space for the trash can
    // Use flexGrow with the div to allow it to take the remaining space of the flexbox that it is nested in
    <div ref={ref} style={{ paddingBottom: '50px', flexGrow: '1', height: '100%' }}>
      <Stack<Step>
        identifier={identifier}
        allowedTypes={allowedTypes}
        items={steps}
        render={render}
        dragApproach={DragApproach.DragHandle}
        tryAddItems={addSteps}
        tryRemoveItems={deleteSteps}
        tryMoveItems={reorderSteps}
        itemsUpdated={stepsUpdated}
        hasTrash={!isReadOnlyBot && hasTrash}
        isDisabled={isReadOnlyBot}
        sourceIdentifier={sourceIdentifier}
        allowDrop={(x) => {
          if (x.type === 'CLU') {
            let step = x.item as CLU;
            if (isContainer) return true;
            else return step.intents.length <= 1;
          }
          return true;
        }}
      />
      {!steps.length && contextMenu}
    </div>
  );
};
