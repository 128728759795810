import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import {
  Button,
  Field,
  PortalModal,
  useFromModal,
} from '@smartaction/visuals';
import { useBots } from 'contexts';
import { useCheckTenantAccess } from 'contexts/AccessContext';
import { useClient } from 'contexts/ClientContext';
import { useSnapshot } from 'contexts/SnapshotContext';
import { EntitlementNames } from 'EntitlementNames';
import React, { useEffect, useState } from 'react';

type AuthModalProps = {
    id: string;
};

export const ApiCredentials: React.FC<AuthModalProps> = ({id}) => {
  const client = useClient('resources');
  const snapshot = useSnapshot();
  const modal = useFromModal();

  const [isSaving, setIsSaving] = useState(false);
  const [apiKey, setApiKey] = useState<string>('');

  const {isReadOnlyBot} = useBots();
  const canManage = useCheckTenantAccess(EntitlementNames.Resources.Manage);
  const isReadOnly = isReadOnlyBot || !canManage;

  const header = {
    type: VisualCategory.Primary,
    content: <>Update API Key</>,
  };

  const content = (<>
      {!isReadOnly && (
        <div className="alert alert-warning">
          Saving changes will change the credentials for any deployed versions of this snapshot.
        </div>
      )}

      <Field inputId={'apiKey'} label="Account SID" name="Account SID">
        <input
          placeholder='***************'
          id={'apiKey'}
          className="form-control"
          type="text"
          disabled={isReadOnly}
          value={apiKey}
          onChange={ev => setApiKey(ev.target.value)}
        />
      </Field>
    </>
  );

  const onSave = async () => {
    setIsSaving(true);
    if (apiKey) {
      const result = await client.address.updateCredentials(snapshot.snapshot.id, id, apiKey);
      if (result.success) {
        modal.forceClose();
      } else {
        setIsSaving(false);
      }
    }
  };

  const buttons = (
    <React.Fragment>
      <Button className={'me-3'} action={onSave} isDisabled={isSaving} type={VisualCategory.Primary}>
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
      <Button action={() => modal.forceClose()} type={VisualCategory.Secondary}>
        Cancel
      </Button>
    </React.Fragment>
  );

  return <PortalModal header={header} content={content} buttons={isReadOnly ? <></> : buttons} />;
};
