import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { ICallFunctionClient } from './ICallFunctionClient';

export class CallFunctionClient implements ICallFunctionClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private url(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }

  async setFunctionId(snapshotId: string, stepId: string, functionId: string): Promise<ServerResponse> {
    const url = `${this.url(snapshotId, stepId)}/function`;
    const response = await this.methods.put(url, functionId);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Updated} Function Id to Call`,
      response,
    );
  }
}
