import { DataResponse, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Methods } from 'internal/clients/Methods';
import { IPhoneTransferClient } from './IPhoneTransferClient';
import { EmptyPointer, Pointer } from 'internal/models';

export class PhoneTransferClient implements IPhoneTransferClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async setNumber(snapshotId: string, stepId: string, number: Pointer): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/number`;

    const response = await this.methods.put(url, number);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Number for Transfer Step`,
      response,
    );
  }

  async setIsSIP(snapshotId: string, stepId: string, isSIP: boolean): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/isSIP`;

    const response = await this.methods.put(url, isSIP);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} isSip for Transfer Step`,
      response,
    );
  }

  async addHeader(snapshotId: string, stepId: string, name: string): Promise<DataResponse<string>> {
    const url = `${this.getUrl(snapshotId, stepId)}/headers`;
    const request = {
      name: name,
      source: new EmptyPointer(),
    };
    const response = await this.methods.post(url, request);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} header for Transfer Step`,
      response,
    );
  }

  async removeHeader(snapshotId: string, stepId: string, headerId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/headers/${headerId}`;

    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Deleted} header from Transfer Step`,
      response,
    );
  }

  async setHeaderSource(
    snapshotId: string,
    stepId: string,
    headerId: string,
    source: Pointer,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/headers/${headerId}/source`;

    const response = await this.methods.put(url, source);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} header source for Transfer Step`,
      response,
    );
  }

  async setHeaderName(snapshotId: string, stepId: string, headerId: string, name: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/headers/${headerId}/name`;

    const response = await this.methods.put(url, name);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} new header name for Transfer Step`,
      response,
    );
  }

  private getUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }
}
