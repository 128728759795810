import { useSnapshot } from '../SnapshotContext';
import React, { useEffect, useState } from 'react';
import { useClient } from '../ClientContext';
import { Policy } from 'internal/models';
import { useFlow } from './FlowContext';

const PoliciesContext = React.createContext<PoliciesContextType | undefined>(undefined);

export type PoliciesContextType = {
  getById: (id: string) => Policy | undefined;
  getPoliciesByOwnerId: (id: string) => Policy[];
  list: Policy[];
  refreshFromServer: () => void;
  updatePolicies: (func: (policies: Policy[]) => void) => void;
};

type PoliciesProviderProps = {
  children: React.ReactNode
}

export const PoliciesProvider: React.FC<PoliciesProviderProps> = ({ children }) => {
  // As noted above, PoliciesProvider 'owns' poplicies, but it gets the list initially when Flow loads up.
  const snapshot = useSnapshot();
  // We're manually replacing the array on flow because Flow 'knows' of the existence of Policies and I don't like the idea of its list being out of sync
  // with the copy we keep here, BUT we're not doing anything with it. This is just to not have any confusion about why flow.policies has policies 1,2,
  // but usePolicies has 1,2,3,4.
  const { flow } = useFlow();
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [policyMap, setPolicyMap] = useState<Map<string, Policy>>(new Map<string, Policy>());
  const [policyByOwnerMap, setPolicyByOwnerMap] = useState<Map<string, Policy[]>>(new Map<string, Policy[]>());
  const client = useClient('flow').policies;

  useEffect(() => {
    refresh();
  }, [flow]);

  const update = (func: (policies: Policy[]) => void) => {
    func(policies);
    refresh();
  };
  const refresh = () => {
    client.getPoliciesBySnapshotAsync(snapshot.snapshot.id).then((policies) => {
      const result = policies.success ? policies.data! : [];
      processPolicies(result);
    });
  };

  const processPolicies = (policies: Policy[]) => {
    const newArray = [...policies];
    setPolicies(newArray);
    // again, this is just to keep it up to date. We're intentionally not using updateFlow because we're *not* going to cause a refresh.
    // PoliciesProvider owns the policies, not Flow. Refreshing everything under Flow due to a change in Policies would be excessive.
    flow.policies = newArray;

    const map = new Map<string, Policy>();

    for (let policy of policies) {
      map.set(policy.id, policy);
    }

    let mapByOwner = groupByOwnerId(policies, (x) => x.ownerId);
    setPolicyMap(map);
    setPolicyByOwnerMap(mapByOwner);
  };

  const byPolicyId = (id: string) => {
    return policyMap.get(id);
  };

  const byOwnerId = (id: string) => {
    let foundPolicies = policyByOwnerMap.get(id);
    if (foundPolicies) {
      return foundPolicies;
    } else return [];
  };

  function groupByOwnerId(array: Policy[], grouper: (item: Policy) => string) {
    return array.reduce((store, item) => {
      const key = grouper(item);
      if (!store.has(key)) {
        store.set(key, [item]);
      } else {
        store.get(key)!.push(item);
      }
      return store;
    }, new Map<string, Policy[]>());
  }

  return (
    <PoliciesContext.Provider
      value={{
        list: policies,
        updatePolicies: update,
        refreshFromServer: refresh,
        getById: byPolicyId,
        getPoliciesByOwnerId: byOwnerId,
      }}
    >
      {children}
    </PoliciesContext.Provider>
  );
};

export const usePolicies = () => {
  const context = React.useContext(PoliciesContext);

  if (context === undefined) {
    throw new Error('usePolicies must be used within a PoliciesProvider');
  }

  return context;
};
