import {
  ConfigPointer,
  ContextPointer,
  CurrentDateTimePointer,
  DirectAssignmentDateTimePointer,
  DirectAssignmentListPointer,
  DirectAssignmentPointer,
  EmptyPointer,
  IterationPointer,
  LocationPointer,
  Pointer,
  PointerControlFilters,
  PointerType,
} from 'internal/models';
import React, { useEffect, useMemo, useState } from 'react';
import { ContextPointerEditor } from './ContextPointerEditor';
import { ConfigPointerEditor } from './ConfigPointerEditor';
import { DirectAssignmentPointerEditor } from './DirectAssignmentPointerEditor';
import { DirectAssignmentListPointerEditor } from './DirectAssignmentListPointerEditor';
import { Select } from '@smartaction/visuals';
import { DateTimePointerEditor } from './DateTimePointerEditor';
import { DirectAssignmentDateTimePointerEditor } from './DirectAssignmentDateTimePointerEditor';
import { useModule } from 'contexts';
import { LocationPointerEditor } from './LocationPointerEditor';
import { IterationPointerEditor } from './IterationPointerEditor';
import { extractIDsFromQueryParams } from 'ui/utils';

type PointerControlProps = {
  update: (pointer: Pointer) => void;
  types: PointerType[];
  pointer: Pointer;
  change?: (pointer: string) => void;
  pointerFilters?: PointerControlFilters[];
  isDisabled?: boolean;
};

type PointerTypeOption = {
  label: string;
  value: PointerType;
};

export const PointerControl: React.FC<PointerControlProps> = ({
  update,
  types,
  change,
  pointer,
  pointerFilters,
  isDisabled = false,
}) => {
  if (types.some((t) => t === PointerType.Empty)) {
    throw new Error('You should only include the pointer types you actually need to use; Empty is always an option.');
  }
  const [selectedPointerType, setSelectedPointerType] = useState<PointerType>(pointer.type);
  const [isForEachBlock, setIsForEachBlock] = useState(false);
  const pointerOptions = useMemo(() => BuildOptions(types, isForEachBlock), [types, isForEachBlock]);
  const module = useModule(extractIDsFromQueryParams().moduleID);

  useEffect(() => {
    setIsForEachBlock(
      module?.blocks?.find((block) => block.id === extractIDsFromQueryParams().nodeID)?.listToIterate.type !==
        PointerType.Empty,
    );
  }, [window.location.search]); //Check if current block is ForEach type

  useEffect(() => {
    setSelectedPointerType(selectedPointerType);
  }, [update]);
  const onChange = (newVal: string) => {
    if (newVal === PointerType.Empty) {
      update(new EmptyPointer());
      setSelectedPointerType(PointerType.Empty);
      return;
    }

    change && change(newVal);

    setSelectedPointerType(newVal as PointerType);
  };

  const save = (pointer?: Pointer) => {
    update(pointer ?? new EmptyPointer());
  };

  if (types.length === 1) {
    return (
      <div className="pointer-control">
        <SelectedPointerTypeView
          isDisabled={isDisabled}
          type={types[0]}
          current={pointer}
          update={save}
          pointerFilters={pointerFilters}
        />
      </div>
    );
  }

  return (
    <div className="pointer-control">
      <Select
        disabled={isDisabled}
        value={selectedPointerType}
        options={pointerOptions}
        onChange={onChange}
        id={'pointer-type'}
        className={`${selectedPointerType}-pointer-type`}
      />
      {selectedPointerType !== PointerType.Empty && (
        <SelectedPointerTypeView
          isDisabled={isDisabled}
          type={selectedPointerType}
          current={pointer}
          update={save}
          pointerFilters={pointerFilters}
        />
      )}
    </div>
  );
};

type SelectedPointerTypeViewProps = {
  type: PointerType;
  current?: Pointer;
  update: (pointer: Pointer) => void;
  pointerFilters?: PointerControlFilters[];
  isDisabled: boolean;
};

const SelectedPointerTypeView: React.FC<SelectedPointerTypeViewProps> = ({
  type,
  current,
  update,
  pointerFilters,
  isDisabled,
}) => {
  switch (type) {
    case PointerType.Config:
      return (
        <ConfigPointerEditor
          pointer={current as ConfigPointer}
          update={update}
          pointerFilters={pointerFilters}
          isDisabled={isDisabled}
        />
      );
    case PointerType.Context:
      return (
        <ContextPointerEditor
          pointer={current as ContextPointer}
          update={update}
          pointerFilters={pointerFilters}
          isDisabled={isDisabled}
        />
      );
    case PointerType.CurrentDateTime:
      return (
        <DateTimePointerEditor pointer={current as CurrentDateTimePointer} update={update} isDisabled={isDisabled} />
      );
    case PointerType.DirectAssignment:
      return (
        <DirectAssignmentPointerEditor
          pointer={current as DirectAssignmentPointer}
          update={update}
          isDisabled={isDisabled}
        />
      );
    case PointerType.DirectAssignmentDateTime:
      return (
        <DirectAssignmentDateTimePointerEditor
          pointer={current as DirectAssignmentDateTimePointer}
          update={update}
          isDisabled={isDisabled}
        />
      );
    case PointerType.DirectAssignmentList:
      return (
        <DirectAssignmentListPointerEditor
          pointer={current as DirectAssignmentListPointer}
          update={update}
          isDisabled={isDisabled}
        />
      );
    case PointerType.Location:
      return <LocationPointerEditor pointer={current as LocationPointer} update={update} isDisabled={isDisabled} />;
    case PointerType.Iteration:
      return <IterationPointerEditor pointer={current as IterationPointer} update={update} isDisabled={isDisabled} />;
  }

  return <React.Fragment />;
};

function BuildOptions(types: PointerType[], isForEachBlock?: boolean): PointerTypeOption[] {
  return [
    EmptyPointerOption,
    ...types
      .filter((t) => t !== PointerType.Empty)
      .filter((t) => (isForEachBlock ? t : t !== PointerType.Iteration))
      .map((t) => {
        return { label: names.get(t)!, value: t };
      }),
  ];
}

const names = new Map<PointerType, string>();
names.set(PointerType.Empty, 'None Selected');
names.set(PointerType.Config, PointerType.Config);
names.set(PointerType.Context, PointerType.Context);
names.set(PointerType.Location, PointerType.Location);
names.set(PointerType.Iteration, 'Current Iteration');
names.set(PointerType.FunctionContext, 'Function Context');
names.set(PointerType.DirectAssignmentDateTime, 'Direct Assignment DateTime');
names.set(PointerType.DirectAssignment, 'Direct Assignment');
names.set(PointerType.DirectAssignmentList, 'Direct Assignment List');
names.set(PointerType.CurrentDateTime, 'Date/Time');

const EmptyPointerOption = { label: names.get(PointerType.Empty)!, value: PointerType.Empty };
