export class ConversationIntent  {
    name: string;
    intentStatus: IntentStatus;

    constructor(name: string, intentStatus: IntentStatus) {
        this.name = name;
        this.intentStatus = intentStatus;
    }

    static fromJson(json: any) {
        const status : IntentStatus = IntentStatus[IntentStatus[json.value] as keyof typeof IntentStatus];

        return new ConversationIntent(json.key, status);
    }
}

export enum IntentStatus
{
    Unknown,
    Started = 'Started',
    Completed = 'Completed',
    Canceled = 'Canceled',
    Resumed = 'Resumed',
    Failed = 'Failed',
    Transferred = 'Transferred'
}