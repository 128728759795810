import React, { useState } from 'react';
import { Button, Field, PortalConfirm, useId, useFromModal } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';

type CreateModuleModalProps = {
  save: (name: string, description: string) => void;
};

const CreateModuleModal: React.FC<CreateModuleModalProps> = ({ save }) => {
  const nameFieldId = useId('moduleName');
  const descFieldId = useId('moduleDescription');
  const [name, setName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [description, setDescription] = useState('');
  const modal = useFromModal();
  const header = {
    type: VisualCategory.Primary,
    content: (
      <React.Fragment>
        <Icon type={IconType.Data} /> Create New Module
      </React.Fragment>
    ),
  };

  const content = (
    <React.Fragment>
      <Field inputId={nameFieldId} label="Name" name="name" groupClass="col">
        <input
          id={nameFieldId}
          className="form-control"
          type="text"
          value={name}
          onChange={(evt) => setName(evt.target.value)}
        />
      </Field>
      <Field inputId={descFieldId} label="Description" name="description" groupClass="col">
        <input
          id={descFieldId}
          className="form-control"
          type="text"
          value={description}
          onChange={(evt) => setDescription(evt.target.value)}
        />
      </Field>
    </React.Fragment>
  );

  const saveClick = () => {
    setIsSaving(true);
    save(name, description);
  };

  return (
    <PortalConfirm
      header={header}
      content={content}
      confirmButton={{
        label: 'Create',
        type: VisualCategory.Primary,
        clicked: saveClick,
      }}
      cancelButton={{
        label: 'Cancel',
        type: VisualCategory.Light,
        clicked: modal.forceClose,
      }}
    />
  );
};
export default CreateModuleModal