import { BaseModel } from '@smartaction/common';

export enum ContextType {
  Unset = 'Unset',
  String = 'String',
  Integer = 'Integer',
  Decimal = 'Decimal',
  Boolean = 'Boolean',
  Time = 'Time',
  Date = 'Date',
  DateRange = 'DateRange',
  DateTime = 'DateTime',
  DayOfWeek = 'DayOfWeek',
  Currency = 'Currency',
  CreditCard = 'CreditCard',
  Address = 'Address',
  Object = 'Object',
  MediaImage = 'MediaImage',
  MediaAudio = 'MediaAudio',
  MediaVideo = 'MediaVideo',
  MediaDocument = 'MediaDocument',
}

export class ContextItem extends BaseModel<ContextItem> {
    originalId: string;
    description: string;
    isSensitive: boolean;
    isReporting: boolean;
    isPublic: boolean;
    isList: boolean;
    type: ContextType;
    typeId?: string;
    isStatic: boolean;
    order: number;
    category: string;

    constructor(id: string, originalId: string, name: string, description: string, isSensitive: boolean, isReported: boolean, isList: boolean, isPublic: boolean, order:number,
        category: string, type: ContextType, typeId?: string, isStatic: boolean = false) {
        super(name, id);
        this.originalId = originalId;
        this.description = description;
        this.isSensitive = isSensitive;
        this.isReporting = isReported;
        this.isPublic = isPublic;
        this.isList = isList;
        this.type = type;
        this.typeId = typeId;
        this.isStatic = isStatic;
        this.order = order;
        this.category = category;
    }

  isDirty(original: ContextItem): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: ContextItem): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): ContextItem {
    throw new Error('Method not implemented.');
  }

    static fromJson(json: any) {
        return new ContextItem(json.id, json.originalId, json.name, json.description, json.isSensitive, json.isReported, json.isList, json.isPublic, json.order, json.category, json.type, json.typeId, json.isStatic);
    }
}

// Unset and Object are not in here, as the latter isn't directly selected, and the former isn't really an option.
export const SelectableContextTypes = [
  ContextType.String,
  ContextType.Integer,
  ContextType.Decimal,
  ContextType.Boolean,
  ContextType.Time,
  ContextType.Date,
  ContextType.DateRange,
  ContextType.DateTime,
  ContextType.DayOfWeek,
  ContextType.Currency,
  ContextType.CreditCard,
  ContextType.Address,
  ContextType.MediaImage,
  ContextType.MediaAudio,
  ContextType.MediaVideo,
  ContextType.MediaDocument,
  ContextType.Object,
];

const nameMap = new Map<ContextType, string>();
for (let type of SelectableContextTypes) {
  nameMap.set(type, type);
}

nameMap.set(ContextType.DateTime, 'Date and Time');
nameMap.set(ContextType.CreditCard, 'Credit Card');
nameMap.set(ContextType.DayOfWeek, 'Day of Week');
nameMap.set(ContextType.MediaImage, 'Media Image');
nameMap.set(ContextType.MediaAudio, 'Media Audio');
nameMap.set(ContextType.MediaVideo, 'Media Video');
nameMap.set(ContextType.MediaDocument, 'Media Document');

export const ContextTypeNames = nameMap;
