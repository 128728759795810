import { BehaviorType } from "./BehaviorType";
import { Behavior } from "./Behavior";


export class SkipBehavior extends Behavior {

    constructor() {
        super(BehaviorType.Skip);
    }

    static fromJson(json: any): SkipBehavior {
        return new SkipBehavior();
    }

}
