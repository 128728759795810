import React from 'react';
import { Ask, IHasOutputs, Output, Say } from 'internal/models';
import { OutputsView } from 'ui/components/design/OutputsView';
import { useClient, useFlow, useSnapshot } from 'contexts';

type StepOutputsViewProps = {
  typeName: string;
  step: IHasOutputs;
};

export const StepOutputsView: React.FC<StepOutputsViewProps> = ({ typeName, step }) => {
  const { snapshot } = useSnapshot();
  const client = useClient('flow');
  const { updateFlow } = useFlow();
  if (!step.outputs) {
    step.outputs = (step as Ask).questions || (step as Say).statements;
  }

  const update = async (newOutputs: Output[]) => {
    const response = await client.steps.setOutputs(snapshot.id, step.id, newOutputs);

    if (response.success) {
      updateFlow(() => {
        step.outputs = newOutputs;
      });
    }
  };

  return <OutputsView update={update} typeName={typeName} outputs={step.outputs} />;
};
