import { useState } from 'react';
import { useClient, useFlow, useSnapshot } from 'contexts';
import { NumericInput, Pointer } from 'internal/models';
import { TableRow } from 'ui/components';
import { Option } from 'ui/types';
import { SingleValue } from 'react-select';

export interface UseTableLogicRes {
  isLoading: boolean;
  rows: TableRow[];
  onDeleteRow: (id: string, rowIndex: number) => void;
  onAddNewRow: () => void;
}

interface UseTableLogicProps {
  step: NumericInput;
}


export interface UseNumericInputsLogicRes {
  isLoading: boolean;
  onMinCharChange: (v: string) => void;
  onMaxCharChange: (v: string) => void;
  onAllowAsterisksChange: (v: boolean) => void;
  onPoundChange: (v: boolean) => void;
  onPointerChange: (pointer: Pointer) => Promise<void>;
  onIntentChange: (option: SingleValue<Option>) => Promise<void>;
  onExcludeUniversalModelChange: (v: boolean) => void;
}
export const useNumericInputsLogic = ({ step }: UseTableLogicProps): UseNumericInputsLogicRes => {
  const client = useClient('flow');
  const { snapshot } = useSnapshot();
  const { updateFlow } = useFlow();
  const [isLoading, setIsLoading] = useState(false);
  const onPointerChange = async (pointer: Pointer) => {
    setIsLoading(true);
    const response = await client.steps.setSaveLocation(snapshot.id, step.id, pointer);
    if (response.success) {
      updateFlow(() => {
        step.saveLocation = pointer;
      });
    }
    setIsLoading(false);
  };
  const onMinCharChange: UseNumericInputsLogicRes['onMinCharChange'] = async (v) => {
    setIsLoading(true);
    const nonNegativeValue = Math.max(0, parseInt(v, 10) || 0);
    const response = await client.steps.numericInputs.setMinLength(snapshot.id, step.id, nonNegativeValue);
    if (response.success) {
      updateFlow(() => {
        step.minLength = +nonNegativeValue;
      });
    }
    setIsLoading(false);
  };
  const onMaxCharChange: UseNumericInputsLogicRes['onMaxCharChange'] = async (v) => {
    setIsLoading(true);
    const nonNegativeValue = Math.max(0, parseInt(v, 10) || 0);
    const response = await client.steps.numericInputs.setMaxLength(snapshot.id, step.id, +nonNegativeValue);
    if (response.success) {
      updateFlow(() => {
        step.maxLength = +nonNegativeValue;
      });
    }
    setIsLoading(false);
  };
  const onAllowAsterisksChange: UseNumericInputsLogicRes['onAllowAsterisksChange'] = async (v) => {
    setIsLoading(true);
    const response = await client.steps.numericInputs.setAllowAsterisks(snapshot.id, step.id, v);
    if (response.success) {
      updateFlow(() => {
        step.allowAsterisks = v;
      });
    }
    setIsLoading(false);
  };
  const onPoundChange: UseNumericInputsLogicRes['onPoundChange'] = async (v) => {
    setIsLoading(true);
    const response = await client.steps.numericInputs.setPoundToEnd(snapshot.id, step.id, v);
    if (response.success) {
      updateFlow(() => {
        step.poundToEnd = v;
      });
    }
    setIsLoading(false);
  };
  const onIntentChange = async (option: SingleValue<Option>) => {
    const alternateIntent = {
      alternateIntent: option?.label || '',
      alternateResourceId: option?.value || '',
    };
    setIsLoading(true);
    if (option?.value) {
      const response = await client.steps.numericInputs.updateAlternateIntent(snapshot.id, step.id, alternateIntent);
      if (response.success) {
        updateFlow(() => {
          step.alternateIntent = alternateIntent.alternateIntent;
          step.alternateResourceId = alternateIntent.alternateResourceId;
        });
      }
    } else {
      const response = await client.steps.numericInputs.deleteAlternateIntent(snapshot.id, step.id);
      if (response.success) {
        updateFlow(() => {
          step.alternateIntent = '';
          step.alternateResourceId = '';
        });
      }
    }

    setIsLoading(false);
  };
  
  const onExcludeUniversalModelChange: UseNumericInputsLogicRes['onExcludeUniversalModelChange'] = async (v) => {
    setIsLoading(true);
    const response = await client.steps.numericInputs.setExcludeUniversalModel(snapshot.id, step.id, v);
    if (response.success) {
      updateFlow(() => {
        step.excludeUniversalModel = v;
      });
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    onMinCharChange,
    onMaxCharChange,
    onAllowAsterisksChange,
    onPoundChange,
    onPointerChange,
    onIntentChange,
    onExcludeUniversalModelChange
  };
};
