import { CustomFieldValue } from './CustomFieldValue';

export class Location {
  id: string;
  originalId: string;
  locationDefinitionId: string;
  ownerId: string;
  tenantId: string;
  displayName: string;
  pronunciations: Map<string, string>;
  note: string;
  customerId: string;
  address: string;
  timeZone: string;
  phoneNumber: string;
  officeHours: Map<string, TimeRange[]>;
  customData: Map<string, CustomFieldValue>;

  constructor(
    id: string,
    originalId: string,
    locationDefinitionId: string,
    ownerId: string,
    tenantId: string,
    displayName: string,
    pronunciations: Map<string, string>,
    note: string,
    customerId: string,
    address: string,
    timeZone: string,
    phoneNumber: string,
    officeHours: Map<string, TimeRange[]>,
    customData: Map<string, CustomFieldValue>,
  ) {
    this.id = id;
    this.originalId = originalId;
    this.locationDefinitionId = locationDefinitionId;
    this.ownerId = ownerId;
    this.tenantId = tenantId;
    this.displayName = displayName;
    this.pronunciations = pronunciations;
    this.note = note;
    this.customerId = customerId;
    this.address = address;
    this.timeZone = timeZone;
    this.phoneNumber = phoneNumber;
    this.officeHours = officeHours;
    this.customData = customData;
  }

  isDirty(original: Location): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: Location): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): Location {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any) {
    const parsedOfficeHrs = new Map<string, TimeRange[]>();
    Object.entries<TimeRange[]>(json.officeHours).forEach(([key, value]) => {
      parsedOfficeHrs.set(key, value);
    });

    const customData = new Map<string, CustomFieldValue>();
    
    Object.entries<CustomFieldValue>(json.customData).forEach(([key, value]) => {
      let locationValue = CustomFieldValue.fromJson(value);
      customData.set(key, locationValue!);
    });

    
    let pronunciations = new Map<string, string>();

    if (json.pronunciations) {
      for(let [key, value] of Object.entries(json.pronunciations)) {
        pronunciations.set(key, value?.toString() ?? '');
      }
    }

    return new Location(
      json.id,
      json.originalId,
      json.locationDefinitionId,
      json.ownerId,
      json.tenantId,
      json.displayName,
      pronunciations,
      json.note,
      json.customerId,
      json.address,
      json.timeZone,
      json.phoneNumber,
      parsedOfficeHrs,
      customData,
    );
  }
}

export class TimeRange {
  start: string;
  end: string;
  day?: string;

  constructor(start: string, end: string, day?: string) {
    this.start = start;
    this.end = end;
    this.day = day;
  }

  static fromJson(json: any) {
    return new TimeRange(json.start, json.end, json.day);
  }
}