import React from 'react';
import { Property } from 'csstype';
import tinycolor from 'tinycolor2';

export type StickyProps = {
    backgroundColor: Property.Color;
    color: Property.Color;
    padding?: number
    children: React.ReactNode
}

export const Sticky: React.FC<StickyProps> = ({children, backgroundColor, color, padding = 15}) => {
    const foldColor = tinycolor(backgroundColor).darken(20).toString();
    return (
        <div className='sticky'>
            <div className='content' style={{color, backgroundColor, paddingLeft: padding, paddingTop: padding, paddingBottom: padding}}>
                {children}
            </div>
            <div className='right' style={{backgroundColor}} />
            <div className='fold' style={{borderTopColor: foldColor, borderLeftColor: foldColor}} />
        </div>
    );
}