export function UpdateQuery(newQuery: string) {
  const currentUrlWithoutQuery = window.location.href.split('?')[0];
  window.history.replaceState({}, '', `${currentUrlWithoutQuery}?${newQuery}`);
}

export function EnsureQuery(query: string) {
  if (!window.location.search.includes(query)) {
    UpdateQuery(query);
  }
}

export function GetQueryParams() {
  const query = window.location.search;
  const params = new URLSearchParams(query);
  return params;
}

export const extractIDsFromQueryParams = () => {
  const queryString = window.location.search;

  // Extract the view parameter
  const view = new URLSearchParams(queryString).get('view');
  let moduleID = '';

  // Check if the view parameter starts with 'module-' and extract the module ID
  if (view && view.startsWith('module-')) {
    moduleID = view.substring(7); // Extract everything after 'module-'
  }

  // Extract the node parameter
  const nodeID = new URLSearchParams(queryString).get('node');

  return { moduleID, nodeID };
};
