import { ContextPointer, IHasOutputs, Pointer } from "internal/models";
import { Step } from '../Step';
import { StepType } from "../Type";
import { Output } from "../OutputMessages";

export class Ask extends Step implements IHasOutputs {
    questions: Output[];
    saveLocation: Pointer;
    canInterrupt: boolean;

    public get outputs() : Output[] {
        return this.questions;
    }

    public set outputs(v : Output[]) {
        this.questions = v;
    }
    
    constructor(id: string | undefined, type: StepType, name: string, description: string, tags: string[], questions: Array<Output>,
        saveLocation: Pointer, canInterrupt: boolean) {
        super(id, name, description, type, tags);
        this.questions = questions;
        this.saveLocation = saveLocation;
        this.canInterrupt = canInterrupt
    }

    isDirty(original: Ask): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Ask): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Ask {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any) {
        const questions = json.questions && Array.isArray(json.questions)
            ? json.questions.map((p: any) => Output.fromJson(p))
            : [];
        
        return new Ask(json.id, json.type, json.name, json.description, json.tags, questions, ContextPointer.fromJson(json.saveLocation), json.canUserInterrupt);
    }
}
