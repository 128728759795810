import { ICellEditorParams } from 'ag-grid-community';
import { ConfigValue, ValueType } from 'internal/models';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';


export const DayOfMonthCellEditor = forwardRef((props: ICellEditorParams, ref: React.ForwardedRef<unknown>) => {
    const configValue = (props.value as ConfigValue);
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState(configValue.type === ValueType.DayOfMonth ? (configValue.value as string) : "");
    const [, setChanged] = useState(false);

    function inputHandler(e : React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value);
        setChanged(true);
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [inputRef]);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value 
                    ? new ConfigValue(ValueType.DayOfMonth, value) 
                    : new ConfigValue(ValueType.Unset, undefined);
            },
            isCancelAfterEnd: () => {
                return value === configValue.value;
            }
        };
    });

    return (
        <React.Fragment>
            <span data-tooltip-id="day-of-week-note" data-tooltip-content={dayOfMonthTooltip}>
            <input type="text" pattern="-?[0-9]+" className="form-control form-control-sm" inputMode="numeric" onBlurCapture={(e) => { 
                if(e.target.checkValidity()){
                    let input = parseInt(e.target.value); 
                    if(input >= -3 && input <= 28 ){
                        setValue(e.target.value);
                    }
                }
            }}/>
          
        </span>
        <Tooltip id="day-of-week-note" style={{ maxWidth: '500px' }} />
      </React.Fragment>
    )
});

const dayOfMonthTooltip =
  'Select 1-28, or use zero or negative numbers (0 = last day, any negatives is # of days from end. E.g. -5 = five days from the last day of the month, 9/25';