import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import {
  BooleanFieldValue,
  CustomField,
  CustomFieldValue,
  CustomFieldType,
  NumberFieldValue,
  StringFieldValue,
  DateFieldValue,
} from 'internal/models';
import { Icon, IconStyle, IconType } from '@smartaction/styles';

type CustomFieldRendererParams = ICellRendererParams<Location, CustomFieldValue> & {
  customField: CustomField;
};

export const CustomFieldValueRenderer: React.FC<CustomFieldRendererParams> = ({ value, customField }) => {
  if (!value) {
    return <React.Fragment />;
  }
  switch (value.type) {
    case CustomFieldType.String:
      const val = value as StringFieldValue;
      return <label>en-US: {val.value.get('en-US')}</label>;
    case CustomFieldType.Number:
      const numVal = value as NumberFieldValue;
      return <label>{numVal.value}</label>;
    case CustomFieldType.Boolean:
      const boolVal = value as BooleanFieldValue;
      return (
        <>
          {boolVal.value ? (
            <Icon size="lg" style={IconStyle.Outline} type={IconType.CheckboxChecked} />
          ) : (
            <Icon size="lg" style={IconStyle.Outline} type={IconType.CheckboxUnchecked} />
          )}
        </>
      );
    case CustomFieldType.Date:
      const dateVal = value as DateFieldValue;
      const displayDate = !!dateVal.value
        ? `${dateVal.value?.month}/${dateVal.value?.day}/${dateVal.value?.year}`
        : '-';
      return <label>{displayDate}</label>;
  }
};
