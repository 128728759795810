export enum BehaviorType {
    GoTo = "GoTo",
    RetryStep = "RetryStep",
    Skip = "Skip",
    Wait = "Wait",
    RunScript = "RunScript",
    Resume = "Resume",
    CallFunction = "CallFunction",
    Talk = "Talk",
    NoAction = "NoAction",
    Unknown = "Unknown"
}