import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { INumericInputClient } from './INumericInputClient';

export class NumericInputClient implements INumericInputClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private baseUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }

  async setMinLength(snapshotId: string, stepId: string, minLength: number): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/min-length`;
    const response = await this.methods.put(url, minLength);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Numeric Input Min Length`,
      response,
    );
  }

  async setMaxLength(snapshotId: string, stepId: string, maxLength: number): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/max-length`;
    const response = await this.methods.put(url, maxLength);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Numeric Input Max Length`,
      response,
    );
  }

  async setPoundToEnd(snapshotId: string, stepId: string, poundToEnd: boolean): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/pound-to-end`;
    const response = await this.methods.put(url, poundToEnd);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Numeric Input Pound to End`,
      response,
    );
  }

  async setAllowAsterisks(snapshotId: string, stepId: string, allowAsterisks: boolean): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/allow-asterisks`;
    const response = await this.methods.put(url, allowAsterisks);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Numeric Input Allow Asterisks`,
      response,
    );
  }

  async updateAlternateIntent(
    snapshotId: string,
    stepId: string,
    alternateIntent: {
      alternateResourceId: string;
      alternateIntent: string;
    },
  ): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/alternate-intent`;
    const response = await this.methods.put(url, alternateIntent);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Numeric Input Alternate Intent`,
      response,
    );
  }

  async deleteAlternateIntent(snapshotId: string, stepId: string): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/alternate-intent`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Deleted} Numeric Input Alternate Intent`,
      response,
    );
  }

  async setExcludeUniversalModel(
    snapshotId: string,
    stepId: string,
    excludeUniversalModel: boolean,
  ): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/allow-asterisks`;
    const response = await this.methods.put(url, excludeUniversalModel);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Numeric Input Exclude Universal Model`,
      response,
    );
  }
}
