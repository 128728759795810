import { Tenant } from '../models';
import { ITenantClient, Methods, ValidateResponseActionEnum } from '.';

export class TenantClient implements ITenantClient {
  methods: Methods;
  gatewayServiceUrl: string;
  tenantServiceUrl: string;

  constructor(gatewayServiceUrl: string, tenantServiceUrl: string, methods: Methods) {
    this.methods = methods;
    this.gatewayServiceUrl = gatewayServiceUrl;
    this.tenantServiceUrl = tenantServiceUrl; // not using this yet; will be for creating tenants and such
  }

  async getAvailableTenantsAsync(): Promise<Tenant[]> {
    const response = await this.methods.get(`${this.gatewayServiceUrl}/v1/availableTenants`);
    const success = await this.methods.handleErrors(`${ValidateResponseActionEnum.Get} tenants`, response);
    if (!success) {
      return [];
    }

    const json = await response.json();
    return json.map((t: any) => Tenant.fromJson(t));
  }
}
