import {
  DragDropProvider,
  Patience,
  FillSpace,
  WindowProvider,
  Editor,
} from '@smartaction/visuals';
import React from 'react';
import './Bots.scss';
import { useFlow } from 'contexts';
import FlowViewContents from './FlowViewContents';
export * from './general';
export const FlowView: React.FC = () => {
  const { flow, loaded } = useFlow();

  if (!flow) {
    return (
      <Patience showPatience={true}>
        <FillSpace buffer={200}><React.Fragment/></FillSpace>
      </Patience>
    );
  }

  return (
    <Patience showPatience={!loaded}>
      <div className="designer">
          <DragDropProvider>
            {/* Required, not having this causes provider availability issues with side panel; specifically steps that have
            editors setup in functions.  */}
            <Editor>
              <WindowProvider>
                <FlowViewContents />
              </WindowProvider>
            </Editor>
          </DragDropProvider>
      </div>
    </Patience>
  );
};


