import { VisualCategory } from '@smartaction/styles';
import { Button } from '@smartaction/visuals';
import { CompareCondition, CompareType, Condition, ConditionType, EmptyPointer, PointerType } from 'internal/models';
import React, { useState } from 'react';
import { TableRow } from 'ui/components';
import { useBots, useConfigs, useContextItems } from 'contexts';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridApi } from 'ag-grid-community';
import { TargetRenderer } from './TargetRenderer';
import { SourceRenderer } from './SourceRenderer';
import { CheckRenderer } from './CheckRenderer';
import { ReverseRenderer } from './ReverseRenderer';
import { LengthRenderer } from './LengthRenderer';
import { DeleteRenderer } from './DeleteRenderer';
import { ExpressionRenderer } from './ExpressionRenderer';
import { ScriptRenderer } from './ScriptRenderer';

type ConditionsTableProps = {
  conditions: Array<Condition>;
  targetAllowedTypes: Array<PointerType>;
  sourceAllowedTypes: Array<PointerType>;
  onAddCondition: (condition: Condition) => void;
  /** the only difference with create is that the id should be set. */
  onUpdateCondition: (condition: Condition) => void;
  onDeleteCondition: (conditionId: string) => void;
};



export const checkIfShowReverseTypeCell = (type: ConditionType): boolean => {
    switch (type) {
      case ConditionType.Regex:
      case ConditionType.Contains:
      case ConditionType.IsIn:
      case ConditionType.IsPopulated:
        return true;
      default:
        return false;
    }
  };
  
  export const checkIfShowLengthCompareTypeCell = (type: ConditionType): boolean => {
    switch (type) {
      case ConditionType.Compare:
      case ConditionType.LengthCompare:
        return true;
      default:
        return false;
    }
  };
  

export const ConditionsTableAG: React.FC<ConditionsTableProps> = ({
  conditions,
  targetAllowedTypes,
  sourceAllowedTypes,
  onAddCondition,
  onUpdateCondition,
  onDeleteCondition,
}) => {

  const { isReadOnlyBot } = useBots();

  const onCreateRow = () => {
    onAddCondition(new CompareCondition('', new EmptyPointer(), new EmptyPointer(), CompareType.Equals));
  };


  const onDeleteRow = (data: Condition) => {
    onDeleteCondition(data.id);
  };


  const columnDefs: ColDef<Condition | any>[] = [
    { headerName: "Target", field: "target", width: 180, resizable: true, cellStyle: { padding: 0 }, cellRenderer: TargetRenderer, cellRendererParams:  { allowedTargetTypes : targetAllowedTypes, onUpdateCondition: onUpdateCondition } },
    { headerName: "Check", field: "", width: 250, resizable: true, cellStyle: { padding: 0 }, cellRenderer: CheckRenderer, cellRendererParams:  { onUpdateCondition: onUpdateCondition } },
    { headerName: "Source", field: "", width: 180, resizable: true, cellStyle: { padding: 0 }, cellRenderer: SourceRenderer, cellRendererParams:  { presetAllowedTypes: sourceAllowedTypes, onUpdateCondition: onUpdateCondition } },
    { headerName: "Reverse", field: "reverse", width: 100, resizable: true, cellStyle: { padding: 0 }, cellRenderer: ReverseRenderer, cellRendererParams:  { onUpdateCondition: onUpdateCondition } },
    { headerName: "Length", field: "length", width: 100, resizable: true, cellStyle: { padding: 0 }, cellRenderer: LengthRenderer, cellRendererParams:  { onUpdateCondition: onUpdateCondition } },
    { headerName: "Expression", field: "", width: 100, resizable: true, cellStyle: { padding: 0 }, cellRenderer: ExpressionRenderer, cellRendererParams:  { onUpdateCondition: onUpdateCondition } },
    { headerName: "Script", field: "", width: 120, resizable: true, cellStyle: { padding: 0 }, cellRenderer: ScriptRenderer, cellRendererParams:  { onUpdateCondition: onUpdateCondition } },
    { headerName: 'Delete', width: 100, resizable: true, cellRenderer: DeleteRenderer, cellRendererParams: { deleteFunc : onDeleteRow, canEdit: true } }
    ];
    
  return (
   
    <div className="ag-theme-balham conversations" style={{ width: '124vh', height: '50vh', paddingBottom: '50px'}}>
        <AgGridReact
            enableCellTextSelection={false} // this and ensureDomOrder from https://www.ag-grid.com/react-data-grid/selection-overview/#cell-text-selection
            ensureDomOrder={true}
            disableStaticMarkup={true} // https://www.ag-grid.com/react-fine-tuning/#react-cell-rendering - disabled this because it causes the very first cell to frequently render twice
            columnDefs={columnDefs}
            defaultColDef={{ cellStyle: { 'fontFamily': 'Quicksand', 'fontSize': '14px'},  autoHeight: true, resizable: true, cellDataType: false }}
            rowData={conditions}
            rowHeight={38}
            suppressRowTransform={true}
            stopEditingWhenCellsLoseFocus={true}>
        </AgGridReact>
        <div className='conditions-footer'>
            <Button isDisabled={isReadOnlyBot} action={onCreateRow} type={VisualCategory.Primary}>
                Create
            </Button>
        </div>
    </div>
  );
};
