import { Methods, DataResponse, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Behavior, Condition } from 'internal/models';
import { IValidatePolicyClient } from './IValidatePolicyClient';

export class ValidatePolicyClient implements IValidatePolicyClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }
  async createValidation(snapshotId: string, policyId: string, name: string): Promise<DataResponse<string>> {
    const url = this.baseUrl(snapshotId, policyId);
    const response = await this.methods.plainBodyRequest(url, 'POST', name);
    return await this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Created} Validation`, response);
  }

  async setValidationName(
    snapshotId: string,
    policyId: string,
    validationId: string,
    name: string,
  ): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, policyId)}/${validationId}/name`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', name);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Validation name`, response);
  }

  async createValidationCondition(
    snapshotId: string,
    policyId: string,
    validationId: string,
    condition: Condition,
  ): Promise<DataResponse<string>> {
    const url = this.conditionsUrl(snapshotId, policyId, validationId);
    const response = await this.methods.post(url, condition);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Validation condition`,
      response,
    );
  }

  async replaceValidationCondition(
    snapshotId: string,
    policyId: string,
    validationId: string,
    conditionId: string,
    condition: Condition,
  ): Promise<ServerResponse> {
    const url = `${this.conditionsUrl(snapshotId, policyId, validationId)}/${conditionId}`;
    const response = await this.methods.put(url, condition);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Validation condition`,
      response,
    );
  }

  async deleteValidationCondition(
    snapshotId: string,
    policyId: string,
    validationId: string,
    conditionId: string,
  ): Promise<ServerResponse> {
    const url = `${this.conditionsUrl(snapshotId, policyId, validationId)}/${conditionId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Deleted} Validation condition`,
      response,
    );
  }

  async setValidationBehaviors(
    snapshotId: string,
    policyId: string,
    validationId: string,
    behaviors: Behavior[],
  ): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, policyId)}/${validationId}/behaviors`;
    const response = await this.methods.put(url, behaviors);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Validation behaviors`, response);
  }

  async deleteValidation(snapshotId: string, policyId: string, validationId: string): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, policyId)}/${validationId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Validation`, response);
  }

  private baseUrl(snapshotId: string, policyId: string) {
    return `${this.serviceUrl}/tenant/${this.methods
      .tenantId!}/snapshot/${snapshotId}/v1/policies/${policyId}/validations`;
  }
  private conditionsUrl(snapshotId: string, policyId: string, validationId: string) {
    return `${this.baseUrl(snapshotId, policyId)}/${validationId}/conditions`;
  }
}
