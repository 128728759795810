import { Step } from '../Step';
import { StepType } from '../Type';
import { Condition } from '../../shared';

export class SetLocation extends Step {
  conditions: Array<Condition>;
  moreThanOneLocationFunctionId: string;
  noLocationsFunctionId: string;
  constructor(
    id: string | undefined,
    name: string,
    description: string,
    tags: string[],
    conditions: Array<Condition>,
    moreThanOneLocationFunctionId: string,
    noLocationsFunctionId: string,
  ) {
    super(id, name, description, StepType.SetLocation, tags);
    this.conditions = conditions;
    this.moreThanOneLocationFunctionId = moreThanOneLocationFunctionId;
    this.noLocationsFunctionId = noLocationsFunctionId;
  }

  isDirty(original: Step): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: Step): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): Step {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any): SetLocation {
    return new SetLocation(
      json.id,
      json.name,
      json.description,
      json.tags,
      json.conditions,
      json.moreThanOneLocationFunctionId,
      json.noLocationsFunctionId,
    );
  }
}
