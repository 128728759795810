import { Editor, Input } from '@smartaction/visuals';
import React from 'react';
import { useBots, useClient, useProperties, useSnapshot, useTenant } from 'contexts';
import SupportedLanguagesTable from './SupportedLanguagesTable';
import TimeoutTable from './TimeoutTable';
import TimezoneTable from './TimezoneTable';
import { useCheckTenantAccess } from 'contexts/AccessContext';
import { EntitlementNames } from 'EntitlementNames';

export const defaultTimeoutsEnum = {
  initialListen: 'initial-listen',
  segmentationSilence: 'segmentation-silence',
  dtmfSegmentationSilence: 'dtmf-segmentation-silence',
};

export const Properties: React.FC = () => {
  const client = useClient('properties');
  const { isReadOnlyBot} = useBots();
  const hasAccess = useCheckTenantAccess(EntitlementNames.Properties.Manage);
  const tenant = useTenant();
  const snapshot = useSnapshot();
  const isReadOnly = isReadOnlyBot || !hasAccess;
  const properties = useProperties();

  const handleMinimumConfidence = (num: number) => {
    if (tenant?.id) {
      client.updateMinimumConfidence(snapshot.snapshot.id, tenant?.id, num).then(() => properties.refresh(true));
    }
  };

  return (
    <React.Fragment>
      <Editor>
        <div className="managPropertyServiceTable">
          <div className="row mb-3">
            <div className="col-sm-8">
              <SupportedLanguagesTable isReadOnly={isReadOnly} />
              <TimeoutTable isReadOnly={isReadOnly} />
              <TimezoneTable isReadOnly={isReadOnly} />
            </div>
            <div className="col-sm-4">
              <h2>Minimum Confidence</h2>
              <div>
                <Input
                  type={'number'}
                  max={100}
                  min={1}
                  value={`${properties?.languageModel?.minimumConfidence}`}
                  onChange={(newValue) => handleMinimumConfidence(+newValue)}
                  id={'minimumConfidence'}
                  disabled={isReadOnly}
                />
              </div>
            </div>
          </div>
        </div>
      </Editor>
    </React.Fragment>
  );
};
