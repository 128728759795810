import { Resource, ResourceType } from './Resource';

export class TwilioResource extends Resource {
  name: string;
  messageServices: MessageService[];
  phoneNumbers: PhoneNumber[];

  constructor(id: string | undefined, originalId: string, name: string, messageServices: MessageService[], phoneNumbers: PhoneNumber[]) {
    super(name, id, originalId, ResourceType.Twilio);
    this.name = name;
    this.messageServices = messageServices;
    this.phoneNumbers = phoneNumbers;
  }

  isDirty(original: Resource): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: Resource): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): Resource {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any) {
    return new TwilioResource(json.id, json.originalId, json.name, json.messageServices, json.phoneNumbers);
  }
}

export type PhoneNumber = {
  number: string,
  friendlyNumber: string
}

export type MessageService = { 
  friendlyName : string,
  sid : string, 
  phoneNumbers: PhoneNumber[]
}
