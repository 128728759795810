import { ValidationErrors } from '@smartaction/common';
import { PostError, PostSuccess } from 'ui/events';
import { DataResponse, ServerResponse } from './Responses';

export enum ValidateResponseActionEnum {
  Get = 'Get',
  Created = 'Created',
  Updated = 'Updated',
  Deleted = 'Deleted',
  Reordered = 'Reordered',
  Moved = 'Moved',
  Donwloaded = 'Donwloaded',
  Dulicated = 'Dulicated',
}

type Header = {
  name: string;
  value: string;
};

let tokenFunc: () => Promise<string | undefined> = () => Promise.resolve(undefined);

export function setTokenFunc(func: () => Promise<string | undefined>) {
  tokenFunc = func;
}

export class Methods {
  public tenantId?: string;

  public setTenant(tenantId?: string) {
    this.tenantId = tenantId;
  }

  public get(url: string, addlHeaders: Array<Header> | null = null) {
    return this.emptyRequest(url, 'GET', addlHeaders);
  }

  public post(url: string, body: any = undefined) {
    return body !== undefined ? this.bodyRequest(url, 'POST', body) : this.emptyRequest(url, 'POST');
  }

  public put(url: string, body: any = undefined) {
    return body !== undefined ? this.bodyRequest(url, 'PUT', body) : this.emptyRequest(url, 'PUT');
  }

  public patch(url: string, body: any = undefined) {
    return body !== undefined ? this.bodyRequest(url, 'PATCH', body) : this.emptyRequest(url, 'PATCH');
  }

  public delete(url: string, body: any = undefined) {
    return body !== undefined ? this.bodyRequest(url, 'DELETE', body) : this.emptyRequest(url, 'DELETE');
  }

  // Based on https://stackoverflow.com/a/59940621
  public async download(response: Response, type?: string, filename?: string) {
    const blob = await response.blob();
    const downloadableBlob = new Blob([blob], { type: type });
    const file = window.URL.createObjectURL(downloadableBlob);
    let link = document.createElement('a');
    link.href = file;
    if (filename) {
      link.download = filename;
    }
    link.click();
  }

  public async emptyRequest(url: string, method: string, addlHeaders: Array<Header> | null = null) {
    return await fetch(url, {
      method: method,
      headers: await this.headers(addlHeaders),
    });
  }

  public async bodyRequest(url: string, method: string, body: any = null, addlHeaders: Array<Header> | null = null) {
    return await fetch(url, {
      method: method,
      headers: await this.headers(addlHeaders),
      body: body !== null ? JSON.stringify(body) : null,
    });
  }

  public async plainBodyRequest(
    url: string,
    method: string,
    body: any = null,
    addlHeaders: Array<Header> | null = null,
  ) {
    const token = await tokenFunc();
    return await fetch(url, {
      method: method,
      headers: new Headers({ Authorization: `Bearer ${token}`, 'content-type': 'text/plain' }),
      body: body,
    });
  }

  public async headers(addlHeaders: Array<Header> | null = null) {
    const token = await tokenFunc();
    const result = new Headers({
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    if (addlHeaders && addlHeaders.length) {
      addlHeaders.forEach((h) => {
        result.set(h.name, h.value);
      });
    }
    return result;
  }

  public async handleErrors(
    action: string,
    response: Response,
    updateValidationErrors?: (update: (errors: ValidationErrors) => void) => void,
  ) {
    if (response.ok || response.redirected) {
      return true;
    }
    const message = await response.text();
    if (response.status === 400) {
      // Validation failure
      const errorJson = JSON.parse(message);
      console.error(`Validation error for action "${action}"`, errorJson);
      if (updateValidationErrors) {
        updateValidationErrors((validationErrors) => {
          for (const name in errorJson) {
            const actualName = name.replace('Model.', '');
            const errors = errorJson[name] as string[];
            if (errors && errors.length > 0) {
              validationErrors.addServerErrors(actualName, errors);
            }
          }
        });
      }
      return false;
    }
    const error = `Failure for action "${action}"`;
    console.error(error);
    console.error(`Response Status: ${response.status} - ${response.statusText}`);
    console.error(`Response Message: ${message}`);
    throw new Error(error);
  }

  /** This is a replacement of handleErrors, to provide back the error so that the client can pop the message into a toast. */
  public async validateResponse(
    action: string,
    response: Response,
    updateValidationErrors?: (update: (errors: ValidationErrors) => void) => void,
  ): Promise<ServerResponse> {
    if (response.ok || response.redirected) {
      return { success: true };
    }
    const message = await response.text();
    if (response.status === 400) {
      // Validation failure
      const errorJson = JSON.parse(message);
      console.error(`Validation error for action "${action}"`, errorJson);
      const errorMessages = new Map<string, string[]>();
      for (const name in errorJson) {
        const actualName = name.replace('Model.', '');
        const errors = errorJson[name] as string[];
        if (errors && errors.length > 0) {
          errorMessages.set(actualName, errors);
        }
      }
      let compiledMessage = `### Error while ${action}: Validation Failure:\n`;
      errorMessages.forEach((vals, propName) => {
        compiledMessage += `#### ${propName}\n`;
        compiledMessage += vals.map((v) => `- ${v}\n`);
      });
      if (updateValidationErrors) {
        updateValidationErrors((validationErrors) => {
          for (let key of errorMessages.keys()) {
            validationErrors.addServerErrors(key, errorMessages.get(key) ?? []);
          }
        });
      }
      return { success: false, error: compiledMessage };
    }
    // Severe error occured, so we still explode.
    const error = `Failure for action "${action}"`;
    console.error(error);
    const errorMsg = `${response.status} - ${response.statusText}`;
    return { success: false, error: errorMsg };
  }

  /** quick shorthand to pull quotes from an id string - since we accept 'application/json', all strings get quotes around
   * them from the server. Simpler to strip them out than to replace the Accept header on creates. */
  public async getId(response: Response) {
    const text = await response.text();
    return text.replaceAll('"', '');
  }

  /** Quick handler for -most- endpoints. Use this for the return on a client method, and call it before validateResponse. */
  public async validatePopToastAndReturnId(
    action: string,
    response: Response,
    updateValidationErrors?: (update: (errors: ValidationErrors) => void) => void,
  ): Promise<DataResponse<string>> {
    const responseValidation = await this.validateResponse(action, response, updateValidationErrors);
    if (!responseValidation.success) {
      PostError(responseValidation.error!, true);
      return responseValidation;
    } else if (responseValidation.success && !action.includes(ValidateResponseActionEnum.Get)) {
      PostSuccess(action);
    }

    const id = await this.getId(response);
    return { success: true, data: id };
  }

  public async validateAndPopToast(
    action: string,
    response: Response,
    updateValidationErrors?: (update: (errors: ValidationErrors) => void) => void,
  ): Promise<ServerResponse> {
    const responseValidation = await this.validateResponse(action, response, updateValidationErrors);
    if (!responseValidation.success) {
      PostError(responseValidation.error!, true);
    } else if (responseValidation.success && !action.includes(ValidateResponseActionEnum.Get)) {
      PostSuccess(action);
    }
    return responseValidation;
  }
}
