import { Methods, DataResponse, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Condition } from 'internal/models';
import { IWhileClient } from './IWhileClient';

export class WhileClient implements IWhileClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private conditionsUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}/conditions`;
  }

  async createCondition(snapshotId: string, stepId: string, condition: Condition): Promise<DataResponse<string>> {
    const url = this.conditionsUrl(snapshotId, stepId);
    const response = await this.methods.post(url, condition);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} While Condition`,
      response,
    );
  }

  async replaceCondition(
    snapshotId: string,
    stepId: string,
    conditionId: string,
    condition: Condition,
  ): Promise<ServerResponse> {
    const url = `${this.conditionsUrl(snapshotId, stepId)}/${conditionId}`;
    const response = await this.methods.put(url, condition);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} While Condition`, response);
  }

  async deleteCondition(snapshotId: string, stepId: string, conditionId: string): Promise<ServerResponse> {
    const url = `${this.conditionsUrl(snapshotId, stepId)}/${conditionId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} While Condition`, response);
  }
}
