import { Behavior, BehaviorType, GoToBehavior, NoActionBehavior, ResumeBehavior, RetryStepBehavior, 
    RunScriptBehavior, SkipBehavior, UnknownBehavior, WaitBehavior, CallFunctionBehavior, TalkBehavior } from 'internal/models';
import { CallFunctionBehaviorView, GoToBehaviorView, NoActionBehaviorView, ResumeBehaviorView, RetryStepBehaviorView, RunScriptBehaviorView, SkipBehaviorView, TalkBehaviorView, UnknownBehaviorView, WaitBehaviorView } from './types';


export function BehaviorTypeViewSelector(behavior: Behavior, update: (behavior: Behavior) => void, allowedTypes: BehaviorType[], isFollowUp: boolean) {
    // Note on below: We don't allow behaviors that can have followup types to stack more than 1 layer deep.
    // In other words, followUps defined on a type cannot themselves have followUps. Thus we send an empty list of allowed types when isFollowUp is true.
    switch(behavior.type) {
        case BehaviorType.CallFunction:
            return <CallFunctionBehaviorView behavior={behavior as CallFunctionBehavior} update={update} />;
        case BehaviorType.GoTo:
            return <GoToBehaviorView behavior={behavior as GoToBehavior} update={update} />;
        case BehaviorType.Resume:
            return <ResumeBehaviorView behavior={behavior as ResumeBehavior} update={update} />;
        case BehaviorType.RetryStep:
            return <RetryStepBehaviorView behavior={behavior as RetryStepBehavior} update={update} />;
        case BehaviorType.RunScript:
            return <RunScriptBehaviorView behavior={behavior as RunScriptBehavior} update={update} allowedFollowUpTypes={isFollowUp ? [] : allowedTypes} />;
        case BehaviorType.Skip:
            return <SkipBehaviorView behavior={behavior as SkipBehavior} update={update} />;
        case BehaviorType.Wait:
            return <WaitBehaviorView behavior={behavior as WaitBehavior} update={update} allowedFollowUpTypes={isFollowUp ? [] : allowedTypes} />;
        case BehaviorType.Talk:
            return <TalkBehaviorView behavior={behavior as TalkBehavior} update={update} allowedFollowUpTypes={isFollowUp ? [] : allowedTypes} />;
        case BehaviorType.NoAction:
            return <NoActionBehaviorView behavior={behavior as NoActionBehavior} update={update} />;
    }

    return <UnknownBehaviorView behavior={behavior as UnknownBehavior} update={() => {}} />;
}