import { BaseModel } from "@smartaction/common";

export class Endpoint extends BaseModel<Endpoint> {
    originalId: string;
    snapshotId: string;
    description: string;
    inputs: Parameter[];
    outputs: Parameter[];
    devScript: string;
    qaScript: string;
    uatScript: string;
    prodScript: string;

    constructor(id: string, originalId: string, snapshotId: string, name: string, description: string, inputs: Parameter[], outputs: Parameter[], devScript: string, qaScript: string, uatScript: string, prodScript: string) {
        super(name, id);
        this.originalId = originalId;
        this.snapshotId = snapshotId;
        this.description = description;
        this.inputs = inputs;
        this.outputs = outputs;
        this.devScript = devScript;
        this.qaScript = qaScript;
        this.uatScript = uatScript;
        this.prodScript = prodScript;
    }

    isDirty(original: Endpoint): boolean {
        throw new Error("Method not implemented.");
    }
    getDirtyFields(original: Endpoint): string[] {
        throw new Error("Method not implemented.");
    }
    clone(): Endpoint {
        throw new Error("Method not implemented.");
    }

    static fromJson(json: any) {
        const inputs = json.inputs && Array.isArray(json.inputs)
            ? json.inputs.map((f: any) => Parameter.fromJson(f))
            : [];
        const outputs = json.outputs && Array.isArray(json.outputs)
            ? json.outputs.map((f: any) => Parameter.fromJson(f))
            : [];
        return new Endpoint(json.id, json.originalId, json.snapshotId, json.name, 
            json.description, inputs, outputs, json.devScript, json.qaScript, json.uatScript, json.prodScript);
    }
}

export class Parameter {
    id: string;
    name: string;
    description: string;
    type: ParameterType;
    isList: boolean;
    typeId?: string;

    constructor(id: string, name: string, description: string, isList: boolean, type: ParameterType, typeId?: string) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.isList = isList;
        this.type = type;
        this.typeId = typeId;
    }

    static fromJson(json: any) {
        return new Parameter(json.id, json.name, json.description, json.isList, json.type, json.typeId);
    }
}

export enum ParameterType {
    String = "String",
    Integer = "Integer",
    Decimal = "Decimal",
    Boolean = "Boolean",
    DateTime = "DateTime",
    Object = "Object"
}