import { GroupBase, StylesConfig } from 'react-select';
import { Option } from 'ui/types';


export const TopBarSelectStyles: StylesConfig<Option, false, GroupBase<Option>> = {
  control: (base, state) => ({
    ...base,
    background: state.isFocused ? "#ffffff" : "transparent",
    borderColor: "#ffffff",
    color: state.isFocused ? "#000000" : "#ffffff",
    borderRadius: 10,
    width: 240
  }),
  singleValue: (base) => ({
    ...base,
    color: 'inherit'
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%"
  })
};

export const SubNavSelectStyles: StylesConfig<Option, false, GroupBase<Option>> = {
  control: (base, state) => ({
    ...base,
    background: state.isFocused ? "#ffffff" : "transparent",
    borderColor: "#ffffff",
    color: state.isFocused ? "#000000" : "#ffffff",
    borderRadius: 10,
    width: 240
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.selectProps.menuIsOpen ? 'var(--bs-body-color)' : 'inherit'
  }),
  menu: (base, state) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    color: state.selectProps.menuIsOpen ? 'var(--bs-body-color)' : 'inherit'
  })
};