import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import {
  Button,
  Editor,
  Field,
  ModalContainerIds,
  Patience,
  PortalConfirm,
  PortalModal,
  useFromModal,
  usePortalModal,
} from '@smartaction/visuals';
import { useClient } from 'contexts/ClientContext';
import { useSnapshot } from 'contexts/SnapshotContext';
import React, { useEffect, useState } from 'react';
import { ITwilio } from 'internal/clients';
import { useCheckTenantAccess } from 'contexts/AccessContext';
import { EntitlementNames } from 'EntitlementNames';
import { useBots } from 'contexts';

type AuthModalProps = {
  id: string;
};

export const AuthModal: React.FC<AuthModalProps> = ({ id }) => {
  const [isSaving, setIsSaving] = useState(false);
  const modal = useFromModal();
  const [authData, setAuthData] = useState<ITwilio | undefined>();
  const [isShowCredentials, setIsShowCredentials] = useState(false);
  const [isAuthEditing, setIsAuthEditing] = useState(false);
  const [accountSID, setAccountSID] = useState<string>();
  const [authToken, setAuthToken] = useState<string>();
  const client = useClient('resources');
  const snapshot = useSnapshot();

  const {isReadOnlyBot} = useBots();
  const canManage = useCheckTenantAccess(EntitlementNames.Resources.Manage);
  const isReadOnly = isReadOnlyBot || !canManage;

  useEffect(() => {
    setAccountSID(authData?.accountSID);
    setAuthToken(authData?.authToken);
  }, [authData]);

  const onAuthViewClick = () => {
    client.twilio
      .getCredentials(snapshot.snapshot.id, id)
      .then((r) => setAuthData(r))
      .finally(() => setIsShowCredentials(true));
  };

  const onAuthEditClick = () => {
    onAuthViewClick();
    setIsAuthEditing(true); // Todo add validate
  };

  const header = {
    type: VisualCategory.Primary,
    content: <React.Fragment>Auth</React.Fragment>,
  };

  const content = (
    <React.Fragment>
      {isAuthEditing && (
        <div className="alert alert-warning">
          Saving changes will change the credentials for any copies of this agent that are active in NOVA.
        </div>
      )}

      <Field inputId={'accountSID'} label="Account SID" name="Account SID">
        <input
          id={'accountSID'}
          className="form-control"
          type="text"
          disabled={!isAuthEditing}
          value={isShowCredentials ? accountSID : '**********'}
          onChange={(evt) => {
            setAccountSID(evt.target.value);
          }}
        />
      </Field>
      <Field inputId={'authToken'} label="Auth Token" name="Auth Token">
        <input
          id={'authToken'}
          className="form-control"
          type="text"
          disabled={!isAuthEditing}
          value={isShowCredentials ? authToken : '**********'}
          onChange={(evt) => {
            setAuthToken(evt.target.value);
          }}
        />
      </Field>
      {!isAuthEditing && (
        <div className="d-flex justify-content-between mt-2">
          <Button action={onAuthViewClick} type={VisualCategory.Primary}>
            View
          </Button>
          {!isReadOnly &&
            <Button action={onAuthEditClick} type={VisualCategory.Secondary}>
              Edit
            </Button>
          }
        </div>
      )}
    </React.Fragment>
  );

  const cancel = () => modal.forceClose();
  const saveClick = async () => {
    setIsSaving(true);
    if (accountSID && authToken) {
      const result = await client.twilio.updateCredentials(snapshot.snapshot.id, id, {
        accountSID: accountSID,
        authToken: authToken,
      });
      if (result.success) {
        modal.forceClose();
      } else {
        setIsSaving(false);
        setIsAuthEditing(false);
        setIsShowCredentials(false);
      }
    }
  };

  const buttons = (
    <React.Fragment>
      {!isReadOnly &&
        <Button className={'me-3'} action={saveClick} isDisabled={isSaving} type={VisualCategory.Primary}>
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
      }
      <Button action={cancel} type={VisualCategory.Secondary}>
        Cancel
      </Button>
    </React.Fragment>
  );

  return <PortalModal header={header} content={content} buttons={buttons} />;
};
