import { DataResponse, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Methods } from 'internal/clients/Methods';
import { IAddressClient } from './IAddressClient';
import { ApiCredentials } from './ApiCredentials';

export class GoogleAddressClient implements IAddressClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }

  private getUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/resources`;
  }

  async create(snapshotId: string, data: ApiCredentials): Promise<DataResponse<string>> {
    let response = await this.methods.post(`${this.getUrl(snapshotId)}/address`, data);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} GoogleAddress Resource`,
      response,
    );
  }

  async updateCredentials(snapshotId: string, resourceId: string, data: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${resourceId}/apikey`;
    const response = await this.methods.put(url, data);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} GoogleAddress Credentials`,
      response,
    );
  }
}
