import { InputFormatterPolicy } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { PolicyModalProps } from '../Types';
import { useClient, usePolicies, useSnapshot, useBots } from 'contexts';
import { Field, useId } from '@smartaction/visuals';
import { EditorInterface, MonacoEditor } from 'ui/components';

export const InputFormatterPolicyView: React.FC<PolicyModalProps<InputFormatterPolicy>> = ({ policy, disabled }) => {
  const inputFormatterId = useId('formatscript');
  const { updatePolicies } = usePolicies();
  const client = useClient('flow');
  const validate = useClient('validator');
  const snapshot = useSnapshot();

    // TODO: prop drill from parent
    const { isReadOnlyBot } = useBots();
    const isReadOnly = isReadOnlyBot;

  const saveScript = (value: string) => {
    client.policies.inputFormatters.setScript(snapshot.snapshot.id, policy.id, value).then((res) => {
      if (res.success) {
        updatePolicies(() => {
          policy.script = value;
        });
      }
    });
  };

  return (
    <div>
      <Field label="Script" inputId={inputFormatterId}>
        <MonacoEditor id={inputFormatterId} 
          isReadOnly={isReadOnly}
          value={policy.script} 
          types={[EditorInterface.ConversationState]} 
          onSave={saveScript} 
          validateMethod={(tenant, snapshot, script) => validate.validateInputFormatterScript(tenant, snapshot, script) } />
      </Field>
    </div>
  );
};
