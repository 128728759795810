import React, { createContext, useState, useContext, ReactNode } from 'react';

type SelectedNodeIdsContextType = {
  selectedNodeIds: string[];
  setSelectedNodeIds: React.Dispatch<React.SetStateAction<string[]>>;
};

const SelectedNodeIdsContext = createContext<SelectedNodeIdsContextType | undefined>(undefined);

export const SelectedNodeIdsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);
  return (
    <SelectedNodeIdsContext.Provider value={{ selectedNodeIds, setSelectedNodeIds }}>
      {children}
    </SelectedNodeIdsContext.Provider>
  );
};

export const useSelectedNodeIds = () => {
  const context = useContext(SelectedNodeIdsContext);
  if (!context) {
    throw new Error('useSelectedNodeIds must be used within a SelectedNodeIdsProvider');
  }
  return context;
};