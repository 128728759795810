import { BehaviorType } from "./BehaviorType";
import { Behavior } from "./Behavior";

export class NoActionBehavior extends Behavior {

    constructor() {
        super(BehaviorType.NoAction);
    }

    // leaving the 'fromJson' approach in spite of there being no data on a NoAction (it's basically a null), so that it looks like the others
    static fromJson(json: any): NoActionBehavior {
        return new NoActionBehavior();
    }
}