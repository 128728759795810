import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Behavior } from 'internal/models';
import { ITimeoutPolicyClient } from './ITimeoutPolicyClient';

export class TimeoutPolicyClient implements ITimeoutPolicyClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }
  async setSeconds(snapshotId: string, policyId: string, seconds: number): Promise<ServerResponse> {
    const url = `${this.policyUrl(snapshotId, policyId)}/seconds`;
    const response = await this.methods.put(url, seconds);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Timeout Policy seconds`, response);
  }

  async setBehaviors(snapshotId: string, policyId: string, behaviors: Behavior[]): Promise<ServerResponse> {
    const url = `${this.policyUrl(snapshotId, policyId)}/behaviors`;
    const response = await this.methods.put(url, behaviors);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Timeout Policy behaviors`, response);
  }

  private policyUrl(snapshotId: string, policyId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/policies/${policyId}`;
  }
}
