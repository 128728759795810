import { Behavior, BehaviorBuilder } from "../behaviors";
import { Policy } from "../Policy";
import { PolicyType } from "../PolicyType";

export class TimeoutPolicy extends Policy {
    seconds: number;
    behaviors: Behavior[];

    constructor(id: string, ownerId: string, seconds: number, behaviors: Behavior[]) {
        super(id, ownerId, PolicyType.Timeout);
        this.seconds = seconds;
        this.behaviors = behaviors;
    }

    static fromJson(json: any): TimeoutPolicy {
        const behaviors = json.behaviors && Array.isArray(json.behaviors)
            ? json.behaviors.map((b: any) => BehaviorBuilder(b))
            : [];
        return new TimeoutPolicy(json.id, json.ownerId, json.seconds, behaviors);
    }
}