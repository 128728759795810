import { Flow, If, Policy, PolicyType, Step, StepType } from "internal/models";

export type PolicyResult<TPolicy extends Policy> = {
    ownerName: string,
    ownerId: string,
    policy: TPolicy
}

type NameAndId = {
    id: string,
    name: string
}

export function GetAllPoliciesOfType<TPolicy extends Policy>(type: PolicyType, flow: Flow, stepId: string): PolicyResult<TPolicy>[] {
    const filterAndTranslatePolicies = (ids: NameAndId[]) => {
        return flow.policies.filter(p => ids.some(id => id.id === p.ownerId) && p.type === type).map(p => {
            const nameAndId = ids.find(i => i.id === p.ownerId);
            return {
                ownerId: nameAndId!.id,
                ownerName: nameAndId!.name,
                policy: p as TPolicy
            };
        });
    }

    for (let module of flow.modules) {
        const containerProcessor = (container: NameAndId, steps: Step[]) => {
            for (let step of steps) {
                if (step.id === stepId) {
                    return [{ id: flow.snapshotId, name: "Snapshot" }, { id: module.id, name: module.name }, container, { id: step.id, name: step.name }];
                }
                if (step.type === StepType.If) {
                    for (let branchStep of (step as If).branches.flatMap(b => b.steps)) {
                        if (branchStep.id === stepId) {
                            return [{ id: flow.snapshotId, name: "Snapshot" }, { id: module.id, name: module.name }, container, { id: step.id, name: step.name }, { id: branchStep.id, name: branchStep.name }];
                        }
                    }
                }
            }
            return undefined;
        };

        for (let entryPoint of module.entryPoints) {
            const ids = containerProcessor({ id: entryPoint.id, name: entryPoint.name }, entryPoint.steps);
            if (ids) {
                return filterAndTranslatePolicies(ids);
            }
        }
            
        for (let block of module.blocks) {
            const ids = containerProcessor({ id: block.id, name: block.name }, block.steps);
            if (ids) {
                return filterAndTranslatePolicies(ids);
            }
        }
    }

    return [];
}