import { Environment } from 'internal/models';
import { SummaryView } from './Summaries/SummaryView';
import { Navigate, Route, Routes } from 'react-router-dom';
import { NavTabs } from '@smartaction/visuals';

export const Conversations: React.FC = () => {
    return (
        <NavTabs
            tabs={[
            { path: `${Environment.DEV}`, label: 'Sandbox' },
            { path: `${Environment.QA}`, label: 'Testing' },
            { path: `${Environment.UAT}`, label: 'UAT' },
            { path: `${Environment.PROD}`, label: 'Live' },
            ]}
        >
        <Routes>
          <Route path=":environment/:conversation?" element={<SummaryView />} />
          <Route path="*" element={<Navigate to={Environment.DEV} />} />
        </Routes>
      </NavTabs>
    );
}