import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { IconType } from '@smartaction/styles';
import { Patience } from '@smartaction/visuals';
import { useBots, useClient, useFlow, useResources, useSnapshot } from 'contexts';
import {
  AzureLanguage,
  CLU,
  EmptyId,
  Intent,
  Pointer,
  PointerControlFilters,
  PointerType,
  ResourceType,
} from 'internal/models';
import React, { useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { Option } from 'ui/types';
import { CLUIntentEditor } from './CLUIntentEditor';
import { CLUIntents } from './CLUIntents';
import { InputStepsColors } from '../../StepColorGroups';
import { PointerControl } from 'ui/components';
import { CanInterruptView } from 'ui/components/design/CanInterruptView';
import { StepOutputsView } from '../../StepOutputsView';

type ResourceOption = Option & { resourceId: string };

export const CLUStyling: StepStyling = {
  typeName: 'CLU',
  icon: IconType.Help,
  ...InputStepsColors,
};

type CLUWithProp = TypedStepProps<CLU> & {
  isContainer: boolean;
};

export const CLUStepView: React.FC<TypedStepProps<CLU>> = ({ step, isContainer, manipulateStep }) => {
  const branchesView = step.intents && step.intents.length > 1 ? <CLUIntents step={step} /> : undefined;
  return (
    <StepView step={step} styling={CLUStyling} isCollapsible={true} branches={branchesView} manipulateStep={manipulateStep}>
      <CLUDetails step={step} isContainer={isContainer!} manipulateStep={manipulateStep} />
    </StepView>
  );
};

const CLUDetails: React.FC<CLUWithProp> = ({ step, isContainer }) => {
  const snapshot = useSnapshot();
  const resources = useResources();
  const flowClient = useClient('flow');
  const { isReadOnlyBot } = useBots();
  const { flow, updateFlow } = useFlow();
  const [isSaving, setIsSaving] = useState(false);

  const noIntent: ResourceOption = { label: 'No Intent', value: '', resourceId: '' };
  const [intents, setIntents] = useState<ResourceOption[]>([]);
  const [options, setOptions] = useState<ResourceOption[]>([]);

  const onPointerChange = async (pointer: Pointer) => {
    const response = await flowClient.steps.setSaveLocation(snapshot.snapshot.id, step.id, pointer);
    if (response.success) {
      updateFlow(() => {
        step.saveLocation = pointer;
      });
    }
  };

  //Used to validate that the resourceId of a selected intent is still in the resource pool
  //No Intent will also be validated
  function verifyValidResources() {
    let valid: boolean = true;

    //Get Id of Intents assigned to step
    let assignedIntentsIds: any[] = [];
    step.intents.forEach((int) => {
      assignedIntentsIds.push(int.resourceId);
    });

    //Get Id of Resources available
    let validIntentIds: any[] = [];
    const azureLanguageResources = (resources.byType.get(ResourceType.AzureLanguage) as AzureLanguage[]) ?? [];
    azureLanguageResources.forEach((r) => {
      validIntentIds.push(r.id);
    });

    //Check for a match between assigned Ids and available Ids
    assignedIntentsIds.forEach((intent) => {
      let verified = validIntentIds.some((res) => {
        return res === intent;
      });

      //If Id matches No Intent then treat as valid
      if (intent === EmptyId || intent === '') {
        verified = true;
      }

      //Return false if invalid
      if (!verified) {
        valid = false;
        return valid;
      }
    });

    //Return true if no invalid cases
    return valid;
  }

  //Check if step.intents needs to be reset on mount due to invalid resources
  useEffect(() => {
    if (!verifyValidResources()) {
      updateIntents([]);
    }
  }, []);

  useEffect(() => {
    // traverse flow - check top level containers...add more if needed
    //  a direct ( step ) of a block or entry point is not a child
    const isNotChild = flow.modules.some(
      (m) =>
        m.blocks.some((b) => b.steps.some((s) => s.id === step.id)) ||
        m.entryPoints.some((b) => b.steps.some((s) => s.id === step.id)),
    );

    // Check if specific intent or child
    if (!isNotChild) {
      if (isNotChild && !step.intents.length) {
        // Create single intent
        setIsSaving(true);

        flowClient.steps.clus.createIntent(snapshot.snapshot.id, step.id).then((r) => {
          updateFlow(() => {
            step.intents = [new Intent(r.data || '', '', '', [], [])];
            setIsSaving(false);
          });
        });
      }
    }

    // Render Multiselect
    let options: any[] = [noIntent];
    let intents: any[] = [];

    if (step.intents.some((i) => i.name === null || i.name === '')) {
      intents.push(noIntent);
    }

    const azureLanguageResources = (resources.byType.get(ResourceType.AzureLanguage) as AzureLanguage[]) ?? [];

    azureLanguageResources.forEach((nlu) => {
      if (!nlu) return;

      const grouped = nlu.intents
        ?.filter((i) => i.name !== 'None')
        .map((el) => {
          const option: ResourceOption = {
            label: el.name,
            value: el.name,
            resourceId: nlu.id,
          };

          if (step.intents.some((i) => i.name === option.value && i.resourceId === option.resourceId)) {
            intents.push(option);
          }

          return option;
        });

      options.push({
        label: nlu.modelName,
        options: grouped,
      });
    });

    setOptions(options);
    setIntents(intents);
  }, [step]);

  const updateIntents = async (selected: MultiValue<ResourceOption>) => {
    setIsSaving(true);
    let result: Intent[] = [];

    await Promise.all(
      selected.map((o) => {
        const index = step.intents.findIndex((i) => i.name === o.value && i.resourceId === o.resourceId);

        if (index < 0) {
          // new item, create
          return flowClient.steps.clus.createIntent(snapshot.snapshot.id, step.id, o.resourceId, o.value).then((r) => {
            result.push(new Intent(r.data || '', o.value, o.resourceId || '', [], []));
          });
        } else {
          // no change, remove and add to list
          result = result.concat(step.intents.splice(index, 1));
          return Promise.resolve();
        }
      }),
    );

    await Promise.all(
      // delete all leftover
      step.intents.map((i) => flowClient.steps.clus.deleteIntent(snapshot.snapshot.id, step.id, i.id)),
    );

    updateFlow(() => {
      step.intents = result;

      setIntents([...selected]);
      setIsSaving(false);
    });
  };

  const handleOptionsDisabled = () => {
    if (isContainer) {
      return false;
    }

    return step.intents.length >= 1;
  };

  return (
    <Patience showPatience={isSaving}>
      <StepOutputsView step={step} typeName="Question" />
      <CanInterruptView ask={step}></CanInterruptView>
      <span>Select Intent</span>
      {
        <Select
          isDisabled={isReadOnlyBot}
          isMulti
          options={options}
          value={intents}
          onChange={updateIntents}
          isOptionDisabled={handleOptionsDisabled}
          menuPosition="fixed"
        />
      }
      {verifyValidResources() && <CLUIntentEditor step={step} index={0} fixedMenu={true} />}
      <span>Save Intent to:</span>
      <PointerControl
        types={[PointerType.Context, PointerType.Iteration]}
        pointer={step.saveLocation}
        update={onPointerChange}
        pointerFilters={[PointerControlFilters.HideStaticContextItems]}
      />
    </Patience>
  );
};
