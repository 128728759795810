import React from 'react';
import { ChannelSourceType, ChannelType } from "internal/models";
import { ICellRendererParams } from 'ag-grid-community';

const ChannelTypeTableCellRenderer: React.FC<ICellRendererParams> = ({ value }) => {
  const channelSourceType = value as ChannelSourceType

  return (
    <div className={'pointer-table-cell-renderer'}>
      {channelSourceType.type} { (channelSourceType.type === ChannelType.Inbound || 
        channelSourceType.type === ChannelType.Outbound || channelSourceType.type === ChannelType.SMS ) && <span className="badge bg-primary">{channelSourceType.sourceType}</span> }
    </div>
  );
};

export default ChannelTypeTableCellRenderer;