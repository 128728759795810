import { Resource, ResourceType } from './Resource';

export class GoogleAddressResource extends Resource {
  name: string;

  constructor(id: string | undefined, originalId: string, name: string) {
    super(name, id, originalId, ResourceType.GoogleAddress);
    this.name = name;
  }

  isDirty(original: Resource): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: Resource): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): Resource {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any) {
    return new GoogleAddressResource(json.id, json.originalId, json.name);
  }
}
