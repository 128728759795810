export class OutputMessage {

    text: string;
    languageCode: string;


    constructor(languageCode: string, text: string) {
        this.text = text;
        this.languageCode = languageCode;
    }

    static fromJson(json: any) {
        return new OutputMessage(json.languageCode, json.text);
    }
}