import { ContextPointer, Pointer } from "internal/models";
import { Step } from '../Step';
import { StepBuilder } from "../StepBuilder";
import { StepType } from "../Type";
import { Output } from "../OutputMessages";
import { Ask } from "./Ask";

export class CLU extends Ask {
    intents: Intent[];
    
    constructor(id: string | undefined, name: string, description: string, tags: string[], questions: Array<Output>,
        saveLocation: Pointer, intents: Array<Intent>, canInterrupt: boolean) {
        super(id, StepType.CLU, name, description, tags, questions, saveLocation, canInterrupt);
        this.intents = intents;
    }

    static fromJson(json: any) {
        const questions = json.questions && Array.isArray(json.questions)
            ? json.questions.map((p: any) => Output.fromJson(p))
            : [];
        
        const intents = json.intents.map((j:any) => Intent.fromJson(j));
        return new CLU(json.id, json.name, json.description, json.tags, questions, ContextPointer.fromJson(json.saveLocation), intents, json.canUserInterrupt);
    }
}

export class Intent {
    id: string;
    name?: string;
    resourceId?: string;
    entities: Entity[];
    steps: Step[];

    constructor(id:string, name:string, resourceId:string, entities:Entity[], steps:Step[]) {
        this.id =  id;
        this.name = name;
        this.resourceId = resourceId;
        this.entities = entities;
        this.steps = steps;
    }

    static fromJson(json: any) {
        const entities = json.entities && Array.isArray(json.entities)
            ? json.entities.map((p: any) => Entity.fromJson(p))
            : [];

        const steps = json.steps && Array.isArray(json.steps)
            ? json.steps.map((s: any) => StepBuilder(s))
            : [];

        return new Intent(json.id, json.name, json.resourceId, entities, steps);
    }
}

export class Entity {
    name: string;
    saveLocation: Pointer;

    constructor( name: string, saveLocation: Pointer) {
        this.name = name;
        this.saveLocation = saveLocation;
    }

    static fromJson(json: any) {
        return new Entity(json.name, ContextPointer.fromJson(json.saveLocation));
    }
}