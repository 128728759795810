import { Pointer } from "internal/models";
import { Step } from "../Step";
import { StepType } from "../Type";

export class Increment extends Step {
    saveLocation: Pointer;
    isDecrement: boolean;

    constructor(id: string | undefined, name: string, description: string, tags: string[], 
        saveLocation: Pointer, isDecrement: boolean) {
        super(id, name, description, StepType.Increment, tags);
        this.saveLocation = saveLocation;
        this.isDecrement = isDecrement;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): Increment {
        return new Increment(json.id, json.name, json.description, json.tags, Pointer.fromJson(json.saveLocation), json.isDecrement);
    }

}