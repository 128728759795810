import { Methods, ValidateResponseActionEnum } from 'internal/clients/Methods';
import { IDuplicatorClient } from './IDuplicatorClient';

export class DuplicatorClient implements IDuplicatorClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async duplicate(tenantId: string, botId: string, snapshotId: string) {
    const url = `${this.serviceUrl}/tenant/${tenantId}/v1/bot/${botId}/snapshot/${snapshotId}/duplicate`;
    let response = await this.methods.post(url, { version: 'v1' });
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Dulicated} bot`, response);
  }
}
