import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Output } from 'internal/models';
import { IExpirationPolicyClient } from './IExpirationPolicyClient';

export class ExpirationPolicyClient implements IExpirationPolicyClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }

  async setSMSTimeout(snapshotId: string, policyId: string, smsTimeout: number): Promise<ServerResponse> {
    const url = `${this.policyUrl(snapshotId, policyId)}/sms-timeout`;
    const response = await this.methods.put(url, smsTimeout);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} sms-timeout for Expiration Policy`,
      response,
    );
  }

  async setWebChatTimeout(snapshotId: string, policyId: string, webChatTimeout: number): Promise<ServerResponse> {
    const url = `${this.policyUrl(snapshotId, policyId)}/webchat-timeout`;
    const response = await this.methods.put(url, webChatTimeout);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} webchat-timeout for Expiration Policy`,
      response,
    );
  }

  async setOutputs(snapshotId: string, policyId: string, outputs: Output[]): Promise<ServerResponse> {
    const url = `${this.policyUrl(snapshotId, policyId)}/outputs`;
    const response = await this.methods.put(url, outputs);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Outputs for Expiration Policy`,
      response,
    );
  }

  private policyUrl(snapshotId: string, policyId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/policies/${policyId}`;
  }
}
