import React from 'react';
import { IconType, VisualCategory } from '@smartaction/styles';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { DTMFMenu, Pointer, PointerControlFilters, PointerType, StepType } from 'internal/models';
import './DTMFStep.scss';
import { PointerControl, Table } from 'ui/components';
import { Button } from '@smartaction/visuals';
import { useTableMenuItemsLogic } from './useTableLogic';
import { useBots, useClient, useFlow, useSnapshot } from 'contexts';
import { InputStepsColors } from '../../StepColorGroups';
import { CanInterruptView } from 'ui/components/design/CanInterruptView';
import { StepOutputsView } from '../../StepOutputsView';

export const DTMFStyling: StepStyling = {
  typeName: StepType.DTMFMenu,
  icon: IconType.Hash,
  ...InputStepsColors,
};

export const DTMFStepView: React.FC<TypedStepProps<DTMFMenu>> = ({ step, manipulateStep }) => {
  return (
    <StepView step={step} styling={DTMFStyling} isCollapsible={true} manipulateStep={manipulateStep}>
      <StepDTMFForm step={step} manipulateStep={manipulateStep} />
    </StepView>
  );
};

const StepDTMFForm: React.FC<TypedStepProps<DTMFMenu>> = ({ step }) => {
  const tableKeys = useTableMenuItemsLogic({ step });
  const client = useClient('flow');
  const { snapshot } = useSnapshot();
  const { updateFlow } = useFlow();
  const { isReadOnlyBot } = useBots();

  const onPointerChange = async (pointer: Pointer) => {
    const response = await client.steps.setSaveLocation(snapshot.id, step.id, pointer);
    if (response.success) {
      updateFlow(() => {
        step.saveLocation = pointer;
      });
    }
  };
  return (
    <div className="dtmf-step-form">
      <StepOutputsView typeName="Question" step={step} />
      <CanInterruptView ask={step}></CanInterruptView>
      <Table
        id="menu-items"
        rows={tableKeys.rows}
        headersGroup={['Key', 'Code', 'Keywords']}
        columnsSize={['xs', 'sm']}
        onDeleteRow={tableKeys.onDeleteRow}
        isLoading={tableKeys.isLoading}
        footer={
          <Button isDisabled={isReadOnlyBot} type={VisualCategory.Primary} action={tableKeys.onAddNewRow}>
            Add key
          </Button>
        }
      />
      <div>
        <h3>Save as</h3>
        <PointerControl
          types={[PointerType.Context, PointerType.Iteration]}
          pointer={step.saveLocation}
          update={onPointerChange}
          pointerFilters={[PointerControlFilters.HideStaticContextItems]}
        />
      </div>
    </div>
  );
};
