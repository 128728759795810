import { ValidationErrors } from "@smartaction/common";
import { Decision } from 'internal/models';

export function ValidateDecision(decision: Decision, validationErrors: ValidationErrors) {
    validationErrors.resetLocal();

    // block not yet instantiated, nothing to validate
    if (!decision) {
        return;
    }

    if (!decision.name || decision.name.trim().length === 0 ) {
        validationErrors.addLocalError("name", "Name cannot be empty.");
    }

}