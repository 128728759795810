import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, Field, PortalModal, useFromModal } from '@smartaction/visuals';
import React, { useState } from 'react';
import { useClient, useResources, useSnapshot } from 'contexts';

export const AddTwilioResourceModal = () => {
  const { snapshot } = useSnapshot();
  const { refresh } = useResources();
  const client = useClient('resources');
  const [isSaving, setIsSaving] = useState(false);
  const modal = useFromModal();
  const [accountSID, setAccountSID] = useState<string>('');
  const [authToken, setAuthToken] = useState<string>('');
  const [name, setName] = useState<string>('');

  const header = {
    type: VisualCategory.Primary,
    content: (
      <React.Fragment>
        <Icon type={IconType.Add} /> Add Twilio Resource
      </React.Fragment>
    ),
  };

  const content = (
    <React.Fragment>
      <Field inputId={'name'} label="Name" name="Name">
        <input
          id={'name'}
          className="form-control"
          type="text"
          value={name}
          onChange={(evt) => {
            setName(evt.target.value);
          }}
        />
      </Field>
      <Field inputId={'accountSID'} label="Account SID" name="Account SID">
        <input
          id={'accountSID'}
          className="form-control"
          type="text"
          value={accountSID}
          onChange={(ev) => setAccountSID(ev.target.value)}
        />
      </Field>
      <Field inputId={'authToken'} label="Auth Token" name="Auth Token">
        <input
          id={'authToken'}
          className="form-control"
          type="text"
          value={authToken}
          onChange={(evt) => {
            setAuthToken(evt.target.value);
          }}
        />
      </Field>
    </React.Fragment>
  );

  const cancel = () => modal.forceClose();
  const saveClick = async () => {
    setIsSaving(true);

    if (accountSID && authToken) {
      await client.twilio.create(snapshot.id, { name, accountSID, authToken }).then((r) => r.success && refresh(true));
    }

    modal.forceClose();
    setIsSaving(false);
  };

  const buttons = (
    <React.Fragment>
      <Button className={'me-3'} action={saveClick} isDisabled={isSaving} type={VisualCategory.Primary}>
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
      <Button action={cancel} type={VisualCategory.Secondary}>
        Cancel
      </Button>
    </React.Fragment>
  );

  return <PortalModal className={'add-twilio-resource-modal'} header={header} content={content} buttons={buttons} />;
};
