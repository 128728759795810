import { DateTimePointerValue, DateTimeValue, DateValue, DayOfMonthValue, DayOfWeekType, DayOfWeekValue, DirectAssignmentDateTimePointer, EmptyValue, TimeValue } from 'internal/models';
import React, { useState } from 'react';
import { PointerEditorProps } from './PointerEditorProps';
import { Select } from '@smartaction/visuals';
import { Tooltip } from 'react-tooltip';

const controlOptions = [
  { value: 'datePicker', label: 'Date' },
  { value: 'datePickerTime', label: 'DateTime' },
  { value: 'timePicker', label: 'Time' },
  { value: 'dayOfWeekPicker', label: 'Day of Week' },
  { value: 'dayOfMonthPicker', label: 'Day of Month' },
];

const dayOfWeekOptions = [
  { value: 'Sunday', label: 'Sunday' },
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
];

const dayOfMonthTooltip =
  'Select 1-28, or use zero or negative numbers (0 = last day, any negatives is # of days from end. E.g. -5 = five days from the last day of the month, 9/25';

export const DirectAssignmentDateTimePointerEditor: React.FC<PointerEditorProps<DirectAssignmentDateTimePointer>> = ({
  pointer, update, isDisabled
}) => {

  const isDateTimePointerValue = pointer?.value instanceof DateTimePointerValue; 

  const [dateValue, setDateValue] = useState<DateTimePointerValue>(isDateTimePointerValue ? pointer.value : new EmptyValue());
  const [displayValue, setDisplayValue] = useState<string>(isDateTimePointerValue ? pointer?.value?.displayResult() : "" );
  const [selectorType, setSelectorType] = useState<string>('datePicker');

  function renderDateInputControl() {
    switch (selectorType) {
      case 'datePicker':
        return <input type="date" className="form-control form-control-sm" onChange={onChange} onBlur={save} />;
      case 'datePickerTime':
        return (
          <input type="datetime-local" className="form-control form-control-sm" onChange={onChange} onBlur={save} />
        );
      case 'timePicker':
        return <input type="time" className="form-control form-control-sm" onChange={onChange} onBlur={save} />;
      case 'dayOfWeekPicker':
        return (
          <Select
            disabled={isDisabled}
            value={displayValue}
            options={dayOfWeekOptions}
            onChange={onDayPickerChange}
            id={'day-of-week-picker'}
            onBlur={save}
          />
        );
      case 'dayOfMonthPicker':
        return (
          <span data-tooltip-id="day-of-week-note" data-tooltip-content={dayOfMonthTooltip}>
            <input type="text" className="form-control form-control-sm" onChange={onChange} onBlur={save} />
            <Tooltip id="day-of-week-note" style={{ maxWidth: '500px' }} />
          </span>
        );
    }
  }

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {    
    switch(selectorType){
      case "datePicker": 
        let dateValue = new DateValue(e.target.value);
        setDisplayValue(dateValue.date);
        await setDateValue(dateValue);
        break;
      case "datePickerTime":
        let dateTimeValue = new DateTimeValue(e.target.value);
        setDisplayValue(dateTimeValue.dateTime);
        await setDateValue(dateTimeValue);
        break;
      case "timePicker": 
        //The concatentation of :00 is to set milli seconds when
        //working with TimeOnly since we only capture Hour/Minutes 
        let timeValue = new TimeValue(e.target.value + ":00");
        setDisplayValue(timeValue.time);
        await setDateValue(timeValue);
        break;
      case "dayOfWeekPicker": 
        //Not Handled in this area
        break;
      case "dayOfMonthPicker": 
        let dayOfMonthValue = new DayOfMonthValue(+e.target.value)
        setDisplayValue(dayOfMonthValue.dayOfMonth.toString());
        await setDateValue(dayOfMonthValue);
        break;
    }

  };

  const onDayPickerChange = (newVal: string) => {
    setDisplayValue(newVal);
    setDateValue(new DayOfWeekValue(newVal as DayOfWeekType));
  };

  const save = () => {
    update(new DirectAssignmentDateTimePointer(dateValue));
  };

  const onSelectorTypeChange = (newVal: string) => {
    console.log(newVal);
    setSelectorType(newVal);
  };

  return (
    <React.Fragment>
      <Select value={selectorType} options={controlOptions} onChange={onSelectorTypeChange} id={'date-selectorType'} disabled={isDisabled} />
      {renderDateInputControl()}
      <input type="text" className="form-control form-control-sm" value={displayValue} disabled />
    </React.Fragment>
  );
};
