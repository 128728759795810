import { EmptyId } from "internal/models";
import { Step } from "../Step";
import { StepType } from "../Type";

export class CallFunction extends Step {
    functionId: string;

    constructor(id: string | undefined, name: string, description: string, tags: string[], functionId: string) {
        super(id, name, description, StepType.CallFunction, tags);
        this.functionId = functionId;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): CallFunction {
        return new CallFunction(json.id, json.name, json.description, json.tags, json.functionId ?? EmptyId);
    }

}