import { IEvent } from "@smartaction/common";
import { Bot, PublishedSnapshot, SnapshotType, UnpublishedSnapshot } from "internal/models";

export class SnapshotOpenedEvent implements IEvent {
    public name: string = SnapshotOpenedEvent.eventName;
    static eventName: "SnapshotOpenedEvent" = "SnapshotOpenedEvent";
    
    bot: Bot;
    snapshot: PublishedSnapshot | UnpublishedSnapshot;
    snapshotType: SnapshotType

    constructor(bot: Bot, snapshot: PublishedSnapshot | UnpublishedSnapshot, snapshotType: SnapshotType) {
        this.bot = bot;
        this.snapshot = snapshot;
        this.snapshotType = snapshotType;
    }
}