import { DataResponse, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Methods } from 'internal/clients/Methods';
import { ITwilioClient } from './ITwilioClient';
import { ITwilio, ITwilioPhoneNumber, ITwilioMessageServices } from './ITwilio';

export class TwilioClient implements ITwilioClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }

  private getUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v2/resources`;
  }

  async create(snapshotId: string, data: ITwilio): Promise<DataResponse<string>> {
    let response = await this.methods.post(`${this.getUrl(snapshotId)}/twilio`, data);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Twilio Resource`,
      response,
    );
  }

  async getCredentials(snapshotId: string, resourceId: string): Promise<ITwilio> {
    let response = await this.methods.get(`${this.getUrl(snapshotId)}/${resourceId}/credentials`);
    return await response.json();
  }

  async refreshNumbers(snapshotId: string, resourceId: string): Promise<ITwilioPhoneNumber[]> {
    const url = `${this.getUrl(snapshotId)}/${resourceId}/refresh-numbers`;
    const response = await this.methods.put(url);
    return await response.json();
  }

  async refreshMessageServices(snapshotId: string, resourceId: string): Promise<ITwilioMessageServices[]> {
    const url = `${this.getUrl(snapshotId)}/${resourceId}/refresh-message-services`;
    const response = await this.methods.put(url);
    return await response.json();
  }

  async updateCredentials(snapshotId: string, resourceId: string, data: ITwilio): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${resourceId}/credentials`;
    const response = await this.methods.put(url, data);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Twilio Credentials`, response);
  }
}
