import { Step } from '../Step';
import { StepType } from '../Type';
import { Pointer } from '../../shared';
import { ChannelType } from 'internal/models';

export class TriggerAgent extends Step {
  botId: string;
  entryPointOriginalId: string;
  destinationId: string;
  channelType: ChannelType | string;
  contextItems: Array<TriggerContextItem>;

  constructor(
    id: string | undefined,
    name: string,
    description: string,
    tags: string[],
    botId: string,
    entryPointOriginalId: string,
    destinationId: string,
    channelType: ChannelType | string,
    contextItems: Array<TriggerContextItem>,
  ) {
    super(id, name, description, StepType.TriggerAgent, tags);
    this.botId = botId;
    this.entryPointOriginalId = entryPointOriginalId;
    this.destinationId = destinationId;
    this.channelType = channelType;
    this.contextItems = contextItems;
  }

  isDirty(original: Step): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: Step): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): Step {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any): TriggerAgent {
    return new TriggerAgent(
      json.id,
      json.name,
      json.description,
      json.tags,
      json.botId,
      json.entryPointOriginalId,
      json.destinationId,
      json.channelType,
      json.contextItems,
    );
  }
}

export class TriggerContextItem {
  secondAgentContextItemOriginalId: string;
  originalPointer: Pointer;

  constructor(secondAgentContextItemOriginalId: string, originalPointer: Pointer) {
    this.secondAgentContextItemOriginalId = secondAgentContextItemOriginalId;
    this.originalPointer = originalPointer;
  }

  static fromJson(json: any) {
    return new TriggerContextItem(json.secondAgentContextItemOriginalId, Pointer.fromJson(json.originalPointer));
  }
}
