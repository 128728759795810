import React from "react";
import { ModuleChildTypes } from "internal/models";
import { Rectangle } from "../nodes/shapes/Rectangle";

const Block: React.FC = () => {
  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('application/reactflow', ModuleChildTypes.Block);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className="block-draggable dndnode" onDragStart={onDragStart} draggable>
      <Rectangle text='Block' size={.46} hasNoBottomRadius={false} isSelected={false} />
    </div>
  )
}
export default Block;