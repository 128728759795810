import { ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Methods } from 'internal/clients/Methods';
import { ISetLanguageClient } from './ISetLanguageClient';

export class SetLanguageClient implements ISetLanguageClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private getUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }

  async setLanguage(snapshotId: string, stepId: string, languageCode: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/language-code`;
    const response = await this.methods.put(url, languageCode);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} LanguageCode for Step`,
      response,
    );
  }
}
