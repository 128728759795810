import {
  CellClassParams,
  CellValueChangedEvent,
  EditableCallbackParams,
  GridApi,
  GridReadyEvent,
  ICellEditorParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { columnDefinition, Patience, selectColumnDefinition, Button, useSidePanel } from '@smartaction/visuals';
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateForm } from './CreateForm';
import { DefaultPublisher } from '@smartaction/common';
import { IConfigClient } from 'internal/clients';
import { Config, ConfigType, ConfigValue, ValueType } from 'internal/models/bots/design/Config';
import { toast } from 'react-toastify';
import { VisualCategory } from '@smartaction/styles';
import { useSnapshot } from 'contexts';
import { ValueRenderer } from './gridComponents/ValueRenderer';
import { Environment, GetEnvName } from 'internal/models';
import {
  StringCellEditor,
  BooleanCellEditor,
  DateCellEditor,
  MultiStringCellEditor,
  NumberCellEditor,
  DateRangeCellEditor,
  DayOfWeekCellEditor,
  TimeCellEditor,
  DayOfMonthCellEditor,
  TwilioCellEditor,
} from './gridComponents';
import { CheckboxCellStyle, CheckEditableRenderer } from 'ui/controls/grid/editors/CheckEditableRenderer';
import { DeleteRenderer } from 'ui/controls/grid/editors/DeleteRenderer';
import { RegExps } from 'ui/constants';

const selectOptions = [
  { label: 'String', value: ConfigType.String },
  { label: 'Number', value: ConfigType.Number },
  { label: 'Boolean', value: ConfigType.Boolean },
  { label: 'Multiple Strings', value: ConfigType.MultiString },
  { label: 'Twilio', value: ConfigType.Twilio },
  { label: 'Time', value: ConfigType.Time },
  { label: 'Date', value: ConfigType.Date },
  { label: 'Date Range', value: ConfigType.DateRange },
  { label: 'Day of Week', value: ConfigType.DayOfWeek },
  { label: 'Day of Month', value: ConfigType.DayOfMonth },
];

function getValueColDef(
  environment: Environment,
  canViewSensitive: boolean,
  canEdit: boolean,
  configSet: 'technical' | 'business',
) {
  const fieldName = `${environment.toLowerCase()}Value`;
  return columnDefinition(GetEnvName(environment), fieldName, ValueRenderer, undefined, {
    editable: (params: EditableCallbackParams) => {
      const config = params.data as Config;
      const value = config[fieldName as keyof Config] as ConfigValue;
      return canEdit && value.type !== ValueType.Sensitive;
    },
    singleClickEdit: true,
    cellRendererParams: {
      options: {
        environment,
        canViewSensitiveValues: canViewSensitive,
        configSet,
      },
    },
    cellEditorSelector: (params: ICellEditorParams) => {
      const { data } = params;

      const config = data as Config;

      switch (config.type) {
        case ConfigType.String:
          return {
            component: StringCellEditor,
          };
        case ConfigType.Number:
          return {
            component: NumberCellEditor,
          };
        case ConfigType.Boolean:
          return {
            component: BooleanCellEditor,
          };
        case ConfigType.MultiString:
          return {
            component: MultiStringCellEditor,
            popup: true,
          };
        case ConfigType.Date:
          return {
            component: DateCellEditor,
            popup: true,
          };
        case ConfigType.DateRange:
          return {
            component: DateRangeCellEditor,
            popup: true,
          };
        case ConfigType.Time:
          return {
            component: TimeCellEditor,
            popup: false,
          };
        case ConfigType.DayOfWeek:
          return {
            component: DayOfWeekCellEditor,
            popup: false,
          };
        case ConfigType.DayOfMonth:
          return {
            component: DayOfMonthCellEditor,
            popup: false,
          };
        case ConfigType.Twilio:
          return {
            component: TwilioCellEditor,
            popup: true,
          };
      }

      return undefined;
    },
    cellStyle: (params: CellClassParams) => {
      const { value } = params;
      const configValue = value as ConfigValue;

      if (configValue && configValue.type === ValueType.Boolean) {
        return CheckboxCellStyle;
      }

      return undefined;
    },
  });
}

function getColDefs(
  canEdit: boolean,
  canViewSensitive: boolean,
  canSetProductionValue: boolean,
  configSet: 'technical' | 'business',
  deleteFunc: (config: Config) => void,
) {
  const cols: any = [
    { headerName: 'Name', field: 'name', editable: canEdit, singleClickEdit: true },
    { headerName: 'Description', field: 'description', editable: canEdit, singleClickEdit: true },
    selectColumnDefinition('Type', 'type', selectOptions, undefined, {
      editable: false,
      isReadOnly: !canEdit,
    }),
    columnDefinition('Is Sensitive', 'isSensitive', CheckEditableRenderer, undefined, {
      editable: false,
      cellStyle: CheckboxCellStyle,
      cellRendererParams: { canEdit: canEdit },
    }),
    getValueColDef(Environment.DEV, canViewSensitive, canEdit, configSet),
    getValueColDef(Environment.QA, canViewSensitive, canEdit, configSet),
    getValueColDef(Environment.UAT, canViewSensitive, canEdit, configSet),
    getValueColDef(Environment.PROD, canViewSensitive, canEdit && canSetProductionValue, configSet),
  ];

  if (canEdit) {
    cols.push({
      headerName: 'Delete',
      cellRenderer: DeleteRenderer,
      cellRendererParams: { canEdit, deleteFunc },
      width: 100,
      resizable: true,
    });
  }

  return cols;
}

type ConfigViewProps = {
  configSet: 'business' | 'technical';
  configs: Config[];
  client: IConfigClient;
  refresh: (fromServer?: boolean) => void;
  manageEntitlement: string;
  viewSensitiveValuesEntitlement: string;
  setProductionValueEntitlement: string;
  isReadOnly: boolean;
};

export const ConfigTable: React.FC<ConfigViewProps> = ({
  configSet,
  configs,
  client,
  refresh,
  manageEntitlement,
  viewSensitiveValuesEntitlement,
  setProductionValueEntitlement,
  isReadOnly = false,
}) => {
  const snapshot = useSnapshot();
  //TODO: Bypassing until AccessContext fixed, currently entitlement names returned dont match with ones defined in UI also logic
  //in AccessContext needs rework when to use AD user vs Outh user.
  //const [canManage, canViewSensitive, canSetProduction] = useCheckTenantAccessArray([manageEntitlement, viewSensitiveValuesEntitlement, setProductionValueEntitlement]);
  const [canManage, canViewSensitive, canSetProduction] = [true, true, true];
  const [gridApi, setGridApi] = useState<GridApi>();

  useEffect(() => {
    gridApi?.setRowData(configs);
    const loadValueSubId = DefaultPublisher.subscribe('LoadConfigValueEvent', (evt) => {
      if (evt.configSet === configSet) {
        client.getValueForEnvironment(snapshot.snapshot.id, evt.config.id, evt.environment).then((res) => {
          if (res.success) {
            switch (evt.environment) {
              case Environment.DEV:
                evt.config.devValue = res.data!;
                break;
              case Environment.QA:
                evt.config.qaValue = res.data!;
                break;
              case Environment.UAT:
                evt.config.uatValue = res.data!;
                break;
              case Environment.PROD:
                evt.config.prodValue = res.data!;
                break;
            }
            refresh();
          }
        });
      }
    });
    return () => DefaultPublisher.unsubscribe('LoadConfigValueEvent', loadValueSubId);
  }, [configs, refresh]);

  const save = async (event: CellValueChangedEvent) => {
    const config = event.data as Config;
    const columnDef = event.colDef;
    const snapshotId = snapshot.snapshot.id;
    const validNameValue = RegExps.letterAndNumberWithUnderscore.test(event.newValue);

    switch (columnDef.field) {
      case 'name':
        if (!event.newValue) {
          toast('Name must be set! Changes are not saved.', { type: 'error', containerId: 'default' });
          return;
        } else if (!validNameValue) {
          toast('Name cannot contain special characters.! Changes are not saved.', { type: 'error', containerId: 'default' });
          config.name = event.oldValue;
        } else {
          await client.updateName(snapshotId, config.id, event.newValue);
          config.name = event.newValue;
        }
        break;
      case 'description':
        await client.updateDescription(snapshotId, config.id, event.newValue);
        config.description = event.newValue;
        break;
      case 'type':
        await client.updateType(snapshotId, config.id, event.newValue);
        config.type = event.newValue;
        break;
      case 'isSensitive':
        await client.updateSensitivity(snapshotId, config.id, event.newValue);
        config.isSensitive = event.newValue;
        break;
      case 'devValue': {
        const val = event.newValue === undefined ? ConfigValue.unset() : event.newValue;
        await client.setValueForEnvironment(snapshotId, config.id, Environment.DEV, val);
        config.devValue = val;
        break;
      }
      case 'qaValue': {
        const val = event.newValue === undefined ? ConfigValue.unset() : event.newValue;
        await client.setValueForEnvironment(snapshotId, config.id, Environment.QA, val);
        config.qaValue = val;
        break;
      }
      case 'uatValue': {
        const val = event.newValue === undefined ? ConfigValue.unset() : event.newValue;
        await client.setValueForEnvironment(snapshotId, config.id, Environment.UAT, val);
        config.uatValue = val;
        break;
      }
      case 'prodValue': {
        const val = event.newValue === undefined ? ConfigValue.unset() : event.newValue;
        await client.setValueForEnvironment(snapshotId, config.id, Environment.PROD, val);
        config.prodValue = val;
        break;
      }
    }

    refresh();
  };

  const deleteFunc = (config: Config) => {
    client.delete(snapshot.snapshot.id, config.id).then((res) => {
      if (res.success) {
        refresh(true);
      }
    });
  };

  const gridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api);
  };

  return (
    <Patience showPatience={configs === undefined}>
      <Routes>
        <Route
          path={`/`}
          element={
            <React.Fragment>
              <div className="ag-theme-balham" style={{ height: '100%' }}>
                <AgGridReact
                  disableStaticMarkup={true} // https://www.ag-grid.com/react-fine-tuning/#react-cell-rendering - disabled this because it causes the very first cell to frequently render twice
                  columnDefs={getColDefs(
                    canManage && snapshot.isEditable && !isReadOnly,
                    canViewSensitive,
                    canSetProduction,
                    configSet,
                    deleteFunc,
                  )}
                  defaultColDef={{ cellStyle: { fontFamily: 'Quicksand', fontSize: '14px' }, singleClickEdit: true }}
                  rowData={configs}
                  onGridReady={gridReady}
                  onCellValueChanged={save}
                  rowHeight={36}
                  suppressRowTransform={true}
                  stopEditingWhenCellsLoseFocus={true}
                  domLayout="autoHeight"
                ></AgGridReact>
              </div>
              <CreateConfigButton client={client} configs={configs} refresh={refresh} isDisabled={!canManage || isReadOnly} />
            </React.Fragment>
          }
        />
      </Routes>
    </Patience>
  );
};

type CreateConfigButtonProps = {
  configs: Config[];
  client: IConfigClient;
  refresh: (fromServer?: boolean) => void;
  isDisabled: boolean;
};

const CreateConfigButton: React.FC<CreateConfigButtonProps> = ({ client, refresh, configs, isDisabled }) => {
  const sidePanel = useSidePanel();
  return (
    <Button
      type={VisualCategory.Primary}
      className="m-3"
      action={() => {
        sidePanel.setContents('Create New Config', <CreateForm refresh={refresh} client={client} configs={configs} />);
        sidePanel.open();
      }}
      isDisabled={isDisabled}
      disabledReason="You don't have the entitlement to manage these configs."
    >
      Create
    </Button>
  );
};
