import { DatePicker } from '@smartaction/visuals';
import { ICellEditorParams } from 'ag-grid-community';
import { ConfigValue, DateRange, ValueType } from 'internal/models';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

export const DateRangeCellEditor = forwardRef((props: ICellEditorParams, ref: React.ForwardedRef<unknown>) => {
    const configValue = (props.value as ConfigValue);
    const [startValue, setStartValue] = useState(configValue.type === ValueType.DateRange ? (configValue.value as DateRange).start : undefined);
    const [endValue, setEndValue] = useState(configValue.type === ValueType.DateRange ? (configValue.value as DateRange).end : undefined);
    const [changed, setChanged] = useState(false);

    function setStart(date: Date | null) {
        setStartValue(date !== null ? date : undefined);
        setChanged(date !== configValue.value);
    }

    function setEnd(date: Date | null) {
        setEndValue(date !== null ? date : undefined);
        setChanged(date !== configValue.value);
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return startValue || endValue 
                    ? new ConfigValue(ValueType.DateRange, { start: startValue, end: endValue}) 
                    : new ConfigValue(ValueType.Unset, undefined);
            },
            isCancelAfterEnd: () => {
                return !changed;
            }
        };
    });

    
    return (
        <div className={'p-2'} style={{backgroundColor: 'white'}}>
            <div className='row mb-3'>
                <label htmlFor="startDatePicker" className="col-sm-2 col-form-label">Start</label>
                <div className='col-sm-10' style={{backgroundColor: 'white'}}>
                    <DatePicker 
                        id="startDatePicker" 
                        selected={startValue}
                        withPortal={false}
                        selectsStart
                        startDate={startValue} 
                        endDate={endValue}
                        onChange={setStart} />
                </div>
            </div>
            <div className='row mb-3'>
                <label htmlFor="endDatePicker" className="col-sm-2 col-form-label">End</label>
                <div className='col-sm-10'>
                    <DatePicker 
                        id="endDatePicker"
                        selected={endValue}
                        withPortal={false}
                        selectsEnd
                        startDate={startValue} 
                        endDate={endValue}
                        onChange={setEnd} />
                </div>
            </div>
        </div>
    );
});