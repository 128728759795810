import { ColorHexes } from "@smartaction/styles";

export type StepColors = {
    color: string,
    detailsBackground?: string
};
// Nova new design colors
export const InputStepsColors: StepColors = {
    color: '#00a4e8',
    detailsBackground: '#E3F7FF'
}
export const OutputStepsColors: StepColors = {
    color: '#aa1086',
    detailsBackground: '#FFECFF'
}
export const ProcessStepsColors: StepColors = {
    color: ColorHexes.Info,
    detailsBackground: '#E9FFF'
}
export const DataStepsColors: StepColors = {
    color: ColorHexes.SecondaryAccent,
    detailsBackground: '#ECE9FF'
}
export const EndConversationStepsColors: StepColors = {
    color: '#CA2027',
    detailsBackground: '#FFE0E1'
}
