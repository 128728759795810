import { Methods, ValidateResponseActionEnum } from '../../Methods';
import { DataResponse, ServerResponse } from '../../Responses';
import { Container, Func, StepType } from 'internal/models';
import { IFunctionClient } from './IFunctionClient';

export class FunctionClient implements IFunctionClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private baseUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/functions`;
  }
  private functionUrl(snapshotId: string, functionId: string) {
    return `${this.baseUrl(snapshotId)}/${functionId}`;
  }

  async get(snapshotId: string, functionId: string): Promise<DataResponse<Func>> {
    const url = this.functionUrl(snapshotId, functionId);
    const response = await this.methods.get(url);
    const validationResponse = await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Get} Function`, response);
    if (!validationResponse.success) {
      return validationResponse;
    }

    return {
      success: true,
      data: Func.fromJson(await response.json()),
    };
  }

  async create(snapshotId: string, name: string): Promise<DataResponse<string>> {
    const url = this.baseUrl(snapshotId);
    const response = await this.methods.plainBodyRequest(url, 'POST', name);
    return await this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Created} Function`, response);
  }

  async updateName(snapshotId: string, functionId: string, name: string): Promise<ServerResponse> {
    const url = `${this.functionUrl(snapshotId, functionId)}/name`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', name);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Function name`, response);
  }

  async updateDescription(snapshotId: string, functionId: string, description: string): Promise<ServerResponse> {
    const url = `${this.functionUrl(snapshotId, functionId)}/description`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', description);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Function description`,
      response,
    );
  }

  async delete(snapshotId: string, functionId: string): Promise<ServerResponse> {
    const url = this.functionUrl(snapshotId, functionId);
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Function`, response);
  }

  async createStep(
    snapshotId: string,
    functionId: string,
    type: StepType,
    name: string,
    index?: number,
  ): Promise<DataResponse<string>> {
    let url = `${this.functionUrl(snapshotId, functionId)}/steps`;

    if (index !== undefined) {
      url += `?index=${index}`;
    }

    const request = {
      type: type,
      name: name,
    };

    const response = await this.methods.post(url, request);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Step in Function`,
      response,
    );
  }

  async reorderSteps(snapshotId: string, functionId: string, stepIds: string[]): Promise<ServerResponse> {
    const url = `${this.functionUrl(snapshotId, functionId)}/steps`;
    const response = await this.methods.patch(url, stepIds);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Reordered} Steps in function`, response);
  }

  async addExistingStep(
    snapshotId: string,
    functionId: string,
    stepId: string,
    container: Container,
    index?: number,
  ): Promise<ServerResponse> {
    let url = `${this.functionUrl(snapshotId, functionId)}/steps/${stepId}`;
    if (index !== undefined) {
      url += `?index=${index}`;
    }

    const response = await this.methods.put(url, container);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Moved} Step to function`, response);
  }
}
