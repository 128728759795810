import { DefaultPublisher } from '@smartaction/common';
import { ColorHexes, Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, Field, Patience, PortalModal, Switch, useAlert, useFromModal, useId, usePortalModal } from '@smartaction/visuals';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useClient, useFlow, useResources, useSnapshot } from 'contexts';
import { BargeInPolicy, CLU, CLUData, PolicyType, Step, StepType, Ask, Environment, CLUIntent, ResourceType, AzureLanguage, InputStepTypes } from 'internal/models';
import { GetAllPoliciesOfType } from 'internal/utilities';
import React, { useEffect, useState } from 'react';
import { useJSONModal } from 'ui/components';
import { PopToastEvent } from 'ui/events';


type CLUTestModalProps = {
    step: Step,
    iconColor?: string
}

type IntentToTest = {
    ownerName: string,
    ownerId: string,
    resourceIdOrUniversalModel: string,
    intent: string,
    isUniversal: boolean,
    cluData?: CLUData
}

export const CLUTestModal : React.FC<CLUTestModalProps> = ({step}) => {
    const modal = usePortalModal();

    if (step.type !== StepType.CLU && step.type !== StepType.NumericInput && step.type !== StepType.YesNo) {
        return <></>;
    }

    return (
        <>
            <div className='icon-holder' onClick={() => modal.openModal(<CLUActualModal step={step} />)}>
                <Icon type={IconType.Test}  color={ColorHexes.Primary} />
            </div>
            <div style={{visibility: 'collapse', marginLeft: 0}}>
                {modal.modal}
            </div>
        </>
    )
}


const CLUActualModal : React.FC<CLUTestModalProps> = ({step}) => {
    const textId = useId("clu-test-text");
    const { snapshot } = useSnapshot();
    const client = useClient('resources');
    const modal = useFromModal();
    const flow = useFlow();
    const [isRunning, setIsRunning] = useState(false);
    const [text, setText] = useState('');
    const [includeBargeIns, setIncludeBargeIns] = useState(true);
    const [intentTests, setIntentTests] = useState<IntentToTest[]>([]);

    const runTest = async (environment: Environment) => {
        if (!text) {
            return;
        }


        const intentsToTest = new Array<IntentToTest>();
        if (includeBargeIns) {
            const policyData = GetAllPoliciesOfType<BargeInPolicy>(PolicyType.BargeIn, flow.flow, step.id);
            for (let bargeInPolicy of policyData) {
                for (let bargeIn of bargeInPolicy.policy.bargeIns) {
                    // Note: BargeIns should only have one intent. Only the first one would work anyways. So when we fix this later on, we can simplify this check.
                    const bargeInsToTest = bargeIn.intents.map(i => {
                        return { ownerId: bargeInPolicy.ownerId, ownerName: `${bargeInPolicy.ownerName}, BargeIn: ${bargeIn.name}`, intent: i.intent, resourceIdOrUniversalModel: i.resourceId, isUniversal: false};
                    });
                    intentsToTest.push(...bargeInsToTest);
                }
            }
        }

        if (step.type === StepType.CLU) {
            for(let intent of (step as CLU).intents) {
                if (intent.name && intent.resourceId) {
                    intentsToTest.push( { ownerId: step.id, ownerName: "Step", intent: intent.name, resourceIdOrUniversalModel: intent.resourceId, isUniversal: false});
                }
            }
        } else if (step.type === StepType.NumericInput) {
            intentsToTest.push( { ownerId: step.id, ownerName: "Step", intent: "SpecifyNumber", resourceIdOrUniversalModel: "Number", isUniversal: true});
        } else if (step.type === StepType.YesNo) {
            intentsToTest.push( { ownerId: step.id, ownerName: "Step", intent: "SpecifyYes/No", resourceIdOrUniversalModel: "Boolean", isUniversal: true});
        }

        let currentOwnerName = "";

        try {
    
            /** TODO: Flatten the run to ensure we don't run the same model multiple times. Presumably by grouping on resourceId. We don't want to dedupe intentsToTest, just the actual calls out to Resources. */
            for(let test of intentsToTest) {
                currentOwnerName = test.ownerName;
                const result = test.isUniversal 
                    ? await client.languages.testCLUUniversalModel(snapshot.id, test.resourceIdOrUniversalModel, environment, text)
                    : await client.languages.testCLUModel(snapshot.id, test.resourceIdOrUniversalModel, environment, text);
                if (result.success) {
                    test.cluData = result.data;
                } else {
                    throw new Error("Server failure while processing CLU results");
                }
            }
        } catch(e) {
            DefaultPublisher.publish(new PopToastEvent(`Testing failed when running for ${currentOwnerName}. Is the model it's using deployed?`, false, { type: 'error', autoClose: false}));
        } finally {
            setIsRunning(false);
        }

        setIntentTests([...intentsToTest]);
    }

    const onClick = (environment: Environment) => {
        setIsRunning(true);
        runTest(environment);
    }

    const header = {
        type: VisualCategory.Primary,
        content: <React.Fragment><Icon type={IconType.Test} /> CLU Testing</React.Fragment>
    }

    const content = (
        <div className='clu-test-modal-contents'>
            <Patience showPatience={isRunning}>
                <Field inputId={textId} label="Test Text" groupClass="col">
                    <input id={textId} className="form-control" type="text" value={text} onChange={evt => setText(evt.target.value)} />
                </Field>
                
                <Switch label='Include Barge-Ins' value={includeBargeIns} setValue={setIncludeBargeIns} isDisabled={false} />
                <CLUResultsView intentResults={intentTests} />
            </Patience>
        </div>
    );

    const buttons = (
        <React.Fragment>
            <Button action={() => onClick(Environment.DEV)} isDisabled={isRunning} type={VisualCategory.Primary}>Sandbox</Button>
            <Button action={() => onClick(Environment.QA)} isDisabled={isRunning} type={VisualCategory.Success}>Testing</Button>
            <Button action={() => onClick(Environment.UAT)} isDisabled={isRunning} type={VisualCategory.SecondaryAccent}>UAT</Button>
            <Button action={() => onClick(Environment.PROD)} isDisabled={isRunning} type={VisualCategory.Accent}>Live</Button>
            <Button action={() => modal.forceClose()} type={VisualCategory.Secondary}>Cancel</Button>
        </React.Fragment>
    )
    
    return (
        <PortalModal header={header} content={content} buttons={buttons} className='clu-test' />
    );
}

type CLUResultsViewProps = {
    intentResults: IntentToTest[]
}

type FlattenedResult = {
    ownerName: string,
    ownerId: string,
    modelName: string,
    expectedIntent: string,
    actualIntent: string,
    confidenceScore: number,
    json: any
}

const CLUResultsView: React.FC<CLUResultsViewProps> = ({intentResults}) => {
    const resources = useResources();
    const [results, setResults] = useState<FlattenedResult[]>([]);

    useEffect(() => {
        const newResults = new Array<FlattenedResult>();

        for(let result of intentResults) {
            if (result.cluData) {
                for(let intent of result.cluData.result.prediction.intents) {
                    const modelName = result.isUniversal 
                        ? result.resourceIdOrUniversalModel
                        : (resources.byType.get(ResourceType.AzureLanguage)?.find(r => r.id === result.resourceIdOrUniversalModel) as AzureLanguage)?.modelName ?? "Unknown";
                    newResults.push({ ownerName: result.ownerName, ownerId: result.ownerId, modelName: modelName, expectedIntent: result.intent, actualIntent: intent.category, confidenceScore: intent.confidenceScore, json: result.cluData.json });
                }
            }
        }
        newResults.sort((a, b) => {
            if (a.confidenceScore === b.confidenceScore) {
                return 0;
            }
            return a.confidenceScore > b.confidenceScore ? -1 : 1;
        });
        setResults(newResults);
    }, [intentResults]);

    return (
        <div className="ag-theme-balham" style={{ width: '850px' }}>
        <AgGridReact
            ensureDomOrder={true}
            disableStaticMarkup={true}
            columnDefs={cols}
            defaultColDef={{ cellStyle: { fontFamily: 'Quicksand', fontSize: '14px' } }}
            rowData={results}
            rowHeight={38}
            suppressRowTransform={true}
            stopEditingWhenCellsLoseFocus={true}
            domLayout='autoHeight' />
        </div>
    );
}

const JsonRenderer: React.FC<ICellRendererParams> = ({data}) => {
    const result = data as FlattenedResult;
    const modal = useJSONModal();

    return (
        <>
            <Button action={() => modal.open("View CLU Data", JSON.stringify(result.json))} type={VisualCategory.Accent} className='btn-sm'>JSON</Button>
            {modal.modal}
        </>
    )
}

const cols: ColDef[] = [
    {
      headerName: 'Owner',
      field: 'ownerName',
      width: 250,
      resizable: true,
      cellStyle: { padding: 0 },
      sortable: true
    },
    {
      headerName: 'Model Name',
      field: 'modelName',
      width: 180,
      resizable: true,
      cellStyle: { padding: 0 },
      sortable: true
    },
    {
      headerName: 'Expected Intent',
      field: 'expectedIntent',
      width: 100,
      resizable: true,
      cellStyle: { padding: 0 },
      sortable: true
    },
    {
      headerName: 'Intent',
      field: 'actualIntent',
      width: 100,
      resizable: true,
      cellStyle: { padding: 0 },
      sortable: true
    },
    {
      headerName: 'Confidence',
      field: 'confidenceScore',
      width: 100,
      resizable: true,
      cellStyle: { padding: 0 },
      sortable: true
    },
    {
      headerName: "CLU Data", 
      width: 100, 
      resizable: false, 
      cellRenderer: JsonRenderer
    }
  ];
/*

const EditValueModal: React.FC<StepModalProps> = ({label, icon, value, update}) => {
    const fieldId = useId("stepValue");
    const [newValue, setNewValue] = useState(value);
    const [isSaving, setIsSaving] = useState(false);
    const modal = useFromModal();

    const header = {
        type: VisualCategory.Primary,
        content: <React.Fragment><Icon type={icon} /> Edit {label}</React.Fragment>
    }

    const content = (
        <React.Fragment>
            <Field inputId={fieldId} label={label} name="description" groupClass="col">
                <input id={fieldId} className="form-control" type="text" value={newValue} onChange={evt => setNewValue(evt.target.value)} />
            </Field>
        </React.Fragment>
    );

    const cancel = () => modal.forceClose();
    const saveClick = async () => {
        setIsSaving(true);
        await update(newValue ?? "");
        modal.forceClose();
    }


    const buttons = (
        <React.Fragment>
            <Button action={saveClick} isDisabled={isSaving} type={VisualCategory.Primary}>{isSaving ? 'Saving...' : 'Save'}</Button>
            <Button action={cancel} type={VisualCategory.Secondary}>Cancel</Button>
        </React.Fragment>
    );

    return (
        <PortalModal header={header} content={content} buttons={buttons} />
    );
}

*/