import { useFlow } from 'contexts';
import { EmptyId } from 'internal/models';
import { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';

import { Option } from 'ui/types';

type FunctionDropDownProps = {
  functionId: string;
  onFunctionSelected: (objectId: string) => void;
  disabled?: boolean;
};

export const FunctionDropDown: React.FC<FunctionDropDownProps> = ({ functionId, onFunctionSelected, disabled }) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option>();
  const { flow } = useFlow();

  useEffect(() => {
    const options = [
      NoneOption,
      ...flow.functions.map((f) => {
        return { label: f.name, value: f.id };
      }),
    ];
    setOptions(options);
    const selected = options.find((o) => o.value === functionId) ?? NoneOption;
    setSelectedOption(selected);
  }, [flow.functions, functionId]);

  const onChange = (opt: SingleValue<Option>) => {
    setSelectedOption(opt ?? NoneOption);
    return onFunctionSelected(opt?.value ?? EmptyId);
  };

  return (
    <Select
      options={options}
      value={selectedOption}
      isDisabled={disabled}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 2147483647 }) }}
      onChange={onChange}
    />
  );
};

const NoneOption = { label: '-- Select --', value: EmptyId };
