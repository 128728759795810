import { BehaviorType } from "./BehaviorType";
import { Behavior } from "./Behavior";
import { BehaviorWithFollowUps } from "./IFollowUpList";
import { OutputMessage } from "internal/models";

export class TalkBehavior extends BehaviorWithFollowUps {
    output: OutputMessage[];

    constructor(output: OutputMessage[], followUpBehaviors: Behavior[]) {
        super(BehaviorType.Talk, followUpBehaviors);
        this.output = output;
    }

    static fromJson(json: any, followUps: Behavior[]): TalkBehavior {
        const outputs = json.statements && Array.isArray(json.output)
            ? json.statements.map((p: any) => OutputMessage.fromJson(p))
            : [];
        return new TalkBehavior(outputs, followUps);
    }
}