import { IconType } from '@smartaction/styles';
import { CallAPI } from 'internal/models/bots/design/flow/steps/data/CallAPI';
import React, { useEffect, useState } from 'react';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { useBots, useClient, useContextItems, useFlow, useObjects, useSnapshot } from 'contexts';
import Select, { SingleValue } from 'react-select';
import { useAPIs } from 'contexts/design/APISContext';
import {
  EmptyId,
  Endpoint,
  Parameter,
  Pointer,
  PointerType,
  EmptyPointer,
  ContextType,
  ContextTypeNames,
  ContextPointer,
} from 'internal/models';
import { ParameterAssignment } from 'internal/models';
import { PostError } from 'ui/events';
import { PointerControl } from 'ui/components';
import { DataStepsColors } from '../../StepColorGroups';
import { Option } from 'ui/types';
import { AssignmentsSelect } from './AssignmentsSelect';

export const CallAPIStyling: StepStyling = {
  typeName: 'Call API',
  icon: IconType.Link,
  ...DataStepsColors,
};

export const CallAPIStepView: React.FC<TypedStepProps<CallAPI>> = ({ step, manipulateStep }) => {
  return (
    <StepView step={step} styling={CallAPIStyling} isCollapsible={true} manipulateStep={manipulateStep}>
      <StepDetails step={step} manipulateStep={manipulateStep} />
    </StepView>
  );
};

const StepDetails: React.FC<TypedStepProps<CallAPI>> = ({ step }) => {
  const apis = useAPIs().apis;
  const snapshot = useSnapshot();
  const flow = useFlow();
  const client = useClient('flow');
  const { isReadOnlyBot } = useBots();
  const emptyOption = {
    label: '-- Select --',
    value: { apiId: EmptyId, endpointId: EmptyId },
  };
  const [options, setOptions] = useState<CustomOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<CustomOption>(emptyOption);
  const [currentEndpoint, setCurrentEndpoint] = useState<Endpoint>();
  const [inputAssignmentItems, setInputAssignmentItems] = useState<Array<AssignmentItem>>([]);
  const [outputAssignmentItems, setOutputAssignmentItems] = useState<Array<AssignmentItem>>([]);

  const [selectedInputAssignmentItems, setSelectedInputAssignmentItems] = useState<Array<AssignmentItem>>([]);
  const [selectedOutputAssignmentItems, setSelectedOutputAssignmentItems] = useState<Array<AssignmentItem>>([]);

  useEffect(() => {
    setOptions([
      emptyOption,
      ...apis.flatMap((a) => {
        return a.endpoints.map((e) => {
          const option = {
            label: `${a.name}-${e.name}`,
            value: { apiId: a.id, endpointId: e.id },
          };

          if (a.id === step.apiId && e.id === step.endpointId) {
            setSelectedOption(option);
          }

          return option;
        });
      }),
    ]);

    const endpoint = apis.find((a) => a.id === step.apiId)?.endpoints.find((e) => e.id === step.endpointId);

    setCurrentEndpoint(endpoint);

    if (endpoint) {
      let inputs = createAndConvertAssignmentItems(step.inputAssignments, endpoint.inputs);
      setInputAssignmentItems(inputs);

      let outputs = createAndConvertAssignmentItems(step.outputAssignments, endpoint.outputs);
      setOutputAssignmentItems(outputs);
    }
  }, []);

  useEffect(() => {
    //Get latest endpoint details
    const endpoint = apis.find((a) => a.id === step.apiId)?.endpoints.find((e) => e.id === step.endpointId);

    if (endpoint) {
      setCurrentEndpoint(endpoint);

      let inputs = createAndConvertAssignmentItems(step.inputAssignments, endpoint.inputs);
      setInputAssignmentItems(inputs);

      let outputs = createAndConvertAssignmentItems(step.outputAssignments, endpoint.outputs);
      setOutputAssignmentItems(outputs);
    }
  }, [step.inputAssignments, step.outputAssignments, currentEndpoint, apis]);

  const onSelectOptionChange = (selection: SingleValue<CustomOption>) => {
    let apiId = EmptyId;
    let endpointId = EmptyId;
    let inputs = new Array<Parameter>();
    let outputs = new Array<Parameter>();

    if (selection) {
      const endpoint = apis
        .find((a) => a.id === selection.value.apiId)
        ?.endpoints.find((e) => e.id === selection.value.endpointId);

      if (endpoint) {
        setCurrentEndpoint(endpoint);
        apiId = selection.value.apiId;
        endpointId = selection.value.endpointId;
        inputs = endpoint.inputs;
        outputs = endpoint.outputs;
      } else {
        PostError('No Endpoint was found during selection of Api');
      }
    }

    client.steps.callAPIs.setAPIAndEndpointAsync(snapshot.snapshot.id, step.id, apiId, endpointId).then((x) => {
      if (x.success) {
        flow.updateFlow(() => {
          step.apiId = apiId;
          step.endpointId = endpointId;
          step.inputAssignments = inputs.map((i) => new ParameterAssignment(i.id, new EmptyPointer()));
          step.outputAssignments = outputs.map((o) => new ParameterAssignment(o.id, new EmptyPointer()));
          client.steps.callAPIs.setInputAssignmentsAsync(snapshot.snapshot.id, step.id, step.inputAssignments);
          client.steps.callAPIs.setOutputAssignmentsAsync(snapshot.snapshot.id, step.id, step.outputAssignments);
        });
        setSelectedOption(selection === null ? emptyOption : selection);
      }
    });
  };

  const setSelectedAssignmentsToVisible = (objects: AssignmentItem[], objectsToMatch: Option[]) => {
    return objects.map((obj) =>
      objectsToMatch.map((obj) => obj.value).includes(obj.id) ? { ...obj, isVisible: true } : obj,
    );
  };
  const onAssignmentItemsChange = (selection: Option[]) => {
    setSelectedInputAssignmentItems(setSelectedAssignmentsToVisible(inputAssignmentItems, selection));
    setSelectedOutputAssignmentItems(setSelectedAssignmentsToVisible(outputAssignmentItems, selection));
  };

  const callAPIInputsUpdate = (list: ParameterAssignment[]) => {
    client.steps.callAPIs.setInputAssignmentsAsync(snapshot.snapshot.id, step.id, list).then((res) => {
      if (res.success) {
        flow.updateFlow(() => {
          step.inputAssignments = list;
        });
      }
    });
  };

  const callAPIOutputsUpdate = (list: ParameterAssignment[]) => {
    client.steps.callAPIs.setOutputAssignmentsAsync(snapshot.snapshot.id, step.id, list).then((res) => {
      if (res.success) {
        flow.updateFlow(() => {
          step.outputAssignments = list;
        });
      }
    });
  };

  const assignments =
    step.endpointId === EmptyId || selectedOption.value.endpointId === EmptyId ? (
      <React.Fragment />
    ) : (
      <div style={{ pointerEvents: isReadOnlyBot ? 'none' : 'initial' }}>
        <AssignmentsSelect
          onAssignmentItemsChange={onAssignmentItemsChange}
          inputAssignmentItems={inputAssignmentItems}
          outputAssignmentItems={outputAssignmentItems}
        />
        <AssignmentList
          name="Selected Inputs"
          list={selectedInputAssignmentItems}
          allowConfigs={true}
          update={callAPIInputsUpdate}
        />
        <AssignmentList
          name="Selected Outputs"
          list={selectedOutputAssignmentItems}
          allowConfigs={false}
          update={callAPIOutputsUpdate}
        />
      </div>
    );

  return (
    <div>
      <h6>Endpoint</h6>
      <Select
        isDisabled={isReadOnlyBot}
        onChange={onSelectOptionChange}
        hideSelectedOptions={true}
        value={selectedOption}
        options={options}
      />
      {assignments}
    </div>
  );
};

const convertToAssignmentItems = (assignments: ParameterAssignment[], parameters: Parameter[]) => {
  const items = new Array<AssignmentItem>();
  for (let a of assignments) {
    let foundParameter = parameters.find((p) => p.id === a.parameterId);
    if (foundParameter) {
      items.push({ id: a.parameterId, name: foundParameter!.name, pointer: a.pointer });
    }
  }
  return items;
};

const createAndConvertAssignmentItems = (assignments: ParameterAssignment[], parameters: Parameter[]) => {
  const items = convertToAssignmentItems(assignments, parameters);
  for (let param of parameters) {
    let foundMatch = items.find((i) => i.id === param.id);
    if (!foundMatch) {
      items.push({ id: param.id, name: param.name, pointer: new EmptyPointer() });
    }
  }
  return items;
};

type CustomOption = {
  label: string;
  value: CustomValue;
};

type CustomValue = {
  apiId: string;
  endpointId: string;
};

export type AssignmentItem = {
  id: string;
  name: string;
  pointer: Pointer;
  isVisible?: boolean;
};

type AssignmentListProps = {
  name: string;
  list: AssignmentItem[];
  update: (list: ParameterAssignment[]) => void;
  allowConfigs: boolean;
};

const AssignmentList: React.FC<AssignmentListProps> = ({ name, list, update, allowConfigs }) => {
  const executeUpdate = (id: string, pointer: Pointer) => {
    const item = list.find((i) => i.id === id);
    if (item === undefined) {
      return;
    }

    item.pointer = pointer;

    const assignments = list.map((i) => new ParameterAssignment(i.id, i.pointer));
    update(assignments);
  };

  const items = list.length ? (
    list.map((i) => (
      <div style={{ display: i.isVisible ? 'initial' : 'none' }} key={`assignmentContainer-${i.id}`}>
        <div className="assignmentContainer">
          <Assignment id={i.id} name={i.name} pointer={i.pointer} update={executeUpdate} allowConfigs={allowConfigs} />
        </div>
        <div className="line"></div>
      </div>
    ))
  ) : (
    <i style={{ color: 'grey' }}>None</i>
  );

  return (
    <div>
      <h6>{name}</h6>
      {items}
    </div>
  );
};

type AssignmentProps = AssignmentItem & {
  update: (id: string, pointer: Pointer) => void;
  allowConfigs: boolean;
};

const Assignment: React.FC<AssignmentProps> = ({ id, name, pointer, update, allowConfigs }) => {
  const contextItems = useContextItems();
  const objects = useObjects();
  const handlePointControlUpdate = (newValue: Pointer) => {
    update(id, newValue ?? new EmptyPointer());
  };

  let contextItemsOptions = [
    ...contextItems.contextItems
      .map((c) => {
        if (c.type === ContextType.Object && c.typeId) {
          const obj = objects.map.get(c.typeId);
          if (obj) {
            return { label: `${c.name} (type: ${obj.name})`, value: c.id };
          }
        }
        return { label: `${c.name} (type: ${ContextTypeNames.get(c.type)!})`, value: c.id };
      })
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];

  const handlePointControlChange = (newValue: string) => {
    const matchedNameAndContextItem = contextItemsOptions.find((item) => item.label.includes(name));

    if (newValue === PointerType.Context && matchedNameAndContextItem) {
      handlePointControlUpdate(new ContextPointer(matchedNameAndContextItem.value, EmptyId));
    }
  };

  let configsAllowed = allowConfigs ? [PointerType.Context, PointerType.Config] : [PointerType.Context];

  return (
    <React.Fragment>
      <label className="callAPILabel me-2">{name}</label>

      <PointerControl
        types={configsAllowed}
        pointer={pointer}
        change={handlePointControlChange}
        update={handlePointControlUpdate}
      />
    </React.Fragment>
  );
};
