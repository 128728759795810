import { Collapsible } from '@smartaction/visuals';
import React from 'react';
import { NewStep } from './index';
import {
  CLUStyling,
  IfStyling,
  SayStyling,
  SendSMSStyling,
  TransferStyling,
  HangupStyling,
  GoToStyling,
  CallAPIStyling,
  ScriptStyling,
  DTMFStyling,
  CallFunctionStyling,
  SetValuesStyling,
  NumericInputStyling,
  YesNoStyling,
  TextEntryStyling,
  StepStyling,
  AddressInputStyling,
  SetLanguageStyling,
  SetLocationStyling,
  TriggerAgentStyling,
  WaitForAgentStyling,
  MediaInputStyling,
  BreakStyling,
} from '../stepManagement';
import {
  If,
  Say,
  SendSMS,
  CLU,
  GoTo,
  CallAPI,
  PhoneTransfer,
  EmptyPointer,
  Hangup,
  DTMFMenu,
  Script,
  CallFunction,
  NumericInput,
  SetValues,
  YesNo,
  TextEntry,
  Step,
  AddressInput,
  SetLanguage,
  ReceiveMedia,
  SetLocation,
  TriggerAgent,
  WaitForAgent,
  Break,
} from 'internal/models';
import { DroppableData } from '@smartaction/visuals';

export type StepsSectionProps = {
  data: DroppableData;
};

const inputSteps = [
  {
    step: () =>
      new NumericInput(
        undefined,
        'Numeric Input',
        '',
        [],
        [],
        new EmptyPointer(),
        0,
        0,
        false,
        false,
        false,
        '',
        '',
        false,
      ),
    styling: NumericInputStyling,
  },
  {
    step: () => new CLU(undefined, 'CLU Step', '', [], [], new EmptyPointer(), [], false),
    styling: CLUStyling,
  },
  {
    step: () => new DTMFMenu(undefined, 'DTMF Menu', '', [], [], [], new EmptyPointer(), false),
    styling: DTMFStyling,
  },
  {
    step: () => new YesNo(undefined, 'Yes/No', '', [], [], new EmptyPointer(), false),
    styling: YesNoStyling,
  },
  {
    step: () => new TextEntry(undefined, 'Text Entry', '', [], [], new EmptyPointer(), false),
    styling: TextEntryStyling,
  },
  {
    step: () => new AddressInput(undefined, 'Address Input', '', [], [], new EmptyPointer(), false),
    styling: AddressInputStyling,
  },
  {
    step: () => new ReceiveMedia(undefined, 'Receive Media', '', [], [], new EmptyPointer(), false),
    styling: MediaInputStyling,
  },
];

const outputSteps = [
  {
    step: () => new Say(undefined, 'Say Step', '', [], []),
    styling: SayStyling,
  },
  {
    step: () => new SendSMS(undefined, 'Send SMS Step', '', [], [], new EmptyPointer(), new EmptyPointer()),
    styling: SendSMSStyling,
  },
];

const processSteps = [
  {
    step: () => new If(undefined, 'If Step', '', [], []),
    styling: IfStyling,
  },
  {
    step: () => new GoTo(undefined, 'GoTo Step', '', [], undefined),
    styling: GoToStyling,
  },
  {
    step: () => new CallFunction(undefined, 'Call Function', '', [], ''),
    styling: CallFunctionStyling,
  },
  {
    step: () => new SetLanguage(undefined, 'Set Language', '', [], ''),
    styling: SetLanguageStyling,
  },
  {
    step: () => new SetLocation(undefined, 'Set Location', '', [], [], '', ''),
    styling: SetLocationStyling,
  },
  {
    step: () => new TriggerAgent(undefined, 'Trigger Agent', '', [], '', '', '', '', []),
    styling: TriggerAgentStyling,
  },
  {
    step: () => new WaitForAgent(undefined, 'Wait For Agent', '', [], '', []),
    styling: WaitForAgentStyling,
  },
  {
    step: () => new Break(undefined, 'Break', '', []),
    styling: BreakStyling,
  },
];

const endConversationSteps = [
  {
    step: () => new PhoneTransfer(undefined, 'Transfer Step', '', [], false, new EmptyPointer(), []),
    styling: TransferStyling,
  },
  {
    step: () => new Hangup(undefined, 'Hangup', '', []),
    styling: HangupStyling,
  },
];

const dataSteps = [
  {
    step: () => new CallAPI(undefined, 'Call API', '', [], '', '', [], []),
    styling: CallAPIStyling,
  },
  {
    step: () => new Script(undefined, 'Script', '', [], ''),
    styling: ScriptStyling,
  },
  {
    step: () => new SetValues(undefined, 'Set Values', '', [], new EmptyPointer(), []),
    styling: SetValuesStyling,
  },
];
export const StepsSection: React.FC<StepsSectionProps> = ({ data }) => {
  const createStepsView = (name: string, steps: { step: () => Step; styling: StepStyling }[]) => {
    return (
      <Collapsible labelContent={name} expandedByDefault={true}>
        <div className="row g-1">
          {steps.map(({ step, styling }) => {
            const s = step();
            return (
              <div className="col-6" key={`toolbar-step-${s.type}`}>
                <NewStep step={s} toolbar={data} styling={styling} />
              </div>
            );
          })}
        </div>
      </Collapsible>
    );
  };
  return (
    <React.Fragment>
      {createStepsView('Input', inputSteps)}
      {createStepsView('Output', outputSteps)}
      {createStepsView('Process', processSteps)}
      {createStepsView('Data', dataSteps)}
      {createStepsView('End Conversation', endConversationSteps)}
    </React.Fragment>
  );
};
