import React from 'react';
import { PublishedSnapshot, UnpublishedSnapshot, UnpublishedState } from 'internal/models';

type SnapshotData = {
  snapshot: PublishedSnapshot | UnpublishedSnapshot;
  /**
  * @deprecated Do not use this, use "useBots()" isReadOnlyBot
  */
  isEditable: boolean;
};

const SnapshotContext = React.createContext<SnapshotData | undefined>(undefined);

type SnapshotProviderProps = {
  snapshot: PublishedSnapshot | UnpublishedSnapshot;
  children: React.ReactNode
};

export const SnapshotProvider: React.FC<SnapshotProviderProps> = ({ snapshot, children }) => {
  return (
    <SnapshotContext.Provider
      value={{
        snapshot: snapshot,
        isEditable:
          snapshot instanceof UnpublishedSnapshot && (snapshot as UnpublishedSnapshot).state === UnpublishedState.Draft,
      }}
    >
      {children}
    </SnapshotContext.Provider>
  );
};

export const useSnapshot = () => {
  const context = React.useContext(SnapshotContext);

  if (context === undefined) {
    throw new Error('useSnapshot must be used within a SnapshotProvider!');
  }

  return context!;
};
