import { Policy } from "../Policy";
import { PolicyType } from "../PolicyType";

export class InputFormatterPolicy extends Policy {
    script: string;

    constructor(id: string, ownerId: string, script: string) {
        super(id, ownerId, PolicyType.InputFormatter);
        this.script = script;
    }

    static fromJson(json: any): InputFormatterPolicy {
        return new InputFormatterPolicy(json.id, json.ownerId, json.script);
    }
}