import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, Patience, useSidePanel } from '@smartaction/visuals';
import React, { useEffect, useState } from 'react';
import { useFlow } from 'contexts';
import Select, { SingleValue } from 'react-select';
import { GroupedOption, Option } from 'ui/types';
import { useClient, useResources, useSnapshot } from 'contexts';
import {
  CLU,
  AzureLanguage,
  ContextPointer,
  Entity,
  Intent,
  Pointer,
  EmptyPointer,
  EmptyId,
  ResourceType,
  PointerControlFilters,
} from 'internal/models';
import { ContextSelect } from 'ui/components';

type ResourceOption = Option & { resourceId: string; isDisabled?: boolean };

export const CLUIntentEditor: React.FC<{ step: CLU; index: number; fixedMenu: boolean }> = ({
  step,
  index,
  fixedMenu,
}) => {
  const snapshot = useSnapshot();
  const flowClient = useClient('flow');
  const resources = useResources();
  const { updateFlow } = useFlow();
  const sidePanel = useSidePanel();

  const noIntent: ResourceOption = { label: 'No Intent', value: '', resourceId: '' };
  const [options, setOptions] = useState<ResourceOption[]>([]);
  const [selected, setSelected] = useState<ResourceOption>();
  const [isSaving, setIsSaving] = useState(false);
  const intent = step.intents[index];

  useEffect(() => {
    let options: any[] = [noIntent];
    if (!intent) {
      sidePanel.clearContents();
      sidePanel.close();
      return;
    }

    if (!intent.name) {
      setSelected(noIntent);
    }

    const azureLanguageResources = (resources.byType.get(ResourceType.AzureLanguage) as AzureLanguage[]) ?? [];
    azureLanguageResources.forEach((nlu) => {
      if (!nlu) return;

      const resourceOptions = nlu.intents
        ?.filter((i) => i.name !== 'None')

        .map((el) => {
          let isDisabled =
            !(intent?.name === el.name && intent?.resourceId === nlu.id) && // not selected
            step.intents.some((e) => e.name === el.name && intent?.resourceId === nlu.id);
          return CreateResourceOption(el.name, el.name, nlu.id, isDisabled);
        });

      const selectedIntent = resourceOptions?.find(
        (el) => intent && el.value === intent.name && el.resourceId === intent.resourceId,
      );
      if (selectedIntent) setSelected(selectedIntent);

      options.push({
        label: nlu.modelName,
        options: resourceOptions,
      });
    });

    setOptions(options);
    setIsSaving(false);
  }, [intent]);

  if (!intent) {
    return <React.Fragment />;
  }

  const deleteEntity = (intentIndex: number, entityIndex: number) => {
    const entity = intent.entities[entityIndex];

    flowClient.steps.clus.deleteEntity(snapshot.snapshot.id, step.id, intent.id, entity.name).then((r) => {
      if (r.success) {
        updateFlow(() => {
          intent.entities.splice(entityIndex, 1);

          if (intent.entities.length === 0) {
            intent.resourceId = EmptyId;
          }
          step.intents[intentIndex] = { ...intent };
        });
      }
    });
  };

  const onSetEntity = async (resourceId: string, entityName: string, saveLocation: Pointer, entityIndex: number) => {
    setIsSaving(true);
    const entity = new Entity(entityName, saveLocation);

    // new entity
    if (entityIndex === -1) {
      const result = await flowClient.steps.clus.setEntity(
        snapshot.snapshot.id,
        step.id,
        intent.id,
        resourceId,
        entity.name,
        saveLocation,
      );
      if (result.success) {
        updateFlow(() => {
          intent.entities.push(entity);
          setIsSaving(false);
        });
      } else {
        // failed, but the toast should alert them
        setIsSaving(false);
      }
      return;
    }

    // user is switching to a different entity, so we have to delete the old one
    // Note that we're not refreshing flow immediately, because that'll cause the entities to reorder (we delete the old one and add the new entity name,
    // causing it to be inserted at the end of the entities list).
    // Until the user refreshes, it's a better experience to leave the changed one in its new place.
    if (entity.name !== intent.entities[entityIndex].name) {
      const result = await flowClient.steps.clus.deleteEntity(
        snapshot.snapshot.id,
        step.id,
        intent.id,
        intent.entities[entityIndex].name,
      );

      // failed, but the toast should alert them
      if (!result.success) {
        setIsSaving(false);
        return;
      }
    }
    const result = await flowClient.steps.clus.setEntity(
      snapshot.snapshot.id,
      step.id,
      intent.id,
      resourceId,
      entity.name,
      saveLocation,
    );
    if (result.success) {
      updateFlow(() => {
        intent.entities[entityIndex] = entity;
      });
    }
    setIsSaving(false);
  };

  const onDeleteEntity = (entityIndex: number) => {
    setIsSaving(true);
    deleteEntity(index, entityIndex);
  };

  return (
    <Patience showPatience={isSaving}>
      <span>Mappings</span>
      {intent.entities.map((e, eIndex) => (
        <EntitySelect
          key={e.name}
          index={eIndex}
          intent={intent}
          entity={e}
          updateEntity={onSetEntity}
          deleteEntity={onDeleteEntity}
          fixedMenu={fixedMenu}
        />
      ))}
      <EntitySelect
        key="new-entity"
        intent={intent}
        updateEntity={onSetEntity}
        deleteEntity={onDeleteEntity}
        index={-1}
        fixedMenu={fixedMenu}
      />
    </Patience>
  );
};

const CreateResourceOption = (
  label: string,
  value: string,
  resourceId: string,
  isDisabled: boolean,
): ResourceOption => {
  return {
    label: label,
    value: value,
    resourceId: resourceId,
    isDisabled: isDisabled,
  };
};

const GenerateIntentEntityOptions = (
  resources: AzureLanguage[],
  intent: Intent,
  setSelectedEntity: React.Dispatch<React.SetStateAction<ResourceOption | undefined>>,
  entity?: Entity,
): ResourceOption[] | GroupedOption[] => {
  ///Already set entity
  if (intent.name && intent.resourceId !== EmptyId) {
    const nlu = resources.find((r) => r.id === intent.resourceId)!;
    let resourceOptions =
      nlu.intents
        ?.find((i) => i.name === intent.name)
        ?.entities.map((entityName) => {
          let isDisabled = entity?.name !== entityName && intent.entities.some((e) => e.name === entityName);
          return CreateResourceOption(entityName, entityName, nlu.id, isDisabled);
        }) || [];

    let selectedEntity = resourceOptions?.find((el) => entity && el.value === entity.name);

    if (selectedEntity) {
      setSelectedEntity(selectedEntity);
    }

    return resourceOptions;
  }

  //No intents Section
  //Since a 'No Intent' can potentially have an entity set, see if we can find
  //location of resource. This also handles Entity Select when attempting
  //a new mapping.
  let findLocationResource = resources.findIndex((x) => {
    const nlu = x as AzureLanguage;

    if (!nlu) return;

    if (!entity && intent.entities.length > 0) {
      return nlu.entities.some((x) => x === intent.entities[0].name);
    }

    return nlu.entities.some((x) => x === entity?.name);
  });
  if (findLocationResource !== -1) {
    let options: GroupedOption[] = [];
    const nlu = resources[findLocationResource] as AzureLanguage;

    const resourceGroupOption = nlu.entities.map((entityName) => {
      let isDisabled = entity?.name !== entityName && intent.entities.some((e) => e.name === entityName);
      return CreateResourceOption(entityName, entityName, nlu.id, isDisabled);
    });

    const selectedEntity = resourceGroupOption.find((option) => entity && option.value === entity.name);
    setSelectedEntity(selectedEntity || { label: 'Select...', value: '', resourceId: '' });

    options.push({ label: nlu.modelName, options: resourceGroupOption });

    return options;
  } else {
    let options: GroupedOption[] = [];
    resources.forEach((r) => {
      const nlu = r as AzureLanguage;
      if (!nlu) return;

      const resourceGroupOption = nlu.entities.map((entityName) => {
        let isDisabled =
          entity?.name !== entityName &&
          intent?.resourceId !== nlu.id &&
          intent.entities.some((e) => e.name === entityName);
        return CreateResourceOption(entityName, entityName, nlu.id, isDisabled);
      });
      const selectedEntity = resourceGroupOption.find((option) => entity && option.value === entity.name);
      setSelectedEntity(selectedEntity || { label: 'Select...', value: '', resourceId: '' });

      options.push({
        label: nlu.modelName,
        options: resourceGroupOption,
      });
    });

    return options;
  }
};

type EntitySelectProps = {
  updateEntity: (resourceId: string, entityName: string, saveLocation: Pointer, index: number) => void;
  deleteEntity: (index: number) => void;
  intent: Intent;
  index: number;
  entity?: Entity;
  fixedMenu: boolean;
};

export const EntitySelect: React.FC<EntitySelectProps> = ({
  updateEntity,
  deleteEntity,
  index,
  entity,
  intent,
  fixedMenu,
}) => {
  const resources = useResources();
  const [contextItemId, setContextItemId] = useState('');

  const [options, setOptions] = useState<ResourceOption[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<ResourceOption>();
  console.log(`Options: ${JSON.stringify(options)}`);
  useEffect(() => {
    let options: any[] = [];

    const azureLanguageResources = (resources.byType.get(ResourceType.AzureLanguage) as AzureLanguage[]) ?? [];
    options = GenerateIntentEntityOptions(azureLanguageResources, intent, setSelectedEntity, entity);

    if (options.some((o) => !o.isDisabled)) {
      setOptions(options);
    }

    setContextItemId((entity?.saveLocation as ContextPointer)?.contextItemId);
  }, [intent, entity]);

  const changePointer = (contextItemId?: string, propertyId?: string) => {
    setContextItemId(contextItemId ?? EmptyId);
    if (!selectedEntity) return;
    updateEntity(
      selectedEntity.resourceId,
      selectedEntity.value,
      contextItemId ? new ContextPointer(contextItemId, propertyId ?? EmptyId) : new EmptyPointer(),
      index,
    );
    reset();
  };

  const onChangeEntity = (selected: SingleValue<Option & { resourceId: string }>) => {
    if (!selected) return;
    setSelectedEntity(selected);
    updateEntity(
      selected.resourceId,
      selected.value,
      contextItemId ? new ContextPointer(contextItemId, EmptyId) : new EmptyPointer(),
      index,
    );
    reset();
  };

  const reset = () => {
    if (index === undefined) {
      setContextItemId('');
      setSelectedEntity({ label: 'Select...', value: '', resourceId: '' });
    }
  };

  return (
    <>
      {
        // render only if any
        options.length > 0 && (
          <div className="flex-fill">
            <Select
              options={options}
              value={selectedEntity}
              onChange={onChangeEntity}
              menuPosition={fixedMenu ? 'fixed' : undefined}
            />
            <span className="pt-2">
              <Icon type={IconType.Right} />
            </span>
            <ContextSelect
              selectedContextId={contextItemId}
              updateSelectedContext={changePointer}
              filters={[PointerControlFilters.HideStaticContextItems]}
              isDisabled={!entity?.name}
            ></ContextSelect>
            {index !== undefined && (
              <Button isDisabled={!entity?.name} type={VisualCategory.Danger} action={() => deleteEntity(index)}>
                <Icon type={IconType.Delete} />
              </Button>
            )}
          </div>
        )
      }
      {options.length === 0 && intent.entities.length === 0 && (
        <div>
          <em>
            <small>No entities available, please add some and refresh your resources</small>
          </em>
        </div>
      )}
    </>
  );
};
