import { Behavior, BehaviorBuilder } from "../behaviors";
import { Policy } from "../Policy";
import { PolicyType } from "../PolicyType";

export class RetryPolicy extends Policy {

    maxAttempts: number;
    behavior: Behavior;

    constructor(id: string, ownerId: string, maxAttempts: number, behavior: Behavior) {
        super(id, ownerId, PolicyType.Retry);
        this.maxAttempts = maxAttempts;
        this.behavior = behavior;
    }

    static fromJson(json: any): RetryPolicy {
        const behavior = BehaviorBuilder(json.behavior);
        return new RetryPolicy(json.id, json.ownerId, json.maxAttempts, behavior);
    }

}