import React, { useState, useEffect } from 'react';
import { Property } from 'csstype';
import { splitLongString } from 'ui/utils';

export type CircleProps = {
    textColor?: Property.Color,
    backgroundColor?: Property.Color,
    text: string,
    size: number,
    policyIcon?: React.ReactNode,
    isSelected: boolean,
}

export const Circle: React.FC<CircleProps> = ({textColor = 'black', backgroundColor = 'white', text = '', size = 1, policyIcon, isSelected}) => {
    const [firstLine, setFirstLine] = useState('');
    const [secondLine, setSecondLine] = useState('');

    useEffect(() => {
        let lines = splitLongString(text, 20);
        setFirstLine(lines[0]);
        setSecondLine(lines[1]);
    }, [text])


    let additionalLine = secondLine.length > 0 ? (
        <p className='circle-text' style={{
            padding: `0`,
            color: textColor, 
            }}
        >
            {secondLine}
        </p>
    ) : <></>;
    
    return (
        <div className='circle' style={{height: (260 * size), width: (260 * size), margin: '8px', filter: isSelected ? 'drop-shadow(-4px 4px 8px rgba(0, 0, 0, 0.4))' : 'none' }}>
            <svg height='100%' width='100%' viewBox="0 0 130 130">
            <circle cx="65" cy="65" r="63" fill={backgroundColor} stroke="black" strokeWidth={isSelected ? 1.5 : 1} />

                <svg height='100%' width='100%' viewBox="0 0 170 170">
                    <foreignObject width="100%" height="100%">
                        <p className='circle-text' style={{
                            paddingTop: secondLine.length > 0 ? 60 : 65,
                            color: textColor, 
                            }}
                        >
                            {firstLine}
                        </p>
                        {additionalLine}             
                    </foreignObject>
                </svg>
            </svg>
            {policyIcon && <div className='policyIcon'>{policyIcon}</div>}
        </div>
    )
}