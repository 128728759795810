import { Pointer } from 'internal/models';
import { Output } from '../OutputMessages';
import { StepType } from '../Type';
import { Ask } from './Ask';

export class ReceiveMedia extends Ask {
  constructor(
    id: string | undefined,
    name: string,
    description: string,
    tags: string[],
    questions: Output[],
    saveLocation: Pointer,
    canInterrupt: boolean,
  ) {
    super(id, StepType.ReceiveMedia, name, description, tags, questions, saveLocation, canInterrupt);
  }

  static fromJson(json: any): ReceiveMedia {
    const questions =
      json.questions && Array.isArray(json.questions) ? json.questions.map((p: any) => Output.fromJson(p)) : [];
    return new ReceiveMedia(
      json.id,
      json.name,
      json.description,
      json.tags,
      questions,
      Pointer.fromJson(json.saveLocation),
      json.canUserInterrupt,
    );
  }
}
