import { ValidationErrors } from '@smartaction/common';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import {
  Button,
  Field,
  Patience,
  PortalModal,
  useComplexState,
  useFromModal,
  useId,
} from '@smartaction/visuals';
import { useClient } from 'contexts/ClientContext';
import { Bot } from 'internal/models';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { ValidateBot } from '../../../internal/validators/foundation/BotValidator';
import { useBots, useTenants } from 'contexts';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

type CreateBotModalProps = {
  starterName?: string;
}

export const CreateBotModal: React.FC<CreateBotModalProps> = ({starterName = ""}) => {
  const client = useClient('bot');
  const modal = useFromModal();
  const bots = useBots();
  const tenants = useTenants();
  const navigate = useNavigate();
  const [name, setName] = useState(starterName);
  const [description, setDescription] = useState('');
  const [code, setCode] = useState('');
  const [tenantCodeForNewBot, setTenantCodeForNewBot] = useState(tenants.selectedTenant?.code ?? '');
  const [validationErrors, updateValidationErrors] = useComplexState(
    new ValidationErrors(['name', 'description', 'code']),
  );
  const nameId = useId('botName');
  const descriptionId = useId('botDescription');
  const codeId = useId('botCode');
  const tenantId = useId('tenant');
  const [isSaving, setIsSaving] = useState(false);

  

  useEffect(() => {
    const currentState = new Bot('', tenants.selectedTenant?.id ?? '', name, description, code, false, false);
    // Call new local validator here
    updateValidationErrors((ve) => ValidateBot(currentState, ve));
  }, [name, description, code]);

  const clearServerErrors = (propertyName: string) => {
    if (validationErrors) {
      updateValidationErrors((ve) => ve.clearServerErrorForProperty(propertyName));
    }
  };

  let tenantField = <React.Fragment />;

  if (tenants.availableTenants && tenants.availableTenants.size > 1) {
    const tenantOptions = Array.from(tenants?.availableTenants?.values() ?? []).map(tenant => ({label: tenant.name, value: tenant.code}));
    tenantField = (
      <Field inputId={tenantId} label="Tenant" name="tenant" groupClass="col">
        <Select
          options={tenantOptions}
          value={tenantOptions.find((t) => t.value === tenantCodeForNewBot)}
          onChange={(o) => {
            if (o) {
              setTenantCodeForNewBot(o.value);
            }
          }}
        />
        <div className="alert alert-warning" role="alert">
          Please verify that you are creating the bot in the correct tenant.
        </div>
      </Field>
    );
  }

  const save = async () => {
    setIsSaving(true);
    var tenant = tenants.availableTenants!.get(tenantCodeForNewBot)!;
    await client.createBotAsync(tenant.id, name, description, code, updateValidationErrors).then(async b => {
      if (b.success) {
        await bots.refreshBots();
        if (tenant.code !== tenants.selectedTenant?.code) {
          tenants.setTenant(tenant.code);
        }
        navigate(`/${tenant.code}/agents/${b.data!.code}`);
      }
    });
    modal.forceClose();
  };

  const cancel = () => {
    modal.forceClose();
  };

  return (
    <PortalModal
      header="Create New Bot"
      content={
        <Patience showPatience={isSaving}>
          <div>
            {tenantField}
            <Field inputId={nameId} label="Name" name="name" groupClass="col" validationErrors={validationErrors}>
              <input
                id={nameId}
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                onFocusCapture={() => {
                  clearServerErrors('name');
                }}
              />
            </Field>
            <Field
              inputId={descriptionId}
              label="Description"
              name="description"
              groupClass="col"
              validationErrors={validationErrors}
            >
              <input
                id={descriptionId}
                type="text"
                className="form-control"
                value={description}
                onChange={(e) => setDescription(e.currentTarget.value)}
                onFocusCapture={() => {
                  clearServerErrors('description');
                }}
              />
            </Field>
            <Field inputId={codeId} label="Code" name="code" groupClass="col" validationErrors={validationErrors}>
              <input
                id={codeId}
                type="text"
                className="form-control"
                value={code}
                onChange={(e) => setCode(e.currentTarget.value)}
                onFocusCapture={() => {
                  clearServerErrors('code');
                }}
              />
            </Field>
          </div>
        </Patience>
      }
      buttons={
        <React.Fragment>
          <Button className={'me-3'} type={VisualCategory.Primary} action={save} isDisabled={isSaving || validationErrors.hasAnyErrors}>
            <Icon type={IconType.Save} /> Save
          </Button>
          <Button type={VisualCategory.Secondary} action={cancel} isDisabled={isSaving}>
            Cancel
          </Button>
        </React.Fragment>
      }
    />
  );
};
