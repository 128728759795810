import { arrayRemove } from "@smartaction/common";
import { useClient, useSnapshot, useObjects } from "contexts";
import { ContextItem, ContextType } from "internal/models";
import React, { useEffect, useState } from "react";

const ContextItemsContext = React.createContext<ContextItemContextType | undefined>(undefined);

export type ContextItemContextType = {
    contextItems: ContextItem[],
    map: Map<string, ContextItem>,
    addItem: (name: string) => void,
    deleteItem: (contextItem: ContextItem) => void,
    refresh: (fromServer?: boolean) => void
}

type ContextItemProviderProps = {
    children: React.ReactNode
}

export const ContextItemProvider: React.FC<ContextItemProviderProps> = ({children}) => {
    const snapshot = useSnapshot();
    const [contextItems, setContextItems] = useState<ContextItem[]>([]);
    const [map, setMap] = useState<Map<string, ContextItem>>(new Map<string, ContextItem>());
    const client = useClient('context');
    const objects = useObjects();

    useEffect(() => {
        refresh(true);
    }, [snapshot.snapshot.id]);

    const refresh = (fromServer?: boolean) => {
        if (fromServer) {
            client.getContexts(snapshot.snapshot.id).then(res => {
                if (res.success) {
                    processContexts(res.data!);
                }
            });
        } else {
            // by putting them in a new array, it'll change the reference and React will refresh, which will also catch any updates (like name changes)
            processContexts(contextItems);
        }
    };

    const addItem = (name: string) => {
        const item = new ContextItem('', '', name, '', false, false, false, false, -1, "General", ContextType.String);
        client.createContext(snapshot.snapshot.id, item.name, item.type, item.isSensitive, item.isReporting).then(res => {
            if (res.success) {
                item.id = res.data!;
                item.originalId = item.id;
                contextItems.push(item);
                refresh(true);
            }
        })
    };

    const deleteItem = (contextItem: ContextItem) => {
        const index = contextItems.findIndex(ci => ci === contextItem);
        client.delete(snapshot.snapshot.id, contextItem.id).then(res => {
            if (res.success) {
                const items = arrayRemove(contextItems, index);
                processContexts(items);
                refresh(true);
            }
        });

    }

    const processContexts = (ctxItems: ContextItem[]) => {
        var map = new Map<string, ContextItem>();
        for(let ctxItem of ctxItems) {
            map.set(ctxItem.id, ctxItem);
        }
        setMap(map);
        //Check for missing typeIds and add Static typeIds if matching
        ctxItems.forEach((ctx)=>{
            if(!ctx.typeId){
                objects.staticObjects.forEach((obj) =>{
                    if(ctx.type === obj.name){
                        ctx.typeId = obj.id;
                    }
                })
            }
        })
        setContextItems([...ctxItems]);
    }

    return (
        <ContextItemsContext.Provider value={{map, contextItems, refresh, addItem, deleteItem}}>
            {children}
        </ContextItemsContext.Provider>
    )
}

export const useContextItems = () => {
    const context = React.useContext<ContextItemContextType|undefined>(ContextItemsContext);

    if (context === undefined) {
        throw new Error("useContextItems must be used within a ContextItemProvider!");
    }

    return context;
}