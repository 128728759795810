import React, {  } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ConversationIntent, IntentStatus } from 'internal/models/bots/design/conversation/ConversationIntent';

export const ConversationIntentsRenderer: React.FC<ICellRendererParams> = ({ colDef, value }) => {
    const intents = value as ConversationIntent[];

    return (
        <React.Fragment>
            { intents.filter(i => i.intentStatus !== IntentStatus.Started).map((intent, index) => {
                let color = '#000000';
                let fontWeight = 'normal';

                const hasStarted = !!intents.find(i => i.name === intent.name && i.intentStatus === IntentStatus.Started);
                if (hasStarted) {
                    fontWeight = 'bold';

                    if (intent.intentStatus === IntentStatus.Completed) {
                        color = '#54BD42';
                    } else if (intent.intentStatus === IntentStatus.Canceled) {
                        color = '#222222';
                    } else if (intent.intentStatus === IntentStatus.Failed) {
                        color = '#CA2027';
                    } else if (intent.intentStatus === IntentStatus.Transferred) {
                        color = '#DD8400';
                    }
                }

                return <>
                    { index ? ', ' : '' }
                    <span key={index} style={{ color, fontWeight }}>{intent.name}</span>
                </>;
            })}
        </React.Fragment>
    );
}
