import React from 'react';
import { ResourceItemType } from '../../ResourceManager';
import { useBots, useResources } from 'contexts';
import { Button, ModalContainerIds, usePortalModal } from '@smartaction/visuals';
import { VisualCategory } from '@smartaction/styles';
import { v4 as uuid } from 'uuid';
import { Table, TableRow } from 'ui/components';
import { AddGoogleAddressResourceModal } from './AddGoogleAddressResourceModal';
import { ApiCredentials } from '../../ApiCredentials';

export const GoogleAddressResourceType: React.FC<ResourceItemType> = ({
  resources,
  onChangeResourceItemName,
  onDeleteResourceItem,
}) => {
  const { isReadOnlyBot } = useBots();
  const { canManage } = useResources();
  const confirm = usePortalModal(ModalContainerIds.Modal);
  const isEditable = !isReadOnlyBot && canManage;
  const modal = usePortalModal();

  const handleCreateResource = () => modal.openModal(<AddGoogleAddressResourceModal />);

  const initialRowsData = resources
    ? resources.map((resource, index) => {
        return {
          id: uuid(),
          cells: [
            {
              type: 'input',
              disabled: !isEditable,
              value: resource.name,
              id: `${resource.type}-name-${index}`,
              onChange: ({ value }) => onChangeResourceItemName(resource.id, `${value}`),
            },
            {
              id: `${resource.type}-props-${index}`,
              type: 'customNode',
              customNode: (
                <>
                  <Button
                    action={() => confirm.openModal(<ApiCredentials id={resource.id} />)}
                    type={VisualCategory.Light}
                    className="btn-sm"
                  >
                    Api Key
                  </Button>
                  {confirm.modal}
                </>
              ),
            },
          ],
        } as TableRow;
      })
    : [];

  return (
    <div>
      <Table
        id={'googleAddressResourceType'}
        headersGroup={['Name', 'Properties', 'Actions']}
        columnsSize={['md', 400, 'sm']}
        rows={initialRowsData}
        onDeleteRow={(id: string) => onDeleteResourceItem(id)}
        footer={
          <Button isDisabled={!isEditable} action={handleCreateResource} type={VisualCategory.Primary}>
            Create
          </Button>
        }
      />
      {confirm.modal}
      {modal.modal}
    </div>
  );
};
