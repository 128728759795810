import { IEvent } from "@smartaction/common";
import { Config, Environment } from "internal/models";

export class LoadConfigValueEvent implements IEvent {
    public name: string = LoadConfigValueEvent.eventName;
    static eventName: "LoadConfigValueEvent" = "LoadConfigValueEvent";

    config: Config;
    environment: Environment;
    configSet: 'business' | 'technical';

    constructor(configSet: 'business' | 'technical', config: Config, environment: Environment) {
        this.config = config;
        this.environment = environment;
        this.configSet = configSet;
    }
}