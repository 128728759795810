import { ValidationErrors } from '@smartaction/common';
import { Bot } from 'internal/models';
import { DataResponse, IBotClient, Methods, ServerResponse, TeamsUrls, ValidateResponseActionEnum } from '.';

export class BotClient implements IBotClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }

  async getBotAsync(id: string): Promise<DataResponse<Bot>> {
    const response = await this.methods.get(`${this.getUrl(id)}`);
    const result = await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Get} a single bot`, response);
    if (!result.success) {
      return result;
    }
    const data = await response.json();

    return {
      success: true,
      data: Bot.fromJson(data),
    };
  }

  async getBotsAsync(): Promise<DataResponse<Array<Bot>>> {
    const response = await this.methods.get(`${this.getUrl()}`);
    const result = await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Get} bots`, response);
    if (!result.success) {
      return result;
    }
    const json = await response.json();
    const bots = json.map((b: any) => Bot.fromJson(b)) as Bot[];

    return {
      success: true,
      data: bots.filter(b => !b.isDeleted),
    };
  }

  async createBotAsync(
    tenantId: string,
    name: string,
    description: string,
    code: string,
    updateValidationErrors: (update: (errors: ValidationErrors) => void) => void,
  ): Promise<DataResponse<Bot>> {
    const request = {
      name: name,
      description: description,
      code: code,
      version: 'v1',
    };
    const response = await this.methods.post(`${this.serviceUrl}/tenant/${tenantId}/v1`, request);
    const result = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Created} bot`,
      response,
      updateValidationErrors,
    );

    if (!result.success) {
      return result;
    }

    const json = await response.json();
    return {
      success: true,
      data: Bot.fromJson(json),
    };
  }

  async getTeamsUrlsAsync(id: string): Promise<DataResponse<TeamsUrls>> {
    try {
      const response = await this.methods.get(`${this.getUrl(id)}/teamsUrls`);
      const validatedResponse = await this.methods.validateAndPopToast(
        `${ValidateResponseActionEnum.Get} Teams links for bot`,
        response,
      );
      if (!validatedResponse.success) {
        return validatedResponse;
      }
      const json = await response.json();
      return {
        success: true,
        data: json,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async updateApprovedByAsync(id: string, value: boolean): Promise<ServerResponse> {
    const response = await this.methods.post(
      `${this.getUrl()}snapshot/${id}/unpublished/approved-by?isApproved=${value}`,
    );
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} approved by`, response);
  }

  async markAsDeletedAsync(id: string): Promise<ServerResponse> {
    const response = await this.methods.delete(this.getUrl(id));

    return await this.methods.validateAndPopToast('Bot Deleted', response);
  }

  private getUrl(botId?: string) {
    const url = `${this.serviceUrl}/tenant/${this.methods.tenantId!}/v1/`;

    return botId ? url + botId : url;
  }
}
