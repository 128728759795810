import { BehaviorType, CallFunctionBehavior } from "internal/models";
import { BehaviorEditorProps } from "./Type";
import React from "react";
import { FunctionDropDown } from "ui/components";
import { Checkbox } from "@smartaction/visuals";


export const CallFunctionBehaviorView: React.FC<BehaviorEditorProps<CallFunctionBehavior>> = ({behavior, update}) => {
    return (
        <React.Fragment>
            <FunctionDropDown functionId={behavior.functionId} onFunctionSelected={(id) => update(new CallFunctionBehavior(id, behavior.repeatStep))} />
            <Checkbox label='Repeat Calling Step' value={behavior.repeatStep} setValue={(chk) => update(new CallFunctionBehavior(behavior.functionId, chk))} />
        </React.Fragment>
    );
}