import { useFlow } from 'contexts';
import { useEffect, useState } from 'react';
import Select, { components, SingleValue, ValueContainerProps } from 'react-select';

import { GroupedOption, Option } from 'ui/types';

type GoToProps = {
  goToNextId: string;
  onModuleItemSelected: (objectId: string) => void;
  disabled?: boolean;
};

export const GoToDropDown: React.FC<GoToProps> = ({ goToNextId, onModuleItemSelected, disabled }) => {
  const [options, setOptions] = useState<GroupedOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option>();
  const { flow } = useFlow();

  useEffect(() => {
    let selectOptions: GroupedOption[] = [];
    let modules = flow.modules;

    for (let module of modules) {
      let blocksAndDecisions = [...module.blocks, ...module.decisions];
      let currentSet = blocksAndDecisions.map((x, index) => {
        return {
          label: x.name,
          value: x.id,
        };
      });
      selectOptions.push({ label: module.name, options: currentSet });
    }

    setOptions(selectOptions);

    let selectedModule = selectOptions.filter((x) => x.options.find((y) => y.value === goToNextId))[0];

    if (!selectedModule) return;

    let selectedOption = selectedModule.options.find((x) => x.value === goToNextId);
    setSelectedOption(selectedOption || { label: '', value: '' });
  }, [flow, flow.modules, goToNextId]);

  const ValueContainer = ({ children, ...props }: ValueContainerProps<Option, false, GroupedOption>) => {
    if (!props.hasValue) {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    }

    let labelName = props.getValue()[0].label;
    let selectedModule = options.filter((x) => x.options.find((y) => y.label === labelName))[0];
    return <components.ValueContainer {...props}>{`${selectedModule.label} /${labelName}`}</components.ValueContainer>;
  };

  const onSelectChangeHandler = (selection: SingleValue<Option>) => {
    if (selection) {
      onModuleItemSelected(selection.value);
    }
  };

  return (
    <Select<Option, false, GroupedOption>
      onChange={onSelectChangeHandler}
      value={selectedOption}
      isDisabled={disabled}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 2147483647 }) }}
      options={options}
      components={{ ValueContainer: ValueContainer }}
    />
  );
};
