import { Pointer } from '../../shared';
import { Output } from '../OutputMessages';
import { StepType } from '../Type';
import { Ask } from './Ask';

export class NumericInput extends Ask {
  minLength: number;
  maxLength: number;
  poundToEnd: boolean;
  allowAsterisks: boolean;
  alternateResourceId: string;
  alternateIntent: string;
  excludeUniversalModel: boolean;

  constructor(
    id: string | undefined,
    name: string,
    description: string,
    tags: string[],
    questions: Output[],
    saveLocation: Pointer,
    minLength: number,
    maxLength: number,
    poundToEnd: boolean,
    canInterrupt: boolean,
    allowAsterisks: boolean,
    alternateResourceId: string,
    alternateIntent: string,
    excludeUniversalModel: boolean,
  ) {
    super(id, StepType.NumericInput, name, description, tags, questions, saveLocation, canInterrupt);
    this.minLength = minLength;
    this.maxLength = maxLength;
    this.poundToEnd = poundToEnd;
    this.allowAsterisks = allowAsterisks;
    this.alternateResourceId = alternateResourceId;
    this.alternateIntent = alternateIntent;
    this.excludeUniversalModel = excludeUniversalModel;

  }

  static fromJson(json: any): NumericInput {
    const questions =
      json.questions && Array.isArray(json.questions) ? json.questions.map((p: any) => Output.fromJson(p)) : [];
    return new NumericInput(
      json.id,
      json.name,
      json.description,
      json.tags,
      questions,
      Pointer.fromJson(json.saveLocation),
      json.minLength,
      json.maxLength,
      json.poundToEnd,
      json.canUserInterrupt,
      json.allowAsterisks,
      json.alternateResourceId,
      json.alternateIntent,
      json.excludeUniversalModel,
    );
  }
}
