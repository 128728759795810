import React, { useEffect, useRef } from 'react';
import { PointerRenderer } from './PointerRenderer';
import { PointerEditor } from './PointerEditor';
import { Pointer, PointerType } from "internal/models";

interface PointerTableCellRendererProps {
  pointer: Pointer;
  allowedTypes: PointerType[];
  onChange: (pointer: Pointer) => void;
}
const PointerTableCellRenderer: React.FC<PointerTableCellRendererProps> = ({ pointer, allowedTypes, onChange }) => {
  const [inEditMode, setInEditMode] = React.useState<boolean>(false);
  const ref = useRef(null);
  const onClick = () => {
    if (!inEditMode) setInEditMode(true);
  };
  useEffect(() => {
    if (!ref.current) return;
    const cb = (evt: { target: any }) => {
      // @ts-ignore
      if (ref.current.contains(evt.target)) return;
      setInEditMode(false);
    };
    document.addEventListener(`click`, cb);
    return () => {
      document.removeEventListener(`click`, cb);
    };
  }, [ref]);
  useEffect(() => {
    setInEditMode(false);
  }, [pointer]);
  return (
    <div ref={ref} onClick={onClick} className={'pointer-ag-table-cell-renderer'}>
      <PointerRenderer pointer={pointer} />
      {inEditMode && <PointerEditor pointer={pointer} allowedTypes={allowedTypes} onChange={onChange}/>}
    </div>
  );
};
export default PointerTableCellRenderer;
