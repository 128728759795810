import { BargeInPolicy } from "./bargeIn";
import { ConditionalPolicy } from "./conditional/ConditionalPolicy";
import { UnknownPolicy } from "./Policy";
import { PolicyType } from "./PolicyType";
import { RetryPolicy } from "./retry/RetryPolicy";
import { TimeoutPolicy } from "./timeout/TimeoutPolicy";
import { ValidatePolicy } from "./validate/ValidatePolicy";
import { DTMFOnlyPolicy } from "./DTMFOnly/DTMFOnlyPolicy";
import { ExpirationPolicy } from "./expiration/ExpirationPolicy";
import { InputFormatterPolicy } from "./inputFormatter/InputFormatterPolicy";


export function PolicyBuilder(json: any) {
    
    switch (json.type) {
        case PolicyType.Conditional: 
            return ConditionalPolicy.fromJson(json);
        case PolicyType.BargeIn: 
            return BargeInPolicy.fromJson(json);
        case PolicyType.Retry:
            return RetryPolicy.fromJson(json);
        case PolicyType.Timeout:
            return TimeoutPolicy.fromJson(json);
        case PolicyType.Expiration:
            return ExpirationPolicy.fromJson(json);
        case PolicyType.Validate:
            return ValidatePolicy.fromJson(json);
        case PolicyType.DTMFOnly:
            return DTMFOnlyPolicy.fromJson(json);
        case PolicyType.InputFormatter:
            return InputFormatterPolicy.fromJson(json);
    }
    return new UnknownPolicy(json.id, json.ownerId, json.type);
}