import { Button } from '@smartaction/visuals';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import { VisualCategory } from '@smartaction/styles/VisualCategory';

export type ActionRendererProps = {
  id: string;
  label: string | React.ReactElement;
  type: VisualCategory;
  action: (data?: any) => void;
};

export type ActionsRendererProps = ICellRendererParams & {
  actions: ActionRendererProps[];
};

export const ActionsRenderer: React.FC<ActionsRendererProps> = ({ data, actions }) => {
  return (
    <div role="group" aria-label="Actions button group">
      {actions?.map((action, i) => (
        <Button  key={action.id} type={action.type} className="btn-sm me-4" action={() => action.action(data)}>
        {action.label}
      </Button>
      ))}
    </div>
  );
};
