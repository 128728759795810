import { Position } from '@smartaction/visuals/Types';
import { DataResponse, Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { IBlockClient } from './IBlockClient';
import { Container, Pointer } from 'internal/models';

export class BlockClient implements IBlockClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private getUrl(snapshotId: string, moduleId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/modules/${moduleId}/blocks`;
  }

  async createAsync(
    snapshotId: string,
    moduleId: string,
    name: string,
    position: Position,
  ): Promise<DataResponse<string>> {
    const request = {
      name: name,
      position: position,
    };

    const response = await this.methods.post(this.getUrl(snapshotId, moduleId), request);

    return await this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Created} Block`, response);
  }

  async updateNextIdAsync(
    snapshotId: string,
    moduleId: string,
    blockId: string,
    nextId: string,
  ): Promise<ServerResponse> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${blockId}/next`, nextId);

    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} NextId for Block`, response);
  }

  async updateMetadataAsync(
    snapshotId: string,
    moduleId: string,
    blockId: string,
    name: string,
    description: string,
  ): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${blockId}/metadata`, {
      name: name,
      description: description,
    });

    await this.methods.handleErrors(`${ValidateResponseActionEnum.Updated} Block Metadata`, response);
  }

  async updateListToIterate(
    snapshotId: string,
    moduleId: string,
    blockId: string,
    pointer: Pointer,
  ): Promise<ServerResponse> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${blockId}/list-to-iterate`, pointer);

    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Block List To Iterate`,
      response,
    );
  }

  async updatePositionAsync(snapshotId: string, moduleId: string, blockId: string, position: Position): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${blockId}/position`, {
      x: position.x,
      y: position.y,
    });

    await this.methods.handleErrors('Updating Block Position', response);
  }

  async deleteAsync(snapshotId: string, moduleId: string, blockId: string): Promise<ServerResponse> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.delete(`${url}/${blockId}`);

    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Block`, response);
  }

  async createStep(
    snapshotId: string,
    moduleId: string,
    blockId: string,
    type: string,
    name: string,
    index?: number,
  ): Promise<DataResponse<string>> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${blockId}/steps`;

    if (index !== undefined) {
      url += `?index=${index}`;
    }

    const request = {
      type: type,
      name: name,
    };

    const response = await this.methods.post(url, request);

    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Step In Block`,
      response,
    );
  }

  async reorderSteps(snapshotId: string, moduleId: string, blockId: string, stepIds: string[]): Promise<void> {
    const url = `${this.getUrl(snapshotId, moduleId)}/${blockId}/steps`;
    const response = await this.methods.patch(url, stepIds);
    await this.methods.handleErrors(`${ValidateResponseActionEnum.Reordered} Steps in Block`, response);
  }

  async addExistingStep(
    snapshotId: string,
    moduleId: string,
    blockId: string,
    stepId: string,
    container: Container,
    index?: number,
  ): Promise<void> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${blockId}/steps/${stepId}`;
    url += `?index=${index}`;

    const response = await this.methods.put(url, container);
    // throws an exception if this fails.
    await this.methods.handleErrors(`${ValidateResponseActionEnum.Moved} Step to Block`, response);
  }
}
