// @flow
import * as React from 'react';
import { useFilter } from 'contexts';
import { useDebouncer } from 'ui/hooks';
import { useEffect, useState } from 'react';
import { Icon, IconType } from '@smartaction/styles';
import { ValidationEntitiesTypeEnum } from 'internal/models';
import { DefaultPublisher } from '@smartaction/common';
import { UpdateNodeQuery } from './module/UpdateNodeQuery';

export const FilterControl = () => {
  const { filter, updateFilter } = useFilter();
  const [filterText, setFilterText] = useState<string>(filter);
  const filterDebouncer = useDebouncer(1000);
  const onFilterChange = (val: string) => {
    setFilterText(val);
    return filterDebouncer.start(() => updateFilter(val));
  };
  const onFilterClear = () => {
    onFilterChange('');
  };

  useEffect(() => {
    const validationErrors = DefaultPublisher.subscribe('NavigateToEntityEvent', (evt) => {
      if (evt.entities) {
        const getValidationEntityId = (entityId: ValidationEntitiesTypeEnum) =>
          evt.entities.filter((item: { type: ValidationEntitiesTypeEnum }) => item.type === entityId)[0].id;

        onFilterChange(getValidationEntityId(ValidationEntitiesTypeEnum.Step));
      }
    });
    return () => {
      DefaultPublisher.unsubscribe('NavigateToEntityEvent', validationErrors);
    };
  }, []);

  return (
    <div className="input-group position-relative filter-control">
      <input
        id={'filter-input'}
        className="form-control pe-3 filter-control__input"
        type="text"
        placeholder={'Search...'}
        value={filterText}
        onChange={(ev) => onFilterChange(ev.target.value)}
      ></input>
      {filter.length > 0 && (
        <span className="input-group-append">
          <button
            onClick={onFilterClear}
            className="btn btn-outline-secondary bg-white filter-control__clear-btn"
            type="button"
          >
            <Icon type={IconType.Close} />
          </button>
        </span>
      )}
    </div>
  );
};
