import { Position } from '@smartaction/visuals/Types';
import { EmptyId, EmptyPointer, Pointer } from 'internal/models';
import { ModuleChildTypes } from '.';
import { Step, StepBuilder } from '../steps';
import { Component } from './ModuleItem';

export class Block extends Component<Block> {
  readonly type: ModuleChildTypes = ModuleChildTypes.Block;
  steps: Array<Step>;
  nextId: string;
  listToIterate: Pointer;

  constructor(
    id: string | undefined,
    name: string,
    description: string,
    position: Position,
    steps: Array<Step>,
    nextId: string,
    listToIterate: Pointer,
  ) {
    super(id, name, description, position);
    this.steps = steps;
    this.nextId = nextId;
    this.listToIterate = listToIterate;
  }

  isDirty(original: Block): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: Block): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): Block {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any) {
    const steps = json.steps && Array.isArray(json.steps) ? json.steps.map((s: any) => StepBuilder(s)) : [];
    return new Block(
      json.id,
      json.name,
      json.description,
      { x: json.position.x, y: json.position.y },
      steps,
      json.nextId ? json.nextId : EmptyId,
      json.listToIterate ? json.listToIterate : new EmptyPointer(),
    );
  }
}
