import { ContextPointer, EmptyId, EmptyPointer, Pointer, PointerControlFilters } from 'internal/models';
import React from 'react';
import { PointerEditorProps } from './PointerEditorProps';
import { ContextSelect } from '.';

export type ContextPointerEditorProps<T extends Pointer> = PointerEditorProps<T> & {
  pointerFilters?: PointerControlFilters[];
  isDisabled: boolean;
};

export const ContextPointerEditor: React.FC<ContextPointerEditorProps<ContextPointer>> = 
  ({ pointer, update, pointerFilters, isDisabled }) => {
    const save = (id?: string, propertyId?: string) => {
      update(id ? new ContextPointer(id, propertyId ?? EmptyId) : new EmptyPointer());
    };
    
    return (
      <ContextSelect
        isDisabled={isDisabled}
        filters={pointerFilters}
        selectedContextId={pointer?.contextItemId}
        updateSelectedContext={save}
        selectedPropertyId={pointer?.propertyId}
      />
    );
};
