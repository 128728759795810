import { CurrentDateTimePointer, EmptyPointer } from 'internal/models';
import React, { useState, useEffect } from 'react';
import { PointerEditorProps } from './PointerEditorProps';
import Select, { SingleValue } from 'react-select';

type DateTimeOption = {
  label: string;
  value: string;
};

export enum DateTimePointerLabelEnum {
  Day = 'Day',
  Date = 'Date',
  Time = 'Time',
  DayDate = 'DayDate',
  DayTime = 'DayTime',
  DateTime = 'DateTime',
  DayDateTime = 'FullDateTime',
}

const currentDate = new Date();
const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
const currentTime = currentDate.toLocaleTimeString('en-US');

export const DateTimePointerOptions = [
  { value: currentDay, label: DateTimePointerLabelEnum.Day, id: DateTimePointerLabelEnum.Day },
  {
    value: currentDate.toLocaleDateString('en-US'),
    label: DateTimePointerLabelEnum.Date,
  },
  {
    value: currentTime,
    label: DateTimePointerLabelEnum.Time,
  },
  {
    value: `${currentDay} ${currentDate.toLocaleDateString('en-US')}`,
    label: DateTimePointerLabelEnum.DayDate,
  },
  {
    value: `${currentDay} ${currentTime}`,
    label: DateTimePointerLabelEnum.DayTime,
  },
  {
    value: currentDate.toLocaleString('en-US'),
    label: DateTimePointerLabelEnum.DateTime,
  },
  {
    value: currentDate.toLocaleString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    }),
    label: DateTimePointerLabelEnum.DayDateTime,
  },
];

const EmptyValue = { value: '', label: '-- Select --' };

export const DateTimePointerEditor: React.FC<PointerEditorProps<CurrentDateTimePointer>> = ({ pointer, update, isDisabled }) => {
  const [selected, setSelected] = useState(EmptyValue);
  const [selectOptions, setSelectOptions] = useState([EmptyValue]);

  useEffect(() => {
    setSelectOptions(DateTimePointerOptions);

    if (pointer?.currentDateTimeCheck) {
      const selectedOption = DateTimePointerOptions.find((item) => item.label === pointer.currentDateTimeCheck);
      setSelected({ value: selectedOption!.value, label: selectedOption!.label });
    }
  }, []);

  const save = (opt: SingleValue<DateTimeOption>) => {
    if (!opt || !opt.label) {
      setSelected(EmptyValue);
      update(new EmptyPointer());
    } else {
      setSelected(opt);
      update(new CurrentDateTimePointer(opt.label));
    }
  };

  return (
    <Select<DateTimeOption>
      isDisabled={isDisabled}
      options={selectOptions}
      value={selected}
      menuPlacement={'top'}
      onChange={save}
      className="date-time-options-select"
    />
  );
};
