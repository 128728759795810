import { Color, VisualCategory } from '@smartaction/styles';
import { ICellRendererParams } from 'ag-grid-community';
import { useConfigs, useContextItems, useLocationDefinitions } from 'contexts';
import {
  ConfigPointer,
  ContextPointer,
  DirectAssignmentListPointer,
  DirectAssignmentPointer,
  LocationPointer,
  Pointer,
  PointerType,
} from 'internal/models';
import React from 'react';

type PointerRendererProps<T extends Pointer> = {
  pointer: T;
  params: ICellRendererParams;
};

export const PointerRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { value } = params;
  const pointer = value as Pointer;
  if (!pointer) {
    return <React.Fragment />;
  }
  switch (pointer.type) {
    case PointerType.Config:
      return <ConfigPointerRenderer params={params} pointer={pointer as ConfigPointer} />;
    case PointerType.Context:
      return <ContextPointerRenderer params={params} pointer={pointer as ContextPointer} />;
    case PointerType.DirectAssignment:
      return <DirectAssignmentPointerRenderer params={params} pointer={pointer as DirectAssignmentPointer} />;
    case PointerType.DirectAssignmentList:
      return <DirectAssignmentListPointerRenderer params={params} pointer={pointer as DirectAssignmentListPointer} />;
    case PointerType.Location:
      return <LocationPointerRenderer params={params} pointer={pointer as LocationPointer} />;
    case PointerType.Empty:
      return (
        <label className={Color(VisualCategory.Dark)}>
          <i>None selected</i>
        </label>
      );
  }
  return <React.Fragment />;
};

export const NotApplicablePointerRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  return <span className="badge bg-dark">N/A</span>;
};

const ConfigPointerRenderer: React.FC<PointerRendererProps<ConfigPointer>> = ({ pointer, params }) => {
  const configs = useConfigs();
  const config = configs.all.get(pointer.configId);
  if (!config) {
    return <React.Fragment>Cannot determine pointer type</React.Fragment>;
  }

  return (
    <React.Fragment>
      {config.name} <span className="badge bg-primary">Config</span>
    </React.Fragment>
  );
};

const ContextPointerRenderer: React.FC<PointerRendererProps<ContextPointer>> = ({ pointer, params }) => {
  const contextItems = useContextItems();
  const item = contextItems.map.get(pointer.contextItemId);
  const name = item ? item.name : <i>Undetermined</i>;
  return (
    <React.Fragment>
      {name} <span className="badge bg-success">Context</span>
    </React.Fragment>
  );
};

const LocationPointerRenderer: React.FC<PointerRendererProps<LocationPointer>> = ({ pointer }) => {
  const { locationDefinitions, commonLocationFieldIds } = useLocationDefinitions();
  const name =
    locationDefinitions[0]?.customFields.find((fieldId) => fieldId.id === pointer.fieldId)?.name ||
    Object.entries(commonLocationFieldIds).find(([key, val]) => val === pointer.fieldId)?.[0];
  if (!name) {
    return <React.Fragment>Cannot determine location pointer type</React.Fragment>;
  }

  return (
    <React.Fragment>
      {name} <span className="badge bg-primary">Location</span>
    </React.Fragment>
  );
};

const DirectAssignmentPointerRenderer: React.FC<PointerRendererProps<DirectAssignmentPointer>> = ({
  pointer,
  params,
}) => {
  return (
    <React.Fragment>
      {pointer.value} <span className="badge bg-dark">Hardcoded</span>
    </React.Fragment>
  );
};

const DirectAssignmentListPointerRenderer: React.FC<PointerRendererProps<DirectAssignmentListPointer>> = ({
  pointer,
  params,
}) => {
  const content = pointer.values ? (
    <span>
      # of values: {pointer.values.length} <span className="badge bg-dark">Hardcoded</span>
    </span>
  ) : (
    <i>No value</i>
  );
  return <React.Fragment>{content}</React.Fragment>;
};
