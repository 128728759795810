import { ICellEditorParams } from 'ag-grid-community';
import { ConfigValue, ValueType } from 'internal/models';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';


export const BooleanCellEditor = forwardRef((props: ICellEditorParams, ref: React.ForwardedRef<unknown>) => {
    const configValue = (props.value as ConfigValue);
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState(configValue.type === ValueType.Boolean ? (configValue.value as boolean) : false);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [inputRef]);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return new ConfigValue(ValueType.Boolean, value);
            },
            isCancelAfterEnd: () => {
                return value === configValue.value;
            }
        };
    });

    return <input className="form-check-input form-control" type="checkbox" id="checkboxNoLabel" checked={value} onChange={(evt) => setValue(evt.target.checked)} aria-label="..." />;
    
});