import { EmptyPointer, Pointer } from '../../shared';
import { Step } from '../Step';
import { StepType } from '../Type';

export class SetValues extends Step {
  saveLocation: Pointer;
  assignments: SetValueAssignment[];

  constructor(
    id: string | undefined,
    name: string,
    description: string,
    tags: string[],
    saveLocation: Pointer,
    assignments: SetValueAssignment[],
  ) {
    super(id, name, description, StepType.SetValues, tags);
    this.saveLocation = saveLocation;
    this.assignments = assignments;
  }

  isDirty(original: Step): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: Step): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): Step {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any): SetValues {
    const assignments =
      json.assignments && Array.isArray(json.assignments)
        ? json.assignments.map((p: any) => SetValueAssignment.fromJson(p))
        : [];

    return new SetValues(
      json.id,
      json.name,
      json.description,
      json.tags,
      Pointer.fromJson(json.saveLocation || new EmptyPointer()),
      assignments,
    );
  }
}

export class SetValueAssignment {
  /** The source of data we're going to assign to Target */
  source: Pointer;
  /** Where we're going to put the data from Source */
  target: Pointer;

  constructor(source: Pointer, target: Pointer) {
    this.source = source;
    this.target = target;
  }

  static fromJson(json: any) {
    return new SetValueAssignment(Pointer.fromJson(json.source), Pointer.fromJson(json.target));
  }
}
