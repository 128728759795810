import { BaseModel } from "@smartaction/common";

export enum UnpublishedState {
    Draft = "Draft",
    Testing = "Testing"
}

export enum BotVersion {
    v1 = "v1"
}

export type SnapshotType = "draft" | "published";

export class Snapshot extends BaseModel<Snapshot> {
    version: BotVersion;
    tickets: Array<string>;
    changes: string;

    constructor(id: string, version: BotVersion, tickets: Array<string>, changes: string) {
        super("", id);
        this.version = version;
        this.tickets = tickets;
        this.changes = changes;
    }

    isDirty(original: Snapshot): boolean {
        throw new Error("Method not implemented.");
    }
    getDirtyFields(original: Snapshot): string[] {
        throw new Error("Method not implemented.");
    }
    clone(): Snapshot {
        throw new Error("Method not implemented.");
    }
}

export class UnpublishedSnapshot extends Snapshot {
    state: UnpublishedState;
    approvedBy: string;
    constructor(state: UnpublishedState, id: string, version: BotVersion, tickets: Array<string>, changes: string, approvedBy: string) {
        super(id, version, tickets, changes);
        this.state = state;
        this.approvedBy = approvedBy;
    }

    static fromJson(json: any) {
        if (!json) {
            return undefined;
        }

        return new UnpublishedSnapshot(json.state, json.id, json.versio, json.tickets, json.changes, json.approvedBy);
    }
}

export class PublishedSnapshot extends Snapshot {
    isDisabled: boolean;
    publishedDate: Date;
    constructor(isDisabled: boolean, publishedDate: Date, id: string, version: BotVersion, tickets: Array<string>, changes: string) {
        super(id, version, tickets, changes);
        this.isDisabled = isDisabled;
        this.publishedDate = publishedDate;
    }

    static fromJson(json: any) {
        if (!json) {
            return undefined;
        }

        return new PublishedSnapshot(json.isDisabled, json.publishedDate, json.id, json.version, json.tickets, json.changes);
    }
}