import { Position } from '@smartaction/visuals/Types';
import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Decision, DecisionBranch, EmptyId } from 'internal/models';
import { IDecisionClient } from './IDecisionClient';
import { Condition } from 'internal/models';

export class DecisionClient implements IDecisionClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private getUrl(snapshotId: string, moduleId: string) {
    return `${this.serviceUrl}/tenant/${this.methods
      .tenantId!}/snapshot/${snapshotId}/v1/modules/${moduleId}/decisions`;
  }

  async createAsync(
    snapshotId: string,
    moduleId: string,
    name: string,
    position: Position,
  ): Promise<Decision | undefined> {
    const response = await this.methods.post(this.getUrl(snapshotId, moduleId), {
      name: name,
      position: position,
    });

    const success = await this.methods.handleErrors(`${ValidateResponseActionEnum.Created} Decision`, response);

    if (success) {
      return new Decision(await this.methods.getId(response), name, '', position, []);
    }

    return undefined;
  }

  async updateMetadataAsync(
    snapshotId: string,
    moduleId: string,
    decisionId: string,
    name: string,
    description: string,
  ): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${decisionId}/metadata`, {
      name: name,
      description: description,
    });

    await this.methods.handleErrors(`${ValidateResponseActionEnum.Updated} Decision metadata`, response);
  }

  async updatePositionAsync(
    snapshotId: string,
    moduleId: string,
    decisionId: string,
    position: Position,
  ): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${decisionId}/position`, position);

    await this.methods.handleErrors(`${ValidateResponseActionEnum.Updated} Decision position`, response);
  }

  async createBranchAsync(
    snapshotId: string,
    moduleId: string,
    decisionId: string,
    name: string,
    position: Position,
  ): Promise<DecisionBranch | undefined> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.post(`${url}/${decisionId}/branches`, {
      name: name,
      position: position,
    });

    const success = await this.methods.handleErrors(`${ValidateResponseActionEnum.Created} Branch`, response);

    if (success) {
      return new DecisionBranch(await this.methods.getId(response), name, position, [], EmptyId);
    }

    return undefined;
  }

  async createConditionAsync(
    snapshotId: string,
    moduleId: string,
    decisionId: string,
    branchId: string,
    condition: Condition,
  ): Promise<ServerResponse> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.post(`${url}/${decisionId}/branches/${branchId}/conditions`, condition);

    return this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Created} Branch condition`, response);
  }

  async updateBranchNameAsync(
    snapshotId: string,
    moduleId: string,
    decisionId: string,
    branchId: string,
    name: string,
  ): Promise<ServerResponse> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${decisionId}/branches/${branchId}/name`, name);

    return this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Branch name`, response);
  }

  async updateBranchPositionAsync(
    snapshotId: string,
    moduleId: string,
    decisionId: string,
    branchId: string,
    position: Position,
  ): Promise<ServerResponse> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${decisionId}/branches/${branchId}/position`, position);

    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Branch position`, response);
  }

  async updateBranchNextIdAsync(
    snapshotId: string,
    moduleId: string,
    decisionId: string,
    branchId: string,
    nextId: string,
  ): Promise<ServerResponse> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${decisionId}/branches/${branchId}/next`, nextId);

    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Branch nextId`, response);
  }

  async updateConditionAsync(
    snapshotId: string,
    moduleId: string,
    decisionId: string,
    branchId: string,
    conditionId: string,
    condition: Condition,
  ): Promise<ServerResponse> {
    const url = this.getUrl(snapshotId, moduleId);
    // This extracts id from the condition, which we don't want to pass along
    const { id, ...request } = condition;
    const response = await this.methods.put(
      `${url}/${decisionId}/branches/${branchId}/conditions/${conditionId}`,
      request,
    );

    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Branch condition`, response);
  }

  async deleteConditionAsync(
    snapshotId: string,
    moduleId: string,
    decisionId: string,
    branchId: string,
    conditionId: string,
  ): Promise<ServerResponse> {
    const url = this.getUrl(snapshotId, moduleId);

    const response = await this.methods.delete(`${url}/${decisionId}/branches/${branchId}/conditions/${conditionId}`);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Deleted} Condition from Branch`,
      response,
    );
  }

  async deleteBranchAsync(snapshotId: string, moduleId: string, decisionId: string, branchId: string): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.delete(`${url}/${decisionId}/branches/${branchId}`);

    await this.methods.handleErrors(`${ValidateResponseActionEnum.Deleted} Branch`, response);
  }

  async deleteDecisionAsync(snapshotId: string, moduleId: string, decisionId: string): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.delete(`${url}/${decisionId}`);

    await this.methods.handleErrors(`${ValidateResponseActionEnum.Deleted} Decision`, response);
  }
}
