import { Color, Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, Field, useId, useSidePanel, useComplexState, usePortalModal, ModalContainerIds, PortalConfirm, usePortalContextMenu } from '@smartaction/visuals';
import { Decision, DecisionBranch } from 'internal/models';
import React, { useState, useEffect, useRef } from 'react';
import { Handle, NodeProps, Position as FlowPosition } from 'reactflow';
import { Diamond } from './shapes/Diamond';
import { ValidateDecision } from 'internal/validators/flow/DecisionValidator';
import { ValidationErrors } from '@smartaction/common';
import { useBots, useFlow } from 'contexts';
import { useClient, useSnapshot, useSelectedNodeIds } from 'contexts';

export type DecisionData = {
  moduleId: string;
  decision: Decision;
};

export function GetHandleForBranch(decision: Decision, branch: DecisionBranch) {
  if (decision.position.x - 30 > branch.position.x) {
    return FlowPosition.Left;
  }

  if (decision.position.x + 140 < branch.position.x) {
    return FlowPosition.Right;
  }

  return FlowPosition.Bottom;
}

export const DecisionNode: React.FC<NodeProps<DecisionData>> = ({ data, isConnectable }) => {
  const decision = data.decision;
  const timer = useRef<NodeJS.Timeout>();
  const sidePanel = useSidePanel();
  const { updateFlow } = useFlow();
  const { isReadOnlyBot} = useBots();
  const client = useClient('flow');
  const ref = useRef<HTMLDivElement>(null);
  const snapshot = useSnapshot();
  const confirm = usePortalModal(ModalContainerIds.Confirm);
  const { selectedNodeIds, setSelectedNodeIds } = useSelectedNodeIds();

  useEffect(() => {
    sidePanel.close();
  }, [isReadOnlyBot]);

  const [isContextMenuClicked, setIsContextMenuClicked] = useState(false);

  const clickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.type === 'contextmenu') {
      setIsContextMenuClicked(true);
      return;
    }

    setIsContextMenuClicked(false);
    if (event.detail === 1) {
      if (event.shiftKey) {
        if (selectedNodeIds.includes(decision.id)) {
          setSelectedNodeIds(selectedNodeIds.filter(id => id !== decision.id));
        }
        else {
          setSelectedNodeIds([...selectedNodeIds, decision.id]);
        }
      } else {
        setSelectedNodeIds([decision.id]);
      }
    }
    else {
      timer.current = setTimeout(() => {
        sidePanel.setContents('Edit Decision', <DecisionSidePanel decision={decision} moduleId={data.moduleId} />);
        sidePanel.open();
      }, 200);
    }
  };

  const contextMenu = usePortalContextMenu(
    ref,
    [
      {
        label: (
          <span className={Color(VisualCategory.Danger)}>
            <Icon type={IconType.Delete} /> Delete Decision
          </span>
        ),
        action: () => {
          confirm.openModal(
            //TODO use Resource.isInUse to check if the resource is used before prompting delete (not wired up currently)
            <PortalConfirm
            header="Delete Node- Decision"
              content={
                <React.Fragment>
                  <div>Are you sure you want to delete this decision?</div>
                </React.Fragment>
              }
              confirmButton={{
                label: (
                  <React.Fragment>
                    <Icon type={IconType.Delete} /> Delete
                  </React.Fragment>
                ),
                type: VisualCategory.Danger,
                clicked: async () => {
                  client.modules.decisions.deleteDecisionAsync(snapshot.snapshot.id, data.moduleId, decision.id).then(() => {
                    updateFlow((x) => {
                      let module = x.modules.find((y) => y.id === data.moduleId);

                      let copyDecisions = [...module?.decisions!];
                      let filteredDecisions = copyDecisions.filter((x) => x.id !== decision.id);
                      module!.decisions = filteredDecisions;
                    });
                  });
                },
              }}
              cancelButton={{ label: 'Cancel', type: VisualCategory.Light, clicked: () => {} }}
            />,
          );
        },
        isDisabled: false,
      },
    ]
  );

  const isSelected = selectedNodeIds.includes(decision.id);

  return (
    <React.Fragment>
      <Handle type="target" position={FlowPosition.Top} style={{ background: '#555' }} isConnectable={isConnectable} />
      <div ref={ref} className="decisionNode node" onClick={clickHandler} onContextMenu={clickHandler}>
        <Diamond text={decision ? decision.name : ''} size={1} isSelected={isSelected} />
        {isContextMenuClicked && contextMenu}
      </div>
      <Handle
        type="source"
        position={FlowPosition.Left}
        id={FlowPosition.Left}
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={FlowPosition.Right}
        id={FlowPosition.Right}
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={FlowPosition.Bottom}
        id={FlowPosition.Bottom}
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      {confirm.modal}
    </React.Fragment>
  );
};

type DecisionSidePanelProps = {
  moduleId: string;
  decision: Decision;
};

export const DecisionSidePanel: React.FC<DecisionSidePanelProps> = ({ moduleId, decision }) => {
  const sidePanel = useSidePanel();
  const [name, setName] = useState(decision.name);
  const snapshot = useSnapshot();
  const { updateFlow } = useFlow();
  const client = useClient('flow');
  const [description, setDescription] = useState(decision.description);
  const nameId = useId('decisionName');
  const descriptionId = useId('decisionDescription');
  const [validationErrors, updateValidationErrors] = useComplexState(new ValidationErrors(['name']));
  const [isSaving, setIsSaving] = useState(false);
  const { isReadOnlyBot} = useBots();

  useEffect(() => {
    const currentState = new Decision('', name, description, { x: 0, y: 0 }, []);
    // Call new local validator here
    updateValidationErrors((ve) => ValidateDecision(currentState, ve));
  }, [name, description]);

  const save = () => {
    setIsSaving(true);
    client.modules.decisions
      .updateMetadataAsync(snapshot.snapshot.id, moduleId, decision.id, name, description)
      .then(() => {
        updateFlow(() => {
          decision.name = name;
          decision.description = description;
        });
        sidePanel.clearContents();
        sidePanel.close();
        setIsSaving(false);
      });
  };

  const cancel = () => {
    sidePanel.clearContents();
    sidePanel.close();
  };
  return (
    <React.Fragment>
      <Field inputId={nameId} label="Name" name="name" groupClass="col" validationErrors={validationErrors}>
        <input
          id={nameId}
          type="text"
          className="form-control"
          value={name}
          disabled={isReadOnlyBot}
          onChange={(e) => setName(e.currentTarget.value)}
        />
      </Field>
      <Field
        inputId={descriptionId}
        label="Description"
        name="description"
        groupClass="col"
        validationErrors={validationErrors}
      >
        <input
          id={descriptionId}
          type="text"
          disabled={isReadOnlyBot}
          className="form-control"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
        />
      </Field>
      <Button
        type={VisualCategory.Primary}
        action={save}
        isDisabled={isSaving || validationErrors.hasAnyErrors || isReadOnlyBot}
      >
        <Icon type={IconType.Save} />
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
      <Button type={VisualCategory.Secondary} action={cancel}>
        Cancel
      </Button>
    </React.Fragment>
  );
};
