import React from 'react';
import { PortalModal } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import {
  BargeInPolicy,
  ConditionalPolicy,
  Policy,
  PolicyType,
  RetryPolicy,
  TimeoutPolicy,
  ValidatePolicy,
  ExpirationPolicy,
  InputFormatterPolicy
} from 'internal/models';
import { useBots, useClient, usePolicies, useSnapshot } from 'contexts';
import { BargeInPolicyView } from './typeContent/bargeIns/BargeInPolicy';
import { ConditionalPolicyView } from './typeContent/ConditionalPolicy';
import { RetryPolicyView } from './typeContent/RetryPolicy';
import { POLICY_ICON_STATUS_COLOR } from 'ui/constants';
import { TimeoutPolicyView } from './typeContent/TimeoutPolicy';
import { ValidatePolicyView } from './typeContent/ValidatePolicy';
import { ExpirationPolicyView } from './typeContent/ExpirationPolicy';
import { InputFormatterPolicyView } from './typeContent/InputFormatterPolicy';
import { Searchable } from '../../../views/bots/design/flow/Searchable';

type PoliciesModalProps = {
  allowedTypes: PolicyType[];
  ownerId: string;
  openPolicyModal: (modal: React.ReactElement) => void;
  createPolicy: (type: PolicyType) => Promise<Policy>;
};

type PolicyDisplay = {
  icon: IconType;
  label: string;
  modalLabel: string;
};

const iconAndLabelMap = new Map<PolicyType, PolicyDisplay>();
iconAndLabelMap.set(PolicyType.BargeIn, {
  icon: IconType.DoorClosed,
  label: 'Barge-in',
  modalLabel: 'Edit Barge-In Policy',
});
iconAndLabelMap.set(PolicyType.Retry, { icon: IconType.ArrowsRotate, label: 'Retry', modalLabel: 'Edit Retry Policy' });
iconAndLabelMap.set(PolicyType.Timeout, { icon: IconType.Clock, label: 'Timeout', modalLabel: 'Edit Timeout Policy' });
iconAndLabelMap.set(
  PolicyType.Expiration, { 
    icon: IconType.Clock, 
    label: 'Expiration', 
    modalLabel: 'Edit Expiration Policy' 
});
iconAndLabelMap.set(PolicyType.Conditional, {
  icon: IconType.CodeBranch,
  label: 'Conditional',
  modalLabel: 'Edit Conditional Policy',
});
iconAndLabelMap.set(PolicyType.Validate, {
  icon: IconType.CheckDouble,
  label: 'Validate',
  modalLabel: 'Edit Validate Policy',
});
iconAndLabelMap.set(PolicyType.DTMFOnly, {
  icon: IconType.Hash,
  label: 'DTMFOnly',
  modalLabel: 'Edit DTMFOnly Policy',
});

iconAndLabelMap.set(PolicyType.InputFormatter, {
  icon: IconType.Edit,
  label: 'Formatter',
  modalLabel: 'Edit Input Formatter',
});


export const PoliciesModal: React.FC<PoliciesModalProps> = ({
  ownerId,
  allowedTypes,
  createPolicy,
  openPolicyModal,
}) => {
  const { isReadOnlyBot } = useBots();
  const maybeCreateButton = (policyType: PolicyType) => {
    return allowedTypes.includes(policyType) ? (
      <PolicyButton
        disabled={isReadOnlyBot}
        policyType={policyType}
        ownerId={ownerId}
        create={createPolicy}
        openPolicyModal={openPolicyModal}
      />
    ) : (
      <React.Fragment />
    );
  };

  const header = {
    type: VisualCategory.Primary,
    content: (
      <>
        <Icon type={IconType.BookOpen} /> Policies
      </>
    ),
  };

  const content = (
    <>
      {maybeCreateButton(PolicyType.BargeIn)}
      {maybeCreateButton(PolicyType.Retry)}
      {maybeCreateButton(PolicyType.Conditional)}
      {maybeCreateButton(PolicyType.InputFormatter)}
      {maybeCreateButton(PolicyType.Validate)}
      {maybeCreateButton(PolicyType.Timeout)}
      {maybeCreateButton(PolicyType.Expiration)}
      {maybeCreateButton(PolicyType.DTMFOnly)}
    </>
  );

  return <PortalModal header={header} content={content} buttons={<></>} />;
};

type PolicyButtonProps = {
  policyType: PolicyType;
  ownerId: string;
  openPolicyModal: (policyModal: React.ReactElement) => void;
  create: (policyType: PolicyType) => Promise<Policy>;
  disabled?: boolean;
};

const PolicyButton: React.FC<PolicyButtonProps> = ({ policyType, ownerId, openPolicyModal, create, disabled }) => {
  const policyCtx = usePolicies();
  const { snapshot } = useSnapshot();
  const client = useClient('flow').policies;
  const policy = policyCtx.getPoliciesByOwnerId(ownerId).find((p) => p.type === policyType);
  const { icon, label, modalLabel } = iconAndLabelMap.get(policyType)!;

  const click = async () => {
    let currentOrNewPolicy: Policy;
    if (!policy) {
      currentOrNewPolicy = await createPolicy(policyType, create);
      policyCtx.updatePolicies((policies) => policies.push(currentOrNewPolicy));
    } else {
      currentOrNewPolicy = policy;
    }
    if (policyType === PolicyType.DTMFOnly) {
      return;
    }
    createModal(currentOrNewPolicy, !policy);
  };

  const clickDelete = async () => {
    if (policy) {
      await client.deletePolicyAsync(snapshot.id, policy.id);
      policyCtx.updatePolicies((policies) => {
        const idx = policies.findIndex((p) => p.id === policy.id);
        policies.splice(idx, 1);
      });
    }
  };

  const createModal = (policy: Policy, isNew: boolean) => {
    const header = (
      <React.Fragment>
        <Icon type={icon} /> {modalLabel}
      </React.Fragment>
    );
    let content: React.ReactElement;
    switch (policy?.type) {
      case PolicyType.BargeIn:
        content = <BargeInPolicyView disabled={disabled} policy={{ ...policy } as BargeInPolicy} />;
        break;
      case PolicyType.Conditional:
        content = <ConditionalPolicyView disabled={disabled} policy={policy as ConditionalPolicy} />;
        break;
      case PolicyType.Retry:
        content = <RetryPolicyView disabled={disabled} policy={policy as RetryPolicy} />;
        break;
      case PolicyType.Timeout:
        content = <TimeoutPolicyView disabled={disabled} policy={policy as TimeoutPolicy} isNew={isNew} />;
        break;
      case PolicyType.Expiration:
        content = <ExpirationPolicyView disabled={disabled} policy={policy as ExpirationPolicy} />;
        break;
      case PolicyType.InputFormatter:
        content = <InputFormatterPolicyView disabled={disabled} policy={policy as InputFormatterPolicy} />;
        break;
      case PolicyType.Validate:
        content = <ValidatePolicyView disabled={disabled} policy={policy as ValidatePolicy} />;
        break;
      default:
        throw new Error(`${policyType} does not have a view assigned yet!`);
    }
    const contentHolder = <div className="policy-modal-viewer">{content}</div>;
    openPolicyModal(<PortalModal className="policy-modal" header={{ content: header }} content={contentHolder} />);
  };

  const button = policy ? (
    <button className="btn" onClick={click}>
      <Icon type={IconType.Edit} color={POLICY_ICON_STATUS_COLOR.active} /> Edit
    </button>
  ) : (
    <button disabled={disabled} className="btn" onClick={click}>
      <Icon type={IconType.Add} color="black" /> Create
    </button>
  );

  const deleteButton = policy ? (
    <button disabled={disabled} className={`btn delete`} onClick={clickDelete}>
      <Icon type={IconType.Delete} /> Delete
    </button>
  ) : (
    <React.Fragment />
  );
  const classes = policy ? 'policy-button existing' : 'policy-button';

  return (
    <Searchable id={policy?.id || ''}>
      <div className={classes}>
        <div className="contents">
          <div className="icon">
            <Icon type={icon} size="lg" />
          </div>
          <div className="label">{label}</div>
          <div className="details">
            {button}
            {deleteButton}
          </div>
        </div>
      </div>
    </Searchable>
  );
};

const createPolicy = async (
  type: PolicyType,
  createMethod: (policyType: PolicyType) => Promise<Policy>,
): Promise<Policy> => {
  return await createMethod(type);
};
