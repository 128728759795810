import React from "react";
import { Output, RetryStepBehavior } from "internal/models";
import { BehaviorEditorProps } from "./Type";
import { OutputsView } from "ui/components/design/OutputsView";


export const RetryStepBehaviorView: React.FC<BehaviorEditorProps<RetryStepBehavior>> = ({behavior, update}) => {
    const save = (saveList: Output[]) => {
        update(new RetryStepBehavior(saveList));
      };
    
      return (
        <React.Fragment>
          <OutputsView typeName="Message" outputs={behavior.output ?? []} update={save} />
        </React.Fragment>
      );
}