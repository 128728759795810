import { Pointer } from './Pointers';

export enum ConditionType {
  Regex = 'Regex',
  Contains = 'Contains',
  IsIn = 'IsIn',
  Compare = 'Compare',
  IsPopulated = 'IsPopulated',
  Script = 'Script',
  DateTime = 'DateTime',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
  LengthCompare = 'LengthCompare'
}

export abstract class Condition {
  id: string;
  type: ConditionType;
  /** The value to check */
  target: Pointer;

  constructor(id: string, type: ConditionType, target: Pointer) {
    this.id = id;
    this.type = type;
    this.target = target;
  }

  static fromJson(json: any) {
    switch (json.type) {
      case ConditionType.Compare:
        return CompareCondition.fromJson(json);
      case ConditionType.DateTime:
        return DateTimeCondition.fromJson(json);
      case ConditionType.Contains:
        return ContainsCondition.fromJson(json);
      case ConditionType.IsIn:
        return IsInCondition.fromJson(json);
      case ConditionType.IsPopulated:
        return IsPopulatedCondition.fromJson(json);
      case ConditionType.Regex:
        return RegexCondition.fromJson(json);
      case ConditionType.Script:
        return ScriptCondition.fromJson(json);
      case ConditionType.StartsWith:
        return StartsWithCondition.fromJson(json);
      case ConditionType.EndsWith:
        return EndsWithCondition.fromJson(json);
      case ConditionType.LengthCompare:
        return LengthCompareCondition.fromJson(json);
    }

    throw new Error("Condition received doesn't match supported types");
  }
}

export class ContainsCondition extends Condition {
  value: Pointer;
  reverse: boolean;

  constructor(id: string, target: Pointer, value: Pointer, reverse: boolean) {
    super(id, ConditionType.Contains, target);
    this.value = value;
    this.reverse = reverse;
  }

  static fromJson(json: any) {
    return new ContainsCondition(json.id, Pointer.fromJson(json.target), Pointer.fromJson(json.value), json.reverse);
  }
}

export enum CompareType {
  Equals = 'EqualTo',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  NotEqual = 'NotEqual',
}

export class CompareCondition extends Condition {
  comparison: CompareType;
  value: Pointer;

  constructor(id: string, target: Pointer, value: Pointer, comparison: CompareType) {
    super(id, ConditionType.Compare, target);
    this.value = value;
    this.comparison = comparison;
  }

  static fromJson(json: any) {
    return new CompareCondition(json.id, Pointer.fromJson(json.target), Pointer.fromJson(json.value), json.comparison);
  }
}

export class DateTimeCondition extends Condition {
  comparison: CompareType;
  value: Pointer;

  constructor(id: string, target: Pointer, value: Pointer, comparison: CompareType) {
    super(id, ConditionType.DateTime, target);
    this.value = value;
    this.comparison = comparison;
  }

  static fromJson(json: any) {
    return new DateTimeCondition(json.id, Pointer.fromJson(json.target), Pointer.fromJson(json.value), json.comparison);
  }
}

export class IsInCondition extends Condition {
  list: Pointer;
  reverse: boolean;

  constructor(id: string, target: Pointer, list: Pointer, reverse: boolean) {
    super(id, ConditionType.IsIn, target);
    this.list = list;
    this.reverse = reverse;
  }

  static fromJson(json: any) {
    return new IsInCondition(json.id, Pointer.fromJson(json.target), Pointer.fromJson(json.list), json.reverse);
  }
}

export class IsPopulatedCondition extends Condition {
  reverse: boolean;

  constructor(id: string, target: Pointer, reverse: boolean) {
    super(id, ConditionType.IsPopulated, target);
    this.reverse = reverse;
  }

  static fromJson(json: any) {
    return new IsPopulatedCondition(json.id, Pointer.fromJson(json.target), json.reverse);
  }
}

export class RegexCondition extends Condition {
  expression: string;

  constructor(id: string, target: Pointer, expression: string) {
    super(id, ConditionType.Regex, target);
    this.expression = expression;
  }

  static fromJson(json: any) {
    return new RegexCondition(json.id, Pointer.fromJson(json.target), json.expression);
  }
}

export class ScriptCondition extends Condition {
  code: string;

  constructor(id: string, target: Pointer, code: string) {
    super(id, ConditionType.Script, target);
    this.code = code;
  }

  static fromJson(json: any) {
    return new ScriptCondition(json.id, Pointer.fromJson(json.target), json.code);
  }
}

export class StartsWithCondition extends Condition {
  value: Pointer;

  constructor(id: string, target: Pointer, value: Pointer) {
    super(id, ConditionType.StartsWith, target);
    this.value = value;
  }

  static fromJson(json: any) {
    return new StartsWithCondition(json.id, Pointer.fromJson(json.target), Pointer.fromJson(json.value));
  }
}

export class EndsWithCondition extends Condition {
  value: Pointer;

  constructor(id: string, target: Pointer, value: Pointer) {
    super(id, ConditionType.EndsWith, target);
    this.value = value;
  }

  static fromJson(json: any) {
    return new EndsWithCondition(json.id, Pointer.fromJson(json.target), Pointer.fromJson(json.value));
  }
}

export class LengthCompareCondition extends Condition {
  comparison: CompareType;
  value: Pointer;

  constructor(id: string, target: Pointer, value: Pointer, comparison: CompareType) {
    super(id, ConditionType.LengthCompare, target);
    this.value = value;
    this.comparison = comparison;
  }

  static fromJson(json: any) {
    return new LengthCompareCondition(json.id, Pointer.fromJson(json.target), Pointer.fromJson(json.value), json.comparison);
  }
}
