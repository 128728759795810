import { ContextItem, ContextItemMap, WaitForAgent } from 'internal/models';
import React, { Fragment, useEffect, useState } from 'react';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { ProcessStepsColors } from '../../StepColorGroups';
import { Button, Field } from '@smartaction/visuals';
import Select from 'react-select';
import { useBots, useClient, useContextItems, useFlow, useSnapshot } from 'contexts';
import { Option } from 'ui/types';

export const WaitForAgentStyling: StepStyling = {
  typeName: 'Wait For Agent',
  icon: IconType.WaitForAgent,
  ...ProcessStepsColors,
};

export const WaitForAgentStep: React.FC<TypedStepProps<WaitForAgent>> = ({ step, manipulateStep }) => {
  const bots = useBots();
  const { snapshot } = useSnapshot();
  const { updateFlow } = useFlow();
  const getBotById = (id: string) => bots.bots?.find((bot) => bot.id === id);

  const contextClient = useClient('context');
  const waitForAgentClient = useClient('flow').steps.waitForAgent;
  const [contextItems, setContextItems] = useState<ContextItemMap[]>(step.contextItemIds);
  const contexts = useContextItems();
  const contextsOptions = contexts.contextItems
    .filter((item) => !item.isStatic)
    .map((ctx) => ({
      label: ctx.name,
      value: ctx.id,
    }));
  const [agentContexts, setAgentContexts] = useState<ContextItem[]>([]);

  useEffect(() => {
    const bot = getBotById(step.botId);
    if (step.botId && bot?.unpublished?.id) {
      getAgentContexts(bot.unpublished.id);
    }
  }, []);
  const getAgentContexts = (id: string) =>
    contextClient.getContexts(id).then((res) => {
      if (res.success && !!res.data) {
        setAgentContexts(res.data);
      }
    });

  const onAgentSelect = (val: Option) => {
    const bot = getBotById(val.value);
    if (bot && bot.unpublished) {
      getAgentContexts(bot.unpublished.id);
      waitForAgentClient.setWaitForAgentBot(snapshot.id, step.id, val.value).then(() =>
        updateFlow(() => {
          step.botId = val.value;
        }),
      );
    }
  };

  const addContextItem = () => {
    setContextItems([
      ...contextItems,
      {
        currentContextItemId: '',
        secondAgentContextItemOriginalId: '',
      },
    ]);
  };
  const deleteContextItem = (currentIndex: number) => {
    const newContextItems = contextItems.filter((x, index) => index !== currentIndex && x);
    setContextItems([...newContextItems]);
    contextItems[currentIndex].currentContextItemId &&
      waitForAgentClient
        .deleteContextItem(snapshot.id, step.id, contextItems[currentIndex].currentContextItemId)
        .then(() =>
          updateFlow(() => {
            step.contextItemIds = newContextItems;
          }),
        );
  };

  const agentOptions = bots.bots
    ? bots.bots.map((bot) => {
        return { label: bot.name, value: bot.id };
      })
    : [];

  const getContextItemName = (contexts: ContextItem[], id: string) => contexts.find((ctx) => ctx.id === id)?.name || '';

  const onSecondAgentContextOptionChange = (item: Option, index: number) => {
    const updatedCtxItems = contextItems.map((contextItem, cIndex) =>
      index === cIndex
        ? {
            ...contextItem,
            secondAgentContextItemOriginalId: item.value,
          }
        : contextItem,
    );
    setContextItems(updatedCtxItems);
    if (contextItems[index].currentContextItemId !== '') {
      waitForAgentClient.setContextItemId(snapshot.id, step.id, item.value).then(() =>
        updateFlow(() => {
          step.contextItemIds = updatedCtxItems;
        }),
      );
    }
  };
  const onCurrentContextOptionChange = (val: Option, index: number) => {
    const updatedCtxItems = contextItems.map((contextItem, cIndex) =>
      index === cIndex
        ? {
            ...contextItem,
            currentContextItemId: val.value,
          }
        : contextItem,
    );
    setContextItems(updatedCtxItems);
    if (contextItems[index].currentContextItemId === '') {
      waitForAgentClient
        .setContextItem(snapshot.id, step.id, {
          currentContextItemId: val.value,
          secondAgentContextItemOriginalId: contextItems[index].secondAgentContextItemOriginalId,
        })
        .then(() =>
          updateFlow(() => {
            step.contextItemIds = updatedCtxItems;
          }),
        );
    } else {
      waitForAgentClient.setContextItemOriginalId(snapshot.id, step.id, val.value).then(() =>
        updateFlow(() => {
          step.contextItemIds = updatedCtxItems;
        }),
      );
    }
  };

  if (!step) {
    return <React.Fragment />;
  }

  return (
    <StepView step={step} styling={WaitForAgentStyling} isCollapsible={true} manipulateStep={manipulateStep}>
      <Field inputId="agent" label="Agent" name="Agent" groupClass="col">
        <Select
          options={agentOptions}
          value={{ label: getBotById(step.botId)?.name || '', value: step.botId }}
          onChange={(val) => val && onAgentSelect(val)}
          className="trigger-agent-agent-select mb-2"
        />
      </Field>
      <Fragment>
        <h5 className="mt-1 mb-0">Context items:</h5>
        {contextItems.map((item, index) => (
          <div className="mb-2 shadow p-2 bg-white rounded">
            <div className="context-heading">
              <h6>Second agent context item:</h6>
              <Button
                isDisabled={bots.isReadOnlyBot}
                type={VisualCategory.Danger}
                className="btn-sm"
                action={() => deleteContextItem(index)}
              >
                <Icon type={IconType.Delete} />
              </Button>
            </div>
            <Select
              options={agentContexts.map((ctx) => ({
                label: ctx.name,
                value: ctx.id,
              }))}
              hideSelectedOptions={true}
              value={{
                label: getContextItemName(agentContexts, item.secondAgentContextItemOriginalId),
                value: item.secondAgentContextItemOriginalId,
              }}
              onChange={(val) => val && onSecondAgentContextOptionChange(val, index)}
              key={`secondAgentContextItem-${index}`}
            />
            <h6>Current agent context item:</h6>
            <Select
              options={contextsOptions}
              isDisabled={!item.secondAgentContextItemOriginalId}
              hideSelectedOptions={true}
              value={{
                label: getContextItemName(contexts.contextItems, item.currentContextItemId),
                value: item.currentContextItemId,
              }}
              onChange={(val) => val && onCurrentContextOptionChange(val, index)}
              key={`currentAgentContextItem-${index}`}
            />
          </div>
        ))}
      </Fragment>

      <Button
        className={'mt-2'}
        isDisabled={bots.isReadOnlyBot || !step.botId}
        type={VisualCategory.Primary}
        action={addContextItem}
      >
        <Icon type={IconType.Add} /> Add
      </Button>
    </StepView>
  );
};
