import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { DefaultPublisher, formatDate } from '@smartaction/common';
import { Color } from '@smartaction/styles';
import { Button } from '@smartaction/visuals';
import { Config, ConfigValue, DateRange, Environment, TwilioValue, ValueType } from 'internal/models';
import { VisualCategory } from '@smartaction/styles';
import { LoadConfigValueEvent } from 'ui/events/LoadConfigValueEvent';

export const ValueRendererName = 'ValueRenderer';

export type ValueRendererOptions = {
  environment: Environment;
  canViewSensitiveValues: boolean;
  configSet: 'technical' | 'business';
};

export const ValueRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { value } = params;
  const configValue = (value as ConfigValue)!;
  if (!configValue) {
    return <React.Fragment />;
  }

  switch (configValue.type) {
    case ValueType.Unset:
      return (
        <span className={Color(VisualCategory.Secondary)}>
          <i>null</i>
        </span>
      );
    case ValueType.Sensitive:
      return <LoadSensitiveValueRenderer {...params} />;
    case ValueType.String:
      return <StringValueRenderer {...params} />;
    case ValueType.Number:
      return <NumberValueRenderer {...params} />;
    case ValueType.Boolean:
      return <BooleanValueRenderer {...params} />;
    case ValueType.MultiString:
      return <MultiStringValueRenderer {...params} />;
    case ValueType.Date:
      return <DateValueRenderer {...params} />;
    case ValueType.DateRange:
      return <DateRangeValueRenderer {...params} />;
    case ValueType.Time:
      return <TimeValueRenderer {...params} />;
    case ValueType.DayOfWeek:
      return <DayOfWeekRenderer {...params} />;
    case ValueType.DayOfMonth:
      return <NumberValueRenderer {...params} />;
    case ValueType.Twilio:
      return <TwilioValueRenderer {...params} />;
  }
  return <React.Fragment />;
};

const LoadSensitiveValueRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { data } = params;
  const config = data as Config;
  const options = (params as any).options as ValueRendererOptions;
  if (!options.canViewSensitiveValues) {
    return (
      <span>
        <i>Sensitive</i>
      </span>
    );
  }

  const { configSet, environment } = options;

  return (
    <Button
      className="btn-sm"
      type={VisualCategory.Light}
      action={() => DefaultPublisher.publish(new LoadConfigValueEvent(configSet, config, environment))}
    >
      Load
    </Button>
  );
};

const StringValueRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { value } = params;
  const configValue = value as ConfigValue;

  return <React.Fragment><>{configValue?.value}</></React.Fragment>;
};

const NumberValueRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { value } = params;
  const configValue = value as ConfigValue;

  return <React.Fragment><>{configValue?.value}</></React.Fragment>;
};

const BooleanValueRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { value } = params;
  const configValue = value as ConfigValue;

  if (configValue?.value !== undefined) {
    return (
      <input
        className="form-check-input form-control disabled"
        type="checkbox"
        id="checkboxNoLabel"
        checked={configValue.value as boolean}
        disabled={true}
      />
    );
  }

  return <React.Fragment />;
};

const DateValueRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { value } = params;

  const configValue = value as ConfigValue;

  return <React.Fragment>{(configValue?.value as Date).toDateString()}</React.Fragment>;
};

const MultiStringValueRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { value } = params;
  const configValue = value as ConfigValue;

  const content = configValue?.value ? (
    <span># of values: {(configValue.value as string[]).length}</span>
  ) : (
    <i>No value</i>
  );
  return <React.Fragment>{content}</React.Fragment>;
};

const DateRangeValueRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { value } = params;

  const configValue = value as ConfigValue;
  const rangeValue = configValue?.value as DateRange;
  if (!rangeValue || (!rangeValue.start && !rangeValue.end)) {
    return <React.Fragment>---</React.Fragment>;
  }

  return (
    <span>
      {rangeValue.start ? formatDate(rangeValue.start) : '?'} - {rangeValue.end ? formatDate(rangeValue.end) : '?'}
    </span>
  );
};

const TimeValueRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { value } = params;
  const configValue = value as ConfigValue;

  if (configValue?.value) {
    //Dont really care for the date but just the time to be
    //able to use functions for a date dealing with time.
    var dummyDate = new Date('1070-01-01T' + configValue?.value);
    return <React.Fragment>{dummyDate.toLocaleTimeString([], { timeStyle: 'short' })} </React.Fragment>;
  }

  return <React.Fragment><>{configValue?.value}</></React.Fragment>;
};

const DayOfWeekRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { value } = params;
  const configValue = value as ConfigValue;

  return <React.Fragment><>{configValue?.value}</></React.Fragment>;
};

const TwilioValueRenderer: React.FC<ICellRendererParams> = (params: ICellRendererParams) => {
  const { value } = params;
  const { phoneNumber } = value?.value as TwilioValue;

  return <React.Fragment>{phoneNumber}</React.Fragment>;
};
