import { useDroppable } from '@dnd-kit/core';
import { DroppableData } from '@smartaction/visuals';
import React from 'react';
import { Block, Decision, EntryPoint, NoteDraggable, NewStep } from './index';
import { NumericInputStyling } from '../stepManagement';
import { NodesSection } from './NodesSection';
import { StepsSection } from './StepsSection';


const ModuleToolbar = () => {
  const data: DroppableData = {
    identifier: 'toolBar',
    allowedTypes: [],
    isEnabled: true,
    allowsSorting: true,
    itemsAdded: () => {},
    itemsRemoved: () => {},
    itemsMoved: () => {},
    clearSelected: () => {},
  };
  const { setNodeRef } = useDroppable({ id: 'toolBar', disabled: false, data: data });
  return (
    <div ref={setNodeRef} className="flow-toolbar">
      <NodesSection />
      <StepsSection data={data} />
    </div>
  );
};

export default ModuleToolbar;
