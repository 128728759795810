import { ICellEditorParams } from 'ag-grid-community';
import { Pointer, PointerType } from 'internal/models';
import { PointerControl } from 'ui/components';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

export const PointerEditor = forwardRef((props: ICellEditorParams, ref: React.ForwardedRef<unknown>) => {
  const pointer = props.value as Pointer;
  const { colDef } = props;
  const allowedTypes = (colDef as any).allowedTypes as PointerType[];
  const [finishedPointer, setFinishedPointer] = useState<Pointer | undefined>(pointer);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return finishedPointer;
      },
      isCancelAfterEnd: () => {
        return finishedPointer === undefined;
      },
    };
  });

  const save = (isValid: boolean, pointer?: Pointer) => {
    if (isValid) {
      setFinishedPointer(pointer);
    } else {
      setFinishedPointer(undefined);
    }
  };
  const width = props.column.getActualWidth();

  return (
    <div className="pointer-editor" style={{ width }}>
      <div className="mt-2">
        <PointerControl types={allowedTypes} pointer={pointer} update={(p) => save(true, p)} />
      </div>
    </div>
  );
});
