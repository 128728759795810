export enum DesignViews {
    Module = 'module',
    Function = 'func',
    ContextManager = 'ContextManager',
    ResourceManager = 'ResourceManager',
    Objects = 'Objects',
    Configurations = 'Configurations',
    APIs = 'APIs',
    Properties = 'Properties',
    Policies = 'Policies',
    Scripts = 'Scripts',
  }