import { Endpoint, Field } from 'internal/models';
import { PostError } from 'ui/events';
import { DataResponse, ServerResponse, Methods, ValidateResponseActionEnum } from 'internal/clients';
import { IEndpointClient } from './IEndpointClient';

export class EndpointClient implements IEndpointClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }

  async getEndpointAsync(snapshotId: string, apiId: string, endpointId: string): Promise<DataResponse<Endpoint>> {
    const url = `${this.getUrl(snapshotId, apiId)}/${endpointId}`;
    const response = await this.methods.get(url);

    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} Endpoint`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    if (!Array.isArray(data)) {
      const error = 'Unexpected response when retrieving Context Items';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data: Endpoint.fromJson(data),
    };
  }

  async getEndpointScriptAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    environment: string,
  ): Promise<DataResponse<string>> {
    const url = `${this.getUrl(snapshotId, apiId)}/${endpointId}/environment/${environment}/script`;
    const response = await this.methods.get(url);

    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} script`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }
    const data = await response.blob();

    return {
      success: true,
      data: await data.text(),
    };
  }

  async createEndpointAsync(
    snapshotId: string,
    apiId: string,
    name: string,
    description: string,
  ): Promise<DataResponse<string>> {
    const url = this.getUrl(snapshotId, apiId);
    const request = {
      name: name,
      description: description,
    };

    const response = await this.methods.post(url, request);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Created} Endpoint`, response);
  }

  async updateEndpointNameAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    name: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, apiId)}/${endpointId}/name`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', name);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Endpoint name`, response);
  }

  async updateEndpointDescriptionAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    description: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, apiId)}/${endpointId}/description`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', description);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Endpoint description`,
      response,
    );
  }

  async updateEndpointScriptAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    environment: string,
    script: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, apiId)}/${endpointId}/${environment}/script`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', script);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Endpoint script`, response);
  }

  async deleteEndpointAsync(snapshotId: string, apiId: string, endpointId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, apiId)}/${endpointId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Endpoint`, response);
  }

  async createInputFieldAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    field: Field,
  ): Promise<DataResponse<string>> {
    const url = this.getUrlInput(snapshotId, apiId, endpointId);

    const request = {
      name: field.name,
      description: field.description,
      type: field.type,
      isList: field.isList,
    };

    const response = await this.methods.post(url, request);

    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Created} Input Field`, response);
  }

  async updateInputFieldNameAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    inputId: string,
    name: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrlInput(snapshotId, apiId, endpointId)}/${inputId}/name`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', name);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Input Field name`, response);
  }

  async updateInputFieldDescriptionAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    inputId: string,
    description: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrlInput(snapshotId, apiId, endpointId)}/${inputId}/description`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', description);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Input Field description`,
      response,
    );
  }

  async updateInputFieldParameterTypeAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    inputId: string,
    parameterType: Field,
  ): Promise<ServerResponse> {
    const url = `${this.getUrlInput(snapshotId, apiId, endpointId)}/${inputId}/parameterType`;
    const request = {
      Type: parameterType.type,
      TypeId: parameterType.typeId,
    };
    const response = await this.methods.put(url, request);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Input Field parameterType`,
      response,
    );
  }

  async updateInputFieldIsListAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    inputId: string,
    isList: boolean,
  ): Promise<ServerResponse> {
    const url = `${this.getUrlInput(snapshotId, apiId, endpointId)}/${inputId}/isList`;
    const response = await this.methods.put(url, isList);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Input Field isList`, response);
  }

  async deleteInputFieldAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    inputId: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrlInput(snapshotId, apiId, endpointId)}/${inputId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Input Field`, response);
  }

  async createOutputFieldAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    field: Field,
  ): Promise<DataResponse<string>> {
    const url = this.getUrlOutput(snapshotId, apiId, endpointId);

    const request = {
      name: field.name,
      description: field.description,
      type: field.type,
      isList: field.isList,
    };

    const response = await this.methods.post(url, request);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Created} Output Field`, response);
  }

  async updateOutputFieldNameAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    outputId: string,
    name: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrlOutput(snapshotId, apiId, endpointId)}/${outputId}/name`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', name);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Output Field name`, response);
  }

  async updateOutputFieldDescriptionAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    outputId: string,
    description: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrlOutput(snapshotId, apiId, endpointId)}/${outputId}/description`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', description);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Output Field description`,
      response,
    );
  }

  async updateOutputFieldParameterTypeAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    outputId: string,
    parameterType: Field,
  ): Promise<ServerResponse> {
    const url = `${this.getUrlOutput(snapshotId, apiId, endpointId)}/${outputId}/parameterType`;
    const request = {
      Type: parameterType.type,
      TypeId: parameterType.typeId,
    };
    const response = await this.methods.put(url, request);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Output Field parameterType`,
      response,
    );
  }

  async updateOutputFieldIsListAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    outputId: string,
    isList: boolean,
  ): Promise<ServerResponse> {
    const url = `${this.getUrlOutput(snapshotId, apiId, endpointId)}/${outputId}/isList`;
    const response = await this.methods.put(url, isList);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Output Field isList`,
      response,
    );
  }

  async deleteOutputFieldAsync(
    snapshotId: string,
    apiId: string,
    endpointId: string,
    outputId: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrlOutput(snapshotId, apiId, endpointId)}/${outputId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Output Field`, response);
  }

  private getUrl(snapshotId: string, apiId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/snapshot/${snapshotId}/api/${apiId}/endpoint`;
  }

  private getUrlInput(snapshotId: string, apiId: string, endpointId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/snapshot/${snapshotId}/api/${apiId}/endpoint/${endpointId}/parameter/input`;
  }

  private getUrlOutput(snapshotId: string, apiId: string, endpointId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/snapshot/${snapshotId}/api/${apiId}/endpoint/${endpointId}/parameter/output`;
  }
}
