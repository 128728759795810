import React, { useEffect, useState } from 'react';
import { Table, TableRow } from 'ui/components';
import { v4 as uuid } from 'uuid';
import { Button } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { useClient, useProperties, useSnapshot, useTenant } from 'contexts';
import ReactSelect from 'react-select';

const SupportedLanguagesTable:React.FC<{isReadOnly:boolean}> = ({isReadOnly}) => {
  const properties = useProperties();
  const client = useClient('properties');
  const snapshot = useSnapshot();
  const tenant = useTenant();

  useEffect(() => {
    setRows(getInitialRowsData());
  }, [properties]);

  const handleAddPropertyServiceRow = () => setRows([...rows, initialRowValues]);

  const handleDefaultLanguage = (lng: string) => {
    if (tenant?.id) {
      client.updateDefaultLanguage(snapshot.snapshot.id, tenant?.id, lng).then(() => properties.refresh(true));
    }
  };
  
  const handleSupportedLanguages = (lng: string, voice: string) => {
    if (tenant?.id) {
      client
        .updateSupportedLanguages(snapshot.snapshot.id, tenant?.id, lng, voice)
        .then(() => properties.refresh(true));
    }
  };

  const handleDeletePropertyService = (lng: string) => {
    if (tenant?.id) {
      client.deleteSupportedLanguage(snapshot.snapshot.id, tenant?.id, lng).then(() => properties.refresh(true));
    }
  };

  const initialRowValues: TableRow = {
    id: uuid(),
    cells: [
      {
        type: 'checkbox',
        value: false,
        id: 'default',
        disabled: true,
        onChange: () => null,
      },
      {
        type: 'customNode',
        id: 'lngCode',
        customNode: (
          <ReactSelect
            styles={{
              container: (base) => ({
                ...base,
                flex: 1,
              }),
            }}
            options={properties?.languageOptions?.filter(
              (languageOption: { value: string }) =>
                !properties?.languageModel?.supportedLanguages.hasOwnProperty(languageOption.value),
            )}
            onChange={(value: any) => handleSupportedLanguages(value.value, value.voices[0].value)}
          ></ReactSelect>
        ),
      },
      {
        type: 'customNode',
        id: 'voice',
        customNode: (
          <ReactSelect
            styles={{
              container: (base) => ({
                ...base,
                flex: 1,
              }),
            }}
            isDisabled={true}
          ></ReactSelect>
        ),
      },
    ],
  };

  const getInitialRowsData = () => {
    if (!properties?.languageModel?.supportedLanguages) {
      return [];
    }
    return properties.languageModel.supportedLanguages.map((language, index) => {
      return {
        id: uuid(),
        cells: [
          {
            type: 'checkbox',
            disabled: isReadOnly,
            value: language.isDefault,
            id: `default-${index}`,
            onChange: () => handleDefaultLanguage(language.languageCode),
          },
          {
            type: 'customNode',
            id: `lngCode-${index}`,
            customNode: (
              <ReactSelect
                isDisabled={isReadOnly}
                defaultValue={{
                  label: language.languageCode,
                  value: language.languageCode,
                }}
                styles={{
                  container: (base) => ({
                    ...base,
                    flex: 1,
                  }),
                }}
                options={properties.languageOptions}
              ></ReactSelect>
            ),
          },
          {
            type: 'customNode',
            id: `voice-${index}`,
            customNode: (
              <ReactSelect
                isDisabled={isReadOnly}
                defaultValue={{
                  label: language.voice,
                  value: language.voice,
                }}
                styles={{
                  container: (base) => ({
                    ...base,
                    flex: 1,
                  }),
                }}
                onChange={(value: any) => handleSupportedLanguages(language.languageCode, value.value)}
                options={properties?.languageOptions?.filter((lng) => language.languageCode === lng.value)[0]?.voices}
              ></ReactSelect>
            ),
          },
          {
            type: 'customNode',
            id: 'delete',
            customNode: language && (
              <Button
                isDisabled={isReadOnly}
                action={() => handleDeletePropertyService(language.languageCode)}
                type={VisualCategory.Light}
                className={'sa-table-row-btn-delete'}
              >
                <Icon type={IconType.Delete} color="#F62D36" />
              </Button>
            ),
          },
        ],
      } as TableRow;
    });
  };
  
  const [rows, setRows] = useState<TableRow[]>(getInitialRowsData());

  return (
    <div>
      <h2>Languages</h2>
      <Table
        id="propertyServiceTable"
        headersGroup={['Default', 'LanguageCode', 'Voice']}
        columnsSize={[200, 200, 200]}
        rows={rows}
        footer={
          <Button type={VisualCategory.Primary} action={handleAddPropertyServiceRow} isDisabled={isReadOnly}>
            Add
          </Button>
        }
      />
    </div>
  );
};

export default SupportedLanguagesTable;