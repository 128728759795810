// @flow
import * as React from 'react';
import { useFilter } from 'contexts';
import { useEffect, useState } from 'react';

type SearchableProps = {
  id: string;
  children: React.ReactNode;
};
export const Searchable = ({ id, children }: SearchableProps) => {
  const { filter, filterIds, matchesFilter } = useFilter();
  const [isMatchesFilter, setIsMatchesFilter] = useState(false);
  useEffect(() => {
    setIsMatchesFilter(matchesFilter(id));
  }, [filterIds]);
  return (
    <div className="position-relative searchable" style={{ opacity: isMatchesFilter || !filter ? 1 : 0.3, whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
      {children}
    </div>
  );
};
