export class DeployResponse {
  readonly userName: string;
  readonly startedOn: string;
  readonly comment: string;

  constructor(userName: string, startedOn: string, comment: string) {
    this.userName = userName;
    this.startedOn = startedOn;
    this.comment = comment;
  }

  static fromJson(json: any) {
    return new DeployResponse(json.userName || '', json.startedOn || '', json.comment || '');
  }
}
