export enum StepType {
  /* eslint-disable no-unused-vars*/

  // Input
  CLU = 'CLU',
  DTMFMenu = 'DTMFMenu',
  NumericInput = 'NumericInput',
  YesNo = 'YesNo',
  TextEntry = 'TextEntry',
  AddressInput = 'AddressInput',
  ReceiveMedia = 'ReceiveMedia',

  // Output
  Say = 'Say',
  SendSMS = 'SendSMS',

  // Conditional
  If = 'If',
  While = 'While',
  ForEach = 'ForEach',

  // Data
  CallAPI = 'CallAPI',
  DefineObject = 'DefineObject',
  SetValues = 'SetValues',

  // Process
  GoTo = 'GoTo',
  CallFunction = 'CallFunction',
  Script = 'Script',
  Increment = 'Increment',
  SetLanguage = 'SetLanguage',
  SetLocation = 'SetLocation',
  TriggerAgent = 'TriggerAgent',
  WaitForAgent = 'WaitForAgent',
  Break = 'Break',

  // Finish
  PhoneTransfer = 'PhoneTransfer',
  Hangup = 'Hangup',

  Unknown = 'Unknown',
  /* eslint-disable no-unused-vars*/
}

export const ProcessStepTypes = [
  StepType.GoTo,
  StepType.CallFunction,
  StepType.Script,
  StepType.Increment,
  StepType.SetLanguage,
  StepType.SetLocation,
  StepType.TriggerAgent,
  StepType.WaitForAgent,
  StepType.Break,
];
export const DataStepTypes = [StepType.CallAPI, StepType.DefineObject, StepType.SetValues];
export const InputStepTypes = [
  StepType.CLU,
  StepType.DTMFMenu,
  StepType.NumericInput,
  StepType.YesNo,
  StepType.TextEntry,
  StepType.AddressInput,
  StepType.ReceiveMedia,
];
export const OutputStepTypes = [StepType.Say, StepType.SendSMS];
export const ConditionalStepTypes = [StepType.If, StepType.While, StepType.ForEach];
export const FinishStepTypes = [StepType.Hangup, StepType.PhoneTransfer];

export const AllButConditionalStepTypes = [
  ...ProcessStepTypes,
  ...DataStepTypes,
  ...InputStepTypes,
  ...OutputStepTypes,
  ...FinishStepTypes,
];
export const AllStepTypes = [...AllButConditionalStepTypes, ...ConditionalStepTypes];

export const ForEachTypes = [...AllButConditionalStepTypes, ...ConditionalStepTypes].filter((step) => step !== StepType.Break);
