import React, { useEffect, useState } from 'react';
import { PolicyModalProps } from '../Types';
import { Behavior, BehaviorType, RetryPolicy } from 'internal/models';
import { useClient, usePolicies, useSnapshot } from 'contexts';
import { Field, useId } from '@smartaction/visuals';
import { BehaviorControl } from '../behaviors/BehaviorControl';

export const RetryPolicyView: React.FC<PolicyModalProps<RetryPolicy>> = ({ policy, disabled }) => {
    const attemptsId = useId('max-attempts');
    const { updatePolicies } = usePolicies();
    const client = useClient('flow');
    const snapshot = useSnapshot();
    const [max, setMax] = useState(policy.maxAttempts);
    useEffect(() => {
        setMax(policy.maxAttempts);
    }, [policy]);

    const saveMaxAttempts = async () => {
        const result = await client.policies.retries.setMaxAttempts(snapshot.snapshot.id, policy.id, max);

        if (result.success) {
            updatePolicies(() => {
                policy.maxAttempts = max;
            });
        } else {
            setMax(policy.maxAttempts);
        }
    }

    const saveBehavior = async (behavior: Behavior) => {
        const result = await client.policies.retries.setBehavior(snapshot.snapshot.id, policy.id, behavior);

        if (result.success) {
            updatePolicies(() => {
                policy.behavior = behavior;
            });            
        }
    }

    return (
        <>
            <Field label="Max Attempts" inputId={attemptsId}>
                <input disabled={disabled} className='form-control' id={attemptsId} type='number' min={0} max={30} value={max} onChange={(evt) => setMax(evt.target.valueAsNumber)} onBlur={saveMaxAttempts} />
            </Field>
            <BehaviorControl disabled={disabled} update={saveBehavior} allowedTypes={[BehaviorType.CallFunction, BehaviorType.GoTo, BehaviorType.RunScript]} behavior={policy.behavior} />
        </>
    );
};