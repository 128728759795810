import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { PropertyAssignment } from 'internal/models';
import { IDefineObjectClient } from './IDefineObjectClient';

export class DefineObjectClient implements IDefineObjectClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private baseUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }

  async setPropertyAssignments(
    snapshotId: string,
    stepId: string,
    assignments: PropertyAssignment[],
  ): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/property-assignments`;
    const response = await this.methods.put(url, assignments);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Define Object Assignments`, response);
  }
}
