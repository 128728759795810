import { BehaviorType } from "./BehaviorType";
import { Behavior } from "./Behavior";
import { BehaviorWithFollowUps } from "./IFollowUpList";

export class WaitBehavior extends BehaviorWithFollowUps {
    secondsToWait: number;
    resumeKeywords: WaitKeywords[];

    constructor(secondsToWait: number, resumeKeywords: WaitKeywords[], followUpBehaviors: Behavior[]) {
        super(BehaviorType.Wait, followUpBehaviors);
        this.secondsToWait = secondsToWait;
        this.resumeKeywords = resumeKeywords;
    }

    static fromJson(json: any, followUpBehaviors: Behavior[]): WaitBehavior {
        const keywords = json.resumeKeywords && Array.isArray(json.resumeKeywords)
            ? json.resumeKeywords.map((k: any) => WaitKeywords.fromJson(k))
            : [];
            
        return new WaitBehavior(json.secondsToWait, keywords, followUpBehaviors);
    }
}


export class WaitKeywords {
    languageCode: string;
    keywords: string[];

    constructor(languageCode: string, keywords: string[]) {
        this.languageCode = languageCode;
        this.keywords = keywords;
    }

    static fromJson(json: any) {
        return new WaitKeywords(json.languageCode, json.keywords);
    }
}