export class Output {

    text: string;
    languageCode: string;


    constructor(languageCode: string, text: string) {
        this.text = text;
        this.languageCode = languageCode;
    }

    static fromJson(json: any) {
        return new Output(json.languageCode, json.text);
    }
}

export enum LangType {
    /* eslint-disable no-unused-vars */
    Eng_US = "en-us",
    Es_Mx  = "es-mx",
}