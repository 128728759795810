import React, { useEffect, useState } from "react";
import { Behavior, BehaviorType, LangType, NoActionBehavior, WaitBehavior, WaitKeywords } from "internal/models";
import { Button, Field, useId } from "@smartaction/visuals";
import Select, { SingleValue } from 'react-select';
import { Option } from 'ui/types';
import { BehaviorEditorProps } from "./Type";
import { BehaviorControl } from "../BehaviorControl";
import { Icon, IconType, VisualCategory } from "@smartaction/styles";
import { FollowUpListView } from "./FollowUpList";

type WaitProps = BehaviorEditorProps<WaitBehavior> & {
    allowedFollowUpTypes: BehaviorType[]
}

export const WaitBehaviorView: React.FC<WaitProps> = ({behavior, update, allowedFollowUpTypes}) => {
    // nullables because when this first comes in, the behavior may be the wrong type.
    const useSecondsId = useId("waitBehaviorSeconds");
    const [seconds, setSeconds] = useState(behavior.secondsToWait ?? 0);
    const [keywords, setKeywords] = useState(behavior.resumeKeywords ?? []);

    useEffect(() => {
        setSeconds(behavior.secondsToWait);
        setKeywords(behavior.resumeKeywords);
    }, [behavior]);

    const saveFollowUps = (behaviors: Behavior[]) => {
        update(new WaitBehavior(seconds, keywords, behaviors));
    }

    const saveFieldChanges = () => {
        update(new WaitBehavior(seconds, keywords, behavior.followUpBehaviors));
    }

    const saveKeywords = (waitKeywords: WaitKeywords, index: number) => {
        keywords[index] = waitKeywords;
        setKeywords([...keywords]);
        update(new WaitBehavior(seconds, keywords, behavior.followUpBehaviors));
    }

    const followUps = allowedFollowUpTypes.length
    ? (
        <React.Fragment>
            <hr/>
            <FollowUpListView allowedTypes={allowedFollowUpTypes} followUpBehaviors={behavior.followUpBehaviors} update={saveFollowUps} />
        </React.Fragment>
    ) 
    : <React.Fragment/>;

    return (
        <React.Fragment>
            <Field inputId={useSecondsId} label='Seconds to Wait'>
                <input id={useSecondsId} type="number" value={seconds} onChange={(evt) => setSeconds(Number.parseInt(evt.target.value))} onBlur={saveFieldChanges} max='120' />
            </Field>
            {keywords.map((k, i) => <WaitBehaviorKeyword keywords={k.keywords} languageCode={k.languageCode} index={i} update={saveKeywords} />)}
            <Button action={() => setKeywords([...keywords, new WaitKeywords('', [])])} type={VisualCategory.Success}><Icon type={IconType.Add} /> Add</Button>
            {followUps}
        </React.Fragment>
    );
}

type WaitBehaviorKeywordProps = {
    languageCode: string,
    keywords: string[],
    index: number,
    update: (waitKeywords: WaitKeywords, index: number) => void
}

const WaitBehaviorKeyword: React.FC<WaitBehaviorKeywordProps> = ({languageCode, keywords: k, index, update}) => {
    const languageCodeId = useId("waitLanguageCode");
    const keywordsId = useId("waitKeywords");
    const [languageCodeOption, setLanguageCodeOption] = useState(languageCode ? LanguageCodeOptions.find(o => o.value === languageCode) : LanguageCodeOptions[0]);
    const [keywords, setKeywords] = useState(k.join(', '));

    const onChange = (newVal: SingleValue<Option>) => {
        if (newVal) {
            setLanguageCodeOption(newVal);
            update(new WaitKeywords(newVal.value, keywords.split(',').map(k => k.trim())), index);
        }
    }

    return (
        <React.Fragment>
            <Field label='Language Code' inputId={languageCodeId}>
                <Select id={languageCodeId} options={LanguageCodeOptions} value={languageCodeOption} onChange={onChange}  />
            </Field>
            <Field label="Keywords" inputId={keywordsId}>
                <input id={keywordsId} type='text' value={keywords} onChange={(e) => setKeywords(e.target.value)} onBlur={(e) => e.target.value.split(',').map(k => k.trim())} />
            </Field>
        </React.Fragment>
    )
}

const LanguageCodeOptions = [
    { label: "- Select -", value: ""},
    { label: LangType.Eng_US, value: LangType.Eng_US },
    { label: LangType.Es_Mx, value: LangType.Es_Mx },
];
