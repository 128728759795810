import { ValidationErrors } from "@smartaction/common";
import { Config } from 'internal/models';
import {RegExps} from "ui/constants";

export function ValidateConfig(config: Config, validationErrors: ValidationErrors) {
    validationErrors.resetLocal();
    const validNameValue = (RegExps.letterAndNumberWithUnderscore).test(config.name)
    // config not yet instantiated, nothing to validate
    if (!config) {
        return;
    }

    if (!config.name || config.name.trim().length === 0 || config.name.trim().length > 60) {
        validationErrors.addLocalError("name", "Name cannot be empty and no more than 60 characters.");
    }

    if (!validNameValue) {
        validationErrors.addLocalError("name", "Name cannot contain special characters.");
    }

    if (!config.type) {
        validationErrors.addLocalError("type", "Type must be set.");
    }
}