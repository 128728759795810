import { Module } from './Module';
import { Policy } from './policies/Policy';
import { PolicyBuilder } from './policies/PolicyBuilder';
import { Func } from './functions';

/** Represents the content of an InteractionVersion dedicated to the actual flow of the Interaction. */
export class Flow {
  snapshotId: string;
  modules: Array<Module>;
  functions: Array<Func>;
  policies: Array<Policy>;
  exceptionHandlerId: string;

  constructor(snapshotId: string, modules: Array<Module>, functions: Array<Func>, policies: Array<Policy>, exceptionHandlerId: string) {
    this.snapshotId = snapshotId;
    this.modules = modules;
    this.functions = functions;
    this.policies = policies;
    this.exceptionHandlerId = exceptionHandlerId;
  }

  /** NOT a deep copy. Just replaces the Flow to cause a new reference, and therefore a re-render. */
  clone(): Flow {
    return new Flow(this.snapshotId, this.modules, this.functions, this.policies, this.exceptionHandlerId);
  }

  static fromJson(json: any) {
    //TODO: Response not returning front/back door and other properties. Also some
    //are being returned but are not used in Flow. ex Version, SnapshotId
    const modules = json.modules && Array.isArray(json.modules) ? json.modules.map((m: any) => Module.fromJson(m)) : [];

    const functions =
      json.functions && Array.isArray(json.functions) ? json.functions.map((m: any) => Func.fromJson(m)) : [];
    const policies =
      json.policies && Array.isArray(json.policies) ? json.policies.map((s: any) => PolicyBuilder(s)) : [];

    const flow = new Flow(json.snapshotId, modules, functions, policies, json.exceptionHandlerId);
    return flow;
  }
}
