import { usePortalModal } from '@smartaction/visuals';
import { Policy, PolicyType } from 'internal/models';
import { PoliciesModal } from './PoliciesModal';
import { ColorHexes, Icon, IconType } from '@smartaction/styles';
import { usePolicies } from 'contexts';
import { POLICY_ICON_STATUS_COLOR } from 'ui/constants';
import { useCallback, useState } from 'react';

type usePoliciesModalResponse = {
    icon: React.ReactElement,
    clickableIcon: React.ReactElement,
    modal: React.ReactElement,
    modalOpen: () => void
}

export const usePoliciesModal = (ownerId: string, allowedTypes: PolicyType[], createPolicy: (type: PolicyType) => Promise<Policy>): usePoliciesModalResponse => {
    const policies = usePolicies().getPoliciesByOwnerId(ownerId);
    const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false);
    const maybeReopenPoliciesModal = useCallback(() => {
        if(isPolicyModalOpen) {
            console.log("gonna open");
            setTimeout(() => modalOpen(), 500);
            setIsPolicyModalOpen(false);
        }
        console.log('closing main');
        modal.closeModal();
        return false;
    }, [isPolicyModalOpen]);
    const modal = usePortalModal(undefined, maybeReopenPoliciesModal);

    const openPolicyModal = (policyModal: React.ReactElement) => {
        modal.closeModal();
        setTimeout(() => modal.openModal(policyModal), 300);
        setIsPolicyModalOpen(true);
    }
    const modalOpen = () => {
        console.log('reopening main');
        modal.openModal(<PoliciesModal ownerId={ownerId} allowedTypes={allowedTypes} createPolicy={createPolicy} openPolicyModal={openPolicyModal} />);
    }
    const openPoliciesModal = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        modalOpen();
    }

    const color = policies.length
        ? ColorHexes.Primary
        : ColorHexes.Secondary;

    return {
        icon: <span className='policy-modal-button'><Icon type={IconType.BookOpen} color={color} /></span>,
        clickableIcon: <span className='policy-modal-button clickable' onClick={openPoliciesModal}><Icon type={IconType.BookOpen} color={color} /></span>,
        modalOpen: modalOpen,
        modal: modal.modal
    };
};