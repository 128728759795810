import {
  AzureLanguage,
  BargeIn,
  BargeInKeywords,
  BargeInPolicy,
  Behavior,
  LangType,
  NoActionBehavior,
  ResourceType,
} from 'internal/models';
import { useEffect, useMemo, useState } from 'react';
import { useClient, usePolicies, useResources, useSnapshot } from 'contexts';
import { createBargeInTriggerRow, CreateBargeInTriggerRowProps } from './dataGenerator';
import { arrayInsert, arrayRemove } from '@smartaction/common';
import { transformStringToArray } from 'ui/utils';

export type IntentOption = {
  label: string;
  value: string;
  resourceId: string;
}
interface UseBargeInPolicyLogicRes {
  data: any[];
  isLoading: boolean;
  intentsOptions: Array<{
    label: string;
    options: IntentOption[];
  }>
  onCreateBargeIn: () => void;
  onDeleteBargeIn: (bargeInIndex: number) => void;
  onChangeEnabled: (v: boolean) => void;
  onChangeExcludeParents: (v: boolean) => void;
  onChangePolicyName: (bargeInIndex: number, v: string) => void;
  onChangeDtmfKey: (bargeInIndex: number, v: string) => void;
  onChangeBehavior: (bargeInIndex: number, v: Behavior) => void;
  onCreateTrigger: (bargeInIndex: number) => void;
  onDeleteTrigger: (b: { bargeInIndex: number; triggerIndex: number }) => void;
  onChangeIntents: (p: {bargeInIndex: number; selectedIntents: IntentOption[] }) => void;
}

export const useBargeInPolicyLogic = (policy: BargeInPolicy): UseBargeInPolicyLogicRes => {
  const [data, setData] = useState<any[]>([]);
  const { updatePolicies } = usePolicies();
  const client = useClient('flow');
  const resources = useResources().byType.get(ResourceType.AzureLanguage) ?? [];

  const { snapshot } = useSnapshot();
  const [isLoading, setIsLoading] = useState(false);

  const intentsOptions: UseBargeInPolicyLogicRes['intentsOptions']  = useMemo(() => {
    return resources.filter( r => r.type === ResourceType.AzureLanguage ).map((r) => {
      const nlu = r as AzureLanguage;

      const grouped = nlu.intents
        .filter((i) => i.name !== 'None')
        .map((el) => {
          return {
            label: el.name,
            value: el.name,
            resourceId: nlu.id,
          };
        });

      return {
        label: nlu.modelName,
        options: grouped,
      };
    });
  }, [resources]);

  const loadingHandler = async (callback: any) => {
    setIsLoading(true);
    try {
      await callback();
      setData(
        policy.bargeIns.map((b: BargeIn, bargeInIndex) =>
          createBargeInTriggerRow({
            bargeIn: b,
            bargeInIndex,
            onChangeTrigger,
          }),
        ),
      );
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const onCreateBargeIn: UseBargeInPolicyLogicRes['onCreateBargeIn'] = () => {
    void loadingHandler(async () => {
      const res = await client.policies.bargeIns.createBargeInAsync(snapshot.id, policy.id, 'New Barge-In');
      if (res.success) {
        updatePolicies(() => {
          policy.bargeIns = arrayInsert(
            policy.bargeIns,
            new BargeIn(res.data!, 'New Barge-In', new NoActionBehavior(), '', [], []),
          );
        });
      }
    });
  };
  const onDeleteBargeIn: UseBargeInPolicyLogicRes['onDeleteBargeIn'] = (bargeInIndex) => {
    const bargeIn = policy.bargeIns[bargeInIndex];
    void loadingHandler(async () => {
      const res = await client.policies.bargeIns.deleteBargeInAsync(snapshot.id, policy.id, bargeIn.id);
      if (res.success) {
        updatePolicies(() => {
          policy.bargeIns = arrayRemove(policy.bargeIns, bargeInIndex);
        });
      }
    });
  };

  const onChangeEnabled: UseBargeInPolicyLogicRes['onChangeEnabled'] = (enabled) => {
    void loadingHandler(async () => {
      const res = await client.policies.bargeIns.setEnabledAsync(snapshot.id, policy.id, enabled);
      if (res.success) {
        updatePolicies(() => {
          policy.enabled = enabled;
        });
      }
    });
  };
  const onChangeExcludeParents: UseBargeInPolicyLogicRes['onChangeExcludeParents'] = async (excludeParents) => {
    void loadingHandler(async () => {
      const res = await client.policies.bargeIns.setExcludeParents(snapshot.id, policy.id, excludeParents);
      if (res.success) {
        updatePolicies(() => {
          policy.excludeParents = excludeParents;
        });
      }
    });
  };

  const onChangePolicyName: UseBargeInPolicyLogicRes['onChangePolicyName'] = async (index, value) => {
    const bargeIn = policy.bargeIns[index];
    void loadingHandler(async () => {
      const res = await client.policies.bargeIns.setBargeInNameAsync(snapshot.id, policy.id, bargeIn.id, value);
      if (res.success) {
        updatePolicies(() => {
          bargeIn.name = value;
        });
      }
    });
  };
  const onChangeDtmfKey: UseBargeInPolicyLogicRes['onChangeDtmfKey'] = async (index, value) => {
    const bargeIn = policy.bargeIns[index];
    void loadingHandler(async () => {
      const res = await client.policies.bargeIns.setBargeInDtmfAsync(snapshot.id, policy.id, bargeIn.id, value);
      if (res.success) {
        updatePolicies(() => {
          bargeIn.dtmfKey = value;
        });
      }
    });
  };
  const onChangeBehavior: UseBargeInPolicyLogicRes['onChangeBehavior'] = async (index, value) => {
    const bargeIn = policy.bargeIns[index];
    void loadingHandler(async () => {
      const res = await client.policies.bargeIns.setBargeInBehaviorAsync(snapshot.id, policy.id, bargeIn.id, value);
      if (res.success) {
        updatePolicies(() => {
          bargeIn.behavior = value;
        });
      }
    });
  };

  const onCreateTrigger: UseBargeInPolicyLogicRes['onCreateTrigger'] = (index) => {
    const bargeIn = policy.bargeIns[index];
    void loadingHandler(async () => {
      const keywords = arrayInsert(bargeIn.keywords, new BargeInKeywords(LangType.Eng_US, []));
      const res = await client.policies.bargeIns.setBargeInTriggersAsync(snapshot.id, policy.id, bargeIn.id, keywords);
      if (res.success) {
        updatePolicies(() => {
          bargeIn.keywords = keywords;
        });
      }
    });
  };
  const onDeleteTrigger: UseBargeInPolicyLogicRes['onDeleteTrigger'] = (t) => {
    const bargeIn = policy.bargeIns[t.bargeInIndex];
    void loadingHandler(async () => {
      const keywords = arrayRemove(bargeIn.keywords, t.triggerIndex);
      const res = await client.policies.bargeIns.setBargeInTriggersAsync(snapshot.id, policy.id, bargeIn.id, keywords);
      if (res.success) {
        updatePolicies(() => {
          bargeIn.keywords = keywords;
        });
      }
    });
  };

  const onChangeTrigger: CreateBargeInTriggerRowProps['onChangeTrigger'] = (t) => {
    const bargeIn = policy.bargeIns[t.bargeInIndex];
    void loadingHandler(async () => {
      if (t.keyValue === 'keywords') bargeIn.keywords[t.triggerIndex].keywords = transformStringToArray(t.newValue);
      if (t.keyValue === 'languageCode') bargeIn.keywords[t.triggerIndex].languageCode = t.newValue;
      await client.policies.bargeIns.setBargeInTriggersAsync(snapshot.id, policy.id, bargeIn.id, bargeIn.keywords);
    });
  };

  const onChangeIntents: UseBargeInPolicyLogicRes['onChangeIntents'] = ({ bargeInIndex, selectedIntents }) => {
    const bargeIn = policy.bargeIns[bargeInIndex];
    const payload = selectedIntents.map(({value, resourceId}) => ({intent: value, resourceId}));
    void loadingHandler(async () => {
      const res = await client.policies.bargeIns.setBargeInIntentsAsync(snapshot.id, policy.id, bargeIn.id, payload);
      if (res.success) {
        updatePolicies(() => {
          bargeIn.intents = payload;
        });
      }
    });
  };

  useEffect(() => {
    void loadingHandler(() => {});
  }, []);
  return {
    data,
    isLoading,
    intentsOptions,
    onCreateBargeIn,
    onDeleteBargeIn,
    onChangeEnabled,
    onChangeExcludeParents,
    onChangePolicyName,
    onChangeDtmfKey,
    onChangeBehavior,
    onCreateTrigger,
    onDeleteTrigger,
    onChangeIntents,
  };
};
