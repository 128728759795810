import { PublishedSnapshot, UnpublishedSnapshot } from 'internal/models';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SnapshotProvider } from 'contexts/SnapshotContext';
import { FlowView } from './design';
import { ObjectsProvider } from 'contexts/design/ObjectsContext';
import {
  ConfigsProvider,
  ContextItemProvider,
  FlowProvider,
  ResourceProvider,
  PropertiesProvider,
  LocationDefinitionProvider,
  FilterProvider,
  LocationProvider,
} from 'contexts';
import { PublishView } from './publisher';
import { APIsProvider } from 'contexts/design/APISContext';
import { Conversations } from './conversations/Conversations';

type SnapshotViewParams = {
  snapshot: PublishedSnapshot | UnpublishedSnapshot;
};

export const SnapshotView: React.FC<SnapshotViewParams> = ({ snapshot }) => {
  if (!snapshot) return null;

  // Note for below: FlowProvider also provides PoliciesProvider, since it gets the initial list of Policies we build off of.
  return (
    <SnapshotProvider snapshot={snapshot}>
      <ObjectsProvider>
        <ConfigsProvider>
          <PropertiesProvider>
            <APIsProvider>
              <ContextItemProvider>
                <ResourceProvider>
                  <LocationDefinitionProvider name="Default">
                    <LocationProvider>
                      <FlowProvider>
                        <FilterProvider>
                          <Routes>
                            <Route path={'flow'} element={<FlowView />} />
                            <Route path={'publish'} element={<PublishView />} />
                            <Route path={'conversations/*'} element={<Conversations />} />
                          </Routes>
                        </FilterProvider>
                      </FlowProvider>
                    </LocationProvider>
                  </LocationDefinitionProvider>
                </ResourceProvider>
              </ContextItemProvider>
            </APIsProvider>
          </PropertiesProvider>
        </ConfigsProvider>
      </ObjectsProvider>
    </SnapshotProvider>
  );
};
