import { Condition } from "internal/models";
import { Behavior, BehaviorBuilder } from "../behaviors";
import { Policy } from "../Policy";
import { PolicyType } from "../PolicyType";

export class ValidatePolicy extends Policy {
    validations: ValidatePolicy_Validation[];

    constructor(id: string, ownerId: string, validations: ValidatePolicy_Validation[]) {
        super(id, ownerId, PolicyType.Validate);
        this.validations = validations;
    }

    static fromJson(json: any): ValidatePolicy {
        const validations = json.validations && Array.isArray(json.validations)
            ? json.validations.map((v: any) => ValidatePolicy_Validation.fromJson(v))
            : [];
        return new ValidatePolicy(json.id, json.ownerId, validations);
    }
}

export class ValidatePolicy_Validation {
    id: string;
    name: string;
    conditions: Condition[];
    behaviors: Behavior[];

    constructor(id: string, name: string, conditions: Condition[], behaviors: Behavior[]) {
        this.id = id;
        this.name = name;
        this.conditions = conditions;
        this.behaviors = behaviors;
    }

    static fromJson(json: any): ValidatePolicy_Validation {
        const conditions = json.conditions && Array.isArray(json.conditions)
            ? json.conditions.map((c: any) => Condition.fromJson(c))
            : [];
        const behaviors = json.behaviors && Array.isArray(json.behaviors)
            ? json.behaviors.map((b: any) => BehaviorBuilder(b))
            : [];
        return new ValidatePolicy_Validation(json.id, json.name, conditions, behaviors);
    }
}