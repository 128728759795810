import { IEvent } from "@smartaction/common";
import { Tenant } from "internal/models";

export class TenantSelectedEvent implements IEvent {
    public name: string = TenantSelectedEvent.eventName;
    static eventName: "TenantSelectedEvent" = "TenantSelectedEvent";
    
    tenant?: Tenant;

    constructor(tenant?: Tenant) {
        this.tenant = tenant;
    }
}