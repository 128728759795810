import { useId } from '@smartaction/visuals';
import React, { useEffect, useState } from 'react';
import { useBots, useClient, useConfigs, useContextItems, useLocationDefinitions, useObjects } from 'contexts';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import { TextToReplaceType } from '@webscopeio/react-textarea-autocomplete';
import { CustomFieldType } from 'internal/models';
import { useIterationPointerLogic, useReferences } from 'ui/hooks';

type OutputProps = {
  /** Question for an Ask, Statement for a Say */
  typeName: string;
  languageCode: string;
  text: string;
  update: (languageCode: string, text: string) => void;
};
type SuggestionNestedFields = {
  name: string;
  type: string;
  id?: string;
};
type ReferenceType = {
  name: string;
  type: string;
  id?: string;
  objectProps?: SuggestionNestedFields[];
};
// For list
type Suggestion = {
  name: string;
  start?: number;
  end?: number;
  objectProps?: SuggestionNestedFields[];
};

export const OutputView: React.FC<OutputProps> = ({ typeName, languageCode, text, update }) => {
  const [localText, setLocalText] = useState('');
  const [referenceTypes, setReferenceTypes] = useState<Map<string, ReferenceType[]>>(new Map());
  const [position, setPosition] = useState(0);

  const textId = useId('text');
  const references = useReferences();
  const configs = useConfigs();
  const contexts = useContextItems();
  const client = useClient('flow');
  const objects = useObjects();
  const { isReadOnlyBot } = useBots();
  const { locationDefinitions, commonLocationFieldIds } = useLocationDefinitions();
  const { iterationItem, isIterationItemObjectType, isForEachBlock } = useIterationPointerLogic();
  const locationsSuggestionsList = [
    locationDefinitions[0].customFields.map((i) => ({
      name: i.name,
      type: i.type,
    })),
    Object.entries(commonLocationFieldIds).map(([key, val]) => ({
      type: CustomFieldType.String,
      name: key,
    })),
  ].flat();
  useEffect(() => {
    setLocalText(references.fromFlow(text));
    // Set up suggestions
    let map = new Map();
    map.set(
      'Config',
      [...configs.all.values()].map((i) => ({
        name: i.name,
        type: i.type,
      })),
    );
    map.set(
      'Context',
      [...contexts.contextItems.values()].map((i) => ({
        name: i.name,
        type: i.type,
        objectProps: objects.objects.flatMap((obj) => (obj.id === i.typeId ? obj.fields : [])).filter((item) => item),
      })),
    );
    isForEachBlock && map.set('CurrentItem', iterationItem);
    map.set('Location', locationsSuggestionsList);
    setReferenceTypes(map);
  }, [languageCode, text, iterationItem]);

  const onSave = () => {
    if (localText !== text) {
      update(languageCode, references.toFlow(localText));
    }
  };

  // return reference type suggestions
  const onGetTypes = (input: string) => {
    let result: Suggestion[] = [];

    // highlight matching letter
    [...referenceTypes.keys()].forEach((value) => {
      let index = value.toLowerCase().indexOf(input.toLowerCase());
      if (index >= 0) {
        result.push({ name: value, start: index, end: index + input.length });
      }
    });

    return result;
  };
  const isNestedObj = (currentStr: string) => {
    const regex = /\./g;
    const matches = (currentStr.match(regex) || []).length;
    return matches > 1;
  };

  // return name suggestions
  const onGetNames = (input: string) => {
    let result: Suggestion[] = [];

    let nearestFormatter = localText?.slice(0, position - input.length - 1)?.lastIndexOf('[');
    let currentString = localText.substring(nearestFormatter, position);
    let splitString = currentString.split('.');
    let itemType = splitString[0].substring(1);

    if (isNestedObj(currentString)) {
      // Return name suggestions if object has nested fields
      referenceTypes
        .get(itemType) // Get array matching itemType
        ?.filter((item) => item.name === splitString[1])[0] // Get matching object by name
        ?.objectProps?.map((prop) => {
          // Get matching item by name + input comparison
          let index = prop.name.toLowerCase().indexOf(input.toLowerCase());

          if (index >= 0) {
            result.push({ name: prop.name, start: index, end: index + input.length }); // Get suggestions of the object props
          }
        });
    } else {
      // Return default name suggestions
      referenceTypes.get(itemType)?.forEach((value) => {
        let index = value.name.toLowerCase().indexOf(input.toLowerCase());

        if (index >= 0) {
          result.push({ name: value.name, start: index, end: index + input.length, objectProps: value.objectProps });
        }
      });
    }

    return result;
  };

  const onRender = (item: { selected: boolean; entity: any }) => {
    let option = item.entity as Suggestion;
    return (
      <React.Fragment>
        {option.name.slice(0, option.start)}
        <b>{option.name.slice(option.start, option.end)}</b>
        {option.name.slice(option.end, option.name.length)}
        {localText.includes(option.name)}
      </React.Fragment>
    );
  };

  const onLoading = () => {
    // probably not needed
    return <div>Loading</div>;
  };

  const onSelect = (item: string | object, trigger?: string) => {
    let result: TextToReplaceType;
    const { name, objectProps } = item as Suggestion;
    if (trigger === '[') {
      result = { text: '[' + name + '.', caretPosition: 'end' };
    } else if (objectProps?.length) {
      result = { text: '.' + name + '.', caretPosition: 'end' };
    } else {
      result = { text: '.' + name + ']', caretPosition: 'next' };
    }
    if (name === 'CurrentItem' && !isIterationItemObjectType) {
      result = { text: '[' + name + ']', caretPosition: 'next' };
    }
    return result;
  };

  return (
    <div className="row g-0 mb-2">
      <div className="col-2">
        <label className="outputs-code">
          <b>{languageCode}</b>
        </label>
      </div>
      <div className="col-10">
        <ReactTextareaAutocomplete
          onBlur={onSave}
          onChange={(ev) => setLocalText(ev.target.value)}
          className="form-control"
          value={localText}
          disabled={isReadOnlyBot}
          loadingComponent={onLoading}
          onCaretPositionChange={(idx) => setPosition(idx)}
          containerStyle={{
            position: 'relative',
          }}
          dropdownStyle={{
            position: 'absolute',
            marginTop: '1.25rem',
            padding: '.25rem 0',
            borderRadius: '.25rem',
            zIndex: 1,
          }}
          dropdownClassName="bgcolor-light"
          listStyle={{
            listStyle: 'none',
            paddingLeft: 0,
            marginBottom: 0,
          }}
          itemStyle={{
            padding: '0 .25rem',
          }}
          trigger={{
            '[': {
              dataProvider: onGetTypes,
              component: onRender,
              output: onSelect,
            },
            '.': {
              dataProvider: onGetNames,
              component: onRender,
              output: onSelect,
            },
          }}
        />
      </div>
    </div>
  );
};
