import { IResourceClient } from './IResourceClient';
import {
  ServerResponse,
  DataResponse,
  Methods,
  ITwilioClient,
  TwilioClient,
  ValidateResponseActionEnum,
} from 'internal/clients';
import { Resource, ResourceType, AzureLanguage, TwilioResource } from 'internal/models';
import { PostError } from 'ui/events';
import { ILanguageClient } from './ILanguageClient';
import { LanguageClient } from './LanguageClient';
import { IAddressClient } from './IAddressClient';
import { GoogleAddressClient } from './GoogleAddressClient';
import { GoogleAddressResource } from 'internal/models/bots/design/resources/GoogleAddress';

export class ResourceClient implements IResourceClient {
  serviceUrl: string;
  methods: Methods;
  languages: ILanguageClient;
  twilio: ITwilioClient;
  address: IAddressClient;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
    this.languages = new LanguageClient(this.serviceUrl, this.methods);
    this.twilio = new TwilioClient(this.serviceUrl, this.methods);
    this.address = new GoogleAddressClient(this.serviceUrl, this.methods);
  }

  async getAll(snapshotId: string): Promise<DataResponse<Resource[]>> {
    let response = await this.methods.get(this.getUrl(snapshotId));
    let validation = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} list of Resource items`,
      response,
    );

    if (!validation.success) {
      return validation;
    }

    let data = await response.json();

    if (!Array.isArray(data)) {
      const error = 'Unexpected response when retrieving Resource Items';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data: data.map((c) => this.fromJson(c)),
    };
  }

  private fromJson(json: any) {
    switch (json.type) {
      case ResourceType.AzureLanguage:
        return AzureLanguage.fromJson(json);
      case ResourceType.Twilio:
        return TwilioResource.fromJson(json);
      case ResourceType.GoogleAddress:
        return GoogleAddressResource.fromJson(json);
    }
    throw new Error("Resource received doesn't match supported types");
  }

  async delete(snapshotId: string, ResourceId: string): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId)}/${ResourceId}`;
    let response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Resource Item`, response);
  }

  private getUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v2/resources`;
  }
}
