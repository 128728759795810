import { Pointer } from "../../shared";
import { Output } from "../OutputMessages";
import { StepType } from "../Type";
import { Ask } from "./Ask";

export class TextEntry extends Ask {

    constructor(id: string | undefined, name: string, description: string, tags: string[], questions: Output[], saveLocation: Pointer, canInterrupt: boolean) {
        super(id, StepType.TextEntry, name, description, tags, questions, saveLocation, canInterrupt);
    }

    static fromJson(json: any): TextEntry {
        const questions = json.questions && Array.isArray(json.questions)
            ? json.questions.map((p: any) => Output.fromJson(p))
            : [];
        return new TextEntry(json.id, json.name, json.description, json.tags, questions, Pointer.fromJson(json.saveLocation), json.canUserInterrupt);
    }
}