import { DatePicker } from '@smartaction/visuals';
import { ICellEditorParams } from 'ag-grid-community';
import { ConfigValue, ValueType } from 'internal/models';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
export const DateCellEditor = forwardRef((props: ICellEditorParams, ref: React.ForwardedRef<unknown>) => {
  const configValue = props.value as ConfigValue;
  const [value, setValue] = useState(configValue.type === ValueType.Date ? (configValue.value as Date) : undefined);
  const [changed, setChanged] = useState(false);

  function set(date: Date | null) {
    setValue(date !== null ? date : undefined);
    setChanged(date !== configValue.value);
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value ? new ConfigValue(ValueType.Date, value) : new ConfigValue(ValueType.Unset, undefined);
      },
      isCancelAfterEnd: () => {
        return !changed;
      },
    };
  });

  return (
    <div className={'p-2'} style={{ backgroundColor: 'white' }}>
      <DatePicker
        className={'config-date-picker'}
        autoFocus={true}
        selected={value}
        onChange={set}
        shouldCloseOnSelect={true}
      />
    </div>
  );
});
