export type Coords = {
    x: number,
    y: number
}

export function DrawToMiddle(canvas: HTMLCanvasElement, color: string = 'black', lineWidth: number = 2, lineCap : CanvasLineCap = "round") {
    const rect = canvas.getBoundingClientRect();
    const center = { x: rect.width / 2, y: rect.height / 2 };
    const ctx = canvas.getContext('2d')!;
    ctx.canvas.width = rect.width;
    ctx.canvas.height = rect.height;
    ctx.clearRect(0, 0, rect.width, rect.height);
    ctx.strokeStyle = color;
    ctx.lineCap = lineCap;
    ctx.lineJoin = 'round';
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(center.x, 0);
    ctx.lineTo(center.x, center.y);
    ctx.stroke();
}

export function GetCenter(canvas: HTMLCanvasElement): Coords { 
    const rect = canvas.getBoundingClientRect();
    return { x: rect.width / 2, y: rect.height / 2 };
};

export function DrawToElement(canvas: HTMLCanvasElement, element: HTMLElement, startingPoint: Coords, color: string = 'black', lineWidth: number = 2, lineCap : CanvasLineCap = "round") {
    
    const offset = getOffset(canvas);
    const center = getMiddleOfElement(element, offset.x);
    const height = canvas.offsetHeight;

    const ctx = canvas.getContext('2d')!;  
    ctx.strokeStyle = color;
    ctx.lineCap = lineCap;
    ctx.lineJoin = 'round';
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(startingPoint.x, startingPoint.y);
    ctx.lineTo(center, startingPoint.y);
    ctx.lineTo(center, height);
    ctx.stroke();
}

function getMiddleOfElement(element: HTMLElement, offsetX: number): number {
    const rect = element.getBoundingClientRect();
    return rect.width/2 + rect.left - offsetX;
}

function getOffset(element: HTMLElement): Coords {
    const rect = element.getBoundingClientRect();
    return { x: rect.left, y: rect.top };
}