import React from 'react';
import {
  CompareCondition,
  CompareType,
  Condition,
  ConditionType,
  ContainsCondition,
  DateTimeCondition,
  EmptyPointer,
  IsInCondition,
  IsPopulatedCondition,
  RegexCondition,
  ScriptCondition,
  StartsWithCondition,
  EndsWithCondition,
  LengthCompareCondition,
} from 'internal/models';
const getSource = (condition: Condition) => {
  switch (condition.type) {
    case ConditionType.IsIn:
      return (condition as IsInCondition).list;
    case ConditionType.Contains:
      return (condition as ContainsCondition).value;
    case ConditionType.Compare:
      return (condition as CompareCondition).value;
    case ConditionType.DateTime:
      return (condition as DateTimeCondition).value;
    case ConditionType.Regex:
    case ConditionType.Script:
    case ConditionType.IsPopulated:
      return undefined;
    case ConditionType.StartsWith:
      return (condition as StartsWithCondition).value;
    case ConditionType.EndsWith:
      return (condition as EndsWithCondition).value;
    case ConditionType.LengthCompare:
      return (condition as LengthCompareCondition).value;
  }
};
type availableChecks = ConditionType | CompareType | undefined;

interface ConditionCheckRendererProps {
  condition: Condition;
  onUpdateCondition: (condition: Condition) => void;
  isConditionTargetAndSourceTypeTime: boolean;
  isConditionTargetTypeDateTime: boolean;
  isConditionSourceOfTypeDateTime: boolean;
}

export const generalConditionAndComparisonCheckOptions: {
  label: string;
  value: string;
  isDateTimeCheck?: boolean;
  isDateTimeAndNonTimeCheck?: boolean;
}[] = [
  { label: 'Regex', value: ConditionType.Regex },
  { label: 'Contains', value: ConditionType.Contains },
  { label: 'Is In', value: ConditionType.IsIn },
  { label: 'Before (less than)', value: CompareType.LessThan, isDateTimeCheck: true },
  { label: 'After (greater than)', value: CompareType.GreaterThan, isDateTimeCheck: true },
  {
    label: 'Before or On (less than or equal to)',
    value: CompareType.LessThanOrEqualTo,
    isDateTimeCheck: true,
  },
  {
    label: 'On or After (greater than or equal to)',
    value: CompareType.GreaterThanOrEqualTo,
    isDateTimeCheck: true,
  },
  { label: 'Equals', value: CompareType.Equals, isDateTimeCheck: true, isDateTimeAndNonTimeCheck: true },
  { label: 'Has Value', value: ConditionType.IsPopulated, isDateTimeCheck: true },
  { label: 'Script', value: ConditionType.Script, isDateTimeCheck: true },
  { label: 'Not Equal', value: CompareType.NotEqual },
  { label: 'Starts With', value: ConditionType.StartsWith },
  { label: 'Ends With', value: ConditionType.EndsWith },
];

export const dateTimeAndNonTimeConditionAndComparisonOptions = generalConditionAndComparisonCheckOptions.filter(
  (option) => option.isDateTimeCheck,
);
export const dateTimeConditionAndComparisonOptions = dateTimeAndNonTimeConditionAndComparisonOptions.filter(
  (option) => !option.isDateTimeAndNonTimeCheck,
);

const ConditionCheckRenderer: React.FC<ConditionCheckRendererProps> = ({
  condition,
  onUpdateCondition,
  isConditionTargetAndSourceTypeTime,
  isConditionTargetTypeDateTime,
  isConditionSourceOfTypeDateTime,
}) => {
  if (!condition) return null;

  //Selected Condition and Comparison Options are based on types for source and target
  const selectedConditionAndComparisonOptions = isConditionTargetAndSourceTypeTime
    ? dateTimeConditionAndComparisonOptions
    : isConditionTargetTypeDateTime
    ? dateTimeAndNonTimeConditionAndComparisonOptions
    : generalConditionAndComparisonCheckOptions;

  const onChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    // if relevant, we'll put the other pointer of the condition back in for whatever the new condition is
    const source = getSource(condition);
    switch (evt.target.value) {
      case ConditionType.IsIn:
        onUpdateCondition(
          new IsInCondition(
            condition.id,
            condition.target,
            source ?? new EmptyPointer(),
            (condition as any).reverse ?? false,
          ),
        );
        break;
      case ConditionType.Regex:
        onUpdateCondition(new RegexCondition(condition.id, condition.target, ''));
        break;
      case ConditionType.Script:
        onUpdateCondition(new ScriptCondition(condition.id, condition.target, '')); // TODOs: should have samples on both of the above
        break;
      case ConditionType.IsPopulated:
        onUpdateCondition(
          new IsPopulatedCondition(condition.id, condition.target, (condition as any).reverse ?? false),
        );
        break;
      case ConditionType.Contains:
        onUpdateCondition(
          new ContainsCondition(
            condition.id,
            condition.target,
            source ?? new EmptyPointer(),
            (condition as any).reverse ?? false,
          ),
        );
        break;
      case CompareType.Equals:
      case CompareType.GreaterThan:
      case CompareType.GreaterThanOrEqualTo:
      case CompareType.LessThan:
      case CompareType.LessThanOrEqualTo:
        let determinedComparison: Condition;
        if (isConditionTargetTypeDateTime || isConditionSourceOfTypeDateTime) {
          determinedComparison = new DateTimeCondition(
            condition.id,
            condition.target,
            source ?? new EmptyPointer(),
            evt.target.value,
          );
        } else {
          determinedComparison = new CompareCondition(
            condition.id,
            condition.target,
            source ?? new EmptyPointer(),
            evt.target.value,
          );
        }

        onUpdateCondition(determinedComparison);
        break;
      case ConditionType.StartsWith:
        onUpdateCondition(new StartsWithCondition(condition.id, condition.target, source ?? new EmptyPointer()));
        break;
      case ConditionType.EndsWith:
        onUpdateCondition(new EndsWithCondition(condition.id, condition.target, source ?? new EmptyPointer()));
        break;

      default:
        const comparison = evt.target.value as CompareType;
        onUpdateCondition(
          new CompareCondition(condition.id, condition.target, source ?? new EmptyPointer(), comparison),
        );
        break;
    }
  };

  let selectedValue: availableChecks = undefined;

  switch (condition.type) {
    case ConditionType.IsIn:
    case ConditionType.Regex:
    case ConditionType.Script:
    case ConditionType.IsPopulated:
    case ConditionType.Contains:
    case ConditionType.StartsWith:
    case ConditionType.EndsWith:
      selectedValue = condition.type;
      break;
    case ConditionType.Compare:
      const comparison = condition as CompareCondition;
      selectedValue = comparison.comparison;
      break;
    case ConditionType.LengthCompare:
      const lComparison = condition as LengthCompareCondition;
      selectedValue = lComparison.comparison;
      break;
    case ConditionType.DateTime:
      const comparisonDate = condition as DateTimeCondition;
      selectedValue = comparisonDate.comparison;
      break;
  }

  return (
    <select className="form-select" value={selectedValue} onChange={onChange}>
      {selectedConditionAndComparisonOptions.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  );
};

export default ConditionCheckRenderer;
