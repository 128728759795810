import { useClient, useFlow, usePolicies, useSnapshot } from 'contexts';
import {
  Ask,
  BargeInPolicy,
  CallAPI,
  CallFunction,
  ChannelType,
  CLU,
  ConditionalPolicy,
  DTMFMenu,
  EmptyId,
  GoTo,
  If,
  InputFormatterPolicy,
  NumericInput,
  PhoneTransfer,
  PointerType,
  PolicyType,
  Script,
  SendSMS,
  SetLanguage,
  SetLocation,
  SetValues,
  Step,
  StepType,
  TimeoutPolicy,
  TriggerAgent,
  ValidatePolicy,
  WaitForAgent,
} from 'internal/models';
import { useEffect, useState } from 'react';

export const useManipulateStep = () => {
  const client = useClient('flow');
  const { snapshot } = useSnapshot();
  const { getPoliciesByOwnerId } = usePolicies();
  const [ownerId, setOwnerId] = useState<string>();
  const [stepId, setStepId] = useState<string>();
  const { updateFlow } = useFlow();

  useEffect(() => {
    if (stepId && ownerId) {
      handlePolicies().then(() =>
        client.policies.getPoliciesBySnapshotAsync(snapshot.id).then(({ data, success }) => {
          if (data && success) {
            updateFlow((flow) => {
              flow.policies = data;
            });
          }
        }),
      );
    }
  }, [ownerId]);

  const handlePolicies = async () => {
    if (stepId && ownerId) {
      const policies = getPoliciesByOwnerId(ownerId);
      await Promise.all(
        policies?.map(async (policy) => {
          await client.policies
            .createPolicyForStepAsync(snapshot.id, stepId, policy.type)
            .then(async ({ data, success }) => {
              const newPolicy = data;
              if (newPolicy && success) {
                if (policy.type === PolicyType.BargeIn) {
                  const bargeInPolicy = policy as BargeInPolicy;
                  await client.policies.bargeIns.setEnabledAsync(snapshot.id, newPolicy.id, bargeInPolicy.enabled);
                  await client.policies.bargeIns.setExcludeParents(
                    snapshot.id,
                    newPolicy.id,
                    bargeInPolicy.excludeParents,
                  );
                  await Promise.all(
                    bargeInPolicy.bargeIns.map(async (bargeIn) => {
                      await client.policies.bargeIns
                        .createBargeInAsync(snapshot.id, newPolicy.id, bargeIn.name)
                        .then(async ({ data }) => {
                          const newBargeInId = data;
                          if (newBargeInId && success) {
                            await client.policies.bargeIns.setBargeInNameAsync(
                              snapshot.id,
                              newPolicy.id,
                              newBargeInId,
                              bargeIn.name,
                            );
                            bargeIn?.dtmfKey &&
                              (await client.policies.bargeIns.setBargeInDtmfAsync(
                                snapshot.id,
                                newPolicy.id,
                                newBargeInId,
                                bargeIn?.dtmfKey,
                              ));
                            !!bargeIn?.intents &&
                              (await client.policies.bargeIns.setBargeInIntentsAsync(
                                snapshot.id,
                                newPolicy.id,
                                newBargeInId,
                                bargeIn?.intents,
                              ));

                            bargeIn?.behavior &&
                              (await client.policies.bargeIns.setBargeInBehaviorAsync(
                                snapshot.id,
                                newPolicy.id,
                                newBargeInId,
                                bargeIn?.behavior,
                              ));
                            !!bargeIn?.keywords &&
                              (await client.policies.bargeIns.setBargeInTriggersAsync(
                                snapshot.id,
                                newPolicy.id,
                                newBargeInId,
                                bargeIn?.keywords,
                              ));
                          }
                        });
                    }),
                  );
                }
                if (policy.type === PolicyType.Conditional) {
                  const conditionalPolicy = policy as ConditionalPolicy;
                  await Promise.all(
                    conditionalPolicy.conditions.map(async (condition) => {
                      await client.policies.conditionals.addConditionAsync(
                        snapshot.id,
                        stepId,
                        newPolicy.id,
                        condition,
                      );
                    }),
                  );
                }
                if (policy.type === PolicyType.InputFormatter) {
                  const inputFormatterPolicy = policy as InputFormatterPolicy;
                  await client.policies.inputFormatters.setScript(
                    snapshot.id,
                    newPolicy.id,
                    inputFormatterPolicy.script,
                  );
                }
                if (policy.type === PolicyType.Validate) {
                  const validatePolicy = policy as ValidatePolicy;
                  await Promise.all(
                    validatePolicy.validations.map(async (validation) => {
                      await client.policies.validates
                        .createValidation(snapshot.id, newPolicy.id, validation.name)
                        .then(async ({ data, success }) => {
                          const newValidation = data;
                          if (newValidation && success) {
                            await client.policies.validates.setValidationName(
                              snapshot.id,
                              newPolicy.id,
                              newValidation,
                              validation.name,
                            );
                            !!validation.conditions &&
                              (await Promise.all(
                                validation?.conditions?.map(async (condition) => {
                                  await client.policies.validates.createValidationCondition(
                                    snapshot.id,
                                    newPolicy.id,
                                    newValidation,
                                    condition,
                                  );
                                }),
                              ));

                            !!validation.behaviors &&
                              (await client.policies.validates.setValidationBehaviors(
                                snapshot.id,
                                newPolicy.id,
                                newValidation,
                                validation.behaviors,
                              ));
                          }
                        });
                    }),
                  );
                }
                if (policy.type === PolicyType.Timeout) {
                  const timeoutPolicy = policy as TimeoutPolicy;
                  !!timeoutPolicy.behaviors &&
                    (await client.policies.timeouts.setBehaviors(snapshot.id, newPolicy.id, timeoutPolicy.behaviors));
                  timeoutPolicy.seconds &&
                    (await client.policies.timeouts.setSeconds(snapshot.id, newPolicy.id, timeoutPolicy.seconds));
                }
              }
            });
        }),
      );
    }
  };

  const triggerStepRequests = async (step: Step, parentId: string) => {
    const defaultStep = step as Ask;
    if (step.id) {
      //--Common Steps
      step.name && (await client.steps.updateName(snapshot.id, step.id, step.name));
      step.description && (await client.steps.updateDescription(snapshot.id, step.id, step.description));
      step.tags && (await client.steps.updateTags(snapshot.id, step.id, step.tags));
      defaultStep.saveLocation &&
        defaultStep.saveLocation.type !== PointerType.Empty &&
        (await client.steps.setSaveLocation(snapshot.id, step.id, defaultStep.saveLocation));
      defaultStep.canInterrupt && (await client.steps.setCanInterrupt(snapshot.id, step.id, defaultStep.canInterrupt));
      defaultStep.outputs && (await client.steps.setOutputs(snapshot.id, step.id, defaultStep.outputs));

      //----- Input Steps -----
      //StepType.NumericInput
      if (step.type === StepType.NumericInput) {
        const numericInputStep = step as NumericInput;
        await client.steps.numericInputs.setMinLength(snapshot.id, step.id, numericInputStep.minLength);
        await client.steps.numericInputs.setMaxLength(snapshot.id, step.id, numericInputStep.maxLength);
        await client.steps.numericInputs.setAllowAsterisks(snapshot.id, step.id, numericInputStep.allowAsterisks);
        await client.steps.numericInputs.setPoundToEnd(snapshot.id, step.id, numericInputStep.poundToEnd);
        numericInputStep.alternateIntent &&
          (await client.steps.numericInputs.updateAlternateIntent(snapshot.id, step.id, {
            alternateIntent: numericInputStep.alternateIntent,
            alternateResourceId: numericInputStep.alternateResourceId,
          }));
        await client.steps.numericInputs.setExcludeUniversalModel(
          snapshot.id,
          step.id,
          numericInputStep.excludeUniversalModel,
        );
      }
      //StepType.CLU
      if (step.type === StepType.CLU) {
        const CLUStep = step as CLU;
        CLUStep.intents?.map(
          (intent) => intent && client.steps.clus.createIntent(snapshot.id, step.id, intent.resourceId, intent.name),
        );
      }
      //StepType.DTMFMenu
      if (step.type === StepType.DTMFMenu) {
        const DTMFMenuStep = step as DTMFMenu;
        DTMFMenuStep.menuItems?.map(
          (item) => item && client.steps.dtmfs.createMenuItem(snapshot.id, step.id, item.key, item.languageCode),
        );
      }
      //----- Output Steps -----
      //StepType.Say
      if (step.type === StepType.SendSMS) {
        const sendSMSStep = step as SendSMS;
        sendSMSStep.fromNumber &&
          (await client.steps.sendSMS.setFromNumber(snapshot.id, step.id, sendSMSStep.fromNumber));
        sendSMSStep.toNumber && (await client.steps.sendSMS.setFromNumber(snapshot.id, step.id, sendSMSStep.toNumber));
      }
      //----- Process Steps -----
      //StepType.If
      if (step.type === StepType.If) {
        const ifStep = step as If;
        ifStep.branches.map(
          async (branch) =>
            await client.steps.ifs
              .createBranchAsync(snapshot.id, step.id, branch.name)
              .then(async ({ success, data }) => {
                if (success && data) {
                  branch.description &&
                    (await client.steps.ifs.updateBranchDescriptionAsync(
                      snapshot.id,
                      step.id,
                      data,
                      branch.description,
                    ));
                  branch.conditions.map(
                    async (condition) =>
                      await client.steps.ifs.createConditionAsync(snapshot.id, step.id, data, condition),
                  );
                  branch.steps.map(async (ifBranchStep) => {
                    return await client.steps.ifs
                      .createStepInIfBranch(snapshot.id, step.id, data, ifBranchStep.type, ifBranchStep.name, undefined)
                      .then(({ data }) => {
                        const copiedStep = Object.assign({}, { ...step, id: data }) as Step;
                        triggerStepRequests(copiedStep, ifBranchStep.id);
                      });
                  });
                }
              }),
        );
      }

      //StepType.GoTo
      if (step.type === StepType.GoTo) {
        const goToStep = step as GoTo;
        goToStep.nextId && (await client.steps.goTos.SetNext(snapshot.id, step.id, goToStep.nextId));
      }
      //StepType.CallFunction
      if (step.type === StepType.CallFunction) {
        const callFunctionStep = step as CallFunction;
        callFunctionStep.functionId &&
          (await client.steps.callFunctions.setFunctionId(snapshot.id, step.id, callFunctionStep.functionId));
      }
      //StepType.SetLanguage
      if (step.type === StepType.SetLanguage) {
        const setLanguageStep = step as SetLanguage;
        setLanguageStep.languageCode &&
          (await client.steps.setLanguages.setLanguage(snapshot.id, step.id, setLanguageStep.languageCode));
      }
      //StepType.SetLocation
      if (step.type === StepType.SetLocation) {
        const setLocationStep = step as SetLocation;
        setLocationStep.conditions.map(
          (condition) => condition.id && client.steps.setLocations.createCondition(snapshot.id, step.id, condition),
        );
        setLocationStep.noLocationsFunctionId &&
          setLocationStep.noLocationsFunctionId !== EmptyId &&
          (await client.steps.setLocations.setNoLocationFunction(
            snapshot.id,
            step.id,
            setLocationStep.noLocationsFunctionId,
          ));
        setLocationStep.moreThanOneLocationFunctionId &&
          setLocationStep.moreThanOneLocationFunctionId !== EmptyId &&
          (await client.steps.setLocations.setMoreLocationFunction(
            snapshot.id,
            step.id,
            setLocationStep.moreThanOneLocationFunctionId,
          ));
      }
      //StepType.TriggerAgent
      if (step.type === StepType.TriggerAgent) {
        const triggerAgentStep = step as TriggerAgent;

        if (triggerAgentStep.entryPointOriginalId) {
          await client.steps.triggerAgent.setTriggerChannel(snapshot.id, step.id, {
            botId: triggerAgentStep?.botId,
            entryPointOriginalId: triggerAgentStep?.entryPointOriginalId,
            channelType: triggerAgentStep?.channelType as ChannelType,
          });
          triggerAgentStep.destinationId &&
            triggerAgentStep.destinationId !== EmptyId &&
            (await client.steps.triggerAgent.setTriggerDestination(
              snapshot.id,
              step.id,
              triggerAgentStep.destinationId,
            ));
          triggerAgentStep.contextItems.map((contextItem) =>
            client.steps.triggerAgent.setTriggerContextItem(snapshot.id, step.id, {
              secondAgentContextItemOriginalId: contextItem.secondAgentContextItemOriginalId,
              originalPointer: contextItem.originalPointer,
            }),
          );
        }
      }
      //StepType.WaitForAgent
      if (step.type === StepType.WaitForAgent) {
        const waitForAgentStep = step as WaitForAgent;
        if (waitForAgentStep.botId) {
          await client.steps.waitForAgent.setWaitForAgentBot(snapshot.id, step.id, waitForAgentStep.botId);
          waitForAgentStep.contextItemIds.map((contextItem) =>
            client.steps.waitForAgent.setContextItem(snapshot.id, step.id, {
              currentContextItemId: contextItem.currentContextItemId,
              secondAgentContextItemOriginalId: contextItem.secondAgentContextItemOriginalId,
            }),
          );
        }
      }
      //----- Data Steps -----
      //StepType.CallAPI
      if (step.type === StepType.CallAPI) {
        const callAPIStep = step as CallAPI;
        callAPIStep.apiId &&
          callAPIStep.endpointId &&
          (await client.steps.callAPIs.setAPIAndEndpointAsync(
            snapshot.id,
            step.id,
            callAPIStep.apiId,
            callAPIStep.endpointId,
          ));
      }
      //StepType.Script
      if (step.type === StepType.Script) {
        const scriptStep = step as Script;
        scriptStep.script && (await client.steps.scripts.setScript(snapshot.id, step.id, scriptStep.script));
      }
      //StepType.SetValues
      if (step.type === StepType.SetValues) {
        const setValuesStep = step as SetValues;
        !!setValuesStep.assignments &&
          (await client.steps.setValues.setValueAssignments(snapshot.id, step.id, setValuesStep.assignments));
      }
      //----- End Conversation Steps -----
      //StepType.PhoneTransfer
      if (step.type === StepType.PhoneTransfer) {
        const phoneTransferStep = step as PhoneTransfer;
        phoneTransferStep.number &&
          phoneTransferStep.number.type !== PointerType.Empty &&
          (await client.steps.transfers.setNumber(snapshot.id, step.id, phoneTransferStep.number));
        await client.steps.transfers.setIsSIP(snapshot.id, step.id, phoneTransferStep.isSIP);
        phoneTransferStep.headers.map(
          async (header) =>
            await client.steps.transfers.addHeader(snapshot.id, step.id, header.name).then(({ success, data }) => {
              success && data && client.steps.transfers.setHeaderSource(snapshot.id, step.id, data, header.source);
            }),
        );
      }
    }
    setStepId(step.id);
    setOwnerId(parentId);
  };

  return {
    triggerStepRequests,
  };
};
