import { Condition, PointerType, SetLocation } from 'internal/models';
import React from 'react';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { useBots, useClient, useFlow, useLocationDefinitions, useSnapshot } from 'contexts';
import { IconType } from '@smartaction/styles';
import { ProcessStepsColors } from '../../StepColorGroups';
import { FunctionDropDown } from 'ui/components';
import { arrayRemove } from '@smartaction/common';
import { ConditionsTableAG } from 'ui/views/bots/design/flow/conditions/ConditionsTable-AG';

export const SetLocationStyling: StepStyling = {
  typeName: 'Set Location',
  icon: IconType.Location,
  ...ProcessStepsColors,
};

export const SetLocationStep: React.FC<TypedStepProps<SetLocation>> = ({ step, manipulateStep }) => {
  const { locationDefinitions } = useLocationDefinitions();
  const client = useClient('flow').steps.setLocations;
  const snapshot = useSnapshot();
  const { updateFlow } = useFlow();
  const { isReadOnlyBot } = useBots();

  if (!locationDefinitions) {
    return <React.Fragment />;
  }

  const addCondition = async (condition: Condition) => {
    const result = await client.createCondition(snapshot.snapshot.id, step.id, condition);
    if (result) {
      condition.id = result.data!;
      updateFlow(() => {
        step.conditions = [...step.conditions, condition];
      });
    }
  };

  const replaceCondition = async (condition: Condition) => {
    const result = await client.replaceCondition(snapshot.snapshot.id, step.id, condition.id, condition);
    if (result) {
      updateFlow(() => {
        const index = step.conditions.findIndex((c) => c.id === condition.id);
        step.conditions[index] = condition;
        step.conditions = [...step.conditions];
      });
    }
  };

  const deleteCondition = async (conditionId: string) => {
    const result = await client.deleteCondition(snapshot.snapshot.id, step.id, conditionId);
    if (result) {
      updateFlow(() => {
        const index = step.conditions.findIndex((c) => c.id === conditionId);
        if (index > -1) {
          step.conditions = arrayRemove(step.conditions, index);
        }
      });
    }
  };

  const setNoLocationFunction = (functionId: string) =>
    client.setNoLocationFunction(snapshot.snapshot.id, step.id, functionId);
  const setMoreLocationFunction = (functionId: string) =>
    client.setMoreLocationFunction(snapshot.snapshot.id, step.id, functionId);

  return (
    <StepView step={step} styling={SetLocationStyling} isCollapsible={true} manipulateStep={manipulateStep}>
      <div style={{ overflowX: 'auto' }}>
        <ConditionsTableAG
          conditions={step.conditions}
          targetAllowedTypes={[PointerType.Location]}
          sourceAllowedTypes={[PointerType.Context, PointerType.Config, PointerType.DirectAssignment]}
          onAddCondition={addCondition}
          onUpdateCondition={replaceCondition}
          onDeleteCondition={deleteCondition}
        />
      </div>

      <h6>Function for no valid locations</h6>
      <FunctionDropDown
        disabled={isReadOnlyBot}
        functionId={step.noLocationsFunctionId!}
        onFunctionSelected={setNoLocationFunction}
      />
      <h6 className={'mt-2'}>Function for more than one valid location</h6>
      <FunctionDropDown
        disabled={isReadOnlyBot}
        functionId={step.moreThanOneLocationFunctionId!}
        onFunctionSelected={setMoreLocationFunction}
      />
    </StepView>
  );
};
