import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { IAddressInputClient } from './IAddressInputClient';
import { Output } from '../../../../../models';

export class AddressInputClient implements IAddressInputClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private baseUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }

  async setSuggestionsType(snapshotId: string, stepId: string, suggestions: any): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/suggestions-type`;
    const response = await this.methods.put(url, suggestions);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Address Input Suggestions Type`,
      response,
    );
  }

  async setPrefilteredAddresses(snapshotId: string, stepId: string, addresses: any): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/prefiltered-addresses`;
    const response = await this.methods.put(url, addresses);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Address Input Prefiltered Addresses`,
      response,
    );
  }

  async setConfirmationOutputs(snapshotId: string, stepId: string, outputs: Output[]): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/confirmation-outputs`;
    const response = await this.methods.put(url, outputs);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Address Input Confirmation Outputs`,
      response,
    );
  }
}
