import {
  ColDef,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GridReadyEvent,
  PaginationChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useBots, useClient, useLocations, useSnapshot } from 'contexts';
import { Environment, GeneralItem, Metadata, Outcome, Summary, SummaryType } from 'internal/models';
import React, { useEffect, useRef, useState } from 'react';
import { formatDateToLocal } from 'ui/utils';
import { Tooltip } from 'react-tooltip';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ConversationButtonsRenderer } from './SummaryButtonsRenderer';
import { ConversationIntentsRenderer } from './ConversationIntentsRenderer';
import { Button } from '@smartaction/visuals';
import { VisualCategory } from '@smartaction/styles';

type RouteParams = {
  environment: Environment;
  conversation?: string;
};

export const SummaryView: React.FC = () => {
  const client = useClient('conversation');
  const snapshot = useSnapshot();
  const navigate = useNavigate();
  const location = useLocation();
  const { locations } = useLocations();
  const { environment, conversation } = useParams<RouteParams>();
  const [summaries, setSummaries] = useState<Summary[] | null>(null);
  const [skip, setSkip] = useState(0);
  const [conversationId, setConversationId] = useState<string>('');
  const [snapshotId, setSnapshotId] = useState<string>('');

  const bots = useBots();
  const count = 50;

  const onExport = async () => {
    await client.exportConversationsAsCSV(
      bots.currentBot!.code,
      environment || Environment.DEV,
      conversationId,
      snapshotId,
      skip,
      count,
    );
  };

  const onPageChange = (event: PaginationChangedEvent) => {
    setSkip(event.api.paginationGetCurrentPage() * count);
  };

  const onFilterChange = (event: FilterChangedEvent) => {
    const convId = event.api.getFilterInstance('conversationId')?.getModel()?.filter;
    const snapId = event.api.getFilterInstance('snapshotId')?.getModel()?.filter;
    setConversationId(convId ?? '');
    setSnapshotId(snapId ?? '');

    const pathParts = location.pathname.split('/');
    const index = pathParts.indexOf('conversations');

    if (convId) {
      const path = `${pathParts.slice(0, index + 2).join('/')}/${convId}`;
      navigate(path);
    }
  };

  const getFakeSummariesToPopulateTable = (length: number) =>
    length < 1 ? [] : new Array(length).fill(defaultSummary);

  const getSummaries = (wipe: boolean) => {
    // The null coalesce doesn't matter because we're going to bail and not use any of this.
    client
      .getConversations(bots.currentBot!.code, environment || Environment.DEV, conversationId, snapshotId, skip, count)
      .then((item) => {
        if (item.success) {
          const rows = item.data!.result;

          if (!wipe && summaries) {
            setSummaries(
              summaries
                .slice(0, skip)
                .concat(rows)
                .concat(summaries.slice(skip + rows.length)),
            );
          } else {
            let fakeSummaries = getFakeSummariesToPopulateTable(item.data!.total - skip - count);
            setSummaries(rows.concat(fakeSummaries));
          }
        } else {
          setSummaries([]);
        }
      });
  };

  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    getSummaries(true);
  }, [snapshot, environment]);

  useEffect(() => {
    if (summaries && summaries[skip + 1]?.conversationId !== '') {
      return;
    }

    getSummaries(false);
  }, [skip, conversationId]);

  if (!environment) {
    return <React.Fragment />;
  }

  const getFilterParams = (filterItem: string) => ({
    filterOptions: ['contains'],
    maxNumConditions: 1,
    textFormatter: (from: string) => from.replaceAll('-', ''),
    value: filterItem,
  });
  const conversationFilterParams = getFilterParams(conversationId);
  const snapshotFilterParams = getFilterParams(snapshotId);

  const GetLocationName = (locationId: string) => {
    return locations?.find((l) => l.id === locationId)?.displayName || locationId;
  };

  const summaryColDefs: ColDef<Summary | any>[] = [
    { headerName: '', field: '', width: 150, resizable: false, cellRenderer: ConversationButtonsRenderer },
    {
      headerName: 'Origin',
      field: 'type',
      filter: true,
      width: 100,
      resizable: true,
      cellStyle: { padding: 0 },
      filterParams: {
        filterOptions: ['contains'],
        maxNumConditions: 1,
      },
    },
    {
      headerName: 'Timestamp',
      field: 'timestamp',
      width: 150,
      resizable: true,
      cellStyle: { padding: 0 },
      valueFormatter: (params) => formatDateToLocal(new Date(params.data?.timestamp ?? '')),
    },
    {
      headerName: 'Id',
      field: 'conversationId',
      editable: false,
      width: 300,
      resizable: true,
      filter: true,
      filterParams: conversationFilterParams,
    },
    {
      headerName: 'SnapshotId',
      field: 'snapshotId',
      editable: false,
      width: 210,
      resizable: true,
      filter: true,
      filterParams: snapshotFilterParams,
    },
    { headerName: 'Source', field: 'source', editable: false, width: 120, resizable: true },
    { headerName: 'Phone Number', field: 'metadata.phoneNumber', editable: false, width: 130, resizable: true },
    { headerName: 'Language', field: 'language', editable: false, width: 100, resizable: true },
    {
      headerName: 'Length',
      field: 'duration',
      editable: false,
      width: 80,
      resizable: true,
      valueFormatter: (params) => (params.data?.duration ? Number(params.data?.duration).toFixed(2) : ''),
    },
    { headerName: 'Outcome', field: 'outcome', editable: false, width: 140, resizable: true, filter: true },
    {
      headerName: 'Location',
      field: 'selectedLocationId',
      editable: false,
      width: 140,
      hide: !locations.length,
      resizable: true,
      valueGetter: (params) => {
        return GetLocationName(params.data?.selectedLocationId);
      },
    },
    {
      headerName: 'Intents',
      field: 'intents',
      editable: false,
      width: 350,
      resizable: true,
      cellRenderer: ConversationIntentsRenderer,
    },
    {
      headerName: 'Tags',
      field: 'tags',
      editable: false,
      width: 350,
      resizable: true,
      valueGetter: (params) => params.data?.tags.join(', '),
    },
    {
      headerName: 'Expected Aigo Outcome',
      field: 'outcome',
      editable: false,
      width: 350,
      resizable: true,
      valueGetter: (params) => {
        return convertNOVAOutcomeTOAigoOutcome(params.data!);
      },
      filter: true,
      filterParams: {
        defaultToNothingSelected: true,
      },
    },
  ];

  const onGridReady = (event: GridReadyEvent) => {
    if (!conversation) {
      event.api.destroyFilter('conversationId');
      return;
    }
    event.api.setFilterModel({
      conversationId: {
        type: 'contains',
        filter: conversation,
        isFilterActive: true,
      },
    });
    event.api.onFilterChanged();
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.setFilterModel({
      outcome: {
        operator: 'AND',
        isFilterActive: true,
        conditions: [
          {
            type: 'notContains',
            filter: [NovaOutcome.Failed],
          },
          {
            type: 'notContains',
            filter: [NovaOutcome.NoAnswer],
          },
        ],
      },
    }); // Omit declined calls on render
    event.api.onFilterChanged();
  };

  return (
    <div className="ag-theme-balham conversations" style={{ height: '70vh', paddingBottom: '50px' }}>
      <AgGridReact<Summary>
        enableCellTextSelection={true} // this and ensureDomOrder from https://www.ag-grid.com/react-data-grid/selection-overview/#cell-text-selection
        ensureDomOrder={true}
        disableStaticMarkup={true} // https://www.ag-grid.com/react-fine-tuning/#react-cell-rendering - disabled this because it causes the very first cell to frequently render twice
        columnDefs={summaryColDefs}
        defaultColDef={{ cellStyle: { fontFamily: 'Quicksand', fontSize: '14px' } }}
        rowData={summaries}
        rowHeight={38}
        suppressRowTransform={true}
        stopEditingWhenCellsLoseFocus={true}
        pagination={true}
        paginationPageSize={count}
        ref={gridRef}
        onPaginationChanged={onPageChange}
        onFilterChanged={onFilterChange}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
      ></AgGridReact>
      <div className="text-end pt-1">
        <Button className="export-button" action={onExport} type={VisualCategory.Primary}>
          Export .CSV
        </Button>
      </div>
      <Tooltip
        id="transcript-tooltip"
        anchorSelect=".transcript.convo-button"
        positionStrategy="fixed"
        place="bottom"
        content="Transcript"
      />
      <Tooltip
        id="data-tooltip"
        anchorSelect=".data.convo-button"
        positionStrategy="fixed"
        place="bottom"
        content="Data"
      />
      <Tooltip
        id="execution-tooltip"
        anchorSelect=".execution.convo-button"
        positionStrategy="fixed"
        place="bottom"
        content="Execution"
      />
      <Tooltip
        id="recording-tooltip"
        anchorSelect=".download.convo-button"
        positionStrategy="fixed"
        place="bottom"
        content="Recording"
      />
    </div>
  );
};

const defaultSummary: Summary = {
  conversationId: '',
  type: SummaryType.Chat,
  jobId: '',
  metadata: new Metadata('', '', ''),
  initialLanguage: '',
  language: '',
  source: '',
  timestamp: '',
  duration: '',
  botCode: '',
  snapshotId: '',
  selectedLocationId: '',
  environment: Environment.DEV,
  outcome: Outcome.BotHangup,
  intents: [],
  tags: [],
  transcript: [],
  configs: new Array<GeneralItem>(),
  contextItems: new Array<GeneralItem>(),
  hasTag: (prefix: string) => false,
};

const convertNOVAOutcomeTOAigoOutcome = (summary: Summary) => {
  switch (summary.outcome.toString()) {
    case NovaOutcome.ImmediateHangup:
      return B2Outcome.ImmediateHangup;
    case NovaOutcome.CallerHangup:
    case NovaOutcome.BotHangup:
      return summary.hasTag(Tag.Finish) ? B2Outcome.Finish : B2Outcome.Hangup;
    case NovaOutcome.ImmediateTransfer:
      return summary.hasTag(Tag.BizRuleXfer) ? B2Outcome.BizRuleTransfer : B2Outcome.ImmediateTransfer;
    case NovaOutcome.Transfer:
      return summary.hasTag(Tag.Finish)
        ? B2Outcome.FinishTransfer
        : summary.hasTag(Tag.BizRuleXfer)
        ? B2Outcome.BizRuleTransfer
        : B2Outcome.CallerTransfer;
    case NovaOutcome.Finish:
      return B2Outcome.Finish;
    default:
      return 'UNKNOWN';
  }
};

const enum NovaOutcome {
  Unknown = 'Unknown',
  BotHangup = 'BotHangup',
  ImmediateHangup = 'ImmediateHangup',
  CallerHangup = 'CallerHangup',
  BotTransfer = 'BotTransfer',
  ImmediateTransfer = 'ImmediateTransfer',
  CallerTransfer = 'CallerTransfer',
  Finish = 'Finish',
  Transfer = 'Transfer',
  AnsweringMachine = 'AnsweringMachine',
  NoAnswer = 'NoAnswer',
  Failed = 'Failed',
}

const enum B2Outcome {
  Caller = 'User',
  Bot = 'Aigo',
  Xfer = 'Xfer',
  ImmediateHangup = 'hangup1',
  CallerHangup = 'hangup2',
  Hangup = 'hangup2',
  ImmediateTransfer = 'callerXfer1',
  CallerTransfer = 'callerXfer2',
  BizRuleTransfer = 'bizruleXfer',
  FinishTransfer = 'finishXfer',
  Finish = 'finish',
}

const enum Tag {
  Breadcrumb = 'b2',
  Endstate = 'endstate',
  Finish = 'finish',
  BizRuleXfer = 'bizrulexfer',
  XferReason = 'xfer',
}
