import { Step } from '../Step';
import { StepType } from '../Type';

export class Break extends Step {
  constructor(id: string | undefined, name: string, description: string, tags: string[]) {
    super(id, name, description, StepType.Break, tags);
  }

  isDirty(original: Step): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: Step): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): Step {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any): Break {
    return new Break(json.id, json.name, json.description, json.tags);
  }
}
