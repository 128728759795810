import { useConfigs, useContextItems, useLocationDefinitions, useObjects } from 'contexts';
import { useIterationPointerLogic } from './useIterationPointerLogic';

export const useReferences = () => {
  const configs = useConfigs();
  const { locationDefinitions, commonLocationFieldIds } = useLocationDefinitions();
  const contexts = useContextItems();
  const regexp = /\[(.*?)\]/g; // matches [xxxxxx]
  const objects = useObjects();
  const { iterationItem, isIterationItemObjectType } = useIterationPointerLogic();

  const getNestedObjectIdByTypeId = (typeId?: string, objectName?: string) => {
    return objects.objects.filter((o) => o.id === typeId)[0]?.fields.filter((prop) => prop.name === objectName)[0]?.id;
  };

  const getNestedObjectNameByTypeId = (typeId?: string, objectId?: string) => {
    return objects.objects.filter((o) => o.id === typeId)[0]?.fields.filter((prop) => prop.id === objectId)[0]?.name;
  };

  const toFlow = (input: string) => {
    Array.from(input.matchAll(regexp)).forEach((match) => {
      let split = match[1].split('.'),
        type = split[0],
        name = split[1],
        subName = split[2],
        id = undefined;

      const contextId = contexts.contextItems.find((c) => c.name === name)?.id;
      const contextTypeId = contexts.contextItems.find((c) => c.name === name)?.typeId;
      const iterationItemId = iterationItem?.find((item) => item.name === name)?.id;

      if (type === 'Context') {
        id = !!subName ? `${contextId}.${getNestedObjectIdByTypeId(contextTypeId, subName)}` : contextId;
      } else if (type === 'Config') {
        for (let [, v] of configs.all) {
          if (v.name === name) {
            id = v.id;
            break;
          }
        }
      } else if (type === 'Location') {
        id = locationDefinitions[0].customFields.find((loc) => loc.name === name)?.id || commonLocationFieldIds[name];
      } else if (type === 'CurrentItem') {
        id = iterationItemId;
        name = '';
      }

      const typeInput = input.replace(match[0], `[${type}]`);
      const defaultInput = input.replace(match[0], `[${type}.${id || name}]`);

      // Can be nullable, validation will be done in flow service or add id validation here
      input = defaultInput;
      if (type === 'CurrentItem') {
        input = isIterationItemObjectType ? defaultInput : typeInput;
      }
    });

    return input;
  };

  const fromFlow = (output: string) => {
    Array.from(output.matchAll(regexp)).forEach((match) => {
      let split = match[1].split('.'),
        type = split[0],
        id = split[1],
        subId = split[2],
        name = undefined;

      const contextName = contexts.map.get(id)?.name;
      const contextTypeId = contexts.map.get(id)?.typeId;

      if (type === 'Context') {
        name = subId ? `${contextName}.${getNestedObjectNameByTypeId(contextTypeId, subId)}` : contextName;
      } else if (type === 'Config') {
        name = configs.all.get(id)?.name;
      } else if (type === 'Location') {
        name =
          locationDefinitions[0].customFields.find((loc) => loc.id === id)?.name ||
          Object.keys(commonLocationFieldIds).find((key) => commonLocationFieldIds[key] === id);
      } else if (type === 'CurrentItem') {
        name = iterationItem?.find((item) => item.id === id)?.name;
      }

      const typeOutput = output.replace(match[0], `[${type}]`);
      const defaultOutput = output.replace(match[0], `[${type}.${name || id}]`);

      output = defaultOutput;

      if (type === 'CurrentItem') {
        output = isIterationItemObjectType ? defaultOutput : typeOutput;
      }
    });

    return output;
  };

  return { toFlow, fromFlow };
};
