import { Step } from 'internal/models';
import { DragApproach, Draggable, DroppableData } from '@smartaction/visuals';
import { StepStyling } from '../stepManagement';
import React from 'react';
import { Icon } from '@smartaction/styles';

type NewStepProps = {
  step: Step;
  toolbar: DroppableData;
  styling: StepStyling;
};

const NewStep: React.FC<NewStepProps> = ({ step, toolbar, styling }) => {
  const render = (
    <div key={step.type} className="newStep" style={{ borderColor: styling.color }}>
      <span className="icon">
        <Icon type={styling.icon} color={styling.color} /> {styling.typeName}
      </span>
    </div>
  );

  return (
    <Draggable<Step>
      key={step.type}
      item={step}
      type={step.type}
      identifier={step.type}
      index={88}
      source={toolbar}
      dragChildren={render}
      orientation="vertical"
      dragApproach={DragApproach.MultiSelect}
      isSelected={false}
      clickSelection={() => {}}
    >
      {render}
    </Draggable>
  );
};

export default NewStep;