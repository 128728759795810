export enum PolicyType {
  Unknown = 'Unknown',
  Conditional = 'Conditional',
  BargeIn = 'BargeIn',
  Retry = 'Retry',
  Timeout = 'Timeout',
  Expiration = 'Expiration',
  Validate = 'Validate',
  DTMFOnly = 'DTMFOnly',
  InputFormatter = 'InputFormatter',
}
