import { Behavior, BehaviorType, NoActionBehavior } from 'internal/models';
import React from 'react';
import { BehaviorControl } from '../BehaviorControl';
import { Button, DragApproach, DragDropProvider, Stack } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';

type FollowUpListProps = {
    followUpBehaviors: Behavior[],
    allowedTypes: BehaviorType[],
    update: (behaviors: Behavior[]) => void
}

type FollowUpBehaviorType = {
    id: string,
    idx: number,
    behavior: Behavior
}

export const FollowUpListView: React.FC<FollowUpListProps> = ({followUpBehaviors, allowedTypes, update}) => {
    const items = followUpBehaviors.map((b, i) => { return { id: i.toString(), idx: i, behavior: b }});
    const addNew = () => {
        update([...followUpBehaviors, new NoActionBehavior()]);
    };

    const listUpdate = (items: Array<FollowUpBehaviorType>) => {
        update(items.map(i => i.behavior));
    }

    const itemUpdate = (item: FollowUpBehaviorType) => {
        const updatedList = [...followUpBehaviors];
        updatedList[item.idx] = item.behavior;
        update(updatedList);
    }

    const render = (b: FollowUpBehaviorType) => {
        return {
            type: 'behavior',
            children: (
                <BehaviorControl key={b.id} behavior={b.behavior} allowedTypes={allowedTypes} isFollowUp={true}
                    update={(change) => itemUpdate({ id: b.id, idx: b.idx, behavior: change })} />
            ),
            dragChildren: <DragView {...b} />
        };
    };
    const addButton = followUpBehaviors.length < 4 
        ? <Button type={VisualCategory.Primary} action={addNew} className='mt-5'><Icon type={IconType.Add} /> Add Behavior</Button>
        : <React.Fragment/>;

    return (
        <div className='followup-list'>
            <label>Follow-Up Behaviors</label>
            <DragDropProvider>
                <Stack<FollowUpBehaviorType>
                    identifier='follow-up-stack'
                    render={render}
                    allowedTypes={['behavior']}
                    dragApproach={DragApproach.DragHandle}
                    items={items}
                    hasTrash={true}
                    itemsUpdated={listUpdate}
                />
            </DragDropProvider>
            {addButton}
        </div>
    );
}

const DragView: React.FC<FollowUpBehaviorType> = ({behavior}) => {
    return <div><label>{behavior.type}</label></div>
};