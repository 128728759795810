import { Condition } from "../../shared";
import { Step } from "../Step";
import { StepBuilder } from "../StepBuilder";
import { StepType } from "../Type";

export class If extends Step {
    branches: Array<Branch>;

    constructor(id: string | undefined, name: string, description: string, tags: string[], branches: Array<Branch>) {
        super(id, name, description, StepType.If, tags);
        this.branches = branches;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): If { 
        
        const branches = json.branches && Array.isArray(json.branches)
        ? json.branches.map((b: any) => Branch.fromJson(b))
        : [];
        return new If(json.id, json.name, json.description, json.tags, branches);
    }

}

export class Branch { 
    id: string;
    name: string;
    description: string;
    conditions: Array<Condition>; 
    steps: Array<Step>;

    constructor(id:string, name:string, description:string, steps: Array<Step>, conditions: Array<Condition>) {
        this.id = id; 
        this.name = name;
        this.description = description;
        this.steps = steps;
        this.conditions = conditions;
    }

    static fromJson(json:any) {
        const steps = json.steps && Array.isArray(json.steps)
            ? json.steps.map((s: any) => StepBuilder(s))
            : [];
        const conditions = json.conditions && Array.isArray(json.conditions)
            ? json.conditions.map((c: any) => Condition.fromJson(c))
            : [];
        return new Branch(json.id, json.name, json.description, steps, conditions);
    }
}