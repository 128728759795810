import React, { useState } from 'react';
import { useBots, useClient, useFlow, useSnapshot } from 'contexts';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, Field, Checkbox, Switch } from '@smartaction/visuals';
import { AgGridReact } from 'ag-grid-react';
import { DeleteRenderer, PointerEditor, PointerRenderer } from 'ui/controls/grid/editors';
import { EmptyPointer, PhoneTransfer, PointerType, PhoneTransferHeader, Pointer } from 'internal/models';
import { CellValueChangedEvent, GridApi } from 'ag-grid-community';
import { PointerControl } from 'ui/components';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { EndConversationStepsColors } from '../../StepColorGroups';
import { Tooltip } from 'react-tooltip';

export const TransferStyling: StepStyling = {
    typeName: "Phone Transfer",
    icon: IconType.Transfer,
    ...EndConversationStepsColors
}

export const TransferStepView: React.FC<TypedStepProps<PhoneTransfer>> = ({ step, manipulateStep }) => {
    return (
        <StepView step={step} styling={TransferStyling} isCollapsible={true} manipulateStep={manipulateStep}>
            <TransferDetails step={step} manipulateStep={manipulateStep} />
        </StepView>
    );
}

const pointerTypes = [PointerType.Context, PointerType.Config, PointerType.DirectAssignment, PointerType.DirectAssignmentList];

export const TransferDetails: React.FC<TypedStepProps<PhoneTransfer>> = ({ step }) => {
    const client = useClient('flow').steps.transfers;
    const flowClient = useClient('flow');
    const { isReadOnlyBot} = useBots();
    const flow = useFlow(); 
    const snapshot = useSnapshot();
    const [gridApi, setGridApi] = useState<GridApi>();

    const refresh = () => {
        step.headers = [...step.headers];
        gridApi?.setRowData(step.headers);
    }

    const addHeader = async () => {
        const name = `New Header ${step.headers.length + 1}`;
        const response = await client.addHeader(snapshot.snapshot.id, step.id, name);
        if (response.success) {
            flow.updateFlow(() => {
                step.headers.push(new PhoneTransferHeader(response.data!, name, new EmptyPointer()));
                flow.updateFlow(() => {
                    refresh();
                });
            });
        }
        
    };

    const saveTransferTo = async (pointer: Pointer) => {
        const response = await client.setNumber(snapshot.snapshot.id, step.id, pointer);
        if (response.success) {
            flow.updateFlow(() => {
                step.number = pointer;
            });
        }
    }

    const saveSipChange = async (checked: boolean) => {
        const response = await client.setIsSIP(snapshot.snapshot.id, step.id, checked);
        if (response.success) {
            flow.updateFlow(() => {
                step.isSIP = checked;
            });
        }
    }

    const saveGridChange = async (evt:CellValueChangedEvent) => {
        const header = evt.data as PhoneTransferHeader;
        switch(evt.colDef.field) {
            case "name":
                client.setHeaderName(snapshot.snapshot.id, step.id, header.id, evt.newValue)
                    .then(r => {
                        if (r.success) {
                            flow.updateFlow(() => {
                                header.name = evt.newValue;
                                refresh();
                            });
                        }
                    });
                break;
            // source is done via a ValueSetter, since the field to be updated varies on the condition type (target... target never changes)
            case "source":
                client.setHeaderSource(snapshot.snapshot.id, step.id, header.id, evt.newValue)
                    .then(r => {
                        if (r.success) {
                            flow.updateFlow(() => {
                                header.source = evt.newValue;
                                refresh();
                            })
                        }
                    })
                break;
            default: break;
        }
    }

    const deleteFunc = (sipHeader: PhoneTransferHeader) => {
        client.removeHeader(snapshot.snapshot.id, step.id, sipHeader.id).then((res) => {
            if (res.success) {
                step.headers = step.headers.filter(h => h.id !== sipHeader.id);
                flow.updateFlow(() => {
                    refresh();
                });
            }
        })
    }

    const colDefs = [
        { headerName: "Name", field: "name", width: 100, editable: true },
        { headerName: "Source", field: "source", cellRenderer: PointerRenderer, cellEditor: PointerEditor, cellEditorPopup: true, 
          width: 180, editable: true, allowedTypes: pointerTypes,
          wrapText: true,
          autoHeight: true
        },
        { headerName: "Delete", cellRenderer: DeleteRenderer, cellRendererParams: { canEdit: true, deleteFunc }, width: 100, resizable: true }
    ];

    const headerGrid = step.isSIP 
        ? <></>
        : (
            <>
                <div className='ag-theme-balham'>
                    <AgGridReact
                            disableStaticMarkup={true} // https://www.ag-grid.com/react-fine-tuning/#react-cell-rendering - disabled this because it causes the very first cell to frequently render twice
                            columnDefs={colDefs}
                            defaultColDef={{ cellStyle: { 'fontFamily': 'Quicksand', 'fontSize': '14px'}, singleClickEdit: false }}
                            onCellValueChanged={saveGridChange}
                            rowHeight={36}
                            suppressRowTransform={true}
                            rowData={step.headers}
                            domLayout='autoHeight'
                            onGridReady={(evt) => {
                                setGridApi(evt.api);
                            }}>
                        </AgGridReact>
                </div>
                <Button isDisabled={isReadOnlyBot} action={() => addHeader()} type={VisualCategory.Primary}>Create Header</Button>
            </>
        );

    return (
        <React.Fragment>
            <Field inputId='transferToId' label="Transfer To" name="transferTo" groupClass="col">
                <PointerControl update={saveTransferTo} types={pointerTypes} pointer={step.number} />
            </Field>
            <div className='row my-2'>
                <div className='col-sm-auto'>
                    <Switch label='As Refer' value={step.isSIP} setValue={saveSipChange} isDisabled={isReadOnlyBot} disabledReason={isReadOnlyBot ? 'The snapshot is in read-only mode' : undefined} />
                </div>
                <div className='col'>
                    <span data-tooltip-id="tooltip-transfer-refer-alert" data-tooltip-content="REFER and PSTN transfers cannot use SIP headers.">
                        <Icon type={IconType.Help} size='sm' />
                    </span>
                    <Tooltip id="tooltip-transfer-refer-alert" />
                </div>
            </div>
            {headerGrid}
        </React.Fragment>
    );
}