import { Methods, DataResponse, ServerResponse, ValidateResponseActionEnum } from 'internal/clients/index';
import { PostError } from 'ui/events';
import { ICustomFieldsClient, ILocationDefinitionClient } from './ILocationDefinitionClient';
import { LocationDefinition, CustomFieldType } from 'internal/models';

export class LocationDefinitionClient implements ILocationDefinitionClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
    this.customFields = new CustomFieldsClient(this.serviceUrl, this.methods);
  }

  async list(): Promise<DataResponse<LocationDefinition[]>> {
    const response = await this.methods.get(this.getUrl());

    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} list of location items`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    if (!data) {
      const error = 'Unexpected response when retrieving Location Items';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data: data.map((d: any) => LocationDefinition.fromJson(d)),
    };
  }

  async create(name: string): Promise<DataResponse<string>> {
    let response = await this.methods.post(`${this.getUrl()}/${name}`);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Location Definition`,
      response,
    );
  }

  async updateName(locationDefinitionId: string, name: string): Promise<ServerResponse> {
    let url = `${this.getUrl(locationDefinitionId)}/name/${name}`;
    let response = await this.methods.put(url, name);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Location Definition Name`,
      response,
    );
  }

  async delete(locationDefinitionId: string): Promise<ServerResponse> {
    const response = await this.methods.delete(this.getUrl(locationDefinitionId));
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Deleted} Location Definition Field`,
      response,
    );
  }

  readonly customFields: ICustomFieldsClient;

  private getUrl(locationDefinitionId?: string) {
    return locationDefinitionId
      ? `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/definitions/${locationDefinitionId}`
      : `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/definitions`;
  }
}

class CustomFieldsClient implements ICustomFieldsClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async create(locationDefinitionId: string, name: string): Promise<DataResponse<string>> {
    let response = await this.methods.post(`${this.getUrl(locationDefinitionId)}/${name}`);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Location Definition Custom Field`,
      response,
    );
  }

  async updateName(locationDefinitionId: string, customFieldId: string, name: string): Promise<ServerResponse> {
    let response = await this.methods.put(`${this.getUrl(locationDefinitionId, customFieldId)}/name/${name}`, name);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Location Definition Custom Field name`,
      response,
    );
  }

  async updateDescription(
    locationDefinitionId: string,
    customFieldId: string,
    description: string,
  ): Promise<ServerResponse> {
    let response = await this.methods.put(
      `${this.getUrl(locationDefinitionId, customFieldId)}/description/${description}`,
      description,
    );
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Location Definition Custom Field description`,
      response,
    );
  }

  async updateType(
    locationDefinitionId: string,
    customFieldId: string,
    fieldType: CustomFieldType,
  ): Promise<ServerResponse> {
    let response = await this.methods.put(
      `${this.getUrl(locationDefinitionId, customFieldId)}/type/${fieldType}`,
      fieldType,
    );
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Location Definition Custom Field type`,
      response,
    );
  }

  async delete(locationDefinitionId: string, customFieldId: string): Promise<ServerResponse> {
    const response = await this.methods.delete(this.getUrl(locationDefinitionId, customFieldId));
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Deleted} Location Definition Custom Field`,
      response,
    );
  }

  private getUrl(locationDefinitionId: string, customFieldId?: string) {
    return customFieldId
      ? `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/definitions/${locationDefinitionId}/fields/${customFieldId}`
      : `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/definitions/${locationDefinitionId}/fields`;
  }
}
