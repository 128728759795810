import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Location, TimeRange } from 'internal/models';
import { DatePicker } from '@smartaction/visuals';
import { ICellEditorParams } from 'ag-grid-community';
import ReactSelect from 'react-select';
import { TimezoneOptions } from '../common';

export const TimeZoneFieldEditor = forwardRef(
  (props: ICellEditorParams<Location>, ref: React.ForwardedRef<unknown>) => {
    const [selectedTimeZone, setSelectedTimeZone] = useState(props.value);

    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return selectedTimeZone;
        },
        isCancelAfterEnd: () => {
          return false;
        },
      };
    });
    if (!props.value) {
      return <React.Fragment />;
    }

    return (
      <div className="location-timezone-field-editor">
        <ReactSelect
          styles={{
            container: (base) => ({
              ...base,
              flex: 1,
            }),
          }}
          onChange={(val) => setSelectedTimeZone(val?.value)}
          options={TimezoneOptions}
          value={{ value: selectedTimeZone, label: selectedTimeZone }}
        />
      </div>
    );
  },
);
