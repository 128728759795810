import React from 'react';
import { Table, TableRow } from 'ui/components';
import { Button } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';

interface ParameterTableProps {
  rows: TableRow[];
  onDeleteRow: (id: string, rowIndex: number) => void;
  onAddNewRow: () => void;
  title: string;
  isDisabled: boolean;
}

export const ParameterTable: React.FC<ParameterTableProps> = 
  ({ rows, onDeleteRow, onAddNewRow, title, isDisabled }) => {
    return (
      <Table
        id={'table'}
        headersGroup={['Name', 'Description', 'Type', 'Is List']}
        columnsSize={[190, 190]}
        rows={rows}
        onDeleteRow={onDeleteRow}
        style={{ maxWidth: 650 }}
        title={title}
        footer={
          <Button type={VisualCategory.Primary} action={onAddNewRow} isDisabled={isDisabled}>
            <Icon type={IconType.Add} />
          </Button>
        }
      ></Table>
    );
};
