import React from 'react';
import { Icon, IconType } from '@smartaction/styles';
import { Marker } from '@smartaction/scripteditor';

type ValidatorScriptTooltipProps = {
  showValidationMessage: boolean;
  errors?: Marker[];
};

export const ValidatorScriptTooltip: React.FC<ValidatorScriptTooltipProps> = ({
  showValidationMessage,
  errors = [],
}) => {
  return (
    <>
      {showValidationMessage && (
        <div className={'validation-tooltips my-3'}>
          {!!errors.length ? (
            <div className="invalid-tooltip">
              <Icon type={IconType.Close} /> Invalid script
            </div>
          ) : (
            <div className="valid-tooltip">
              <Icon type={IconType.CheckboxChecked} /> Valid script
            </div>
          )}
        </div>
      )}
    </>
  );
};
