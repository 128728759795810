export class Access {
    fullAccess: boolean = false;
    systemEntitlements: string[];
    tenantEntitlements: Map<string, string[]>;

    constructor(fullAccess: boolean, systemEntitlements?: string[], tenantEntitlements?: Map<string, string[]>) {
        if (fullAccess) {
            this.fullAccess = true;
            this.systemEntitlements = [];
            this.tenantEntitlements = new Map();
        } else {
            this.systemEntitlements = systemEntitlements!;
            this.tenantEntitlements = tenantEntitlements!;
        }
    }

    // Converts JSON data into an Access object.
    static fromJson(json: any) {
        // Convert json.entitlementsByTenant from a dictionary to a Map.
        const tenantEntitlementsMap = new Map<string, string[]>(
            Object.entries(json.entitlementsByTenant || {})
        );
        
        return new Access(true, json.systemEntitlements, tenantEntitlementsMap);
    }
}