import { Output, Step, StepType, IHasOutputs } from "..";

export class Say extends Step implements IHasOutputs {
    statements: Output[];
    
    public get outputs() : Output[] {
        return this.statements;
    }

    public set outputs(v : Output[]) {
        this.statements = v;
    }
    
    constructor(id: string | undefined, name: string, description: string, tags: string[], statements: Output[]) {
        super(id, name, description, StepType.Say, tags);
        this.statements = statements;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): Say {
        const statements = json.statements && Array.isArray(json.statements)
            ? json.statements.map((p: any) => Output.fromJson(p))
            : [];
        return new Say(json.id, json.name, json.description, json.tags, statements);

    }

}