import { BehaviorType } from "./BehaviorType";
import { Behavior } from "./Behavior";
import { BehaviorWithFollowUps } from "./IFollowUpList";

export class RunScriptBehavior extends BehaviorWithFollowUps {
    script: string;

    constructor(script: string, followUpBehaviors: Behavior[]) {
        super(BehaviorType.RunScript, followUpBehaviors);
        this.script = script;
    }

    static fromJson(json: any, followUps: Behavior[]): RunScriptBehavior {
        return new RunScriptBehavior(json.script, followUps);
    }
}