import { Resource, ResourceType } from "./Resource";

export class AzureLanguage extends Resource {
    modelName: string;
    intents: AzureIntent[];
    entities: string[];

    constructor(id: string | undefined, originalId:string, modelName:string, intents:AzureIntent[], entities:string[]) {
        super(modelName, id, originalId, ResourceType.AzureLanguage);
        this.modelName = modelName;
        this.intents = intents;
        this.entities = entities;
    }

    isDirty(original: Resource): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Resource): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Resource {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any) {
        return new AzureLanguage(json.id, json.originalId, json.modelName, json.intents, json.entities);
    }
}

export class AzureIntent {
    name: string;
    entities: string[];

    constructor(name: string, entities: string[]) {
        this.name = name;
        this.entities = entities;
    }
}