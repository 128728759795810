import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, Editor, Collapsible, usePortalModal, ModalContainerIds, Field, useId } from '@smartaction/visuals';
import React, { useEffect, useState } from 'react';
import { API, ConfigPointer, EmptyId, EmptyPointer, Pointer, PointerType } from 'internal/models';
import { useAPIs } from 'contexts/design/APISContext';
import DeleteAPIConfirmModal from './DeleteAPIConfirmModal';
import EndpointTable from './EndpointTable';
import { PointerControl } from 'ui/components';
import { useBots } from 'contexts';
import { useCheckTenantAccess } from 'contexts/AccessContext';
import { EntitlementNames } from 'EntitlementNames';

export const APIManager: React.FC = () => {
  const apis = useAPIs();
  const modal = usePortalModal();
  const {isReadOnlyBot} = useBots();
  const hasAccess = useCheckTenantAccess(EntitlementNames.APIs.Manage)
  const isReadOnly = isReadOnlyBot || !hasAccess;

  return (
    <React.Fragment>
      <Editor>
        <div className="manageApi">
          <Button type={VisualCategory.Primary} 
            isDisabled={isReadOnly}
            action={() => apis.addItem('New API')}>
            Create
          </Button>

          {apis?.apis?.map((api) => 
            <APIView key={api.id} api={api} isDisabled={isReadOnly} />
          )}
        </div>
      </Editor>
      {modal.modal}
    </React.Fragment>
  );
};

type APIViewProps = {
  api: API;
  isDisabled: boolean;
};

const APIView: React.FC<APIViewProps> = ({ api, isDisabled }) => {
  const apis = useAPIs();
  const modal = usePortalModal();

  const onDeleteAPI = ({ endpoints, id, name }: API) => {
    if (endpoints.length) {
      modal.openModal(
        <DeleteAPIConfirmModal
          apiName={name}
          endpointsLength={endpoints.length}
          onSubmit={async () => {
            await apis.deleteItem(id);
            modal.closeModal();
          }}
        />,
      );
      return;
    }
    void apis.deleteItem(id);
  };

  const onUpdateApiName = (id: string) => 
    (event: React.FocusEvent<HTMLInputElement>) => apis.updateName(id, event.target.value);

  const id = useId('baseUrlId');
  const labelContent = (
    <div className={'manageApi-title'}>
      <input
        type="text"
        className="noBorder manageApi-title-input"
        id="name"
        onBlur={onUpdateApiName(api.id)}
        defaultValue={api.name}
        disabled={isDisabled}
      />
    </div>
  );
  const extraControls = (
    <Button type={VisualCategory.Danger} isDisabled={isDisabled} className="manageApi-extra-control-btn" action={() => onDeleteAPI(api)}>
      <Icon type={IconType.Delete} size="sm" />
    </Button>
  );
  const pointer = api.baseUrlId && api.baseUrlId !== EmptyId ? new ConfigPointer(api.baseUrlId) : new EmptyPointer();
  return (
    <React.Fragment>
      <Collapsible
        key={api.id}
        labelContent={labelContent}
        extraControls={extraControls}
        className={'my-3'}
        bodyMaxHeight={'auto'}
      >
        <Field inputId={id} label="Base Url" groupClass="col-3">
          <PointerControl
            isDisabled={isDisabled}
            types={[PointerType.Config]}
            pointer={pointer}
            update={(p) => apis.updateBaseUrlId(api.id, (p as ConfigPointer).configId)}
          />
        </Field>
        <EndpointTable endpoints={api.endpoints} apiId={api.id} isDisabled={isDisabled} />
      </Collapsible>
      {modal.modal}
    </React.Fragment>
  );
};
