import { Output, ExpirationPolicy } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { PolicyModalProps } from '../Types';
import { useClient, usePolicies, useSnapshot } from 'contexts';
import { Field, useId } from '@smartaction/visuals';
import { OutputsView } from '../../OutputsView';

export const ExpirationPolicyView: React.FC<PolicyModalProps<ExpirationPolicy>> = ({ policy, disabled }) => {
  const smsTimeoutId = useId('smsTimeout');
  const webChatTimeoutId = useId('webChatTimeout');
  const { updatePolicies } = usePolicies();
  const client = useClient('flow');
  const snapshot = useSnapshot();
  const [smsTimeout, setSMSTimeout] = useState(policy.smsTimeout);
  const [webChatTimeout, setWebChatTimeout] = useState(policy.webChatTimeout);

  useEffect(() => {
    setSMSTimeout(policy.smsTimeout);
    setWebChatTimeout(policy.webChatTimeout);
  }, [policy.smsTimeout, policy.webChatTimeout, policy.outputs]);

  const updateSMSTimeout = () => {
    client.policies.expirations.setSMSTimeout(snapshot.snapshot.id, policy.id, smsTimeout).then((res) => {
      if (res.success) {
        updatePolicies(() => {
          policy.smsTimeout = smsTimeout;
        });
      }
    });
  };

  const updateWebChatTimeout = () => {
    client.policies.expirations.setWebChatTimeout(snapshot.snapshot.id, policy.id, webChatTimeout).then((res) => {
      if (res.success) {
        updatePolicies(() => {
          policy.webChatTimeout = webChatTimeout;
        });
      }
    });
  };

  const update = async (newOutputs: Output[]) => {
    const response = await client.policies.expirations.setOutputs(snapshot.snapshot.id, policy.id, newOutputs);

    if (response.success) {
      updatePolicies(() => {
        policy.outputs = newOutputs;
      });
    }
}

  return (
    <div>
      <Field label="SMS Expiration (Seconds)" inputId={smsTimeoutId}>
        <input
          id={smsTimeoutId}
          type="number"
          min ={5}
          max={86400}
          value={smsTimeout}
          disabled={disabled}
          onChange={(e) => setSMSTimeout(Number.parseInt(e.target.value))}
          onBlur={updateSMSTimeout}
        />
      </Field>
      <Field label="WebChat Expiration (Seconds)" inputId={webChatTimeoutId}>
        <input
          id={webChatTimeoutId}
          type="number"
          min ={5}
          max={86400}
          value={webChatTimeout}
          disabled={disabled}
          onChange={(e) => setWebChatTimeout(Number.parseInt(e.target.value))}
          onBlur={updateWebChatTimeout}
        />
      </Field>
      <OutputsView update={update} typeName={"Statement"} outputs={policy.outputs} />
    </div>
  );
};
