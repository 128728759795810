import { Methods } from 'internal/clients/Methods';
import { IValidatorClient } from './IValidatorClient';
import { PostError } from 'ui/events';
import { Environment } from 'internal/models/bots/Environment';
import { DataResponse } from 'internal/clients';
import { Marker } from '@smartaction/scripteditor';

export class ValidatorClient implements IValidatorClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async validateAPIScript(tenantId: string, snapshotId: string, script: string) : Promise<DataResponse<Marker[]>> {
    const url = this.GetUrl(tenantId, snapshotId, 'api');
    return await this.executeValidateScript(url, script);
  }

  async validateScriptStep(tenantId: string, snapshotId: string, script: string) : Promise<DataResponse<Marker[]>> {
    const url = this.GetUrl(tenantId, snapshotId, 'step');
    return await this.executeValidateScript(url, script);
  }

  async validateInputFormatterScript(tenantId: string, snapshotId: string, script: string): Promise<DataResponse<Marker[]>> {
    const url = this.GetUrl(tenantId, snapshotId, 'input-formatter');
    return await this.executeValidateScript(url, script);
  }

  async validateValidationScript(tenantId: string, snapshotId: string, script: string): Promise<DataResponse<Marker[]>> {
    const url = this.GetUrl(tenantId, snapshotId, 'validate');
    return await this.executeValidateScript(url, script);
  }

  async validateBehaviorScript(tenantId: string, snapshotId: string, script: string): Promise<DataResponse<Marker[]>> {
    const url = this.GetUrl(tenantId, snapshotId, 'behavior');
    return await this.executeValidateScript(url, script);
  }

  async validate(tenantId: string, snapshotId: string, environment: Environment) {
    const response = await this.methods.post(
      `${this.serviceUrl}/tenant/${tenantId}/snapshot/${snapshotId}/v1/validation/${environment}/validate`,
    );
    const validateResponse = await this.methods.validateResponse(`Validating ${environment}`, response);
    const data = await response.json();

    if (!validateResponse.success) {
      return validateResponse;
    }

    return { success: true, data };
  }

  private async executeValidateScript(url: string, script: string) {
    
    const response = await this.methods.plainBodyRequest(url,'POST', script);
    const data = await response.json();
    
    const validationResponse = await this.methods.validateAndPopToast('Validating Script for Step', response);

    if (!validationResponse.success) {
      return validationResponse;
    }

    if (!data) {
      const error = 'Unexpected response when retrieving validation data';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data,
    };
  }

  private GetUrl(tenantId: string, snapshotId: string, scriptType: string) { 
    return `${this.serviceUrl}/tenant/${tenantId}/snapshot/${snapshotId}/v1/validation/validate/scripts/${scriptType}`;
  }
}
