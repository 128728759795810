import { useCheckTenantAccess } from 'contexts/AccessContext';
import { useSnapshot } from 'contexts/SnapshotContext';
import { Config } from 'internal/models/bots/design/Config';
import React, { useEffect, useState } from 'react';
import { useClient } from '../ClientContext';
import { EntitlementNames } from 'EntitlementNames';

const ConfigsContext = React.createContext<ConfigsContextType | undefined>(undefined);

export type ConfigsContextType = {
  all: Map<string, Config>;
  technical: Config[];
  business: Config[];
  refresh: (fromServer?: boolean) => void;
};

type ConfigsProviderProps = {
  children: React.ReactNode
}

export const ConfigsProvider: React.FC<ConfigsProviderProps> = ({ children }) => {
  const snapshot = useSnapshot();
  const [techConfigs, setTechConfigs] = useState<Config[]>([]);
  const [businessConfigs, setBusinessConfigs] = useState<Config[]>([]);
  const [configMap, setConfigMap] = useState<Map<string, Config>>(new Map<string, Config>());
  const client = useClient('config');
  // TODO: accessContext hardcode
  //const canViewTech = useCheckTenantAccess(EntitlementNames.Configs.Technical.View);
  const canViewTech = true;

  useEffect(() => {
    refresh(true);
  }, [snapshot]);

  const refresh = (fromServer?: boolean) => {
    if (fromServer) {
      const businessPromise = client.business.getAll(snapshot.snapshot.id);
      const technicalPromise = canViewTech
        ? client.technical.getAll(snapshot.snapshot.id)
        : Promise.resolve({ success: true, data: [] });

      Promise.all([businessPromise, technicalPromise]).then(([business, technical]) => {
        const bconfigs = business.success ? business.data! : [];
        const tconfigs = technical.success ? technical.data! : [];
        processConfigs(bconfigs, tconfigs);
      });
    } else {
      // by putting them in a new array, it'll change the reference and React will refresh, which will also catch any updates (like name changes)
      processConfigs(businessConfigs, techConfigs);
    }
  };

  //
  const processConfigs = (business: Config[], technical: Config[]) => {
    setTechConfigs([...technical]);
    setBusinessConfigs([...business]);
    const map = new Map<string, Config>();
    for (let config of technical) {
      map.set(config.id, config);
    }

    for (let config of business) {
      map.set(config.id, config);
    }

    setConfigMap(map);
  };

  return (
    <ConfigsContext.Provider value={{ technical: techConfigs, business: businessConfigs, refresh, all: configMap }}>
      {children}
    </ConfigsContext.Provider>
  );
};

export const useConfigs = () => {
  const context = React.useContext(ConfigsContext);

  if (context === undefined) {
    throw new Error('useConfigs must be used within a ConfigsProvider');
  }

  return context;
};
