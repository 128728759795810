import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Patience, PortalModal, RadioToggleButtons, SubNav, useFromModal, usePortalModal } from '@smartaction/visuals';
import { PublishedSnapshot, SnapshotType, UnpublishedSnapshot, UnpublishedState } from 'internal/models';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';
import { DefaultPublisher } from '@smartaction/common';
import { useBots } from 'contexts/BotsContext';
import { SnapshotView } from './SnapshotView';
import { compile, match } from 'path-to-regexp';
import { SnapshotOpenedEvent } from 'event';
import { Link } from 'ui/controls';
import { useCheckTenantAccess } from 'contexts/AccessContext';
import { EntitlementNames } from 'EntitlementNames';
import { useClient, useTenant } from 'contexts';
import { BotSelect } from './BotSelect';
import { Tooltip } from 'react-tooltip';
import { VisualCategory } from '@smartaction/styles';
type RouteParams = {
  code: string;
  version?: SnapshotType;
};

export const BotView: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { code, version } = useParams<RouteParams>();
  const bots = useBots();
  const client = useClient('bot');
  const modal = usePortalModal();
  const [snapshot, setSnapshot] = useState<PublishedSnapshot | UnpublishedSnapshot>();
  const [approvedBy, setApprovedBy] = useState<boolean>(!!bots.currentBot?.unpublished?.approvedBy);
  const canApprove = useCheckTenantAccess(EntitlementNames.Snapshot.PublishingApproval);

  const [canEdit, setCanEdit] = useState(false);

  interface PathVars {
    tenant: string;
    botId: string;
    version: string;
    page: string;
  }

  interface PathMatch {
    params: PathVars;
  }

  useEffect(() => {
    if (bots.currentBot?.code !== code) {
      bots.setBot(code);
      setSnapshot(undefined);
    }
  }, [code, bots, bots.currentBot]);

  useEffect(() => {
    setApprovedBy(!!bots.currentBot?.unpublished?.approvedBy);
  }, [bots?.currentBot?.unpublished]);

  useEffect(() => {
    if (bots.currentBot && version) {
      const selectedSnapshot = version === 'draft' ? bots.currentBot.unpublished! : bots.currentBot.published!;
      setSnapshot(selectedSnapshot);

      let isEditable = selectedSnapshot instanceof UnpublishedSnapshot;
      setCanEdit(isEditable); // if published, do not allow readonly to be unchecked
      isEditable = isEditable && (selectedSnapshot as UnpublishedSnapshot).state === UnpublishedState.Draft;
      bots.updateReadOnly(!isEditable); // update bot's readonly state based snapshot state

      DefaultPublisher.publish(new SnapshotOpenedEvent(bots.currentBot, selectedSnapshot, version));
    }
  }, [bots.currentBot, version]);

  const setVersion = (selectedVersion: SnapshotType) => {
    if (bots.currentBot === undefined) {
      return;
    }

    const snapshot = selectedVersion === 'draft' ? bots.currentBot.unpublished! : bots.currentBot.published!;

    const pathPattern = '/:tenant/agents/:botId/:version/:page/:environment?';
    const matchFn = match(pathPattern, { decode: decodeURIComponent });
    const res = matchFn(location.pathname) as PathMatch;
    const toPath = compile(pathPattern, { encode: encodeURIComponent });
    const newPath = toPath({ ...res.params, version: selectedVersion });
    navigate(newPath);
    setSnapshot(snapshot);
    DefaultPublisher.publish(new SnapshotOpenedEvent(bots.currentBot, snapshot, selectedVersion));
  };

  const approve = async (value: boolean) => {
    await client.updateApprovedByAsync(bots.currentBot?.unpublished?.id!, value).then((b) => {
      if (b.success) {
        bots.refreshBots();
        setApprovedBy(!approvedBy);
      }
    });
  };

  if (!bots.currentBot) {
    return <h4>Select a bot above to continue.</h4>;
  }

  if (!version) {
    return (
      <Navigate
        to={`${location.pathname}/${bots.currentBot.unpublished !== undefined ? 'draft' : 'published'}/conversations`}
      />
    );
  }

  const deleteButton = bots.currentBot.published 
    ? <React.Fragment/>
    : (
      <div className='ms-auto p-2'>
        <Button className='btn-sm' type={VisualCategory.Danger} action={() => modal.openModal(<DeleteModal />)}>Delete</Button>
      </div>
    );


  return (
    <React.Fragment>
      <SubNav>
        <BotSelect />
        <RadioToggleButtons
          buttons={[
            { label: 'Published', value: 'published', isEnabled: bots.currentBot.published !== undefined },
            { label: 'Draft', value: 'draft', isEnabled: bots.currentBot.unpublished !== undefined },
          ]}
          selectedValue={version}
          setSelectedValue={setVersion}
          name="versionSelection"
          className="btn-group-sm"
          style={{ margin: '5px' }}
        />
        <Link path="conversations">Conversations</Link>
        <Link path="flow">Designer</Link>
        <Link path="publish">Publisher</Link>
        {snapshot instanceof UnpublishedSnapshot && (
          <div className="sub-nav-text">
            <Checkbox label="Approved for Live" value={approvedBy} setValue={approve} readonly={!canApprove}></Checkbox>
          </div>
        )}
        <div className="sub-nav-text ms-3" data-tooltip-id="not-editable">
          <Checkbox
            label="Read-only"
            readonly={!canEdit}
            value={bots.isReadOnlyBot}
            setValue={(val) => bots.updateReadOnly(val, true)}
          />
          {!canEdit && (
            <Tooltip
              id="not-editable"
              positionStrategy="fixed"
              content="Published snapshots are not editable, please use Draft or copy to Draft"
            />
          )}
        </div>
        {deleteButton}
      </SubNav>
      <div className="bot-view">
        <SnapshotView snapshot={snapshot!} />
      </div>
      {modal.modal}
    </React.Fragment>
  );
};


const DeleteModal: React.FC = () => {
  const tenant = useTenant();
  const { currentBot, refreshBots } = useBots();
  const [showPatience, setShowPatience] = useState(false);
  const client = useClient('bot');
  const modal = useFromModal();
  const nav = useNavigate();
  const [makeThemWriteDeleteText, setMakeThemWriteDeleteText]  = useState('');
  
  if (!currentBot) {
    return <React.Fragment/>;
  }

  const deleteAction = async () => {
    setShowPatience(true);
    await client.markAsDeletedAsync(currentBot.id);
    await refreshBots();
    nav(`/${tenant!.code}/agents/`);
    setShowPatience(false);
    modal.forceClose();
  };

  const buttons = (
    <React.Fragment>
      <Button className='mx-2' type={VisualCategory.Danger} action={deleteAction} isDisabled={makeThemWriteDeleteText !== 'delete'} disabledReason='You must type "delete" above to enable this button.'>Delete</Button>
      <Button type={VisualCategory.Secondary} action={modal.forceClose}>Cancel</Button>
    </React.Fragment>
  );

  const content = (
    <React.Fragment>
      <label>Deleting this bot will prevent you from selecting it in the future.</label>
      <label>Please type <code>delete</code> in the text field below to enable the delete button.</label>
      <div className='text-center m-2'>
        <input type='text' className='w-50' value={makeThemWriteDeleteText} onChange={(evt) => setMakeThemWriteDeleteText(evt.target.value)} />
      </div>
    </React.Fragment>
  );

  return (
    <Patience showPatience={showPatience}>
      <PortalModal header='Are you sure?' content={content} buttons={buttons} />
    </Patience>
  );
}
