import { ConfigsContextType, useConfigs, useTenant } from 'contexts';
import { ConfigPointer, ConfigType, EmptyPointer, PointerControlFilters } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { PointerEditorProps } from './PointerEditorProps';
import Select, { SingleValue } from 'react-select';

type ConfigOption = {
  label: string;
  value: string;
};

const EmptyValue = { value: '', label: '--Select Config--' };

type ConfigPointerEditorProps = PointerEditorProps<ConfigPointer> & {
  pointerFilters?: PointerControlFilters[];
  isDisabled: boolean;
};

export const ConfigPointerEditor: React.FC<ConfigPointerEditorProps> = ({ pointer, update, pointerFilters, isDisabled }) => {
  const configs = useConfigs();

  const [selected, setSelected] = useState(EmptyValue);
  const [selectOptions, setSelectOptions] = useState([
    { label: '', options: [] },
    { label: '', value: '' },
  ]);

  useEffect(() => {
    const options = getOptionsForConfig(
      true,
      configs,
      pointer?.configId,
      pointerFilters?.includes(PointerControlFilters.MultiStringOnly)
        ? ConfigType.MultiString
        : pointer?.specificConfigType,
    );

    setSelectOptions(options);
    if (pointer?.configId) {
      const selectedOption = [...configs.business, ...configs.technical].find((item) => item?.id === pointer.configId);
      setSelected(selectedOption ? { value: selectedOption?.id, label: selectedOption!.name } : EmptyValue);
    }
  }, []);

  const save = (opt: SingleValue<ConfigOption>) => {
    if (!opt || !opt.value) {
      setSelected(EmptyValue);
      update(new EmptyPointer());
    } else {
      setSelected(opt);
      update(new ConfigPointer(opt.value));
    }
  };

  return (
    <Select<ConfigOption>
      //Use menuPortal settings to grant menu full visibility
      //Use menuPosition to prevent portal from oddly displacing menu itself
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999999 }),
        menu: (baseStyles) => ({
          ...baseStyles,
          fontSize: 14,
        }),
      }}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      options={selectOptions}
      value={selected}
      onChange={save}
      isDisabled={isDisabled}
      className='custom-select__container'
    />
  );
};

function getOptionsForConfig(
  canViewTechnical: boolean,
  configs: ConfigsContextType,
  selectedConfigId: string | undefined,
  specificConfigType?: ConfigType, // Options will be filtered by specific config type
) {
  let options: Array<{ value: string; label: string } | { options: any; label: string }> = [];

  if (!canViewTechnical) {
    options.push(...configs.business.map((b) => ({ value: b.id, label: b.name, type: b.type })));
    if (selectedConfigId && !configs.business.find((b) => b.id === selectedConfigId)) {
      options.push({ value: selectedConfigId, label: 'Technical Config' });
    }
    return options;
  }
  const businessOptions = configs.business.length
    ? configs.business
        .map((b) => ({ value: b.id, label: b.name, type: b.type }))
        .filter((technicalOption) =>
          specificConfigType ? technicalOption.type === specificConfigType : technicalOption,
        )
        .sort((a, b) => a.label.localeCompare(b.label))
    : [{ value: '', label: 'No Business Configs Defined' }];

  options.push({ label: 'Business', options: businessOptions });
  const technicalOptions = configs.technical.length
    ? configs.technical
        .map((b) => ({ value: b.id, label: b.name, type: b.type }))
        .filter((technicalOption) =>
          specificConfigType ? technicalOption.type === specificConfigType : technicalOption,
        )
        .sort((a, b) => a.label.localeCompare(b.label))
    : [{ value: '', label: 'No Technical Configs Defined' }];

  options.push({ label: 'Technical', options: technicalOptions });

  return options;
}
