import React, { forwardRef, useImperativeHandle } from 'react';
import { Channel, ChannelType, ChannelSourceType, SourceType } from 'internal/models';
import { Select } from '@smartaction/visuals';
import { ICellEditorParams } from 'ag-grid-community';

type TypeOptions = {
  value: string;
  label: string;
};

export const ChannelTypeTableCellEditor = forwardRef((props: ICellEditorParams, ref: React.ForwardedRef<unknown>) => {
  const channel = props.data as Channel;
  const [channelSourceType, setChannelSourceType] = React.useState<ChannelSourceType>(channel.type);
  const [channelType, setChannelType] = React.useState<ChannelType>(channelSourceType.type);
  const channelTypeOptions = (props.colDef as any).channelTypeOptions as TypeOptions[];
  const sourceTypeOptions = (props.colDef as any).sourceTypeOptions as TypeOptions[];

  const onChangeChannelType = (value: string) => {
    setChannelType(value as ChannelType);

    if (value !== ChannelType.Inbound && value !== ChannelType.SMS) {
      setChannelSourceType({ type: value as ChannelType, sourceType: SourceType.NotApplicable });
    }
    if (value === ChannelType.Outbound) {
      setChannelSourceType({ type: value as ChannelType, sourceType: SourceType.ANI });
    }
  };

  const onChangeSourceType = (value: string) => {
    setChannelSourceType({ type: channelType, sourceType: value as SourceType });
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return channelSourceType;
      },
      isCancelAfterEnd: () => {
        return channelSourceType === undefined;
      },
    };
  });

  return (
    <div>
      <Select
        id={channel.id + '-channel-type'}
        options={channelTypeOptions}
        value={channelType}
        onChange={onChangeChannelType}
      />
      {(channelType === ChannelType.Inbound || channelType === ChannelType.SMS) && (
        <div>
          <Select
            id={channel.id + '-source-type'}
            options={sourceTypeOptions}
            value={channelSourceType.type === channelType ? channelSourceType.sourceType : sourceTypeOptions[0].value}
            onChange={onChangeSourceType}
          />
        </div>
      )}
    </div>
  );
});
