import { IconType } from '@smartaction/styles';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { GoTo } from 'internal/models';
import { GoToDropDown } from 'ui/components';
import { useBots, useClient, useFlow, useSnapshot } from 'contexts';
import { ProcessStepsColors } from '../../StepColorGroups';

export const GoToStyling: StepStyling = {
  typeName: 'Go To',
  icon: IconType.ArrowCircleRight,
  ...ProcessStepsColors,
};

export const GoToStepView: React.FC<TypedStepProps<GoTo>> = ({ step, manipulateStep }) => {
  const client = useClient('flow');
  const flow = useFlow();
  const { isReadOnlyBot} = useBots();
  const snapshot = useSnapshot();
  const changeNext = (nextId: string) =>
    client.steps.goTos.SetNext(snapshot.snapshot.id, step.id, nextId).then((res) => {
      if (res.success) {
        flow.updateFlow(() => (step.nextId = nextId));
      }
    });

  return (
    <StepView step={step} styling={GoToStyling} manipulateStep={manipulateStep}>
      <GoToDropDown
        disabled={isReadOnlyBot}
        goToNextId={(step as GoTo).nextId!}
        onModuleItemSelected={changeNext}
      />
    </StepView>
  );
};
