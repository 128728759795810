import { Step } from "./Step";
import { StepType } from "./Type";

export class UnknownStep extends Step {
    receivedType: string;

    constructor(id: string | undefined, name: string, description: string, tags: string[], type: string) {
        super(id, name, description, StepType.Unknown, tags);
        this.receivedType = type;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): UnknownStep {
        return new UnknownStep(json.id, json.name, json.description, json.tags, json.type);

    }

}