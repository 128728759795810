import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Pointer } from 'internal/models';
import { IForEachClient } from './IForEachClient';

export class ForEachClient implements IForEachClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private url(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }

  async setListToIterate(snapshotId: string, stepId: string, pointer: Pointer): Promise<ServerResponse> {
    const url = `${this.url(snapshotId, stepId)}/list-to-iterate`;
    const response = await this.methods.put(url, pointer);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Updated} ForEach List Pointer`,
      response,
    );
  }
}
