import { ICellRendererParams } from 'ag-grid-community';
import { ConfigsContextType, ContextItemContextType, useConfigs, useContextItems } from 'contexts';
import { CompareCondition, CompareType, Condition, ConditionType, ConfigType, ContextType, DateTimeCondition, Pointer, PointerType, ScriptCondition } from 'internal/models';
import React, {  } from 'react';
import { isConfigDateTime, isContextItemDateTime } from './HelperConditionMethods';
import PointerTableCellRenderer from 'ui/controls/table/PointerTableCellRenderer';



type SourceRendererProps = ICellRendererParams<CompareCondition> & {
  presetAllowedTypes: Array<PointerType>;
    onUpdateCondition: (condition: Condition) => void;
}

export const SourceRenderer: React.FC<SourceRendererProps> = ({colDef, value, data, presetAllowedTypes, onUpdateCondition}) => {
    const condition = data as CompareCondition;
    const configs = useConfigs();
    const contextItems = useContextItems();

    const onSourcePointerChange = (newPointerValue: Pointer) => { 
      let isCtxDateTime = isContextItemDateTime(newPointerValue, contextItems.contextItems);
      let isCfgDateTime = isConfigDateTime(newPointerValue, configs.all);;

      if (
        (newPointerValue.type === PointerType.Context && isCtxDateTime) ||
        (newPointerValue.type === PointerType.Config && isCfgDateTime)
      ) {

        let newDateTimeCondition = { 
            ...condition,
            target: condition.target,
            value: newPointerValue,
            type: ConditionType.DateTime
        } as DateTimeCondition;

        onUpdateCondition(newDateTimeCondition);
        
      } else if (newPointerValue.type === PointerType.DirectAssignmentDateTime) {

        let newDateTimeConditionWithLessThan = {
            ...condition,
            target: condition.target,
            value: newPointerValue,
            type: ConditionType.DateTime,
            comparison: CompareType.LessThan
        } as DateTimeCondition;

        onUpdateCondition(newDateTimeConditionWithLessThan);
      } else {
        const pointerValueKey = condition.type === ConditionType.IsIn ? 'list' : 'value';
        onUpdateCondition({ ...condition, [pointerValueKey]: newPointerValue });
      }
    }

    switch (condition.type) {
      case ConditionType.IsIn:
      case ConditionType.Contains:
      case ConditionType.Compare:
      case ConditionType.DateTime:
      case ConditionType.StartsWith:
      case ConditionType.EndsWith:
      case ConditionType.LengthCompare: {
  
        //Check if Pointer is a Config or Context item
        const conditionPointer = condition.target;
        const pointerProperties = Object.entries(conditionPointer);
        let allowedTypes = presetAllowedTypes;
  
        //Find index of the PropertyName for a Config or ContextItem
        const index = ptrPropIndex(pointerProperties);
        const pointerGeneralInfo = getGeneralPointerInfo(pointerProperties, index, contextItems, configs);
  
        if(pointerGeneralInfo)
        {
          //Enable special handling for Config and Context Items
          allowedTypes = overridePresetAllowedTypesForPointerType(pointerGeneralInfo, presetAllowedTypes) ?? [];
        }
  
        return (
          <PointerTableCellRenderer
            //@ts-ignore
            pointer={condition[condition.type === ConditionType.IsIn ? 'list' : 'value']}
            allowedTypes={allowedTypes} 
            onChange={onSourcePointerChange}
          /> );
      }

      case ConditionType.IsPopulated:
      case ConditionType.Regex:
      case ConditionType.Script:
        return (
          <span>
            <i>n/a</i>
          </span>
        );
      default:
        return <span>Coming Soon!</span>;
    }

}

type PointerGeneralInfo = { Name : string, Id : string, DataType : ConfigType | ContextType | undefined};

 enum PointerPropertyNames {
  ContextItemId = 'contextItemId',
  ConfigId = 'configId',
}

  const ptrPropIndex = (pointerProperties : [string, any][])  => { 
      return pointerProperties.findIndex((ptrProperty)=>{
        return ptrProperty[0].toLowerCase() === PointerPropertyNames.ConfigId.toLowerCase() ||
         ptrProperty[0].toLowerCase() === PointerPropertyNames.ContextItemId.toLowerCase();
      });
  }

  const getPointerDataType = (pointerPropertyName: string, pointerId: string, contextItems : ContextItemContextType, configItems : ConfigsContextType ) => {
    if(pointerPropertyName === PointerPropertyNames.ConfigId) {
        return configItems.all.get(pointerId)?.type
    }
    else if(pointerPropertyName === PointerPropertyNames.ContextItemId){ 
        return contextItems.map.get(pointerId)?.type
    }
  }

  const getGeneralPointerInfo = (pointerProperties: [string, any][], propertyIndex: number, contextItems : ContextItemContextType, configItems : ConfigsContextType ) => { 

    if(propertyIndex < 0) return undefined;

    let name = pointerProperties[propertyIndex][0]
    let id = pointerProperties[propertyIndex][1] as string

    return { 
      Name : name, 
      Id : id, 
      DataType: getPointerDataType(name, id, contextItems, configItems) };
  }

  const overridePresetAllowedTypesForPointerType = (pointerGeneralInfo : PointerGeneralInfo,  allowedTypes: PointerType[]) => { 

      switch(pointerGeneralInfo.Name){
        //Config Item handling
        case PointerPropertyNames.ConfigId:
          return overrideDefaultAllowedTypesForConfig(pointerGeneralInfo.DataType as ConfigType, allowedTypes);

        //Context Item handling
        case PointerPropertyNames.ContextItemId:
          return overrideDefaultAllowedTypesForContext(pointerGeneralInfo.DataType as ContextType, allowedTypes);
      }

      return undefined;
  }

  const overrideDefaultAllowedTypesForConfig = (pointerDataType : ConfigType, defaultTypes : PointerType[]) => {
    switch(pointerDataType.toLowerCase()){
      case 'date':
      case 'daterange':
      case 'time':
      case 'dayofmonth':
        return [PointerType.Config, PointerType.Context, PointerType.DirectAssignmentDateTime];
      default:
        return defaultTypes;
    }
  }

  const overrideDefaultAllowedTypesForContext = (pointerDataType : ContextType, defaultTypes : PointerType[]) => {
    switch(pointerDataType.toLowerCase()){
      case 'date':
      case 'daterange':
      case 'datetime':
      case 'time':
        return [PointerType.Config, PointerType.Context, PointerType.DirectAssignmentDateTime];
      default:
        return defaultTypes;
    }
  }
  