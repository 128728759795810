import { IconType } from '@smartaction/styles';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { CallFunction } from 'internal/models';
import { FunctionDropDown } from 'ui/components';
import { useBots, useClient, useFlow, useSnapshot } from 'contexts';
import { ProcessStepsColors } from '../../StepColorGroups';

export const CallFunctionStyling: StepStyling = {
  typeName: 'Call Function',
  icon: IconType.Function,
  ...ProcessStepsColors,
};

export const CallFunctionStepView: React.FC<TypedStepProps<CallFunction>> = ({ step, manipulateStep }) => {
  const client = useClient('flow');
  const flow = useFlow();
  const { isReadOnlyBot} = useBots();
  const snapshot = useSnapshot();
  const changeFunctionId = (functionId: string) =>
    client.steps.callFunctions.setFunctionId(snapshot.snapshot.id, step.id, functionId).then((res) => {
      if (res.success) {
        flow.updateFlow(() => (step.functionId = functionId));
      }
    });

  return (
    <StepView step={step} styling={CallFunctionStyling} manipulateStep={manipulateStep}>
      <FunctionDropDown
        disabled={isReadOnlyBot}
        functionId={step.functionId!}
        onFunctionSelected={changeFunctionId}
      />
    </StepView>
  );
};
