import {
  IClientManager,
  IClients,
  IBotClient,
  BotClient,
  ITenantClient,
  TenantClient,
  IFlowClient,
  FlowClient,
  IAPIClient,
  APIClient,
  IAccessClient,
  AccessClient,
  IConfigsClient,
  ConfigsClient,
  IObjectClient,
  ObjectClient,
  IResourceClient,
  ResourceClient,
  Methods,
  PublishClient,
  ContextClient,
  IContextClient,
  PolicyClient,
  IPolicyClient,
  IPublishClient,
  ConversationClient,
  IConversationClient,
  PropertiesClient,
  IPropertiesClient,
  ValidatorClient,
  IValidatorClient,
  DuplicatorClient,
  IDuplicatorClient,
  LocationClient,
  ILocationClient,
  ILocationDefinitionClient,
  LocationDefinitionClient,
} from '.';
import { ServiceUrls } from 'internal/models';

// <T extends keyof IPublisherEvents>(eventName: T, action: (event: IPublisherEvents[T]) => void): string;

declare global {
  interface Window {
    serviceUrls: ServiceUrls;
  }
}
export class ClientManager implements IClientManager {
  methods: Methods;
  botClient: IBotClient;
  flowClient: IFlowClient;
  apiClient: IAPIClient;
  accessClient: IAccessClient;
  tenantClient: ITenantClient;
  configClient: IConfigsClient;
  publishClient: IPublishClient;
  contextClient: IContextClient;
  objectClient: IObjectClient;
  resourceClient: IResourceClient;
  policyClient: IPolicyClient;
  conversationClient: IConversationClient;
  propertiesClient: IPropertiesClient;
  validatorClient: IValidatorClient;
  duplicatorClient: IDuplicatorClient;
  locationDefinitionClient: ILocationDefinitionClient;
  locationsClient: ILocationClient;

  constructor(serviceUrls: ServiceUrls) {
    this.methods = new Methods();
    this.botClient = new BotClient(serviceUrls.BotDepot, this.methods);
    this.flowClient = new FlowClient(serviceUrls.Flow, this.methods);
    this.apiClient = new APIClient(serviceUrls.API, this.methods);
    this.tenantClient = new TenantClient(serviceUrls.Gateway, serviceUrls.Tenant, this.methods);
    this.accessClient = new AccessClient(serviceUrls.Gateway, this.methods);
    this.configClient = new ConfigsClient(serviceUrls.Config, this.methods);
    this.publishClient = new PublishClient(serviceUrls.Publisher, this.methods);
    this.contextClient = new ContextClient(serviceUrls.Context, this.methods);
    this.objectClient = new ObjectClient(serviceUrls.Objects, this.methods);
    this.resourceClient = new ResourceClient(serviceUrls.Resources, this.methods);
    this.policyClient = new PolicyClient(serviceUrls.Flow, this.methods);
    this.conversationClient = new ConversationClient(serviceUrls.Conversation, this.methods);
    this.propertiesClient = new PropertiesClient(serviceUrls.Properties, this.methods);
    this.validatorClient = new ValidatorClient(serviceUrls.Validator, this.methods);
    this.duplicatorClient = new DuplicatorClient(serviceUrls.Duplicator, this.methods);
    this.locationDefinitionClient = new LocationDefinitionClient(serviceUrls.Location, this.methods);
    this.locationsClient = new LocationClient(serviceUrls.Location, this.methods);
    window.serviceUrls = serviceUrls;
  }

  setTenantId(tenantId?: string) {
    this.methods.setTenant(tenantId);
  }

  getClient<T extends keyof IClients>(client: T): IClients[T] | null {
    switch (client) {
      case 'bot':
        return this.botClient as IClients[T];
      case 'flow':
        return this.flowClient as IClients[T];
      case 'apis':
        return this.apiClient as IClients[T];
      case 'access':
        return this.accessClient as IClients[T];
      case 'tenant':
        return this.tenantClient as IClients[T];
      case 'config':
        return this.configClient as IClients[T];
      case 'publisher':
        return this.publishClient as IClients[T];
      case 'context':
        return this.contextClient as IClients[T];
      case 'objects':
        return this.objectClient as IClients[T];
      case 'resources':
        return this.resourceClient as IClients[T];
      case 'conversation':
        return this.conversationClient as IClients[T];
      case 'properties':
        return this.propertiesClient as IClients[T];
      case 'validator':
        return this.validatorClient as IClients[T];
      case 'duplicator':
        return this.duplicatorClient as IClients[T];
      case 'location':
        return this.locationsClient as IClients[T];
      case 'locationDefinition':
        return this.locationDefinitionClient as IClients[T];
      default:
        return null;
    }
  }
}
