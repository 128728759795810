import { Access } from 'internal/models';
import { IAccessClient, Methods, ValidateResponseActionEnum } from '.';

export class AccessClient implements IAccessClient {
  methods: Methods;
  serviceUrl: string;

  constructor(serviceUrl: string, methods: Methods) {
    this.methods = methods;
    this.serviceUrl = serviceUrl;
  }

  async getAccessAsync(): Promise<Access | undefined> {
    const response = await this.methods.get(`${this.serviceUrl}/v1/access`);
    const success = await this.methods.handleErrors(`${ValidateResponseActionEnum.Get} access`, response);
    if (!success) {
      return undefined;
    }
    const unlimitedOrAccess = await response.json();

    if (unlimitedOrAccess === 'UNLIMITED!') {
      return new Access(true);
    }
    return Access.fromJson(unlimitedOrAccess);
  }
}
