import React, { useEffect, useState } from 'react';
import { useBots, useClient, useResources, useSnapshot } from 'contexts';
import { Table, TableRow } from 'ui/components';
import { Button, usePortalModal } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { ResourceItemType } from '../../ResourceManager';
import { AzureLanguage } from 'internal/models';
import { v4 as uuid } from 'uuid';
import Select from 'react-select';
import { AddAzureLanguageResourceModal } from './AddAzureLanguageResourceModal';
import { Option } from 'ui/types';

export const AzureLanguageResourceType: React.FC<ResourceItemType> = ({
  resources,
  onChangeResourceItemName,
  onDeleteResourceItem,
}) => {
  const { isReadOnlyBot } = useBots();
  const client = useClient('resources');
  const { snapshot } = useSnapshot();
  const { canManage } = useResources();
  const isEditable = !isReadOnlyBot && canManage;
  const modal = usePortalModal();
  const [cluOptions, setCluOptions] = useState<Option[] | undefined>([]);

  useEffect(() => {
    client.languages.getResources(snapshot.id, true).then((r) => {
      setCluOptions(
        r.map((el) => ({
          label: el.replace(/([A-Z])/g, ' $1'),
          value: el,
        })),
      );
    });
  }, []);
  const handleCreateResource = () => modal.openModal(<AddAzureLanguageResourceModal />);

  const initialRowsData = resources
    ? resources.map((resource, index) => {
        const handleRefresh = async () =>
          await client.languages.refreshIntents(snapshot.id, resource.id).then((r) => {
            if (!r.success || !r.data) return;

            let nlu = resources.find((r) => r.id === resource.id) as AzureLanguage;
            nlu.intents = r.data;
          });
        return {
          id: uuid(),
          cells: [
            {
              id: `${resource.type}-name-${index}`,
              type: 'customNode',
              customNode: (
                <Select
                  isDisabled={!isEditable}
                  options={cluOptions}
                  onChange={(value) => value && onChangeResourceItemName(resource.id, value.value)}
                  value={cluOptions?.find((option) => option.value === resource.name)}
                />
              ),
            },
            {
              id: `${resource.type}-props-${index}`,
              type: 'customNode',
              customNode: (
                <>
                  <b>Intents: </b>
                  {(resource as AzureLanguage).intents.map((i) => i.name).join(', ')}
                </>
              ),
            },
            {
              id: `${resource.type}-action-${index}`,
              type: 'customNode',
              customNode: (
                <>
                  <div className={'d-flex justify-content-between'}>
                    <Button action={handleRefresh} type={VisualCategory.Info} className="btn-sm me-2">
                      <Icon type={IconType.Import} />
                    </Button>
                  </div>
                </>
              ),
            },
          ],
        } as TableRow;
      })
    : [];
  return (
    <div>
      <Table
        id={'azureLanguageResourceType'}
        headersGroup={['Name', 'Properties', 'Actions']}
        columnsSize={['md', 400, 'sm']}
        rows={initialRowsData}
        onDeleteRow={(id) => onDeleteResourceItem(id)}
        style={{ pointerEvents: isReadOnlyBot ? 'none' : 'initial' }}
        footer={
          <Button isDisabled={!isEditable} action={handleCreateResource} type={VisualCategory.Primary}>
            Create
          </Button>
        }
      />
      {modal.modal}
    </div>
  );
};
