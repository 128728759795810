import { Position } from '@smartaction/visuals/Types';
import { EmptyId, Pointer } from 'internal/models';
import { ModuleChildTypes } from '.';
import { Step, StepBuilder } from '../steps';
import { Component } from './ModuleItem';

export class EntryPoint extends Component<EntryPoint> {
  readonly type: ModuleChildTypes = ModuleChildTypes.EntryPoint;
  steps: Array<Step>;
  nextId: string;
  channels: Channel[];
  originalId: string;

  constructor(
    id: string | undefined,
    name: string,
    description: string,
    position: Position,
    steps: Array<Step>,
    nextId: string,
    channels: Array<Channel>,
    originalId: string,
  ) {
    super(id, name, description, position);
    this.steps = steps;
    this.nextId = nextId;
    this.channels = channels;
    this.originalId = originalId;
  }

  isDirty(original: EntryPoint): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: EntryPoint): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): EntryPoint {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any) {
    const steps = json.steps && Array.isArray(json.steps) ? json.steps.map((s: any) => StepBuilder(s)) : [];
    const channels =
      json.channels && Array.isArray(json.channels) ? json.channels.map((c: any) => Channel.fromJson(c)) : [];
    return new EntryPoint(
      json.id,
      json.name,
      json.description,
      { x: json.position.x, y: json.position.y },
      steps,
      json.nextId ? json.nextId : EmptyId,
      channels,
      json.originalId,
    );
  }
}

export class Channel {
  id: string;
  name: string;
  source: Pointer;
  type: ChannelSourceType;
  sipHeaders: SipHeaderType[];
  nonHumanFunctionId?: string;
  indeterminateFunctionId?: string;

  constructor(
    id: string,
    name: string,
    channelType: ChannelType,
    source: Pointer,
    sourceType: SourceType,
    sipHeaders: SipHeaderType[],
    nonHumanFunctionId?: string,
    indeterminateFunctionId?: string,
  ) {
    this.id = id;
    this.name = name;
    this.source = source;
    this.type = new ChannelSourceType(channelType, sourceType);
    this.sipHeaders = sipHeaders;
    this.nonHumanFunctionId = nonHumanFunctionId;
    this.indeterminateFunctionId = indeterminateFunctionId;
  }

  static fromJson(json: any) {
    return new Channel(
      json.id,
      json.name,
      json.type,
      Pointer.fromJson(json.source),
      json.sourceType,
      json.sipHeaders,
      json.nonHumanFunctionId,
      json.indeterminateFunctionId,
    );
  }
}

export enum ChannelType {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  SMS = 'SMS',
  WebChat = 'WebChat',
}

export type SipHeaderType = {
  id: string;
  name: string;
  source: Pointer;
};

export enum SourceType {
  NotApplicable = 'NotApplicable',
  ANI = 'ANI',
  DNIS = 'DNIS',
}

export class ChannelSourceType {
  type: ChannelType;
  sourceType: SourceType;

  constructor(channelType: ChannelType, sourceType: SourceType) {
    this.type = channelType;
    this.sourceType = sourceType;
  }
}

