import { DateTimePointerValue, DateTimePointerValueType, DateValue, DayOfMonthValue, DayOfWeekValue, EmptyValue, TimeValue } from "./Pointers";

export function DateTimePointerValueBuilder(json: any) {
    switch (json.type) {
      
      case DateTimePointerValueType.DateTimeValue:
        return DateTimePointerValue.fromJson(json);
      case DateTimePointerValueType.DateValue:
        return DateValue.fromJson(json);
      case DateTimePointerValueType.TimeValue:
        return TimeValue.fromJson(json);
      case DateTimePointerValueType.DayOfWeekValue:
        return DayOfWeekValue.fromJson(json);
      case DateTimePointerValueType.DayOfMonthValue:
        return DayOfMonthValue.fromJson(json);
      case DateTimePointerValueType.Empty:
        return new EmptyValue();

    }
  }
  