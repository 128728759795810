import { BehaviorType } from "./BehaviorType";
import { Behavior } from "./Behavior";


export class CallFunctionBehavior extends Behavior {
    functionId: string;
    // Whether we should attempt the original step again
    repeatStep: boolean;

    constructor(functionId: string, repeatStep: boolean) {
        super(BehaviorType.CallFunction);
        this.functionId = functionId;
        this.repeatStep = repeatStep;
    }

    static fromJson(json: any): CallFunctionBehavior {
        return new CallFunctionBehavior(json.functionId, json.repeatStep);
    }
}
