import React from "react";
import { ModuleChildTypes, Note } from "internal/models";
import { Sticky } from "../nodes/shapes/Sticky";

const NoteDraggable: React.FC = () => {
  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('application/reactflow', ModuleChildTypes.Note);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className="noteDraggable dndnode" onDragStart={onDragStart} draggable>
      <Sticky color={Note.defaultTextColor} backgroundColor={Note.defaultColor} >Note</Sticky>
    </div>
  )
}
export default NoteDraggable;