import { Condition } from "../../shared";
import { Step } from "../Step";
import { StepBuilder } from "../StepBuilder";
import { StepType } from "../Type";

export class While extends Step {
    conditions: Condition[];
    steps: Step[];

    constructor(id: string | undefined, name: string, description: string, tags: string[], steps: Array<Step>, conditions: Array<Condition>) {
        super(id, name, description, StepType.While, tags);
        this.steps = steps;
        this.conditions = conditions;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): While { 
        const steps = json.steps && Array.isArray(json.steps)
            ? json.steps.map((s: any) => StepBuilder(s))
            : [];
        const conditions = json.conditions && Array.isArray(json.conditions)
            ? json.conditions.map((c: any) => Condition.fromJson(c))
            : [];
        return new While(json.id, json.name, json.description, json.tags, steps, conditions);
    }
}