import { DataResponse, Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients/index';
import { PostError } from 'ui/events';
import { ILocationClient } from './ILocationClient';
import { CustomFieldValue, Location, TimeRange } from '../../../models';

export class LocationClient implements ILocationClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async list(): Promise<DataResponse<Location[]>> {
    const url = this.getUrl();
    const response = await this.methods.get(url);

    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} list of location items`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    if (!data) {
      const error = 'Unexpected response when retrieving Location Items';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data: data.map((d: any) => Location.fromJson(d)),
    };
  }

  async createLocation(locationDefinitionId: string, displayName: string): Promise<DataResponse<string>> {
    let response = await this.methods.post(`${this.getUrl()}`, {
      displayName,
      locationDefinitionId,
    });
    return await this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Created} Location`, response);
  }

  async updateName(locationId: string, name: string): Promise<ServerResponse> {
    let url = `${this.getUrl(locationId)}/name/${name}`;
    let response = await this.methods.put(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Location name`, response);
  }

  async delete(locationId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(locationId)}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Location Field`, response);
  }

  async setPronunciation(locationId: string, languageCode: string, value: string): Promise<ServerResponse> {
    let url = `${this.getUrl(locationId)}/pronunciation/${languageCode}`;
    let response = await this.methods.plainBodyRequest(url, 'PUT', value);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} pronunciation`, response);
  }

  async removePronunciation(locationId: string, languageCode: string): Promise<ServerResponse> {
    let url = `${this.getUrl(locationId)}/pronunciation/${languageCode}`;
    let response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} pronunciation`, response);
  }

  async setPronunciations(locationId: string, pronunciations: Map<string, string>): Promise<ServerResponse> {
    let url = `${this.getUrl(locationId)}/pronunciation`;
    const body: { [s: string]: string } = {};
    pronunciations.forEach((value, key) => {
      body[key] = value;
    });
    let response = await this.methods.put(url, body);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} pronunciations`, response);
  }

  async updateNote(locationId: string, value: string): Promise<ServerResponse> {
    let url = `${this.getUrl(locationId)}/note`;
    let response = await this.methods.plainBodyRequest(url, 'PUT', value);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} note`, response);
  }

  async updateCustomerId(locationId: string, value: string): Promise<ServerResponse> {
    let url = `${this.getUrl(locationId)}/customer-id/${value}`;
    let response = await this.methods.put(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} customer id`, response);
  }

  async updateAddress(locationId: string, value: string): Promise<ServerResponse> {
    let url = `${this.getUrl(locationId)}/address`;
    let response = await this.methods.plainBodyRequest(url, 'PUT', value);
    return await this.methods.validateAndPopToast(`Updated address`, response);
  }

  async updateTimeZone(locationId: string, value: string): Promise<ServerResponse> {
    let url = `${this.getUrl(locationId)}/time-zone`;
    let response = await this.methods.plainBodyRequest(url, 'PUT', value);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} time zone`, response);
  }

  async updatePhoneNumber(locationId: string, value: string): Promise<ServerResponse> {
    let url = `${this.getUrl(locationId)}/phone-number`;
    let response = await this.methods.plainBodyRequest(url, 'PUT', value);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} phone number`, response);
  }

  async updateHours(locationId: string, day: string, value: TimeRange[]): Promise<ServerResponse> {
    let url = `${this.getUrl(locationId)}/office-hours/${day}`;
    let response = await this.methods.bodyRequest(url, 'PUT', value);
    return await this.methods.validateResponse(`${ValidateResponseActionEnum.Updated} Location hrs`, response);
  }

  async updateCustomField(locationId: string, customFieldId: string, value: CustomFieldValue): Promise<ServerResponse> {
    let url = `${this.getUrl(locationId)}/custom-data/${customFieldId}`;
    const request = (value as CustomFieldValue).toJson();
    let response = await this.methods.bodyRequest(url, 'PUT', request);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Custom Field`, response);
  }

  private getUrl(locationId?: string) {
    return locationId
      ? `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/locations/${locationId}`
      : `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/locations`;
  }
}
