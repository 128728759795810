import { ColorHexes, Icon, IconType } from '@smartaction/styles';
import { PortalModal, usePortalModal } from '@smartaction/visuals';
import { ICellRendererParams } from 'ag-grid-community';
import { useClient, useConfigs, useContextItems, useFlow, useSnapshot } from 'contexts';
import { CLU, If, StepType, Summary } from 'internal/models';
import React, {  } from 'react';
import { useJSONModal } from 'ui/components/JSONModal';
import { splitLongStringInAPrettyWay } from 'ui/utils';

export const ConversationButtonsRenderer: React.FC<ICellRendererParams> = ({colDef, value, data}) => {
    const client = useClient('conversation');
    const summary = data as Summary;
    const snapshot = useSnapshot();
    const configs = useConfigs();
    const flow = useFlow();
    const contextItems = useContextItems();
    const jsonModal = useJSONModal();
    const modal = usePortalModal();

    const getExecution = async () => { 
        let result = await client.getExecution(snapshot.snapshot.id, summary.conversationId); 
        if (!result.success) {
         modal.openModal(<PortalModal className='Summary' content="Unable to load data for this conversation." header="Cannot load" />);
         return;
        }
        for(let executionItem of result.data!) {
             let updatedActionStrings = (executionItem.action as string).split('\n');
             // Since the string is easier to read if it's not an array, only make it an array if we need to 
             // (there were line breaks in the string, or the length is over the 120 char limit)
             if (updatedActionStrings.length > 1 || updatedActionStrings[0].length > 120) {
                 updatedActionStrings = updatedActionStrings.flatMap(s => splitLongStringInAPrettyWay(s, 120));
                 executionItem.action = updatedActionStrings;
             }
             if (executionItem.userInput) {
                 let updatedUserInputStrings = (executionItem.userInput as string).split('\n');
                 // Don't need to turn this into an array if there's nothing to split on.
                 if (updatedUserInputStrings.length === 1) {
                     continue;
                 }
                 updatedUserInputStrings = updatedUserInputStrings.flatMap(s => splitLongStringInAPrettyWay(s, 120));
                 executionItem.userInput = updatedUserInputStrings;
             }
        }
 
        let jsonResult = JSON.stringify(result.data); 
 
        for( let config of configs.all ){
          jsonResult = jsonResult.replace(new RegExp(`${config[0]}`, 'g'), config[1].name);
         }
        for( let contextItem of contextItems.map ){
          jsonResult = jsonResult.replace(new RegExp(`${contextItem[0]}`, 'g'), contextItem[1].name);
        }
 
        for (let modules of flow.flow.modules) {
         for (let block of modules.blocks) {
             jsonResult = jsonResult.replace(new RegExp(`${block.id}`, 'g'), block.name);
 
             for (let step of block.steps) {
                 jsonResult = jsonResult.replace(new RegExp(`${step.id}`, 'g'), step.name);
                 if (step.type === StepType.If) {
                     for (let branch of (step as If).branches) {
                         jsonResult = jsonResult.replace(new RegExp(`${branch.id}`, 'g'), branch.name);
                         for (let branchStep of branch.steps) {
                             jsonResult = jsonResult.replace(new RegExp(`${branchStep.id}`, 'g'), branchStep.name);
                         }
                     }
                 } else if (step.type === StepType.CLU) {
                     for (let intent of (step as CLU).intents) {
                         jsonResult = jsonResult.replace(new RegExp(`${intent.id}`, 'g'), intent.name ?? "Unnamed Intent");
 
                         for (let intentStep of intent.steps) {
                             jsonResult = jsonResult.replace(new RegExp(`${intentStep.id}`, 'g'), intentStep.name);
                         }
                     }
                 }
             }
         }
 
         for (let entryPoint of modules.entryPoints) {
             jsonResult = jsonResult.replace(new RegExp(`${entryPoint.id}`, 'g'), entryPoint.name);
             for (let step of entryPoint.steps) {
                 jsonResult = jsonResult.replace(new RegExp(`${step.id}`, 'g'), step.name);
                 if (step.type === StepType.If) {
                     for (let branch of (step as If).branches) {
                         jsonResult = jsonResult.replace(new RegExp(`${branch.id}`, 'g'), branch.name);
                         for (let branchStep of branch.steps) {
                             jsonResult = jsonResult.replace(new RegExp(`${branchStep.id}`, 'g'), branchStep.name);
                         }
                     }
                 } else if (step.type === StepType.CLU) {
                     for (let intent of (step as CLU).intents) {
                         jsonResult = jsonResult.replace(new RegExp(`${intent.id}`, 'g'), intent.name ?? "Unnamed Intent");
 
                         for (let intentStep of intent.steps) {
                             jsonResult = jsonResult.replace(new RegExp(`${intentStep.id}`, 'g'), intentStep.name);
                         }
                     }
                 }
             }
         }
 
         for (let decision of modules.decisions) {
             jsonResult = jsonResult.replace(new RegExp(`${decision.id}`, 'g'), decision.name);
 
             for (let branch of decision.branches) {
                 jsonResult = jsonResult.replace(new RegExp(`${branch.id}`, 'g'), branch.name);
             }
         }
        }
        jsonModal.open("Execution", jsonResult);
     }
 
     const showTranscript = () => {
         const transcript = summary.transcript.map(t => {
             return { 
                 'Speaker': t.speaker,
                 'Message': splitLongStringInAPrettyWay(t.text, 120),
                 'Timestamp': t.timestamp
             };
         });
         jsonModal.open("Transcript", JSON.stringify(transcript));
     }
 
     const showData = () => {
         // we don't care about types because we're already presenting this data as JSON.
         const configData: any[] = [];
         const contextItemData: any[] = [];
         summary.configs.forEach((v, k) => {
            console.log(v);
            const configDefinition = configs.all.get(v.key);
            if (configDefinition) {
                configData.push({ name: configDefinition.name, value: v.value });
            } else {
                configData.push({ name: `Unknown (Id: ${v.key})`, value: v.value});
            }
         });
 
         summary.contextItems.forEach((v, k) => {
            console.log(v);
            const contextItemDefinition = contextItems.map.get(v.key);
            if (contextItemDefinition) {
                contextItemData.push({ name: contextItemDefinition.name, value: v.value});
            } else {
                contextItemData.push({ name: `Unknown (Id: ${v.key})`, value: v.value});
            }
         });
 
         if (!configData.length) {
             jsonModal.open("Context Items", JSON.stringify(contextItemData));
         } else {
             jsonModal.openMultiples("Data", [{ title: "Configs", data: JSON.stringify(configData) },
                 { title: "Context Items", data: JSON.stringify(contextItemData), showLinks: true }]);
         }
     }
     
    const download = async () => {
        await client.downloadRecording(summary.botCode, summary.conversationId);
    }
    return (
        <React.Fragment>
            <span className='convo-button transcript' onClick={showTranscript}><Icon size='lg' type={IconType.Transcript} color={ColorHexes.Primary} /></span>
            <span className='convo-button data' onClick={showData}><Icon size='lg' type={IconType.Database} color={ColorHexes.Primary} /></span>
            <span className='convo-button execution' onClick={getExecution}><Icon size='lg' type={IconType.Execution} color={ColorHexes.Primary} /></span>
            <span className='convo-button download' onClick={download}><Icon size='lg' type={IconType.Recording} color={ColorHexes.Primary} /></span>
            {jsonModal.modal}
            {modal.modal}
        </React.Fragment>
    );
}
