import { Methods, ValidateResponseActionEnum } from 'internal/clients/Methods';
import { ISetLocationClient } from './ISetLocationClient';
import { Condition } from 'internal/models';
import { DataResponse, ServerResponse } from '../../../../Responses';

export class SetLocationClient implements ISetLocationClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }
  private getUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }

  private conditionsUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}/conditions`;
  }

  async setNoLocationFunction(snapshotId: string, stepId: string, functionId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/no-locations-function`;
    const response = await this.methods.put(url, functionId);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Location Function for Step`,
      response,
    );
  }
  async setMoreLocationFunction(snapshotId: string, stepId: string, functionId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/more-than-one-location-function`;

    const response = await this.methods.put(url, functionId);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Location Function for Step`,
      response,
    );
  }
  async createCondition(snapshotId: string, stepId: string, condition: Condition): Promise<DataResponse<string>> {
    const url = this.conditionsUrl(snapshotId, stepId);
    const response = await this.methods.post(url, condition);
    return await this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Created} Condition`, response);
  }

  async replaceCondition(
    snapshotId: string,
    stepId: string,
    conditionId: string,
    condition: Condition,
  ): Promise<ServerResponse> {
    const url = `${this.conditionsUrl(snapshotId, stepId)}/${conditionId}`;
    const response = await this.methods.put(url, condition);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Condition`, response);
  }

  async deleteCondition(snapshotId: string, stepId: string, conditionId: string): Promise<ServerResponse> {
    const url = `${this.conditionsUrl(snapshotId, stepId)}/${conditionId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Condition`, response);
  }
}
