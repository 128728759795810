import React, { useEffect, useRef, useState } from 'react';

const navKeys = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Home", "End"];

type StringListCellEditorProps = {
    strings: string[],
    setStrings: (values: string[]) => void,
    placeholder?: string,
    style?: React.CSSProperties,
    className?: string,
    isDisabled?: boolean
}

function joinStrings(strings: string[]) {
    if (!strings?.length) {
        return "";
    }

    return strings.join('\n');
}

export const StringListCellEditor : React.FC<StringListCellEditorProps> = 
  ({strings, setStrings, placeholder, style, className, isDisabled}) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [value, setValue] = useState(joinStrings(strings));

    function inputHandler(e : React.ChangeEvent<HTMLTextAreaElement>) {
        setValue(e.target.value);
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [inputRef]);

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (navKeys.some(k => k === event.key) || (event.key === "Enter" && !event.ctrlKey)) {
            // this stops the grid from receiving the event and executing keyboard navigation
            event.stopPropagation();
        }
    }

    const save = () => {
        setStrings(value.split('\n'));
    }

    let styling: React.CSSProperties = {'zIndex': 2, 'minHeight':'120px'};
    if (style) {
        styling = { ...styling, ...style };
    }

    return (
        <textarea
            className={className}
            ref={inputRef}
            onChange={inputHandler}
            onBlur={save}
            value={value}
            onKeyDownCapture={onKeyDown}    
            placeholder={placeholder}
            style={styling}
            disabled={isDisabled}
        />
    );
}