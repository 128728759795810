import Creatable from 'react-select/creatable';
import { useBots, useTenant } from 'contexts';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SubNavSelectStyles } from 'ui/layout';
import { Option } from 'ui/types';
import { usePortalModal } from '@smartaction/visuals';
import { CreateBotModal } from './CreateBotModal';

const NoBotSelection = { label: '-- Select or new name--', value: '' };


export const BotSelect: React.FC = () => {
    const bots = useBots();
    const tenant = useTenant();
    const navigate = useNavigate();
    const modal = usePortalModal();
    const params = useParams();

    useEffect(() => {
        if (bots.currentBot && !params.code) {
            if (bots.currentBot.tenantId !== tenant!.id) {
                bots.setBot(undefined);
                return;
            }
            navigate(`/${tenant!.code}/agents/${bots.currentBot.code}`);
        }
    }, []);
    
    const selectedBot = bots.currentBot ? { label: bots.currentBot.name, value: bots.currentBot.code } : NoBotSelection;
    const botOptions = bots.bots
      ? bots.bots.map((b) => {
          return { label: b.name, value: b.code };
        })
      : [];
    
    const change = (opt: Option) => {
        if (bots.bots) {
            bots.setBot(opt.value);
            navigate(`/${tenant!.code}/agents/${opt.value}`);
        }
    };

    const handleCreate = (inputValue: string) => {
        modal.openModal(<CreateBotModal starterName={inputValue} />);
      };

    return (
        <React.Fragment>
            <Creatable
                value={selectedBot}
                options={botOptions}
                onChange={(b) => {
                    if (b !== null) {
                    change(b);
                    }
                }}
                onCreateOption={handleCreate}
                styles={SubNavSelectStyles}
                className="bot-select" />
            {modal.modal}
        </React.Fragment>
    );
}