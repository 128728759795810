import React from 'react';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { IconType } from '@smartaction/styles';
import { Field } from '@smartaction/visuals';
import { InputStepsColors } from '../../StepColorGroups';
import { CanInterruptView, PointerControl } from 'ui/components/design';
import { Pointer, PointerControlFilters, PointerType, TextEntry } from 'internal/models';
import { useClient, useFlow, useSnapshot } from 'contexts';
import { StepOutputsView } from '../../StepOutputsView';

export const TextEntryStyling: StepStyling = {
  typeName: 'Text Entry',
  icon: IconType.TextEntry,
  ...InputStepsColors,
};

export const TextEntryStepView: React.FC<TypedStepProps<TextEntry>> = ({ step, manipulateStep }) => {
  const { snapshot } = useSnapshot();
  const client = useClient('flow');
  const { updateFlow } = useFlow();

  const changePointer = async (pointer: Pointer) => {
    const response = await client.steps.setSaveLocation(snapshot.id, step.id, pointer);

    if (response.success) {
      updateFlow(() => {
        step.saveLocation = pointer;
      });
    }
  };

  return (
    <StepView step={step} styling={TextEntryStyling} isCollapsible={true} manipulateStep={manipulateStep}>
      <StepOutputsView typeName="Question" step={step} />
      <Field inputId="saveLocationId" label="Save Location" name="saveLocation" groupClass="col">
        <PointerControl
          types={[PointerType.Context, PointerType.Iteration]}
          pointer={step.saveLocation}
          update={changePointer}
          pointerFilters={[PointerControlFilters.HideStaticContextItems]}
        />
      </Field>
      <CanInterruptView ask={step} />
    </StepView>
  );
};
