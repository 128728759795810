import React, { useEffect, useState } from 'react';
import { Property } from 'csstype';
import { splitLongString } from 'ui/utils';

export type RectangleProps = {
  textColor?: Property.Color;
  backgroundColor?: Property.Color;
  text: string;
  size: number;
  policyIcon?: React.ReactNode;
  hasNoBottomRadius: boolean;
  isSelected: boolean;
};

export const Rectangle: React.FC<RectangleProps> = ({
  textColor = 'black',
  backgroundColor = 'white',
  text = '',
  size = 1,
  policyIcon,
  hasNoBottomRadius,
  isSelected
}) => {

  const [firstLine, setFirstLine] = useState<string>('');
  const [secondLine, setSecondLine] = useState<string>('');
  const shadow = isSelected ? '0 4px 8px rgba(0, 0, 0, 0.4)' : 'none';
  const border = isSelected ? '3px solid #222' : '2px solid #222';

  useEffect(() => {
    let lines = splitLongString(text, 30);
    setFirstLine(lines[0]);
    setSecondLine(lines[1]);
  }, [text])

  let additionalLine =  secondLine.length > 0 ? <><br></br>{secondLine}</> : <></>;

  const bottomRadiusValue = hasNoBottomRadius ? "0" : undefined;
  return (
    <div
      className="rectangle-container"
      style={{
        display: 'inline-block',
        margin: '8px'
      }}
    >
      <div
        className="rectangle"
        style={{
          height: 72 * size,
          width: 282 * size,
          backgroundColor,
          borderBottomLeftRadius: bottomRadiusValue,
          borderBottomRightRadius: bottomRadiusValue,
          border,
          boxShadow: shadow,
        }}
      >
        <div className="rectangle-text" style={{color: textColor}}>
          {firstLine}
          {additionalLine}
        </div>
        {policyIcon}
      </div>
    </div>
  );
};