import { Position } from '@smartaction/visuals/Types';
import { Methods } from 'internal/clients';
import { Note } from 'internal/models';
import { INoteClient } from './INoteClient';

export class NoteClient implements INoteClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }
  private getUrl(snapshotId: string, moduleId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/modules/${moduleId}/notes`;
  }

  async createAsync(
    snapshotId: string,
    moduleId: string,
    text: string,
    position: Position,
    height: number,
    width: number,
    color: string,
    textColor: string,
  ): Promise<Note | undefined> {
    const request = {
      text,
      position,
      height,
      width,
      color,
      textColor,
    };

    const response = await this.methods.post(this.getUrl(snapshotId, moduleId), request);

    const success = await this.methods.handleErrors('Creating Note', response);

    if (success) {
      return new Note(await this.methods.getId(response), text, position, height, width, color, textColor);
    }

    return undefined;
  }

  async updateSizeAsync(
    snapshotId: string,
    moduleId: string,
    noteId: string,
    height: number,
    width: number,
  ): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.bodyRequest(`${url}/${noteId}/size`, 'PUT', {
      height: Math.round(height),
      width: Math.round(width),
    });

    await this.methods.handleErrors('Updating Note Text Color', response);
  }
  async updateColorAsync(snapshotId: string, moduleId: string, noteId: string, color: string): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.plainBodyRequest(`${url}/${noteId}/color`, 'PUT', color);

    await this.methods.handleErrors('Updating Note Color', response);
  }
  async updateTextColorAsync(snapshotId: string, moduleId: string, noteId: string, color: string): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.plainBodyRequest(`${url}/${noteId}/textColor`, 'PUT', color);

    await this.methods.handleErrors('Updating Note Text Color', response);
  }

  async updateTextAsync(snapshotId: string, moduleId: string, noteId: string, text: string): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.plainBodyRequest(`${url}/${noteId}/text`, 'PUT', text);

    await this.methods.handleErrors('Updating Note Text', response);
  }

  async updatePositionAsync(snapshotId: string, moduleId: string, noteId: string, position: Position): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${noteId}/position`, {
      x: position.x,
      y: position.y,
    });

    await this.methods.handleErrors('Updating Block Position', response);
  }

  async deleteAsync(snapshotId: string, moduleId: string, noteId: string): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.delete(`${url}/${noteId}`);

    await this.methods.handleErrors('Deleting Block', response);
  }
}
