import { DataResponse, IAPIClient, Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { API } from 'internal/models';
import { PostError } from 'ui/events';
import { EndpointClient } from './EndpointClient';
import { IEndpointClient } from './IEndpointClient';

export class APIClient implements IAPIClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
    this.endpoints = new EndpointClient(serviceUrl, methods);
  }

  async getAPIsAsync(snapshotId: string): Promise<DataResponse<API[]>> {
    const url = this.getUrl(snapshotId);
    const response = await this.methods.get(url);

    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} APIs`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    if (!Array.isArray(data)) {
      const error = 'Unexpected response when retrieving Context Items';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data: data.map((c) => API.fromJson(c)),
    };
  }

  async getAPIAsync(snapshotId: string, id: string): Promise<DataResponse<API>> {
    const url = `${this.getUrl(snapshotId)}/${id}`;
    const response = await this.methods.get(url);

    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} API`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    if (!Array.isArray(data)) {
      const error = 'Unexpected response when retrieving Context Items';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data: API.fromJson(data),
    };
  }

  async createAPIAsync(snapshotId: string, name: string, description: string): Promise<DataResponse<API>> {
    const url = this.getUrl(snapshotId);

    const request = {
      name: name,
      description: description,
    };

    const response = await this.methods.post(url, request);

    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Created} API`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }

    return {
      success: true,
      data: await response.json(),
    };
  }

  async updateAPINameAsync(snapshotId: string, apiId: string, name: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${apiId}/name`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', name);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} API name`, response);
  }

  async updateAPIDescriptionAsync(snapshotId: string, apiId: string, description: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${apiId}/description`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', description);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} API description`, response);
  }

  async updateBaseUrlIdAsync(snapshotId: string, apiId: string, baseUrlId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${apiId}/baseUrlId`;
    const response = await this.methods.bodyRequest(url, 'PUT', baseUrlId);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} API baseUrlId`, response);
  }

  async deleteAPIAsync(snapshotId: string, apiId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${apiId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} API`, response);
  }

  readonly endpoints: IEndpointClient;

  private getUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/snapshot/${snapshotId}/api`;
  }
}
