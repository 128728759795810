import { BaseModel } from "@smartaction/common";
import { Endpoint } from "internal/models";

export class API extends BaseModel<API> {
    isDirty(original: API): boolean {
        throw new Error("Method not implemented.");
    }
    getDirtyFields(original: API): string[] {
        throw new Error("Method not implemented.");
    }
    clone(): API {
        throw new Error("Method not implemented.");
    }
    originalId: string;
    snapshotId: string;
    description: string;
    baseUrlId: string;
    endpoints: Endpoint[];

    constructor(id: string, originalId: string, snapshotId: string, name: string, description: string, baseUrlId: string, endpoints: Endpoint[]) {
        super(name, id);
        this.description = description;
        this.originalId = originalId;
        this.snapshotId = snapshotId;
        this.baseUrlId = baseUrlId;
        this.endpoints = endpoints;
    }

    static fromJson(json: any) {
        const endpoints = json.endpoints && json.endpoints.length > 0
            ? json.endpoints.map((e: any) => Endpoint.fromJson(e))
            : [];
        return new API(json.id, json.originalId, json.snapshotId, json.name, json.description, json.baseUrlId, endpoints);
    }
}