import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { PortalModal } from '@smartaction/visuals';
import React from 'react';
import { useCallAPI } from 'ui/components/design/scripting/useCallAPI';
import { useClient } from 'contexts';
import { EditorInterface, MonacoEditor } from 'ui/components';

export const ScriptEditor = ({ endpointId, headerText, value, save, isReadOnly }: any) => {
  const callApi = useCallAPI(endpointId);
  const client = useClient('validator');

  let interpolate = value;
  interpolate = callApi.toNames(interpolate);
  console.log(value);
  const header = {
    type: VisualCategory.Primary,
    content: (
      <>
        <Icon type={IconType.Code} /> {headerText}
      </>
    ),
  };

  const onSave = (script: string) => {
    let interpolated = script;
    interpolated = callApi.fromNames(interpolated);
    save(interpolated);
  };

  const onValidate = async (tenant: string, snapshot: string, script: string) => {
      let interpolated = script;
      interpolated = callApi.fromNames(interpolated);
      return await client.validateAPIScript(tenant, snapshot, interpolated);
  };

  const content = (
    <div className="script-modal" style={{ width: '50vw' }}>
      <MonacoEditor 
        id={endpointId} 
        isReadOnly={isReadOnly}
        value={interpolate} 
        types={[EditorInterface.ConversationState]} 
        onSave={onSave} 
        validateMethod={onValidate} />      
    </div>
  );

  return <PortalModal header={header} content={content} buttons={<React.Fragment/>} />;
};
