import { BargeIn } from './BargeIn';
import { Policy } from '../Policy';
import { PolicyType } from '../PolicyType';

export class BargeInPolicy extends Policy {
  bargeIns: Array<BargeIn>;
  enabled: boolean;
  excludeParents: boolean;
  disabled?: boolean;

  constructor(
    id: string,
    ownerId: string,
    enabled: boolean,
    excludeParents: boolean,
    bargeIns: Array<BargeIn>,
    disabled?: boolean,
  ) {
    super(id, ownerId, PolicyType.BargeIn);
    this.enabled = enabled;
    this.excludeParents = excludeParents;
    this.bargeIns = bargeIns;
    this.disabled = disabled;
  }

  static fromJson(json: any): BargeInPolicy {
    const bargeIns =
      json.bargeIns && Array.isArray(json.bargeIns) ? json.bargeIns.map((c: any) => BargeIn.fromJson(c)) : [];

    return new BargeInPolicy(json.id, json.ownerId, json.enabled, json.excludeParents, bargeIns, json.disabled);
  }
}
