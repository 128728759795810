import { Config, ConfigPointer, ContextItem, ContextPointer, Pointer } from "internal/models";

export enum ContextDateTimePointerLabelEnum {
    Date = 'Date',
    DateTime = 'DateTime',
    DayOfWeek = 'DayOfWeek',
    Time = 'Time',
  }
  
  export enum ConfigDateTimePointerLabelEnum {
    Date = 'Date',
    DayOfWeek = 'DayOfWeek',
    DayOfMonth = 'DayOfMonth',
    Time = 'Time',
  }


export const isTimeValue = (str: string) => /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(str);

export const isContextItemDateTime = (pointer: Pointer, contextItems: ContextItem[]) => {
  return Object.keys(ContextDateTimePointerLabelEnum).some((pointerLabel) => {
    return contextItems
      .find((item) => item.id === (pointer as ContextPointer).contextItemId)
      ?.type.match(pointerLabel);
  });
};

export const isConfigDateTime = (pointer: Pointer, configs: Map<string, Config>) => {
  return Object.keys(ConfigDateTimePointerLabelEnum).some((pointerLabel) => {
    let config = configs.get((pointer as ConfigPointer).configId);

    if (config) {
      return config.type.match(pointerLabel);
    }
    return false;
  });
};