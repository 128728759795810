import { TableRow } from 'ui/components';
import { useEffect, useState } from 'react';
import { ContextType, Endpoint, Field, FieldType, SelectableContextTypes } from 'internal/models';
import { useAPIs, useClient, useSnapshot } from 'contexts';
import { IEndpointClient } from 'internal/clients';
import { EnvironmentsList } from './EnvironmentsList';
import { ParameterTable } from './ParameterTable';
import { createParameterRow } from './ParameterLogic';

interface UseTableApiLogicRes {
  rows: TableRow[];
  isLoading: boolean;
  onDeleteRow: (endpointId: string, rowIndex: number) => void;
  onAddNewRow: () => void;
  onExpandAll: (expand: boolean) => void;
  onExpandRow: (endpointId: string) => void;
}

interface UseTableApiLogicProps {
  endpoints: Endpoint[];
  apiId: string;
  isDisabled: boolean;
}

export const useEndpointLogic = ({ endpoints, apiId, isDisabled }: UseTableApiLogicProps): UseTableApiLogicRes => {
  const [rows, setRows] = useState<TableRow[]>([]);
  const [expandedItemIds, setExpandedItemIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const client = useClient('apis').endpoints;
  const { snapshot } = useSnapshot();
  const apis = useAPIs();
  const loadingHandler = async (callback: any) => {
    setIsLoading(true);
    try {
      await callback();
      await apis.refresh(true);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };
  const onDeleteRow = (endpointId: string) =>
    loadingHandler(() => client.deleteEndpointAsync(snapshot.id, apiId, endpointId));
  const onAddNewRow = () => loadingHandler(() => client.createEndpointAsync(snapshot.id, apiId, 'New Endpoint', ''));

  const onUpdateEndpointValue: CreateEndpointRowProps['onUpdateEndpointValue'] = (e) =>
    // @ts-ignore client[e.clientMethod]
    loadingHandler(() => client[e.clientMethod](snapshot.id, apiId, e.endpointId, e.value));

  const onUpdateScript: CreateEndpointRowProps['onUpdateScript'] = (endpointId, environmentId, value) => {
    return loadingHandler(() => client.updateEndpointScriptAsync(snapshot.id, apiId, endpointId, environmentId, value));
  };

  const onUpdateParameterValue: CreateEndpointRowProps['onUpdateParameterValue'] = (p) => {
    // @ts-ignore client[p.clientMethod]
    return loadingHandler(() => client[p.clientMethod](snapshot.id, apiId, p.endpointId, p.parameterId, p.value));
  };

  const onCreateParameter: CreateEndpointRowProps['onCreateParameter'] = (p) => {
    return loadingHandler(() =>
      // @ts-ignore client[p.clientMethod]
      client[p.clientMethod](
        snapshot.id,
        apiId,
        p.endpointId,
        new Field('', 'New Parameter', '', FieldType.String, false),
      ),
    );
  };

  const onDeleteParameter: CreateEndpointRowProps['onDeleteParameter'] = (p) => {
    // @ts-ignore client[p.clientMethod]
    return loadingHandler(() => client[p.clientMethod](snapshot.id, apiId, p.endpointId, p.parameterId));
  };

  const onExpandAll = (expand: boolean) => {
    setExpandedItemIds(expand ? endpoints.map((endpoint) => endpoint.id) : []);
  };
  const onExpandRow = (endpointId: string) => {
    setExpandedItemIds((prev) => {
      if (prev.includes(endpointId)) {
        return prev.filter((id) => id !== endpointId);
      }
      return [...prev, endpointId];
    });
  };

  useEffect(() => {
    setRows(
      endpoints.map((endpoint, index) =>
        createEndpointRow({
          apiId,
          endpoint,
          onUpdateEndpointValue,
          onUpdateScript,
          expanded: expandedItemIds.includes(endpoint.id),
          onCreateParameter,
          onDeleteParameter,
          onUpdateParameterValue,
          isDisabled
        }),
      ),
    );
  }, [endpoints, expandedItemIds]);
  return {
    rows,
    isLoading,
    onDeleteRow,
    onAddNewRow,
    onExpandAll,
    onExpandRow,
  };
};

interface CreateEndpointRowProps {
  apiId: string;
  endpoint: Endpoint;
  onUpdateScript: (endpointId: string, environmentId: string, value: string) => Promise<void>;
  expanded: boolean;
  onUpdateParameterValue: (props: {
    clientMethod: keyof IEndpointClient;
    endpointId: string;
    parameterId: string;
    value: string | Field | boolean;
  }) => void;
  onUpdateEndpointValue: (props: { clientMethod: keyof IEndpointClient; endpointId: string; value: string }) => void;
  onCreateParameter: (props: { clientMethod: keyof IEndpointClient; endpointId: string }) => void;
  onDeleteParameter: (props: { clientMethod: keyof IEndpointClient; endpointId: string; parameterId: string }) => void;
  isDisabled: boolean;
}

const createEndpointRow = (props: CreateEndpointRowProps): TableRow => {
  const { devScript, qaScript, uatScript, prodScript } = props.endpoint;
  const endpointId = props.endpoint.id;

  const inputParameters = props.endpoint.inputs.map(parameter => {
    const parameterId = parameter.id;
    return createParameterRow({
      parameter,
      isDisabled: props.isDisabled,
      onUpdateName: (value) =>
        props.onUpdateParameterValue({
          clientMethod: 'updateInputFieldNameAsync',
          endpointId,
          parameterId,
          value,
        }),
      onUpdateDescription: (value) =>
        props.onUpdateParameterValue({
          clientMethod: 'updateInputFieldDescriptionAsync',
          endpointId,
          parameterId,
          value,
        }),
      onUpdateParameterType: (value) => {
        const isStandardType = SelectableContextTypes.includes(value as ContextType);

        const typeObj = {
          type: isStandardType ? value : ContextType.Object,
          typeId: isStandardType ? undefined : value
        };

        props.onUpdateParameterValue({
          clientMethod: 'updateInputFieldParameterTypeAsync',
          endpointId,
          parameterId,
          value: typeObj as Field,
        });
      },
      onUpdateParameterIsList: (value) => {
        props.onUpdateParameterValue({
          clientMethod: 'updateInputFieldIsListAsync',
          endpointId,
          parameterId: parameter.id,
          value: value
        })
      }
    });
  });

  const outputParameters = props.endpoint.outputs.map((parameter) => {
    return createParameterRow({
      parameter,
      isDisabled: props.isDisabled,
      onUpdateName: (value) =>
        props.onUpdateParameterValue({
          clientMethod: 'updateOutputFieldNameAsync',
          endpointId,
          parameterId: parameter.id,
          value,
        }),
      onUpdateDescription: (value) =>
        props.onUpdateParameterValue({
          clientMethod: 'updateOutputFieldDescriptionAsync',
          endpointId,
          parameterId: parameter.id,
          value,
        }),
      onUpdateParameterType: (value) => {
        const isStandardType = SelectableContextTypes.includes(value as ContextType);

        const typeObj = {
          type: isStandardType ? value : ContextType.Object,
          typeId: isStandardType ? undefined : value
        };

        props.onUpdateParameterValue({
          clientMethod: 'updateOutputFieldParameterTypeAsync',
          endpointId,
          parameterId: parameter.id,
          value: typeObj as Field,
        });
      },
      onUpdateParameterIsList: (value) => {
        props.onUpdateParameterValue({
          clientMethod: 'updateOutputFieldIsListAsync',
          endpointId,
          parameterId: parameter.id,
          value: value
        })
      }
    });
  });

  return {
    id: endpointId,
    cells: [
      {
        id: endpointId + '-name',
        type: 'input',
        value: props.endpoint.name,
        onChange: ({ value }) => props.onUpdateEndpointValue({ clientMethod: 'updateEndpointNameAsync', endpointId, value: (value as string) }),
        disabled: props.isDisabled
      },
      {
        id: endpointId + '-description',
        type: 'textarea',
        value: props.endpoint.description,
        onChange: ({ value }) =>
          props.onUpdateEndpointValue({
            clientMethod: 'updateEndpointDescriptionAsync',
            endpointId,
            value: (value as string),
          }),
        disabled: props.isDisabled
      },
      {
        id: endpointId + '-scripts',
        type: 'customNode',
        onChange: () => {},
        customNode: (
          <EnvironmentsList
            apiId={props.apiId}
            endpointId={endpointId}
            isDisabled={props.isDisabled}
            scripts={{ DEV: devScript, QA: qaScript, UAT: uatScript, PROD: prodScript }}
            onUpdateScript={(environmentId, value) => props.onUpdateScript(endpointId, environmentId, value)}
          />
        ),
      }
    ],
    subContent: (<>
      <ParameterTable
        isDisabled={props.isDisabled}
        rows={inputParameters}
        onDeleteRow={(parameterId) =>
          props.onDeleteParameter({
            clientMethod: 'deleteInputFieldAsync',
            endpointId,
            parameterId,
          })
        }
        onAddNewRow={() => props.onCreateParameter({ clientMethod: 'createInputFieldAsync', endpointId })}
        title={'Inputs'} />
        
      <ParameterTable
        isDisabled={props.isDisabled}
        rows={outputParameters}
        onDeleteRow={(parameterId) =>
          props.onDeleteParameter({
            clientMethod: 'deleteOutputFieldAsync',
            endpointId,
            parameterId,
          })
        }
        onAddNewRow={() => props.onCreateParameter({ clientMethod: 'createOutputFieldAsync', endpointId })}
        title={'Outputs'} />
    </>),
    expanded: props.expanded
  };
};
