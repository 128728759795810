import { Pointer, PointerControlFilters, PointerType } from 'internal/models';
import { PointerControl } from 'ui/components';
import React, { forwardRef, useImperativeHandle } from 'react';

interface PointerEditorProps {
  pointer: Pointer;
  allowedTypes: PointerType[];
  onChange: (pointer: Pointer) => void;
}

export const PointerEditor = forwardRef(
  ({ pointer, allowedTypes, onChange }: PointerEditorProps, ref: React.ForwardedRef<unknown>) => {
    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return pointer;
        },
        isCancelAfterEnd: () => {
          return pointer === undefined;
        },
      };
    });

    return (
      <div className="pointer-editor">
        <PointerControl
          types={allowedTypes}
          pointer={pointer}
          update={onChange}
          pointerFilters={[PointerControlFilters.HideCreateOption]}
        />
      </div>
    );
  },
);
