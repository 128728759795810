import {
  Behavior,
  BehaviorType,
  Condition,
  NoActionBehavior,
  PointerType,
  ValidatePolicy,
  ValidatePolicy_Validation,
} from 'internal/models';
import React, { useState } from 'react';
import { PolicyModalProps } from '../Types';
import { useClient, usePolicies, useSnapshot } from 'contexts';
import { Accordion, Button } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { EditableLabel } from 'ui/controls/EditableLabel';
import { arrayRemove } from '@smartaction/common';
import { BehaviorControl } from '../behaviors/BehaviorControl';
import { ConditionsTableAG } from 'ui/views/bots/design/flow/conditions/ConditionsTable-AG';

const targetAllowedTypes = [PointerType.Config, PointerType.Context];
const sourceAllowedTypes = [
  PointerType.Config,
  PointerType.Context,
  PointerType.DirectAssignment,
  PointerType.DirectAssignmentList,
];

export const ValidatePolicyView: React.FC<PolicyModalProps<ValidatePolicy>> = ({ policy, disabled }) => {
  const { updatePolicies } = usePolicies();
  const client = useClient('flow');
  const snapshot = useSnapshot();

  const updateValidation = (validation: ValidatePolicy_Validation) => {
    const validations = [...policy.validations];
    const idx = validations.findIndex((v) => v.id === validation.id);
    if (idx > -1) {
      validations[idx] = validation;
      updatePolicies(() => {
        policy.validations = validations;
      });
    }
  };

  const deleteValidation = async (validation: ValidatePolicy_Validation) => {
    const result = await client.policies.validates.deleteValidation(snapshot.snapshot.id, policy.id, validation.id);
    if (result.success) {
      const validations = [...policy.validations];
      const idx = validations.findIndex((v) => v.id === validation.id);
      if (idx > -1) {
        updatePolicies(() => {
          policy.validations = arrayRemove(policy.validations, idx);
        });
      }
    }
  };

  const createValidation = async () => {
    const res = await client.policies.validates.createValidation(snapshot.snapshot.id, policy.id, 'New Validation');
    if (res.success) {
      updatePolicies(() => {
        policy.validations = [
          ...policy.validations,
          new ValidatePolicy_Validation(res.data!, 'New Validation', [], []),
        ];
      });
    }
  };

  const content = policy.validations.length ? (
    policy.validations.map((v) => (
      <ValidationView
        validation={v}
        disabled={disabled}
        update={updateValidation}
        policyId={policy.id}
        deleteValidation={deleteValidation}
      />
    ))
  ) : (
    <label>No Validations yet</label>
  );

  return (
    <React.Fragment>
      {content}
      <Button isDisabled={disabled} type={VisualCategory.Primary} action={createValidation}>
        Create Validation
      </Button>
    </React.Fragment>
  );
};

type ValidationViewProps = {
  policyId: string;
  validation: ValidatePolicy_Validation;
  update: (validation: ValidatePolicy_Validation) => void;
  deleteValidation: (validation: ValidatePolicy_Validation) => void;
  disabled?: boolean;
};

const ValidationView: React.FC<ValidationViewProps> = ({
  policyId,
  validation,
  update,
  deleteValidation,
  disabled,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const snapshot = useSnapshot();
  const client = useClient('flow');

  const saveName = async (name: string) => {
    const result = await client.policies.validates.setValidationName(
      snapshot.snapshot.id,
      policyId,
      validation.id,
      name,
    );
    if (result.success) {
      validation.name = name;
      update(validation);
    }
    return result.success;
  };

  const addCondition = async (condition: Condition) => {
    const result = await client.policies.validates.createValidationCondition(
      snapshot.snapshot.id,
      policyId,
      validation.id,
      condition,
    );

    if (result.success) {
      condition.id = result.data!;
      validation.conditions = [...validation.conditions, condition];
      update(validation);
    }
  };

  const replaceCondition = async (condition: Condition) => {
    const result = await client.policies.validates.replaceValidationCondition(
      snapshot.snapshot.id,
      policyId,
      validation.id,
      condition.id,
      condition,
    );

    if (result.success) {
      const conditions = [...validation.conditions];
      const index = conditions.findIndex((c) => c.id === condition.id);
      conditions[index] = condition;
      validation.conditions = conditions;
      update(validation);
    }
  };

  const deleteCondition = async (conditionId: string) => {
    const result = await client.policies.validates.deleteValidationCondition(
      snapshot.snapshot.id,
      policyId,
      validation.id,
      conditionId,
    );
    if (result.success) {
      const idx = validation.conditions.findIndex((c) => c.id === conditionId);
      validation.conditions = arrayRemove(validation.conditions, idx);
      update(validation);
    }
  };

  const updateBehavior = async (behavior: Behavior, index?: number) => {
    if (index === undefined) {
      throw new Error('Unable to complete work - index was somehow not set.');
    }
    const behaviors = [...validation.behaviors];
    behaviors[index] = behavior;
    const result = await client.policies.validates.setValidationBehaviors(
      snapshot.snapshot.id,
      policyId,
      validation.id,
      behaviors,
    );
    if (result.success) {
      validation.behaviors = behaviors;
      update(validation);
    }
  };

  const addBehavior = async () => {
    const behaviors = [...validation.behaviors, new NoActionBehavior()];
    const result = await client.policies.validates.setValidationBehaviors(
      snapshot.snapshot.id,
      policyId,
      validation.id,
      behaviors,
    );
    if (result.success) {
      validation.behaviors = behaviors;
      update(validation);
    }
  };

  const deleteBehavior = async (index: number) => {
    const behaviors = arrayRemove(validation.behaviors, index);
    const result = await client.policies.validates.setValidationBehaviors(
      snapshot.snapshot.id,
      policyId,
      validation.id,
      behaviors,
    );
    if (result.success) {
      validation.behaviors = behaviors;
      update(validation);
    }
  };

  const behaviors = validation.behaviors.map((b, i) => {
    return {
      label: `Attempt ${i + 1}`,
      content: (
        <ValidationBehaviorView
          disabled={disabled}
          index={i}
          behavior={b}
          update={updateBehavior}
          deleteBehavior={deleteBehavior}
        />
      ),
      collapsed: true,
    };
  });

  const validationContents = collapsed ? (
    <React.Fragment />
  ) : (
    <div
      style={{ pointerEvents: disabled ? 'none' : 'initial' }}
      className={collapsed ? 'validation-contents' : 'validation-contents expanded'}
    >
      <ConditionsTableAG
        conditions={validation.conditions!}
        targetAllowedTypes={targetAllowedTypes}
        sourceAllowedTypes={sourceAllowedTypes}
        onAddCondition={addCondition}
        onUpdateCondition={replaceCondition}
        onDeleteCondition={deleteCondition}
      />
      <Accordion items={behaviors} />
      <Button isDisabled={disabled} type={VisualCategory.Primary} className="mt-2" action={addBehavior}>
        <Icon type={IconType.Add} /> Add Behavior
      </Button>
      <div>
        <Button
          isDisabled={disabled}
          type={VisualCategory.Danger}
          className="mt-4"
          action={() => deleteValidation(validation)}
        >
          <Icon type={IconType.Delete} /> Delete Validation
        </Button>
      </div>
    </div>
  );

  return (
    <div className="validation">
      <div className="label">
        <EditableLabel inputLabel="Validation Name" text={validation.name} save={saveName} />
        <div className="expand-button mt-1" onClick={() => setCollapsed(!collapsed)}>
          <Icon size="lg" type={collapsed ? IconType.Down : IconType.Up} />
        </div>
      </div>
      {validationContents}
    </div>
  );
};

const ValidationAllowedBehaviorTypes = [
  BehaviorType.CallFunction,
  BehaviorType.GoTo,
  BehaviorType.Skip,
  BehaviorType.RetryStep,
  BehaviorType.Talk,
];

type ValidationBehaviorProps = {
  index: number;
  behavior: Behavior;
  update: (behavior: Behavior, index: number) => void;
  deleteBehavior: (index: number) => void;
  disabled?: boolean;
};

const ValidationBehaviorView: React.FC<ValidationBehaviorProps> = ({
  index,
  behavior,
  update,
  deleteBehavior,
  disabled,
}) => {
  const save = (behavior: Behavior) => update(behavior, index);
  return (
    <>
      <BehaviorControl
        disabled={disabled}
        behavior={behavior}
        update={save}
        allowedTypes={ValidationAllowedBehaviorTypes}
      />
      <Button isDisabled={disabled} type={VisualCategory.Danger} action={() => deleteBehavior(index)}>
        <Icon type={IconType.Delete} /> Delete Behavior
      </Button>
    </>
  );
};
