import { DefaultPublisher } from '@smartaction/common';
import { ColorHexes, Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, Field, Patience, useId, useSidePanel } from '@smartaction/visuals';
import { useClient, useFlow, useModule, useSnapshot } from 'contexts';
import { Block, EmptyPointer, Pointer, PointerControlFilters, PointerType } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ModuleItemRenamedEvent } from 'ui/events/ModuleItemRenamedEvent';
import { PointerControl } from 'ui/components';

type BlockEditorProps = {
  moduleId: string;
  block: Block;
};

export const BlockEditor: React.FC<BlockEditorProps> = ({ moduleId, block }) => {
  const sidePanel = useSidePanel();
  const [name, setName] = useState(block.name);
  const [description, setDescription] = useState(block.description);
  const nameId = useId('entryPointName');
  const descriptionId = useId('entryPointDescription');
  const { updateFlow } = useFlow();
  const client = useClient('flow');
  const snapshotData = useSnapshot();
  const module = useModule(moduleId);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setName(block.name);
    setDescription(block.description);
  }, [block]);

  function saveMetadata() {
    if (name) {
      setIsSaving(true);
      client.modules.blocks
        .updateMetadataAsync(snapshotData.snapshot!.id, moduleId, block.id, name, description)
        .then((f) => {
          if (name !== block.name) {
            DefaultPublisher.publish(new ModuleItemRenamedEvent(block.id, `${module.name}: ${name}`));
          }
          updateFlow(() => {
            block.name = name;
            block.description = description;
          });
        })
        .finally(() => setIsSaving(false));
    } else {
      toast.warn('Name must be set - changes to name and description are not saved.');
    }
  }

  const close = () => {
    sidePanel.clearContents();
    sidePanel.close();
  };

  const handleUpdateListToIterate = (pointer: Pointer) =>
    client.modules.blocks.updateListToIterate(snapshotData.snapshot!.id, moduleId, block.id, pointer).then((f) => {
      updateFlow(() => {
        block.listToIterate = pointer;
      });
    });

  return (
    <Patience showPatience={isSaving}>
      <Field inputId={nameId} label="Name" name="name" groupClass="col">
        <input
          id={nameId}
          type="text"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          onBlur={saveMetadata}
        />
      </Field>
      <Field inputId={descriptionId} label="Description" name="description" groupClass="col">
        <input
          id={descriptionId}
          type="text"
          className="form-control"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          onBlur={saveMetadata}
        />
      </Field>
      <Field inputId={'listToIterate'} label="List To Iterate" name="listToIterate" groupClass="col">
        <div className="d-flex justify-content-between align-items-start">
          <PointerControl
            types={[PointerType.Context, PointerType.Config, PointerType.DirectAssignmentList]}
            pointerFilters={[
              PointerControlFilters.ListsOnly,
              PointerControlFilters.HideCreateOption,
              PointerControlFilters.MultiStringOnly,
              PointerControlFilters.ShowObjects,
            ]}
            pointer={block.listToIterate}
            update={handleUpdateListToIterate}
          />
          {block.listToIterate.type !== PointerType.Empty && (
            <button className={`btn delete`} onClick={() => handleUpdateListToIterate(new EmptyPointer())}>
              <Icon color={ColorHexes.Danger} type={IconType.Delete} />
            </button>
          )}
        </div>
      </Field>
      <Button className={'mt-3'} type={VisualCategory.Secondary} action={close}>
        Close
      </Button>
    </Patience>
  );
};
