import React from 'react';
import { BaseStepProps } from "./stepTypes";
import { Icon } from '@smartaction/styles';


export const DraggableStep: React.FC<BaseStepProps> = ({step, styling}) => {

    return (
        <div className='draggable-step'>
            <div className='icon-holder' style={ { color: styling.color }}>
                <Icon type={styling.icon} size='lg' />
            </div>
            <div className='labels'>
                <label className='stepName' style={ { color: styling.color }}>{styling.typeName}</label>
                <label>{step.name}</label>
            </div>
        </div>
    );
};