import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { SetValueAssignment } from 'internal/models';
import { ISetValuesClient } from './ISetValuesClient';

export class SetValuesClient implements ISetValuesClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private baseUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }

  async setValueAssignments(
    snapshotId: string,
    stepId: string,
    assignments: SetValueAssignment[],
  ): Promise<ServerResponse> {
    const url = `${this.baseUrl(snapshotId, stepId)}/value-assignments`;
    const response = await this.methods.put(url, assignments);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Set Value Assignments`, response);
  }
}
