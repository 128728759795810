import { DataResponse, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Methods } from 'internal/clients/Methods';
import { AzureIntent, CLUData, Environment } from 'internal/models';
import { ILanguageClient } from './ILanguageClient';
import { ILanguage } from './ILanguage';

export class LanguageClient implements ILanguageClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }

  private getUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v2/resources`;
  }

  async create(snapshotId: string, modelName: string): Promise<DataResponse<string>> {
    let response = await this.methods.post(`${this.getUrl(snapshotId)}/azure-language`, modelName);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Language Resource`,
      response,
    );
  }

  async getResources(snapshotId: string, fromServer: boolean): Promise<string[]> {
    let response = await this.methods.get(`${this.getUrl(snapshotId)}/available-language-resources`);
    return await response.json();
  }

  async getLanguages(): Promise<DataResponse<ILanguage[]>> {
    let response = await this.methods.get(`${this.serviceUrl}/languages`);
    let validation = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} Languages Resource`,
      response,
    );
    if (!validation.success) {
      return validation;
    }

    let data = await response.json();
    return {
      success: true,
      data,
    };
  }
  async getVoices(lng: string): Promise<DataResponse<string[]>> {
    let response = await this.methods.get(`${this.serviceUrl}/languages/${lng}/voices`);
    let validation = await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Get} Voices`, response);
    if (!validation.success) {
      return validation;
    }

    let data = await response.json();
    return {
      success: true,
      data,
    };
  }

  async refreshIntents(snapshotId: string, resourceId: string): Promise<DataResponse<AzureIntent[]>> {
    let response = await this.methods.patch(`${this.getUrl(snapshotId)}/${resourceId}/intents`);
    let validation = await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Get} Intents`, response);
    if (!validation.success) {
      return validation;
    }

    let data = await response.json();
    return {
      success: true,
      data,
    };
  }

  async updateModelName(snapshotId: string, resourceId: string, modelName: string): Promise<ServerResponse> {
    let response = await this.methods.patch(`${this.getUrl(snapshotId)}/${resourceId}/modelName`, modelName);
    return await this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Updated} Model Name`, response);
  }

  async testCLUModel(
    snapshotId: string,
    resourceId: string,
    environment: Environment,
    text: string,
  ): Promise<DataResponse<CLUData>> {
    let response = await this.methods.plainBodyRequest(
      `${this.getUrl(snapshotId)}/${resourceId}/${environment}/test`,
      'POST',
      text,
    );

    let validation = await this.methods.validateResponse('Testing CLU Model', response);
    if (!validation.success) {
      return validation;
    }

    let data = await response.json();
    return {
      success: true,
      data: CLUData.fromJson(data),
    };
  }
  async testCLUUniversalModel(
    snapshotId: string,
    modelName: string,
    environment: Environment,
    text: string,
  ): Promise<DataResponse<CLUData>> {
    let response = await this.methods.plainBodyRequest(
      `${this.getUrl(snapshotId)}/universal-model/${modelName}/${environment}/test`,
      'POST',
      text,
    );

    let validation = await this.methods.validateResponse('Testing CLU Model', response);
    if (!validation.success) {
      return validation;
    }

    let data = await response.json();
    return {
      success: true,
      data: CLUData.fromJson(data),
    };
  }
}
