import { useEffect, useState } from 'react';
import { useAPIs, useConfigs, useContextItems } from 'contexts';
import { Marker, Suggestion } from "@smartaction/scripteditor";
import { Config, ContextItem, Endpoint, Parameter } from 'internal/models';

export const useCallAPI = (endpointId:string) => {
  const apis = useAPIs();
  const endpoint = apis.apis.find(api => api.endpoints.find(_ => _.id === endpointId))?.endpoints.find(_ => _.id === endpointId);
  const idRegex = '\\["[a-zA-Z0-9_]+"\\]';
  const nameRegex = '\\.[a-zA-Z0-9_]+'

  const suggestions:Suggestion[] = [];
  suggestions.push({
    name: "inputs",
    suffix: '.',
    suggestions: endpoint?.inputs.map(_ => {
        return {
          name: _.name,
          description: _.description,
          suffix: ' '
        };
      })
    });

    suggestions.push({
    name: "outputs",
    suffix: '.',
    suggestions: endpoint?.outputs.map(_ => {
        return {
          name: _.name,
          description: _.description,
          suffix: ' '
        };
      })
  });

  const fromNames = (script: string):string => {
    if (endpoint) {
      script = interpolate(script, 'inputs', nameRegex, 
        new Map(endpoint.inputs.map((_) => [ _.name, _.id ])));

      script = interpolate(script, 'outputs', nameRegex, 
        new Map(endpoint.outputs.map((_) => [ _.name, _.id ])));
    }
    return script;
  }

  const toNames = (script: string) => {
    if (endpoint) {
      script = interpolate(script, 'inputs', idRegex, 
        new Map(endpoint.inputs.map((_) => [ _.id, _.name ])));

      script = interpolate(script, 'outputs', idRegex, 
        new Map(endpoint.outputs.map((_) => [ _.id, _.name ])));
    }
    return script;
  }
  
  const interpolate = (script:string, target:string, pattern:string, items:Map<string, string>) => {
    const regex = RegExp(`${target}${pattern}`, 'g');

    for (const match of script.matchAll(regex)) {
      let itemId = match[0].match(RegExp(pattern)); // get id

      if (itemId) {
        itemId = itemId[0].match(/[a-zA-Z0-9_]+/); // strip extra
      }

      if (itemId) {
        const item = items.get(itemId[0]);

        if (item) {
          let replacement = target;

          if (pattern === nameRegex) { // Check name is match
            replacement += idRegex.replace('[a-zA-Z0-9_]+', item).replaceAll('\\', ""); // to ids
          } else {
            replacement += nameRegex.replace('[a-zA-Z0-9_]+', item).replaceAll('\\', ""); // to name
          }

          script = script.replace(match[0], replacement);
        } else {
          console.error(`${itemId[0]} is not valid`);
        }
      }
    }

    return script;
  }

  const validate = (script:string):Marker[] => {
    let markers:Marker[] = [];

    for (const suggestion of suggestions) {
      markers = markers.concat(checkSuggestion(script, suggestion));
    }

    return markers;
  };

  const checkSuggestion = (script:string, target:Suggestion, prefix?:string) => {
    const regex = RegExp(`${prefix||''}${target.name}\\${target.suffix}[a-zA-Z0-9_]+`, 'g');
    let result:Marker[] = [];
    
    // TODO: optimized, this will likely be redundant
    for (const match of script.matchAll(regex)) {
      const split = match[0].split(target.suffix),
          suggestion = target.suggestions?.find(_ => _.name === split[1]); // 2nd half

      if (suggestion) { // if match, recursion
        if (suggestion.suggestions) {
          result = result.concat(checkSuggestion(script, suggestion, `${split[0]}\\${target.suffix}`));
        }
      } else {
        const index = (match.index || 0) + split[0].length + target.suffix.length,
              line = getLineNumber(script, index),
              position = index - script.lastIndexOf('\n', index); // get last newline before position
        
        result.push({
          description: `These are your options: ${target.suggestions?.map(_ => _.name).join(', ')}`,
          lineNumber: line,
          startPosition: position
        });
      }
    }
    
    return result;
  };

  const getLineNumber = (input:string, position:number):number => { // count newlines
    let line = 1;

    for (let i = 0; i < position; i++) {
      if (input[i] === '\n') {
        line++;
      }
    }

    return line;
  }

  return { suggestions, validate, toNames, fromNames };
}