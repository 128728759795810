import { DataResponse, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Methods } from 'internal/clients/Methods';
import { Container, EmptyId, Pointer, StepType } from 'internal/models';
import { ICLUClient } from './ICLUClient';

export class CLUClient implements ICLUClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }

  private getUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }

  async createIntent(
    snapshotId: string,
    stepId: string,
    resourceId?: string,
    intent?: string,
  ): Promise<DataResponse<string>> {
    let url = `${this.getUrl(snapshotId, stepId)}/intents`;

    if (resourceId && intent) {
      url += `/resources/${resourceId || EmptyId}/intent/${intent}`;
    }

    const response = await this.methods.post(url);
    return await this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Created} Intent`, response);
  }

  async updateIntent(
    snapshotId: string,
    stepId: string,
    intentId: string,
    resourceId: string,
    intent?: string,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, stepId)}/intents/${intentId}/resources/${resourceId || EmptyId}`;
    if (intent) {
      url += `/intent/${intent}`;
    }

    const response = await this.methods.put(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Intent`, response);
  }

  async deleteIntent(snapshotId: string, stepId: string, intentId: string): Promise<ServerResponse> {
    const response = await this.methods.delete(`${this.getUrl(snapshotId, stepId)}/intents/${intentId}`);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Intent`, response);
  }

  async setEntity(
    snapshotId: string,
    stepId: string,
    intentId: string,
    resourceId: string,
    entity: string,
    saveLocation: Pointer,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, stepId)}/intents/${intentId}/resources/${resourceId}/entities/${entity}`;
    const response = await this.methods.put(url, saveLocation);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Entity`, response);
  }

  async deleteEntity(snapshotId: string, stepId: string, intentId: string, entity: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/intents/${intentId}/entities/${entity}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Entity`, response);
  }

  async createStepInCLUIntent(
    snapshotId: string,
    cluStepId: string,
    intentId: string,
    type: StepType,
    name: string,
    index?: number,
  ): Promise<DataResponse<string>> {
    const request = { type, name };

    let url = `${this.getUrl(snapshotId, cluStepId)}/intents/${intentId}/steps?index=${index}`;
    const response = await this.methods.post(url, request);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Step In CLU Branch`,
      response,
    );
  }

  async moveStepToCLUIntent(
    snapshotId: string,
    cluStepId: string,
    intentId: string,
    stepId: string,
    container: Container,
    index?: number,
  ): Promise<void> {
    let url = `${this.getUrl(snapshotId, cluStepId)}/intents/${intentId}/steps/${stepId}?index=${index}`;

    const response = await this.methods.put(url, container);
    await this.methods.handleErrors(`${ValidateResponseActionEnum.Moved} Step to If Branch`, response);
  }

  async reorderSteps(snapshotId: string, stepId: string, intentId: string, stepIds: string[]): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/intents/${intentId}/steps`;
    const response = await this.methods.patch(url, stepIds);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Reordered} Step Order in CLU Intents`,
      response,
    );
  }
}
