import { Behavior } from "../behaviors";
import { BehaviorBuilder } from "../behaviors";

export class BargeIn  {

    id : string;
    name : string; 
    behavior : Behavior; 
    dtmfKey?: string;
    keywords: BargeInKeywords[];
    intents: BargeInIntents[];

    constructor(id: string, name: string,  behavior: Behavior, dtmfKey: string, 
        keywords: BargeInKeywords[], intents: BargeInIntents[]) {
        this.id = id;
        this.name = name; 
        this.behavior = behavior;
        this.dtmfKey = dtmfKey;
        this.keywords = keywords ?? [];
        this.intents = intents ?? [];
    }

    static fromJson(json: any): BargeIn {
        const behavior = BehaviorBuilder(json.behavior); 
        const keywords = json.keywords && Array.isArray(json.keywords)
            ? json.keywords.map((k: any) => BargeInKeywords.fromJson(k))
            : [];

        const intents = json.intents && Array.isArray(json.intents)
            ? json.intents.map((i: any) => BargeInIntents.fromJson(i))
            : [];
        return new BargeIn(json.id, json.name, behavior, json.dtmfKey, keywords, intents);
    }
}

export class BargeInKeywords {
    languageCode: string;
    keywords: string[];

    constructor(languageCode: string, keywords: string[]) {
        this.languageCode = languageCode;
        this.keywords = keywords;
    }

    static fromJson(json: any) {
        const keywords = json.keywords && Array.isArray(json.keywords)
            ? json.keywords as string[]
            : [];
        return new BargeInKeywords(json.languageCode, keywords);
    }
}

export class BargeInIntents {
    resourceId: string;
    intent: string;

    constructor(resourceId: string, intent: string) {
        this.resourceId = resourceId;
        this.intent = intent;
    }

    static fromJson(json: any) {
        return new BargeInIntents(json.resourceId, json.intent);
    }
}

