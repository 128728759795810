import { BehaviorType } from "./BehaviorType";
import { Behavior } from "./Behavior";
import { OutputMessage } from "../../shared/OutputMessage";


export class RetryStepBehavior extends Behavior {
    output: OutputMessage[];

    constructor(output: OutputMessage[]) {
        super(BehaviorType.RetryStep);
        this.output = output;
    }

    static fromJson(json: any): RetryStepBehavior {
        const output = json.output && Array.isArray(json.output)
            ? json.output.map((p: any) => OutputMessage.fromJson(p))
            : [];
        return new RetryStepBehavior(output);
    }
}
