import { Step } from "../Step";
import { StepType } from "../Type";

export class SetLanguage extends Step {
    languageCode: string;

    constructor(id: string | undefined, name: string, description: string, tags: string[], languageCode: string) {
        super(id, name, description, StepType.SetLanguage, tags);
        this.languageCode = languageCode;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): SetLanguage {
        return new SetLanguage(json.id, json.name, json.description, json.tags, json.languageCode);

    }

}