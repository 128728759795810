import { Icon, IconType, VisualCategory } from "@smartaction/styles";
import { Button, Select } from "@smartaction/visuals";
import { useLanguages } from "contexts";
import React, { useEffect, useState } from "react";

type LanguageStringPairsManagerProps = {
  availableLanguages?: string[];
  initialLanguageStringPairs: Map<string, string>;
  save: (updatedLanguageStringPairs: Map<string, string>) => void;
};
  
export const MultiLingualCellEditor: React.FC<LanguageStringPairsManagerProps> = ({
  availableLanguages,
  initialLanguageStringPairs,
  save
}) => {
  // The hook is not going to be happy if we call it conditionally, so we always call it, but maybe replace it with the provided languages
  let languages =  useLanguages().languageCodes;
  if (availableLanguages) {
    languages = availableLanguages;
  }
  const [languageStringPairs, setLanguageStringPairs] = useState<Map<string, string>>(initialLanguageStringPairs);
  const [selectedLanguageCode, setSelectedLanguageCode] = useState<string>('');

  const updateLanguageStringPair = (language: string, value: string | undefined) => {
    const updatedPairs = new Map(languageStringPairs);
    if (value === undefined) {
      updatedPairs.delete(language);
    } else {
      updatedPairs.set(language, value);
    }
    setLanguageStringPairs(updatedPairs);
    save(updatedPairs);
  };

  const handleAddLanguage = (newLanguageCode: string) => {
    if (!languageStringPairs.has(newLanguageCode)) {
      const updatedPairs = new Map(languageStringPairs).set(newLanguageCode, '');
      setLanguageStringPairs(updatedPairs);
      save(updatedPairs);
    }

    setSelectedLanguageCode('');
  };

  const unusedLanguages = languages.filter(langCode => !languageStringPairs.has(langCode));
  const languageOptions = unusedLanguages.map(langCode => ({
    label: langCode,
    value: langCode,
  }));

  const DEFAULT_LANGUAGE_LABEL = { label: "Add a language", value: "" };
  return (
    <div>
      {Array.from(languageStringPairs.keys()).map((language, i) => (
        <IndividualStringField
          index={i * 2}
          key={language}
          language={language}
          value={languageStringPairs.get(language) || ''}
          update={updateLanguageStringPair}
        />
      ))}
      <Select
        id="language-select"
        tabIndex={10000}
        value={selectedLanguageCode}
        onChange={handleAddLanguage}
        options={[
          DEFAULT_LANGUAGE_LABEL,
          ...languageOptions
        ]}
      />
    </div>
  );
};
  
type IndividualStringFieldProps = {
  index: number
  language: string;
  value: string | undefined;
  update: (language: string, value: string | undefined) => void;
};
  
const IndividualStringField: React.FC<IndividualStringFieldProps> = ({ index, language, value, update }) => {
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  const save = (newValue: string) => {
    update(language, newValue);
  };

  const handleDelete = () => {
    // Pass undefined to indicate that this language should be removed.
    update(language, undefined);
  };

  return (
    <div className="form-control" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div style={{ flex: 1, marginRight: '8px' }}>
        <label>{language}:</label>
        <input
          onKeyDownCapture={(evt) => {
            if (evt.key === 'Tab') {
              evt.stopPropagation();
            }
          }}
          id={language + "-language-value"}
          type="text"
          value={val}
          onChange={(evt) => save(evt.target.value)}
          onBlur={(evt) => save(evt.target.value)}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
              save(evt.currentTarget.value);
            }
          }}
          tabIndex={index + 1}
          style={{ width: '100%' }}
        />
      </div>
      <Button type={VisualCategory.Danger} action={handleDelete}>
        <Icon type={IconType.Delete} />
      </Button>
    </div>
  );
};
