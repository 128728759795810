import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Location } from '../../../../../internal/models';
import { ICellEditorParams } from 'ag-grid-community';
import { MultiLingualCellEditor } from 'ui/controls/grid/editors/MultiLingualStringEditor';

export const PronunciationsFieldEditor = forwardRef(
  (props: ICellEditorParams<Location, Map<string, string>>, ref: React.ForwardedRef<unknown>) => {
    
    const [value, setValue] = useState<Map<string, string>>(props.value ?? new Map<string, string>());
    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value;
        },
        isCancelAfterEnd: () => {
          return false;
        },
      };
    });

    if (!props.value) {
      return <React.Fragment />;
    }

    const save = (updatedMap: Map<string, string>) => {
      setValue(new Map(updatedMap));
    };

    return (
      <div className={'location-custom-field-editor'}>
        <MultiLingualCellEditor initialLanguageStringPairs={value!}
          save={save} />
      </div>
    );
  },
);