import { SetLanguage } from 'internal/models';
import React from 'react';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { useBots, useClient, useFlow, useProperties, useSnapshot } from 'contexts';
import { IconType } from '@smartaction/styles';
import { ProcessStepsColors } from '../../StepColorGroups';
import Select from 'react-select';
import { Option } from 'ui/types';

export const SetLanguageStyling: StepStyling = {
    typeName: "Set Language",
    icon: IconType.Language,
    ...ProcessStepsColors,
}

export const SetLanguageStep: React.FC<TypedStepProps<SetLanguage>> = ({ step, manipulateStep }) => {
    const { snapshot } = useSnapshot();
    const { updateFlow } = useFlow();
    const { isReadOnlyBot} = useBots();
    const client = useClient('flow');
    const { languageModel } = useProperties();

    const setLanguage = async (language?: string) => {
        if (!language) {
            return;
        }

        const result = await client.steps.setLanguages.setLanguage(snapshot.id, step.id, language);
        if (result.success) {
            updateFlow(() => {
                step.languageCode = language;
            })
        }
    }

    if (!languageModel) {
        return <React.Fragment/>;
    }

    const options: Option[] = [{ label: "-- Select --", value: ""}, ...languageModel.supportedLanguages.map(o => { return { label: o.languageCode, value: o.languageCode } })];
    const selectedOption = options.find(o => o.value === step.languageCode);
    
    return (
        <StepView step={step} styling={SetLanguageStyling} manipulateStep={manipulateStep}>
            <Select isDisabled={isReadOnlyBot} options={options} value={selectedOption} onChange={(o) => setLanguage(o?.value)} />
        </StepView>
    );
}