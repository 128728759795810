import { Properties, PropertiesDefaultTimeoutsType, PropertiesLanguagesType, GeneralType } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { useClient } from '../ClientContext';
import { useSnapshot } from 'contexts/SnapshotContext';
import { useTenant } from '../TenantContext';
import { ILanguage } from '../../internal/clients/design/resources/ILanguage';

const PropertiesContext = React.createContext<PropertiesContextType | undefined>(undefined);

type TypeOptions = {
  value: string;
  label: string;
  voices?: {
    value: string;
    label: string;
  }[];
};
type PropertiesContextType = {
  languageModel?: PropertiesLanguagesType;
  defaultTimeouts?: PropertiesDefaultTimeoutsType;
  general?: GeneralType;
  refresh: (fromServer?: boolean) => void;
  getLanguageOptions: () => void;
  languageOptions?: TypeOptions[];
};

type PropertiesProviderProps = {
  children: React.ReactNode
}

export const PropertiesProvider: React.FC<PropertiesProviderProps> = ({ children }) => {
  const snapshot = useSnapshot();
  const tenant = useTenant();
  const [properties, setProperties] = useState<Properties>();
  const [languageOptions, setLanguageOptions] = useState<TypeOptions[]>();
  const [timezone, setTimezoneOptions] = useState<TypeOptions[]>();

  const client = useClient('properties');
  const resourceClient = useClient('resources');

  useEffect(() => {
    refresh(true);
    getLanguageOptions();
  }, [snapshot]);

  const refresh = (fromServer?: boolean) => {
    if (fromServer && tenant?.id) {
      client.getProperties(snapshot.snapshot.id, tenant?.id).then((res) => {
        if (res.success && res.data) {
          setProperties(res.data);
        }
      });
    } else {
      setProperties(properties);
    }
  };
  const getLanguageOptions = () => {
    resourceClient.languages.getLanguages().then((res) => {
      if (res.success) {
        setLanguageOptions(
          res.data?.map((lng: ILanguage) => ({
            label: lng.code,
            value: lng.code,
            voices: lng.voices.map((voice) => ({
              label: voice.code,
              value: voice.code,
            })),
          })),
        );
      }
    });
  };

  return (
    <PropertiesContext.Provider value={{ ...properties, refresh, getLanguageOptions, languageOptions }}>
      {children}
    </PropertiesContext.Provider>
  );
};
export const useProperties = () => {
  const context = React.useContext(PropertiesContext);
  if (context === undefined) {
    throw new Error('useProperties must be used within an PropertiesProvider!');
  }
  return context;
};
