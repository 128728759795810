import { ColorHexes, Icon, IconType } from '@smartaction/styles';
import { PortalModal, usePortalModal } from '@smartaction/visuals';
import { ICellRendererParams } from 'ag-grid-community';
import { useClient, useConfigs, useContextItems, useFlow, useSnapshot } from 'contexts';
import { CLU, CompareCondition, CompareType, Condition, ConditionType, DateTimeCondition, DateTimePointerValueType, DirectAssignmentDateTimePointer, EmptyPointer, If, PointerType, StepType, Summary } from 'internal/models';
import React, {  } from 'react';
import { useJSONModal } from 'ui/components/JSONModal';
import PointerTableCellRenderer from 'ui/controls/table/PointerTableCellRenderer';
import { splitLongStringInAPrettyWay } from 'ui/utils';
import { isConfigDateTime, isContextItemDateTime } from './HelperConditionMethods';
import ConditionCheckRenderer from './ConditionCheckRenderer';



type CheckRendererProps = ICellRendererParams<CompareCondition> & {
    targetAllowedTypes: Array<PointerType>;
    onUpdateCondition: (condition: Condition) => void;
}


export const CheckRenderer: React.FC<CheckRendererProps> = ({colDef, value, data, onUpdateCondition}) => {
    const condition = data as CompareCondition;
    const snapshot = useSnapshot();
    const flow = useFlow();

    const configs = useConfigs();
    const contextItems = useContextItems();

    let isSourceOfTypeDateTime = false;
    let isTargetOfTimeDateTime = false;
  
    if (condition?.value) {
      if (condition.value.type === PointerType.DirectAssignmentDateTime) {
        const valuePointer = condition?.value as DirectAssignmentDateTimePointer;
        isSourceOfTypeDateTime =
          valuePointer.value.type === DateTimePointerValueType.DateTimeValue ||
          valuePointer.value.type === DateTimePointerValueType.TimeValue;
      } else if (condition.value.type === PointerType.Config) {
        isSourceOfTypeDateTime = isConfigDateTime(condition.value, configs.all);
      } else if (condition.value.type === PointerType.Context) {
        isSourceOfTypeDateTime = isContextItemDateTime(condition.value, contextItems.contextItems);
      }
    }
  
    if (condition.target.type === PointerType.Config) {
      isTargetOfTimeDateTime = isConfigDateTime(condition.target, configs.all);
    } else if (condition.target.type === PointerType.Context) {
      isTargetOfTimeDateTime = isContextItemDateTime(condition.target, contextItems.contextItems);
    }
  
    //Depending on value will determine the list of options that will be
    //available for working with types with times for both source/target
    const isConditionTargetAndSourceOfTypeDateTime = isTargetOfTimeDateTime && isSourceOfTypeDateTime;
  
    return (
        
        <ConditionCheckRenderer
        isConditionTargetTypeDateTime={isTargetOfTimeDateTime}
        isConditionSourceOfTypeDateTime={isSourceOfTypeDateTime}
        isConditionTargetAndSourceTypeTime={isConditionTargetAndSourceOfTypeDateTime}
        condition={condition}
        onUpdateCondition={onUpdateCondition}
      />
    );

}
