import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Pointer } from 'internal/models';
import { ISendSMSClient } from './ISendSMSClient';

export class SendSMSClient implements ISendSMSClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async setToNumber(snapshotId: string, stepId: string, toNumber: Pointer): Promise<ServerResponse> {
    const url = `${this.url(snapshotId, stepId)}/to-number`;
    const response = await this.methods.put(url, toNumber);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Updated} Send SMS ToNumber`,
      response,
    );
  }

  async setFromNumber(snapshotId: string, stepId: string, fromNumber: Pointer): Promise<ServerResponse> {
    const url = `${this.url(snapshotId, stepId)}/from-number`;
    const response = await this.methods.put(url, fromNumber);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Updated} Send SMS FromNumber`,
      response,
    );
  }

  private url(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }
}
