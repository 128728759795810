import { ServiceUrls } from "./ServiceUrls";

export class AppConfig {
    serviceUrls: ServiceUrls;

    constructor(serviceUrls: ServiceUrls) {
        this.serviceUrls = serviceUrls;
    }
}

