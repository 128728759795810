import React, { useMemo } from 'react';
import Cell, { CellData, CellEmpty } from './Cell';
import { Button, Patience } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import './Table.scss';

export type TableRow = {
  cells: Array<CellData | null>;
  id: string;
  subContent?: React.ReactNode;
  expanded?: boolean;
};

export interface TableProps {
  columnsSize?: Array<undefined | 'xs' | 'sm' | 'md' | number>;
  id: string;
  footer?: React.ReactNode;
  headersGroup: string[];
  rows: TableRow[];
  onDeleteRow?: (id: string, rowIndex: number, row?: TableRow) => void;
  isLoading?: boolean;
  onExpandAll?: (expand: boolean) => void;
  onExpandRow?: (id: string) => void;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const Table: React.FC<TableProps> = ({
  headersGroup,
  rows,
  id,
  columnsSize,
  footer,
  onDeleteRow,
  isLoading,
  onExpandAll,
  onExpandRow,
  title,
  className,
  style,
}) => {
  const showHeaderExpandButton = useMemo(() => rows.some((row) => row.subContent), [rows]);
  const headerExpandButtonIsActive = useMemo(() => showHeaderExpandButton && rows.some((row) => row.expanded), [rows]);
  return (
    <div className={`sa-table ${className || ''}`} style={style}>
      {title && <div className="sa-table-title">{title}</div>}
      <div className="sa-table-body">
        <div className={`sa-table-row sa-table-header ${showHeaderExpandButton ? 'p-0' : ''}`}>
          {showHeaderExpandButton && (
            <Button
              type={VisualCategory.Light}
              className={`sa-table-expand-btn ${headerExpandButtonIsActive ? 'is--expanded' : ''}`}
              action={() => onExpandAll?.(!headerExpandButtonIsActive)}
            >
              <Icon type={IconType.Down} />
            </Button>
          )}
          {headersGroup.map((label, index) => {
            return (
              <div
                key={`${id}-header-${label}`}
                className={`sa-table-cell ${
                  typeof columnsSize?.[index] === 'string' ? `size--${columnsSize[index]}` : ''
                }`}
                style={{ flex: typeof columnsSize?.[index] === 'number' ? `0 0 ${columnsSize?.[index]}px` : undefined }}
              >
                {label}
              </div>
            );
          })}
        </div>
        <Patience showPatience={!!isLoading}>
          {rows.map((row, rowIndex) => {
            return (
              <>
                <div key={`${id}-${row.id}`} className="sa-table-row">
                  {row.subContent && (
                    <Button
                      type={VisualCategory.Light}
                      className={`sa-table-expand-btn ${row.expanded ? 'is--expanded' : ''}`}
                      action={() => onExpandRow?.(row.id)}
                    >
                      <Icon type={IconType.Down} />
                    </Button>
                  )}
                  {row.cells.map((cell, cellIndex) => {
                    if (!cell) {
                      return <CellEmpty size={columnsSize?.[cellIndex]} key={`${id}-${row.id}-${cellIndex}`} />;
                    }
                    const idCell = `${id}-${row.id}-${cell.id}`;
                    return (
                      <Cell
                        key={idCell}
                        id={idCell}
                        disabled={cell.disabled}
                        size={columnsSize?.[cellIndex]}
                        type={cell.type}
                        value={cell.value}
                        selectOptions={cell.selectOptions}
                        onChange={cell.onChange}
                        rowId={row.id}
                        customNode={cell.customNode}
                      />
                    );
                  })}
                  {onDeleteRow && (
                    <Button
                      action={() => onDeleteRow(row.id, rowIndex)}
                      type={VisualCategory.Light}
                      className={'sa-table-row-btn-delete'}
                    >
                      <Icon type={IconType.Delete} color="#F62D36" />
                    </Button>
                  )}
                </div>
                {row.subContent && row.expanded && <div className="sa-table-sub-content">{row.subContent}</div>}
              </>
            );
          })}
        </Patience>
      </div>
      {footer && <div className="sa-table-footer">{footer}</div>}
    </div>
  );
};
