import { AddressInput, CLU, DTMFMenu, ReceiveMedia, NumericInput, TextEntry, YesNo } from './input';
import { CallFunction, GoTo, Increment, Script, SetLanguage, SetLocation, TriggerAgent, WaitForAgent } from './process';
import { ForEach, If, While } from './conditional';
import { Say, SendSMS } from './output';
import { StepType } from './Type';
import { PhoneTransfer, Hangup, Break } from './finish';
import { CallAPI, DefineObject, SetValues } from './data';
import { UnknownStep } from './Unknown';

export function StepBuilder(json: any) {
  switch (json.type) {
    // input
    case StepType.CLU:
      return CLU.fromJson(json);
    case StepType.DTMFMenu:
      return DTMFMenu.fromJson(json);
    case StepType.NumericInput:
      return NumericInput.fromJson(json);
    case StepType.TextEntry:
      return TextEntry.fromJson(json);
    case StepType.YesNo:
      return YesNo.fromJson(json);
    case StepType.AddressInput:
      return AddressInput.fromJson(json);
    case StepType.ReceiveMedia:
      return ReceiveMedia.fromJson(json);

    // output
    case StepType.Say:
      return Say.fromJson(json);
    case StepType.SendSMS:
      return SendSMS.fromJson(json);

    // conditional
    case StepType.If:
      return If.fromJson(json);
    case StepType.While:
      return While.fromJson(json);

    // data
    case StepType.CallAPI:
      return CallAPI.fromJson(json);
    case StepType.DefineObject:
      return DefineObject.fromJson(json);
    case StepType.SetValues:
      return SetValues.fromJson(json);

    // process
    case StepType.Script:
      return Script.fromJson(json);
    case StepType.GoTo:
      return GoTo.fromJson(json);
    case StepType.CallFunction:
      return CallFunction.fromJson(json);
    case StepType.Increment:
      return Increment.fromJson(json);
    case StepType.SetLanguage:
      return SetLanguage.fromJson(json);
    case StepType.SetLocation:
      return SetLocation.fromJson(json);
    case StepType.TriggerAgent:
      return TriggerAgent.fromJson(json);
    case StepType.WaitForAgent:
      return WaitForAgent.fromJson(json);
    case StepType.Break:
      return Break.fromJson(json);

    // finish
    case StepType.PhoneTransfer:
      return PhoneTransfer.fromJson(json);
    case StepType.Hangup:
      return Hangup.fromJson(json);

    default:
      return UnknownStep.fromJson(json);
  }
}
