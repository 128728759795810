import React, { useState } from 'react';
import { Button, ModalContainerIds, PortalConfirm, usePortalModal } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Table, TableRow } from 'ui/components';
import { useBots, useClient, useResources, useSnapshot } from 'contexts';
import { ResourceItemType } from '../../ResourceManager';
import { v4 as uuid } from 'uuid';
import { AuthModal } from '../../AuthModal';
import { AddTwilioResourceModal } from './AddTwilioResourceModal';

export const TwilioResourceType: React.FC<ResourceItemType> = ({
  resources,
  onChangeResourceItemName,
  onDeleteResourceItem,
}) => {
  const client = useClient('resources');
  const snapshot = useSnapshot();
  const { isReadOnlyBot } = useBots();
  const { canManage } = useResources();
  const confirm = usePortalModal(ModalContainerIds.Modal);
  const isEditable = !isReadOnlyBot && canManage;
  const [isLoadingNumbers, setIsLoadingNumbers] = useState(false);
  const modal = usePortalModal();

  const handleCreateResource = () => modal.openModal(<AddTwilioResourceModal />);

  const initialRowsData = resources
    ? resources.map((resource, index) => {
        const onServiceQueuePhoneNumbersOpen = async () => {
          setIsLoadingNumbers(true);
          await client.twilio.refreshMessageServices(snapshot.snapshot.id, resource.id).then((rec) =>
            confirm.openModal(
              <PortalConfirm
                header="Service Queues"
                content={
                  <React.Fragment>
                    <ul>
                      {rec.map((serviceQueueNumber) => (
                        <li key={serviceQueueNumber.friendlyName}>
                          {serviceQueueNumber.friendlyName}
                          <br />
                          <ul>
                            {serviceQueueNumber.phoneNumbers.map((phones) => (
                              <li>{phones.friendlyNumber}</li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </React.Fragment>
                }
                confirmButton={{
                  label: <React.Fragment>Ok</React.Fragment>,
                  type: VisualCategory.Success,
                  clicked: async () => {},
                }}
                cancelButton={{ label: 'Cancel', type: VisualCategory.Light, clicked: () => {} }}
              />,
            ),
          );
          setIsLoadingNumbers(false);
        };
        const onAuthOpen = () => resource?.id && confirm.openModal(<AuthModal id={resource.id} />);

        return {
          id: uuid(),
          cells: [
            {
              type: 'input',
              disabled: !isEditable,
              value: resource.name,
              id: `${resource.type}-name-${index}`,
              onChange: ({ value }) => onChangeResourceItemName(resource.id, `${value}`),
            },
            {
              id: `${resource.type}-props-${index}`,
              type: 'customNode',
              customNode: (
                <>
                  <div className={'d-flex justify-content-between'}>
                    <Button action={onAuthOpen} type={VisualCategory.Light} className="btn-sm me-2">
                      Auth
                    </Button>
                    <Button action={onServiceQueuePhoneNumbersOpen} type={VisualCategory.Light} className="btn-sm">
                      {isLoadingNumbers ? 'Loading queue' : ' Service Queues'}
                    </Button>
                  </div>
                </>
              ),
            },
            {
              id: `${resource.type}-action-${index}`,
              type: 'customNode',
              customNode: (
                <>
                  <div className={'d-flex justify-content-between'}>
                    <Button
                      action={async () => await client.twilio.refreshMessageServices(snapshot.snapshot.id, resource.id)}
                      type={VisualCategory.Info}
                      className="btn-sm me-2"
                    >
                      <Icon type={IconType.Import} />
                    </Button>
                  </div>
                </>
              ),
            },
          ],
        } as TableRow;
      })
    : [];

  return (
    <div>
      <Table
        id={'twilioResourceType'}
        headersGroup={['Name', 'Properties', 'Actions']}
        columnsSize={['md', 400, 'sm']}
        rows={initialRowsData}
        onDeleteRow={(id: string) => onDeleteResourceItem(id)}
        footer={
          <Button isDisabled={!isEditable} action={handleCreateResource} type={VisualCategory.Primary}>
            Create
          </Button>
        }
      />
      {confirm.modal}
      {modal.modal}
    </div>
  );
};
