import React, { useEffect, useState } from 'react';
import { useClient } from '../ClientContext';
import { useTenant } from '../TenantContext';
import { LocationDefinition } from 'internal/models';

const LocationDefinitionsContext = React.createContext<LocationDefinitionsContextType | undefined>(undefined);
const LocationDefinitionContext = React.createContext<LocationDefinitionContextType | undefined>(undefined);

type LocationDefinitionsContextType = {
  map: Map<string, LocationDefinition>;
  locationDefinitions: LocationDefinition[];
  refresh: () => void;
  commonLocationFieldIds: { [key: string]: string };
};

type LocationDefinitionContextType = {
  locationDefinition: LocationDefinition;
};

type LocationDefinitionsProviderProps = {
  children: React.ReactElement;
}

export const LocationDefinitionsProvider: React.FC<LocationDefinitionsProviderProps> = ({ children }) => {
  const tenant = useTenant();
  const [locationDefinitions, setLocationDefinitions] = useState<LocationDefinition[]>([]);
  const client = useClient('locationDefinition');

  const commonLocationFieldIds = {
    Name: '10ca00000000000000000001',
    CustomerId: '10ca00000000000000000002',
    Address: '10ca00000000000000000003',
    PhoneNumber: '10ca00000000000000000004',
    Hours: '10ca00000000000000000005',
  };

  useEffect(() => {
    if (tenant?.id) {
      refresh().then((defs) => {
        if (defs.length === 0) {
          client.create('Default').then((res) => {
            if (res.success && res.data) {
              refresh();
            }
          });
        }
      });
    }
  }, [tenant?.id]);

  const map = new Map<string, LocationDefinition>();

  for (const locationDefinition of locationDefinitions) {
    map.set(locationDefinition.id, locationDefinition);
  }

  const refresh = async () => {
    const response = await client.list();
    const currentLocationDefinitions = response.success && response.data ? response.data : [];
    if (response.success) {
      setLocationDefinitions(currentLocationDefinitions);
    }

    return currentLocationDefinitions;
  };

  const data = { locationDefinitions, map, refresh, commonLocationFieldIds };

  if (locationDefinitions.length === 0) {
    return <React.Fragment />;
  }

  return <LocationDefinitionsContext.Provider value={data}>{children}</LocationDefinitionsContext.Provider>;
};

export const useLocationDefinitions = () => {
  const context = React.useContext(LocationDefinitionsContext);
  if (context === undefined) {
    throw new Error('useLocationDefinitions must be used within a LocationDefinitionProvider!');
  }
  return context;
};

type LocationDefinitionProviderProps = {
  name: string;
  children: React.ReactElement;
};

export const LocationDefinitionProvider: React.FC<LocationDefinitionProviderProps> = ({ name, children }) => {
  const { locationDefinitions } = useLocationDefinitions();
  const locationDefinition = locationDefinitions.find((d) => d.name === name);

  if (!locationDefinition) {
    return <React.Fragment />;
  }

  return (
    <LocationDefinitionContext.Provider value={{ locationDefinition }}>{children}</LocationDefinitionContext.Provider>
  );
};

export const useLocationDefinition = () => {
  const context = React.useContext(LocationDefinitionContext);

  if (context === undefined) {
    throw new Error('useLocationDefinition must be used within a LocationDefinitionProvider!');
  }

  return context.locationDefinition;
};
