import { ICellRendererParams } from 'ag-grid-community';
import { BaseModel } from '@smartaction/common';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';


export const LinkRenderer = <T extends BaseModel<T>>(params: ICellRendererParams) => {
    const { value, data } = params;
    const location = useLocation();
    const model = data as T;
    return <NavLink to={`${location.pathname}/${model.id}`}>{value}</NavLink>;
}
