import React, { useEffect, useState } from 'react';
import { useClient } from '../ClientContext';
import { useTenant } from '../TenantContext';
import { Location } from 'internal/models';
import { useLocationDefinitions } from './LocationDefinitonContext';

const LocationContext = React.createContext<LocationContextType | undefined>(undefined);

type LocationContextType = {
  map: Map<string, Location>;
  locations: Location[];
  refresh: () => void;
};

type LocationProviderProps = {
  children: React.ReactNode
}

export const LocationProvider: React.FC<LocationProviderProps> = ({ children }) => {
  const tenant = useTenant();
  const [locations, setLocations] = useState<Location[]>([]);
  const [map, setLocationMap] = useState<Map<string, Location>>(new Map<string, Location>());
  const locationDefinitions = useLocationDefinitions();
  const client = useClient('location');

  useEffect(() => {
    if (!locationDefinitions.locationDefinitions) {
      return;
    }
    refresh();
  }, [locationDefinitions.locationDefinitions]);

  const refresh = async () => {
    const res = await client.list();
    if (res.success && res.data) {
      setLocations(res.data);
      const map = new Map<string, Location>();
      for (const location of res.data) {
        map.set(location.id, location);
      }
      setLocationMap(map);
    }
  };
  const data = { map, locations, refresh };

  return <LocationContext.Provider value={data}>{children}</LocationContext.Provider>;
};

export const useLocations = () => {
  const context = React.useContext(LocationContext);
  if (context === undefined) {
    throw new Error('useLocation must be used within an LocationProvider!');
  }
  return context;
};
