import { EmptyPointer, Pointer } from "internal/models";
import { Step, StepType } from '..';

export class PhoneTransfer extends Step {
    number: Pointer;
    isSIP: boolean;
    headers: PhoneTransferHeader[];

    constructor(id: string | undefined, name: string, description: string, tags: string[], isSIP: boolean, number: Pointer, headers: PhoneTransferHeader[]) {
        super(id, name, description, StepType.PhoneTransfer, tags);
        this.isSIP = isSIP;
        this.number = number;
        this.headers = headers;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): PhoneTransfer {

        const headers = json.headers && Array.isArray(json.headers)
            ? json.headers.map((h: any) => PhoneTransferHeader.fromJson(h))
            : [];

        const number = json.number 
            ? Pointer.fromJson(json.number)
            : new EmptyPointer();
        return new PhoneTransfer(json.id, json.name, json.description, json.tags, json.isSIP ?? false, number, headers);
    }

}

export class PhoneTransferHeader {
    id: string;
    name: string;
    source: Pointer;

    constructor(id: string, name: string, source: Pointer) {
        this.id = id;
        this.name = name;
        this.source = source;
    }

    static fromJson(json: any): PhoneTransferHeader {
        return new PhoneTransferHeader(json.id, json.name, Pointer.fromJson(json.source));
    }
}