import { PortalModal, usePortalModal } from '@smartaction/visuals';
import React from 'react';
import JSONPretty from 'react-json-pretty';

export type JSONDataType = {
    title: string,
    data: string,
    showLinks?: boolean
}

export type JSONModalType = {
    open: (title: string, jsonData: string) => void,
    openMultiples: (title: string, jsonData: JSONDataType[]) => void,
}

export const useJSONModal = () => {
    const modal = usePortalModal();
    const openAction = (title: string, jsonData: string) => {
        const json = JSON.parse(jsonData);
        const jsonPretty = <JSONPretty data={json} />;
        modal.openModal(<PortalModal className="Summary" content={jsonPretty} header={title}></PortalModal>);
    };
    const openMultiples = (title: string, jsonData: JSONDataType[]) => {
        modal.openModal(<PortalModal className='Summary' content={<JSONViewer data={jsonData} />} header={title} />);
    }
    return { open: openAction, openMultiples, modal: modal.modal, close: modal.closeModal };
}

type JSONDataViewerProps = {
    data: JSONDataType[]
};

const JSONViewer: React.FC<JSONDataViewerProps> = ({data}) => {
    const expression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);

    const elements = data.map(d => (
        <React.Fragment>
            <h3>{d.title}</h3>
            {d.showLinks && 
                d.data.match(regex)?.map(l => 
                    <>
                        <a href={l} target='_blank'>{l}</a>
                    </>
            )}
            <JSONPretty data={JSON.parse(d.data)} />
        </React.Fragment>
    ));

    return <React.Fragment>{elements}</React.Fragment>;
}