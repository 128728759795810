import React, { useState, useEffect } from 'react';
import { Monaco, Suggestion, Marker } from '@smartaction/scripteditor';
import { useConversationState } from './useConversationState';
import { Button } from '@smartaction/visuals';
import { VisualCategory } from '@smartaction/styles';
import { useBots, useClient, useSnapshot, useTenant } from 'contexts';
import { ValidatorScriptTooltip } from 'ui/components';
import { DataResponse } from 'internal/clients';

// DEPRECATED

type MonacoEditorProps = {
  id: string;
  types: EditorInterface[];
  value: string;
  onChange?: (value: string) => void;
  validateMethod: (tenantId: string, snapshotId: string, script: string) => Promise<DataResponse<Marker[]>>;
  onSave?: (value: string) => void;
  isReadOnly: boolean;
};

export enum EditorInterface {
  ConversationState,
  ScriptValidation,
}

export const MonacoEditor: React.FC<MonacoEditorProps> = ({
  id,
  value,
  types,
  onChange,
  validateMethod,
  onSave,
  isReadOnly,
}) => {
  const conversationState = useConversationState();
  const tenant = useTenant();
  const snapshot = useSnapshot();

  // initialize
  if (types.indexOf(EditorInterface.ConversationState) >= 0) {
    // contains
    value = conversationState.toNames(value);
  }

  const [script, setScript] = useState(value);
  const [isValidating, setIsValidating] = useState(false);

  const [errors, setErrors] = useState<Marker[]>([]);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  useEffect(() => {
    let suggestions: Suggestion[] = [];

    if (types.indexOf(EditorInterface.ConversationState) >= 0) {
      // contains
      suggestions = suggestions.concat(conversationState.addSuggestions());
    }

    if (types.indexOf(EditorInterface.ScriptValidation) >= 0) {
      suggestions = suggestions.concat(conversationState.addValidationScriptSuggestions());
    }

    setSuggestions(suggestions);
    setErrors(conversationState.validate(script));
  }, [value]);

  const executeScriptValidation = async (scriptContent?: string | undefined) => {
    if (tenant?.id) {
      return await validateMethod(
        tenant.id,
        snapshot.snapshot.id,
        conversationState.fromNames(scriptContent ? scriptContent : script),
      );
    }
    return undefined;
  };

  const change = (latestScriptContent: string) => {
    setScript(latestScriptContent);
    setIsValidating(true);

    executeScriptValidation(latestScriptContent).then((response) => {
      if (response) {
        setErrors(response.data || []);
        setShowValidationMessage(!!response.data);
        setIsValidating(false);
      }
    });

    if (onChange) onChange(latestScriptContent);
  };

  const save = async () => {
    let response = await executeScriptValidation();

    if (response) {
      setErrors(response.data || []);
      setShowValidationMessage(!!response.data);
    }

    if (onSave) {
      onSave(conversationState.fromNames(script));
    }
  };

  return (
    <>
      <Monaco
        canEdit={!isReadOnly}
        language="csharp"
        height="50vh"
        theme="vs-dark"
        value={value}
        suggestions={suggestions}
        onChange={change}
        onChangeDelay={true}
        //TODO:There is a current Issue with errors in Monaco component, must investigate why it breaks
        //on line numbers given from Validation Markers.
        errors={errors}
      />

      <ValidatorScriptTooltip showValidationMessage={showValidationMessage} errors={errors} />
      <div className="mt-3">
        <Button className={'me-3'} type={VisualCategory.Warning} action={() => executeScriptValidation()}>
          Validate
        </Button>
        <Button isDisabled={isReadOnly || isValidating} type={VisualCategory.Primary} action={save}>
          Save
        </Button>
      </div>
    </>
  );
};
