import { Step, StepBuilder } from '../steps';

export class Func {
  id: string;
  name: string;
  description: string;
  steps: Array<Step>;

  constructor(id: string, name: string, description: string, steps: Array<Step>) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.steps = steps;
  }

  static fromJson(json: any) {
    const steps = json.steps && Array.isArray(json.steps) 
        ? json.steps.map((s: any) => StepBuilder(s)) 
        : [];

    return new Func(json.id, json.name, json.description, steps);
  }
}
