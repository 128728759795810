import { Methods, ValidateResponseActionEnum } from 'internal/clients/Methods';
import { DataResponse, ServerResponse } from 'internal/clients/Responses';
import { Condition } from 'internal/models';
import { IConditionalPolicyClient } from './IConditionalPolicyClient';

export class ConditionalPolicyClient implements IConditionalPolicyClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }

  async addConditionAsync(
    snapshotId: string,
    stepId: string,
    policyId: string,
    condition: Condition,
  ): Promise<DataResponse<string>> {
    const url = `${this.getUrl(snapshotId, policyId)}`;
    const response = await this.methods.post(url, condition);
    return await this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Created} Condition`, response);
  }

  async replaceConditionAsync(
    snapshotId: string,
    stepId: string,
    policyId: string,
    conditionId: string,
    condition: Condition,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, policyId)}/${conditionId}`;
    const { id, ...request } = condition;
    const response = await this.methods.put(url, request);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Condition`, response);
  }

  async deleteConditionAsync(
    snapshotId: string,
    stepId: string,
    policyId: string,
    conditionId: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, policyId)}/${conditionId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Condition`, response);
  }

  private getUrl(snapshotId: string, policyId: string) {
    return `${this.serviceUrl}/tenant/${this.methods
      .tenantId!}/snapshot/${snapshotId}/v1/policies/${policyId}/conditions`;
  }
}
