import { useId } from '@smartaction/visuals';
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { v4 as uuid } from 'uuid';

type EditableLabelProps = {
  inputLabel: string;
  text: string;
  className?: string;
  emptyIsAllowed?: boolean;
  labelIfEmpty?: string;
  save: (text: string) => Promise<boolean>;
};

export const EditableLabel: React.FC<EditableLabelProps> = ({
  inputLabel,
  text,
  className,
  emptyIsAllowed = false,
  labelIfEmpty = '-no value-',
  save,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [input, setInput] = useState(text);
  const ref = useRef<HTMLDivElement>(null);
  const id = useId('editableLabel');
  const [tooltipId] = useState(`click-to-edit-${uuid()}`);
  const containerClasses = className ? `editable-label ${className}` : 'editable-label';

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (isEditing) {
      setTimeout(() => {
        const input = ref.current!.querySelector('input');
        input?.focus();
        input?.select();
      }, 20);
    }
  }, [isEditing, ref]);

  if (!isEditing) {
    return (
      <div className={containerClasses}>
        <label
          className={text ? 'display d-inline-block text-truncate' : 'display empty'}
          data-tooltip-id={tooltipId}
          onDoubleClick={() => setIsEditing(true)}
          style={{ cursor: 'pointer', maxWidth: '200px' }}
        >
          {text ? text : <i>{labelIfEmpty}</i>}
        </label>
        <Tooltip
          style={{ zIndex: 1000 }}
          delayShow={50}
          delayHide={50}
          id={tooltipId}
          positionStrategy="fixed"
          place="bottom"
          content="Double click to edit"
        />
      </div>
    );
  }

  const isValid = emptyIsAllowed || input.trim();
  const classes = isValid ? 'form-control form-control-sm' : 'form-control form-control-sm is-invalid';

  const handleSave = async () => {
    if (input === text) {
      setIsEditing(false);
    } else if (isValid) {
      const saved = await save(input.trim());
      if (saved) {
        setIsEditing(false);
      } else {
        setInput(text);
      }
    }
  };

  return (
    <div ref={ref} className={containerClasses}>
      <input
        type="text"
        className={classes}
        id={id}
        value={input}
        onChange={(evt) => {
          setInput(evt.target.value);
        }}
        onKeyDown={(evt) => {
          if (evt.key === 'Escape') {
            setIsEditing(false);
            setInput(text);
            return;
          }

          if (evt.key === 'Enter') {
            handleSave();
          }
          evt.stopPropagation();
        }}
        onBlur={() => handleSave()}
      />
      <label className="input-label" htmlFor={id}>
        {isValid ? inputLabel : 'Cannot be empty'}
      </label>
    </div>
  );
};
