import {
  ConfigPointer,
  ContextPointer,
  ContextType,
  EmptyPointer,
  IterationPointer,
  Pointer,
  PointerType,
} from 'internal/models';
import React, { useEffect, useState } from 'react';
import { PointerEditorProps } from './PointerEditorProps';
import Select, { SingleValue } from 'react-select';
import { Option } from 'ui/types';
import { useBots, useConfigs, useContextItems, useModule, useObjects } from 'contexts';
import { extractIDsFromQueryParams } from 'ui/utils';

export const IterationPointerEditor: React.FC<PointerEditorProps<IterationPointer>> = ({ pointer, update, isDisabled }) => {
  const [selected, setSelected] = useState<Option>();
  const [options, setOptions] = useState<Option[]>();
  const module = useModule(extractIDsFromQueryParams().moduleID);
  const configs = useConfigs();
  const contexts = useContextItems();
  const objects = useObjects();

  const setContextOptionOrOptionsList = (blockListToIterate: Pointer) => {
    const contextItem = contexts.contextItems.find(
      (c) => c.id === (blockListToIterate as ContextPointer)?.contextItemId,
    );
    if (contextItem?.type === ContextType.Object || contextItem?.type === ContextType.Address) {
      const objectItem = objects.objects.find((item) => item.id === contextItem.typeId);
      const objectItemField = objects.objects
        .find((item) => item.id === contextItem.typeId)
        ?.fields.find((field) => field.id === pointer.propertyId);

      setOptions(
        objectItem?.fields?.map((item) => ({
          value: `${item?.id}`,
          label: `${contextItem.name} - ${item?.name}`,
        })) || [],
      );

      if (objectItemField) {
        setSelected({
          value: pointer.propertyId,
          label: `${contextItem.name} - ${objectItemField?.name} (type : ${objectItemField?.type})`,
        });
      }
    } else {
      if (contextItem?.originalId) {
        update(new IterationPointer(contextItem?.originalId));
        setSelected({
          value: contextItem.id,
          label: `${contextItem.name} (type : ${contextItem.type})`,
        });
      }
    }
  };
  const setConfigOption = (blockListToIterate: Pointer) => {
    const configItem = [...configs.all.values()].find((c) => c.id === (blockListToIterate as ConfigPointer)?.configId);
    if (configItem?.originalId) {
      update(new IterationPointer(configItem?.originalId));
      setSelected({
        value: configItem.id,
        label: `${configItem.name} (type : ${configItem.type})`,
      });
    }
  };

  const getPropertyByTypeAndId = () => {
    const blockListToIterate = module?.blocks?.find(
      (blockItem) => blockItem.id === extractIDsFromQueryParams().nodeID,
    )?.listToIterate;

    if (blockListToIterate?.type === PointerType.Context) {
      setContextOptionOrOptionsList(blockListToIterate);
    }
    if (blockListToIterate?.type === PointerType.Config) {
      setConfigOption(blockListToIterate);
    }
  };

  useEffect(() => {
    getPropertyByTypeAndId();
  }, [window.location.search]);

  const save = (opt: SingleValue<Option>) => {
    if (!opt || !opt.label) {
      setSelected(selected);
      update(new EmptyPointer());
    } else {
      setSelected(opt);
      update(new IterationPointer(opt.value));
    }
  };

  return (
    <Select
      menuPosition={'fixed'}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menu: (baseStyles) => ({
          ...baseStyles,
          fontSize: 14,
          width: 'max-content',
        }),
      }}
      hideSelectedOptions={true}
      isDisabled={isDisabled}
      options={options}
      value={selected}
      onChange={save}
    />
  );
};
