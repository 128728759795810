import { VisualCategory } from '@smartaction/styles';
import { Button, Editor } from '@smartaction/visuals';
import { useClient } from 'contexts/ClientContext';
import { useObjects } from 'contexts/design/ObjectsContext';
import { useSnapshot } from 'contexts/SnapshotContext';
import { Obj } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { FieldsRenderer, ObjectInUse } from './FieldsRenderer';
import { useCheckTenantAccess } from 'contexts/AccessContext';
import { EntitlementNames } from 'EntitlementNames';
import { CellValueChangedEvent, EditableCallbackParams, GridApi, GridReadyEvent } from 'ag-grid-community';
import { ServerResponse } from 'internal/clients';
import { toast } from 'react-toastify';
import { DeleteRenderer } from 'ui/controls';
import { useBots, useContextItems } from 'contexts';

export const ObjectsView : React.FC = () => {
    const snapshot = useSnapshot();
    const objects = useObjects();
    const client = useClient('objects');
    const [gridApi, setGridApi] = useState<GridApi>();

    // Check snapshot readonly, permissions
    const hasAccess = useCheckTenantAccess(EntitlementNames.Objects.Manage);
    const {isReadOnlyBot} = useBots();

    const isNotStaticObject = (param: EditableCallbackParams): boolean => {
        console.log(param.data?.obj);
        return !objects.staticObjects.some((staticObjects) => param.data?.obj?.id === staticObjects.id);
    }

    const contextItems = useContextItems();
    const objectIdsInContextItems = contextItems.contextItems.filter(i => i.typeId !== undefined).map(i => i.typeId);
    const objectsInUse = objects.objects.map(o => {
        return {obj: o, inUse:objectIdsInContextItems.includes(o.id)};
    });

    useEffect(() => {
        gridApi?.setRowData(objectsInUse);
    }, [objectsInUse]);

    const colDefs = (isReadOnly: boolean, deleteFunc: (object: ObjectInUse) => void) => {
        const cols: any = [
            { 
                headerName: "Name", 
                field: "obj.name", 
                editable: isNotStaticObject,
                width: 200, 
                resizable: true
            },
            { 
                headerName: "Description", 
                field: "obj.description", 
                editable: isNotStaticObject, 
                width: 250, 
                resizable: true,
                cellRendererParams: {}
            },
            { 
                headerName: "Fields", 
                field: "obj.fields", 
                cellRenderer: FieldsRenderer, 
                width: 500, 
                resizable: true
            }
        ];

        if (!isReadOnly){
            cols.push({ 
                headerName: "Delete", 
                cellRenderer: DeleteRenderer, 
                cellRendererParams: { canEdit: isNotStaticObject, deleteFunc }, 
                width: 100, 
                resizable: true 
            });
        }

        return cols;
    }

    const handleSaveEvent = async (event:CellValueChangedEvent<ObjectInUse>) => {
        var columnDef = event.colDef; 
        const item = event.data;
        const newValue = event.newValue;
        const snapshotId = snapshot.snapshot.id;
        switch(columnDef.field) {
            case "obj.name": 
                if (!event.newValue) {
                    toast("Name must be set! Changes are not saved.", { type: 'error', containerId: 'default' });
                    return;
                }
                finishSave(client.updateName(snapshotId, item.obj.id, newValue), () => item.obj.name = newValue);
                break;
            case "obj.description":
                finishSave(client.updateDescription(snapshotId, item.obj.id, newValue), () => item.obj.description = newValue);
                break;
        }
    };

    const finishSave = async (responsePromise: Promise<ServerResponse>, localUpdate: () => void) => {
        const response = await responsePromise;
        if (response.success) {
            localUpdate();
            objects.refresh();
        }
    }

    const create = async () => {
        const obj = new Obj('', '', "New Object", "", []);
        const response = await client.create(snapshot.snapshot.id, obj.name);
        if (response.success) {
            obj.id = response.data!;
            objects.objects.push(obj);
            objects.refresh();
        }
    }

    const gridReady = (evt: GridReadyEvent) => {
        setGridApi(evt.api);
    }

    return (
        <Editor>
            <div className="ag-theme-balham">
                <AgGridReact
                    disableStaticMarkup={true} // https://www.ag-grid.com/react-fine-tuning/#react-cell-rendering - disabled this because it causes the very first cell to frequently render twice
                    columnDefs={colDefs(isReadOnlyBot || !hasAccess, (i) => objects.deleteObj(i.obj))}
                    defaultColDef={{ cellStyle: { 'fontFamily': 'Quicksand', 'fontSize': '14px'} }}
                    rowData={objectsInUse}
                    onCellValueChanged={handleSaveEvent}
                    onGridReady={gridReady}
                    rowHeight={36}
                    stopEditingWhenCellsLoseFocus={true}
                    suppressRowTransform={true}
                    domLayout="autoHeight">
                </AgGridReact>
            </div>
            <Button className={'mt-3'} action={create} type={VisualCategory.Primary}>Create</Button>
        </Editor>
    );
}