import React, { useCallback, useEffect, useState } from 'react';
import { Flow, Note, EmptyId, CLU, If, Step, PolicyType, ValidatePolicy } from 'internal/models';
import { useSnapshot } from '../SnapshotContext';
import { useClient } from '../ClientContext';
import { PoliciesProvider } from './PoliciesContext';

type FlowContext = {
  flow: Flow;
  loaded: boolean;
  updateFlow: (action: (flow: Flow) => void) => void;
  getNameById: (id?: string) => string | null;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const FlowContext = React.createContext<FlowContext | undefined>(undefined);
const mapFlowItemsTranslations = (data: Array<{ name: string; id: string }>) =>
  data.reduce((total, { id, name }) => {
    return { ...total, [id]: name };
  }, {});


const nestedStepsTranslations = ( steps: Step[]) => {

  let foundCLUSteps = steps.filter( x => x.type === "CLU" ) as CLU[]; 
  let foundIfSteps = steps.filter( x => x.type ==="If" ) as If[]

  let nestedStepsTranslations : Array<{ [id : string] : string }> = []

  if( foundCLUSteps ){ 
    for( let clu of foundCLUSteps ){ 
      for( let intent of clu.intents ){ 
        if(intent){
          nestedStepsTranslations.push({ [intent.id] : intent.name! })
          if(intent.steps.length > 0 ) nestedStepsTranslations.push( mapFlowItemsTranslations(intent.steps));
        }
      }
    }
  }

  if( foundIfSteps ){ 
    for( let ifStep of foundIfSteps ){ 
      for( let branch of ifStep.branches ){ 
        if(branch){
          nestedStepsTranslations.push({ [branch.id] : branch.name! })
          if(branch.steps.length > 0 ) nestedStepsTranslations.push( mapFlowItemsTranslations(branch.steps));
        }
      }
    }
  }


  let accumulatedFuncCLUSteps = nestedStepsTranslations.reduce( (total, x ) => {
    return { 
      ...total,
      ...x
    }
  }, {})

  return accumulatedFuncCLUSteps;

}

type FlowProviderProps = {
  children: React.ReactNode
}

export const FlowProvider: React.FC<FlowProviderProps> = ({ children }) => {
  const snapshot = useSnapshot();
  const client = useClient('flow');
  const [loaded, setLoaded] = useState(false);
  const [currentFlow, setFlow] = useState<Flow>();
  const [idTranslations, setIdTranslations] = useState<{ [id: string]: string }>({ [EmptyId]: 'Empty' });

  const updateFlow = (action: (flow: Flow) => void) => {
    if (currentFlow) {
      action(currentFlow);
      setFlow(currentFlow.clone());
    }
  };

  const getNameById = useCallback(
    (id?: string) => {
      if (!id) return null;
      return idTranslations[id] || null;
    },
    [idTranslations],
  );

  useEffect(() => {
    if (!currentFlow || currentFlow.snapshotId !== snapshot.snapshot.id)
    {
      setLoaded(false);
      client.getFlowAsync(snapshot.snapshot.id).then((flow) => {
        setFlow(flow);
        setLoaded(true);

        const flowTranslations = flow.modules.reduce(
        (
          total, 
          { blocks, decisions, entryPoints, id, name }
        ) => {   

          let validations : {} = {};
          let policies = flow.policies.reduce( (total, current ) => { 

            if(current.type === PolicyType.Validate ){ 
              const vpolicy = current as ValidatePolicy; 
              validations = mapFlowItemsTranslations( vpolicy.validations ); 
            }
           
            return { ...validations, ...{ [current.id] : current.type }  }

          }, { })

          let functionSteps = flow.functions.reduce( (total, { steps }) => {    
            
            let nestedSteps = nestedStepsTranslations(steps); 
            
            return { 
              ...total,
              ...mapFlowItemsTranslations(steps),
              ...nestedSteps
            }
          } , {})

          let decisionBranches = decisions.reduce( (total, { branches }) => {      

            let branchTranslations = branches.reduce( (total, current ) => { 

              return { ...{ [current.id] : current.name }  }
  
            }, { })

            return { 
              ...total,
              ...branchTranslations,
              ...mapFlowItemsTranslations(branches)
            }
          } , {})

          let blockSteps = blocks.reduce( (total, { steps }) => {

            let nestedSteps = nestedStepsTranslations(steps); 
            return { 
              ...total,
              ...mapFlowItemsTranslations(steps),
              ...nestedSteps
            }
          } , {})

          let entryPointSteps = blocks.reduce( (total, { steps }) => {

            let nestedSteps = nestedStepsTranslations(steps); 
            return { 
              ...total,
              ...mapFlowItemsTranslations(steps),
              ...nestedSteps
            }
          } , {})


          return {
            ...total,
            ...policies,
            ...mapFlowItemsTranslations(blocks),
            ...blockSteps,
            ...mapFlowItemsTranslations(decisions),
            ...decisionBranches,
            ...mapFlowItemsTranslations(entryPoints),
            ...entryPointSteps,
            ...mapFlowItemsTranslations(flow.functions),
            ...functionSteps,
            ...{ [id] : name }
            
          };
        }, {});
        
        setIdTranslations((prevState) => ({ ...prevState, ...flowTranslations }));
      });
    }
  }, [client, snapshot, currentFlow]);

  if (!currentFlow) return null;
  return (
    <FlowContext.Provider value={{ flow: currentFlow, loaded, updateFlow, getNameById }}>
      <PoliciesProvider>
        {children}
      </PoliciesProvider>
    </FlowContext.Provider>
  );
};

export const useFlow = (): FlowContext => {
  const context = React.useContext(FlowContext);

  if (context === undefined) {
    throw new Error('useFlow must be used inside of FlowProvider!');
  }

  return context;
};

export const useModule = (moduleId: string) => {
  const context = React.useContext(FlowContext);

  if (context === undefined) {
    throw new Error('useBlock must be used inside of FlowProvider!');
  }

  return context.flow.modules.find((m) => m.id === moduleId)!;
};

export const useNote = (moduleId: string, noteId: string): [Note, (n: Note) => void] => {
  const context = React.useContext(FlowContext);

  if (context === undefined) {
    throw new Error('useBlock must be used inside of FlowProvider!');
  }

  const mod = context.flow.modules.find((m) => m.id === moduleId)!;
  const noteIndex = mod?.notes?.findIndex((ep) => ep.id === noteId)!;
  const updateNote = (n: Note) => context.updateFlow((f) => (mod.notes[noteIndex] = n));
  return [mod?.notes[noteIndex], updateNote];
};

export const useContextInUse = () => {
  const context = React.useContext(FlowContext);

  if (context === undefined) {
    throw new Error('useContextManager must be used inside of FlowProvider!');
  }

  const MODULES = JSON.stringify(context.flow.modules);

  const modulesItemArr = MODULES.split('contextItemId":"');
  const contextItemStrings = modulesItemArr
    ?.filter((item, i) => i > 0)
    ?.map((item) => item?.split('","propertyId"')?.shift());

  const modulesContextArr = MODULES.split('[Context.');
  const contextStrings = modulesContextArr
    ?.filter((item, i) => i > 0)
    ?.map((item) => item?.split('] ","languageCode":')?.shift());

  return [...contextItemStrings, ...contextStrings];
};
