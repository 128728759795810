import { Step } from "../Step";
import { StepType } from "../Type";

export class GoTo extends Step {
    nextId?: string;

    constructor(id: string | undefined, name: string, description: string, tags: string[], nextId?: string) {
        super(id, name, description, StepType.GoTo, tags);
        this.nextId = nextId;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): GoTo {
        return new GoTo(json.id, json.name, json.description, json.tags, json.nextId);
    }

}