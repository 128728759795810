import { ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Methods } from 'internal/clients/Methods';
import { ContextItemMap } from 'internal/models';
import { IWaitForAgentClient } from './IWaitForAgentClient';

export class WaitForAgentClient implements IWaitForAgentClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async setWaitForAgentBot(snapshotId: string, stepId: string, botId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/bot-id`;
    const response = await this.methods.put(url, botId);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Wait For Agent Bot`, response);
  }

  async setContextItem(snapshotId: string, stepId: string, contextItemMap: ContextItemMap): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/context-item-map`;
    const response = await this.methods.post(url, contextItemMap);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Context Item`, response);
  }

  async setContextItemId(snapshotId: string, stepId: string, id: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/context-item-map/${id}/id`;
    const response = await this.methods.put(url, id);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Context Item Id`, response);
  }

  async setContextItemOriginalId(snapshotId: string, stepId: string, id: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/context-item-map/${id}/original-id`;
    const response = await this.methods.put(url, id);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Context Item Original Id`,
      response,
    );
  }

  async deleteContextItem(snapshotId: string, stepId: string, id: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/context-item-map/${id}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Context Item`, response);
  }

  private getUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }
}
