import { Collapsible } from '@smartaction/visuals';
import React from 'react';
import { Block, Decision, EntryPoint, NoteDraggable } from './index';

export const NodesSection: React.FC = () => {
    return <Collapsible labelContent='Nodes'>
      <div className="row">
        <div className="col-6">
          <EntryPoint />
        </div>
        <div className="col-6">
          <Block />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <Decision />
        </div>
        <div className="col-6">
          <NoteDraggable />
        </div>
      </div>
    </Collapsible>
}