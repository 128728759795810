import { ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Methods } from 'internal/clients/Methods';
import { IGoToClient } from './IGoToClient';

export class GoToClient implements IGoToClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async SetNext(snapshotId: string, stepId: string, nextId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/next/${nextId}`;
    const response = await this.methods.put(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} NextId for GoTo`, response);
  }

  private getUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }
}
