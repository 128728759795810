import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import {
  EmptyPointer,
  Pointer,
  PointerControlFilters,
  PointerType,
  SetValueAssignment,
  SetValues,
} from 'internal/models';
import { useBots, useClient, useFlow, useSnapshot } from 'contexts';
import { DataStepsColors } from '../../StepColorGroups';
import { PointerControl } from 'ui/components';
import { Button } from '@smartaction/visuals';

export const SetValuesStyling: StepStyling = {
  typeName: 'Set Values',
  icon: IconType.Equals,
  ...DataStepsColors,
};

export const SetValuesView: React.FC<TypedStepProps<SetValues>> = ({ step, manipulateStep }) => {
  const client = useClient('flow');
  const flow = useFlow();
  const snapshot = useSnapshot();
  const prevRef = useRef<SetValueAssignment[]>(step.assignments);
  const [assignments, setAssignments] = useState<SetValueAssignment[]>(step.assignments);
  const { isReadOnlyBot } = useBots();

  useEffect(() => {
    prevRef.current !== assignments &&
      client.steps.setValues.setValueAssignments(snapshot.snapshot.id, step.id, assignments).then((res) => {
        if (res.success) {
          flow.updateFlow(() => {
            step.assignments = assignments;
          });
        }
      });
  }, [assignments]);

  const generateAssignment = (target?: Pointer, source?: Pointer) => ({
    target: target || new EmptyPointer(),
    source: source || new EmptyPointer(),
  });

  const handlePointerSelect = (pointer: Pointer, assignment: SetValueAssignment) =>
    pointer.type === PointerType.Context
      ? generateAssignment(pointer, assignment.source)
      : generateAssignment(assignment.target, pointer);

  const changeAssignments = (pointer: Pointer, index: number) => {
    setAssignments(
      assignments.map((assignment, assignmentIndex) =>
        index === assignmentIndex ? handlePointerSelect(pointer, assignment) : assignment,
      ),
    );
  };

  const addAssignment = () => setAssignments([...assignments, generateAssignment()]);

  const deleteAssignment = (currentIndex: Number) => {
    const newAssignments = assignments.filter((x, index) => {
      if (index !== currentIndex) {
        return x;
      }
    });
    setAssignments([...newAssignments]);
  };

  return (
    <StepView step={step} isCollapsible={true} styling={SetValuesStyling} manipulateStep={manipulateStep}>
      <Fragment>
        {assignments.map((assignment, index) => (
          <div className="mb-2 shadow p-2 bg-white rounded">
            <div className="context-heading">
              <h6>Context item:</h6>
              <Button
                isDisabled={isReadOnlyBot}
                type={VisualCategory.Danger}
                className="btn-sm"
                action={() => deleteAssignment(index)}
              >
                <Icon type={IconType.Delete} />
              </Button>
            </div>
            <PointerControl
              key={`target-${index}`}
              types={[PointerType.Context]}
              pointerFilters={[PointerControlFilters.HideStaticContextItems]}
              pointer={assignment.target}
              update={(p) => changeAssignments(p, index)}
            />
            <h6>Value:</h6>
            <PointerControl
              key={`source-${index}`}
              types={[
                PointerType.Context,
                PointerType.DirectAssignment,
                PointerType.DirectAssignmentList,
                PointerType.Iteration,
              ]}
              pointer={assignment.source}
              update={(p) => changeAssignments(p, index)}
            />
          </div>
        ))}
      </Fragment>

      <Button isDisabled={isReadOnlyBot} type={VisualCategory.Primary} action={addAssignment}>
        <Icon type={IconType.Add} /> Add
      </Button>
    </StepView>
  );
};
