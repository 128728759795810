import React, { useEffect } from 'react';
import { ToolbarProvider, useBots, useClient, useFlow, useSnapshot } from 'contexts';
import {
  ListViewer,
  ListViewerFolder,
  ListViewerOpenDefinedItemEvent,
  ListViewerOpenItemEvent,
  ListViewerPort,
  ListViewerRenameItemEvent,
  ModalContainerIds,
  TabClosedEvent,
  usePortalModal,
  useWindows,
} from '@smartaction/visuals';
import { Module, PolicyType, Func, UnknownPolicy } from 'internal/models';
import { toast } from 'react-toastify';
import { IconType } from '@smartaction/styles';
import { ModuleView } from './Module';
import { DefaultPublisher, arrayRemove } from '@smartaction/common';
import CreateModuleModal from './module/CreateModuleModal';
import CreateFunctionModal from './module/CreateFunctionModal';
import { APIManager, ContextManager, ResourceManager, Properties, Scripts } from './general';
import { usePoliciesModal } from 'ui/components';
import { FunctionWindow } from './functions/FunctionWindow';
import { ObjectsView } from '../objects';
import { ConfigsView } from '../configs';
import { ListViewerItem } from '@smartaction/visuals/Layout/ListViewer/Types';
import { EnsureQuery, GetQueryParams, UpdateQuery } from 'ui/utils';
import { DesignViews } from '../DesignViews';
import { FilterControl } from './FilterControl';
import { Searchable } from './Searchable';
import { Tooltip } from 'react-tooltip';
import { useCheckTenantAccess } from 'contexts/AccessContext';
import { EntitlementNames } from 'EntitlementNames';

function renderView(view: DesignViews, id: string | undefined, element: React.ReactElement) {
  return () => {
    EnsureQuery(id ? `view=${view}-${id}` : `view=${view}`);
    return element;
  };
}

function createGeneralItem(view: DesignViews, name: string, element: React.ReactElement): ListViewerItem {
  return {
    id: view,
    isEditable: false,
    label: (
      <div
        data-tooltip-id={`list-viewer-tooltip`}
        data-tooltip-content={name}
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {name}
      </div>
    ),
    labelValue: name,
    onOpen: {
      label: name,
      renderFunc: renderView(view, undefined, element),
      dropType: 'flowControl',
      closeable: true,
      selected: () => {
        UpdateQuery(`view=${view}`);
      },
    },
  };
}

const GeneralItems: ListViewerItem[] = [
  createGeneralItem(DesignViews.ContextManager, 'Context Manager', <ContextManager />),
  createGeneralItem(DesignViews.ResourceManager, 'Resource Manager', <ResourceManager />),
  createGeneralItem(DesignViews.Objects, 'Objects', <ObjectsView />),
  createGeneralItem(DesignViews.Configurations, 'Configurations', <ConfigsView />),
  createGeneralItem(DesignViews.APIs, 'APIs', <APIManager />),
  createGeneralItem(DesignViews.Properties, 'Properties', <Properties />),
  createGeneralItem(DesignViews.Scripts, 'Scripts', <Scripts />),
];

function GeneralItemOpener(id: string) {
  const item = GeneralItems.find((item) => item.id === id);
  if (!item) {
    return false;
  }

  setTimeout(
    () => DefaultPublisher.publish(new ListViewerOpenDefinedItemEvent('flowList', ListViewerPort.Left, item!.id)),
    1100,
  );
  return true;
}

const FlowViewContents: React.FC = () => {
  const snapshot = useSnapshot();
  const { flow, updateFlow } = useFlow();
  const client = useClient('flow');
  const modal = usePortalModal(ModalContainerIds.Modal);
  const window = useWindows();
  const snapshotPolicyIcon = usePoliciesModal(
    snapshot.snapshot.id,
    [PolicyType.BargeIn, PolicyType.Retry, PolicyType.DTMFOnly, PolicyType.Expiration],
    async (policyType: PolicyType) => {
      const result = await client.policies.createPolicyForSnapshotAsync(snapshot.snapshot.id, policyType);
      return result.data ?? new UnknownPolicy('', snapshot.snapshot.id, policyType);
    },
  );

  const { isReadOnlyBot } = useBots();
  const canManage = useCheckTenantAccess(EntitlementNames.Flow.Modules.Manage);
  const isReadOnly = isReadOnlyBot || !canManage;

  useEffect(() => {
    const params = GetQueryParams();
    const view = params.get('view');
    console.log(view);
    // No view param, or the GeneralItemOpener was able to open the view
    if (!view) {
      if (!flow.modules.length) {
        return;
      }

      //Open the intial module
      const module = flow.modules[0];
      DefaultPublisher.publish(
        new ListViewerOpenItemEvent('flowList', ListViewerPort.Left, {
          label: `${module.name}`,
          id: `tab-${DesignViews.Module}-${module.id}`,
          renderFunc: () => <ModuleView module={module} isReadOnly={isReadOnly} />,
          dropType: 'module-tab',
          closeable: true,
        }),
      );

      EnsureQuery(`view=${DesignViews.Module}-${module.id}`);

      return;
    }

    if (GeneralItemOpener(view)) {
      return;
    }

    const [viewType, id] = view.split('-');
    console.log(viewType);
    if (viewType === DesignViews.Function) {
      const func = flow.functions.find((f) => f.id === id);
      if (func) {
        setTimeout(() => onFunctionOpen(func), 500);
      } else {
        UpdateQuery('');
      }
      return;
    } else if (viewType === DesignViews.Module) {
      const module = flow.modules.find((m) => m.id === id);

      if (module) {
        setTimeout(() => openModule(module), 500);
      } else {
        UpdateQuery('');
      }
    }
  }, []);

  const openModule = (module: Module) => {
    DefaultPublisher.publish(
      new ListViewerOpenDefinedItemEvent('flowList', ListViewerPort.Left, `${DesignViews.Module}-${module.id}`),
    );
  };

  const saveModule = (name: string, description: string) => {
    client.modules.create(snapshot.snapshot.id, name, description).then((id) => {
      updateFlow((f) => f.modules.push(new Module(id, name, description)));
      toast(`New Module Created: ${name}`, { type: 'success', containerId: 'default' });
      modal.closeModal();
      const lastModule = flow.modules[flow.modules.length - 1];
      openModule(lastModule);
    });
  };

  const saveFunction = (name: string) => {
    client.functions.create(snapshot.snapshot.id, name).then((res) => {
      if (res.success) {
        updateFlow((f) => (f.functions = [...f.functions, new Func(res.data!, name, '', [])]));
      }
      modal.closeModal();
    });
  };

  const onFunctionOpen = (func: Func) => {
    EnsureQuery(`view=${DesignViews.Function}-${func.id}`);
    window.addWindow({
      id: `${DesignViews.Function}-${func.id}`,
      className: 'func',
      title: `${func.name}`,
      titleIcon: { type: IconType.Function, size: 'xs' },
      children: <FunctionWindow funcId={func.id} />,
      closeAction: () => {},
    });
  };

  const folders: ListViewerFolder[] = [
    {
      identifier: 'modules',
      label: 'Modules',
      isEditable: !isReadOnly,
      requestReorder: async (ids) => {
        const response = await client.modules.reorderModules(snapshot.snapshot.id, ids);
        if (response.success) {
          updateFlow((f) => {
            f.modules = [...f.modules.sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id))];
          });
        }
      },
      items: flow.modules.map((m) => {
        return {
          id: `${DesignViews.Module}-${m.id}`,
          isEditable: !isReadOnly,
          label: (
            <div data-tooltip-id={`list-viewer-tooltip`} data-tooltip-content={m.name}>
              <Searchable id={m.id}>{m.name}</Searchable>
            </div>
          ),
          labelValue: m.name, // Add labelValue prop if label is a React.ReactElement
          itemIcon: <ModulePoliciesIcon snapshotId={snapshot.snapshot.id} module={m} />,
          onOpen: {
            label: m.name,
            renderFunc: renderView(DesignViews.Module, m.id, <ModuleView module={m} isReadOnly={isReadOnly} />),
            dropType: 'module-tab',
            closeable: true,
            selected: () => {
              // EnsureQuery(`view=${DesignViews.Module}-${m.id}`);
            },
          },
          requestRename: (name: string) => {
            client.modules.updateMetadata(snapshot.snapshot.id, m.id, name, m.description).then(() => {
              updateFlow(() => {
                m.name = name;
              });
              DefaultPublisher.publish(new ListViewerRenameItemEvent('flowList', 'modules', m.id, name));
            });
          },
          requestDelete: () => {
            client.modules.deleteModule(snapshot.snapshot.id, m.id).then(() => {
              updateFlow((f) => {
                const idx = f.modules.findIndex((mod) => mod.id === m.id);
                f.modules = arrayRemove(flow.modules, idx);

                DefaultPublisher.publish(new TabClosedEvent(`tab-${m.id}`));
              });
            });
          },
        };
      }),
      createItem: () => modal.openModal(<CreateModuleModal save={saveModule} />),
    },
    {
      identifier: 'functions',
      label: 'Functions',
      isEditable: !isReadOnly,
      items: flow.functions.map((f) => {
        return {
          id: `${DesignViews.Function}-${f.id}`,
          isEditable: !isReadOnly,
          label: (
            <div data-tooltip-id={`list-viewer-tooltip`} data-tooltip-content={f.name}>
              <Searchable id={f.id}>{f.name}</Searchable>
            </div>
          ),
          labelValue: f.name, // Add labelValue prop if label is a React.ReactElement
          itemIcon: <FunctionPoliciesIcon snapshotId={snapshot.snapshot.id} func={f} />,
          onOpen: () => onFunctionOpen(f),
          requestRename: (name: string) => {
            client.functions.updateName(snapshot.snapshot.id, f.id, name).then(() => {
              updateFlow(() => {
                f.name = name;
              });
            });
          },
          requestDelete: () => {
            client.functions.delete(snapshot.snapshot.id, f.id).then(() => {
              updateFlow(() => {
                const idx = flow.functions.findIndex((mod) => mod.id === f.id);
                flow.functions = arrayRemove(flow.functions, idx);

                DefaultPublisher.publish(new TabClosedEvent(`tab-${f.id}`));
              });
            });
          },
        };
      }),
      createItem: () => modal.openModal(<CreateFunctionModal save={saveFunction} />),
    },
    {
      identifier: 'general',
      label: 'General',
      isEditable: false,
      items: [
        ...GeneralItems,
        {
          id: `${DesignViews.Policies}`,
          isEditable: false,
          label: (
            <div data-tooltip-id={`list-viewer-tooltip`} data-tooltip-content={'Policies'}>
              Policies
            </div>
          ),
          labelValue: 'Policies',
          itemIcon: snapshotPolicyIcon.icon,
          onOpen: snapshotPolicyIcon.modalOpen,
        },
      ],
    },
  ];

  return (
    <ToolbarProvider>
      <ListViewer folders={folders} identifier="flowList" dropTypes={['module-tab']} openFirstItem={true}>
        <FilterControl />
      </ListViewer>
      {snapshotPolicyIcon.modal}
      {modal.modal}
      <Tooltip id="list-viewer-tooltip" positionStrategy="fixed" place="top" />
    </ToolbarProvider>
  );
};

type ModulePoliciesIconProps = {
  snapshotId: string;
  module: Module;
};

const ModulePoliciesIcon: React.FC<ModulePoliciesIconProps> = ({ snapshotId, module }) => {
  const client = useClient('flow');
  const icon = usePoliciesModal(
    module.id,
    [PolicyType.BargeIn, PolicyType.Retry, PolicyType.DTMFOnly],
    async (type: PolicyType) => {
      const result = await client.policies.createPolicyForModuleAsync(snapshotId, module.id, type);
      return result.data ?? new UnknownPolicy('', module.id, type);
    },
  );

  return (
    <React.Fragment>
      {icon.clickableIcon}
      {icon.modal}
    </React.Fragment>
  );
};

type FunctionPoliciesIconProps = {
  snapshotId: string;
  func: Func;
};

const FunctionPoliciesIcon: React.FC<FunctionPoliciesIconProps> = ({ snapshotId, func }) => {
  const client = useClient('flow');
  const icon = usePoliciesModal(
    func.id,
    [PolicyType.BargeIn, PolicyType.Retry, PolicyType.DTMFOnly],
    async (type: PolicyType) => {
      const result = await client.policies.createPolicyForFunctionAsync(snapshotId, func.id, type);
      return result.data ?? new UnknownPolicy('', func.id, type);
    },
  );

  return (
    <React.Fragment>
      {icon.clickableIcon}
      {icon.modal}
    </React.Fragment>
  );
};

export default FlowViewContents;
