import { Policy } from '../Policy';
import { PolicyType } from '../PolicyType';

export class DTMFOnlyPolicy extends Policy {
  constructor(id: string, ownerId: string) {
    super(id, ownerId, PolicyType.DTMFOnly);
  }

  static fromJson(json: any): DTMFOnlyPolicy {
    return new DTMFOnlyPolicy(json.id, json.ownerId);
  }
}
