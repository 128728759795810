import React, { useContext, useEffect, useState } from 'react';
import { IClients, ClientManager } from '../internal/clients';
import { useConfig } from './AppConfigContext';
import { DefaultPublisher } from '@smartaction/common';
import { useToken } from '@smartaction/authentication';
import { setTokenFunc } from '../internal/clients';


const ClientManagerContext = React.createContext<ClientManager | undefined>(undefined);

type ClientProviderProps = {
    children: React.ReactNode
}

export const ClientProvider: React.FC<ClientProviderProps> = ({ children}) => {
    const config = useConfig();
    const token = useToken();
    const [ready, setReady] = useState(false);
    const client = new ClientManager(config.serviceUrls);

    useEffect(() => {
        const subId = DefaultPublisher.subscribe('TenantSelectedEvent', (evt) => 
            client.setTenantId(evt.tenant?.id));
        return () => DefaultPublisher.unsubscribe('TenantSelectedEvent', subId);
    });

    useEffect(() => {
        setTokenFunc(() => token.get());
        setReady(true);
    }, [token]);

    if (!ready) {
        return <React.Fragment/>;
    }

    return (
        <ClientManagerContext.Provider value={client}>
            {children}
        </ClientManagerContext.Provider>
    )
}

export const useClient = <T extends keyof IClients>(client: T): IClients[T] => {
    const ctx = useContext(ClientManagerContext);

    if (ctx === undefined) {
        throw new Error("useClient must be used within a ClientProvider!");
    }

    return ctx.getClient(client) as IClients[T];
}