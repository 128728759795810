import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { IModuleClient } from './IModuleClient';
import {
  NoteClient,
  INoteClient,
  EntryPointClient,
  IEntryPointClient,
  BlockClient,
  IBlockClient,
  DecisionClient,
  IDecisionClient,
} from './moduleItems';
import { Module } from 'internal/models';

export class ModuleClient implements IModuleClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
    this.decisions = new DecisionClient(serviceUrl, methods);
    this.blocks = new BlockClient(serviceUrl, methods);
    this.entryPoints = new EntryPointClient(serviceUrl, methods);
    this.notes = new NoteClient(serviceUrl, methods);
  }

  async getModuleAsync(snapshotId: string): Promise<Module[]> {
    const url = this.getUrl(snapshotId);
    const response = await this.methods.get(url);

    await this.methods.handleErrors(`${ValidateResponseActionEnum.Get} Modules`, response);

    return await response.json();
  }
  async create(snapshotId: string, name: string, description: string): Promise<string> {
    const response = await this.methods.post(
      `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/modules`,
      {
        name: name,
        description: description,
      },
    );

    const success = await this.methods.handleErrors(`${ValidateResponseActionEnum.Created} Module Metadata`, response);

    return success ? await this.methods.getId(response) : '';
  }

  async updateMetadata(snapshotId: string, moduleId: string, name: string, description: string): Promise<any> {
    const response = await this.methods.put(
      `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/modules/${moduleId}/metadata`,
      {
        name: name,
        description: description,
      },
    );

    await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Module name`, response);
  }

  async reorderModules(snapshotId: string, moduleIds: string[]): Promise<ServerResponse> {
    const url = this.getUrl(snapshotId);

    const response = await this.methods.patch(url, moduleIds);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Reordered} Modules`, response);
  }

  async deleteModule(snapshotId: string, moduleId: string): Promise<any> {
    const response = await this.methods.delete(
      `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/modules/${moduleId}`,
    );
    await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Module`, response);
  }

  readonly entryPoints: IEntryPointClient;
  readonly blocks: IBlockClient;
  readonly decisions: IDecisionClient;
  readonly notes: INoteClient;

  private getUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/modules`;
  }
}
