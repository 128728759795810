import { Branch, Condition, PointerType } from 'internal/models';
import React from 'react';
import { useBots, useClient, useSnapshot } from 'contexts';
import { useFlow } from 'contexts';
import { arrayRemove } from '@smartaction/common';
import { ConditionsTableAG } from 'ui/views/bots/design/flow/conditions/ConditionsTable-AG';

type BranchEditorProps = {
  stepId: string;
  branch: Branch;
};

const targetAllowedTypes = [PointerType.Config, PointerType.Context, PointerType.Iteration];
const sourceAllowedTypes = [
  PointerType.Config,
  PointerType.Context,
  PointerType.DirectAssignment,
  PointerType.DirectAssignmentList,
  PointerType.Iteration,
];

export const BranchEditor: React.FC<BranchEditorProps> = ({ stepId, branch }) => {
  const snapshot = useSnapshot();
  const { updateFlow } = useFlow();
  const client = useClient('flow');
  const { isReadOnlyBot } = useBots();

  const addCondition = async (condition: Condition) => {
    const result = await client.steps.ifs.createConditionAsync(snapshot.snapshot.id, stepId, branch.id, condition);
    if (result) {
      condition.id = result.data!;
      updateFlow(() => {
        branch.conditions = [...branch.conditions, condition];
      });
    }
  };

  const replaceCondition = async (condition: Condition) => {
    const result = await client.steps.ifs.updateConditionAsync(
      snapshot.snapshot.id,
      stepId,
      branch.id,
      condition.id,
      condition,
    );
    if (result) {
      updateFlow(() => {
        const index = branch.conditions.findIndex((c) => c.id === condition.id);
        branch.conditions[index] = condition;
        branch.conditions = [...branch.conditions];
      });
    }
  };

  const deleteCondition = async (conditionId: string) => {
    const result = await client.steps.ifs.deleteConditionAsync(snapshot.snapshot.id, stepId, branch.id, conditionId);
    if (result) {
      updateFlow(() => {
        const index = branch.conditions.findIndex((c) => c.id === conditionId);
        if (index > -1) {
          branch.conditions = arrayRemove(branch.conditions, index);
        }
      });
    }
  };
  return (
    <div style={{ pointerEvents: isReadOnlyBot ? 'none' : 'initial' }} className="decision-branch-editor">
      <label className="mt-2">Conditions</label>
      <ConditionsTableAG
        conditions={branch.conditions}
        targetAllowedTypes={targetAllowedTypes}
        sourceAllowedTypes={sourceAllowedTypes}
        onAddCondition={addCondition}
        onUpdateCondition={replaceCondition}
        onDeleteCondition={deleteCondition}
      />
    </div>
  );
};
