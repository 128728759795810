import { DefaultPublisher, IEvent } from "@smartaction/common";
import { ToastOptions, TypeOptions } from "react-toastify";

export class PopToastEvent implements IEvent {
    public name: string = PopToastEvent.eventName;
    static eventName: "PopToastEvent" = "PopToastEvent";

    message: string;
    options: ToastOptions;
    isMarkdown: boolean;

    constructor(message: string, isMarkdown: boolean, options: ToastOptions) {
        this.message = message;
        this.options = options;
        this.isMarkdown = isMarkdown;
    }
}

export function PostMessage(message: string, isMarkdown: boolean, type: TypeOptions, autoClose: number | false = 5000) {
    DefaultPublisher.publish(new PopToastEvent(message, isMarkdown, { type: type, autoClose: autoClose}))
}

export function PostSuccess(message: string, isMarkdown: boolean = false) { PostMessage(message, isMarkdown, 'success', 3000); }
export function PostError(message: string, isMarkdown: boolean = false) { PostMessage(message, isMarkdown, 'error', false); }