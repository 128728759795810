import { Checkbox, Patience } from '@smartaction/visuals';
import React, { useState } from 'react';
import { useBots, useClient, useFlow, useSnapshot } from 'contexts';
import { Ask } from 'internal/models/bots/design/flow/steps/input/Ask';

type CanInterruptProps = {
  ask: Ask;
};

export const CanInterruptView: React.FC<CanInterruptProps> = ({ ask }) => {
  const snapshot = useSnapshot();
  const { updateFlow } = useFlow();
  const { isReadOnlyBot } = useBots();
  const flowClient = useClient('flow');
  const [isLoading, setIsLoading] = useState(false);

  const onChange = async (value: boolean) => {
    setIsLoading(true);
    const response = await flowClient.steps.setCanInterrupt(snapshot.snapshot.id, ask.id, value);
    if (response.success) {
      updateFlow(() => {
        ask.canInterrupt = value;
      });
    }
    setIsLoading(false);
  };

  return (
    <Patience showPatience={isLoading}>
      <Checkbox
        readonly={isReadOnlyBot}
        label={'Can be interrupted'}
        value={ask.canInterrupt}
        setValue={onChange}
      />
    </Patience>
  );
};
