import { Environment, Metadata } from "internal/models";
import { Record } from "./Record";
import { ConversationIntent } from "./ConversationIntent";

export class Summary {
    conversationId: string;
    type: SummaryType;
    jobId:string;
    metadata: Metadata;
    initialLanguage: string;
    language: string; 
    source: string; 
    timestamp : string; 
    duration: string; 
    botCode: string; 

    snapshotId: string;
    selectedLocationId: string;
    environment: Environment; 

    outcome: Outcome; 
    intents: Array<ConversationIntent>;
    tags: Array<string>;
    transcript: Array<Record>;
    configs: Array<GeneralItem>; 
    contextItems: Array<GeneralItem>;

    constructor(conversationId: string, type: SummaryType, jobId: string, metadata: Metadata, 
        initialLanguage: string, language: string, source: string, timestamp: string, 
        duration: string, botCode: string, snapshotId: string, selectedLocationId: string,
        environment: Environment, outcome: Outcome, intents: Array<ConversationIntent>, tags: Array<string>, 
        transcript: Array<Record>, configs: Array<GeneralItem>, contextItems: Array<GeneralItem> )  {
        this.conversationId = conversationId;
        this.type = type;
        this.initialLanguage = initialLanguage;
        this.source = source;
        this.language = language;
        this.jobId = jobId;
        this.metadata = metadata
        this.timestamp = timestamp;
        this.duration = duration;
        this.botCode = botCode;
        this.snapshotId = snapshotId;
        this.selectedLocationId = selectedLocationId;
        this.environment = environment;
        this.outcome = outcome;
        this.intents = intents;
        this.tags = tags;
        this.transcript = transcript;
        this.snapshotId = snapshotId;
        this.configs = configs; 
        this.contextItems = contextItems;
    }

    hasTag( prefixOrName: string ) : boolean{
        return this.tags.some( x => x.toLowerCase().startsWith(prefixOrName.toLowerCase()));
    }

    static fromJson(json: any) {

        const tags = json.configs && Array.isArray(json.tags)
        ? json.tags.map((f: string) => { return f;})
        : [];

        return new Summary(json.conversationId, json.type, json.jobId, Metadata.fromJson(json.metadata), 
            json.initialLanguage, json.language, json.source, json.timestamp, 
            json.duration, json.botCode, json.snapshotId, json.selectedLocationId,
            json.environment, json.outcome, json.intents.map(ConversationIntent.fromJson), tags, 
            json.transcript, json.configs, json.contextItems) ;
    }
}

export type GeneralItem = { key: string, value: string }

export enum SummaryType { InboundCall, OutboundCall, Chat }

export enum Outcome { BotHangup, ImmediateHangup, CallerHangup, BotTransfer, ImmediateTransfer, CallerTransfer, Finish, Transfer, AnsweringMachine, NoAnswer, Failed }