import { Flow } from 'internal/models';
import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import {
  FunctionClient,
  IFlowClient,
  IFunctionClient,
  IModuleClient,
  IPolicyClient,
  IStepClient,
  ModuleClient,
  StepClient,
} from '.';
import { PolicyClient } from './policies';

export class FlowClient implements IFlowClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
    this.modules = new ModuleClient(this.serviceUrl, this.methods);
    this.policies = new PolicyClient(this.serviceUrl, this.methods);
    this.steps = new StepClient(this.serviceUrl, this.methods);
    this.functions = new FunctionClient(this.serviceUrl, this.methods);
  }

  async getFlowAsync(snapshotId: string): Promise<Flow> {
    const url = this.getUrl(snapshotId);
    const response = await this.methods.get(url);

    await this.methods.handleErrors('Getting Flow', response);

    const json = await response.json();
    return Flow.fromJson(json);
  }

  async setExceptionHandlerAsync(snapshotId: string, blockId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/exception-handler/${blockId}`;

    const response = await this.methods.put(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Exception Handler Block`,
      response,
    );
  }

  private getUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/flow`;
  }

  readonly modules: IModuleClient;
  readonly policies: IPolicyClient;
  readonly functions: IFunctionClient;
  readonly steps: IStepClient;
}
