import { useSidePanel } from '@smartaction/visuals';
import React, { useEffect, useRef } from 'react';
import { DrawToMiddle, DrawToElement, GetCenter } from 'ui/utils';
import { useClient, useSnapshot } from 'contexts';
import useResizeObserver from '@react-hook/resize-observer';
import { useFlow } from 'contexts';
import { StepManager } from '../../../StepManager';
import { CLU, AllButConditionalStepTypes, Container } from 'internal/models';
import { CLUIntentEditor } from './CLUIntentEditor';
import { arrayRemove } from '@smartaction/common';

export const CLUIntents: React.FC<{ step: CLU}> = ({ step }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const rowRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    drawLines(canvasRef, rowRef);
  }, [step]);

  useResizeObserver(rowRef, () => {
    drawLines(canvasRef, rowRef);
  });

  const drawLines = (
    canvasReference: React.RefObject<HTMLCanvasElement>,
    divReference: React.RefObject<HTMLDivElement>,
  ) => {
    if (!canvasReference.current || !divReference.current) return;

    DrawToMiddle(canvasReference.current);

    for (let child of divReference.current.children) {
      DrawToElement(canvasReference.current, child as HTMLElement, GetCenter(canvasReference.current));
    }
  };

  const intentViews = step.intents.map((i, index) => <IntentView key={i.id} step={step} index={index} />);

  return (
    <React.Fragment>
      <canvas className="branchLinesCanvas noselect" ref={canvasRef}></canvas>
      <div ref={rowRef} className="flexContainer gx-1 noselect">
        {intentViews}
      </div>
    </React.Fragment>
  );
};

const IntentView: React.FC<{ step: CLU; index: number }> = ({ step: clu, index}) => {
  const snapshot = useSnapshot();
  const client = useClient('flow');
  const sidePanel = useSidePanel();
  const typeName = 'CLUStep';
  const ref = useRef<HTMLDivElement>(null);

  const { updateFlow } = useFlow();
  const intent = clu.intents[index];

  const onClick = () => {
    sidePanel.setContents('Edit Mappings', <div style={{minHeight:400}}><CLUIntentEditor step={clu} index={index} fixedMenu={false} /></div>);
    sidePanel.open();
  };

  return (
    <div id={intent.id} className="flexItem">
      <div className="intent">
        <div className="d-flex justify-content-between" ref={ref}>
          <b className="p-1">{intent.name}</b>
          <button className="btn btn-sm btn-default" onClick={onClick}>
            Mappings
          </button>
        </div>
        <StepManager
          identifier={intent.id}
          steps={intent.steps}
          sourceIdentifier={{ type: typeName, parentId: clu.id }}
          stepsUpdated={(steps) => {
            updateFlow(() => (intent.steps = steps));
          }}
          reorderSteps={async (stepIds) => {
            const r = await client.steps.clus.reorderSteps(snapshot.snapshot.id, clu.id, intent.id, stepIds);
            return r.success;
          }}
          addSteps={async (steps, fromSource, index) => {
            try {
              await Promise.all(
                steps.map((stepId, index) => {
                  const step = steps[index];
                  if (!step.id) {
                    return client.steps.clus
                      .createStepInCLUIntent(snapshot.snapshot.id, clu.id, intent.id, step.type, step.name, index)
                      .then((r) => {
                        step.id = r.data!;
                      });
                  } else {
                    return client.steps.clus.moveStepToCLUIntent(
                      snapshot.snapshot.id,
                      clu.id,
                      intent.id,
                      step.id,
                      new Container(
                        fromSource?.sourceIdentifier?.type,
                        fromSource?.sourceIdentifier?.parentId,
                        fromSource?.identifier,
                      ),
                      index,
                    );
                  }
                }),
              );
              return true;
            } catch (ex) {
              return false;
            }
          }}
          deleteSteps={async (steps) => {
            try {
              await Promise.all(
                steps.map(step => {
                  client.steps.deleteAsync(
                    snapshot.snapshot.id,
                    step.id,
                    new Container(typeName, clu.id, intent.id),
                  );

                  updateFlow(async () => {
                    const index = intent.steps.findIndex((s) => s.id === step.id);
                    if (index > -1) {
                      intent.steps = arrayRemove(intent.steps, index);
                    }
                  });
                }),
              );
              return true;
            } catch (ex) {
              return false;
            }
          }}
          allowedTypes={AllButConditionalStepTypes}
          hasTrash={false}
          isContainer={false}
        />
      </div>
    </div>
  );
};
