import { Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { ParameterAssignment } from 'internal/models';
import { ICallAPIClient } from './ICallAPIClient';

export class CallApiClient implements ICallAPIClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async setAPIAndEndpointAsync(
    snapshotId: string,
    stepId: string,
    apiId: string,
    endpointId: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}`;

    const request = {
      apiId: apiId,
      endpointId: endpointId,
    };

    const response = await this.methods.put(url, request);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Api/Endpoint`, response);
  }

  async setInputAssignmentsAsync(
    snapshotId: string,
    stepId: string,
    inputAssignments: ParameterAssignment[],
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/input-assignments`;
    const response = await this.methods.put(url, inputAssignments);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Input Assignments`, response);
  }

  async setOutputAssignmentsAsync(
    snapshotId: string,
    stepId: string,
    outputAssignments: ParameterAssignment[],
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/output-assignments`;
    const response = await this.methods.put(url, outputAssignments);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Output Assignments`, response);
  }

  private getUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }
}
