import {
  BargeIn,
  BargeInKeywords,
  BehaviorType,
  LangType,
  PhoneKeys
} from "../../../../../../internal/models";
import { TableRow } from '../../../../table';
import { v4 as uuid } from 'uuid';
import { IntentOption } from "./useBargeInPolicyLogic";

interface CreateBargeInTriggerRowRes extends Omit<BargeIn, 'intents'> {
  rows: TableRow[];
  intents: IntentOption[];
}
export interface CreateBargeInTriggerRowProps {
  bargeIn: BargeIn;
  bargeInIndex: number;
  onChangeTrigger: (b: {
    bargeInIndex: number;
    triggerIndex: number;
    keyValue: keyof BargeInKeywords;
    newValue: string;
  }) => void;
}

const lngOptions = Object.values(LangType).map((lng) => ({ label: lng, value: lng }));


export const createBargeInTriggerRow = (props: CreateBargeInTriggerRowProps): CreateBargeInTriggerRowRes => {
  return {
    ...props.bargeIn,
    intents: props.bargeIn.intents.map(({ intent, resourceId }) => ({ label: intent, value: intent, resourceId })),
    rows: props.bargeIn.keywords.map((keyword, triggerIndex) => {
      return {
        id: uuid(),
        cells: [
          {
            type: 'dropdown',
            value: keyword.languageCode,
            selectOptions: lngOptions,
            id: uuid(),
            onChange: ({ value }) =>
              props.onChangeTrigger({
                bargeInIndex: props.bargeInIndex,
                triggerIndex,
                keyValue: 'languageCode',
                newValue: value as string,
              }),
          },
          {
            type: 'textarea',
            value: keyword.keywords.join(', '),
            id: uuid(),
            onChange: ({ value }) =>
              props.onChangeTrigger({
                bargeInIndex: props.bargeInIndex,
                triggerIndex,
                keyValue: 'keywords',
                newValue: value as string,
              }),
          },
        ],
      };
    }),
  };
};

export const dtmfOptions = PhoneKeys.map((key) => ({
    label: key,
    value: key === "None" ? "" : key
  }));
export const allowedBehaviors = [
  BehaviorType.GoTo,
  BehaviorType.Skip,
  BehaviorType.RetryStep,
  BehaviorType.Wait,
  //BehaviorType.RunScript, Eventually will be available
  BehaviorType.CallFunction,
  BehaviorType.Talk,
];
