
export class Metadata  {

    phoneNumber: string;
    ipAddress: string;
    name: string;

    constructor(phoneNumber: string, ipAddress: string, name: string) {
        this.phoneNumber = phoneNumber;
        this.ipAddress = ipAddress;
        this.name = name;
    }

    static fromJson(json: any) {
        return new Metadata(json.phoneNumber, json.ipAddress, json.name);
    }
}
