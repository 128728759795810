import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { BotView } from './BotView';
import './index.scss';
import { useBots } from 'contexts/BotsContext';
import { Button, Editor, SubNav, usePortalModal } from '@smartaction/visuals';
import React, { useEffect } from 'react';
import { TopBarSelectStyles } from 'ui/layout';
import { createPortal } from 'react-dom';
import { useTenants } from 'contexts';
import { Option } from 'ui/types';
import Select from 'react-select';
import { VisualCategory } from '@smartaction/styles';
import { CreateBotModal } from './CreateBotModal';
import { BotSelect } from './BotSelect';

export const Bots = () => {
  const tenants = useTenants();

  if (!tenants.selectedTenant) {
    return <React.Fragment />;
  }
  return (
    <React.Fragment>
      <Editor>
        <Routes>
          <Route path='' element={<Navigate to="agents" />} />
          <Route path={'agents/:code/:version/*'} element={<BotView />} />
          <Route path={'agents/:code'} element={<BotView />} />
          <Route path={'agents'} element={<BotSelectionView />} />
        </Routes>
      </Editor>
    </React.Fragment>
  );
};


export const BotSelectionView: React.FC = () => {
  const modal = usePortalModal();
  return (
    <React.Fragment>
      <SubNav>
        <BotSelect />
      </SubNav>
      <h1>Please select a bot above to continue.</h1>
      {modal.modal}
    </React.Fragment>
  )
}