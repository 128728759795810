export class Tenant {
    name: string;
    description: string;
    imagePath: string;
    code: string;
    id: string;

    constructor(id: string, name: string, description: string, code: string, imagePath: string) {
        this.name = name;
        this.description = description;
        this.imagePath = imagePath;
        this.code = code;
        this.id = id;
    }

    static fromJson(json: any) {
        const tenant = new Tenant(json.id, json.name, json.description, json.code, ""); // No imagePath prop yet
        return tenant;
    }
}

