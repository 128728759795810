import { Methods, DataResponse, ServerResponse, IPropertiesClient, ValidateResponseActionEnum } from 'internal/clients';
import { Properties } from 'internal/models';
import { PostError } from 'ui/events';

export class PropertiesClient implements IPropertiesClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async getProperties(snapshotId: string, tenantId: string): Promise<DataResponse<Properties>> {
    const url = this.getUrl(snapshotId, tenantId);
    const response = await this.methods.get(url);

    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} list of context items`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    if (!data) {
      const error = 'Unexpected response when retrieving Context Items';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data: data!,
    };
  }

  async updateTimeout(snapshotId: string, tenantId: string, value: string, timeout: string): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, tenantId)}/${timeout}`;
    let response = await this.methods.put(url, value);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Timeout`, response);
  }

  async updateTimezone(snapshotId: string, tenantId: string, value: string): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, tenantId)}/default-time-zone`;
    let response = await this.methods.put(url, value);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Timezone`, response);
  }

  async updateDefaultLanguage(snapshotId: string, tenantId: string, lng: string): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, tenantId)}/default-language/${lng}`;
    let response = await this.methods.put(url, lng);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Default Language`, response);
  }
  async updateSupportedLanguages(
    snapshotId: string,
    tenantId: string,
    lng: string,
    voice: string,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, tenantId)}/supported-languages/${lng}`;
    let response = await this.methods.put(url, voice);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Supported Languages`,
      response,
    );
  }

  async updateMinimumConfidence(
    snapshotId: string,
    tenantId: string,
    minimumConfidence: number,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, tenantId)}/minimum-confidence`;
    let response = await this.methods.put(url, minimumConfidence);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Minimum Confidence`, response);
  }

  async deleteSupportedLanguage(snapshotId: string, tenantId: string, lng: string): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, tenantId)}/supported-languages/${lng}`;
    let response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Deleted} Supported Language Item`,
      response,
    );
  }

  private getUrl(snapshotId: string, tenantId: string) {
    return `${this.serviceUrl}/tenant/${tenantId}/snapshot/${snapshotId}/v1/properties`;
  }
}
