import React, { useEffect, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import { GroupedOption, Option } from 'ui/types';
import { PointerType } from 'internal/models';
import { AssignmentItem } from './CallAPIStep';

type AssignmentsSelectProps = {
  inputAssignmentItems: AssignmentItem[];
  outputAssignmentItems: AssignmentItem[];
  onAssignmentItemsChange: (options: Option[]) => void;
};

export const AssignmentsSelect: React.FC<AssignmentsSelectProps> = ({
  inputAssignmentItems,
  outputAssignmentItems,
  onAssignmentItemsChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  useEffect(() => {
    const defaultValues = [...inputAssignmentItems, ...outputAssignmentItems]
      .filter((assignment) => assignment.pointer.type !== PointerType.Empty)
      .map((item) => ({
        value: item.id,
        label: item.name,
      }));
    setSelectedOptions(defaultValues);
  }, [inputAssignmentItems, outputAssignmentItems]);

  useEffect(() => {
    onAssignmentItemsChange(selectedOptions);
  }, [selectedOptions]);

  const getOptionsFromAssignment = (items: AssignmentItem[]) =>
    items.map((assignment) => ({
      value: assignment.id,
      label: assignment.name,
    }));

  const groupedOptions: GroupedOption[] = [
    {
      label: 'Input Assignment Items',
      options: getOptionsFromAssignment(inputAssignmentItems),
    },
    {
      label: 'Output Assignment Items',
      options: getOptionsFromAssignment(outputAssignmentItems),
    },
  ];

  return (
    <>
      <h6>All Inputs/Outputs</h6>
      <Select
        value={selectedOptions}
        isMulti
        hideSelectedOptions={false}
        onChange={(options: MultiValue<Option>) => setSelectedOptions(options?.map((opt) => opt))}
        options={groupedOptions}
      />
    </>
  );
};
