import useResizeObserver from '@react-hook/resize-observer';
import { Color, Icon, IconStyle, IconType, VisualCategory } from '@smartaction/styles';
import { Button, useContextMenu, useSidePanel, useFromModal, Field, useId, PortalModal, usePortalModal } from '@smartaction/visuals';
import { useBots, useClient, useSnapshot } from 'contexts';
import { AllButConditionalStepTypes, Branch, Flow, If, Container } from 'internal/models';
import React, { useEffect, useRef, useState } from 'react';
import { EditableLabel } from 'ui/controls';
import { useFlow } from 'contexts';
import { BranchEditor } from './IfBranch';
import { StepManager } from '../../../StepManager';
import { DrawToMiddle, DrawToElement, GetCenter } from 'ui/utils';
import { POLICY_ICON_STATUS_COLOR } from 'ui/constants';
import { Tooltip } from 'react-tooltip';
import { arrayRemove } from '@smartaction/common';

type BranchesViewProps = {
  step: If;
};

export const BranchesView: React.FC<BranchesViewProps> = ({ step }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const rowRef = useRef<HTMLDivElement>(null);
  const snapshot = useSnapshot();
  const client = useClient('flow');
  const { updateFlow } = useFlow();
  const { isReadOnlyBot} = useBots();

  useResizeObserver(rowRef, (entry) => {
    DrawBranchLines(canvasRef, rowRef);
  });

  useEffect(() => {
    DrawBranchLines(canvasRef, rowRef);
  }, [step.branches]);

  function DrawBranchLines(
    canvasReference: React.RefObject<HTMLCanvasElement>,
    divReference: React.RefObject<HTMLDivElement>,
  ) {
    if (canvasReference.current === null || divReference.current === null) {
      return;
    }

    DrawToMiddle(canvasReference.current);

    for (let child of divReference.current.children) {
      DrawToElement(canvasReference.current, child as HTMLElement, GetCenter(canvasReference.current));
    }
  }

    const addBranch = () => {
        client.steps.ifs.createBranchAsync(snapshot.snapshot.id, step.id, "New Branch").then(r => {
            if (r.success) {
                updateFlow(() => {
                    step.branches.push(new Branch(r.data!, "New Branch", "", [], []));
                });
            }
        });
    }

  const deleteBranch = (id: string) => {
    client.steps.ifs.deleteBranchAsync(snapshot.snapshot.id, step.id, id).then((r) => {
      if (r.success) {
        updateFlow(() => {
          step.branches = step.branches.filter((x) => x.id !== id);
        });
      }
    });
  };

  const branchViews = step.branches.map((b) => (
    <BranchView stepId={step.id} key={b.id} branch={b} updateFlow={updateFlow} deleteBranch={deleteBranch} />
  ));

  const addButton =
    step.branches.length < 6 ? (
      <div className="col addBranch">
        <Button isDisabled={isReadOnlyBot} type={VisualCategory.Primary} action={addBranch}>
          <Icon type={IconType.Add} />
        </Button>
      </div>
    ) : (
      <React.Fragment />
    );
  return (
    <React.Fragment>
      <canvas className="branchLinesCanvas noselect" ref={canvasRef}></canvas>
      <div ref={rowRef} className="flexContainer gx-1 noselect">
        {branchViews}
        {addButton}
      </div>
    </React.Fragment>
  );
};

type BranchViewProps = {
  stepId: string;
  branch: Branch;
  deleteBranch: (id: string) => void;
  updateFlow: (action: (flow: Flow) => void) => void;
};

const BranchView: React.FC<BranchViewProps> = ({ stepId, branch, deleteBranch, updateFlow }) => {
  const snapshot = useSnapshot();
  const client = useClient('flow');
  const { isReadOnlyBot} = useBots();
  const sidePanel = useSidePanel();
  const typeName = 'IfStep';
  const ref = useRef<HTMLDivElement>(null);

  const save = async (newName: string) => {
    const result = await client.steps.ifs.updateBranchNameAsync(snapshot.snapshot.id, stepId, branch.id, newName);
    if (result.success) {
      updateFlow(() => {
        branch.name = newName;
      });
    };
    return result.success;
  }

  const contextMenu = useContextMenu(
    ref,
    [
      {
        label: (
          <span className={Color(VisualCategory.Danger)}>
            <Icon type={IconType.Delete} /> Delete Branch
          </span>
        ),
        action: () => {
          deleteBranch(branch.id);
        },
        isDisabled: false,
      },
    ],
    false,
  );

  const activeIconColor = POLICY_ICON_STATUS_COLOR.active;
  const inactiveIconColor = POLICY_ICON_STATUS_COLOR.inactive;
  const descriptionIcon = branch.description ? (
    <Icon type={IconType.Sticky} color={activeIconColor} size="xs" />
  ) : (
    <Icon type={IconType.Sticky} style={IconStyle.Outline} color={inactiveIconColor} size="xs" />
  );

  const modal = usePortalModal();

  return (
    <div id={branch.id} className="flexItem">
      <div className="branch">
        <div style={{ pointerEvents: isReadOnlyBot ? 'none' : 'initial' }} ref={ref}>
          <div className="name-and-description" style={{ display: 'flex', alignItems: 'center' }}>
            <EditableLabel text={branch.name} inputLabel="Branch Name" className="noselect" save={save} />
            <span
              style={{ pointerEvents: isReadOnlyBot ? 'none' : 'initial', cursor: 'pointer' }}
              data-tooltip-id="If-condition-description-note"
              data-tooltip-content={branch.description}
              onClick={() =>
                modal.openModal(
                  <EditValueModal
                    label="Description"
                    icon={IconType.Sticky}
                    value={branch.description}
                    update={async (desc: string) => {
                      await client.steps.ifs.updateBranchDescriptionAsync(snapshot.snapshot.id, stepId, branch.id, desc);
                      updateFlow(() => (branch.description = desc));
                    }}
                  />,
                )
              }
            >
              {descriptionIcon}
            </span>
          </div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              sidePanel.setContents('Edit Conditions', <BranchEditor branch={branch} stepId={stepId} />);
              sidePanel.open();
            }}
          >
             <Icon type={IconType.Pencil} /> Conditions
          </button>
        </div>
        {contextMenu}
        <Tooltip id="If-condition-description-note" />
        <StepManager
          identifier={branch.id}
          steps={branch.steps}
          sourceIdentifier={{ type: typeName, parentId: stepId }}
          stepsUpdated={(steps) => {
            updateFlow(() => (branch.steps = steps));
          }}
          // waiting on updated Visuals package
          reorderSteps={async (idsInNewOrder) => {
            try {
              await client.steps.ifs.reorderStepsInIfBranch(snapshot.snapshot.id, stepId, branch.id, idsInNewOrder);
              return true;
            } catch {
              return false;
            }
          }}
          addSteps={async (steps, fromSource, index) => {
            try {
              // we go down so that the items are placed in the expected order. If I have a list of 1,2,3,4, and I push A and B to index 2, I expect 1,2,A,B,3,4.
              for (let i = steps.length - 1; i >= 0; i--) {
                const step = steps[i];
                if (!step.id) {
                  const response = await client.steps.ifs.createStepInIfBranch(
                    snapshot.snapshot.id,
                    stepId,
                    branch.id,
                    step.type,
                    step.name,
                    index,
                  );
                  if (!response.success) {
                    return false;
                  }
                  step.id = response.data!;
                } else {
                  await client.steps.ifs.moveStepToIfBranch(
                    snapshot.snapshot.id,
                    stepId,
                    branch.id,
                    step.id,
                    new Container(
                      fromSource?.sourceIdentifier?.type,
                      fromSource?.sourceIdentifier?.parentId,
                      fromSource?.identifier,
                    ),
                    index,
                  );
                }
              }
              return true;
            } catch (e) {
              console.error(e);
              return false;
            }
          }}
          deleteSteps={async (steps) => {
            try {
              for (let step of steps) {
                await client.steps.deleteAsync(
                  snapshot.snapshot.id,
                  step.id,
                  new Container(typeName, stepId, branch.id),
                );

                updateFlow(async () => {
                  const index = branch.steps.findIndex((s) => s.id === step.id);
                  if (index > -1) {
                    branch.steps = arrayRemove(branch.steps, index);
                  }
                });
              }
              return true;
            } catch (e) {
              return false;
            }
          }}
          allowedTypes={AllButConditionalStepTypes}
          hasTrash={false}
          isContainer={false}
        />
      </div>
      {modal.modal}
    </div>
  );
};

// Modal component for editing description.
type StepModalProps = {
  label: string;
  icon: IconType;
  value: string;
  update: (value: string) => Promise<void>;
};

const EditValueModal: React.FC<StepModalProps> = ({ label, icon, value, update }) => {
  const fieldId = useId('stepValue');
  const [newValue, setNewValue] = useState(value);
  const [isSaving, setIsSaving] = useState(false);
  const modal = useFromModal();

  const header = {
    type: VisualCategory.Primary,
    content: (
      <React.Fragment>
        <Icon type={icon} /> Edit {label}
      </React.Fragment>
    ),
  };

  const content = (
    <React.Fragment>
      <Field inputId={fieldId} label={label} name="description" groupClass="col">
        <input
          id={fieldId}
          className="form-control"
          type="text"
          value={newValue}
          onChange={(evt) => setNewValue(evt.target.value)}
        />
      </Field>
    </React.Fragment>
  );

  const cancel = () => modal.forceClose();
  const saveClick = async () => {
    setIsSaving(true);
    await update(newValue ?? '');
    modal.forceClose();
  };

  const buttons = (
    <React.Fragment>
      <Button action={saveClick} isDisabled={isSaving} type={VisualCategory.Primary}>
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
      <Button action={cancel} type={VisualCategory.Secondary}>
        Cancel
      </Button>
    </React.Fragment>
  );

  return <PortalModal header={header} content={content} buttons={buttons} />;
};
