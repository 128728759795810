import { DataResponse, Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Condition, Container, StepType } from 'internal/models';
import { IIfClient } from './IIfClient';

export class IfClient implements IIfClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }
  async createBranchAsync(snapshotId: string, stepId: string, name: string): Promise<DataResponse<string>> {
    const request = {
      name: name,
    };

    const response = await this.methods.post(`${this.getUrl(snapshotId, stepId)}/branches`, request);

    return await this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Created} Branch`, response);
  }

  async updateBranchNameAsync(
    snapshotId: string,
    stepId: string,
    branchId: string,
    name: string,
  ): Promise<ServerResponse> {
    const response = await this.methods.plainBodyRequest(
      `${this.getUrl(snapshotId, stepId)}/branches/${branchId}/name`,
      'PUT',
      name,
    );

    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Branch Name`, response);
  }

  async updateBranchDescriptionAsync(
    snapshotId: string,
    stepId: string,
    branchId: string,
    description: string,
  ): Promise<ServerResponse> {
    const response = await this.methods.plainBodyRequest(
      `${this.getUrl(snapshotId, stepId)}/branches/${branchId}/description`,
      'PUT',
      description,
    );

    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Branch Description`, response);
  }

  async createStepInIfBranch(
    snapshotId: string,
    ifStepId: string,
    branchId: string,
    type: StepType,
    name: string,
    index?: number,
  ): Promise<DataResponse<string>> {
    const request = {
      type: type,
      name: name,
    };

    let url = `${this.getUrl(snapshotId, ifStepId)}/branches/${branchId}/steps`;

    if (index !== undefined) {
      url += `?index=${index}`;
    }
    const response = await this.methods.post(url, request);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Step In If Branch`,
      response,
    );
  }

  async moveStepToIfBranch(
    snapshotId: string,
    ifStepId: string,
    branchId: string,
    stepId: string,
    container: Container,
    index?: number,
  ): Promise<void> {
    let url = `${this.getUrl(snapshotId, ifStepId)}/branches/${branchId}/steps/${stepId}`;
    url += `?index=${index}`;

    const response = await this.methods.put(url, container);
    // throws an exception if this fails.
    await this.methods.handleErrors('Move Step to If Branch', response);
  }

  async reorderStepsInIfBranch(snapshotId: string, ifStepId: string, branchId: string, stepIds: string[]) {
    let url = `${this.getUrl(snapshotId, ifStepId)}/branches/${branchId}/steps`;
    const response = await this.methods.patch(url, stepIds);
    await this.methods.handleErrors('Updating Step Order in EntryPoint', response);
  }

  async deleteBranchAsync(snapshotId: string, ifStepId: string, branchId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, ifStepId)}/branches/${branchId}`;

    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Deleted} Branch from If Step`,
      response,
    );
  }

  async createConditionAsync(
    snapshotId: string,
    ifStepId: string,
    branchId: string,
    condition: Condition,
  ): Promise<DataResponse<string>> {
    const url = `${this.getUrl(snapshotId, ifStepId)}/branches/${branchId}/conditions`;
    const response = await this.methods.post(url, condition);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Condition in If Branch`,
      response,
    );
  }

  async updateConditionAsync(
    snapshotId: string,
    ifStepId: string,
    branchId: string,
    conditionId: string,
    condition: Condition,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, ifStepId)}/branches/${branchId}/conditions/${conditionId}`;

    // This extracts id from the condition, which we don't want to pass along (because it's set in the URL)
    const { id, ...request } = condition;
    const response = await this.methods.put(url, request);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Updated} Condition in If Branch`,
      response,
    );
  }

  async deleteConditionAsync(
    snapshotId: string,
    ifStepId: string,
    branchId: string,
    conditionId: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, ifStepId)}/branches/${branchId}/conditions/${conditionId}`;

    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Deleted} Condition from If Branch`,
      response,
    );
  }

  private getUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }
}
