import { Table, TableRow } from 'ui/components';
import { v4 as uuid } from 'uuid';
import { defaultTimeoutsEnum } from './Properties';
import { useClient, useProperties, useSnapshot, useTenant } from 'contexts';

const TimeoutTable:React.FC<{isReadOnly:boolean}> = ({isReadOnly}) => {
  const client = useClient('properties');
  const tenant = useTenant();
  const snapshot = useSnapshot();
  const properties = useProperties();

  const handleTimeoutChange = (value: string, timeout: string) => {
    if (tenant?.id) {
      client.updateTimeout(snapshot.snapshot.id, tenant?.id, value, timeout);
    }
  };

  const initialTimeoutValues: TableRow = {
    id: uuid(),
    cells: [
      {
        type: 'input',
        disabled: isReadOnly,
        id: defaultTimeoutsEnum.initialListen,
        value: `${properties?.defaultTimeouts?.initialListen}`,
        onChange: (e) => handleTimeoutChange(`${e.value}`, defaultTimeoutsEnum.initialListen),
      },
      {
        type: 'input',
        disabled: isReadOnly,
        id: 'segmentation-silence',
        value: `${properties?.defaultTimeouts?.segmentationSilence}`,
        onChange: (e) => handleTimeoutChange(`${e.value}`, defaultTimeoutsEnum.segmentationSilence),
      },
      {
        type: 'input',
        disabled: isReadOnly,
        id: 'dtmf-segmentation-silence',
        value: `${properties?.defaultTimeouts?.dtmfSegmentationSilence}`,
        onChange: (e) => handleTimeoutChange(`${e.value}`, defaultTimeoutsEnum.dtmfSegmentationSilence),
      },
    ],
  };

  return (
    <div>
      <h2>Timeouts</h2>
      <Table
        id="propertyServiceTable"
        headersGroup={['Initial Silence', 'Segmentation', 'DTMF Segmentation']}
        columnsSize={[200, 200, 200]}
        rows={[initialTimeoutValues]}
      />
    </div>
  );
};

export default TimeoutTable;
