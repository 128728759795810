import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, Field, PortalModal, useFromModal } from '@smartaction/visuals';
import React, { useState } from 'react';
import { useClient, useResources, useSnapshot } from 'contexts';

export const AddGoogleAddressResourceModal = () => {
  const { snapshot } = useSnapshot();
  const { refresh } = useResources();
  const client = useClient('resources');
  const [isSaving, setIsSaving] = useState(false);
  const modal = useFromModal();
  const [name, setName] = useState<string>('');
  const [apiKey, setApiKey] = useState<string>('');

  const header = {
    type: VisualCategory.Primary,
    content: (
      <React.Fragment>
        <Icon type={IconType.Add} /> Add Twilio Resource
      </React.Fragment>
    ),
  };

  const content = (
    <>
      <Field inputId={'name'} label="Name" name="Name">
        <input
          id={'name'}
          className="form-control"
          type="text"
          value={name}
          onChange={(ev) => setName(ev.target.value)}
        />
      </Field>
      <Field inputId={'apiKey'} label="API Key" name="ApiKey">
        <input
          id={'apiKey'}
          className="form-control"
          type="text"
          value={apiKey}
          onChange={(evt) => {
            setApiKey(evt.target.value);
          }}
        />
      </Field>
    </>
  );

  const cancel = () => modal.forceClose();
  const saveClick = async () => {
    setIsSaving(true);

    if (name && apiKey) {
      await client.address.create(snapshot.id, { name, apiKey }).then((r) => r.success && refresh(true));
    }

    modal.forceClose();
    setIsSaving(false);
  };

  const buttons = (
    <React.Fragment>
      <Button className={'me-3'} action={saveClick} isDisabled={isSaving} type={VisualCategory.Primary}>
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
      <Button action={cancel} type={VisualCategory.Secondary}>
        Cancel
      </Button>
    </React.Fragment>
  );

  return <PortalModal className={'add-g-address-resource-modal'} header={header} content={content} buttons={buttons} />;
};
