import { Methods, DataResponse, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { BargeInIntents, BargeInKeywords, Behavior } from 'internal/models';
import { IBargeInPolicyClient } from './IBargeInPolicyClient';

export class BargeInPolicyClient implements IBargeInPolicyClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }

  async createBargeInAsync(snapshotId: string, policyId: string, name: string): Promise<DataResponse<string>> {
    const url = `${this.getUrl(snapshotId, policyId)}/barge-ins`;
    const response = await this.methods.post(url, name);
    return await this.methods.validatePopToastAndReturnId(`${ValidateResponseActionEnum.Created} BargeIn`, response);
  }

  async setEnabledAsync(snapshotId: string, policyId: string, enable: boolean): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, policyId)}/enabled`;
    const response = await this.methods.put(url, enable);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Enable`, response);
  }
  async setExcludeParents(snapshotId: string, policyId: string, excludeParents: boolean): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, policyId)}/exclude-parents`;
    const response = await this.methods.put(url, excludeParents);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} InheritanceType`, response);
  }
  async setBargeInNameAsync(
    snapshotId: string,
    policyId: string,
    bargeInId: string,
    name: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, policyId)}/barge-ins/${bargeInId}/name`;
    const response = await this.methods.put(url, name);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Name`, response);
  }

  async deleteBargeInAsync(snapshotId: string, policyId: string, bargeInId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, policyId)}/barge-ins/${bargeInId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} BargeIn`, response);
  }

  async setBargeInTriggersAsync(
    snapshotId: string,
    policyId: string,
    bargeInId: string,
    keywords: BargeInKeywords[],
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, policyId)}/barge-ins/${bargeInId}/keywords`;
    const response = await this.methods.put(url, keywords);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Barge-In Keywords`, response);
  }
  async setBargeInIntentsAsync(
    snapshotId: string,
    policyId: string,
    bargeInId: string,
    intents: BargeInIntents[],
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, policyId)}/barge-ins/${bargeInId}/intents`;
    const response = await this.methods.put(url, intents);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Barge-In Intents`, response);
  }

  async setBargeInDtmfAsync(
    snapshotId: string,
    policyId: string,
    bargeInId: string,
    dtmfKey: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, policyId)}/barge-ins/${bargeInId}/dtmf`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', dtmfKey);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} DTMF`, response);
  }

  async setBargeInBehaviorAsync(
    snapshotId: string,
    policyId: string,
    bargeInId: string,
    behavior: Behavior,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, policyId)}/barge-ins/${bargeInId}/behavior`;
    const response = await this.methods.put(url, behavior);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Behavior`, response);
  }

  private getUrl(snapshotId: string, policyId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/policies/${policyId}`;
  }
}
