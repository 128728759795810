import { ConvoContainer } from "./Container";

export class ExecutionUnit  {

    unitType: UnitType;
    type: string;
    timestamp: string;
    result: string;
    /** Action is broken up when viewing it in the Summary Data. The string prettifier breaks 
     * strings into arrays for simplicity, so we have to support treating the action as a string[]. */
    action: string | string[];
    /** Ditto user input */
    userInput: string | string[];
    container: ConvoContainer;
    tags: Array<string>;


    constructor(unitType: UnitType, type: string, timestamp:string, result:string, action:string, userInput:string, container: ConvoContainer, tags: Array<string>) {

        this.unitType = unitType;
        this.type = type;
        this.timestamp = timestamp;
        this.result = result;
        this.action = action;
        this.userInput = userInput;
        this.container = container;
        this.tags = tags;
    }

    static fromJson(json: any) {
        const tags = json.configs && Array.isArray(json.tags)
        ? json.tags.map((f: string) => { return f;})
        : [];

        return new ExecutionUnit(json.unitType, json.type, json.timestamp, json.result, json.action, 
            json.userInput, json.container, tags);
    }
}

export enum UnitType { Step, Policy, Behavior }