import { IEvent } from "@smartaction/common";

export class ModuleItemRenamedEvent implements IEvent {
    public name: string = ModuleItemRenamedEvent.eventName;
    static eventName: "ModuleItemRenamedEvent" = "ModuleItemRenamedEvent";

    itemId: string;
    newName: string;

    constructor(itemId: string, newName: string) {
        this.itemId = itemId;
        this.newName = newName;
    }
}