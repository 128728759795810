import { BehaviorType } from "./BehaviorType";
import { Behavior } from "./Behavior";


export class ResumeBehavior extends Behavior {

    constructor() {
        super(BehaviorType.Resume);
    }

    static fromJson(json: any): ResumeBehavior {
        return new ResumeBehavior();
    }

}
