export class Container {
  type?: string;
  parentId?: string;
  containerId?: string;

  constructor(type?: string, parentId?: string, containerId?: string) {
    this.type = type;
    this.parentId = parentId;
    this.containerId = containerId;
  }
}
