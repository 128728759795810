import { BaseModel } from "@smartaction/common";
import { Position } from "@smartaction/visuals/Types";
import { ModuleChildTypes } from 'internal/models/bots/design/flow/moduleItems';

export abstract class Component<T> extends BaseModel<T> {
    readonly abstract type: ModuleChildTypes;
    description: string;
    position: Position;

    constructor(id: string | undefined, name: string, description: string, position: Position) {
        super(name, id);
        this.description = description || "";
        this.position = position;
    }
}