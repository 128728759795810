import { CustomFieldType, LangType } from 'internal/models';

export const CustomFieldTypeOptions = [
  { label: CustomFieldType.String, value: CustomFieldType.String },
  { label: CustomFieldType.Number, value: CustomFieldType.Number },
  { label: CustomFieldType.Boolean, value: CustomFieldType.Boolean },
  { label: CustomFieldType.Date, value: CustomFieldType.Date },
];

export const TimezoneOptions = [
  { value: 'Pacific Standard Time', label: 'Pacific Standard Time' },
  { value: 'Mountain Standard Time', label: 'Mountain Standard Time' },
  { value: 'Central Standard Time', label: 'Central Standard Time' },
  { value: 'Eastern Standard Time', label: 'Eastern Standard Time' },
];

export const LanguageCodeOptions = [
  { label: LangType.Eng_US, value: LangType.Eng_US },
  { label: LangType.Es_Mx, value: LangType.Es_Mx },
];
