import { Step } from '../Step';
import { StepType } from '../Type';

export class WaitForAgent extends Step {
  botId: string;
  contextItemIds: Array<ContextItemMap>;

  constructor(
    id: string | undefined,
    name: string,
    description: string,
    tags: string[],
    botId: string,
    contextItems: Array<ContextItemMap>,
  ) {
    super(id, name, description, StepType.WaitForAgent, tags);
    this.botId = botId;
    this.contextItemIds = contextItems;
  }

  isDirty(original: Step): boolean {
    throw new Error('Method not implemented.');
  }
  getDirtyFields(original: Step): string[] {
    throw new Error('Method not implemented.');
  }
  clone(): Step {
    throw new Error('Method not implemented.');
  }

  static fromJson(json: any): WaitForAgent {
    return new WaitForAgent(json.id, json.name, json.description, json.tags, json.botId, json.contextItemIds);
  }
}

export class ContextItemMap {
  currentContextItemId: string;
  secondAgentContextItemOriginalId: string;

  constructor(currentContextItemId: string, secondAgentContextItemOriginalId: string) {
    this.currentContextItemId = currentContextItemId;
    this.secondAgentContextItemOriginalId = secondAgentContextItemOriginalId;
  }

  static fromJson(json: any) {
    return new ContextItemMap(json.currentContextItemId, json.secondAgentContextItemOriginalId);
  }
}
