import { If } from 'internal/models';
import React from 'react';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { IconType } from '@smartaction/styles';
import { BranchesView } from './BranchesView';
import { ProcessStepsColors } from '../../StepColorGroups';

export const IfStyling: StepStyling = {
    typeName: "If",
    icon: IconType.Branch,
    ...ProcessStepsColors
}

export const IfStepView: React.FC<TypedStepProps<If>> = ({ step, manipulateStep }) => {
    return <StepView step={step} styling={IfStyling} branches={<BranchesView step={step} />} manipulateStep={manipulateStep} />;
}