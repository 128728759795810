import { CustomFieldType } from './CustomField';

export abstract class CustomFieldValue {
  type: CustomFieldType;

  constructor(type: CustomFieldType) {
    this.type = type;
  }

  abstract toJson(): any;
  static fromJson(json: any): CustomFieldValue {
    switch (json.type) {
      case CustomFieldType.String:
        return StringFieldValue.fromJson(json);
      case CustomFieldType.Number:
        return NumberFieldValue.fromJson(json);
      case CustomFieldType.Boolean:
        return BooleanFieldValue.fromJson(json);
      case CustomFieldType.Date:
        return DateFieldValue.fromJson(json);
    }
    throw new Error("Location Value received doesn't match supported types");
  }
}

export class StringFieldValue extends CustomFieldValue {
  value: Map<string, string>;

  constructor(value: Map<string, string>) {
    super(CustomFieldType.String);

    this.value = value;
  }

  toJson() {
    const obj = {
      type: CustomFieldType.String,
      value: {},
    };
    this.value.forEach((v, k) => {
      (obj.value as any)[k] = v;
    });

    return obj;
  }

  static fromJson(json: any) {
    var map = new Map<string, string>();
    for (var key in json.value) {
      map.set(key, json.value[key]);
    }
    return new StringFieldValue(map);
  }
}

export class NumberFieldValue extends CustomFieldValue {
  value: number;

  constructor(value: number) {
    super(CustomFieldType.Number);

    this.value = value;
  }

  static fromJson(json: any) {
    return new NumberFieldValue(json.value);
  }

  toJson() {
    return { type: CustomFieldType.Number, value: this.value };
  }
}

export class BooleanFieldValue extends CustomFieldValue {
  value: boolean;

  constructor(value: boolean) {
    super(CustomFieldType.Boolean);

    this.value = value;
  }

  static fromJson(json: any) {
    return new BooleanFieldValue(json.value);
  }

  toJson() {
    return { type: CustomFieldType.Boolean, value: this.value };
  }
}

export class DateFieldValue extends CustomFieldValue {
  value: CustomDate;

  constructor(value: CustomDate) {
    super(CustomFieldType.Date);

    this.value = value;
  }

  static fromJson(json: any) {
    return new DateFieldValue(json.value);
  }

  toJson() {
    return { type: CustomFieldType.Date, value: this.value};
  }
}

export class CustomDate {
  month:string;
  day:string;
  year:string; 

  constructor(month: string, day:string, year:string){
    this.month = month;
    this.day = day;
    this.year = year; 
  }

  static fromJson(json: any) {
    return new CustomDate(json.month, json.day, json.year);
  }

  toJson() {
    return { month: this.month, day: this.day, year: this.year};
  }
}
