import { ValidationErrors } from '@smartaction/common';
import {
  DataResponse,
  ServerResponse,
  IConfigClient,
  IConfigsClient,
  Methods,
  ValidateResponseActionEnum,
} from 'internal/clients';
import { Config, ConfigType, ConfigValue, DateRange, ValueType, Environment, TwilioValue } from 'internal/models';

export class ConfigsClient implements IConfigsClient {
  constructor(serviceUrl: string, methods: Methods) {
    this.business = new ConfigClient(serviceUrl, methods, 'business');
    this.technical = new ConfigClient(serviceUrl, methods, 'technical');
  }

  business: IConfigClient;
  technical: IConfigClient;
}

export class ConfigClient implements IConfigClient {
  serviceUrl: string;
  methods: Methods;
  configType: string;

  constructor(serviceUrl: string, methods: Methods, configType: string) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
    this.configType = configType;
  }
  async getAll(snapshotId: string): Promise<DataResponse<Config[]>> {
    const response = await this.methods.get(this.rootUrl(this.methods.tenantId!, snapshotId, this.configType));
    const result = await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Get} Configs`, response);

    if (!result.success) {
      return result;
    }

    const json = await response.json();
    return { success: true, data: json.map((b: any) => Config.fromJson(b)) };
  }
  async get(snapshotId: string, id: string): Promise<DataResponse<Config>> {
    throw new Error('Method not implemented.');
  }
  async getSensitiveValues(snapshotId: string, id: string): Promise<DataResponse<ConfigValue[]>> {
    throw new Error('Method not implemented.');
  }
  async create(
    snapshotId: string,
    name: string,
    type: ConfigType,
    isSensitive: boolean,
    updateValidationErrors: (update: (errors: ValidationErrors) => void) => void,
  ): Promise<DataResponse<string>> {
    const request = {
      name: name,
      type: type,
      isSensitive: isSensitive,
    };

    const response = await this.methods.post(
      this.rootUrl(this.methods.tenantId!, snapshotId, this.configType),
      request,
    );
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Config`,
      response,
      updateValidationErrors,
    );
  }
  async delete(snapshotId: string, id: string): Promise<ServerResponse> {
    const url = `${this.rootUrl(this.methods.tenantId!, snapshotId, this.configType)}/${id}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Config`, response);
  }

  async updateName(snapshotId: string, id: string, name: string): Promise<ServerResponse> {
    const response = await this.methods.put(
      `${this.rootUrl(this.methods.tenantId!, snapshotId, this.configType)}/${id}/name`,
      name,
    );
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Config name`, response);
  }
  async updateDescription(snapshotId: string, id: string, description: string): Promise<ServerResponse> {
    const response = await this.methods.put(
      `${this.rootUrl(this.methods.tenantId!, snapshotId, this.configType)}/${id}/description`,
      description,
    );
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Config description`, response);
  }
  async updateType(snapshotId: string, id: string, type: ConfigType): Promise<ServerResponse> {
    const response = await this.methods.put(
      `${this.rootUrl(this.methods.tenantId!, snapshotId, this.configType)}/${id}/type`,
      type,
    );
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Config type`, response);
  }
  async updateSensitivity(snapshotId: string, id: string, isSensitive: boolean): Promise<ServerResponse> {
    const response = await this.methods.put(
      `${this.rootUrl(this.methods.tenantId!, snapshotId, this.configType)}/${id}/isSensitive`,
      isSensitive,
    );
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Config sensitivity`, response);
  }
  async getValueForEnvironment(
    snapshotId: string,
    id: string,
    environment: Environment,
  ): Promise<DataResponse<ConfigValue>> {
    const response = await this.methods.get(
      `${this.rootUrl(this.methods.tenantId!, snapshotId, this.configType)}/${id}/${environment}/sensitive`,
    );
    const result = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} Config Value for ${environment}`,
      response,
    );

    if (!result.success) {
      return result;
    }

    const json = await response.json();
    return { success: true, data: ConfigValue.fromJson(json) };
  }
  async setValueForEnvironment(
    snapshotId: string,
    id: string,
    environment: Environment,
    value: ConfigValue,
  ): Promise<ServerResponse> {
    const route = `${this.rootUrl(this.methods.tenantId!, snapshotId, this.configType)}/${id}/${environment}`;

    let response: Response;
    if (value.type === ValueType.Unset) {
      response = await this.methods.delete(route);
    } else if (value.type === ValueType.DateRange) {
      const dateRange = value.value as DateRange;
      response = await this.methods.put(route, { type: value.type, ...dateRange });
    } else if (value.type === ValueType.Twilio) {
      const twiVal = value.value as TwilioValue;
      response = await this.methods.put(route, { type: value.type, ...twiVal });
    } else {
      response = await this.methods.put(route, value);
    }

    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Config Value for ${environment}`,
      response,
    );
  }

  private rootUrl(tenantId: string, snapshotId: string, configType: string) {
    return `${this.serviceUrl}/tenant/${tenantId}/v1/snapshot/${snapshotId}/${configType}`;
  }
}
