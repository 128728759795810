import React from 'react';
import { Table } from 'ui/components';
import { Button } from "@smartaction/visuals";
import { VisualCategory } from "@smartaction/styles";
import { Endpoint } from 'internal/models';
import { useEndpointLogic } from './EndpointLogic';

interface EndpointTableProps {
  endpoints: Endpoint[];
  apiId: string;
  isDisabled: boolean;
}

const EndpointTable: React.FC<EndpointTableProps> = ({ endpoints, apiId, isDisabled }) => {
  const { rows, isLoading, onDeleteRow, onAddNewRow, onExpandAll, onExpandRow } = 
    useEndpointLogic({ endpoints, apiId, isDisabled });
  
  return (
    <Table
      id={'table'}
      headersGroup={['Endpoints', 'Description', 'Environments']}
      columnsSize={[215, 215]}
      rows={rows}
      onDeleteRow={onDeleteRow}
      isLoading={isLoading}
      style={{ maxWidth: 750 }}
      onExpandAll={onExpandAll}
      onExpandRow={onExpandRow}
      footer={
        <Button type={VisualCategory.Primary} action={onAddNewRow} isDisabled={isDisabled}>
          Add endpoint
        </Button>
      }
    ></Table>
  );
};

export default EndpointTable;
