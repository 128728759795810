import { ICellEditorParams } from 'ag-grid-community';
import { ConfigValue, ValueType } from 'internal/models';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { StringListCellEditor } from 'ui/controls';

export const MultiStringCellEditor = forwardRef((props: ICellEditorParams, ref: React.ForwardedRef<unknown>) => {
    const configValue = (props.value as ConfigValue);
    const [changed, setChanged] = useState(false);
    const [values, setValues] = useState(configValue.type === ValueType.MultiString ? configValue.value as string[] : []);

    const update = (strings: string[]) => {
        setValues(strings);
        setChanged(true);
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return values 
                    ? new ConfigValue(ValueType.MultiString, values) 
                    : new ConfigValue(ValueType.Unset, undefined);
            },
            isCancelAfterEnd: () => {
                return !changed || values === configValue.value;
            }
        };
    });

    return (
        <StringListCellEditor strings={values} setStrings={update}
            placeholder='Set Config Value' />
    )
});