import { Methods, ValidateResponseActionEnum } from 'internal/clients/Methods';
import { ServerResponse } from 'internal/clients/Responses';
import { IInputFormatterPolicyClient } from './IInputFormatterPolicyClient';

export class InputFormatterPolicyClient implements IInputFormatterPolicyClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }

  async setScript(snapshotId: string, policyId: string, script: string): Promise<ServerResponse> {
    const url = `${this.serviceUrl}/tenant/${this.methods
      .tenantId!}/snapshot/${snapshotId}/v1/policies/${policyId}/script`;

    const response = await this.methods.plainBodyRequest(url, 'PUT', script);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Updated} Input Formatter script`,
      response,
    );
  }
}
