import React, { useEffect, useState } from 'react';
import {
  Behavior,
  BehaviorType,
  CallFunctionBehavior,
  EmptyId,
  GoToBehavior,
  NoActionBehavior,
  ResumeBehavior,
  RetryStepBehavior,
  RunScriptBehavior,
  SkipBehavior,
  TalkBehavior,
  WaitBehavior,
} from 'internal/models';
import Select, { SingleValue } from 'react-select';
import { BehaviorTypeViewSelector } from './BehaviorTypeViewSelector';
import { BehaviorWithFollowUps } from 'internal/models/bots/design/flow/policies/behaviors/IFollowUpList';

type BehaviorControlProps = {
  allowedTypes: BehaviorType[];
  behavior: Behavior;
  update: (behavior: Behavior) => void;
  isFollowUp?: boolean;
  disabled?: boolean;
};

type BehaviorTypeOption = {
  label: string;
  value: BehaviorType;
};

export const BehaviorControl: React.FC<BehaviorControlProps> = ({
  allowedTypes,
  behavior,
  update,
  isFollowUp = false,
  disabled = false,
}) => {
  if (allowedTypes.some((t) => t === BehaviorType.NoAction)) {
    throw new Error(
      'You should only include the behavior types you actually need to use; NoAction is always an option.',
    );
  }

  const [behaviorOptions, setBehaviorOptions] = useState(BuildOptions(allowedTypes, behavior));
  const [behaviorType, setBehaviorType] = useState<BehaviorTypeOption>(NoActionOption);

  useEffect(() => {
    const options = BuildOptions(allowedTypes, behavior);
    setBehaviorOptions(options);
    setBehaviorType(options.find((x) => x.value === behavior.type) ?? options[0]);
  }, [behavior]);

  const onChange = (newVal: SingleValue<BehaviorTypeOption>) => {
    if (!newVal || newVal.value === BehaviorType.NoAction) {
      update(new NoActionBehavior());
      setBehaviorType(NoActionOption);
      return;
    }
    const newBehavior = CreateNewBehavior(newVal.value, behavior);
    update(newBehavior);
  };

  const typeView = BehaviorTypeViewSelector(behavior, (b) => update(b), allowedTypes, isFollowUp);

  return (
    <div className="behavior-control">
      <div className="row">
        <label className="col-sm-3 col-form-label">Behavior</label>
        <div className="col-sm-9 behavior-control-type">
          <Select<BehaviorTypeOption>
            options={behaviorOptions}
            isDisabled={disabled}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 2147483647 }) }}
            value={behaviorType}
            onChange={onChange}
          />
        </div>
      </div>
      <div style={{ pointerEvents: disabled ? 'none' : 'initial' }}  className="type-view">{typeView}</div>
    </div>
  );
};

function BuildOptions(types: BehaviorType[], behavior: Behavior): BehaviorTypeOption[] {
  const opts = [
    NoActionOption,
    ...types.map((t) => {
      return { label: names.get(t)!, value: t };
    }),
  ];

  if (behavior.type === BehaviorType.Unknown) {
    return [UnknownOption, ...opts];
  }

  return opts;
}

const names = new Map<BehaviorType, string>();
names.set(BehaviorType.GoTo, 'Go To');
names.set(BehaviorType.NoAction, 'No Action');
names.set(BehaviorType.RetryStep, 'Retry Step');
names.set(BehaviorType.CallFunction, 'Call Function');
names.set(BehaviorType.Resume, BehaviorType.Resume);
names.set(BehaviorType.RunScript, 'Run Script');
names.set(BehaviorType.Skip, 'Skip');
names.set(BehaviorType.Wait, 'Wait');

const NoActionOption = { label: names.get(BehaviorType.NoAction)!, value: BehaviorType.NoAction };
const UnknownOption = { label: 'Unrecognized Behavior', value: BehaviorType.Unknown };

function CreateNewBehavior(behaviorType: BehaviorType, oldBehavior: Behavior) {
  switch (behaviorType) {
    case BehaviorType.CallFunction:
      return new CallFunctionBehavior(EmptyId, false);
    case BehaviorType.GoTo:
      return new GoToBehavior(EmptyId);
    case BehaviorType.Resume:
      return new ResumeBehavior();
    case BehaviorType.RetryStep:
      const rOutputs = oldBehavior.type === BehaviorType.Talk ? (oldBehavior as TalkBehavior).output : [];
      return new RetryStepBehavior(rOutputs);
    case BehaviorType.RunScript:
      const sFollowUps =
        oldBehavior.type === BehaviorType.Wait || oldBehavior.type === BehaviorType.Talk
          ? (oldBehavior as BehaviorWithFollowUps).followUpBehaviors
          : [];
      return new RunScriptBehavior('', sFollowUps);
    case BehaviorType.Skip:
      return new SkipBehavior();
    case BehaviorType.Wait:
      const wFollowUps =
        oldBehavior.type === BehaviorType.RunScript || oldBehavior.type === BehaviorType.Talk
          ? (oldBehavior as BehaviorWithFollowUps).followUpBehaviors
          : [];
      return new WaitBehavior(10, [], wFollowUps);
    case BehaviorType.Talk:
      const tOutputs = oldBehavior.type === BehaviorType.RetryStep ? (oldBehavior as RetryStepBehavior).output : [];
      const tFollowUps =
        oldBehavior.type === BehaviorType.RunScript || oldBehavior.type === BehaviorType.Wait
          ? (oldBehavior as BehaviorWithFollowUps).followUpBehaviors
          : [];
      return new TalkBehavior(tOutputs, tFollowUps);
  }
  return new NoActionBehavior();
}
