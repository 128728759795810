import { ICellRendererParams } from 'ag-grid-community';
import { useConfigs, useContextItems, useFlow, useSnapshot } from 'contexts';
import { CompareCondition, CompareType, Condition, ConditionType, DateTimeCondition, EmptyPointer, PointerType } from 'internal/models';
import React, {  } from 'react';
import PointerTableCellRenderer from 'ui/controls/table/PointerTableCellRenderer';
import { isConfigDateTime, isContextItemDateTime } from './HelperConditionMethods';



type TargetRendererProps = ICellRendererParams<CompareCondition> & {
    allowedTargetTypes: Array<PointerType>;
    onUpdateCondition: (condition: Condition) => void;
}


export const TargetRenderer: React.FC<TargetRendererProps> = ({colDef, value, data, allowedTargetTypes, onUpdateCondition}) => {
    const condition = data as CompareCondition;
    const configs = useConfigs();
    const contextItems = useContextItems();
    

    return (
        
        <PointerTableCellRenderer
            pointer={condition.target}
            allowedTypes={allowedTargetTypes}
            onChange={ (newPointerValue) => {

            let isCtxDateTime = isContextItemDateTime(newPointerValue, contextItems.contextItems);
            let isCfgDateTime = isConfigDateTime(newPointerValue, configs.all);;
            
            if (
                (newPointerValue.type === PointerType.Context && isCtxDateTime) ||
                (newPointerValue.type === PointerType.Config && isCfgDateTime)
            ) {

                let newDateTimeCondition = { 
                    ...condition,
                    target: newPointerValue,
                    value: new EmptyPointer(),
                    type: ConditionType.DateTime,
                    comparison: CompareType.LessThan,
                } as DateTimeCondition;

                onUpdateCondition(newDateTimeCondition);

                } else if (!(isCtxDateTime || isCfgDateTime)) {

                let newCompareCondition = { 
                    ...condition,
                    target: newPointerValue,
                    type: ConditionType.IsPopulated,
                    value: {},
                } as CompareCondition;
                
                //This is defined behavior based on UserStory
                onUpdateCondition(newCompareCondition);

                } else {
                onUpdateCondition({ ...condition, target: newPointerValue });
                }
            } } /> 
    );

}
