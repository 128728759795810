import { DefaultPublisher } from '@smartaction/common';
import { TenantSelectedEvent } from 'event/TenantSelectedEvent';
import { Tenant } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { useAccess } from './AccessContext';
import { useClient } from './ClientContext';

type TenantData = Tenant & {
    entitlements: string[]
}

type TenantContextProps = {
    selectedTenant?: TenantData,
    availableTenants?: Map<string, Tenant>,
    setTenant: (tenantCode?: string) => void
}

const TenantContext = React.createContext<TenantContextProps | undefined>(undefined);

type TenantProviderProps = {
    children: React.ReactNode
}

export const TenantProvider: React.FC<TenantProviderProps> = ({children}) => {
    const [tenants, setTenants] = useState<Map<string, Tenant>>();
    const [selectedTenant, setSelectedTenant] = useState<TenantData>();
    const client = useClient("tenant");
    const access = useAccess();

    useEffect(() => {
        client.getAvailableTenantsAsync().then(list => {
            const newTenants = new Map<string, Tenant>();
            list.forEach(t => newTenants.set(t.code, t));
            setTenants(newTenants);
        });
    }, []);

    const props = {
        selectedTenant: selectedTenant,
        availableTenants: tenants,
        setTenant: (tenantCode?: string) => {
            if (tenants === undefined) {
                // No console warning, because this route could be hit early by someone pasting in a route that included a tenant 
                // in the path, and we're still loading tenants.
                return;
            }

            if (tenantCode === undefined) {
                setSelectedTenant(undefined);
                DefaultPublisher.publish(new TenantSelectedEvent());
                return;
            }

            const tenant = tenants.get(tenantCode);
            let tenantData: TenantData | undefined = undefined;
            
            if (tenant === undefined) {
                console.warn(`Tenant with code '${tenantCode}' could not be found. Resetting tenant to unselected.`);
            } else {
                const entitlements = access.tenantEntitlements.get(tenant.id) ?? [];
                tenantData = { ...tenant, entitlements: entitlements};
            }
            setSelectedTenant(tenantData);
            DefaultPublisher.publish(new TenantSelectedEvent(tenantData));
        }
    };

    return (
        <TenantContext.Provider value={props}>
            {children}
        </TenantContext.Provider>
    );
};

export const useTenant = () => {
    const context = React.useContext(TenantContext);

    if (context === undefined) {
        throw new Error("useTenant must be used within a TenantProvider!");
    }

    return context.selectedTenant;
}

export const useTenants = () => {
    const context = React.useContext(TenantContext);

    if (context === undefined) {
        throw new Error("useTenant must be used within a TenantProvider!");
    }

    return context;
}