import { PolicyType } from "./PolicyType";

export abstract class Policy {
    id: string;
    ownerId: string; 
    type: PolicyType;

    constructor(id: string, ownerId: string, type: PolicyType) {
        this.id = id;
        this.ownerId = ownerId;
        this.type = type;
    }

}

export class UnknownPolicy extends Policy {
    providedType: string;
    constructor(id:string, ownerId: string, providedType: string) {
        super(id, ownerId, PolicyType.Unknown);
        this.providedType = providedType;
    }
}
