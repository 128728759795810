import { IconType } from "@smartaction/styles";
import { StepStyling, StepView, TypedStepProps } from "../../Step";
import { Hangup } from "internal/models";
import { EndConversationStepsColors } from '../../StepColorGroups';

export const HangupStyling: StepStyling = {
    typeName: "Hang Up",
    icon: IconType.Hangup,
    ...EndConversationStepsColors
}

export const HangupStepView: React.FC<TypedStepProps<Hangup>> = ({ step, manipulateStep }) => {
    return <StepView step={step} styling={HangupStyling} manipulateStep={manipulateStep} />;
}