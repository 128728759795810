import React, { useEffect, useState } from 'react';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { AzureLanguage, NumericInput, PointerControlFilters, PointerType, ResourceType } from 'internal/models';
import { IconType } from '@smartaction/styles';
import { PointerControl } from 'ui/components';
import { Checkbox, Field, Input, Patience } from '@smartaction/visuals';
import { useNumericInputsLogic } from './useNumericStepLogic';
import { InputStepsColors } from '../../StepColorGroups';
import { CanInterruptView } from 'ui/components/design/CanInterruptView';
import Select from 'react-select';
import { useBots, useResources } from 'contexts';
import { GroupedOption } from 'ui/types';
import { StepOutputsView } from '../../StepOutputsView';

export const NumericInputStyling: StepStyling = {
  typeName: 'Numeric Input',
  icon: IconType.NumericInput,
  ...InputStepsColors,
};
export const NumericInputStepView: React.FC<TypedStepProps<NumericInput>> = ({ step, manipulateStep }) => {
  return (
    <StepView step={step} styling={NumericInputStyling} isCollapsible={true} manipulateStep={manipulateStep}>
      <NumericInputForm step={step} manipulateStep={manipulateStep} />
    </StepView>
  );
};

export const NumericInputForm: React.FC<TypedStepProps<NumericInput>> = ({ step }) => {
  const {
    isLoading,
    onMinCharChange,
    onMaxCharChange,
    onAllowAsterisksChange,
    onPoundChange,
    onPointerChange,
    onIntentChange,
    onExcludeUniversalModelChange,
  } = useNumericInputsLogic({
    step,
  });
  const resources = useResources();
  const noIntent = { label: 'No Intent', options: [{ label: 'No Intent', value: '' }] };
  const [options, setOptions] = useState<GroupedOption[]>([]);
  const [minValue, setMinValue] = useState<string>(step.minLength?.toString() || '0');
  const [maxValue, setMaxValue] = useState<string>(step.maxLength?.toString() || '0');
  const [isValidMinMaxValues, setIsValidMinMaxValues] = useState(true);
  const { isReadOnlyBot } = useBots();

  useEffect(() => {
    const azureLanguageResources = (resources.byType.get(ResourceType.AzureLanguage) as AzureLanguage[]) ?? [];
    const options = azureLanguageResources.map((nlu) => {
      const grouped = nlu.intents
        ?.filter((i) => i.name !== 'None')
        .map((el) => {
          return {
            label: el.name,
            value: nlu.id,
          };
        });

      return {
        label: nlu.modelName,
        options: grouped,
      };
    });

    setOptions([noIntent, ...options]);
  }, [step]);

  useEffect(() => {
    setIsValidMinMaxValues(+maxValue >= +minValue);
  }, [minValue, maxValue]);

  const inValidMinMaxBorderStyle = `1px solid ${isValidMinMaxValues ? 'initial' : 'red'}`;

  return (
    <div className="numeric-step-form">
      <Patience showPatience={isLoading}>
        <StepOutputsView typeName="Question" step={step} />
        <CanInterruptView ask={step}></CanInterruptView>
        <Field
          inputId={`min-char-${step.id}`}
          groupClass={'numeric-step-form-field'}
          label={'Min characters'}
          layout={'horizontal'}
        >
          <Input
            id={`min-char-${step.id}`}
            style={{ border: inValidMinMaxBorderStyle }}
            type="number"
            min="0"
            value={minValue}
            onChange={(value) => {
              setMinValue(value);
              onMinCharChange(value);
            }}
            disabled={isReadOnlyBot}
          />
        </Field>
        <Field
          inputId={`max-char-${step.id}`}
          groupClass={'numeric-step-form-field'}
          label={'Max characters'}
          layout={'horizontal'}
        >
          <Input
            id={`max-char-${step.id}`}
            style={{ border: inValidMinMaxBorderStyle }}
            type="number"
            min="0"
            value={maxValue}
            onChange={(value) => {
              setMaxValue(value);
              onMaxCharChange(value);
            }}
            disabled={isReadOnlyBot}
          />
        </Field>
        <Checkbox
          readonly={isReadOnlyBot}
          label={'Allow Asterisks'}
          value={step.allowAsterisks}
          setValue={onAllowAsterisksChange}
        />
        <Checkbox readonly={isReadOnlyBot} label={'Press # to end'} value={step.poundToEnd} setValue={onPoundChange} />
        <div>
          <h6>Save as</h6>
          <PointerControl
            types={[PointerType.Context, PointerType.Iteration]}
            pointer={step.saveLocation}
            update={onPointerChange}
            pointerFilters={[PointerControlFilters.HideStaticContextItems]}
          />
        </div>
        <div>
          <h6>Specify Alternate Intent</h6>
          {
            <Select
              options={options}
              value={
                step?.alternateIntent
                  ? {
                      value: step?.alternateResourceId,
                      label: step?.alternateIntent,
                    }
                  : noIntent.options[0]
              }
              isDisabled={isReadOnlyBot}
              onChange={onIntentChange}
              menuPosition="fixed"
            />
          }
          <Checkbox
            readonly={isReadOnlyBot}
            label={'Use Alternate Intent Only'}
            value={step.excludeUniversalModel}
            setValue={onExcludeUniversalModelChange}
          />
        </div>
      </Patience>
    </div>
  );
};
