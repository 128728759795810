import { Pointer } from "../../shared";
import { Output } from "../OutputMessages";
import { StepType } from "../Type";
import { Ask } from "./Ask";

export class DTMFMenu extends Ask {
    menuItems: DTMFMenuItem[];

    constructor(id: string | undefined, name: string, description: string, tags: string[], questions: Output[], menuItems: DTMFMenuItem[], saveLocation: Pointer, canInterrupt: boolean) {
        super(id, StepType.DTMFMenu, name, description, tags, questions, saveLocation, canInterrupt);
        this.menuItems = menuItems;
    }

    static fromJson(json: any): DTMFMenu {
        const questions = json.questions && Array.isArray(json.questions)
            ? json.questions.map((p: any) => Output.fromJson(p))
            : [];
        const menuItems = json.menuItems && Array.isArray(json.menuItems)
            ? json.menuItems.map((mi: any) => DTMFMenuItem.fromJson(mi))
            : [];
        return new DTMFMenu(json.id, json.name, json.description, json.tags, questions, menuItems, Pointer.fromJson(json.saveLocation), json.canUserInterrupt);
    }
}

export class DTMFMenuItem {
    id: string;
    key: string;
    languageCode: string;
    keywords: string[];

    constructor(id: string, key: string, languageCode: string, keywords: string[]) {
        this.id = id;
        this.key = key;
        this.languageCode = languageCode;
        this.keywords = keywords;
    }

    static fromJson(json: any) {
        return new DTMFMenuItem(json.id, json.key, json.languageCode, json.keywords);
    }
}