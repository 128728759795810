import { BehaviorType } from "./BehaviorType";
import { Behavior } from "./Behavior";


export class UnknownBehavior extends Behavior {

    constructor() {
        super(BehaviorType.Unknown);
    }
}
