export class CustomField {
  id: string;
  name: string;
  description: string;
  type: CustomFieldType;

  constructor(id: string, name: string, description: string, type: CustomFieldType) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.type = type;
  }

  static fromJson(json: any) {
    return new CustomField(json.id, json.name, json.description, json.type);
  }
}



export enum CustomFieldType {
  String = 'String',
  Number = 'Number',
  Date = 'Date',
  Boolean = 'Boolean',
}