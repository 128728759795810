import React from 'react';
import { Patience } from '@smartaction/visuals';
import { ResultType, ValidationEntitiesTypeEnum, ValidationResponse } from 'internal/models';
import { useLocation, useNavigate } from 'react-router-dom';
import { DefaultPublisher } from '@smartaction/common';
import { NavigateToEntityEvent } from 'event';
import { GenerateNewQuery } from '../design/flow/module/UpdateNodeQuery';

export type ValidationInfoDataProps = {
  data: ValidationResponse[];
  type: 'warnings' | 'errors';
  title: string;
};
export type ValidationResultProps = {
  data: ValidationResponse[] | undefined;
  loadingStatus?: boolean;
};
const ValidationResultData: React.FC<ValidationInfoDataProps> = ({ data, type, title }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onValidationMessageClick = (data: ValidationResponse) => {
    const validationEntityNode = data.entitiesStack.filter(
      (item: { type: ValidationEntitiesTypeEnum }) =>
        item.type === ValidationEntitiesTypeEnum.EntryPoint || item.type === ValidationEntitiesTypeEnum.Block,
    )[0]?.type;
    const getValidationEntityId = (entityId: ValidationEntitiesTypeEnum) =>
      data.entitiesStack.filter((item: { type: ValidationEntitiesTypeEnum }) => item.type === entityId)[0]?.id;

    if (!!validationEntityNode) {
      const newPathName = `${location.pathname.replace('publish', 'flow')}${GenerateNewQuery(
        getValidationEntityId(ValidationEntitiesTypeEnum.Module),
        getValidationEntityId(validationEntityNode),
      )}`;

      navigate(newPathName);
    }
    setTimeout(() => {
      DefaultPublisher.publish(new NavigateToEntityEvent(data.entitiesStack));
    }, 250);
  };

  return (
    <div className={`validation-info is--${type}`}>
      <h5 className={`validation-info-title is--${type}`}>{title}</h5>
      <ul>
        {data.map((element, index) => {
          return (
            <li key={`validation-result-${type}-${index}`}>
              <div className={'validation-info-item'}>
                <b>{'Message'}:</b>
                {element.entitiesStack.length === 1 ? (
                  <span>{element.message}</span>
                ) : (
                  <a className={'d-block text-truncate'} href={'#'} onClick={() => onValidationMessageClick(element)}>
                    {element.message}
                  </a>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const ValidationResult: React.FC<ValidationResultProps> = ({ data, loadingStatus }) => {
  const errors = data?.filter((v) => v.type.toString() === ResultType[ResultType.Error]);
  const warnings = data?.filter((v) => v.type.toString() === ResultType[ResultType.Warning]);

  return (
    <Patience showPatience={!!loadingStatus} className="margin-small">
      {data && <h4 className={'validation-info-title'}>Validation {errors?.length ? 'Unsuccessful' : 'Success'}</h4>}
      {!!errors?.length && <ValidationResultData data={errors} type={'errors'} title={'Errors Found'} />}
      {!!warnings?.length && <ValidationResultData data={warnings} type={'warnings'} title={'Warnings Found'} />}
    </Patience>
  );
};
export default ValidationResult;
