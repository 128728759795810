import { BehaviorType } from "./BehaviorType";
import { Behavior } from "./Behavior";


export class GoToBehavior extends Behavior {

    targetId: string;

    constructor(targetId: string) {
        super(BehaviorType.GoTo);
        this.targetId = targetId;
    }

    static fromJson(json: any): GoToBehavior {
        return new GoToBehavior(json.targetId);
    }


}
