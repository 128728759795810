import React, { useEffect, useState } from 'react';
import {
  CustomField,
  CustomFieldType,
  CustomFieldValue,
  Location,
  LocationDefinition,
  TimeRange,
} from 'internal/models';
import {
  LocationDefinitionProvider,
  LocationProvider,
  useClient,
  useLocationDefinition,
  useLocationDefinitions,
  useLocations,
} from 'contexts';
import { AgGridReact } from 'ag-grid-react';
import { CellValueChangedEvent, ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { toast } from 'react-toastify';
import { CustomFieldValueRenderer } from './renderers';
import { CustomFieldValueEditor, HoursFieldEditor } from './editors';
import { Button, ModalContainerIds, usePortalModal } from '@smartaction/visuals';
import { ColorHexes, Icon, IconStyle, IconType, VisualCategory } from '@smartaction/styles';
import ManageCustomFieldsModal from './ManageCustomFieldsModal';
import AddCustomFieldModal from './AddCustomFieldModal';
import { ActionsRenderer } from 'ui/controls/grid/editors/ActionsRenderer';
import { PronunciationsFieldEditor } from './editors/PronunciationsFieldEditor';
import { TimeZoneFieldEditor } from './editors/TimeZoneFieldEditor';
import { PostError, PostSuccess } from 'ui/events/PopToastEvent';

export const LocationsManagement: React.FC = () => {
  return (
    <LocationDefinitionProvider name="Default">
      <LocationProvider>
        <LocationsManagementContent />
      </LocationProvider>
    </LocationDefinitionProvider>
  );
};

export const LocationsManagementContent: React.FC = () => {
  const locationDefinition = useLocationDefinition();
  const { refresh: refreshCustomFields } = useLocationDefinitions();
  const { locations, refresh } = useLocations();
  const client = useClient('location');
  const lcClient = useClient('locationDefinition');
  const modal = usePortalModal(ModalContainerIds.Modal);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [colDefs, setColDefs] = useState<any[]>([]);

  useEffect(() => {
    setColDefs(createColDefs(locationDefinition, deleteFunc));
  }, [locationDefinition]);

  const save = async (event: CellValueChangedEvent) => {
    
    const location = event.data as Location;
    const columnDef = event.colDef;
    switch (columnDef.field) {
      case 'displayName':
        if (!event.newValue) {
          toast('Name must be set! Changes are not saved.', { type: 'error', containerId: 'default' });
          return;
        } else {
          await client.updateName(location.id, event.newValue);
        }
        break;
      case 'phoneNumber':
        await client.updatePhoneNumber(location.id, event.newValue);
        break;
      case 'address':
        await client.updateAddress(location.id, event.newValue);
        break;
      case 'customerId':
        await client.updateCustomerId(location.id, event.newValue);
        break;
      case 'timeZone':
        await client.updateTimeZone(location.id, event.newValue);
        break;
      case 'pronunciations':
        const val = event.newValue as Map<string, string>;
        await client.setPronunciations(location.id, val);
        break;
      case 'officeHours':
        let newtimeRanges : Map<string, TimeRange[]> = event.value;

        const officeHrTaskUpdates = Array.from(newtimeRanges.entries()).map(([key, value]) => {
          return client.updateHours(location.id, `${key}`, value);
        });

        Promise.all(officeHrTaskUpdates).then( response => {
          PostSuccess('Saved!');
        }).catch( error => {
          PostError(error, true);
        });

        break;
      default:
        const customField = columnDef.cellRendererParams.customField as CustomField;
        if (customField) {
          await client.updateCustomField(location.id, customField.id, event.value);
        } else {
          throw new Error(`Unexpected data type being saved: ${columnDef.field}`);
        }
        break;
    }
    refresh();
    bringFocusBack();
  };

  const onCreateLocation = async () => {
    await client.createLocation(locationDefinition.id, '');
    refresh();
  };

  const deleteFunc = (location: Location) => {
    client.delete(location.id).then((res) => {
      if (res.success) {
        refresh();
      }
    });
  };

  useEffect(() => {
    gridApi?.setRowData(locations);
  }, [locations]);

  const gridReady = (evt: GridReadyEvent) => {
    setGridApi(evt.api);
  };

  const createLocationDefinitionCustomField = async (
    name: string,
    selectedType?: CustomFieldType,
  ) => {
    await lcClient.customFields.create(locationDefinition.id, name).then((res) => {
      if (res.success && res.data) {
        if (selectedType && selectedType !== CustomFieldType.String) {
          lcClient.customFields.updateType(locationDefinition.id, res.data, selectedType);
        }
        refreshCustomFields();
      }
    });
  };

  const bringFocusBack = () => {
    let cell = gridApi?.getFocusedCell();
    if (cell) {
      gridApi?.setFocusedCell(cell.rowIndex, cell.column, null);
    }
  };

  return (
    <>
      <div className="d-flex m-2 justify-content-between py-2">
        <h4>Locations</h4>
        <div>
          <Button
            type={VisualCategory.Secondary}
            action={() => modal.openModal(<ManageCustomFieldsModal />)}
            className="btn-sm me-2"
          >
            <Icon type={IconType.Edit} /> Manage Custom Fields
          </Button>
          <Button
            type={VisualCategory.Primary}
            action={() => modal.openModal(<AddCustomFieldModal save={createLocationDefinitionCustomField} />)}
            className="btn-sm"
          >
            <Icon type={IconType.Add} /> Add Custom Field
          </Button>
        </div>
      </div>
      <div className="ag-theme-balham" style={{ height: '100%' }}>
        <Button type={VisualCategory.Primary} action={onCreateLocation} className="btn-sm ms-2 mb-2">
          <Icon type={IconType.Add} /> Add Location
        </Button>
        <AgGridReact
          disableStaticMarkup={true}
          columnDefs={colDefs}
          defaultColDef={{
            cellStyle: { fontFamily: 'Quicksand', fontSize: '14px' },
            singleClickEdit: false,
          }}
          rowData={locations}
          onGridReady={gridReady}
          //Here we made an update that updated the cell.
          onCellValueChanged={save}
          rowHeight={36}
          suppressRowTransform={true}
          stopEditingWhenCellsLoseFocus={true}
          domLayout="autoHeight"
        />
      </div>
      {modal.modal}
    </>
  );
};

function createColDefs(
  locationDefinition: LocationDefinition,
  deleteFunc: (loc: Location) => void,
): ColDef<Location>[] {
  const checkDefaultHours = (hours: Map<string, TimeRange[]>) => {
    let filterMap = Array.from(hours.values()).filter( x => x !== undefined);
    return filterMap.every( x => x.length === 0 ); 
  }


  return [
    { headerName: 'Name', field: 'displayName', editable: true },
    { headerName: 'Phone', field: 'phoneNumber', editable: true },
    { headerName: 'Address', field: 'address', editable: true },
    { headerName: 'Customer Id', field: 'customerId', editable: true },
    {
      headerName: 'Pronunciations',
      field: 'pronunciations',
      editable: true,
      cellRenderer: (params: { value: Map<String, string> }) => `en-US: ${params.value.get('en-US') ?? 'None'}`,
      cellEditorSelector: () => {
        return {
          component: PronunciationsFieldEditor,
          popup: true,
        };
      },
    },
    {
      headerName: 'Timezone',
      field: 'timeZone',
      editable: true,
      cellRenderer: (params: { value: string }) => params.value,
      cellEditorSelector: () => {
        return {
          component: TimeZoneFieldEditor,
          popup: true,
        };
      },
    },
    {
      headerName: 'Office Hours',
      field: 'officeHours',
      editable: true,
      singleClickEdit: true,
      cellRenderer: (params: { value: Map<string, TimeRange[]> }) => {
        return (
          <button className="btn">
            <Icon
              type={IconType.Calendar}
              style={checkDefaultHours(params.value) ? IconStyle.Outline : IconStyle.Normal}
              color={ColorHexes.Primary}
            />
          </button>
        );
      },

      cellEditorSelector: () => {
        return {
          component: HoursFieldEditor,
          popup: true,
        };
      },
    },
    ...locationDefinition.customFields.map((cf) => CreateCustomFieldColDef(cf)),
    {
      headerName: 'Delete',
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        actions: [
          {
            id: 'deleteButton',
            label: <Icon type={IconType.Delete} />,
            type: VisualCategory.Danger,
            action: deleteFunc,
          },
        ],
      },
      width: 100,
      resizable: true,
    },
  ];
}

function CreateCustomFieldColDef(field: CustomField): ColDef<Location, CustomFieldValue> {
  return {
    headerName: field.name,
    cellRendererParams: {
      customField: field,
    },
    cellEditorParams: {
      customField: field,
    },
    cellRenderer: CustomFieldValueRenderer,
    editable: true,
    cellEditorSelector: () => {
      return {
        component: CustomFieldValueEditor,
        popup: true,
      };
    },
    valueSetter: (params) => {
      const location = params.data as Location;
      if (params.newValue) {
        location.customData.set(field.id, params.newValue);
        return true;
      }
      return false;
    },
    valueGetter: (params) => {
      const location = params.data as Location;
      return location.customData.get(field.id);
    },
  };
}
