import { IContextClient } from './IContextClient';
import { Methods, ServerResponse, DataResponse, ValidateResponseActionEnum } from 'internal/clients';
import { ContextItem, ContextType } from 'internal/models';
import { PostError } from 'ui/events';

export class ContextClient implements IContextClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async getContexts(snapshotId: string): Promise<DataResponse<ContextItem[]>> {
    const url = this.getUrl(snapshotId);
    const response = await this.methods.get(url);

    const validationResponse = await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Get} list of context items`, response);

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    if (!Array.isArray(data)) {
      const error = 'Unexpected response when retrieving Context Items';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data: data.map((c) => ContextItem.fromJson(c)),
    };
  }

  async createContext(
    snapshotId: string,
    name: string,
    type: ContextType,
    isSensitive: boolean,
    isReporting: boolean,
    typeId?: string | undefined,
  ): Promise<DataResponse<string>> {
    const url = this.getUrl(snapshotId);

    const request = {
      type: type,
      name: name,
      typeId: typeId,
      isSensitive: isSensitive,
      isReporting: isReporting,
    };

    const response = await this.methods.post(url, request);

    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} Context Item`,
      response,
    );
  }

  async updateName(snapshotId: string, contextId: string, name: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${contextId}/name`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', name);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Updated} Context Item name`,
      response,
    );
  }

  async updateDescription(snapshotId: string, contextId: string, description: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${contextId}/description`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', description);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Updated} Context Item description`,
      response,
    );
  }

  async updateType(
    snapshotId: string,
    contextId: string,
    type: ContextType,
    typeId?: string | undefined,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${contextId}/type`;

    const request = {
      type: type,
      typeId: typeId,
    };

    const response = await this.methods.put(url, request);

    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Context Item type`, response);
  }

  async updateIsList(snapshotId: string, contextId: string, isList: boolean): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${contextId}/is-list/${isList}`;
    const response = await this.methods.put(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Context Item isList`,
      response,
    );
  }

  async updateIsSensitive(snapshotId: string, contextId: string, isSensitive: boolean): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${contextId}/sensitivity/${isSensitive}`;
    const response = await this.methods.put(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Context Item sensitivity`,
      response,
    );
  }

  async updateIsReporting(snapshotId: string, contextId: string, isReported: boolean): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${contextId}/reporting/${isReported}`;
    const response = await this.methods.put(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Context Item IsReporting`,
      response,
    );
  }

  async updateIsPublic(snapshotId: string, contextId: string, isPublic: boolean): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${contextId}/is-public/${isPublic}`;
    const response = await this.methods.put(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Context Item IsPublic`,
      response,
    );
  }

  async updateCategory(snapshotId: string, contextId: string, category: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${contextId}/category`;

    const response = await this.methods.plainBodyRequest(url, 'PUT', category);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Context Item category`,
      response,
    );
  }

  async updateOrder(snapshotId: string, contextId: string, order: number): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${contextId}/order`;

    const response = await this.methods.put(url, order);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Context Item order`, response);
  }

  async delete(snapshotId: string, contextId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId)}/${contextId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Context Item`, response);
  }

  private getUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/v1/snapshot/${snapshotId}`;
  }
}
