import { ICellEditorParams } from 'ag-grid-community';
import { ConfigValue, MessageService, PhoneNumber, ResourceType, TwilioResource, TwilioValue, ValueType } from 'internal/models';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Select from 'react-select';
import { Option  } from 'ui/types';
import { useResources } from 'contexts/design';



export const TwilioCellEditor = forwardRef((props: ICellEditorParams, ref: React.ForwardedRef<unknown>) => {
  const configValue = props.value as ConfigValue;
  const isTwilioValue = configValue.type === ValueType.Twilio;
  const resources = useResources();
  const inputRef = useRef<HTMLInputElement>(null);
  const defaultOptionValue = { label: '-- Select --', value: ''};

  //Potential Data from TwilioValue
  const [value, setValue] = useState(isTwilioValue ? (configValue.value as TwilioValue) : undefined);
  const [resourceId, setResourceId] = useState(isTwilioValue ? value?.resourceId : undefined);
  const [phoneNumber, setPhoneNumber] = useState(isTwilioValue ? value?.phoneNumber : undefined);
  const [messageServiceSId, setMessageServiceSId] = useState(isTwilioValue ? value?.messageServiceSId : undefined);

  const twilioResources = (resources.byType.get(ResourceType.Twilio) as TwilioResource[]) ?? [];
  const [messageServices, setMessageServices] = useState<MessageService[] | undefined>(undefined);
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[] | undefined>(undefined); 

  const [selectedMessageService, setMessageService] = useState<MessageService | undefined>(undefined);
  const [selectedTwilioResource, setSelectedTwilioResource] = useState<TwilioResource | undefined>(undefined);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<PhoneNumber | undefined>(undefined);

  useEffect(() => {
    let determinedTwilioResource = twilioResources.find((r) => r.id === resourceId);
    setSelectedTwilioResource(determinedTwilioResource);

    if(determinedTwilioResource){
      setMessageServices(
        determinedTwilioResource.messageServices
      );
    }

  }, [resourceId]);


  useEffect(() => {
    if(messageServices){
      let determinedMessageService = messageServices.find((msgSvc) => { return msgSvc.sid === messageServiceSId });
      setMessageService(determinedMessageService);
      setPhoneNumbers(determinedMessageService?.phoneNumbers);
    }
  }, [messageServices, messageServiceSId]);


  useEffect(() => {
    if(phoneNumbers){ 
      setSelectedPhoneNumber(phoneNumbers.find((phoneNo) => {return phoneNo.number === phoneNumber}));
    }
  }, [phoneNumbers, phoneNumber]);


  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [inputRef]);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return new ConfigValue(ValueType.Twilio, value);
      },
      isCancelAfterEnd: () => {
        return value === configValue.value;
      },
    };
  });

  const setResource = (id: string | undefined) => {
    if (id === resourceId) {
      return;
    }
    setResourceId(id);
    setMessageServiceSId(undefined);
    setPhoneNumber(undefined);
  };

  const setMessageSvcId = ( id: string | undefined ) => {
    if (id === messageServiceSId) {
      return;
    }
    setMessageServiceSId(id);
    setPhoneNumber(undefined);
  }

  const setPhoneAndValue = (phone: string | undefined) => {
    if (phoneNumber === phone) {
      return;
    }

    if (!phone) {
      setPhoneNumber(undefined);
      return;
    }

    setPhoneNumber(phone);

    if (resourceId && messageServiceSId) {
      setValue({ resourceId: resourceId, phoneNumber: phone, messageServiceSId : messageServiceSId });
    }
  };

  return (
    <div className="p-2" style={{ backgroundColor: 'white' }}>
      <div className="mb-3">
        <label htmlFor="resourceSelect" className="col-form-label">
          Resource
        </label>
        <Select<Option>
          options={twilioResources?.map((resource) => {return { label: resource.name, value: resource.id}})}
          onChange={(s) => setResource(s?.value)}
          value={selectedTwilioResource ? { label: selectedTwilioResource?.name, value: selectedTwilioResource?.id } : defaultOptionValue}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="messageServiceSelect" className="col-form-label">
          Message Services
        </label>
        <Select<Option>
          options={messageServices?.map((msgSvc) => {return { label:msgSvc.friendlyName, value:msgSvc.sid}})}
          onChange={(s) => setMessageSvcId(s?.value)}
          value={
            selectedMessageService
              ? { label: selectedMessageService.friendlyName, value: selectedMessageService.sid }
              : defaultOptionValue
          }
        />
      </div>
      <div className="mb-3">
        <label htmlFor="phoneNumberSelect" className="col-form-label">
          Phone number
        </label>
        <Select<Option>
          options={phoneNumbers?.map((phoneNoInfo) => {return { label: phoneNoInfo.friendlyNumber, value: phoneNoInfo.number }})}
          onChange={(s) => setPhoneAndValue(s?.value)}
          value={
            selectedPhoneNumber
              ? { label: selectedPhoneNumber.friendlyNumber, value: selectedPhoneNumber.number }
              : defaultOptionValue
          }
        />
      </div>
    </div>
  );
});
