import { useContextMenu } from '@smartaction/visuals';
import { Color, Icon, IconType, VisualCategory } from '@smartaction/styles';
import React, { useState } from 'react';
import { NodeProps, useStore } from 'reactflow';
import { useBots, useFlow, useModule, useNote } from 'contexts';
import { useRef } from 'react';
import { useClient } from 'contexts/ClientContext';
import { useSnapshot } from 'contexts';
import { Sticky } from './shapes/Sticky';
import ReactMarkdown from 'react-markdown';
import { NodeResizer } from '@reactflow/node-resizer';
import { useConstantDebouncer } from 'ui/hooks';


export type NoteData = {
  moduleId: string;
  id: string;
};

export const NoteNode: React.FC<NodeProps<NoteData>> = ({ data, selected }) => {
  const snapshot = useSnapshot();
  const {isReadOnlyBot} = useBots();
  const ref = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const { updateFlow } = useFlow();
  const module = useModule(data.moduleId);
  const [note] = useNote(data.moduleId, data.id);
  const [text, setText] = useState(note?.text);
  const client = useClient('flow');
  const [color, setColor] = useState(note?.color);
  const [textColor, setTextColor] = useState(note?.textColor);
  const size = useStore((s) => {
    const node = s.nodeInternals.get(data.id);
    if (!node) {
      return;
    }
    return {
      width: node.width,
      height: node.height,
    };
  });

  useConstantDebouncer(
    () => {
      if (color !== note.color) {
        updateColor(color);
      }
    },
    1000,
    [color],
  );

  useConstantDebouncer(
    () => {
      if (textColor !== note.textColor) {
        updateTextColor(textColor);
      }
    },
    1000,
    [textColor],
  );

  useConstantDebouncer(
    () => {
      if (!size || !size.height || !size.width) {
        return;
      }
      if (Math.abs(size.height - note.height) < 1 || Math.abs(size.width - note.width) < 1) {
        return; // not changing anything for a 1 pixel move
      }
      updateSize(size.height!, size.width!);
    },
    1000,
    [size],
  );

  const contextMenu = useContextMenu(
    ref,
    [
      {
        label: (
          <span className={Color(VisualCategory.Danger)}>
            <Icon type={IconType.Delete} /> Delete Note
          </span>
        ),
        action: () => {
          client.modules.notes.deleteAsync(snapshot.snapshot.id, data.moduleId, data.id).then(() => {
            updateFlow((x) => {
              let module = x.modules.find((y) => y.id === data.moduleId);

              let copyNotes = [...module?.notes!];

              let filteredNotes = copyNotes.filter((x) => x.id !== data.id);
              module!.notes = filteredNotes;
            });
          });
        },
        isDisabled: false,
      },
    ],
    true,
  );

  if (!note) {
    return <React.Fragment />;
  }

  // as with position changes in the module, not calling updateFlow to prevent a re-render (and a deselect as a result), but we are updating the note to keep its properties in sync with
  // their useState counterparts.
  const updateText = async () => {
    setIsEditing(false);
    if (text !== note.text) {
      await client.modules.notes.updateTextAsync(snapshot.snapshot.id, module.id, note.id, text);
    }
    note.text = text;
  };

  const updateColor = async (c: string) => {
    await client.modules.notes.updateColorAsync(snapshot.snapshot.id, module.id, note.id, c);
    note.color = color;
  };

  const updateTextColor = async (c: string) => {
    await client.modules.notes.updateTextColorAsync(snapshot.snapshot.id, module.id, note.id, c);
    note.textColor = textColor;
  };

  const updateSize = async (height: number, width: number) => {
    await client.modules.notes.updateSizeAsync(snapshot.snapshot.id, module.id, note.id, height, width);
    note.height = height;
    note.width = width;
  };

  const style =
    size?.height && size.width
      ? { height: size.height, width: size.width }
      : { height: note.height, width: note.width };

  return (
    <React.Fragment>
      <NodeResizer isVisible={selected} lineStyle={{ borderWidth: '2px' }} />
      <div
        ref={ref}
        className="noteNode node"
        onDoubleClick={() => {
          if (!isReadOnlyBot) {
            setIsEditing(!isEditing);
          }
        }}
        style={style}
      >
        <Sticky color={textColor} backgroundColor={color}>
          <div className="noteText nowheel">
            {isEditing ? (
              <textarea
                className="nodrag"
                value={text}
                cols={90}
                rows={20}
                onChange={(evt) => setText(evt.target.value)}
                onBlur={updateText}
                onKeyDown={(evt) => {
                  if (evt.key === 'Escape') {
                    setText(note.text);
                    setIsEditing(false);
                  }
                  if (evt.key === 'Enter' && evt.ctrlKey) {
                    setIsEditing(false);
                    updateText();
                  }
                }}
              />
            ) : (
              <ReactMarkdown>{text}</ReactMarkdown>
            )}
          </div>
          <div className="colors">
            <input
              className="nodrag color"
              type="color"
              onChange={(evt) => setColor(evt.target.value)}
              defaultValue={note.color}
            />
            <input
              className="nodrag textColor"
              type="color"
              onChange={(evt) => setTextColor(evt.target.value)}
              defaultValue={note.textColor}
            />
          </div>
        </Sticky>
        {contextMenu}
      </div>
    </React.Fragment>
  );
};
