import { EmptyPointer, LocationPointer } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { PointerEditorProps } from './PointerEditorProps';
import Select, { SingleValue } from 'react-select';
import { Option } from 'ui/types';
import { useBots, useLocationDefinitions } from 'contexts';

export const LocationPointerEditor: React.FC<PointerEditorProps<LocationPointer>> = ({ pointer, update, isDisabled }) => {
  const [selected, setSelected] = useState<Option>();
  const [options, setOptions] = useState<Option[]>();
  const { locationDefinitions, commonLocationFieldIds } = useLocationDefinitions();

  useEffect(() => {
    setSelected({
      value: pointer.fieldId,
      label:
        locationDefinitions[0].customFields.find((fieldId) => fieldId.id === pointer.fieldId)?.name ||
        Object.entries(commonLocationFieldIds).find(([key, val]) => val === pointer.fieldId)?.[0] ||
        '',
    });
  }, []);
  useEffect(() => {
    locationDefinitions.length &&
      setOptions([
        { label: '-- Select --', value: '' },
        ...Object.entries(commonLocationFieldIds).map(([key, val]) => {
          return { label: key, value: val };
        }),
        ...locationDefinitions[0].customFields.map((field) => {
          return { label: field.name, value: field.id };
        }),
      ]);
  }, [locationDefinitions]);

  const save = (opt: SingleValue<Option>) => {
    if (!opt || !opt.label) {
      setSelected(selected);
      update(new EmptyPointer());
    } else {
      setSelected(opt);
      update(new LocationPointer(opt.value));
    }
  };

  return <Select 
    menuPosition={'fixed'}
    menuPortalTarget={document.body}
    styles={{
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      menu: (baseStyles) => ({
        ...baseStyles,
        fontSize: 14,
        width: 'max-content',
      }),
    }}
    isDisabled={isDisabled} 
    options={options} 
    value={selected} 
    onChange={save}
  />;
};
