import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, usePortalModal, PortalConfirm, ModalContainerIds } from '@smartaction/visuals';
import { ICellRendererParams } from 'ag-grid-community';
import { useObjects } from 'contexts/design/ObjectsContext';
import React from 'react';

type DeleteRenderProps = ICellRendererParams & {
    deleteFunc: (data: any) => void,
    canEdit: boolean,
    typeName: string
}

export const DeleteRenderer: React.FC<ICellRendererParams> = (props) => {
    const { data, deleteFunc, canEdit } = (props as DeleteRenderProps);
    const confirm = usePortalModal(ModalContainerIds.Confirm);
    const objects = useObjects();

    function isEditableObject(params: any){
        let paramId = params?.data?.obj?.id;
        let editable = true;
        if(paramId){
            objects.staticObjects.forEach((obj) =>{
                if(paramId === obj.id){
                    editable = false;
                    return;
                }})}

                return editable;
        }

    if (!deleteFunc) {
        console.info("Delete renderer exists but was not given a method to delete the item. Use canEdit instead to disable deletion.");
        return <React.Fragment/>;
    }

    const onAction = () => {
        if (data.isInUse) {
            return confirm.openModal(<PortalConfirm header="Are you sure?" content={`This ${data.name} is currently being used.`} confirmButton={{ label: <React.Fragment><Icon type={IconType.Delete}/> Delete</React.Fragment>, type: VisualCategory.Danger, clicked: async () => {
                await deleteFunc(data);
            }}} cancelButton={{ label: "Cancel", type:VisualCategory.Light, clicked: () => {}}} />)
        }

        deleteFunc(data);
    }

    if(!isEditableObject(props)){
        return(<React.Fragment></React.Fragment>)
    }

    return (
        <React.Fragment>
            <Button type={VisualCategory.Danger} className="btn-sm" isDisabled={!canEdit} action={onAction}>
                <Icon type={IconType.Delete} />
            </Button>
            {confirm.modal}
        </React.Fragment>
    );
}