import React from "react";
import { ModuleChildTypes } from "internal/models";
import { Diamond } from "../nodes/shapes/Diamond";

const Decision: React.FC = () => {
  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('application/reactflow', ModuleChildTypes.Decision);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className="decision-draggable dndnode" onDragStart={onDragStart} draggable>
      <Diamond text='Decision' size={.40} isSelected={false}/>
    </div>
  )
}
export default Decision;