import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import {  Button } from '@smartaction/visuals';
import { ICellRendererParams } from 'ag-grid-community';
import { CompareCondition } from 'internal/models';
import React, {  } from 'react';


export type DeleteRenderProps = ICellRendererParams & {
    deleteFunc: (data: any) => void,
    canEdit: boolean
}
export const DeleteRenderer: React.FC<DeleteRenderProps> = ({colDef, value, data, deleteFunc}) => {
    const condition = data as CompareCondition;

    return (
        <Button
        action={() => deleteFunc(condition)}
        type={VisualCategory.Danger}
        className={'btn-md'}>
        <Icon type={IconType.Delete} />
      </Button>
    );

}
