export class CLUData {
  kind: string;
  result: CLUResult;
  json: any;

  constructor(kind: string, result: CLUResult, json: any) {
    this.kind = kind;
    this.result = result;
    this.json = json;
  }

  static fromJson(json: any) {
    if (!json.kind) {
        throw new Error("Unexpected type from testing CLUs");
    }
    return new CLUData(json.kind, CLUResult.fromJson(json.result), json);
  }
}

export class CLUResult {
    query: string;
    prediction: CLUPrediction;

    constructor(query: string, prediction: CLUPrediction) {
        this.query = query;
        this.prediction = prediction;
    }

    static fromJson(json: any) {
        return new CLUResult(json.query, CLUPrediction.fromJson(json.prediction));
    }
}

export class CLUPrediction {
    intents: CLUIntent[];
    entities: CLUEntity[];

    constructor(intents: CLUIntent[], entities: CLUEntity[]) {
        this.intents = intents;
        this.entities = entities;
    }

    static fromJson(json: any) {
        const intents = [];
        const entities = [];

        if (json.intents) {
            for(let i of json.intents) {
                intents.push(CLUIntent.fromJson(i));
            }
        }

        if (json.entities) {
            for(let e of json.entities) {
                entities.push(CLUEntity.fromJson(e));
            }
        }

        return new CLUPrediction(intents, entities);
    }
}

export class CLUIntent {
    category: string;
    confidenceScore: number;

    constructor(category: string, confidenceScore: number) {
        this.category = category;
        this.confidenceScore = confidenceScore;
    }

    static fromJson(json: any) {
        return new CLUIntent(json.category, json.confidenceScore);
    }
}

export class CLUEntity {
    category: string;
    text: string;
    extraInformation: CLUExtraInformation[];

    constructor(category: string, text: string, extraInformation: CLUExtraInformation[]) {
        this.category = category;
        this.text = text;
        this.extraInformation = extraInformation;
    }

    static fromJson(json: any) {
        const extraInfos = [];

        if (json.extraInformation) {
            for (let ei of json.extraInformation) {
                extraInfos.push(CLUExtraInformation.fromJson(ei));
            }
        }
            
        return new CLUEntity(json.category, json.text, extraInfos);
    }
}

export class CLUExtraInformation {
    key: string;
    value: string;
    regexPattern: string;
    extraInformationKind: CLUExtraInformationKind;

    constructor(key: string, value: string, regexPattern: string, extraInformationKind: CLUExtraInformationKind) {
        this.key = key;
        this.value = value;
        this.regexPattern = regexPattern;
        this.extraInformationKind = extraInformationKind;
    }

    static fromJson(json: any) {
        return new CLUExtraInformation(json.key, json.value, json.regexPattern, json.extraInformationKind);
    }
}

export enum CLUExtraInformationKind { ListKey, EntitySubType, RegexKey }
