import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useClient, useFlow, useProperties, useSnapshot } from 'contexts';
import { DTMFMenuItem, DTMFMenu, LangType, PhoneKeys } from 'internal/models';
import { TableRow, CellData } from 'ui/components';
import { transformStringToArray } from 'ui/utils/strings';
import { Option } from 'ui/types';

export interface UseTableLogicRes {
  isLoading: boolean;
  rows: TableRow[];
  onDeleteRow: (id: string, rowIndex: number) => void;
  onAddNewRow: () => void;
}

interface CreateDTMFMenuItemRowProps {
  row: DTMFMenuItem;
  supportedLanguages: Option[];
  onChangeDTMFKey: CellData['onChange'];
  onChangeDTMFLngCode: CellData['onChange'];
  onChangeDTMFKeywords: CellData['onChange'];
}

interface UseTableLogicProps {
  step: DTMFMenu;
}

const lngOptions = Object.values(LangType).map((lng) => ({ label: lng, value: lng }));
const createDTMFMenuItemRow = ({
  row,
  supportedLanguages,
  onChangeDTMFKey,
  onChangeDTMFLngCode,
  onChangeDTMFKeywords,
}: CreateDTMFMenuItemRowProps): TableRow => ({
  cells: [
    {
      type: 'dropdown',
      value: row.key,
      selectOptions: PhoneKeys.map((key) => ({ label: key, value: key })),
      id: uuid(),
      onChange: onChangeDTMFKey,
    },
    {
      type: 'dropdown',
      value: row.languageCode,
      selectOptions: supportedLanguages,
      id: uuid(),
      onChange: onChangeDTMFLngCode,
    },
    { type: 'textarea', value: row.keywords.join(', '), id: uuid(), onChange: onChangeDTMFKeywords },
  ],
  id: row.id,
});

export const useTableMenuItemsLogic = ({ step }: UseTableLogicProps): UseTableLogicRes => {
  const { updateFlow } = useFlow();
  const client = useClient('flow');
  const { snapshot } = useSnapshot();
  const [rows, setRows] = useState<TableRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const properties = useProperties();
  const supportedLanguages =
    properties?.languageModel?.supportedLanguages.map((lng) => ({
      label: lng.languageCode,
      value: lng.languageCode,
    })) || lngOptions;
  const onDeleteRow = (rowId: string) => {
    setIsLoading(true);
    client.steps.dtmfs
      .deleteMenuItem(snapshot.id, step.id, rowId)
      .then(() => {
        updateFlow(() => {
          step.menuItems = step.menuItems.filter((q) => q.id !== rowId);
          updateRows();
        });
      })
      .finally(() => setIsLoading(false));
  };
  const onAddNewRow = () => {
    setIsLoading(true);
    client.steps.dtmfs
      .createMenuItem(snapshot.id, step.id, PhoneKeys[0], supportedLanguages[0].value)
      .then(({ data: menuItemId }) => {
        updateFlow(() => {
          step.menuItems.push(new DTMFMenuItem(menuItemId as string, PhoneKeys[0], supportedLanguages[0].value, []));
          updateRows();
        });
      })
      .finally(() => setIsLoading(false));
  };
  const onChangeDTMFKey: CellData['onChange'] = ({ rowId, value }) => {
    setIsLoading(true);
    client.steps.dtmfs
      .setMenuItemKey(snapshot.id, step.id, rowId, value as string)
      .then((res) => {
        updateFlow(() => {
          const menuItem = step.menuItems.find((q) => q.id === rowId);
          if (menuItem) {
            menuItem.key = value as string;
            updateRows();
          }
        });
      })
      .finally(() => setIsLoading(false));
  };
  const onChangeDTMFLngCode: CellData['onChange'] = ({ rowId, value }) => {
    setIsLoading(true);
    client.steps.dtmfs
      .setMenuItemLanguageCode(snapshot.id, step.id, rowId, value as string)
      .then((res) => {
        updateFlow(() => {
          const menuItem = step.menuItems.find((q) => q.id === rowId);
          if (menuItem) {
            menuItem.languageCode = value as string;
            updateRows();
          }
        });
      })
      .finally(() => setIsLoading(false));
  };
  const onChangeDTMFKeywords: CellData['onChange'] = ({ rowId, value }) => {
    const keywords = transformStringToArray(value as string);
    if (keywords.join(' ') === step.menuItems.find((q) => q.id === rowId)?.keywords.join(' ')) {
      return;
    }
    setIsLoading(true);
    client.steps.dtmfs
      .setMenuItemKeywords(snapshot.id, step.id, rowId, keywords)
      .then((res) => {
        updateFlow(() => {
          const menuItem = step.menuItems.find((q) => q.id === rowId);
          if (menuItem) {
            menuItem.keywords = keywords;
            updateRows();
          }
        });
      })
      .finally(() => setIsLoading(false));
  };

  const updateRows = () =>
    setRows(
      step.menuItems.map((row) =>
        createDTMFMenuItemRow({
          row,
          supportedLanguages: supportedLanguages,
          onChangeDTMFKey,
          onChangeDTMFLngCode,
          onChangeDTMFKeywords,
        }),
      ),
    );

  useEffect(() => {
    // Initial render
    updateRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, rows, onDeleteRow, onAddNewRow };
};
