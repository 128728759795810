import { Button, PortalModal, usePortalModal } from '@smartaction/visuals';
import React, { useEffect, useState } from 'react';
import { useClient } from 'contexts';
import { Condition, ConditionType, Environment, ScriptCondition } from 'internal/models';
import { EditorInterface, MonacoEditor } from 'ui/components/design/scripting/MonacoEditor';
//import { VisualCategory } from '@smartaction/styles/VisualCategory';
import { ICellRendererParams } from 'ag-grid-community';
import { VisualCategory } from '@smartaction/styles';


type ScriptRendererProps = ICellRendererParams<ScriptCondition, boolean> & {
    onUpdateCondition: (condition: Condition) => void;
}

 const checkIfScriptCompareTypeCell = (type: ConditionType): boolean => {
    switch (type) {
      case ConditionType.Script:
        return true;
      default:
        return false;
    }
  };
  


export const ScriptRenderer: React.FC<ScriptRendererProps> = ({colDef, value, data, onUpdateCondition}) => {
    const condition = data as ScriptCondition;
    const modal = usePortalModal();

    if(!checkIfScriptCompareTypeCell(condition.type))
      return (<span>n/a</span>);

    const onHandleScript = (value: string) => {
      if (value) 
      {
        let newScriptCondition = {
            ...condition,
            code: value,
            type: ConditionType.Script
        } as ScriptCondition;
        onUpdateCondition(newScriptCondition);
      } 
    };

    return (
        <>
            <Button 
              type={VisualCategory.Primary} 
              action={() =>modal.openModal(<ScriptModal condition={condition} 
              onHandleScript={onHandleScript}/>) }>
              Script Editor
            </Button> 
            {modal.modal}
        </>
    );
}

type ScriptModelProps = {
    condition: ScriptCondition;
    onHandleScript: (value: string) => void;
};

export const ScriptModal: React.FC<ScriptModelProps> = ({ condition, onHandleScript }) => {
    const header = "Script Editor";
    const client = useClient('validator');
    const [script, setScript] = useState(condition.code);



    useEffect(() => {
      setScript(condition.code);
    }, [condition.code]);

    const onValidate = async (tenant: string, snapshot: string, script: string) => {
         return await client.validateValidationScript(tenant, snapshot, script);
    };
  
    const content = (
      <div className="script-modal" style={{ width: '50vw' }}>
        <MonacoEditor 
          id={condition.id} 
          isReadOnly={false}
          value={script} 
          types={[EditorInterface.ConversationState, EditorInterface.ScriptValidation]} 
          onSave={onHandleScript} 
          validateMethod={onValidate} />      
      </div>
    );
  
    return <PortalModal header={header} content={content} buttons={<React.Fragment/>} />;
}

export default ScriptRenderer;
