import { Position } from "@smartaction/visuals/Types";
import { ModuleChildTypes } from ".";
import { Component } from "./ModuleItem";

export class Note extends Component<Note> {
    readonly type: ModuleChildTypes = ModuleChildTypes.Note;
    height: number;
    width: number;
    text: string;
    color: string;
    textColor: string;

    constructor(id: string | undefined, text: string, position: Position, height: number, width: number, color: string, textColor: string) {
        super(id, "", "", position);
        this.height = height;
        this.width = width;
        this.text = text;
        this.color = color;
        this.textColor = textColor;
    }

    isDirty(original: Note): boolean {
        throw new Error("Method not implemented.");
    }
    getDirtyFields(original: Note): string[] {
        throw new Error("Method not implemented.");
    }
    clone(): Note {
        throw new Error("Method not implemented.");
    }

    static fromJson(json: any) {
        return new Note(json.id, json.text, { x:json.position.x, y:json.position.y }, json.height, json.width, json.color, json.textColor); 
    }

    static defaultSize: number = 100;
    static defaultColor: string = "#FFE54D";
    static defaultTextColor: string = "#000";
}

