import React, { useEffect } from 'react';
import { Input, Select, CheckboxSimple as Checkbox } from '@smartaction/visuals';

export interface CellData {
  type: 'dropdown' | 'input' | 'textarea' | 'checkbox' | 'customNode';
  customNode?: React.ReactNode;
  id: string;
  selectOptions?: Array<{ label: string; value: string }>;
  value?: string | boolean;
  onChange?: (props: { rowId: string; value: string | boolean}) => void;
  disabled?: boolean;
}

interface CellProps {
  type: CellData['type'];
  size?: 'xs' | 'sm' | 'md' | number;
  value: CellData['value'];
  id: CellData['id'];
  selectOptions: Required<CellData['selectOptions']>;
  onChange: CellData['onChange'];
  rowId: string;
  customNode?: CellData['customNode'];
  disabled?: boolean;
}

interface InputProps {
  value: string;
  onChange: CellData['onChange'];
  rowId: string;
  disabled?: boolean;
}

const TextArea: React.FC<InputProps> = ({ value, onChange, rowId, disabled }) => {
  const [state, setState] = React.useState(value);
  const onChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(() => e.target.value);
  };
  const onBlur = () => {
    onChange?.({ rowId, value: state });
  };

  const onPressEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      onChange?.({ rowId, value: state });
    }
  };

  useEffect(() => {
    setState(value);
  }, [value]);
  return (
    <textarea
      rows={2}
      placeholder={'Type here...'}
      className={'sa-table-input form-control'}
      value={state}
      onChange={onChangeValue}
      onBlur={onBlur}
      onKeyDown={onPressEnter}
      disabled={disabled}
    />
  );
};
const getCellStyles = (size: CellProps['size']) => {
  if (typeof size === 'number') {
    return { flex: `0 0 ${size}px`, minWidth: `${size}px` };
  }
  return {};
};
const Cell: React.FC<CellProps> = ({
  type,
  size,
  value,
  id,
  disabled,
  selectOptions,
  customNode,
  onChange,
  rowId,
}) => {
  return (
    <div className={`sa-table-cell ${typeof size === 'string' ? `size--${size}` : ''}`} style={getCellStyles(size)}>
      {type === 'checkbox' && (
        <Checkbox
          onChange={(value) => onChange?.({ rowId, value } )}
          value={value as boolean}
          size={26}
          disabled={disabled}
        />
      )}
      {type === 'dropdown' && selectOptions && (
        <Select
          options={selectOptions}
          onChange={(value) => onChange?.({ rowId, value })}
          id={id}
          value={value as string}
          disabled={disabled}
        />
      )}
      {type === 'textarea' && (
        <TextArea value={value as string} onChange={onChange} rowId={rowId} disabled={disabled} />
      )}
      {type === 'input' && (
        <Input
          value={value as string}
          onChange={(newValue) => onChange?.({ rowId, value: newValue })}
          id={id}
          disabled={disabled}
        />
      )}
      {type === 'customNode' && customNode}
    </div>
  );
};
export const CellEmpty: React.FC<{ size: CellProps['size'] }> = ({ size }) => {
  return <div className={`sa-table-cell ${size ? `size--${size}` : ''}`} />;
};
export default Cell;
