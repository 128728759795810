import { Methods, ValidateResponseActionEnum } from 'internal/clients/Methods';
import { DataResponse, ServerResponse } from 'internal/clients/Responses';
import { Policy } from 'internal/models/bots/design/flow/policies/Policy';
import { PolicyBuilder } from 'internal/models/bots/design/flow/policies/PolicyBuilder';
import { PolicyType } from 'internal/models/bots/design/flow/policies/PolicyType';
import { PostError } from 'ui/events';
import { BargeInPolicyClient } from './BargeInPolicyClient';
import { ConditionalPolicyClient } from './ConditionalPolicyClient';
import { IBargeInPolicyClient } from './IBargeInPolicyClient';
import { IConditionalPolicyClient } from './IConditionalPolicyClient';
import { IPolicyClient } from './IPolicyClient';
import { IRetryPolicyClient } from './IRetryPolicyClient';
import { RetryPolicyClient } from './RetryPolicyClient';
import { ITimeoutPolicyClient } from './ITimeoutPolicyClient';
import { IValidatePolicyClient } from './IValidatePolicyClient';
import { ValidatePolicyClient } from './ValidatePolicyClient';
import { TimeoutPolicyClient } from './TimeoutPolicyClient';
import { IExpirationPolicyClient } from './IExpirationPolicyClient';
import { ExpirationPolicyClient } from './ExpirationPolicyClient';
import { IInputFormatterPolicyClient } from './IInputFormatterPolicyClient';
import { InputFormatterPolicyClient } from './InputFormatterPolicyClient';

export class PolicyClient implements IPolicyClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
    this.conditionals = new ConditionalPolicyClient(serviceUrl, methods);
    this.bargeIns = new BargeInPolicyClient(serviceUrl, methods);
    this.retries = new RetryPolicyClient(serviceUrl, methods);
    this.validates = new ValidatePolicyClient(serviceUrl, methods);
    this.timeouts = new TimeoutPolicyClient(serviceUrl, methods);
    this.expirations = new ExpirationPolicyClient(serviceUrl, methods);
    this.inputFormatters = new InputFormatterPolicyClient(serviceUrl, methods);
  }

  async createPolicyForSnapshotAsync(snapshotId: string, type: PolicyType): Promise<DataResponse<Policy>> {
    return this.executeCreatePolicy('Snapshot', this.getUrlSnapshot(snapshotId), type);
  }

  async createPolicyForModuleAsync(
    snapshotId: string,
    moduleId: string,
    type: PolicyType,
  ): Promise<DataResponse<Policy>> {
    return this.executeCreatePolicy('Module', this.getUrlModule(snapshotId, moduleId), type);
  }

  createPolicyForBlockAsync(
    snapshotId: string,
    moduleId: string,
    blockId: string,
    type: PolicyType,
  ): Promise<DataResponse<Policy>> {
    return this.executeCreatePolicy('Block', this.getUrlBlock(snapshotId, moduleId, blockId), type);
  }

  createPolicyForEntryPointAsync(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    type: PolicyType,
  ): Promise<DataResponse<Policy>> {
    return this.executeCreatePolicy('EntryPoint', this.getUrlEntryPoint(snapshotId, moduleId, entryPointId), type);
  }

  async createPolicyForFunctionAsync(
    snapshotId: string,
    functionId: string,
    type: PolicyType,
  ): Promise<DataResponse<Policy>> {
    const url = `${this.serviceUrl}/tenant/${this.methods
      .tenantId!}/snapshot/${snapshotId}/v1/functions/${functionId}/policies`;
    return this.executeCreatePolicy('Function', url, type);
  }

  createPolicyForStepAsync(
    snapshotId: string,
    stepId: string,
    type: PolicyType,
  ): Promise<DataResponse<Policy>> {
    return this.executeCreatePolicy('Step', this.getUrlStep(snapshotId, stepId), type);
  }

  private async executeCreatePolicy(ownerType: string, url: string, type: PolicyType) {
    const response = await this.methods.post(url, type);
    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Created} Policy for ${ownerType}`,
      response,
    );
    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    return {
      success: true,
      data: PolicyBuilder(data),
    };
  }

  async getPoliciesBySnapshotAsync(snapshotId: string): Promise<DataResponse<Policy[]>> {
    const response = await this.methods.get(`${this.getUrlSnapshot(snapshotId)}`);
    const validationResponse = await this.methods.validateAndPopToast(  `${ValidateResponseActionEnum.Get} Policies`, response);

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    if (!Array.isArray(data)) {
      const error = 'Unexpected response when retrieving Context Items';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data: data.map((c) => PolicyBuilder(c)),
    };
  }

  async deletePolicyAsync(snapshotId: string, policyId: string): Promise<ServerResponse> {
    let response = await this.methods.delete(`${this.getUrlSnapshot(snapshotId)}/${policyId}`);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} Policy`, response);
  }

  private getUrlSnapshot(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/policies`;
  }
  private getUrlModule(snapshotId: string, moduleId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/modules/${moduleId}/policies`;
  }

  private getUrlBlock(snapshotId: string, moduleId: string, blockId: string) {
    return `${this.serviceUrl}/tenant/${this.methods
      .tenantId!}/snapshot/${snapshotId}/v1/modules/${moduleId}/blocks/${blockId}/policies`;
  }

  private getUrlEntryPoint(snapshotId: string, moduleId: string, entryPointId: string) {
    return `${this.serviceUrl}/tenant/${this.methods
      .tenantId!}/snapshot/${snapshotId}/v1/modules/${moduleId}/entry-points/${entryPointId}/policies`;
  }

  private getUrlStep(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}/policies`;
  }

  readonly conditionals: IConditionalPolicyClient;
  readonly bargeIns: IBargeInPolicyClient;
  readonly retries: IRetryPolicyClient;
  readonly timeouts: ITimeoutPolicyClient;
  readonly expirations: IExpirationPolicyClient;
  readonly validates: IValidatePolicyClient;
  readonly inputFormatters: IInputFormatterPolicyClient;
}
