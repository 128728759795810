import { useBots, useProperties } from 'contexts';
import { Output } from 'internal/models';
import React, { useEffect } from 'react';
import { OutputView } from './OutputView';

type OutputsViewProps = {
  /** Question or Statement (or Message for Send SMS, perhaps). Something to describe the value. */
  typeName: string;
  outputs: Output[];
  update: (outputs: Output[]) => void;
};

export const OutputsView: React.FC<OutputsViewProps> = ({ typeName, outputs, update }) => {
  const { languageModel } = useProperties();
  const { isReadOnlyBot} = useBots();

  useEffect(() => {
    if (
      !languageModel ||
      AllEntriesMatch(
        languageModel.supportedLanguages.map((o) => o.languageCode),
        outputs.map((o) => o.languageCode),
      )
    ) {
      return;
    }

    const newOutputs = languageModel.supportedLanguages.map(
      (l) =>
        new Output(
          l.languageCode,
          outputs.find((o) => o.languageCode.toUpperCase() === l.languageCode.toUpperCase())?.text ?? '',
        ),
    );
    !isReadOnlyBot && update(newOutputs);
  }, [outputs, languageModel]);

  const updateOutput = async (languageCode: string, text: string) => {
    const newOutputs = [...outputs];
    const output = newOutputs.find((o) => o.languageCode === languageCode);
    output!.text = text;
    update(newOutputs);
  };

  const views = outputs.map((o) => (
    <OutputView typeName={typeName} languageCode={o.languageCode} text={o.text} update={updateOutput} />
  ));

  return (
    <React.Fragment>
      <h4>{typeName}s</h4>

      <div style={{ maxWidth: '400px' }} className="row g-0">
        <div className="col-2">
          <label>Code</label>
        </div>
        <div className="col-10">
          <label>{typeName}</label>
        </div>
        {views}
      </div>
    </React.Fragment>
  );
};

// https://stackoverflow.com/a/6230314
function AllEntriesMatch(groupA: string[], groupB: string[]) {
  if (groupA.sort().join(',').toUpperCase() === groupB.sort().join(',').toUpperCase()) {
    return true;
  }

  return false;
}
