import { Behavior } from "./Behavior";
import { BehaviorType } from "./BehaviorType";

export abstract class BehaviorWithFollowUps extends Behavior {
    followUpBehaviors: Behavior[];

    constructor(behaviorType: BehaviorType, followUpBehaviors: Behavior[]) {
        super(behaviorType);
        this.followUpBehaviors = followUpBehaviors;
    }
}