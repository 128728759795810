import { Methods, DataResponse, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { IDTMFClient } from './IDTMFClient';

export class DTMFClient implements IDTMFClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private baseUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}/menu-items`;
  }

  private menuItemUrl(snapshotId: string, stepId: string, menuItemId: string) {
    return `${this.baseUrl(snapshotId, stepId)}/${menuItemId}`;
  }

  async createMenuItem(
    snapshotId: string,
    stepId: string,
    key: string,
    languageCode: string,
  ): Promise<DataResponse<string>> {
    const url = this.baseUrl(snapshotId, stepId);
    const request = {
      key: key,
      languageCode: languageCode,
    };
    const response = await this.methods.post(url, request);
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} DTMF Menu Item`,
      response,
    );
  }

  async setMenuItemKey(snapshotId: string, stepId: string, menuItemId: string, key: string): Promise<ServerResponse> {
    const url = `${this.menuItemUrl(snapshotId, stepId, menuItemId)}/key`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', key);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} DTMF Menu Item Key`, response);
  }

  async setMenuItemLanguageCode(
    snapshotId: string,
    stepId: string,
    menuItemId: string,
    languageCode: string,
  ): Promise<ServerResponse> {
    const url = `${this.menuItemUrl(snapshotId, stepId, menuItemId)}/language-code`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', languageCode);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} DTMF Menu Item Language Code`,
      response,
    );
  }

  async setMenuItemKeywords(
    snapshotId: string,
    stepId: string,
    menuItemId: string,
    keywords: string[],
  ): Promise<ServerResponse> {
    const url = `${this.menuItemUrl(snapshotId, stepId, menuItemId)}/keywords`;
    const response = await this.methods.put(url, keywords);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} DTMF Menu Item Keywords`,
      response,
    );
  }

  async deleteMenuItem(snapshotId: string, stepId: string, menuItemId: string): Promise<ServerResponse> {
    const url = this.menuItemUrl(snapshotId, stepId, menuItemId);
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} DTMF Menu Item`, response);
  }
}
