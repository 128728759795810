import { IRetryPolicyClient } from "./IRetryPolicyClient";
import { Methods, ValidateResponseActionEnum } from 'internal/clients/Methods';
import { ServerResponse } from "internal/clients/Responses";
import { Behavior } from "internal/models/bots/design/flow/policies/behaviors/Behavior";

export class RetryPolicyClient implements IRetryPolicyClient {
    serviceUrl: string;
    methods: Methods;

    constructor(serviceUrl: string, methods: Methods) {
        this.serviceUrl = serviceUrl;
        this.methods = methods; 

    }

    async setMaxAttempts(snapshotId: string, policyId: string, maxAttempts: number): Promise<ServerResponse> {
        const url = `${this.getUrl(snapshotId, policyId)}/max-attempts`;
        const response = await this.methods.put(url, maxAttempts);
        return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} max attempts`, response);
    }

    async setBehavior(snapshotId: string, policyId: string, behavior: Behavior): Promise<ServerResponse> {
        const url = `${this.getUrl(snapshotId, policyId)}/behavior`;
        const response = await this.methods.put(url, behavior);
        return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} behavior`, response);
    }

    private getUrl(snapshotId: string, policyId: string ) { 
        return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/policies/${policyId}`; 
    }
}