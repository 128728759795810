import { useEffect, useState } from 'react';
import { useModule, useConfigs, useContextItems, useObjects } from 'contexts';
import { ConfigPointer, ContextPointer, ContextType, IterationPointer, PointerType } from 'internal/models';
import { extractIDsFromQueryParams } from 'ui/utils';

interface IterationItemOutputProps {
  id: string;
  name: string;
  type: string;
}

interface useIterationPointerRes {
  iterationItem: IterationItemOutputProps[] | undefined;
  isForEachBlock: boolean;
  isIterationItemObjectType: boolean;
}

export const useIterationPointerLogic = (): useIterationPointerRes => {
  const configs = useConfigs();
  const contexts = useContextItems();
  const objects = useObjects();
  const module = useModule(extractIDsFromQueryParams().moduleID);
  const blockListToIterate = module?.blocks?.find(
    (blockItem) => blockItem.id === extractIDsFromQueryParams().nodeID,
  )?.listToIterate;

  const [iterationItem, setIterationItem] = useState<IterationItemOutputProps[] | undefined>([]);

  const [isForEachBlock, setIsForEachBlock] = useState(false);

  const [isIterationItemObjectType, setIsIterationItemObjectType] = useState(false);

  const getIterationPointerOutputs = () => {
    if (blockListToIterate?.type === PointerType.Context) {
      const contextItem = contexts.contextItems.find(
        (c) => c.id === (blockListToIterate as ContextPointer)?.contextItemId,
      );

      if (contextItem?.type === ContextType.Object || contextItem?.type === ContextType.Address) {
        const objectItem = objects.objects.find((item) => item.id === contextItem.typeId);
        const objectItemField = objects.objects
          .find((item) => item.id === contextItem.typeId)
          ?.fields.find((field) => field.id === (blockListToIterate as IterationPointer).propertyId);

        objectItem &&
          setIterationItem(
            objectItem.fields.map((field) => ({
              id: field.id,
              name: field.name,
              type: field.type,
            })),
          );

        objectItemField &&
          setIterationItem([
            {
              id: objectItemField.id,
              name: objectItemField.name,
              type: objectItemField.type,
            },
          ]);
      } else {
        contextItem?.originalId &&
          setIterationItem([
            {
              id: contextItem.id,
              name: contextItem.name,
              type: contextItem.type,
            },
          ]);
      }
    }
    if (blockListToIterate?.type === PointerType.Config) {
      const configItem = [...configs.all.values()].find(
        (c) => c.id === (blockListToIterate as ConfigPointer)?.configId,
      );

      configItem?.originalId &&
        setIterationItem([
          {
            id: configItem.id,
            name: configItem.name,
            type: configItem.type,
          },
        ]);
    }
  };

  useEffect(() => {
    getIterationPointerOutputs();
    setIsForEachBlock(blockListToIterate?.type !== PointerType.Empty);
  }, [blockListToIterate, contexts, configs, objects]);

  useEffect(() => {
    iterationItem && setIsIterationItemObjectType(iterationItem.length > 1);
  }, [iterationItem]);

  return {
    iterationItem,
    isForEachBlock,
    isIterationItemObjectType,
  };
};
