import React, { useState, useEffect } from 'react';
import { Property } from 'csstype';
import { splitLongString } from 'ui/utils';

type DiamondProps = {
    textColor?: Property.Color,
    backgroundColor?: Property.Color,
    text: string,
    size: number,
    isSelected: boolean,
}

export const Diamond: React.FC<DiamondProps> = ({textColor = 'black', backgroundColor = 'white', text = '', size = 1, isSelected}) => {

    const [firstLine, setFirstLine] = useState<string>('');
    const [secondLine, setSecondLine] = useState<string>('');
    const selectedWidth = 3;
    const normalWidth = 2;

    useEffect(() => {
        let lines = splitLongString(text, 30);
        setFirstLine(lines[0]);
        setSecondLine(lines[1]);

    }, [text])

    let additionalLine = secondLine.length > 0 ? ( 
        <p className='diamond-text' style={{
            color: textColor, 
            }}
        >
            {secondLine}
        </p>
    ) : <></>;

    return (
        <div className="diamondContainer" style={{ height: (240 * size), width: (240 * size) }}>
            <svg height='100%' width='100%' viewBox="0 0 240 240">
                <defs>
                    {isSelected && (
                        <filter id="dropShadow">
                            <feGaussianBlur in="SourceAlpha" stdDeviation="4" />
                            <feOffset dx="-4" dy="4" result="offsetblur" />
                            <feFlood floodColor="rgba(0,0,0,0.4)" />
                            <feComposite in2="offsetblur" operator="in" />
                            <feMerge>
                                <feMergeNode />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    )}
                </defs>
                <polygon
                    points="0 120,120 240,240 120,120 0"
                    style={{
                        fill: backgroundColor,
                        stroke: '#222',
                        strokeWidth: isSelected ? selectedWidth : normalWidth,
                        filter: isSelected ? 'url(#dropShadow)' : 'none'
                    }}
                />
                <svg height='100%' width='100%' viewBox="0 0 295 295">
                    <foreignObject width="100%" height="100%">
                        <p className='diamond-text' style={{
                            paddingTop: secondLine.length > 0 ? 120 : 125,
                            color: textColor,
                        }}>
                            {firstLine}
                        </p>
                        {additionalLine}
                    </foreignObject>
                </svg>
            </svg>
        </div>
    )
}