import { Patience, Select } from '@smartaction/visuals';
import { useObjects } from 'contexts';
import { Obj } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ContextItem, ContextType, ContextTypeNames, SelectableContextTypes } from 'internal/models';
import { ContextItemInUse } from './ColumnDefinitions';


type TypeRendererProps = ICellRendererParams<ContextItemInUse, ContextType> & {
    changeType: (contextItem: ContextItem, newType: ContextType, newTypeId?: string) => void;
    canEdit: boolean,
  };
  
  type SelectOptions = Array<{
    label: string;
    value: string;
    disabled?: boolean;
  }>;
  
  export const TypeRenderer: React.FC<TypeRendererProps> = ({ colDef, data, value, changeType, canEdit, rowIndex }) => {
    const [options, setOptions] = useState<SelectOptions>([]);
    const [selectedOption, setSelectedOption] = useState<string>('empty');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const objects = useObjects();
  
    useEffect(() => {
      if (!data) {
        setOptions([]);
        setSelectedOption('empty');
        return;
      }
      let selectOptions: SelectOptions = [
        { label: '--- Select ---', value: 'empty', disabled: true },
        { label: 'Standard Types:', value: 'groupStandard', disabled: true },
        ...SelectableContextTypes.map((o) => {
          return {
            label: ContextTypeNames.get(o)!,
            value: o,
          };
        }),
      ];
      if (objects.objects.length > objects.staticObjects.length) {
        // Remove Static Objects from render
        let cleanArray = objects.objects;
        cleanArray = cleanArray.filter( (x: Obj) => {
          return !objects.staticObjects.includes(x)});

        const objectsArray: SelectOptions = [
          { label: 'Objects:', value: 'groupObjects', disabled: true },
          ...cleanArray.map((o: Obj) => {
            return {
              label: o.name,
              value: o.id,
            };
          }),
        ];
        selectOptions = [...selectOptions, ...objectsArray];
      }
      setOptions(selectOptions);
      if (value === ContextType.Object && data.item.typeId) {
        const obj = objects.map.get(data.item.typeId);
        setSelectedOption(obj?.id || 'empty');
      } else {
        setSelectedOption(value ?? 'empty');
      }
      setIsSaving(false);
    }, [data, value, data?.item.typeId, objects]);
  
    const onChange = (value: string) => {
      if (!value || !data) {
        return;
      }
      const obj = objects.map.get(value);
  
      if (obj) {
        changeType(data.item, ContextType.Object, obj.id);
        return;
      }
      setIsSaving(true);
      changeType(data.item, value as ContextType);
    };
    return (
      <Patience showPatience={isSaving}>
        <Select disabled={!canEdit} id={`select-type-${rowIndex}`} value={selectedOption} onChange={onChange} options={options} />
      </Patience>
    );
  };