import { ContextType, Endpoint, Field, Parameter, SelectableContextTypes } from 'internal/models';
import { TableRow } from 'ui/components';
import { SelectType } from './SelectType';

export interface CreateParameterRowProps {
  parameter: Parameter;
  onUpdateName: (value: string) => void;
  onUpdateDescription: (value: string) => void;
  onUpdateParameterType: (value: string) => void;
  onUpdateParameterIsList: (value: boolean) => void;
  isDisabled: boolean;
}

export const createParameterRow = ({
  parameter, onUpdateName, onUpdateDescription, onUpdateParameterType, onUpdateParameterIsList, isDisabled
}: CreateParameterRowProps): TableRow => {
  return {
    id: parameter.id,
    cells: [
      {
        id: parameter.id + '-name',
        type: 'input',
        value: parameter.name,
        onChange: ({ value }) => onUpdateName((value as string)),
        disabled: isDisabled
      },
      {
        id: parameter.id + '-description',
        type: 'textarea',
        value: parameter.description,
        onChange: ({ value }) => onUpdateDescription((value as string)),
        disabled: isDisabled
      },
      { 
        id: parameter.id + '-type',
        type: 'customNode',
        customNode: 
          <SelectType value={parameter.type} isDisabled={isDisabled} onChange={(value) => onUpdateParameterType(value)} />,
        onChange: () => {}
      },
      {
        id: parameter.id + '-isList',
        type: 'checkbox',
        value: parameter.isList,
        onChange: ({ value }) => onUpdateParameterIsList(value as boolean),
        disabled: isDisabled
      }
    ]
  };
};