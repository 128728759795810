import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Accordion, ModalContainerIds, PortalConfirm, usePortalModal } from '@smartaction/visuals';
import { useClient } from 'contexts/ClientContext';
import { useSnapshot } from 'contexts/SnapshotContext';
import React, { Fragment } from 'react';
import { Resource, ResourceType } from 'internal/models';
import { useResources } from 'contexts';
import { AzureLanguageResourceType, GoogleAddressResourceType, TwilioResourceType } from './ResourceTypes';

export type ResourceItemType = {
  resources?: Resource[];
  onChangeResourceItemName: (id: string, value: string) => void;
  onDeleteResourceItem: (id: string) => void;
};

export const ResourceManager: React.FC = () => {
  const { snapshot } = useSnapshot();
  const { refresh, deleteItem, byType } = useResources();
  const client = useClient('resources');
  const confirm = usePortalModal(ModalContainerIds.Confirm);

  const onChangeResourceItemName = (id: string, value: string) => {
    client.languages.updateModelName(snapshot.id, id, value).then(() => {
      refresh(true); // update resources
    });
  };

  const onDeleteResourceItem = (id: string) => {
    return confirm.openModal(
      //TODO use Resource.isInUse to check if the resource is used before prompting delete (not wired up currently)
      <PortalConfirm
        header="Are you sure?"
        content={
          <React.Fragment>
            <div>If this resource is currently used, deleting it will break your bot.</div>
            <div>Use the green button to update the resource instead.</div>
          </React.Fragment>
        }
        confirmButton={{
          label: (
            <React.Fragment>
              <Icon type={IconType.Delete} /> Delete
            </React.Fragment>
          ),
          type: VisualCategory.Danger,
          clicked: async () => {
            deleteItem(id);
          },
        }}
        cancelButton={{ label: 'Cancel', type: VisualCategory.Light, clicked: () => {} }}
      />,
    );
  };

  const resourceTypes = [
    {
      label: ResourceType.Twilio,
      content: (
        <TwilioResourceType
          resources={byType.get(ResourceType.Twilio)}
          onChangeResourceItemName={onChangeResourceItemName}
          onDeleteResourceItem={onDeleteResourceItem}
        />
      ),
      collapsed: false,
    },
    {
      label: ResourceType.AzureLanguage,
      content: (
        <AzureLanguageResourceType
          resources={byType.get(ResourceType.AzureLanguage)}
          onChangeResourceItemName={onChangeResourceItemName}
          onDeleteResourceItem={onDeleteResourceItem}
        />
      ),
      collapsed: false,
    },
    {
      label: ResourceType.GoogleAddress,
      content: (
        <GoogleAddressResourceType
          resources={byType.get(ResourceType.GoogleAddress)}
          onChangeResourceItemName={onChangeResourceItemName}
          onDeleteResourceItem={onDeleteResourceItem}
        />
      ),
      collapsed: false,
    },
  ];

  return (
    <Fragment>
      <h3>Resources</h3>
      <Accordion items={resourceTypes}></Accordion>
    </Fragment>
  );
};
