import { ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { Methods } from 'internal/clients/Methods';
import { ITriggerAgentClient } from './ITriggerAgentClient';
import { ChannelType, Pointer, TriggerContextItem } from 'internal/models';

export type triggerChannelBodyType = {
  botId: string;
  entryPointOriginalId: string;
  channelType: ChannelType;
};
export class TriggerAgentClient implements ITriggerAgentClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async setTriggerChannel(snapshotId: string, stepId: string, body: triggerChannelBodyType): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/trigger-channel`;
    const response = await this.methods.put(url, body);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} Trigger Channel`, response);
  }

  async setTriggerDestination(snapshotId: string, stepId: string, destinationId: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/trigger-destination`;
    const response = await this.methods.put(url, destinationId);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Trigger Destination`,
      response,
    );
  }

  async setTriggerContextItem(
    snapshotId: string,
    stepId: string,
    contextItem: TriggerContextItem,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/trigger-context-item`;
    const response = await this.methods.post(url, contextItem);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Trigger Context Item`,
      response,
    );
  }

  async setTriggerContextItemId(
    snapshotId: string,
    stepId: string,
    contextItemId: string,
    id: string,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/trigger-context-item/${contextItemId}/id`;
    const response = await this.methods.put(url, id);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Trigger Context Item Id`,
      response,
    );
  }

  async setTriggerContextItemPointer(
    snapshotId: string,
    stepId: string,
    contextItemId: string,
    pointer: Pointer,
  ): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/trigger-context-item/${contextItemId}/pointer`;
    const response = await this.methods.put(url, pointer);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} Trigger Context Item Pointer`,
      response,
    );
  }

  async deleteTriggerContextItem(snapshotId: string, stepId: string, id: string): Promise<ServerResponse> {
    const url = `${this.getUrl(snapshotId, stepId)}/trigger-context-item/${id}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Deleted} Trigger Context Item`,
      response,
    );
  }

  private getUrl(snapshotId: string, stepId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/steps/${stepId}`;
  }
}
