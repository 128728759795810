import { IconType } from '@smartaction/styles';
import {  Say } from 'internal/models';
import React from 'react';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { OutputStepsColors } from '../../StepColorGroups';
import { StepOutputsView } from '../../StepOutputsView';

export const SayStyling: StepStyling = {
    typeName: "Say",
    icon: IconType.Say,
    ...OutputStepsColors
}

export const SayStepView: React.FC<TypedStepProps<Say>> = ({ step, manipulateStep }) => {
    return (
        <StepView step={step} styling={SayStyling} isCollapsible={true} manipulateStep={manipulateStep}>
            <SayDetails step={step} manipulateStep={manipulateStep} />
        </StepView>
    );
}

const SayDetails: React.FC<TypedStepProps<Say>> = ({step}) => {

  return (
    <React.Fragment>
      <StepOutputsView step={step} typeName='Statement' />
    </React.Fragment>
  );
}