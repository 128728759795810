import { DirectAssignmentListPointer, EmptyPointer } from 'internal/models';
import React from 'react';
import { PointerEditorProps } from './PointerEditorProps';
import { StringListCellEditor } from 'ui/controls/grid/editors/StringListCellEditor';

export const DirectAssignmentListPointerEditor: React.FC<PointerEditorProps<DirectAssignmentListPointer>> = ({pointer, update, isDisabled}) => {
    
    const save = (strings: string[]) => {
        update(strings && strings.length
            ? new DirectAssignmentListPointer(strings.filter(s => s !== ''))
            : new EmptyPointer());
    }

    return <StringListCellEditor
        isDisabled={isDisabled}
        strings={pointer?.values ?? []}
        setStrings={save}
        placeholder='Set hardcoded value'
    />;
}