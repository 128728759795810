import { usePortalModal } from '@smartaction/visuals';
import React from 'react';
import { ScriptEditor } from './ScriptEditor';
import { useClient, useSnapshot } from 'contexts';
import { Environment, GetEnvName } from 'internal/models';
import { useCheckTenantAccess } from 'contexts/AccessContext';
import { EntitlementNames } from 'EntitlementNames';

interface EnvironmentsListProps {
  apiId: string;
  endpointId: string;
  isDisabled: boolean;
  scripts: Record<Environment, string>;
  onUpdateScript: (type: string, value: string) => Promise<void>;
}

export const EnvironmentsList: React.FC<EnvironmentsListProps> = 
  ({ apiId, endpointId, isDisabled, scripts, onUpdateScript }) => {
    const modal = usePortalModal();
    const snapshot = useSnapshot();
    const client = useClient('apis').endpoints;

    // Script has extra permissions
    const hasAccess = useCheckTenantAccess(EntitlementNames.APIs.ModifyScripts);
    const isReadOnly = isDisabled || !hasAccess;

    const onSaveScript = (environmentId: string) => (value: string) =>
      onUpdateScript(environmentId.toLowerCase(), value).then(modal.closeModal);

    const onClick = (environmentId: string, value: string) => () => {

      client.getEndpointScriptAsync(snapshot.snapshot.id, apiId, endpointId, environmentId).then((res) =>{
        if (res.success) {
          modal.openModal(
            <ScriptEditor
              isReadOnly={isReadOnly}
              endpointId={endpointId}
              headerText={`${environmentId} Script`}
              value={res.data}
              save={onSaveScript(environmentId.toLowerCase())}
              modal={modal}
            />,
          );
        }
      });
    };
    
    return (
      <>
        <div className="manageApi-env-list">
          {Object.entries(scripts).map(([environmentId, value]) => {
            return (
              <span key={`script-btn-${endpointId}-${environmentId}`}
                className={`manageApi-env-list-item ${value ? 'is--active' : ''}`}
                onClick={onClick(environmentId, value)}>
                {GetEnvName(environmentId as Environment)}
              </span>
            );
          })}
        </div>
        {modal.modal}
      </>
    );
  };
