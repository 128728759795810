import React, { useState } from 'react';
import { Button, Field, PortalModal, useFromModal, useId } from '@smartaction/visuals';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';

type CreateFunctionModalProps = {
  save: (name: string) => void;
};

const CreateFunctionModal: React.FC<CreateFunctionModalProps> = ({ save }) => {
  // EXAMPLE NOTE: there's nothing stopping the modal itself from calling useModal and being able to close itself, or open an alert.
  const nameFieldId = useId('moduleName');
  const [name, setName] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const modal = useFromModal();

  const header = {
    type: VisualCategory.Primary,
    content: (
      <React.Fragment>
        <Icon type={IconType.Function} /> Create New Function
      </React.Fragment>
    ),
  };

  const content = (
    <React.Fragment>
      <Field inputId={nameFieldId} label="Name" name="name" groupClass="col">
        <input
          id={nameFieldId}
          className="form-control"
          type="text"
          value={name}
          onChange={(evt) => setName(evt.target.value)}
        />
      </Field>
    </React.Fragment>
  );

  const cancel = () => modal.forceClose();
  const saveClick = () => {
    setIsSaving(true);
    save(name);
  };

  const buttons = (
    <React.Fragment>
      <Button className={'me-3'} action={saveClick} isDisabled={isSaving || !name} type={VisualCategory.Primary}>
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
      <Button action={cancel} type={VisualCategory.Secondary}>
        Cancel
      </Button>
    </React.Fragment>
  );

  return <PortalModal header={header} content={content} buttons={buttons} />;
};
export default CreateFunctionModal;
