import React from "react";
import { Behavior, BehaviorType, Output, TalkBehavior } from "internal/models";
import { BehaviorEditorProps } from "./Type";
import { FollowUpListView } from "./FollowUpList";
import { OutputsView } from "ui/components/design/OutputsView";

type TalkBehaviorProps = BehaviorEditorProps<TalkBehavior> & {
    allowedFollowUpTypes: BehaviorType[]
}

export const TalkBehaviorView: React.FC<TalkBehaviorProps> = ({behavior, update, allowedFollowUpTypes}) => {
    if (behavior.type !== BehaviorType.Talk) {
        update(new TalkBehavior([], []));
        return <React.Fragment/>;
    }

    const saveOutput = (saveList: Output[]) => {
        update(new TalkBehavior(saveList, behavior.followUpBehaviors));
    };

    const saveFollowUps = (followups: Behavior[]) => update(new TalkBehavior(behavior.output, followups));
    
    const followUps = allowedFollowUpTypes.length
      ? (
          <React.Fragment>
              <hr/>
              <FollowUpListView allowedTypes={allowedFollowUpTypes} followUpBehaviors={behavior.followUpBehaviors} update={saveFollowUps} />
          </React.Fragment>
      ) 
      : <React.Fragment/>;
  
    return (
      <React.Fragment>
        <OutputsView outputs={behavior?.output ?? []} update={saveOutput} typeName="Message" />
        {followUps}
      </React.Fragment>
    );
}