import { Pointer } from "../../shared";
import { Step } from "../Step";
import { StepType } from "../Type";

export class CallAPI extends Step {
    apiId : string
    endpointId: string
    inputAssignments : ParameterAssignment[];
    outputAssignments : ParameterAssignment[]; 

    constructor(id: string | undefined, name: string, description: string, tags: string[], apiId: string, 
        endpointId: string, inputAssignments: ParameterAssignment[], outputAssignments: ParameterAssignment[]) {
        super(id, name, description, StepType.CallAPI, tags);
        this.apiId = apiId;
        this.endpointId = endpointId;
        this.inputAssignments = inputAssignments; 
        this.outputAssignments = outputAssignments;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): CallAPI {
        const inputAssignments = json.inputAssignments && Array.isArray(json.inputAssignments)
            ? json.inputAssignments.map((p: any) => ParameterAssignment.fromJson(p))
            : [];
        const outputAssignments = json.outputAssignments && Array.isArray(json.outputAssignments)
            ? json.outputAssignments.map((p: any) => ParameterAssignment.fromJson(p))
            : [];

        return new CallAPI(json.id, json.name, json.description, json.tags, json.apiId, json.endpointId, inputAssignments, outputAssignments);

    }

}

export class ParameterAssignment {

    parameterId: string;
    pointer: Pointer;

    constructor(parameterId: string, pointer: Pointer) {
        this.parameterId = parameterId;
        this.pointer = pointer; 
    }

    static fromJson(json: any) {
        return new ParameterAssignment(json.parameterId, Pointer.fromJson(json.pointer));
    }
}
