import { DataResponse, Methods } from 'internal/clients';
import { Environment, DeployResponse, ValidationResponse } from 'internal/models';
import { IPublishClient } from './IPublishClient';

export class PublishClient implements IPublishClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = serviceUrl;
    this.methods = methods;
  }

  async publish(
    snapshotId: string,
    environment: Environment,
    comment: string,
  ): Promise<DataResponse<string>> {
    const response = await this.methods.post(
      `${this.rootUrl(this.methods.tenantId!, snapshotId)}/${environment}/publish`,
      { comment },
    );

    if (response.ok || response.status === 400) {
      const data = await response.json();

      return { success: response.ok, data };
    }

    return { success: response.ok };
  }

  private rootUrl(tenantId: string, snapshotId: string) {
    return `${this.serviceUrl}/tenant/${tenantId}/snapshot/${snapshotId}/v2/deploys`;
  }

  async getLastPublished(snapshotId: string, environment: Environment): Promise<DataResponse<DeployResponse[]>> {
    try {
      const response = await this.methods.get(
        `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v2/deploys/${environment}`,
      );

      const json = (await response.json()) as Array<{ userName: string; startedOn: string, comment: string }>;

      return { success: true, data: json.map((d) => DeployResponse.fromJson(d)) };
    } catch (e) {
      return Promise.reject(e);
    }
  }
  
  async getDeploymentResult(snapshotId: string, deployId: string): Promise<DataResponse<boolean | undefined>> {
    const response = await this.methods.get(
      `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v2/deploys/${deployId}`,
    );

    if (!response.ok || response.status === 204) {
      return { success: response.ok };
    }

    const data = await response.json();

    return { success: response.ok, data };
  }
}
