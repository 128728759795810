import { IconType } from '@smartaction/styles';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import { Break } from 'internal/models';
import { ProcessStepsColors } from '../../StepColorGroups';

export const BreakStyling: StepStyling = {
  typeName: 'Break',
  icon: IconType.WorkflowNext,
  ...ProcessStepsColors,
};

export const BreakStepView: React.FC<TypedStepProps<Break>> = ({ step, manipulateStep }) => {
  return <StepView step={step} styling={BreakStyling} manipulateStep={manipulateStep} />;
};
