import React from 'react';
import { useClient } from '../../contexts';


type ActionMenuItem = {
    label: React.ReactElement | string,
    action: () => void,
    isDisabled?: boolean
}

type ActionMenuProps = {
    items: ActionMenuItem[]
}

/** Intended to act as a set of buttons at the top of a control, like a window.
 * Would be called Toolbar, but the name is taken.
 */
export const ActionMenu: React.FC<ActionMenuProps> = ({items}) => {
    const menuItems = items.map((item, i) => <button key={i} className='btn btn-sm action-menu-item col' disabled={item.isDisabled} onClick={item.action}>{item.label}</button>);

    return (
        <div className='action-menu container'>
            <div className='row'>
                {menuItems}
            </div>
        </div>
    );
}