import { BaseModel } from "@smartaction/common";
import { Block, Decision, EntryPoint, Note } from '.';

export class Module extends BaseModel<Module> {
    description: string;
    blocks: Array<Block>;
    decisions: Array<Decision>;
    notes: Array<Note>;
    entryPoints: Array<EntryPoint>;

    constructor(id: string | undefined, name: string, description: string, blocks?: Array<Block>, decisions?: Array<Decision>, notes?: Array<Note>, entryPoints?: Array<EntryPoint>) {
        super(name, id);
        this.description = description;
        this.blocks = blocks ? blocks : [];
        this.decisions = decisions ? decisions : [];
        this.notes = notes ? notes : [];
        this.entryPoints = entryPoints ? entryPoints : [];
    }

    isDirty(original: Module): boolean {
        throw new Error("Method not implemented.");
    }
    getDirtyFields(original: Module): string[] {
        throw new Error("Method not implemented.");
    }
    clone(): Module {
        throw new Error("Method not implemented.");
    }

    static fromJson(json: any) {
        const blocks = json.blocks && Array.isArray(json.blocks)
            ? json.blocks.map((b: any) => Block.fromJson(b))
            : [];

        const decisions = json.decisions && Array.isArray(json.decisions)
            ? json.decisions.map((d: any) => Decision.fromJson(d))
            : [];

        const entryPoints = json.entryPoints && Array.isArray(json.entryPoints)
            ? json.entryPoints.map((ep: any) => EntryPoint.fromJson(ep))
            : [];

        const notes = json.notes && Array.isArray(json.notes)
            ? json.notes.map((n: any) => Note.fromJson(n))
            : [];

        return new Module(json.id, json.name, json.description, blocks, decisions, notes, entryPoints);
    }

}