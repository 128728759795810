import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, PortalModal, useFromModal } from '@smartaction/visuals';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Option } from 'ui/types';
import { useClient, useResources, useSnapshot } from 'contexts';

export const AddAzureLanguageResourceModal = () => {
  const [isSaving, setIsSaving] = useState(false);
  const { snapshot } = useSnapshot();
  const { refresh } = useResources();
  const client = useClient('resources');
  const modal = useFromModal();
  const [selectedCLU, setSelectedCLU] = useState<Option>();
  const [cluOptions, setCluOptions] = useState<Option[] | undefined>([]);

  useEffect(() => {
    client.languages.getResources(snapshot.id, true).then((r) => {
      setCluOptions(
        r.map((el) => ({
          label: el.replace(/([A-Z])/g, ' $1'),
          value: el,
        })),
      );
    });
  }, []);

  const header = {
    type: VisualCategory.Primary,
    content: (
      <React.Fragment>
        <Icon type={IconType.Add} /> Add Azure Language Resource
      </React.Fragment>
    ),
  };

  const content = (
    <div className={'mt-2'}>
      <Select
        options={cluOptions}
        onChange={(s) => s && setSelectedCLU(s)}
        value={selectedCLU}
        hideSelectedOptions={true}
        styles={{
          menu: (base) => ({
            ...base,
            position: 'relative',
          }),
        }}
      />
    </div>
  );

  const cancel = () => modal.forceClose();
  const saveClick = async () => {
    setIsSaving(true);
    selectedCLU?.value &&
      (await client.languages.create(snapshot.id, selectedCLU?.value).then((r) => r.success && refresh(true)));
    modal.forceClose();
    setIsSaving(false);
  };

  const buttons = (
    <React.Fragment>
      <Button className={'me-3'} action={saveClick} isDisabled={isSaving} type={VisualCategory.Primary}>
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
      <Button action={cancel} type={VisualCategory.Secondary}>
        Cancel
      </Button>
    </React.Fragment>
  );

  return (
    <PortalModal className={'add-azure-language-resource-modal'} header={header} content={content} buttons={buttons} />
  );
};
