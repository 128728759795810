export enum Environment {
  DEV = 'DEV',
  QA = 'QA',
  UAT = 'UAT',
  PROD = 'PROD',
}

export const GetEnvName = (environment: Environment) => {
  switch (environment) {
    case Environment.DEV:
      return 'Sandbox';
    case Environment.QA:
      return 'Testing';
    case Environment.UAT:
      return 'UAT';
    case Environment.PROD:
      return 'Live';
  }
} 
