export const transformStringToArray = (str: string): string[] => {
  return str.split(/[ ,]+/)
    .filter((word) => word)
    .map((word) => word.trim());
}

/** Takes a string of unknown length. If it is under the requested limit, returns it right back.

 *  Otherwise, it splits the string into one string at or under the limit, breaking if there's a space within 10 characters of the limit.

 *  Everything else goes into the second string.

 *

 *  This is to provide us a simple way of doing static ellipses on text where we can break it out into multiple lines; the first line stays under

 *  the length at which ellipses are necessary, the rest gets tossed into the second line that gets an ellipsis.

*/

export const splitLongString = (str: string, limit: number): [string, string] => {

  if (str.length <= limit) {
    return [str, ''];
  }

  let lineOne = str.substring(0, limit);
  let lineTwo = str.substring(limit);

  const lastSpace = lineOne.lastIndexOf(' ');
  // There's a space close enough to break on
  if (limit - lastSpace < 10) {
    lineTwo = lineOne.substring(lastSpace) + lineTwo;
    lineOne = lineOne.substring(0, lastSpace);
  }


  return [lineOne, lineTwo];
}

export const splitLongStringInAPrettyWay = (str: string, limit: number): string | string[] => {
  if (str.length <= limit) {
    return str;
  }

  let remainingStr = str;
  const result = [];
  while (remainingStr) {
    let activeStr = remainingStr.substring(0, limit);
    const lastSpace = activeStr.lastIndexOf(' ');

    if (limit - lastSpace < 15) {
      activeStr = remainingStr.substring(0, lastSpace);
      remainingStr = remainingStr.substring(lastSpace + 1);
    } else {
      remainingStr = remainingStr.substring(limit);
    }

    result.push(activeStr);
  }

  return result;
}