import { ICellRendererParams, RowDragCallbackParams } from "ag-grid-community";
import { ContextItem, ContextType } from "internal/models";
import React from "react";
import { DeleteRenderer } from "ui/controls";
import { CheckDisabledRenderer, CheckEditableRenderer, CheckboxCellStyle } from "ui/controls/grid/editors/CheckEditableRenderer";
import { TypeRenderer } from "./TypeRenderer";


export type ContextItemInUse = {
    item: ContextItem;
    inUse: boolean;
  };
  
export function CtxManagerColDefs(
    canEdit: boolean,
    isStatic: boolean,
    changeType: (contextItem: ContextItem, newType: ContextType, newTypeId?: string) => void,
    deleteFunc: (contextItem: ContextItemInUse) => void,
  ) {



    const order = {
        rowDrag: true,
        headerName : "Order",
        field: 'item.order',
        sortable: true,
        sortingOrder: ['asc'],
        width: 100,
        hide: false,
       // suppressToolPanel: true
      };

    const name = {
        headerName: 'Name',
        field: 'item.name',
        editable: (params: any) => {
          if (!params.data) {
            return undefined;
          }
          const contextItem = params.data;
          if (contextItem.item.isStatic) {
            return false;
          }
          return canEdit;
        },
        width: 200,
        resizable: true,
      };

    const category = {
        headerName : "Category",
        field: 'item.category',
        filter: "agTextColumnFilter",
        editable: (params: any) => {
          if (!params.data) {
            return undefined;
          }
          const contextItem = params.data;
          if (contextItem.item.isStatic) {
            return false;
          }
          return canEdit;
        },
        width: 100,
        hide: false,
      };

    const description = {
        headerName: 'Description',
        field: 'item.description',
        editable: (params: any) => {
          if (!params.data) {
            return undefined;
          }
          const contextItem = params.data;
          if (contextItem.item.isStatic) {
            return false;
          }
          return canEdit;
        },
        width: 350,
        resizable: true,
      };

    const type = {
        headerName: 'Type',
        field: 'item.type',
        cellRendererSelector: (params: any) => {
          if (!params.data) {
            return undefined;
          }
          const contextItem = params.data.item;
          if (contextItem.isStatic) {
            return { label: contextItem.type.toString() };
          }
  
          return {
            component: TypeRenderer,
          };
        },
        cellRendererParams: { canEdit, changeType },
        width: 200,
        resizable: true,
        cellStyle: { padding: 0 },
      };
      
    const isList = {          
        headerName: 'Is List',
        field: 'item.isList',
        cellRendererSelector: (params: any) => {
          if (!params.data) {
            return undefined;
          }
          const contextItem = params.data;

          if (contextItem.item.isStatic) {
            return { component:  EmptyRenderer };
          }
  
          return {
            component: CheckEditableRenderer,
          };
        },
        editable: false,
        cellStyle: CheckboxCellStyle,
        cellRendererParams: { canEdit: canEdit },
        width: 100,
        resizable: true,
      };

      const isSensitive = {
        headerName: 'Is Sensitive',
        field: 'item.isSensitive',
        cellRendererSelector: (params: any) => {
          if (!params.data) {
            return undefined;
          }

          const contextItem = params.data;
          if (contextItem.item.isStatic) {
            return { component: EmptyRenderer };
          }
  
          return {
            component: CheckEditableRenderer,
          };
        },
        editable: false,
        cellStyle: CheckboxCellStyle,
        cellRendererParams: { canEdit: canEdit },
        width: 100,
        resizable: true,
      };

    const isReported = {
        headerName: 'Is Reported',
        field: 'item.isReporting',
        cellRendererSelector: (params: any) => {
          if (!params.data) {
            return undefined;
          }
          const contextItem = params.data;
          if (contextItem.item.isStatic) {
            return { component: EmptyRenderer };
          }
          if (contextItem.item.isSensitive) {
            return {
              component: CheckDisabledRenderer,
            };
          }
  
          return {
            component: CheckEditableRenderer,
          };
        },
        editable: false,
        cellStyle: CheckboxCellStyle,
        cellRendererParams: { canEdit: canEdit },
        width: 100,
        resizable: true,
      };

    const isPublic = {
        headerName: 'Is Public',
        field: 'item.isPublic',
        cellRendererSelector: (params: any) => {
          if (!params.data) {
            return undefined;
          }
          const contextItem = params.data;
          if (contextItem.item.isStatic) {
            return { component: EmptyRenderer };
          }
  
          return {
            component: CheckEditableRenderer,
          };
        },
        editable: false,
        cellStyle: CheckboxCellStyle,
        cellRendererParams: { canEdit: canEdit },
        width: 100,
        resizable: true,
      };

    // without the 'any', TypeScript is gonna try to interpret what props we should pass to cellRendererParams, but we send different ones to type vs. delete
    const staticCols: any =  [
        name,
        description,
        type
    ];
    
    const normalCols : any = [
      order,
      name,
      category,
      description,
      type,
      isList,
      isSensitive,
      isReported,
      isPublic
    ];
  
    if (canEdit) {
      normalCols.push({
        headerName: 'Delete',
        cellRendererSelector: (params: any) => {
          if (!params.data) {
            return undefined;
          }
          const contextItem = params.data;
          if (contextItem.item.isStatic) {
            return { component: EmptyRenderer };
          }
  
          return {
            component: DeleteRenderer,
          };
        },
        cellRendererParams: { canEdit, deleteFunc },
        width: 100,
        resizable: true,
      });
    }

    return isStatic ? staticCols : normalCols;
  }


  //Replaced { component : React.Fragment }, since it was causing errors significant amount of errors
  export const EmptyRenderer: React.FC<ICellRendererParams> = (params : React.PropsWithChildren<ICellRendererParams<any, any, any>> ) => {
    return <React.Fragment/>;
}

