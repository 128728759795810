import {  CheckboxSimple } from '@smartaction/visuals';
import { ICellRendererParams } from 'ag-grid-community';
import { useFlow, useSnapshot } from 'contexts';
import { CompareCondition, Condition, ConditionType } from 'internal/models';
import React, {  } from 'react';



type ReverseRendererProps = ICellRendererParams<CompareCondition, boolean> & {
    onUpdateCondition: (condition: Condition) => void;
}

 const checkIfShowReverseTypeCell = (type: ConditionType): boolean => {
  switch (type) {
    case ConditionType.Regex:
    case ConditionType.Contains:
    case ConditionType.IsIn:
    case ConditionType.IsPopulated:
      return true;
    default:
      return false;
  }
};


export const ReverseRenderer: React.FC<ReverseRendererProps> = ({colDef, value, data, onUpdateCondition}) => {
    const condition = data as CompareCondition;
  
    if(!checkIfShowReverseTypeCell(condition.type))
      return null;

    return (
      <CheckboxSimple
        onChange={(x) => { 
            let newCondition = { 
                ...condition,
                reverse: x,
            } as CompareCondition;

            onUpdateCondition(newCondition);
        }}
        value={value as boolean}
        size={26}
        disabled={false}
      />
    );

}
