import React from 'react';
import { AllStepTypes, Container, Func, Step, StepType } from 'internal/models';
import { useClient, useFlow, useSnapshot } from 'contexts';
import { StepManager } from '../module/stepManagement';
import { DroppableData } from '@smartaction/visuals';
import { arrayRemove } from '@smartaction/common';

type FunctionWindowProps = {
    funcId: string
}

const typeName = "Function";

export const FunctionWindow: React.FC<FunctionWindowProps> = ({funcId}) => {
    const snapshot = useSnapshot();
    const client = useClient('flow');
    const { flow, updateFlow } = useFlow();
    const func = flow.functions.find(f => f.id === funcId);
    const container = new Container(typeName, undefined, funcId);

    const reorder = async (ids: string[]) => {
        const response = await client.functions.reorderSteps(snapshot.snapshot.id, funcId, ids);
        return response.success;
    }

    if (!func) {
        return <h4>Couldn't find the function requested!</h4>
    }

    const deleteSteps = async (steps: Step[]) => {
        for(let step of steps) {
            const response = await client.steps.deleteAsync(snapshot.snapshot.id, step.id, container);
            
            updateFlow(async () => {
                const index = func.steps.findIndex((s) => s.id === step.id);
                if (index > -1) {
                  func.steps = arrayRemove(func.steps, index);
                }
              });
        }
        return true;
    }

    const addSteps = async (steps: Step[], fromSource?: DroppableData, index?: number) => {
        for(let step of steps) {
            if (!step.id) {
                const response = await client.functions.createStep(snapshot.snapshot.id, funcId, step.type, step.name, index);
                if (!response.success) {
                    return false;
                } else {
                    step.id = response.data!;
                }
            } else {
                await client.functions.addExistingStep(snapshot.snapshot.id, funcId, step.id, new Container(fromSource?.sourceIdentifier?.type, 
                    fromSource?.sourceIdentifier?.parentId, fromSource?.identifier), index);
            }
        }
        return true;
    }

    return (
        <StepManager 
            identifier={funcId}
            steps={func.steps}
            sourceIdentifier={ { type: typeName, parentId: "" }}
            allowedTypes={AllStepTypes}
            hasTrash={true}
            addSteps={addSteps}
            reorderSteps={reorder} 
            deleteSteps={deleteSteps}
            stepsUpdated={(steps) => {
                updateFlow(() => (func.steps = steps));
              }}
              isContainer={true}
        />
    )
}