import { Methods, ValidateResponseActionEnum } from '../../Methods';
import { DataResponse, PagedListResponse } from '../../Responses';
import { Environment, ExecutionUnit, Summary } from 'internal/models';
import { IConversationClient } from './IConversationClient';
import { PostError } from 'ui/events';

export class ConversationClient implements IConversationClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  async getConversations(
    botCode: string,
    environment: Environment,
    conversationId: string,
    snapshotId: string,
    skip: number,
    count: number,
  ): Promise<DataResponse<PagedListResponse<Summary>>> {
    const response = await this.methods.get(
      `${this.summariesBaseUrl(
        botCode,
        environment,
      )}?conversationId=${conversationId}&snapshotId=${snapshotId}&skip=${skip}&count=${count}`,
    );
    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} Call Summary`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    return {
      success: true,
      data: {
        total: data.total,
        result: data.result.map((c: any) => Summary.fromJson(c)),
      } as PagedListResponse<Summary>,
    };
  }

  async getExecution(snapshotId: string, conversationId: string): Promise<DataResponse<ExecutionUnit[]>> {
    const url = this.baseUrl(snapshotId);
    const response = await this.methods.get(`${url}/${encodeURIComponent(conversationId)}/execution-stack`);
    const validationResponse = await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Get} Execution Stack`,
      response,
    );

    if (!validationResponse.success) {
      return validationResponse;
    }

    const data = await response.json();

    if (!Array.isArray(data)) {
      const error = 'Unexpected response when retrieving Context Items';
      PostError(error);
      throw new Error(error);
    }

    return {
      success: true,
      data: data.map((c) => ExecutionUnit.fromJson(c)),
    };
  }

  async downloadRecording(botCode: string, conversationId: string): Promise<void> {
    const url = `${this.serviceUrl}/tenant/${this.methods.tenantId}/v1/bot/${botCode}/recordings/${conversationId}`;
    const response = await this.methods.get(url);
    const success = response.status === 200;
    if (success) {
      await this.methods.download(response, 'audio/mpeg', `${conversationId}.mp3`);
    } else {
      await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Donwloaded} recording`, response);
    }
  }

  async exportConversationsAsCSV(
    botCode: string,
    environment: Environment,
    conversationId: string,
    snapshotId: string,
    skip: number,
    count: number,
  ): Promise<void> {
    const response = await this.methods.get(
      `${this.summariesBaseUrl(
        botCode,
        environment,
      )}/csv?conversationId=${conversationId}&snapshotId=${snapshotId}&skip=${skip}&count=${count}`,
    );
    const success = response.status === 200;
    if (success) {
      await this.methods.download(
        response,
        'text/csv',
        `${environment}-Conversations-ExportedOn-${new Date().toISOString().slice(0, 10)}.csv`,
      );
    } else {
      await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Donwloaded} .csv`, response);
    }
  }

  private summariesBaseUrl(botCode: string, environment: Environment) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/bot/${botCode}/v1/summaries/${environment}`;
  }

  private baseUrl(snapshotId: string) {
    return `${this.serviceUrl}/tenant/${this.methods.tenantId!}/snapshot/${snapshotId}/v1/summaries`;
  }
}
