import { ICellEditorParams } from 'ag-grid-community';
import { ConfigValue, ValueType } from 'internal/models';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

export const NumberCellEditor = forwardRef((props: ICellEditorParams, ref: React.ForwardedRef<unknown>) => {
    const configValue = (props.value as ConfigValue);
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState(configValue.type === ValueType.Number ? (configValue.value as number).toString() : "");
    const [, setChanged] = useState(false);

    function inputHandler(e : React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value);
        setChanged(true);
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [inputRef]);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value 
                    ? new ConfigValue(ValueType.Number, value.indexOf('.') > -1
                        ? Number.parseFloat(value) 
                        : Number.parseInt(value)) 
                    : new ConfigValue(ValueType.Unset, undefined);
            },
            isCancelAfterEnd: () => {
                return value === configValue.value;
            }
        };
    });

    return (
        <input
            type="number"
            className="ag-input-field-input ag-text-field-input"
            ref={inputRef}
            onChange={inputHandler}
            value={value}
            placeholder='Set Config Value'
        />
    )
});