import { PortalConfirm, useFromModal } from '@smartaction/visuals';
import { VisualCategory } from '@smartaction/styles';
import React from 'react';

export type DeleteAPIConfirmModalProps = {
  endpointsLength: number;
  apiName: string;
  onSubmit: () => void;
};

const  DeleteAPIConfirmModal: React.FC<DeleteAPIConfirmModalProps> = ({ apiName, endpointsLength, onSubmit }) => {
  const modal = useFromModal();
  return (
    <PortalConfirm
      header={`Delete ${apiName} API?`}
      content={`There are ${endpointsLength} endpoints in use.`}
      confirmButton={{
        label: 'Delete',
        type: VisualCategory.Primary,
        clicked: onSubmit,
      }}
      cancelButton={{
        label: 'Cancel',
        type: VisualCategory.Light,
        clicked: modal.forceClose,
      }}
    />
  );
}
export default DeleteAPIConfirmModal;
