import React from "react";
import { ModuleChildTypes } from "internal/models";
import { Circle } from "../nodes/shapes/Circle";

const EntryPoint: React.FC = () => {
  const onDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('application/reactflow', ModuleChildTypes.EntryPoint);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className="entry-point-draggable dndnode" onDragStart={onDragStart} draggable>
      <Circle text='EntryPoint' size={.35} isSelected={false}/>
    </div>
  )
}
export default EntryPoint;