import { useClient, useProperties, useSnapshot, useTenant } from 'contexts';
import ReactSelect from "react-select";

const TimeoutTable:React.FC<{isReadOnly:boolean}> = ({isReadOnly}) => {
  const properties = useProperties();
  const snapshot = useSnapshot();
  const tenant = useTenant();
  const client = useClient('properties');

  const handleTimezoneChange = (value: string) => {
    if (tenant?.id) {
      client.updateTimezone(snapshot.snapshot.id, tenant?.id, value);
    }
  };
  
  const timezoneOptions = [
    { value: 'Pacific Standard Time', label: 'Pacific Standard Time' },
    { value: 'Mountain Standard Time', label: 'Mountain Standard Time' },
    { value: 'Central Standard Time', label: 'Central Standard Time' },
    { value: 'Eastern Standard Time', label: 'Eastern Standard Time' }
  ]

  return (
    <div>
      <h2>Timezone</h2>
      <ReactSelect
        isDisabled={isReadOnly}
        defaultValue={{
          label: properties.general?.defaultTimeZone,
          value: properties.general?.defaultTimeZone,
        }}
        styles={{
          container: (base) => ({
            ...base,
            flex: 1,
          }),
        }}
        onChange={(value: any) => handleTimezoneChange(value.value)}
        options={timezoneOptions}
      />
    </div>
  );
};

export default TimeoutTable;
