import { Content, Patience, RadioToggleButtons, SubNav } from '@smartaction/visuals';
import React, { useEffect } from 'react';
import { Bots } from 'ui/views/bots';
import { BotsProvider, LanguageProvider, LocationDefinitionsProvider, useBots, useTenants } from 'contexts';
import { Route, Routes, useParams } from "react-router-dom";
import { DefaultPublisher } from '@smartaction/common';
import { toast } from 'react-toastify';
import ReactMarkdown from 'react-markdown';
import { TenantManagement } from 'ui/views/tenantManagement';
import { Link } from 'ui/controls';

export const Main: React.FC = () => {
    const tenants = useTenants();

    return (
            <Patience showPatience={tenants.availableTenants === undefined}>
                <div id="main">
                    <Content>
                        <div id="mainContent">
                            <Routes>
                                <Route path="/" element={ <h4>Select a tenant above to continue.</h4>} />
                                <Route path=":tenantCode/*" element={<TenantView />} />
                            </Routes>                        
                        </div>
                        <ToastCatcher />
                    </Content>
                </div>
            </Patience>
    );
}

const ToastCatcher = () => {
    useEffect(() => {
        const toastEventId = DefaultPublisher.subscribe('PopToastEvent', (evt) => {
            toast(evt.isMarkdown 
                ? <ReactMarkdown>{evt.message}</ReactMarkdown>
                : evt.message, { ...evt.options, containerId: 'default'}); // default Id is specified in "Content" from Visuals
        });

        return () => DefaultPublisher.unsubscribe('PopToastEvent', toastEventId);
    }, []);
    return <React.Fragment/>;
}

type TenantRouteParams = {
    tenantCode: string
}

const TenantView: React.FC = () => {
    const tenants = useTenants();
    
    const { tenantCode } = useParams<TenantRouteParams>();
    useEffect(() => {
        if (tenants.selectedTenant?.code !== tenantCode) {
            tenants.setTenant(tenantCode);
        }
    }, [tenants.selectedTenant]);

    if (!tenants.selectedTenant) {
        return <React.Fragment/>;   
    }

    return (
        <LanguageProvider>
            <LocationDefinitionsProvider>
                <BotsProvider>
                    <TenantManagement />
                    <Bots />
                </BotsProvider>
            </LocationDefinitionsProvider>
        </LanguageProvider>
    );
}