import { ICellEditorParams } from 'ag-grid-community';
import { ConfigValue, ValueType } from 'internal/models';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

export const TimeCellEditor = forwardRef((props: ICellEditorParams, ref: React.ForwardedRef<unknown>) => {
    const configValue = (props.value as ConfigValue);
    const [value, setValue] = useState(configValue.type === ValueType.Time ? (configValue.value as string) : undefined);
    const [changed, setChanged] = useState(false);


    function set(time: string | null) {
        setValue(time !== null ? time : undefined);
        setChanged(time !== configValue.value);
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value 
                    ? new ConfigValue(ValueType.Time, value) 
                    : new ConfigValue(ValueType.Unset, undefined);
            },
            isCancelAfterEnd: () => {
                return !changed;
            }
        };
    });

    //The :00 is attached so service recognizes as TimeOnly Type. Format it expects is 00:00:00 not 00:00
    return <input type="time" className="form-control form-control-sm" onChange={ e => { set(e.target.value + ":00");}}  />;
});