import { useState } from 'react';
import { useConfigs, useContextItems } from 'contexts';
import { Marker, Suggestion } from "@smartaction/scripteditor";
import { Config, ContextItem } from 'internal/models';

export const useConversationState = () => {
  const configs = useConfigs();
  const contextItems = useContextItems();
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [validationScriptSuggestions, setValidationScriptSuggestions] = useState<Suggestion[]>([]);

  const addSuggestions = ():Suggestion[] => {
    suggestions.push({
      name: "Context",
      suffix: '.',
      suggestions: [{
          name: "Get",
          description: "Get value",
          suffix: "(",
          suggestions: contextItems.contextItems.map(_ => {
            return {
              name: _.name,
              description: _.description,
              suffix: ');'
            };
          })
        }, {
          name: "Set",
          suffix: "(",
          suggestions: contextItems.contextItems.map(_ => {
            return {
              name: _.name,
              description: _.description,
              suffix: ', '
            };
          })
        }]
      });

    suggestions.push({
      name: 'Config',
      description: 'Configuration values',
      suffix: '.',
      suggestions: [{
        name: "Get",
        description: "Get value",
        suffix: "(",
        suggestions: [...configs.all.values()].map(_ => {
          return {
            name: _.name,
            description: _.description,
            suffix: ');'
          };
        })
      }]
    });

    suggestions.push({
      name: "Logger",
      suffix: ".",
      suggestions: [{
        name: "Splunk",
        description: "Log to Splunk",
        suffix: ".",
        suggestions: [{
          name: "LogDebug",
          description: "Log debug information to Splunk",
          suffix: "("
        }, {
          name: "LogProblem",
          description: "Log error information to Splunk",
          suffix: "("
        }, {
          name: "LogException",
          description: "Log exception information to Splunk",
          suffix: "("
        }]
      }, {
        name: "Action",
        description: "Information to be pushed to TurnStack in a single Action",
        suffix: ".",
        suggestions: [{
          name: "Log",
          description: "Log message to push to TurnStack",
          suffix: "("
        }, {
          name: "Log<",
          description: "Log message with data to push to TurnStack",
          suffix: ">("
        }]
      }]
    });

    suggestions.push({
      name: "AddTag",
      suffix: "("
    })


    setSuggestions(suggestions);
    return suggestions;
  };

  const addValidationScriptSuggestions = (): Suggestion[] => { 
    validationScriptSuggestions.push({
      name: "input",
      suffix: "",
    })

    setValidationScriptSuggestions(validationScriptSuggestions);
    return validationScriptSuggestions;
  };

  const fromNames = (input: string):string => {
    const configMap = new Map<string, Config>();
    for(let _ of configs.all.values()) {
      configMap.set(_.name, _);
    }

    const contextMap = new Map<string, ContextItem>();
    for(let _ of contextItems.contextItems) {
      contextMap.set(_.name, _);
    }

    // IConversationState conversationState
    const parameterName = 'conversationState'

    // make sure to escape parentheses for regex
    input = interpolate(input, 'Config.Get\\(', `${parameterName}.Config.Get(`, configMap);
    input = interpolate(input, 'Context.Get\\(', `${parameterName}.Context.Get(`, contextMap);
    input = interpolate(input, 'Context.Set\\(', `${parameterName}.Context.Set(`, contextMap);
    input = input.replaceAll('Logger', `${parameterName}.Logger`);
    input = input.replaceAll('Execution', `${parameterName}.Execution`);
    input = input.replaceAll('ScratchPad', `${parameterName}.ScratchPad`);
    input = input.replaceAll('Random', `${parameterName}.Random`);

    return input;
  }

  const toNames = (output: string) => {
    // make sure to escape parentheses for regex
    output = interpolate(output, '[a-zA-Z0-9_]+.Config.Get\\(', 'Config.Get(', configs.all);
    output = interpolate(output, '[a-zA-Z0-9_]+.Context.Get\\(', 'Context.Get(', contextItems.map);
    output = interpolate(output, '[a-zA-Z0-9_]+.Context.Set\\(', 'Context.Set(', contextItems.map);
    output = output.replaceAll(/[a-zA-Z0-9_]+.Logger/g, 'Logger');
    output = output.replaceAll(/[a-zA-Z0-9_]+.Execution/g, 'Execution');
    output = output.replaceAll(/[a-zA-Z0-9_]+.ScratchPad/g, 'ScratchPad');
    output = output.replaceAll(/[a-zA-Z0-9_]+.Random/g, 'Random');

    return output;
  }
  
  const interpolate = (input:string, target:string, replaceWith:string, items:Map<string, any>) => {
    const regex = RegExp(`${target}[a-zA-Z0-9_"]+`, 'g');
    let result = input;

    for (const match of input.matchAll(regex)) {
      let replacement = replaceWith,
          itemName = match[0].split('(')[1].replaceAll('"', ''); // get name/id

      if (items.get(itemName)) {
        if (match[0].endsWith('"')) { // Check if objectid string
          replacement += items.get(itemName).name;
        } else {
          replacement += `"${items.get(itemName).id}"`;
        }
      } else {
        replacement += itemName;
        console.error(`${itemName} is not valid`);
      }

      result = result.replace(match[0], replacement);
    }

    return result;
  }

  const validate = (script:string):Marker[] => {
    let markers:Marker[] = [];

    for (const suggestion of suggestions) {
      markers = markers.concat(checkSuggestion(script, suggestion));
    }

    return markers;
  };

  const checkSuggestion = (script:string, target:Suggestion, prefix?:string) => {
    const regex = RegExp(`${prefix||''}${target.name}\\${target.suffix}[a-zA-Z0-9_]+`, 'g');
    let result:Marker[] = [];
    
    // TODO: optimized, this will likely be redundant
    for (const match of script.matchAll(regex)) {
      const split = match[0].split(target.suffix),
          suggestion = target.suggestions?.find(_ => _.name === split[1]); // 2nd half

      if (suggestion) { // if match, recursion
        if (suggestion.suggestions) {
          result = result.concat(checkSuggestion(script, suggestion, `${split[0]}\\${target.suffix}`));
        }
      } else {
        const index = (match.index || 0) + split[0].length + target.suffix.length,
              line = getLineNumber(script, index),
              position = index - script.lastIndexOf('\n', index); // get last newline before position
        
        result.push({
          description: `These are your options: ${target.suggestions?.map(_ => _.name).join(', ')}`,
          lineNumber: line,
          startPosition: position
        });
      }
    }
    
    return result;
  };

  const getLineNumber = (input:string, position:number):number => { // count newlines
    let line = 1;

    for (let i = 0; i < position; i++) {
      if (input[i] === '\n') {
        line++;
      }
    }

    return line;
  }

  return { addSuggestions, addValidationScriptSuggestions, validate, toNames, fromNames };
}