import { Patience } from '@smartaction/visuals';
import { useClient, useCurrentBot } from 'contexts';
import { TeamsUrls } from 'internal/clients';
import React, { useEffect, useState } from 'react';

export const Connections: React.FC = () => {
  const client = useClient('bot');
  const bot = useCurrentBot();
  const [teamsUrls, setTeamsUrls] = useState<TeamsUrls>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    if (bot) {
      setTimeout(() => {
        client
          .getTeamsUrlsAsync(bot.id)
          .then((data) => {
            if (data.success) {
              setTeamsUrls(data.data);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }, 2000);
    }
    // client is always loaded immediately. Bot is too, but I could imaging letting someone switch bots via the nav in the future.
  }, [bot]);

  return (
    <Patience showPatience={loading}>
      {teamsUrls ? (
        <>
          <h3>Teams links</h3>
          <div>
            <span className={'me-2'}>
              <a href={teamsUrls.DEV}>Sandbox</a>
            </span>
            <span className={'me-2'}>
              <a href={teamsUrls.QA}>Testing</a>
            </span>
            <span className={'me-2'}>
              <a href={teamsUrls.UAT}>UAT</a>
            </span>
            <span className={'me-2'}>
              <a href={teamsUrls.PROD}>Live</a>
            </span>
          </div>
        </>
      ) : (
        <div>Could not retrieve links.</div>
      )}
    </Patience>
  );
};
