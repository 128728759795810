import { Pointer, EmptyPointer } from "internal/models";
import { Step, StepType, Output, IHasOutputs } from "..";

export class SendSMS extends Step implements IHasOutputs {
    toNumber: Pointer;
    fromNumber: Pointer;
    outputs: Output[];

    constructor(id: string | undefined, name: string, description: string, tags: string[], outputs: Output[], toNumber: Pointer, fromNumber: Pointer) {
        super(id, name, description, StepType.SendSMS, tags);
        this.outputs = outputs;
        this.toNumber = toNumber;
        this.fromNumber = fromNumber;
    }

    isDirty(original: Step): boolean {
        throw new Error('Method not implemented.');
    }
    getDirtyFields(original: Step): string[] {
        throw new Error('Method not implemented.');
    }
    clone(): Step {
        throw new Error('Method not implemented.');
    }

    static fromJson(json: any): SendSMS {
        const outputs = json.outputs && Array.isArray(json.outputs)
            ? json.outputs.map((p: any) => Output.fromJson(p))
            : [];
        const toNumber = json.toNumber ? Pointer.fromJson(json.toNumber) : new EmptyPointer();
        const fromNumber = json.fromNumber ? Pointer.fromJson(json.fromNumber) : new EmptyPointer();
        return new SendSMS(json.id, json.name, json.description, json.tags, outputs, toNumber, fromNumber);
    }
}