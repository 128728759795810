import {  Input } from '@smartaction/visuals';
import { ICellRendererParams } from 'ag-grid-community';
import { Condition, ConditionType, RegexCondition } from 'internal/models';
import React, {  } from 'react';



type ExpressionRendererProps = ICellRendererParams<RegexCondition, boolean> & {
    onUpdateCondition: (condition: Condition) => void;
}

 const checkIfRequirementsMetToRender = (type: ConditionType): boolean => {
    return type === ConditionType.Regex;
  };
  


export const ExpressionRenderer: React.FC<ExpressionRendererProps> = ({colDef, value, data, onUpdateCondition}) => {
    const condition = data as RegexCondition;

    if(!checkIfRequirementsMetToRender(condition.type))
      return (<span>n/a</span>);

    return (

        <Input
            type={"text"}
            onChange={(newExpression) => {
            if (newExpression) {
                let newRegexCondition = {
                    ...condition,
                    expression: newExpression,
                    type: ConditionType.Regex
                };
                onUpdateCondition(newRegexCondition);
              }

        }}
        value = {condition.expression} 
        />
    );

}
