import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Location, TimeRange } from 'internal/models';
import { Button, DatePicker } from '@smartaction/visuals';
import { ICellEditorParams } from 'ag-grid-community';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';

type HoursEditorParams = ICellEditorParams<Location>;

export const HoursFieldEditor = forwardRef((props: HoursEditorParams, ref: React.ForwardedRef<unknown>) => {
  // Temp as in this is the working draft of the hours prior to being updated. The useImperativeHandle returns the results of tempHours, which is handled by the ag-grid in LocationsManagement to ultimately set the hours on the object.
  const [tempHours, setTempHours] = useState<Map<string, TimeRange[]>>(new Map(props.data.officeHours));
  const [selectedHours, setSelectedHours] = useState<TimeRange[]>([]);

  useEffect(() => {
      const newHours = new Map(tempHours);
      if(selectedHours[0]){
        newHours.set(selectedHours[0].day!, selectedHours);
        
        setTempHours(newHours);
      }

  }, [selectedHours]);
  useImperativeHandle(ref, () => {

    return {
      getValue: () => {
        return tempHours;
      },
      isCancelAfterEnd: () => {
        return false;
      },
    };
  });

  const filterTime = (startTimeVal: Date, endTimeVal: string, reverse?: boolean) => {
    const now = new Date();
    const nowDateTime = now.toISOString();
    const nowDate = nowDateTime.split('T')[0];
    return reverse
      ? startTimeVal.getTime() > new Date(nowDate + 'T' + endTimeVal).getTime()
      : startTimeVal.getTime() < new Date(nowDate + 'T' + endTimeVal).getTime();
  };

  const onHandleDeleteAction = (day:string , index: number, timeRanges: TimeRange[]) => { 

    let newTimes = timeRanges.filter( (x, currentIndex) => { 

      return currentIndex !== index;

    }).map( x =>{  return { ...x, day: day}});


    tempHours.set(day, newTimes);


    setSelectedHours(newTimes);
  };

  const onHandleAddAction = (day: string) => {

    let timeRanges = tempHours.get(day);

    let oldTimesWithDay = timeRanges?.map( x => { return { ...x, day: day }});
    let newTimeRanges = [...oldTimesWithDay!, { start: "08:00:00", end: "17:00:00", day: day}];
    tempHours.set(day,  newTimeRanges);


    setSelectedHours(newTimeRanges);
  }


  //Take a specific days time ranges to create layout
  const displayDayOfWeekTimeRanges = (day: string, timeRanges: TimeRange[]) => {

    if(timeRanges.length === 0)
      return <div className='mt-0 flex-item'>Closed</div>;

    //Traverse through all of a days time ranges
    return timeRanges.map( (x:TimeRange, index:number) => { 
      
      return (
          <div className={'d-flex  gap-2 align-items-center'}>
                <DatePicker
                  className={'location-item-date-picker me-2 flex-item'}
                  value={x.start}
                  onChange={
                    (value) => {
                      let latestTimeRanges = tempHours.get(day); 
                      if (value && latestTimeRanges)
                      {
                        //Find day of latest timeRanges for day, copy then modify timeRange affected, and update state
                        let latestTimesRangesOfDay = [...latestTimeRanges];
                        latestTimesRangesOfDay[index] = { start : value?.toLocaleTimeString('en-US', { hour12: false }), end: x.end , day: day}; 
                        setSelectedHours(latestTimesRangesOfDay);
                      }
                    }
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  filterTime={(val) => filterTime(val, x.end)} // Start time values before the end time values
                  shouldCloseOnSelect={true}
                />
                <DatePicker
                  className={'location-item-date-picker'}
                  value={x.end}
                  onChange={
                    (value) => {
                      let latestTimeRanges = tempHours.get(day); 
                      if (value && latestTimeRanges)
                      {
                        //Find day of latest timeRanges for day, copy then modify timeRange affected, and update state
                        let latestTimesRangesOfDay = [...latestTimeRanges];
                        latestTimesRangesOfDay[index] = { start: x.start, end: value?.toLocaleTimeString('en-US', { hour12: false }), day: day}; 
                        setSelectedHours(latestTimesRangesOfDay);
                      }
                    }
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  filterTime={(val) => filterTime(val, x.start, true)} // End time values after the start time values
                  shouldCloseOnSelect={true}
                />
            <div className='flex-item'>
              <Button type={VisualCategory.Danger} action={() => onHandleDeleteAction( day, index, timeRanges) }>
                <Icon type={IconType.Delete} size="sm" />
              </Button>
            </div>
          </div>
      )
    });
  }

  return (
    <div className="location-custom-field-editor">
        {[...tempHours].map(([key, timeRanges], index) => {
          return (
            <>
              <h6>{key}</h6>
              <div className='d-flex flex-column'>
                {displayDayOfWeekTimeRanges(key,timeRanges)}
              </div>
              <div className='d-flex'>
                <Button type={VisualCategory.Primary}  className='flex-grow-1' action={() => onHandleAddAction(key)}>   + Add</Button>
              </div>
            </>
          );
        })}
    </div>
  );
});
