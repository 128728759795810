import { ValidationErrors } from "@smartaction/common";
import { Bot } from "internal/models";

export function ValidateBot(bot: Bot, validationErrors: ValidationErrors) {
    validationErrors.resetLocal();

    // bot not yet instantiated, nothing to validate
    if (!bot) {
        return;
    }

    if (!bot.name || bot.name.trim().length === 0) {
        validationErrors.addLocalError("name", "Name cannot be empty.");
    }

    if (!bot.description || bot.description.trim().length === 0) {
        validationErrors.addLocalError("description", "Description cannot be empty.");
    }

    if (!bot.code || (bot.code.indexOf(' ') >= 0 && bot.code.length <= 12)) {
        validationErrors.addLocalError("code", "Code cannot have spaces and must be no more than 12 characters.");
    }
}