import React, { useState } from 'react';
import { Button, Field, PortalModal, Select, useFromModal, useId } from '@smartaction/visuals';
import { VisualCategory } from '@smartaction/styles';
import { CustomFieldTypeOptions } from './common';
import { CustomFieldType } from 'internal/models';

type AddCustomFieldModalProps = {
  save: (name: string, selectedType?: CustomFieldType) => void;
};

const AddCustomFieldModal: React.FC<AddCustomFieldModalProps> = ({ save }) => {
  const nameFieldId = useId('fieldName');
  const typeFieldId = useId('fieldType');
  const [name, setName] = useState('');
  const [selectedType, setSelectedType] = useState<CustomFieldType>();
  const [isSaving, setIsSaving] = useState(false);
  const modal = useFromModal();

  const header = {
    type: VisualCategory.Primary,
    content: <React.Fragment>Add Custom Field</React.Fragment>,
  };

  const content = (
    <React.Fragment>
      <Field inputId={nameFieldId} label="Name" name="name" groupClass="col">
        <input
          id={nameFieldId}
          className="form-control mb-2"
          type="text"
          value={name}
          onChange={(evt) => setName(evt.target.value)}
        />
      </Field>
      <Field inputId={typeFieldId} label="Type" name="type" groupClass="col">
        <Select
          id={'custom-field-type'}
          className={'mb-2'}
          value={`${selectedType}`}
          onChange={(value) => setSelectedType(value as CustomFieldType)}
          options={CustomFieldTypeOptions}
        />
      </Field>
    </React.Fragment>
  );

  const cancel = () => modal.forceClose();
  const saveClick = () => {
    setIsSaving(true);
    save(name, selectedType);
    modal.forceClose();
  };

  const buttons = (
    <React.Fragment>
      <Button className={'me-3'} action={saveClick} isDisabled={isSaving || !name} type={VisualCategory.Primary}>
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
      <Button action={cancel} type={VisualCategory.Secondary}>
        Cancel
      </Button>
    </React.Fragment>
  );

  return <PortalModal header={header} content={content} buttons={buttons} />;
};
export default AddCustomFieldModal;
