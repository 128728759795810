import { Position } from "@smartaction/visuals/Types";
import { ModuleChildTypes } from ".";
import { Component } from "./ModuleItem";
import { Condition } from "../shared";
import { EmptyId } from "internal/models";

/** A Decision is an object that can exist directly in a module */
export class Decision extends Component<Decision> {
    readonly type: ModuleChildTypes = ModuleChildTypes.Decision;
    branches: Array<DecisionBranch>;

    constructor(id: string | undefined, name: string, description: string, position: Position, branches: Array<DecisionBranch>) {
        super(id, name, description, position);
        this.branches = branches;
    }

    isDirty(original: Decision): boolean {
        throw new Error("Method not implemented.");
    }
    getDirtyFields(original: Decision): string[] {
        throw new Error("Method not implemented.");
    }
    clone(): Decision {
        throw new Error("Method not implemented.");
    }

    static fromJson(json: any) {
        const branches = json.branches && Array.isArray(json.branches)
            ? json.branches.map((b: any) => DecisionBranch.fromJson(b))
            : [];
        return new Decision(json.id, json.name, json.description, { x:json.position.x, y:json.position.y }, branches); 
    }

}

export class DecisionBranch extends Component<DecisionBranch> {
    type: ModuleChildTypes = ModuleChildTypes.Branch;

    isDirty(original: DecisionBranch): boolean {
        throw new Error("Method not implemented.");
    }
    getDirtyFields(original: DecisionBranch): string[] {
        throw new Error("Method not implemented.");
    }
    clone(): DecisionBranch {
        throw new Error("Method not implemented.");
    }
    conditions: Condition[];
    nextId: string;

    constructor(id: string, name: string, position: Position, conditions: Condition[], nextId: string) {
        super(id, name, "", position);
        this.conditions = conditions;
        this.nextId = nextId;
    }

    static fromJson(json: any) {
        const conditions = json.conditions && Array.isArray(json.conditions)
            ? json.conditions.map((c: any) => Condition.fromJson(c))
            : [];
        
        // handling the very odd case that a position wasn't returned. Should always be required
        const position = json.position
            ? { x: json.position.x, y: json.position.y }
            : { x: 500, y: 500};

            return new DecisionBranch(json.id, json.name, position, conditions, json.nextId ? json.nextId : EmptyId);
    }
}