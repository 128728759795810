import { Behavior, BehaviorType, NoActionBehavior, TimeoutPolicy } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { PolicyModalProps } from '../Types';
import { useClient, usePolicies, useSnapshot } from 'contexts';
import { Accordion, Button, Field, useId } from '@smartaction/visuals';
import { BehaviorControl } from '../behaviors/BehaviorControl';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { arrayRemove } from '@smartaction/common';

export const TimeoutPolicyView: React.FC<PolicyModalProps<TimeoutPolicy>> = ({ policy, disabled, isNew }) => {
  const timeoutSecondsId = useId('timeout-seconds');
  const { updatePolicies } = usePolicies();
  const client = useClient('flow');
  const snapshot = useSnapshot();
  const [seconds, setSeconds] = useState(policy.seconds);

  useEffect(() => {
    setSeconds(policy.seconds);
  }, [policy.seconds]);

  const updateSeconds = () => {
    client.policies.timeouts.setSeconds(snapshot.snapshot.id, policy.id, seconds).then((res) => {
      if (res.success) {
        updatePolicies(() => {
          policy.seconds = seconds;
        });
      }
    });
  };

  const updateBehavior = (behavior: Behavior, index?: number) => {
    if (index === undefined) {
      throw new Error('Unable to complete work - index was somehow not set.');
    }
    const behaviors = [...policy.behaviors];
    behaviors[index] = behavior;
    client.policies.timeouts.setBehaviors(snapshot.snapshot.id, policy.id, behaviors).then((res) => {
      if (res.success) {
        updatePolicies(() => {
          policy.behaviors = behaviors;
        });
      }
    });
  };

  const addBehavior = () => {
    const behaviors = [...policy.behaviors, new NoActionBehavior()];
    client.policies.timeouts.setBehaviors(snapshot.snapshot.id, policy.id, behaviors).then((res) => {
      if (res.success) {
        updatePolicies(() => {
          policy.behaviors = behaviors;
        });
      }
    });
  };

  const deleteBehavior = (index: number) => {
    const behaviors = arrayRemove(policy.behaviors, index);
    client.policies.timeouts.setBehaviors(snapshot.snapshot.id, policy.id, behaviors).then((res) => {
      if (res.success) {
        updatePolicies(() => {
          policy.behaviors = behaviors;
        });
      }
    });
  };

  const behaviors = policy.behaviors.map((b, i) => {
    return {
      label: `Attempt ${i + 1}`,
      content: (
        <TimeoutBehaviorView
          disabled={disabled}
          index={i}
          behavior={b}
          update={updateBehavior}
          deleteBehavior={deleteBehavior}
        />
      ),
      collapsed: !isNew && i === 0,
    };
  });

  return (
    <div>
      <Field label="Seconds Before Timeout" inputId={timeoutSecondsId}>
        <input
          id={timeoutSecondsId}
          type="number"
          value={seconds}
          disabled={disabled}
          onChange={(e) => setSeconds(Number.parseInt(e.target.value))}
          onBlur={updateSeconds}
        />
      </Field>
      <div className="mt-2">
        <Accordion items={behaviors} />
      </div>
      <Button isDisabled={disabled} type={VisualCategory.Primary} className="mt-2" action={addBehavior}>
        <Icon type={IconType.Add} /> Add
      </Button>
    </div>
  );
};

// Timeout and Validate Policies allow multiple behaviors to be defined, but the idea is that each behavior
// is one that runs on each failure. So after one timeout or validate, we use the "Attempt #1" behavior,
// after two, we use #2, etc.

// That's why the behaviors list doesn't work the same as the FollowUpBehaviors for Wait, RunScript, and Talk.

const TimeoutAllowedBehaviorTypes = [
  BehaviorType.CallFunction,
  BehaviorType.GoTo,
  BehaviorType.Skip,
  BehaviorType.RetryStep,
  BehaviorType.Talk,
];

type TimeoutBehaviorProps = {
  index: number;
  behavior: Behavior;
  update: (behavior: Behavior, index: number) => void;
  deleteBehavior: (index: number) => void;
  disabled?: boolean;
};

const TimeoutBehaviorView: React.FC<TimeoutBehaviorProps> = ({ index, behavior, update, deleteBehavior, disabled }) => {
  const save = (behavior: Behavior) => update(behavior, index);
  return (
    <>
      <BehaviorControl disabled={disabled} behavior={behavior} update={save} allowedTypes={TimeoutAllowedBehaviorTypes} />
      <Button isDisabled={disabled} type={VisualCategory.Danger} action={() => deleteBehavior(index)}>
        <Icon type={IconType.Delete} /> Delete
      </Button>
    </>
  );
};
