import { IEvent } from "@smartaction/common";
import { Bot } from "internal/models";

export class BotClosedEvent implements IEvent {
    public name: string = BotClosedEvent.eventName;
    static eventName: "BotClosedEvent" = "BotClosedEvent";
    
    interaction: Bot;

    constructor(interaction: Bot) {
        this.interaction = interaction;
    }
}