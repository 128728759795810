import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import {
  BooleanFieldValue,
  CustomDate,
  CustomField,
  CustomFieldType,
  CustomFieldValue,
  DateFieldValue,
  Location,
  NumberFieldValue,
  StringFieldValue,
} from 'internal/models';
import { DatePicker } from '@smartaction/visuals';
import { MultiLingualCellEditor } from 'ui/controls/grid/editors/MultiLingualStringEditor';

type CustomFieldEditorParams = ICellEditorParams<Location, CustomFieldValue> & {
  customField: CustomField;
};

function GetValue(value: CustomFieldValue | undefined | null, customField: CustomField): CustomFieldValue {
  if (value) {
    return value;
  }
  switch (customField.type) {
    case CustomFieldType.String:
      const map = new Map<string, string>();
      map.set('en-US', '');
      return new StringFieldValue(map);
    case CustomFieldType.Number:
      return new NumberFieldValue(0);
    case CustomFieldType.Boolean:
      return new BooleanFieldValue(false);
    case CustomFieldType.Date:
      return new DateFieldValue(new CustomDate('01', '01', '0001'));
  }

  throw new Error('Not implemented');
}

export const CustomFieldValueEditor = forwardRef((props: CustomFieldEditorParams, ref: React.ForwardedRef<unknown>) => {
  const [value, setValue] = useState(GetValue(props.value, props.customField));
  const [displayValue, setDisplayValue] = useState<Date>();
  const customField = props.customField;

  useEffect(() => {
    const dateValue = (value as DateFieldValue)?.value;
    if (!!dateValue && value?.type === CustomFieldType.Date) {
      //Creating a local date, though we wont technically be needing the time.
      //we need date object to add local timezone so the react-datepicker so it doesnt use
      //a different format ( UTC ) when selecting/displaying final result.
      //const formatDate = (value as DateFieldValue).value + "T12:00:00Z";

      const formattedDate = `${dateValue.year}-${pad(+dateValue.month)}-${pad(+dateValue.day)}T12:00:00Z`;
      setDisplayValue(new Date(formattedDate));
    }
  }, [value]);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      isCancelAfterEnd: () => {
        return false;
      },
    };
  });
  if (!props.value) {
    return <React.Fragment />;
  }

  let editorContents = <React.Fragment />;

  switch (customField.type) {
    case CustomFieldType.String:
      editorContents = <StringFieldEditor value={value as StringFieldValue} update={setValue} />;
      break;
    case CustomFieldType.Number:
      const numVal = value as NumberFieldValue;
      editorContents = (
        <input
          type="number"
          value={numVal.value}
          min={0}
          onChange={(evt) => setValue(new NumberFieldValue(Number(evt.target.value)))}
          onBlur={(evt) => setValue(new NumberFieldValue(Number(evt.target.value)))}
          onKeyDownCapture={(evt) => {
            if (evt.key === 'Enter') {
              setValue(new NumberFieldValue(Number(evt.currentTarget.value)));
            }
          }}
        />
      );
      break;
    case CustomFieldType.Boolean:
      const boolVal = value as BooleanFieldValue;
      editorContents = (
        <input
          type="checkbox"
          checked={boolVal.value}
          onChange={(evt) => setValue(new BooleanFieldValue(evt.target.checked))}
          onKeyDownCapture={(evt) => {
            if (evt.key === 'Enter') {
              setValue(new BooleanFieldValue(evt.currentTarget.checked));
            }
          }}
        />
      );
      break;
    case CustomFieldType.Date:
      editorContents = (
        <DatePicker
          className={'customNode-date-picker'}
          selected={displayValue}
          onChange={(val) => {
            if (val) {
              let year = val.getFullYear();
              let month = pad(val.getMonth() + 1); // getMonth() is zero-based
              let day = pad(val.getDate());
              setValue(new DateFieldValue(new CustomDate(month, day, year.toString())));
            }
          }}
          shouldCloseOnSelect={true}
        />
      );
      break;
  }

  return <div className="location-custom-field-editor">{editorContents}</div>;
});

type StringEditorProps = {
  value: StringFieldValue;
  update: (value: CustomFieldValue) => void;
};

const StringFieldEditor: React.FC<StringEditorProps> = ({ value, update }) => {
  const save = (updatedLanguageStringPairs: Map<string, string>) => {
    update(new StringFieldValue(updatedLanguageStringPairs));
  };

  return <MultiLingualCellEditor initialLanguageStringPairs={value.value} save={save} />;
};

const pad = (num: Number) => num.toString().padStart(2, '0');
