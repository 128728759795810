import { BaseModel } from '@smartaction/common';

export enum ResourceType {
  AzureLanguage = 'AzureLanguage',
  CLU = 'CLU',
  Twilio = 'Twilio',
  GoogleAddress = 'GoogleAddress',
}

export abstract class Resource extends BaseModel<Resource> {
  type: ResourceType;
  originalId: string;
  isInUse?: boolean;

  constructor(
    name: string,
    id: string | undefined,
    originalId: string,
    type: ResourceType,
    isInUse?: boolean,
  ) {
    super(name, id);
    this.type = type;
    this.originalId = originalId;
    this.isInUse = isInUse;
  }
}
