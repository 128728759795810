import { IEvent } from '@smartaction/common';
import { ValidationEntitiesTypeEnum } from '../internal/models';

export class NavigateToEntityEvent implements IEvent {
  public name: string = NavigateToEntityEvent.eventName;
  static eventName: 'NavigateToEntityEvent' = 'NavigateToEntityEvent';

  entities: {
    id: string;
    type: ValidationEntitiesTypeEnum;
  }[];

  constructor(
    entities: {
      id: string;
      type: ValidationEntitiesTypeEnum;
    }[],
  ) {
    this.entities = entities;
  }
}
