import { ValidationErrors } from '@smartaction/common';
import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button, Field, useComplexState, useId, useSidePanel, SelectOption, Checkbox } from '@smartaction/visuals';
import Select from 'react-select';
import { useSnapshot } from 'contexts/SnapshotContext';
import React, { useState, useEffect } from 'react';
import { ValidateConfig } from 'internal/validators/foundation/ConfigValidator';
import { Config, ConfigType } from 'internal/models';
import { IConfigClient } from 'internal/clients';

type CreateFormProps = {
  client: IConfigClient;
  configs: Config[];
  refresh: (fromServer?: boolean) => void;
};

export const CreateForm: React.FC<CreateFormProps> = ({ client, configs, refresh }) => {
  const sidePanel = useSidePanel();
  const snapshot = useSnapshot();
  const [name, setName] = useState('');
  const [type, setType] = useState<ConfigType>(ConfigType.String);
  const [isSensitive, setIsSensitive] = useState(false);
  const [validationErrors, updateValidationErrors] = useComplexState(
    new ValidationErrors(['name', 'description', 'type']),
  );
  const nameId = useId('newConfigName');
  const typeId = useId('newConfigType');
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const currentState = new Config('', '', name, '', type, isSensitive);
    updateValidationErrors((ve) => ValidateConfig(currentState, ve));
  }, [name, type, isSensitive]);

  const clearServerErrors = (propertyName: string) => {
    if (validationErrors) {
      updateValidationErrors((ve) => ve.clearServerErrorForProperty(propertyName));
    }
  };

  const save = async () => {
    setIsSaving(true);
    const response = await client.create(snapshot.snapshot.id, name, type, isSensitive, updateValidationErrors);
    if (response.success) {
      configs.push(new Config(response.data!, response.data!, name, '', type, isSensitive));
      refresh();
      sidePanel.clearContents();
      sidePanel.close();
    } else {
      setIsSaving(false);
    }
  };

  const cancel = () => {
    sidePanel.clearContents();
    sidePanel.close();
  };

  return (
    <div className="createForm">
      <Field inputId={nameId} label="Name" name="name" groupClass="col" validationErrors={validationErrors}>
        <input
          id={nameId}
          type="text"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          onFocusCapture={() => {
            clearServerErrors('name');
          }}
        />
      </Field>
      <Field inputId={typeId} label="Type" name="type" groupClass="col" validationErrors={validationErrors}>
        <Select
          styles={{
            menu: (base) => ({
              ...base,
              position: 'relative',
            }),
          }}
          options={typeOptions}
          onChange={(e) => setType(e?.value as ConfigType)}
          onFocus={() => {
            clearServerErrors('type');
          }}
        />
      </Field>
      <Checkbox label="Is Sensitive" value={isSensitive} setValue={(v) => setIsSensitive(v)} />
      <div className="createForm__btn-wrapper">
        <Button
          className="createForm__btn-submit"
          type={VisualCategory.Primary}
          action={save}
          isDisabled={isSaving || validationErrors.hasAnyErrors}
        >
          <Icon type={IconType.Save} />
          {isSaving ? 'Saving...' : 'Save'}
        </Button>
        <Button className="createForm__btn-cancel" type={VisualCategory.Secondary} action={cancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

const typeOptions: Array<SelectOption> = Object.keys(ConfigType).map((c) => {
  return { label: c, value: c };
});
